// *******************************************************
// InsightDetails
// -------------------------------------------------------
// This is a InsightDetails
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import InsightPanel from './InsightPanel';
import { useInsightsContext } from '../../contexts/insightsContext';
import { INSIGHTS_BREAKDOWN, INSIGHTS_ELEMENTS } from './insightsConfig';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const InsightDetails = () => {
  const { insightName, insightsStats } = useInsightsContext();
  if (!insightName || !insightsStats) {
    return <p>Something went wrong - try again</p>;
  }
  const { positive, negative } = INSIGHTS_BREAKDOWN[insightName.toLowerCase()];
  const positiveDetails = INSIGHTS_ELEMENTS[positive];
  const positiveStats = insightsStats[positive];

  const negativeDetails = INSIGHTS_ELEMENTS[negative];
  const negativeStats = insightsStats[negative];

  return (
    <div className={'flex flex-col items-center mt-6'}>
      <InsightPanel
        name={positiveDetails.title}
        image={positiveDetails.image}
        moreButtonShown={false}
        bestDay={positiveStats.best}
        worstDay={positiveStats.worst}
        industryAverage={positiveStats.average}
        disabledMargin
      />
      <div className='border-b border-gray-200 w-[80%] self-center my-4 ' />
      <InsightPanel
        name={negativeDetails.title}
        image={negativeDetails.image}
        moreButtonShown={false}
        bestDay={negativeStats.best}
        worstDay={negativeStats.worst}
        industryAverage={negativeStats.average}
        disabledMargin
      />
    </div>
  );
};

export default InsightDetails;
