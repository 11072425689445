import React from 'react';
// import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from '@leecheuk/react-google-login';
import { gapi } from 'gapi-script';

function GLogin() {
  const onClick = () => {
    gapi.auth2.getAuthInstance().signIn();
  };

  return (
    <button
      onClick={onClick}
      // disabled={renderProps.disabled}
      className={`mt-2 mb-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    >
      Authenticate for API access with Google
    </button>
  );
}

export default GLogin;
