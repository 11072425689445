/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { audioNodeIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import {
  AudioNodeType,
  AudioNodeTypeBase,
  AudioNodeTypeBuilder,
  AudioNodeTypeNew,
  HomeworkStatus,
  SwitchType,
  TrackType,
} from '../modeltypes/audioNode';
import { timestampType } from './shared';

export const trackTypeSchema = z.nativeEnum(TrackType);
export const homeworkStatusTypeSchema = z.nativeEnum(HomeworkStatus);

export const switchOptionTypeSchema = z.object({
  type: z.string(),
  nextNode: z.string(),
});
export const switchTypeSchema = z.nativeEnum(SwitchType);

export const audioNodeTypeSchema = z.object({
  id: audioNodeIdSchema,
  createdAt: timestampType().optional(),
  updatedAt: timestampType().optional(),
  title: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
  summaryText: z.string().optional().nullable(),
  duration: z.number().optional().nullable(),
  spokenText: z.string().optional().nullable(),
  singleTap: z.string().optional().nullable(),
  doubleTap: z.string().optional().nullable(),
  tripleTap: z.string().optional().nullable(),
  nextSubsection: z.string().optional().nullable(),
  // It's not clear that "naming" paths is actually meaningful or helpful, but...
  path: z.string().optional().nullable(),
  pathCode: z.string().optional().nullable(),
  silenceDuration: z.number().optional().nullable(),
  silenceFixedLen: z.boolean().optional().nullable(),
  comments: z.array(z.string()).optional(),
  importedFromGDocID: z.string().optional().nullable(),
  nodeType: trackTypeSchema.optional().nullable(),
  audioUrl: z.string().optional().nullable(),
  homeworkStatus: homeworkStatusTypeSchema.optional().nullable(),
  homeworkAnswer: z.string().optional().nullable(),
  switchOptions: z.array(switchOptionTypeSchema).optional().nullable(),
  subType: switchTypeSchema.optional().nullable(),
  shouldCheckHomework: z.boolean().nullable(),
  toCheck: z.boolean().optional().nullable(),
  singleTapTagId: z.string().optional().nullable(),
  mlAttribute: z.string().optional().nullable(),
  doubleTapTagId: z.string().optional().nullable(),
  tripleTapTagId: z.string().optional().nullable(),
});

export const audioNodeTypeBaseSchema = audioNodeTypeSchema.partial();

export const audioNodeTypeBuilderSchema = audioNodeTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: audioNodeIdSchema.default(() => IdFactory.AudioNode()) }));

export const audioNodeTypeNewSchema = audioNodeTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: audioNodeIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: AudioNodeType = audioNodeTypeSchema.parse(undefined);
  const x: AudioNodeTypeBase = audioNodeTypeBaseSchema.parse(undefined);
  const y: AudioNodeTypeBuilder = audioNodeTypeBuilderSchema.parse(undefined);
  const z: AudioNodeTypeNew = audioNodeTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
