// *******************************************************
// Chapter form
// -------------------------------------------------------
// This is a Page for adding/editing chapter details
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { addNewChapter, updateChapter } from '../../collections/chapter';
import { useAppContext } from '../../contexts/appContext';
import { appendChapterToLesson } from '../../collections/lesson';
import { appendBreadcrumbIfNecessary } from '../../utility/GeneralUtilities';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const ChapterForm = () => {
  const { chapterId, lessonId } = useParams<{ chapterId: string | 'new'; lessonId: string }>();
  const navigate = useNavigate();

  const { breadcrumbPaths, setBreadcrumbPaths } = useAppContext();

  const { values, handleChange, handleSubmit } = useFormik<{ description: string; title: string }>({
    initialValues: {
      title: '',
      description: '',
    },
    onSubmit: async (values) => {
      if (lessonId && chapterId) {
        let chapterID = chapterId;
        if (chapterID === 'new') {
          chapterID = await addNewChapter();
        }

        await updateChapter(chapterID, {
          id: chapterID,
          description: values.description,
          title: values.title,
        });

        await appendChapterToLesson(lessonId, chapterID);

        navigate(`/lessons/${lessonId}`);
      }
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      description: yup.string(),
    }),
  });

  useEffect(() => {
    const thisBreadcrumb = {
      label: 'New Chapter',
      path: window.location.pathname,
    };
    // if (JSON.stringify(breadcrumbPaths[breadcrumbPaths.length - 1]) !== JSON.stringify(thisBreadcrumb)) {
    //   setBreadcrumbPaths([...breadcrumbPaths, thisBreadcrumb]);
    // }
    appendBreadcrumbIfNecessary(thisBreadcrumb, breadcrumbPaths, setBreadcrumbPaths);
  }, [breadcrumbPaths, setBreadcrumbPaths]);

  return (
    <form
      className='space-y-8 divide-y divide-gray-200'
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
        <div>
          <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
            Details
          </label>
          <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Title
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='title'
                    id='title'
                    autoComplete='title'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    onChange={handleChange}
                    value={values.title}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
            <div>
              <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Description
              </label>
            </div>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <textarea
                id='description'
                name='description'
                rows={3}
                className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                onChange={handleChange}
                value={values.description}
              />
            </div>
          </div>
          <div className='pt-5 pb-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {chapterId === 'new' ? 'Create' : 'Update'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChapterForm;
