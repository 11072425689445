// *******************************************************
// Lesson Details Page
// -------------------------------------------------------
// This is a Page with Lesson details
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewLesson, getLessonById, updateLesson } from '../../collections/lesson';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { uploadFile } from '../../firebase/storage';
import { useAppContext } from '../../contexts/appContext';
import { dieIfNullOrUndef, emptyStringToNull, nullToUndef } from '../../utility/GeneralUtilities';
import { uiFormatTimestamp } from '../../collections/shared';
import { LessonChapterList } from '../../components/lessons/LessonChapterList';
import { LessonId } from '../../modeltypes/id';
import { LessonType, LessonTypeBuilder } from '../../modeltypes/lesson';
import { FileUploader } from 'react-drag-drop-files';
import { updateAudioNode } from '../../collections/audioNode';
import { AudioNodeTypeBase } from '../../modeltypes/audioNode';
import { serverTimestamp } from '../../models/dalaccess';
import { lessonHomeworkSpecIsValid } from 'src/utility/ScriptUtilities';
import { handleAudioUpload, UploadedFile, UploadedFileList } from '../../utility/uploadHandler';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';
import { toast } from 'react-toastify';
import { ReactSelectOptions } from '../../types/types';
import { getAllTags } from '../../collections/tags';
import Select from 'react-select';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

type LessonDetailsFormType = {
  title?: string | null;
  subtitle?: string | null;
  description?: string | null;
  author?: string | null;
  voiceArtist?: string | null;
  category?: string | null;
  introUiText?: string | null;
  footerUiText?: string | null;
  importedFromGDocID?: string | null;
  scriptLastUpdate?: string | null;
  homeworkAssigned?: string | null;
  productionStatus?: 'DEV' | 'PROD' | null;
  icon?: UploadedFile;
  grayedIcon?: UploadedFile;
  bulkUploads?: UploadedFileList;
  homeworkDoneQuestion?: string | null;
  homeworkNotDoneQuestion?: string | null;
  tags?: ReactSelectOptions[];
};

function homeworkSpecValid(this: yup.StringSchema, msg: string) {
  return this.test({
    name: 'homeworkSpecValid',
    message: msg,
    test: lessonHomeworkSpecIsValid,
  });
}

yup.addMethod(yup.string, 'homeworkSpecValid', homeworkSpecValid);

const LessonForm = () => {
  const { setBreadcrumbPaths, setValidateElement } = useAppContext();
  const navigate = useNavigate();
  const { id } = useParams<{ id: LessonId | 'new' }>();

  // const lessonRef = id ? getLessonRefById(id) : getLessonRefById('notarealid');
  // const { data: lesson } = useFirestoreDocData(dieIfNullOrUndef(lessonRef), { idField: 'id' });
  const [lesson, setLesson] = useState<LessonType | undefined>(undefined);
  const [tags, setTags] = useState<ReactSelectOptions[] | null>(null);

  useEffect(() => {
    console.debug('LessonDetails.useEffect called.');
    getLessonById(id).then((les) => setLesson(nullToUndef(les)));
    getAllTags().then((fTags) =>
      setTags(
        fTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        })),
      ),
    );
  }, [setLesson, id]);

  const { handleSubmit, handleChange, values, setValues, errors, isSubmitting, setFieldValue } =
    useFormik<LessonDetailsFormType>({
      initialValues: {
        title: '',
        subtitle: '',
        description: '',
        author: '',
        voiceArtist: '',
        category: '',
        introUiText: '',
        footerUiText: '',
        homeworkAssigned: '',
        productionStatus: null,
        importedFromGDocID: undefined,
        scriptLastUpdate: undefined,
        homeworkDoneQuestion: '',
        homeworkNotDoneQuestion: '',
      },
      onSubmit: async (values) => {
        dieIfNullOrUndef(lesson);
        await toast
          .promise(
            async () => {
              let lessonId = lesson?.id;
              if (!lessonId) {
                lessonId = await addNewLesson();
              }
              const urls: { icon?: string; grayedIcon?: string } = {
                icon: undefined,
                grayedIcon: undefined,
              };
              if (
                lesson?.grayedIcon !== values.grayedIcon?.url &&
                values.grayedIcon?.url !== '' &&
                values.grayedIcon?.file
              ) {
                urls.grayedIcon = await uploadFile(values.grayedIcon.file, 'grayIcon', ['assets', lessonId]);
              }
              if (lesson?.icon !== values.icon?.url && values.icon?.url !== '' && values.icon?.file) {
                urls.icon = await uploadFile(values.icon.file, 'icon', ['assets', lessonId]);
              }

              const updateData: LessonTypeBuilder = {
                id: lessonId,
                title: values.title,
                description: values.description,
                subtitle: values.subtitle,
                author: values.author,
                voiceArtist: values.voiceArtist,
                category: values.category,
                introUiText: values.introUiText,
                footerUiText: values.footerUiText,
                homeworkAssigned: values.homeworkAssigned,
                icon: urls.icon || (lesson ? lesson.icon : ''),
                grayedIcon: urls.grayedIcon || (lesson ? lesson.grayedIcon : ''),
                productionStatus: values.productionStatus,
                homeworkDoneQuestion: values.homeworkDoneQuestion,
                homeworkNotDoneQuestion: values.homeworkNotDoneQuestion,
                tags: values.tags?.map((tag) => tag.value) || [],
              };

              if (lesson?.importedFromGDocID) {
                updateData.importedFromGDocID = values.importedFromGDocID;
                updateData.scriptLastUpdate = values.scriptLastUpdate;
              }

              await updateLesson(lessonId, updateData);

              if (values.bulkUploads?.length) {
                toast.info('Preparing audio to be updated!', {
                  toastId: 'audio',
                  theme: 'colored',
                  autoClose: false,
                  closeOnClick: false,
                  hideProgressBar: false,
                  isLoading: true,
                });
                let progress = 0;
                // We may need to give the user some feedback here, as this could take a while, but one thing at a time.
                const bulkResults = await handleAudioUpload(
                  dieIfNullOrUndef(lesson),
                  dieIfNullOrUndef(values.bulkUploads),
                );
                for (const br of bulkResults) {
                  // Update the audio nodes.
                  const audioUpdateData: AudioNodeTypeBase = {};
                  if (br.matchingNodeId && br.downloadUrl) {
                    audioUpdateData.audioUrl = br.downloadUrl;
                    audioUpdateData.id = br.matchingNodeId;
                  }
                  if (br.audioDuration) {
                    // This is just a tiny bit of slop so that we don't round up things that are practically dead on.
                    audioUpdateData.duration = Math.ceil(br.audioDuration - 0.05);
                  }
                  if (audioUpdateData.audioUrl) {
                    await updateAudioNode(dieIfNullOrUndef(audioUpdateData.id), {
                      ...audioUpdateData,
                      updatedAt: serverTimestamp(),
                    });
                    const index = bulkResults.findIndex((i) => i.url === br.url);
                    progress = (index + 1) / bulkResults.length;
                    toast.update('audio', {
                      render: 'Uploading...',
                      progress,
                    });
                  }
                }

                // Refresh the current page instead of navingating to lessons.
                const fetchData = async () => {
                  const les = await getLessonById(id);
                  setLesson(nullToUndef(les));
                };

                await fetchData().catch(console.error);
                toast.update('audio', {
                  render: 'Uploaded audio!',
                  theme: 'colored',
                  type: 'success',
                  autoClose: 5000,
                  closeOnClick: true,
                  isLoading: false,
                });
              }
            },
            {
              pending: `Updating ${values.title}! Please wait...`,
              success: 'Successfully Updated Lesson!',
              error: 'Something went wrong while Updating Lesson',
            },
          )
          .then(() => navigate('/lessons'));
      },
      validationSchema: yup.object().shape({
        title: yup.string().required(),
        homeworkAssigned: yup
          .string()
          .trim()
          .homeworkSpecValid(`Homework spec should look like: 'Microhit: #, Formal: #, Background: #`)
          .optional(),
      }),
    });

  useEffect(() => {
    if (lesson && tags) {
      setFieldValue(
        'tags',
        tags.filter((el) => lesson.tags?.includes(el.value)),
      );
    }
  }, [setFieldValue, tags, lesson]);

  useEffect(() => {
    if (lesson) {
      setValues({
        title: lesson.title || '',
        description: lesson.description || '',
        subtitle: lesson.subtitle || '',
        author: lesson.author || '',
        voiceArtist: lesson.voiceArtist || '',
        category: lesson.category || '',
        introUiText: lesson.introUiText || '',
        footerUiText: lesson.footerUiText || '',
        icon: {
          url: lesson.icon,
        },
        grayedIcon: {
          url: lesson.grayedIcon,
        },
        homeworkAssigned: lesson.homeworkAssigned || '',
        importedFromGDocID: lesson.importedFromGDocID || '',
        scriptLastUpdate: lesson.scriptLastUpdate || '',
        productionStatus: lesson.productionStatus || null,
        homeworkNotDoneQuestion: lesson.homeworkNotDoneQuestion || '',
        homeworkDoneQuestion: lesson.homeworkDoneQuestion || '',
      });
    }
    setBreadcrumbPaths([
      {
        label: 'Lessons',
        path: '/lessons',
      },
      {
        label: lesson?.title || 'New Lesson',
        path: `/lessons/${id}`,
      },
    ]);
  }, [id, lesson, setValues, setBreadcrumbPaths]);

  const handleUploadDrop = useCallback(
    (file: File | FileList): void => {
      let fl: FileList;
      if (file instanceof File) {
        // Single
        fl = new FileList();
        fl[0] = file;
      } else {
        fl = file;
      }

      const listForFomik = [];
      for (const uploadedFile of fl) {
        // console.log(uploadedFile);
        const url = URL.createObjectURL(uploadedFile);
        const lffItem = {
          file: uploadedFile,
          url: url,
        };
        listForFomik.push(lffItem);
      }

      // This stashes the references away in Formik, and we'll handle them on submit.
      setValues({
        ...values,
        bulkUploads: listForFomik,
      });
    },
    [values, setValues],
  );

  const changeTo = (id: string, to: 'DEV' | 'PROD') => {
    setValidateElement({
      id,
      type: 'lesson',
      to,
    });
  };

  // noinspection DuplicatedCode
  return !lesson ? null : (
    <div>
      <form
        className='space-y-8 divide-y divide-gray-200'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
          <div>
            <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
              Lesson Details <span style={{ fontSize: '80%' }}>(ID: {lesson.id})</span>
            </label>
            <p>
              <strong>Created:</strong>&nbsp;{uiFormatTimestamp(lesson?.createdAt)}
              &nbsp;&nbsp;
              <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(lesson?.updatedAt)}
              {lesson ? (
                <>
                  <div className={'flex flex-row items-center mt-3'}>
                    <p className='font-bold mr-3'>Status:</p>
                    {lesson?.productionStatus || 'WIP'}
                    {lesson?.productionStatus === 'DEV' ? (
                      <button
                        type={'button'}
                        onClick={() => changeTo(lesson?.id, 'PROD')}
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Change to Prod
                      </button>
                    ) : null}
                    {!lesson?.productionStatus ? (
                      <button
                        type={'button'}
                        onClick={() => changeTo(lesson?.id, 'DEV')}
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Change to Dev
                      </button>
                    ) : null}
                  </div>
                  <div className={'flex flex-row items-center mt-3'}>
                    <p className='font-bold mr-3'>Locked:</p>
                    {lesson?.locked ? (
                      <CheckIcon className='h-5 w-5 text-green-500' />
                    ) : (
                      <XIcon className='h-5 w-5 text-red-400' />
                    )}
                  </div>
                </>
              ) : null}
            </p>
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                    This information will be displayed publicly so be careful what you share
                  </p>
                </div>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      maxLength={40}
                      type='text'
                      name='title'
                      id='title'
                      autoComplete='title'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                        errors?.title ? 'border-red-300' : 'border-gray-300'
                      }`}
                      onChange={handleChange}
                      value={values.title || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Subtitle
                  </label>
                  <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                    This information will be displayed publicly so be careful what you share
                  </p>
                </div>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='subtitle'
                      id='subtitle'
                      autoComplete='subtitle'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                        errors?.title ? 'border-red-300' : 'border-gray-300'
                      }`}
                      onChange={handleChange}
                      value={values.subtitle || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Description
                </label>
                <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                  This information will be displayed publicly so be careful what you share
                </p>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <textarea
                  id='description'
                  name='description'
                  rows={3}
                  className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  onChange={handleChange}
                  value={values.description || ''}
                />
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <div>
                <label htmlFor='author' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Author
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='author'
                    id='author'
                    autoComplete='author'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    onChange={handleChange}
                    value={values.author || ''}
                  />
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Voice Artist
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='voiceArtist'
                    id='voiceArtist'
                    autoComplete='voiceArtist'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    onChange={handleChange}
                    value={values.voiceArtist || ''}
                  />
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='introUiText' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Intro UI Text
                </label>
                <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                  This information will be displayed publicly so be careful what you share
                </p>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <textarea
                  id='introUiText'
                  name='introUiText'
                  rows={3}
                  className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  onChange={handleChange}
                  value={values.introUiText || ''}
                />
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='footerUiText' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Footer UI Text
                </label>
                <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                  This information will be displayed publicly so be careful what you share
                </p>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <textarea
                  id='footerUiText'
                  name='footerUiText'
                  rows={3}
                  className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  onChange={handleChange}
                  value={values.footerUiText || ''}
                />
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                Icons
              </label>
              <div className='flex  justify-evenly'>
                <div className='flex items-center justify-center flex-col '>
                  <p className='text-sm text-gray-600 mb-2'>Normal</p>
                  <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100 hover:cursor-pointer'>
                    {values?.icon?.url ? (
                      <img className='h-full w-full' src={values.icon.url} alt='icon' />
                    ) : (
                      <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                        <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                      </svg>
                    )}
                  </span>
                  <div className='flex text-sm text-gray-600 mt-3'>
                    <label
                      htmlFor='icon'
                      className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                    >
                      <span>Upload a file</span>
                      <input
                        id='icon'
                        name='icon'
                        type='file'
                        accept='image/*'
                        className='sr-only'
                        onChange={(e) => {
                          const file = e.target?.files?.item(0);
                          if (file) {
                            const url = URL.createObjectURL(file);
                            setValues({
                              ...values,
                              icon: {
                                url: url,
                                file: file,
                              },
                            });
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className='flex items-center justify-center flex-col '>
                  <p className='text-sm text-gray-600 mb-2'>Grayed</p>
                  <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100 hover:cursor-pointer'>
                    {values?.grayedIcon?.url ? (
                      <img className='h-full w-full' src={values.grayedIcon.url} alt='icon' />
                    ) : (
                      <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                        <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                      </svg>
                    )}
                  </span>
                  <div className='flex text-sm text-gray-600 mt-3'>
                    <label
                      htmlFor='grayIcon'
                      className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                    >
                      <span>Upload a file</span>
                      <input
                        id='grayIcon'
                        name='grayIcon'
                        type='file'
                        accept='image/*'
                        className='sr-only'
                        onChange={(e) => {
                          const file = e.target?.files?.item(0);
                          if (file) {
                            const url = URL.createObjectURL(file);
                            setValues({
                              ...values,
                              grayedIcon: {
                                url: url,
                                file: file,
                              },
                            });
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Tags:
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <Select
                  options={tags || []}
                  isMulti={true}
                  value={values.tags}
                  onChange={(vals) => {
                    setFieldValue('tags', vals as ReactSelectOptions[]);
                  }}
                  className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                />
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
            <div>
              <label htmlFor='homeworkAssigned' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Homework
              </label>
            </div>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='homeworkAssigned'
                  id='homeworkAssigned'
                  autoComplete='homeworkAssigned'
                  placeholder='i.e. Microhit: 0, Formal: 0, Background: 0'
                  className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                  onChange={handleChange}
                  value={values.homeworkAssigned || ''}
                />
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
            <div>
              <label htmlFor='homeworkAssigned' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Homework Response Questions
              </label>
            </div>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <label htmlFor='homeworkAssigned' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Done
              </label>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='homeworkDoneQuestion'
                  id='homeworkDoneQuestion'
                  autoComplete='homeworkDoneQuestion'
                  // placeholder='i.e. Microhit: 0, Formal: 0, Background: 0'
                  className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                  onChange={handleChange}
                  value={values.homeworkDoneQuestion || ''}
                />
              </div>
              <label htmlFor='homeworkAssigned' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Not Done
              </label>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='homeworkNotDoneQuestion'
                  id='homeworkNotDoneQuestion'
                  autoComplete='homeworkNotDoneQuestion'
                  // placeholder=''
                  className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                  onChange={handleChange}
                  value={values.homeworkNotDoneQuestion || ''}
                />
              </div>
            </div>
          </div>
          {!values.importedFromGDocID?.length ? null : (
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='importedFromGDocID' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Google Doc ID
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='importedFromGDocID'
                    id='importedFromGDocID'
                    autoComplete='importedFromGDocID'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    value={values.importedFromGDocID}
                    onChange={handleChange}
                  />
                  {!emptyStringToNull(values.importedFromGDocID) ? null : (
                    <a
                      href={`https://docs.google.com/document/d/${values.importedFromGDocID}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {' '}
                      <Icon path={mdiOpenInNew} size={1} style={{ display: 'inline-block' }} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
            <div>
              <label htmlFor='bulkAudio' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Bulk Audio Upload
              </label>
            </div>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm' style={{ display: 'block' }}>
                <div className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  <p>
                    Note: This feature is experimental. Files must be M4A files, and must be named
                    <strong> exactly </strong>with the &apos;code&apos; of the Audio Node they&apos;ll be attached to
                    (i.e. a file for a node with a code of &apos;TPS1&apos; needs to be named &apos;TPS1.m4a&apos;). It
                    will update the Node with a duration measured from each audio file, as well as the Internet-facing
                    download URL.
                  </p>
                </div>
                <br />
                <FileUploader
                  handleChange={handleUploadDrop}
                  id={'bulkAudio'}
                  name='file'
                  types={['m4a', 'M4A']}
                  multiple={true}
                  hoverTitle={''}
                  maxSize={10}
                  minSize={0}
                  label={'Select or drag one or more audio files here.'}
                />
              </div>
            </div>
          </div>

          <div className='pt-5 pb-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                disabled={isSubmitting}
                type='submit'
                className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>

      <LessonChapterList lesson={lesson} locked={lesson?.productionStatus === 'PROD'} />
    </div>
  );
};

export default LessonForm;
