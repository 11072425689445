// *******************************************************
// Company Form
// -------------------------------------------------------
// This is a Component for Editing / adding company
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// *******************************************
// Component Imports
// -------------------------------------------
// *******************************************
// Hooks Import
// -------------------------------------------
import { useAppContext } from '../contexts/appContext';
// *******************************************
// Action Imports
// -------------------------------------------
import { getSessionById } from '../collections/session';
import { getRespirationDataById } from 'src/collections/respirationData';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLine } from 'victory';

// *******************************************
// Styles Imports
// -------------------------------------------
// *******************************************
// Constants
// -------------------------------------------
const MEDITATION_MAX = 9; // 11.71;
const MEDITATION_MIN = 3; // 5;
const GLOBAL_AVERAGE_BREATH = 16.52;

// *******************************************
// Types
// -------------------------------------------

const RawData = () => {
  console.log('inside RawData');
  const { sessionId, respirationId } = useParams();
  const { setBreadcrumbPaths } = useAppContext();

  const [session, setSession] = useState<any | null>(null);
  const [respirationData, setRespirationData] = useState<any | null>(null);
  const [loading, setIsLoading] = useState<boolean>(true);
  console.log('hello world from raw data session', session);
  console.log('hello world from raw data respirationId', respirationId);
  //  const yMax= Math.max(...respirationData?.data.map((i) => i.y), GLOBAL_AVERAGE_BREATH) + 1
  // const xMax = Math.max(...respirationData.data.map((i) => i.x), 19) + 1;
  // const xMin = Math.min(...respirationData.data.map((i) => i.x));
  console.log('GLOBAL_AVERAGE_BREATH: ', GLOBAL_AVERAGE_BREATH);

  const yMax = 20;
  const xMax = 620;
  const xMin = 4;

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Science Lovers',
        path: '/science',
      },

      {
        label: 'Raw Data',
        path: '/rawData',
      },
    ]);
  }, [setBreadcrumbPaths]);

  useEffect(() => {
    const prepareRespirationData = async () => {
      const thisSession = await getSessionById(sessionId);
      const thisRespiration = await getRespirationDataById(respirationId);
      console.log('thisRespiration', thisRespiration);
      setRespirationData(thisRespiration);
      setSession(thisSession);
    };

    prepareRespirationData()
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
      <div className=' sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
        <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
          Session Data
        </label>
        {loading ? <p>Loading</p> : <p>{session ? JSON.stringify(session) : 'No Data'}</p>}
      </div>
      <div className=' sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
        <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
          Respiration Data
        </label>
        {loading ? <p>Loading</p> : <p>{respirationData ? JSON.stringify(respirationData) : 'No Data'}</p>}
      </div>
      <div>
        <div>
          <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
            {`Breaths Per Minute ${respirationData?.breathRate ? respirationData?.breathRate.toFixed(1) : '---'}`}
          </label>
          <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
            {`Zone Minutes ${respirationData?.zoneMinutes}`}
          </label>

          <VictoryChart
            maxDomain={{
              y: yMax,
              x: xMax,
            }}
            minDomain={{ y: 0, x: xMin }}
            padding={{ left: 25, top: 50, right: 50, bottom: 50 }}
            animate
          >
            {/* Remove the global average breath line for now
          <VictoryLine
            style={{ data: { stroke: "blue", strokeDasharray: 10 } }}
            data={line}
            // y={() => GLOBAL_AVERAGE_BREATH}
          /> */}

            <VictoryArea
              y={() => MEDITATION_MAX}
              y0={() => MEDITATION_MIN}
              style={{ data: { fill: '#D6D6D6', opacity: 0.4 } }}
            />

            <VictoryArea
              y={() => MEDITATION_MAX}
              y0={() => MEDITATION_MIN}
              style={{ data: { fill: '#D8D8', opacity: 0.4 } }}
            />
            {respirationData?.breakPoints.intro > 0 && (
              <VictoryArea
                labels={['Intro']}
                x={() => respirationData?.breakPoints.intro / 2}
                style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
              />
            )}
            {respirationData?.breakPoints.intro > 0 && (
              <VictoryArea
                x={() => respirationData?.breakPoints.intro}
                y={() => 1}
                style={{ data: { fill: '#D6D6D6' }, labels: { fill: '#D6D6D6' } }}
              />
            )}
            {respirationData?.breakPoints.meditation > 0 && (
              <VictoryArea
                labels={['Meditation']}
                x={() =>
                  (respirationData?.breakPoints.meditation - respirationData?.breakPoints.intro) / 2 +
                  respirationData?.breakPoints.intro
                }
                style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
              />
            )}
            {respirationData?.breakPoints.meditation > 0 && (
              <VictoryArea
                x={() => respirationData?.breakPoints.meditation}
                y={() => 1}
                style={{ data: { fill: '#D6D6D6' }, labels: { fill: '#D6D6D6' } }}
              />
            )}
            {respirationData?.breakPoints.practice > 0 ? (
              <VictoryArea
                labels={['Interaction']}
                x={() =>
                  (respirationData?.breakPoints.practice - respirationData?.breakPoints.meditation) / 2 +
                  respirationData?.breakPoints.meditation
                }
                style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
              />
            ) : respirationData?.breakPoints.meditation > 0 ? (
              <VictoryArea
                labels={['Interaction']}
                x={() =>
                  (respirationData?.data.length - respirationData?.breakPoints.meditation) / 2 +
                  respirationData?.breakPoints.meditation
                }
                style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
              />
            ) : null}
            <VictoryLine
              data={respirationData?.data?.filter(
                ({ x, y }: { x: number; y: number }) => x !== null && x !== undefined && y,
              )}
              style={{ data: { stroke: '#000000', strokeWidth: 1 } }}
              interpolation={'natural'}
            />
            <VictoryAxis
              dependentAxis
              style={{
                axis: { stroke: '#000000' },
                tickLabels: { fill: '#000000' },
              }}
            />
            <VictoryAxis
              style={{
                axis: { stroke: '#000000' },
                tickLabels: { fill: '#000000' },
              }}
              tickValues={[60, 120, 180, 240, 300, 360, 420, 480, 540, 600]}
            />
          </VictoryChart>
          {/* </div>

      <div style={{ alignItems: 'center', height: 100, justifyContent: 'space-evenly' }}>
        <div style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
          <DashedLine spacing={16} color={colors.blue} width={70} height={14} />
          <label style={{ width: 177, color: colors.light }}>Global Average Breath Rate</label>
        </div>
        <div style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center' }}>
          <div style={{ width: 64, height: 2, backgroundColor: 'blue' }} />
          <label style={{ width: 177, color: colors.light }}>Your Breath Rate</label>
        </div>
        <div style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
          <div style={{ width: 64, backgroundColor: colors.gray50, opacity: 0.4 }} />
          <label style={{ width: 177, color: colors.light }}>Ideal Meditation Zone</label>
        </div>
      </div>
    </div> */}
        </div>
      </div>
    </div>
  );
};

export default RawData;
