import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { USER_JOURNEY_TABLE_NAME } from './tableName';
import {
  UserJourneyType,
  UserJourneyTypeBase,
  UserJourneyTypeBuilder,
  UserJourneyTypeNew,
  UserJourneyTypeUpdate,
} from '../modeltypes/userJourney';
import { UserJourneyId } from '../modeltypes/id';

// noinspection JSUnusedGlobalSymbols
export const getNewUserJourneyId = getNewIdFor<UserJourneyId>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewUserJourney = addNewFor<UserJourneyId, UserJourneyTypeNew>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewUserJourney = createNewFor<UserJourneyId, UserJourneyTypeBuilder>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewUserJourney = setNewFor<UserJourneyId, UserJourneyTypeNew>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserJourneyRefById = getRefByIdFor<UserJourneyId>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserJourneyById = getByIdFor<UserJourneyId, UserJourneyType>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserJourneysByIdArray = getByIdArrayFor<UserJourneyId, UserJourneyType>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllUserJourneys = getAllFor<UserJourneyType>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserJourneysByQuery = getByQueryFor<UserJourneyType>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllUserJourneysQuery = getAllQueryFor(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateUserJourney = updateDocumentFor<UserJourneyId, UserJourneyTypeUpdate>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteUserJourney = deleteDocumentFor<UserJourneyId>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchUserJourneyIdSet = watchIdSetFor<UserJourneyId>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserJourneyType = getValidateTypeFor<UserJourneyType>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserJourneyTypeBase = getValidateTypeBaseFor<UserJourneyTypeBase>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserJourneyTypeBuilder =
  getValidateTypeBuilderFor<UserJourneyTypeBuilder>(USER_JOURNEY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserJourneyTypeNew = getValidateTypeNewFor<UserJourneyType>(USER_JOURNEY_TABLE_NAME);
