import { SCIENCE_DATA_TABLE_NAME } from './tableName';
import { ScienceDataId, UserId } from '../modeltypes/id';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import {
  ScienceDataType,
  ScienceDataTypeBase,
  ScienceDataTypeBuilder,
  ScienceDataTypeNew,
} from '../modeltypes/scienceData';
import { where } from 'firebase/firestore';
import { idToString } from 'src/utility/GeneralUtilities';

// noinspection JSUnusedGlobalSymbols
export const getNewScienceDataId = getNewIdFor<ScienceDataId>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewScienceData = addNewFor<ScienceDataId, ScienceDataTypeNew>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewScienceData = createNewFor<ScienceDataId, ScienceDataTypeBuilder>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getScienceDataRefById = getRefByIdFor<ScienceDataId>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getScienceDataById = getByIdFor<ScienceDataId, ScienceDataType>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getScienceDataByIdArray = getByIdArrayFor<ScienceDataId, ScienceDataType>(SCIENCE_DATA_TABLE_NAME);

export const getAllScienceData = getAllFor<ScienceDataType>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getScienceDataByQuery = getByQueryFor<ScienceDataType>(SCIENCE_DATA_TABLE_NAME);

export const getScienceDataByUserId = async (userId: UserId) => {
  const scienceData = await getScienceDataByQuery(where('user', '==', idToString(userId)));
  // console.log('returning scienceData: ', scienceData );
  return scienceData;
};

// noinspection JSUnusedGlobalSymbols
export const getAllScienceDataQuery = getAllQueryFor(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateScienceData = updateDocumentFor<ScienceDataId, ScienceDataTypeBase>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteScienceData = deleteDocumentFor<ScienceDataId>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchScienceDataIdSet = watchIdSetFor<ScienceDataId>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateScienceDataType = getValidateTypeFor<ScienceDataType>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateScienceDataTypeBase = getValidateTypeBaseFor<ScienceDataTypeBase>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateScienceDataTypeBuilder =
  getValidateTypeBuilderFor<ScienceDataTypeBuilder>(SCIENCE_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateScienceDataTypeNew = getValidateTypeNewFor<ScienceDataType>(SCIENCE_DATA_TABLE_NAME);
