// *******************************************************
// Month on Month Analysis
// -------------------------------------------------------
// This is a Screen for showing list all Statistics for month to month
// Mat wants the following fds
// Downloads
// Subscriptions
// Active Users
// Average time in lesson per user

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useMemo, useState } from 'react';
import { AnalyticsType } from 'src/modeltypes/analytics';
import { format } from 'date-fns';
import BarChart from 'src/components/BarChart';
import colors from 'src/assets/colors';
import Select from 'react-select';
import { AnalysisTypeSelector } from './Home';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

const MonthonMonthAnalysis = ({ analytics }: { analytics: AnalyticsType[] | undefined }) => {
  console.log('MonthonMonthAnalysis analytics: ', analytics);

  // Chart Details
  const chartDetails = [
    {
      chart: 'totalUsers',
      title: 'Total Users',
      xLabel: 'Month',
      yLabel: 'User Count',
      color: colors.primary,
    },
    {
      chart: 'subscriptions',
      title: 'Subscriptions',
      xLabel: 'Month',
      yLabel: 'User Count',
      color: colors.secondary,
    },
    {
      chart: 'averageTimeInLessonsPerUser',
      title: 'Average Time In Lessons Per User',
      xLabel: 'Month',
      yLabel: 'Minutes',
      color: colors.yellow,
    },
    {
      chart: 'expiredSubscriptions',
      title: 'Expirations per Month',
      xLabel: 'Month',
      yLabel: 'User Count',
      color: colors.primary,
    },

    {
      chart: 'churn',
      title: 'Churn Rate ',
      xLabel: 'Month',
      yLabel: 'Percentage',
      color: colors.yellow,
    },

    {
      chart: 'activeMonthlyUsers',
      title: 'Monthly Active Users ',
      xLabel: 'Month',
      yLabel: 'userCount',
      color: colors.yellow,
    },
  ];

  // Put in a selector just in case you want to chage to subscription, cohort, etc.
  const [currentMonthType, setCurrentMonthType] = useState('ALL');

  const monthlyBreakdownTotalValues = useMemo(() => {
    let thisTotalKey: any;

    if (currentMonthType === 'ALL') {
      thisTotalKey = 'allUsersTotal';
    }
    if (currentMonthType === 'B2C') {
      thisTotalKey = 'b2cTotal';
    }
    if (currentMonthType === 'COMPANYS') {
      thisTotalKey = 'companyTotal';
    }

    if (currentMonthType === 'COHORTS') {
      thisTotalKey = 'cohortTotal';
    }
    if (currentMonthType === 'SUBSCRIPTIONS') {
      thisTotalKey = 'subscriptionsTotal';
    }

    if (analytics?.length) {
      // FIRST WE ARE GOING TO GET ALL DAILY DATA...
      const dailyData = analytics
        ?.filter((analytic) => analytic?.type === 'TODAY')
        .sort((a: any, b: any) => {
          const thisA = a.createdAt.toDate() || 0;
          const thisB = b.createdAt.toDate() || 0;
          return thisB - thisA;
        });

      const totalData = analytics
        ?.filter((analytic) => analytic?.type === 'TOTAL')
        .sort((a: any, b: any) => {
          const thisA = a.createdAt.toDate() || 0;
          const thisB = b.createdAt.toDate() || 0;
          return thisB - thisA;
        });

      const monthsDaily = dailyData?.reduce((monthMap: { [month: string]: any[] }, data) => {
        const date = data.createdAt.toDate();
        const thisMonth = date.getMonth();
        const thisYear = date.getFullYear();

        const thisKey = `${thisYear}-${thisMonth}`;

        (monthMap[thisKey] = monthMap[thisKey] || []).push(data);
        return monthMap;
      }, {});

      const monthsTotal = totalData?.reduce((monthMap: { [month: string]: any[] }, data) => {
        const date = data.createdAt.toDate();
        const thisMonth = date.getMonth();
        const thisYear = date.getFullYear();

        const thisKey = `${thisYear}-${thisMonth}`;

        (monthMap[thisKey] = monthMap[thisKey] || []).push(data);
        return monthMap;
      }, {});

      const desiredKeys = Object.keys(monthsDaily);

      const derivedData: any[] = [];

      desiredKeys.forEach((thisMonthString) => {
        console.log('thisMonthString: ', thisMonthString);
        const dailyMonthData = monthsDaily?.[thisMonthString];
        const totalMonthData = monthsTotal?.[thisMonthString];

        console.log('dailyMonthData: ', dailyMonthData);

        const totalUserData = totalMonthData?.map((data) => data?.[thisTotalKey]);

        const date = totalMonthData?.[totalMonthData.length - 1].createdAt.toDate();
        const thisMonth = date.getMonth();
        const thisYear = date.getFullYear();
        const startDate = totalMonthData?.[totalMonthData.length - 1].createdAt.toDate();
        const endDate = totalMonthData?.[0].createdAt.toDate();

        const LessonsPerUser = totalUserData.reduce(
          (sum: number, { averageLessonsPerUser }: { averageLessonsPerUser: number }) => sum + averageLessonsPerUser,
          0,
        );

        const averageLessonsPerUser = LessonsPerUser / totalUserData.length;

        const timeInLessonsPerUser = totalUserData.reduce(
          (sum: number, { averageTimeInLessonsPerUser }: { averageTimeInLessonsPerUser: number }) =>
            sum + averageTimeInLessonsPerUser,
          0,
        );

        const averageTimeInLessonsPerUser = timeInLessonsPerUser / totalUserData.length;

        const firstNewUsers = totalUserData?.[totalUserData.length - 1]?.totalUsers;
        const lastNewUsers = totalUserData?.[0]?.totalUsers;

        const newUsers = lastNewUsers - firstNewUsers;

        const firstExpiredSubscription = totalUserData?.[totalUserData.length - 1]?.expiredSubscriptionAccounts;
        const lastExpiredSubscription = totalUserData?.[0]?.expiredSubscriptionAccounts;

        const expiredSubscriptions = lastExpiredSubscription - firstExpiredSubscription;

        const firstSubscription = totalUserData?.[totalUserData.length - 1]?.subscriptionAccounts;
        const lastSubscription = totalUserData?.[0]?.subscriptionAccounts;

        const lastMonthlyActiveAccount = totalUserData?.[0]?.monthlyActiveUsers;

        const totalSubscriptions = lastSubscription - firstSubscription;

        const totalUsers = totalUserData?.[totalUserData.length - 1]?.totalUsers;

        //FROM https://blog.hubspot.com/service/what-is-churn-rate...
        //Customer Churn Rate = (Lost Customers ÷ Total Customers at the Start of Time Period) x 100

        const churn = !expiredSubscriptions
          ? 0
          : !totalSubscriptions
          ? 0
          : (expiredSubscriptions / totalSubscriptions) * 100;

        console.log('churn totalSubscriptions', totalSubscriptions);
        console.log('churn expiredSubscriptions', expiredSubscriptions);
        // console.log(totalStartDate, ' churn : ', churn);

        // Downloads
        // Subscriptions
        // Active Users
        // Average time in lesson per user

        const derivedTotalData = {
          month: thisMonth,
          year: thisYear,
          id: thisMonthString,
          startDate,
          endDate,
          monthString: format(date, 'MMM'),
          newUsers,
          expiredSubscriptions,
          averageLessonsPerUser,
          averageTimeInLessonsPerUser,
          subscriptions: totalSubscriptions,
          totalUsers,
          activeMonthlyUsers: lastMonthlyActiveAccount,
          churn,
        };

        derivedData.push({ ...derivedTotalData });
      });

      console.log('derivedData: ', derivedData);

      return derivedData;
    }

    return null;
  }, [analytics, currentMonthType]);

  const orderedData = monthlyBreakdownTotalValues?.sort((a: any, b: any) => {
    const thisA = a.startDate || 0;
    const thisB = b.startDate || 0;
    return thisA - thisB;
  });

  const keysWeAreGoingToChart = [
    'totalUsers',
    'activeMonthlyUsers',
    'averageLessonsPerUser',
    'averageTimeInLessonsPerUser',
    'subscriptions',
    'expiredSubscriptions',
    'churn',
  ];

  console.log('monthlyBreakdownTotalValues : ', monthlyBreakdownTotalValues);

  return (
    <>
      <div className='flex'>MONTH ON MONTH</div>
      <Select
        options={AnalysisTypeSelector}
        value={AnalysisTypeSelector.find((e) => currentMonthType === e.value)}
        onChange={(option) => {
          setCurrentMonthType(option?.value || 'ALL');
        }}
        className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
      />

      {keysWeAreGoingToChart.map((thisKey) => {
        const thisChartDetails = chartDetails.find((x) => x.chart === thisKey);

        const thisKeyChartData =
          orderedData?.map((oD) => {
            const thisValue = oD?.[thisKey];
            const thisLabel = `${oD?.monthString} ${oD?.year}`;

            return {
              x: thisLabel,
              y: thisValue,
            };
          }) || [];

        const tickValues = thisKeyChartData.map((z) => z.y);
        console.log('tickValues', tickValues);

        return (
          <div key={thisKey}>
            <BarChart
              chartData={[
                {
                  data: thisKeyChartData,
                  fill: thisChartDetails?.color,
                  name: thisKey,
                },
              ]}
              chartTitle={thisChartDetails?.title || thisKey}
              tickValues={tickValues}
              verticalLabel
              barWidth={10}
              domainPadding={10}
              tooltipsEnabled
              xLabel={thisChartDetails?.xLabel || 'Month'}
              yLabel={thisChartDetails?.yLabel || 'User Count'}
            />
          </div>
        );
      })}
    </>
  );
};

export default MonthonMonthAnalysis;
