// *******************************************************
// AddUserModal
// -------------------------------------------------------
// This is a AddUserModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useState } from 'react';
import { CohortType } from '../../modeltypes/cohort';
import { toast } from 'react-toastify';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
type UserTableData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

interface AddUsersToCohortModalProps {
  hide: () => void;
  users: UserTableData[];
  cohorts: CohortType[];
  addUsersToCohort: (userIds: string[], cohortId: string) => Promise<void>;
}

const AddUsersToCohortModal = ({ hide, users, cohorts, addUsersToCohort }: AddUsersToCohortModalProps) => {
  const [isPending, setIsPending] = useState(false);
  const [selectedCohortId, setSelectedCohortId] = useState<string | null>(null);

  const callAddUsers = async () => {
    if (selectedCohortId) {
      setIsPending(true);
      await toast.promise(
        addUsersToCohort(
          users.map((el) => el.id),
          selectedCohortId,
        ),
        {
          pending: 'Adding users to Cohort',
          error: 'Something went wrong, try again ...',
          success: 'Successfully added users to cohort!',
        },
      );
      hide();
    }
  };

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
      onClick={() => hide()}
    >
      <div className='relative p-4 w-full max-w-3xl h-full md:max-h-[100px]' onClick={(e) => e.stopPropagation()}>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Add chosen users to cohort</h3>
            <button
              type='button'
              className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
              onClick={hide}
            >
              <svg
                aria-hidden='true'
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
              <span className='sr-only'>Close modal</span>
            </button>
          </div>
          <div className='p-6 space-y-6'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='relative w-full text-gray-400 focus-within:text-gray-600 mb-5 md:rounded-lg'>
                <select onChange={(x) => setSelectedCohortId(x.target.value)}>
                  <option value={''}>Select cohort</option>
                  <option value={'remove'}>Remove from cohort</option>
                  <option disabled>---</option>

                  {cohorts.map((cohort) => (
                    <option value={cohort.id} key={cohort.id}>
                      {cohort.name}
                    </option>
                  ))}
                </select>
                <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-y-auto mt-5'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-white'>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                        >
                          <p className='group inline-flex'>First Name</p>
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                        >
                          <p className='group inline-flex'>Last Name</p>
                        </th>

                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                        >
                          <p className='group inline-flex'>Email</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      {users.map((user) => (
                        <tr key={user.id} className='hover:bg-stone-200'>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='font-medium text-gray-900'>{user.firstName}</div>
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='font-medium text-gray-900'>{user.lastName}</div>
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='font-medium text-gray-900'>{user.email}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600'>
            <button
              disabled={isPending || !selectedCohortId || selectedCohortId.length < 0}
              onClick={callAddUsers}
              type='button'
              className={`${
                !selectedCohortId || selectedCohortId.length < 0
                  ? 'opacity-25'
                  : 'hover:bg-blue-800 dark:hover:bg-blue-700'
              } w-full text-white bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600  dark:focus:ring-blue-800`}
            >
              {isPending ? 'Adding...' : 'Add'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsersToCohortModal;
