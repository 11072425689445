import React, { useEffect } from 'react';
import TrackList from '../../components/tracks/TrackList';
import { useAppContext } from '../../contexts/appContext';
import { useNavigate } from 'react-router-dom';

const CoachesPage = () => {
  const navigate = useNavigate();
  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([{ label: 'Tracks', path: '/tracks' }]);
  }, [setBreadcrumbPaths]);
  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Tracks</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            onClick={() => navigate('/tracks/new')}
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Add Track
          </button>
        </div>
      </div>
      <h2 className='text-l font-semibold text-gray-900 mt-5'>Available Tracks</h2>
      <TrackList />
      <h2 className='text-l font-semibold text-gray-900 mt-10'>Archived Tracks</h2>
      <TrackList showArchived={true} />
    </>
  );
};

export default CoachesPage;
