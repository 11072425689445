import { collection, DocumentReference, getDoc, getDocs, query, where } from '../models/dalaccess';
import { DailyDiaryType } from '../modeltypes/dailyDiary';
import { UserId } from '../modeltypes/id';
import { prepareChunks } from './shared';
import { SURVEY_TABLE_NAME } from './tableName';

export const getDailyDiaryWithRef = async (ref: DocumentReference): Promise<DailyDiaryType | null> => {
  const dailyDiary = await getDoc(ref);

  if (!dailyDiary.exists()) {
    return null;
  }
  return {
    ...(dailyDiary.data() as DailyDiaryType),
    id: dailyDiary.id,
  };
};

export async function getDailyDiaryForArray(userIdList: UserId[]) {
  const chunks = prepareChunks(userIdList);
  const dds = chunks.map(async (chunkOfIds) => {
    const q = query(collection(SURVEY_TABLE_NAME), where('user', 'in', chunkOfIds), where('type', '==', 'DAILY_DIARY'));
    const ddDocs = await getDocs(q);

    const diaries: DailyDiaryType[] = [];

    if (ddDocs.empty) {
      return diaries;
    }
    ddDocs.forEach((doc) => {
      const data = doc.data() as DailyDiaryType;
      data.id = doc.id;
      diaries.push({ ...data });
    });

    return diaries;
  });
  return Promise.all(dds);
}
