import { doc, getDoc } from '../models/dalaccess';
import { ADMIN_PAGE_TABLE_NAME } from './tableName';
import { IndustryAveragesType, IndustryAveragesTypeBase } from '../modeltypes/industryAverage';
import { validateAgainstSchema } from './shared';
import { industryAverageTypeBaseSchema, industryAverageTypeSchema } from '../modelschemas/industryAverage';

// This one is a little unusual. It refers to a single document with in the AdminPage
// collection, and uses a human-readable/semantic ID. This means the standard patterns
// for data access don't really apply. This almost feels like configuration data, but...

const INDUSTRY_AVERAGES_DOC_ID = 'industry_averages';

export function validateIndustryAverages(
  data: IndustryAveragesType,
  throwOnError = true,
  logOnError = true,
): IndustryAveragesType {
  const rv = validateAgainstSchema(INDUSTRY_AVERAGES_DOC_ID, data, industryAverageTypeSchema, throwOnError, logOnError);
  return rv;
}

// noinspection JSUnusedGlobalSymbols
export function validateIndustryAveragesBase(
  data: IndustryAveragesTypeBase,
  throwOnError = true,
  logOnError = true,
): IndustryAveragesTypeBase {
  const rv = validateAgainstSchema(
    INDUSTRY_AVERAGES_DOC_ID,
    data,
    industryAverageTypeBaseSchema,
    throwOnError,
    logOnError,
  );
  return rv;
}

export const getIndustryAverages = async (): Promise<IndustryAveragesType> => {
  const docRef = doc(ADMIN_PAGE_TABLE_NAME, INDUSTRY_AVERAGES_DOC_ID);
  const docSnap = await getDoc(docRef);
  const rv = validateIndustryAverages(docSnap.data() as IndustryAveragesType, false, true);
  return rv;
};
