import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  appId,
  authDomain,
  firebaseApiKey,
  measurementId,
  messagingSenderId,
  projectId,
  storageBucket,
} from '../constants';
import { getStorage } from 'firebase/storage';
import { TotalDataType } from '../modeltypes/analytics';

export const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
};
export const firebaseApp = initializeApp(firebaseConfig);

export const firestore = getFirestore(firebaseApp);

export const auth = getAuth();
export const storage = getStorage();

export const functions = getFunctions();
// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }
export const getUserLastLoginDate = httpsCallable<
  { ids: { uid: string }[] },
  { dates: { uid: string; lastLogin: string }[] }
>(functions, 'getUserLastLoginDate');

export const getUserClaims = httpsCallable<
  { ids: { uid: string }[] },
  { claims: { uid: string; claims: { [p: string]: unknown } }[] }
>(functions, 'getUserClaims');

export const setUserClaim = httpsCallable<{ id: string; claimTitle: string; claimValue: unknown }, string>(
  functions,
  'setUserClaim',
);

export const setUserEmail = httpsCallable<{ id: string; email: string }, string>(functions, 'setUserEmail');

export const createTokenWithUID = httpsCallable<{ uid: string }, { token: string }>(functions, 'createTokenWithUID');

export type CohortStats = {
  insights: any;
  positiveChartData: any;
  negativeChartData: any;
  allSessionsChartData: any;
  usersPerDay: any;
  weekSessionsChartData: any;
  skillsPerLessons: any;
  dailyPerLessons: any;
  ratingsPerLesson: any;
  usersProgramProgress: any;
  error: string | null;
};
export const getCohortStats = httpsCallable<{ cohortId: string }, CohortStats>(functions, 'getCohortStats');

export const getCompanyStats = httpsCallable<{ companyId: string }, CohortStats>(functions, 'getCompanyStats');

export const getAnalyticsCalculations = httpsCallable(functions, 'calculateAnalytics');

export const getAnalyticsDifferenceBetweenDates = httpsCallable<
  { type: string; startDate: number; endDate: number },
  TotalDataType & { error?: string }
>(functions, 'getAnalyticsDifferenceBetweenDates');

export const getMonthToMonthAnalyticsFromDb = httpsCallable<
  { startDate: number; endDate: number },
  TotalDataType & { error?: string }
>(functions, 'getMonthToMonthAnalyticsFromDb');
