// noinspection JSUnusedGlobalSymbols

import { z } from 'zod';

export const accessIdSchema = z.string();
export const accessCodeIdSchema = z.string();
export const accessRequestIdSchema = z.string();
export const accountIdSchema = z.string();
export const adminPageIdSchema = z.string();
export const afterSessionSurveyIdSchema = z.string();
export const audioNodeIdSchema = z.string();
export const audioRecordingWhitelistIdSchema = z.string();
export const chapterIdSchema = z.string();
export const coachIdSchema = z.string();
export const cohortIdSchema = z.string();
export const cohortMeetingIdSchema = z.string();
export const companyIdSchema = z.string();
export const dailyDiaryIdSchema = z.string();
export const incentiveTemplateIdSchema = z.string();
export const lessonIdSchema = z.string();
export const lessonHomeworkIdSchema = z.string();
export const lessonProgressTemplateIdSchema = z.string();
export const lessonRatingIdSchema = z.string();
export const notificationTemplateIdSchema = z.string();
export const programIdSchema = z.string();
export const remoteConfigIdSchema = z.string();
export const scienceDataIdSchema = z.string();
export const sessionIdSchema = z.string();
export const statisticsDataIdSchema = z.string();
export const surveyIdSchema = z.string();
export const userIdSchema = z.string();
export const mailIdSchema = z.string();
export const communicationIdSchema = z.string();
