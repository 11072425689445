import { where } from '../models/dalaccess';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { COMMUNICATIONS_TABLE_NAME } from './tableName';
import { CoachId, CommunicationId, UserId } from '../modeltypes/id';
import {
  CommunicationType,
  CommunicationTypeBase,
  CommunicationTypeBuilder,
  CommunicationTypeNew,
} from '../modeltypes/communication';
import { idToString } from '../utility/GeneralUtilities';

// noinspection JSUnusedGlobalSymbols
export const getNewCommunicationId = getNewIdFor<CommunicationId>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const _addNewCommunication = addNewFor<CommunicationId, CommunicationTypeNew>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewCommunication = createNewFor<CommunicationId, CommunicationTypeBuilder>(
  COMMUNICATIONS_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getCommunicationRefById = getRefByIdFor<CommunicationId>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCommunicationById = getByIdFor<CommunicationId, CommunicationType>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCommunicationsByIdArray = getByIdArrayFor<CommunicationId, CommunicationType>(
  COMMUNICATIONS_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllCommunications = getAllFor<CommunicationType>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCommunicationsQuery = getAllQueryFor(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCommunicationsByQuery = getByQueryFor<CommunicationType>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateCommunication = updateDocumentFor<CommunicationId, CommunicationTypeBase>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteCommunication = deleteDocumentFor<CommunicationId>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchCommunicationIdSet = watchIdSetFor<CommunicationId>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCommunicationType = getValidateTypeFor<CommunicationType>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCommunicationTypeBase = getValidateTypeBaseFor<CommunicationTypeBase>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCommunicationTypeBuilder =
  getValidateTypeBuilderFor<CommunicationTypeBuilder>(COMMUNICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCommunicationTypeNew = getValidateTypeNewFor<CommunicationTypeNew>(COMMUNICATIONS_TABLE_NAME);

export const getCommunicationsForCoach = async (coachId?: CoachId): Promise<CommunicationType[]> => {
  if (coachId) {
    const rv = await getCommunicationsByQuery(where('coach', 'array-contains', idToString(coachId)));
    return rv;
  }
  const rv = await getAllCommunications();
  return rv;
};

// Wrapping the standard method becuase of the nonstandard creationDate thing.
export const addNewCommunication = async (data: CommunicationTypeNew): Promise<string> => {
  return await _addNewCommunication({
    ...data,
    // We should standardize on createdAt/updatedAt.
    // creationDate: Timestamp.now(),
  });
};

// noinspection JSUnusedGlobalSymbols
export const checkIfIncentivesAlreadyAssigned = async (incentivesId: UserId) => {
  const docs = await getCommunicationsByQuery(where('incentives', '==', idToString(incentivesId)));
  return docs.length > 0;
};

// I'm leaving this, but this very likely doesn't work. Firestore does not allow you to query for the absence of
// a value. Null may be special, but I'm not sure.
export const getCommunicationsWithIncentives = async () => {
  return await getCommunicationsByQuery(where('incentives', '!=', null));
};
