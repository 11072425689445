// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/appContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

// *******************************************
// Component Imports
// -------------------------------------------
import { dieIfNullOrUndef, emptyCallback, nullToUndef } from '../../utility/GeneralUtilities';
import { uiFormatTimestamp, uiStatusString } from '../../collections/shared';
import { getLessonsByIdArray } from '../../collections/lesson';
import { uploadFile } from '../../firebase/storage';
import { LessonType } from '../../modeltypes/lesson';
import { LessonId } from '../../modeltypes/id';
import { UnitType, UnitTypeBuilder } from '../../modeltypes/unit';
import { addNewUnit, createNewUnit, getUnitById, updateUnit } from '../../collections/units';
import { ChallengeType } from '../../modeltypes/challenges';
import { getChallengesByIdArray } from '../../collections/challenges';
import { ReactSortable } from 'react-sortablejs';
import AddElementModal from '../../components/modals/AddElementModal';
import Select from 'react-select';
import { ReactSelectOptions } from '../../types/types';
import { getAllTags } from '../../collections/tags';
import { Pages } from '../../enums/pages';
import { toast } from 'react-toastify';
import { getTasksByIdArray } from '../../collections/tasks';
import { TaskType } from '../../modeltypes/tasks';
import { getQuizItemsByIdArray } from '../../collections/quizItems';
import { QuizType } from '../../modeltypes/quizItems';
import { getChallengeSwitchesByIdArray } from '../../collections/challengeswitches';
import { ChallengeSwitchType } from '../../modeltypes/challengeswitches';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { MobilePosition, mobilePositionOptions } from 'src/modeltypes/abstract';
import { getLessonAudioChunksByIdArray } from '../../collections/lessonAudioChunk';
import { LessonAudioChunkType } from '../../modeltypes/lessonAudioChunk';
import { Optional } from 'utility-types';
import { getLearningGamesByIdArray } from '../../collections/learningGames';
import { LearningGameType } from '../../modeltypes/learningGames';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
type ElementWithMetaDataType = (LessonType | ChallengeType | LessonAudioChunkType) & {
  order: number;
  pathType: string;
  position?: MobilePosition | null;
};

const ElementsListItem = ({
  element,
  onRemoveLessonClick,
  index,
  position,
  onElementUpdate,
}: {
  element: ElementWithMetaDataType;
  onRemoveLessonClick: (lid: LessonId) => void;
  onElementUpdate: (val: Optional<ElementWithMetaDataType>, el: ElementWithMetaDataType) => void;
  index: number;
  position?: MobilePosition | null;
}) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    switch (element.pathType) {
      case 'audioLesson':
        navigate('/' + Pages.Lessons + '/' + element.id);
        break;
      case 'challengeSwitch':
        navigate('/' + Pages.ChallengeSwitches + '/' + element.id);
        break;
      case 'task':
        navigate('/' + Pages.Tasks + '/' + element.id);
        break;
      case 'quiz':
        navigate('/' + Pages.QuizItems + '/' + element.id);
        break;
      case 'learningGame':
        navigate('/' + Pages.LearningGames + '/' + element.id);
        break;
      case 'challenge':
        navigate('/' + Pages.Challenges + '/' + element.id);
        break;
      case 'audioChunk':
      case 'practice':
        navigate('/' + Pages.LessonAudioChunks + '/' + element.id);
        break;
      default:
        console.log('dont know this pathType');
    }
  };

  const lessonId = dieIfNullOrUndef(element?.id);
  // const indexForSure: number = index !== null && index !== undefined ? index : -1;
  return !element ? null : (
    <div key={lessonId} className={'flex flex-row hover:bg-gray-50 hover:cursor-move'}>
      <div className='w-1/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex items-center '>
        {index + 1}
      </div>
      <div className='flex items-center w-2/12'>
        <Select
          options={mobilePositionOptions || []}
          value={mobilePositionOptions.find((e) => position === e.value) || null}
          onChange={(val) => {
            onElementUpdate({ position: (val?.value as MobilePosition) || MobilePosition.center }, element);
          }}
          className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
        />
      </div>

      <div className='w-4/12 whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer' onClick={() => navigateTo()}>
          {element.title || `(ID: ${lessonId})`}
        </div>
      </div>
      <div className='w-2/12 whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{element.pathType}</div>
      <div className='w-2/12 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
        {uiStatusString(element)}
      </div>
      <div className='w-2/12 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
        {['audioChunk', 'practice'].includes(element.pathType) ? (
          <input
            type={'checkbox'}
            checked={element.pathType === 'practice'}
            onChange={(e) => {
              onElementUpdate({ pathType: e.target.checked ? 'practice' : 'audioChunk' }, element);
            }}
          />
        ) : null}
      </div>
      <div className='w-2/12 relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
        <div
          className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer'
          onClick={() => onRemoveLessonClick(lessonId)}
        >
          Remove
        </div>
      </div>
    </div>
  );
};

const ElementsList = ({
  elements,
  onAddLessonClick,
  onRemoveLessonClick,
  setElements,
  onElementUpdate,
}: {
  elements: ElementWithMetaDataType[];
  setElements: (elements: ElementWithMetaDataType[]) => void;
  onAddLessonClick: () => void;
  onRemoveLessonClick: (lid: LessonId) => void;
  onElementUpdate: (val: Optional<ElementWithMetaDataType>, el: ElementWithMetaDataType) => void;
}) => {
  return !elements ? null : (
    <div className='sm:grid sm:items-start sm:pt-5'>
      <div className='flex items-center justify-between'>
        <label htmlFor='name' className='block text-lg font-medium text-gray-700'>
          Lessons and order
        </label>
        <div className='relative flex justify-evenly font-bold'>
          <button
            type={'button'}
            className={`ml-3 inline-flex justify-center py-2 px-4  border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            onClick={onAddLessonClick}
          >
            Add Existing Element
          </button>
        </div>
      </div>
      <div className='mt-8 flex flex-col '>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg pb-[130px]'>
              <div className='flex flex-row bg-white'>
                <div className='w-1/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                  Order
                </div>
                <div className='w-2/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                  Mobile Position
                </div>

                <div className='w-4/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>Title</div>
                <div className='w-2/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>Type</div>
                <div className='w-2/12 relative py-3.5 pl-3 pr-4 sm:pr-6 flex justify-evenly'>Status</div>
                <div className='w-2/12 relative py-3.5 pl-3 pr-4 sm:pr-6 flex justify-evenly'>Is practice?</div>
                <div className='w-2/12 relative py-3.5 pl-3 pr-4 sm:pr-6 flex justify-evenly' />
              </div>
              <ReactSortable
                list={elements}
                setList={setElements}
                sort={true}
                ghostClass={'lightBlueBackground'}
                // removeOnSpill={true} onSpill={(e) => console.log(e.item)}
              >
                {(elements || []).map((element, index) => {
                  return (
                    <ElementsListItem
                      index={index}
                      element={element}
                      key={element.id}
                      onRemoveLessonClick={onRemoveLessonClick}
                      position={element?.position || null}
                      onElementUpdate={onElementUpdate}
                    />
                  );
                })}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UnitForm = () => {
  const { setBreadcrumbPaths, setValidateElement } = useAppContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showAddElementModal, setShowAddElementModal] = useState(false);
  const [unit, setUnit] = useState<UnitType | undefined>(undefined);

  const [tags, setTags] = useState<ReactSelectOptions[] | null>(null);

  const [elements, setElements] = useState<ElementWithMetaDataType[]>([]);

  useEffect(() => {
    console.debug('UnitForm.useEffect - the first one - called.');
    getUnitById(id).then((fUnit) => setUnit(nullToUndef(fUnit)));
    getAllTags().then((fTags) =>
      setTags(
        fTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        })),
      ),
    );
  }, [id]);

  useEffect(() => {
    console.debug('ElementsList.useEffect called.');
    const fetchData = async () => {
      if (unit?.elements) {
        const elements = unit?.elements?.length ? unit.elements : [];
        const lessonIds = elements.filter((el) => el.pathType === 'audioLesson').map((el) => el.id);
        const challengesIds = elements.filter((el) => el.pathType === 'challenge').map((el) => el.id);
        const challengeSwitchesIds = elements.filter((el) => el.pathType === 'challengeSwitch').map((el) => el.id);
        const tasksIds = elements.filter((el) => el.pathType === 'task').map((el) => el.id);
        const quizIds = elements.filter((el) => el.pathType === 'quiz').map((el) => el.id);
        const learningGameIds = elements.filter((el) => el.pathType === 'learningGame').map((el) => el.id);

        const audioChunkIds = elements
          .filter((el) => ['practice', 'audioChunk'].includes(el.pathType))
          .map((el) => el.id);

        const fetchedLessons = lessonIds.length ? await getLessonsByIdArray(lessonIds) : [];
        const fetchedChallenges = challengesIds.length ? await getChallengesByIdArray(challengesIds) : [];
        const fetchedTasks = tasksIds.length ? await getTasksByIdArray(tasksIds) : [];
        const fetchedQuizItems = quizIds.length ? await getQuizItemsByIdArray(quizIds) : [];
        const fetchedLearningGames = learningGameIds.length ? await getLearningGamesByIdArray(learningGameIds) : [];
        const fetchedChallengeSwitches = challengeSwitchesIds.length
          ? await getChallengeSwitchesByIdArray(challengeSwitchesIds)
          : [];
        const fetchedAudioChunks = audioChunkIds.length ? await getLessonAudioChunksByIdArray(audioChunkIds) : [];

        const mergedElementsContent = [
          ...fetchedLessons,
          ...fetchedChallenges,
          ...fetchedTasks,
          ...fetchedQuizItems,
          ...fetchedChallengeSwitches,
          ...fetchedAudioChunks,
          ...fetchedLearningGames,
        ];

        const fElements = unit.elements.map((e) => ({
          ...e,
          ...(mergedElementsContent.find((ec) => ec.id === e.id) as
            | LessonType
            | ChallengeType
            | TaskType
            | QuizType
            | ChallengeSwitchType
            | LessonAudioChunkType
            | LearningGameType),
        }));

        setElements(fElements.sort((prev, next) => prev.order - next.order));
      }
    };

    // Might be nice to re-use the array, and avoid this fetch, but this is easier for now.
    // if (
    //   (elements.length === 0 && unit?.elements?.length) ||
    //   !entityArrayMatchesIdArrayExact(unit?.elements || [], elements)
    // ) {
    console.debug('ElementsList.useEffect.fetchData called.');
    fetchData().catch(console.error);
    // }
  }, [unit, unit?.elements]);

  const { handleSubmit, handleChange, values, setValues, errors, isSubmitting, setFieldValue } = useFormik<{
    title: string;
    description: string;
    productionStatus?: 'DEV' | 'PROD' | null;
    icon?: { file?: File; url?: string };
    grayedIcon?: { file?: File; url?: string };
    tags?: ReactSelectOptions[];
  }>({
    initialValues: {
      title: '',
      description: '',
      productionStatus: 'DEV',
    },
    onSubmit: async (values) => {
      const isNew = !unit;
      const unitToUpdate = unit ? unit : createNewUnit();
      const urls: { icon?: string; grayedIcon?: string } = {
        icon: undefined,
        grayedIcon: undefined,
      };
      if (
        unitToUpdate.grayedIcon !== values.grayedIcon?.url &&
        values.grayedIcon?.url !== '' &&
        values.grayedIcon?.file
      ) {
        urls.grayedIcon = await uploadFile(values.grayedIcon.file, 'grayIcon', [
          'assets',
          dieIfNullOrUndef(unitToUpdate.id),
        ]);
      }
      if (unitToUpdate.icon !== values.icon?.url && values.icon?.url !== '' && values.icon?.file) {
        urls.icon = await uploadFile(values.icon.file, 'icon', ['assets', dieIfNullOrUndef(unitToUpdate.id)]);
      }

      const update: UnitTypeBuilder = {
        id: dieIfNullOrUndef(unitToUpdate.id),
        title: values.title,
        description: values.description,
        productionStatus: values.productionStatus,
        tags: values.tags?.map((e) => e.value) || [],
      };

      if (urls.icon) {
        update.icon = urls.icon;
      }

      if (urls.grayedIcon) {
        update.grayedIcon = urls.grayedIcon;
      }

      if (isNew) {
        await toast.promise(
          () =>
            addNewUnit({
              ...update,
              elements: [],
              tags: values.tags?.map((e) => e.value) || [],
            }),
          {
            pending: `Creating ${update.title} Unit...`,
            error: "Can't do it now, try again.",
            success: `Created ${update.title} Unit!`,
          },
        );
      } else {
        await toast.promise(
          () =>
            updateUnit(update.id, {
              ...update,
              elements: elements.map((el, index) => ({
                id: el.id,
                pathType: el.pathType,
                order: index,
                position: el?.position || null,
              })),
            }),
          {
            pending: `Updating ${update.title} Unit...`,
            error: "Can't do it now, try again.",
            success: `Updated ${update.title} Unit!`,
          },
        );
      }
      const updatedUnit = (await getUnitById(unitToUpdate.id)) as UnitType;
      setUnit(updatedUnit);
      // navigate('/units');
    },
  });

  useEffect(() => {
    console.debug('UnitForm.useEffect - the second one - called.');
    if (!id) {
      setBreadcrumbPaths([
        {
          label: 'Units',
          path: '/units',
        },
      ]);
    } else {
      setBreadcrumbPaths([
        {
          label: 'Units',
          path: '/units',
        },
        {
          label: unit?.title || 'New Unit',
          path: `/units/${id}`,
        },
      ]);
    }

    if (unit) {
      setValues({
        title: unit.title || '',
        description: unit.description || '',
        productionStatus: unit.productionStatus,
        icon: {
          url: unit.icon || undefined,
        },
        grayedIcon: {
          url: unit.grayedIcon || undefined,
        },
      });
    } else {
      setValues({
        title: '',
        description: '',
        productionStatus: 'DEV',
        icon: undefined,
        grayedIcon: undefined,
      });
    }
  }, [id, setBreadcrumbPaths, setValues, unit]);

  const addLessonToProgram = useCallback(
    async (element: LessonType | ChallengeType, pathType: string) => {
      if (unit) {
        if (unit.productionStatus === 'PROD' && element.productionStatus !== 'PROD') {
          toast.error("This unit is Live in production. You can't add to it element that is not ready for production!");
        } else {
          setElements((els) => {
            const temp = [...els];
            temp.push({
              ...element,
              pathType,
              order: temp.length,
            });
            return temp;
          });
        }
      }
    },
    [unit],
  );

  const removeLessonFromProgram = useCallback(
    async (lessonId: LessonId) => {
      if (elements) {
        setElements((prev) => {
          const index = elements.findIndex((e) => e.id === lessonId);
          const temp = [...prev];
          temp.splice(index, 1);
          return temp;
        });
      }
    },
    [elements],
  );

  const onElementUpdate = useCallback(
    async (val: Optional<ElementWithMetaDataType>, el: ElementWithMetaDataType) => {
      if (elements) {
        const temp = [...elements];
        const updatedElementPositionsArray = temp.map((e: ElementWithMetaDataType) => {
          if (e.id === el.id) {
            return {
              ...el,
              ...val,
            };
          }
          return e;
        });
        setElements(updatedElementPositionsArray);
      }
    },
    [elements],
  );

  useEffect(() => {
    if (unit && tags) {
      setFieldValue(
        'tags',
        tags.filter((el) => unit.tags?.includes(el.value)),
      );
    }
  }, [setFieldValue, tags, unit]);

  const changeTo = (id: string, to: 'PROD' | 'DEV') => {
    setValidateElement({
      id,
      type: 'unit',
      to,
    });
  };

  return (
    <>
      <form
        className='space-y-8 divide-y divide-gray-200'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
          <div>
            <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
              Unit Details <span style={{ fontSize: '80%' }}>(ID: {unit?.id})</span>
            </label>
            <p>
              <strong>Created:</strong>&nbsp;{uiFormatTimestamp(unit?.createdAt)}
              &nbsp;&nbsp;
              <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(unit?.updatedAt)}
            </p>
            {unit ? (
              <>
                <div className={'flex flex-row items-center mt-3'}>
                  <p className='font-bold mr-3'>Status:</p>
                  {unit?.productionStatus || 'WIP'}
                  {unit?.productionStatus === 'DEV' ? (
                    <button
                      type={'button'}
                      onClick={() => changeTo(unit?.id, 'PROD')}
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Change to Prod
                    </button>
                  ) : null}
                  {!unit?.productionStatus ? (
                    <button
                      type={'button'}
                      onClick={() => changeTo(unit?.id, 'DEV')}
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Change to Dev
                    </button>
                  ) : null}
                </div>
                <div className={'flex flex-row items-center mt-3'}>
                  <p className='font-bold mr-3'>Locked:</p>
                  {unit?.locked ? (
                    <CheckIcon className='h-5 w-5 text-green-500' />
                  ) : (
                    <XIcon className='h-5 w-5 text-red-400' />
                  )}
                </div>
              </>
            ) : null}
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                    This information will be displayed publicly so be careful what you share
                  </p>
                </div>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='title'
                      id='title'
                      autoComplete='title'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                        errors?.title ? 'border-red-300' : 'border-gray-300'
                      }`}
                      onChange={handleChange}
                      value={values.title}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Description
                </label>
                <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                  This information will be displayed publicly so be careful what you share
                </p>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <textarea
                  maxLength={120}
                  id='description'
                  name='description'
                  rows={3}
                  className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  onChange={handleChange}
                  value={values.description}
                />
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Status:
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <select
                  name='productionStatus'
                  value={values.productionStatus || ''}
                  onChange={handleChange}
                  style={{ display: 'block' }}
                >
                  <option value='' label='None'>
                    NONE
                  </option>
                  <option value='DEV' label='DEV'>
                    DEV
                  </option>
                  <option value='PROD' label='PROD'>
                    PROD
                  </option>
                </select>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                Icons
              </label>
              <div className='flex  justify-evenly'>
                <div className='flex items-center justify-center flex-col '>
                  <p className='text-sm text-gray-600 mb-2'>Normal</p>
                  <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100 hover:cursor-pointer'>
                    {values?.icon?.url ? (
                      <img className='h-full w-full' src={values.icon.url} alt='icon' />
                    ) : (
                      <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                        <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                      </svg>
                    )}
                  </span>
                  <div className='flex text-sm text-gray-600 mt-3'>
                    <label
                      htmlFor='icon'
                      className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                    >
                      <span>Upload a file</span>
                      <input
                        id='icon'
                        name='icon'
                        type='file'
                        accept='image/*'
                        className='sr-only'
                        onChange={(e) => {
                          const file = e.target?.files?.item(0);
                          if (file) {
                            const url = URL.createObjectURL(file);
                            setValues({
                              ...values,
                              icon: {
                                url: url,
                                file: file,
                              },
                            });
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className='flex items-center justify-center flex-col '>
                  <p className='text-sm text-gray-600 mb-2'>Grayed</p>
                  <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100 hover:cursor-pointer'>
                    {values?.grayedIcon?.url ? (
                      <img className='h-full w-full' src={values.grayedIcon.url} alt='icon' />
                    ) : (
                      <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                        <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                      </svg>
                    )}
                  </span>
                  <div className='flex text-sm text-gray-600 mt-3'>
                    <label
                      htmlFor='grayIcon'
                      className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                    >
                      <span>Upload a file</span>
                      <input
                        id='grayIcon'
                        name='grayIcon'
                        type='file'
                        accept='image/*'
                        className='sr-only'
                        onChange={(e) => {
                          const file = e.target?.files?.item(0);
                          if (file) {
                            const url = URL.createObjectURL(file);
                            setValues({
                              ...values,
                              grayedIcon: {
                                url: url,
                                file: file,
                              },
                            });
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Tags:
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <Select
                  options={tags || []}
                  isMulti={true}
                  value={values.tags}
                  onChange={(vals) => {
                    setFieldValue('tags', vals as ReactSelectOptions[]);
                  }}
                  className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                />
              </div>
            </div>
          </div>
          {unit ? (
            <>
              <ElementsList
                elements={elements}
                setElements={setElements}
                onAddLessonClick={() => setShowAddElementModal(true)}
                onRemoveLessonClick={removeLessonFromProgram}
                onElementUpdate={onElementUpdate}
              />
            </>
          ) : null}
          <div className='pt-5 pb-5'>
            <div className='flex justify-end items-center'>
              <p className={'mr-10'}>Make sure to press update after making any change!</p>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                disabled={isSubmitting}
                type='submit'
                className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                {!unit ? 'Create' : 'Update'}
              </button>
            </div>
          </div>
        </div>
      </form>

      {showAddElementModal && unit && (
        <AddElementModal
          unitElementsIds={elements.map((el) => el.id)}
          addElement={(lessonId, pathType) => {
            addLessonToProgram(lessonId, pathType).then(emptyCallback).catch(console.error);
          }}
          hide={() => setShowAddElementModal(false)}
        />
      )}
    </>
  );
};

export default UnitForm;
