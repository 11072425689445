import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { NodeType } from '../../modeltypes/node';
import { ModalType } from '../paths/sharedValues';
import { MoveIcon } from '../../assets/tabIcons/tabIcons';
import './index.css';

export type StoryNodeProps = {
  node: NodeType | null;
  showModal: (modal: ModalType) => void;
};

const StoryCustomNode = ({ id, data, type, isConnectable }: NodeProps<StoryNodeProps>) => {
  if (!data.node) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
  const truncateMiddle = (text: string) => {
    if (text.length > 100) {
      const start = text.substring(0, 25);
      const end = text.substring(text.length - 25, text.length);
      return `${start} (...) ${end}`;
    }
    return text;
  };
  return (
    <div className='flex flex-col bg-white p-5 hover:cursor-default text-[8px]'>
      <MoveIcon
        className='custom-drag-handle hover:cursor-grab'
        style={{
          display: 'inline-block',
          width: 15,
          height: 15,
          marginLeft: 5,
        }}
      />
      <Handle
        type='target'
        position={Position.Top}
        isConnectable={isConnectable}
        className='w-4 h-2 rounded-none border-0'
      />
      <div className='flex flex-col text-center font-semibold'>
        <p className='text-[8px]'>{data.node.code}</p>
        <p className='font-light text-[8px]'>{type} Node</p>
        <p className='text-center bg-red text-[8px]'>{id}</p>
        <div className='flex flex-col my-2 gap-y-1 max-w-[150px]'>
          <div className='flex flex-col w-full text-[8px]'>
            {data.node.audioUrl ? <audio src={data.node.audioUrl || ''} controls /> : 'N/A'}
            <button onClick={() => data.showModal(ModalType.UPDATE_AUDIO)} className='underline m-2 text-blue-300'>
              Update Audio
            </button>
          </div>
          <div className='flex flex-col text-[8px]'>
            {data.node.texts?.length ? (
              <p className='my-2 max-w-[125px] overflow-hidden ellipsis self-center text-[8px]'>
                {data?.node?.texts
                  ? truncateMiddle(data?.node?.texts.map((x) => x.text)?.join(`\n`))
                  : truncateMiddle(data?.node?.text || '')}
              </p>
            ) : (
              'N/A'
            )}
            <button
              onClick={() => data.showModal(ModalType.SHOW_FULL_TEXT)}
              className='flex  text-[8px]  justify-center shadow border border-blue-300 rounded-full pl-3 pr-3'
            >
              Show Full Text
            </button>
            <button
              onClick={() => data.showModal(ModalType.UPDATE_TEXT)}
              className='w-auto border-b underline text-[8px] mt-2 pb-2 text-blue-300'
            >
              Update Text
            </button>
          </div>
        </div>

        {data.node.nextNode && <p>Connecting to: {data.node.nextNode}</p>}
      </div>
      {!data.node.nextNode && (
        <button
          onClick={() => data.showModal(ModalType.ADD_NODE_AFTER)}
          className='w-auto border-b border-blue-300 text-blue-300 self-center'
        >
          Add node after this node
        </button>
      )}
      <Handle type='source' position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

export default memo(StoryCustomNode);
