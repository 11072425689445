// *******************************************************
// Cohort Report Page
// -------------------------------------------------------
// This is a Screen for showing charts for a cohort
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
// *******************************************
// Component Imports
// -------------------------------------------
import BarChart from '../../components/BarChart';

// *******************************************
// Hooks Import
// -------------------------------------------
import { useParams } from 'react-router-dom';

// *******************************************
// Action Imports
// -------------------------------------------
import { getCohortById } from '../../collections/cohort';
import { getWeek } from 'date-fns';
import LineChart from '../../components/LineChart';
import { getIndustryAverages } from '../../collections/industryAverage';
import { useAppContext } from '../../contexts/appContext';
import { CohortType } from '../../modeltypes/cohort';
import { IndustryAveragesType } from '../../modeltypes/industryAverage';
import InsightPanel from '../../components/insights/InsightPanel';
import InsightsModal from '../../components/insights/InsightsModal';
import { useInsightsContext } from '../../contexts/insightsContext';
import { INSIGHTS_ELEMENTS } from '../../components/insights/insightsConfig';
import { getCohortStats } from '../../firebase/firebaseConfig';
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';
// import { TrackType } from '../../modeltypes/tracks';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const statsFirstRow = [INSIGHTS_ELEMENTS.sleep, INSIGHTS_ELEMENTS.mood, INSIGHTS_ELEMENTS.focus];

const statsSecondRow = [INSIGHTS_ELEMENTS.clarity, INSIGHTS_ELEMENTS.equanimity, INSIGHTS_ELEMENTS.concentration];

const CohortReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const [cohort, setCohort] = useState<CohortType>();

  const [chartsWeekNumbers, setChartsWeekNumbers] = useState<{
    sessionsWeek: number;
    positiveWeek: number;
    negativeWeek: number;
  }>({
    negativeWeek: getWeek(new Date()),
    positiveWeek: getWeek(new Date()),
    sessionsWeek: getWeek(new Date()),
  });

  const [industryAverages, setIndustryAverages] = useState<IndustryAveragesType>();

  const [positiveChartData, setPositiveChartData] = useState<any>(null);
  const [negativeChartData, setNegativeChartData] = useState<any>(null);
  const [allSessionsChartData, setAllSessionsChartData] = useState<any>(null);
  const [usersPerDay, setUsersPerDay] = useState<any>(null);
  const [weekSessionsChartData, setWeekSessionsChartData] = useState<any>(null);
  const [skillsPerLessons, setSkillsPerLessons] = useState<any>(null);
  const [dailyPerLessons, setDailyPerLessons] = useState<any>(null);
  const [ratingsPerLesson, setRatingsPerLesson] = useState<any>(null);
  const [usersProgramProgress, setUsersProgramProgress] = useState<any>(null);

  const { modalShown, setInsightsStats, insightsStats } = useInsightsContext();

  const setWeekNumber = (weeks: { negativeWeek?: number; positiveWeek?: number; sessionsWeek?: number }) => {
    setChartsWeekNumbers({ ...chartsWeekNumbers, ...weeks });
  };

  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Cohorts',
        path: '/cohorts',
      },
      {
        label: 'Report',
        path: `/cohorts/report/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  useEffect(() => {
    getIndustryAverages().then((response) => setIndustryAverages(response));
  }, []);
  useEffect(() => {
    if (id) {
      getCohortById(id).then((cohortDB) => {
        if (cohortDB) {
          setCohort(cohortDB);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (cohort?.id) {
      getCohortStats({ cohortId: cohort.id }).then(({ data }) => {
        if (!data || data?.error) {
          toast.error(`Something went wrong while preparing the charts  ${data?.error ? `- ${data.error}` : ''}`);
          return;
        }
        if (Object.keys(data.negativeChartData).length !== 0) {
          setNegativeChartData(data.negativeChartData);
        }
        if (Object.keys(data.positiveChartData).length !== 0) {
          setPositiveChartData(data.positiveChartData);
        }
        if (Object.keys(data.allSessionsChartData).length !== 0) {
          setAllSessionsChartData(data.allSessionsChartData);
        }
        if (Object.keys(data.dailyPerLessons).length !== 0) {
          setDailyPerLessons(data.dailyPerLessons);
        }
        if (Object.keys(data.ratingsPerLesson).length !== 0) {
          setRatingsPerLesson(data.ratingsPerLesson);
        }
        if (Object.keys(data.usersPerDay).length !== 0) {
          setUsersPerDay(data.usersPerDay);
        }
        if (Object.keys(data.weekSessionsChartData).length !== 0) {
          setWeekSessionsChartData(data.weekSessionsChartData);
        }
        if (Object.keys(data.insights).length !== 0) {
          setInsightsStats(data.insights);
        }
        if (Object.keys(data.skillsPerLessons).length !== 0) {
          setSkillsPerLessons(data.skillsPerLessons);
        }
        if (Object.keys(data.usersProgramProgress).length !== 0) {
          setUsersProgramProgress(data.usersProgramProgress);
        }
      });
    }
  }, [cohort?.id]);

  return (
    <div>
      <h3 className='text-3xl leading-6 font-medium text-sky-700 text-center mb-10'>{cohort?.name}</h3>

      {cohort ? (
        <>
          {insightsStats ? (
            <>
              <p className='text-2xl font-medium mt-5 mb-8 text-sky-500'>{'Company Insights'}</p>
              <div className='w-full flex flex-row flex-wrap justify-center'>
                {statsFirstRow?.map(({ image, field, title }) => {
                  const stats = insightsStats[field];
                  return (
                    <InsightPanel
                      key={field}
                      name={title}
                      image={image}
                      bestDay={stats.best}
                      worstDay={stats.worst}
                      cohortAverage={stats.cohortAverage}
                      industryAverage={stats.average}
                    />
                  );
                })}
              </div>
              <div className='w-full flex flex-row flex-wrap justify-center'>
                {statsSecondRow?.map(({ image, field, title }) => {
                  const stats = insightsStats[field];
                  return (
                    <InsightPanel
                      key={field}
                      name={title}
                      image={image}
                      bestDay={stats.best}
                      worstDay={stats.worst}
                      cohortAverage={stats.cohortAverage}
                      industryAverage={stats.average}
                      moreButtonShown={false}
                    />
                  );
                })}
              </div>
            </>
          ) : null}
          <div>
            <p className='text-2xl font-medium mt-5 mb-5 text-sky-500'>{'Week chart'}</p>
            {weekSessionsChartData ? (
              <>
                <div className='flex flex-row justify-center'>
                  <p
                    className='mr-5 hover:cursor-pointer'
                    onClick={() =>
                      setWeekNumber({
                        sessionsWeek: chartsWeekNumbers.sessionsWeek - 1 < 1 ? 1 : chartsWeekNumbers.sessionsWeek - 1,
                      })
                    }
                  >
                    {'Previous '}{' '}
                  </p>
                  <p> {`${chartsWeekNumbers.sessionsWeek} week`} </p>
                  <p
                    className='ml-5 hover:cursor-pointer'
                    onClick={() => setWeekNumber({ sessionsWeek: chartsWeekNumbers.sessionsWeek + 1 })}
                  >
                    {' '}
                    {' Next'}
                  </p>
                </div>
                <BarChart chartTitle={['No. of sessions &', 'completed lessons']} {...weekSessionsChartData} />
              </>
            ) : (
              <Spinner />
            )}
            <p className='text-2xl font-medium mt-5 mb-5 text-sky-500'>{'Since the beginning of cohort'}</p>
            {allSessionsChartData ? (
              <BarChart
                chartTitle={['No. of sessions &', 'completed lessons']}
                {...allSessionsChartData}
                zoomDomainXMax={10}
              />
            ) : (
              <Spinner />
            )}
            <p className='text-2xl font-medium mt-5 text-sky-500'>{'Users per day'}</p>
            {usersPerDay ? (
              <BarChart chartTitle={['Users per day']} {...usersPerDay} zoomDomainXMax={15} />
            ) : (
              <Spinner />
            )}
            <p className='text-2xl font-medium mt-5 text-sky-500'>{'Users progress'}</p>
            {usersProgramProgress ? (
              <BarChart
                chartData={usersProgramProgress}
                chartTitle={'Intro - Users progress'}
                xLabel={'Lesson'}
                yLabel={'# Users'}
                verticalLabel
              />
            ) : (
              <Spinner />
            )}

            <p className='text-2xl font-medium mt-5 mb-5 text-sky-500'>{'Ups and Downs'}</p>
            {positiveChartData ? (
              <>
                <div className='flex flex-row justify-center'>
                  <p
                    className='mr-5 hover:cursor-pointer'
                    onClick={() =>
                      setWeekNumber({
                        positiveWeek: chartsWeekNumbers.positiveWeek - 1 < 1 ? 1 : chartsWeekNumbers.positiveWeek - 1,
                      })
                    }
                  >
                    {'Previous '}{' '}
                  </p>
                  <p> {`${chartsWeekNumbers.positiveWeek} week`} </p>
                  <p
                    className='ml-5 hover:cursor-pointer'
                    onClick={() => setWeekNumber({ positiveWeek: chartsWeekNumbers.positiveWeek + 1 })}
                  >
                    {' '}
                    {' Next'}
                  </p>
                </div>
                <BarChart
                  chartTitle={['Focus,', 'Restfulness, happiness']}
                  {...positiveChartData}
                  max={5}
                  industryAverage={[
                    {
                      value: industryAverages?.restfulness,
                      fill: '#cbd72d',
                      name: 'Avg Restfulness',
                    },
                    {
                      value: industryAverages?.happiness,
                      fill: '#2ca0ff',
                      name: 'Avg Happiness',
                    },
                    {
                      value: industryAverages?.distractability,
                      fill: '#2e4df8',
                      name: 'Avg Focus',
                    },
                  ]}
                />
              </>
            ) : (
              <Spinner />
            )}
            {negativeChartData ? (
              <>
                {/*<p className='text-2xl font-medium mt-5 mb-5 / text-sky-500'>{'Negative Daily Diaries'}</p>*/}
                <div className='flex flex-row justify-center'>
                  <p
                    className='mr-5 hover:cursor-pointer'
                    onClick={() =>
                      setWeekNumber({
                        negativeWeek: chartsWeekNumbers.negativeWeek - 1 < 1 ? 1 : chartsWeekNumbers.negativeWeek - 1,
                      })
                    }
                  >
                    {'Previous '}{' '}
                  </p>
                  <p> {`${chartsWeekNumbers.negativeWeek} week`} </p>
                  <p
                    className='ml-5 hover:cursor-pointer'
                    onClick={() => setWeekNumber({ negativeWeek: chartsWeekNumbers.negativeWeek + 1 })}
                  >
                    {' '}
                    {' Next'}
                  </p>
                </div>
                <BarChart
                  chartTitle={['Sleep Onset,', 'Depression, Overwhelm']}
                  {...negativeChartData}
                  max={5}
                  industryAverage={[
                    {
                      value: industryAverages?.depression,
                      fill: '#82de3a',
                      name: 'Avg Depression',
                    },
                    {
                      value: industryAverages?.overwhelm,
                      fill: '#fa5a5a',
                      name: 'Avg Overwhelm',
                    },
                    {
                      value: industryAverages?.latency,
                      fill: '#faa823',
                      name: 'Avg Sleep Onset',
                    },
                  ]}
                />
              </>
            ) : null}
            {dailyPerLessons && skillsPerLessons ? (
              <>
                <div className='flex flex-col'>
                  <p className='text-2xl font-medium mt-5 mb-5 text-sky-500'> Daily Diaries</p>
                  <LineChart
                    chartData={dailyPerLessons}
                    chartTitle={'Daily Diaries'}
                    max={5}
                    xLabel={'Lesson'}
                    verticalLabel
                  />
                </div>
                <div className='flex flex-col'>
                  <p className='text-2xl font-medium mt-5 mb-5 text-sky-500'> Skills</p>
                  <LineChart
                    chartData={skillsPerLessons}
                    chartTitle={'Skills'}
                    max={7}
                    xLabel={'Lesson'}
                    verticalLabel
                  />
                </div>
              </>
            ) : null}
            {ratingsPerLesson ? (
              <BarChart
                chartData={ratingsPerLesson}
                chartTitle={'Ratings per Lesson'}
                max={5}
                xLabel={'Lesson'}
                verticalLabel
              />
            ) : null}
          </div>
        </>
      ) : (
        <Skeleton count={5} />
      )}
      {modalShown ? <InsightsModal /> : null}
    </div>
  );
};

export default CohortReportPage;
