/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { accessCodeIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { AccessCodeType, AccessCodeTypeBase, AccessCodeTypeBuilder, AccessCodeTypeNew } from '../modeltypes/accessCode';

export const accessCodeTypeSchema = z.object({
  id: accessCodeIdSchema,
  code: z.string(),
  description: z.string().optional(),
  from: z.string().optional(),
  companyId: z.string().optional(),
  cohortId: z.string().optional(),
  to: z.string().optional(),
  used: z.boolean().optional(),
  invited: z.boolean().optional(),
  signedUp: z.any().optional(),
  expireType: z.string().optional(),
  expireValue: z.any().optional(),
  userLimit: z.number().optional(),
  discountAssossiation: z.number().optional(),
  benifits: z.any().optional(),
  userList: z.array(z.string()).optional(),
  createdAt: z.any(),
  startDate: z.any(),
  accessCodeType: z.string().optional(),
});

export const accessCodeTypeBaseSchema = accessCodeTypeSchema.partial();

export const accessCodeTypeBuilderSchema = accessCodeTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: accessCodeIdSchema.default(() => IdFactory.AccessCode()) }));

export const accessCodeTypeNewSchema = accessCodeTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: accessCodeIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: AccessCodeType = accessCodeTypeSchema.parse(undefined);
  const x: AccessCodeTypeBase = accessCodeTypeBaseSchema.parse(undefined);
  const y: AccessCodeTypeBuilder = accessCodeTypeBuilderSchema.parse(undefined);
  const z: AccessCodeTypeNew = accessCodeTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
