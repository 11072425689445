// *******************************************************
// NodeFormPage
// -------------------------------------------------------
// This is a NodeFormPage
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useAppContext } from '../../contexts/appContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getNodeById } from '../../collections/nodes';
import { NodeType } from '../../modeltypes/node';
import NodeForm from './NodeForm';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const NodeFormPage = () => {
  const [node, setNode] = useState<NodeType | null>(null);

  const { setBreadcrumbPaths, breadcrumbPaths } = useAppContext();

  const navigate = useNavigate();
  const { id, nodeId } = useParams();

  useEffect(() => {
    if (nodeId !== 'new') {
      getNodeById(nodeId).then((currentNode) => {
        if (currentNode) {
          setNode(currentNode);
        }
      });
    }
  }, [nodeId]);

  useEffect(() => {
    if (node) {
      setBreadcrumbPaths([
        ...breadcrumbPaths,
        {
          label: node?.code || 'New Node',
          path: `${location.pathname}`,
        },
      ]);
    }
  }, [node]);

  if (!id) {
    return (
      <div>
        <p>ERROR</p>
        <p>{"Can't find challenge ID"}</p>
      </div>
    );
  }

  return (
    <NodeForm
      node={node}
      challengeId={id}
      afterSubmitFunction={() => navigate(`/challenges/${id}`)}
      onCloseFunction={() => navigate(-1)}
    />
  );
};

export default NodeFormPage;
