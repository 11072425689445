// *******************************************************
// DataSciencePage Screen
// -------------------------------------------------------
// This is a Screen for showing any data scinece we have..
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/appContext';
import { CSVDownload } from 'react-csv';
import { getAllDetailedSessions } from '../collections/detailedSessions';
import { DetailedSessionType } from '../modeltypes/detailedSession';
import { collectScience } from '../firebase/functions';
import { toast } from 'react-toastify';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
const DataSciencePage = () => {
  const { role } = useAppContext();
  const { setBreadcrumbPaths } = useAppContext();
  const [csvData, setCsvData] = useState<DetailedSessionType[] | null>(null);

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Data Science',
        path: '/datascience',
      },
    ]);
  }, [setBreadcrumbPaths]);

  const downloadCsv = () => {
    toast.promise(
      async () => {
        const detailedSessions = await getAllDetailedSessions();
        setCsvData(detailedSessions);
      },
      {
        pending: 'Preparing data...',
        success: 'Data prepared! Downloading...',
        error: 'Something went wrong. Try again',
      },
    );
  };

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  const functions = [
    {
      id: 'getScienceData',
      name: 'Download',
      description: 'Download session data as csv',
      onPress: () => {
        downloadCsv();
      },
    },
  ];

  const runCollectData = () => {
    collectScience();
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='basis-4/12 mx-auto mb-5 sm:mb-0 self-start '>
          <h1 className='font-bold text-3xl text-center mb-2'>Welcome to DataScience</h1>
          <h4 className='font-light text-xl text-gray-700 mb-4 text-center'>
            Where if you are a nerd like us, all your dreams can come true.
          </h4>
        </div>
        <div className='mb-5 flex justify-center mx-5'>
          <button
            onClick={() => runCollectData()}
            className='inline-flex self-end rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Run science
          </button>
        </div>

        {/*<SearchBar />*/}
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                      Id
                    </th>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                      Function Name
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Description
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Run Lola Run!
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {functions.map((thisFunction) => (
                    <tr key={thisFunction.id} className='hover:bg-stone-200'>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='flex items-center'>
                          <div className='ml-4'>
                            <div className='text-gray-500'>{thisFunction.id}</div>
                          </div>
                        </div>
                      </td>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='flex items-center'>
                          <div className='ml-4'>
                            <div className='text-gray-500'>{thisFunction.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <div className='font-medium text-gray-900'>{thisFunction.description}</div>
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                        {role === 'admin' && (
                          <div
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => thisFunction.onPress()}
                          >
                            Run
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {csvData && (
        <CSVDownload
          data={csvData}
          asyncOnClick
          onClick={(x, done) => {
            console.log('x');
            done(false);
          }}
        />
      )}
    </div>
  );
};

export default DataSciencePage;
