/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { companyIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { CompanyType, CompanyTypeBase, CompanyTypeBuilder, CompanyTypeNew } from '../modeltypes/company';

export const companyTypeSchema = z.object({
  id: companyIdSchema,
  domain: z.string().default(''),
  companyName: z.string().optional(),
  sso: z.array(z.string()).optional(),
  isScience: z.boolean().nullable(),
});

export const companyTypeBaseSchema = companyTypeSchema.partial();

export const companyTypeBuilderSchema = companyTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: companyIdSchema.default(() => IdFactory.Company()) }));

export const companyTypeNewSchema = companyTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: companyIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: CompanyType = companyTypeSchema.parse(undefined);
  const x: CompanyTypeBase = companyTypeBaseSchema.parse(undefined);
  const y: CompanyTypeBuilder = companyTypeBuilderSchema.parse(undefined);
  const z: CompanyTypeNew = companyTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
