// *******************************************************
// ChallengeNodeFlow
// -------------------------------------------------------
// This is a ChallengeNodeFlow
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AnswerType, NodeType } from '../../modeltypes/node';
import ReactFlow, {
  addEdge,
  Connection,
  Controls,
  Edge,
  Node,
  updateEdge,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import { DEFAULT_NODE_HEIGHT, DEFAULT_NODE_WIDTH, laidOutNodes } from '../../utility/FlowUtilities';
import { NodeId } from '../../modeltypes/id';
import { allPossibleDestinations } from '../../utility/ScriptUtilities';
import DecisionCustomNode, { DecisionNodeProps } from '../flowParts/DecisionCustomNode';
import StoryCustomNode, { StoryNodeProps } from '../flowParts/StoryCustomNode';
import { confirmAlert } from 'react-confirm-alert';
import { updateNode } from '../../collections/nodes';
import { toast } from 'react-toastify';
import FlowModal, { ModalContentType } from './FlowModal';
import { ModalType } from './sharedValues';
import QuizCustomNode, { QuizCustomNodeProps } from '../flowParts/QuizCustomNode';
import { useParams } from 'react-router-dom';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface ChallengeNodeFlowProps {
  rawNodes: NodeType[];
  refresh: () => void;
  updateNodes: (nodeIds: string[]) => void;
  locked?: boolean;
}

export type CustomNodeProps = StoryNodeProps | DecisionNodeProps | QuizCustomNodeProps;

const nodeTypes = {
  DECISION: DecisionCustomNode,
  STORY: StoryCustomNode,
  QUIZ: QuizCustomNode,
  default: StoryCustomNode,
};

const editConnection = ({
  sourceId,
  targetId,
  isDeleting = false,
}: {
  sourceId: string;
  targetId: string;
  isDeleting?: boolean;
}) => {
  const data = isDeleting ? { nextNode: null } : { nextNode: targetId };
  return updateNode(sourceId, data);
};

const editAnswerConnection = ({ answers, sourceId }: { sourceId: string; answers: AnswerType[] }) => {
  return updateNode(sourceId, { answers });
};

const ChallengeNodeFlow = ({ rawNodes, refresh, updateNodes, locked = false }: ChallengeNodeFlowProps) => {
  const { id: challengeId } = useParams();

  const [flowModalShown, setFlowModalShown] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContentType | null>(null);

  const nodeMap = new Map<NodeId, NodeType>();
  const nodeIds: string[] = [];
  const seenNodeIds = new Set<NodeId>();

  const nodeRef = useRef<Node<CustomNodeProps> | null>(null);

  rawNodes.forEach((an) => {
    nodeMap.set(dieIfNullOrUndef(an.id), an);
    if (an.code) {
      if (!seenNodeIds.has(an.code)) {
        nodeIds.push(dieIfNullOrUndef(an.id));
        seenNodeIds.add(dieIfNullOrUndef(an.id));
      } else {
        console.warn(
          `Subsection code: '${an.code}' is found on more than one node in the lesson. Only the first will be graphed.`,
        );
      }
    } else {
      console.warn(`Node ID: ${an.id} has no subsection code, and will be orphaned.`);
    }
  });

  const showModal = (node: NodeType | null) => (modalType: ModalType) => {
    if (node) {
      setFlowModalShown(true);
      setModalContent({
        node,
        type: modalType,
        challengeId: challengeId || '',
      });
    }
  };

  const nodesInitial: Node[] = rawNodes.map((node, index) => {
    return {
      id: dieIfNullOrUndef(node.id),
      data: {
        node,
        showModal: showModal(node),
      },
      type: node.nodeType || 'default',
      position: {
        x: DEFAULT_NODE_WIDTH,
        y: DEFAULT_NODE_HEIGHT * 2 * index,
      },
      dragHandle: '.custom-drag-handle',
      connectable: !locked,
    };
  });

  // Collect Edges
  const edgesInitial: Edge[] = [];
  nodeIds.forEach((nodeId) => {
    const node = dieIfNullOrUndef(nodeMap.get(nodeId));
    const allDsts = allPossibleDestinations(node);
    for (const dst of allDsts) {
      edgesInitial.push({
        id: `${nodeId}_${dst.target}`,
        source: nodeId,
        target: dst.target,
        sourceHandle: dst.sourceHandle,
        type: 'smoothstep',
      });
    }
  });
  const positionedNodesInitial = laidOutNodes(nodesInitial, edgesInitial, 250, 400);

  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNodeProps>(positionedNodesInitial);
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgesInitial);

  const edgeUpdateSuccessful = useRef(true);

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  useEffect(() => {
    if (!flowModalShown) {
      setModalContent(null);
    }
  }, [flowModalShown]);

  useEffect(() => {
    setNodes((prevState) =>
      prevState.map((node) => {
        const nodeToUpdate = rawNodes.find((n) => n.id === node.id);
        return {
          ...node,
          data: {
            node: nodeToUpdate || node.data.node,
            showModal: showModal(nodeToUpdate || node.data.node),
          },
        };
      }),
    );
  }, [rawNodes]);

  const onEdgeUpdateEnd = useCallback((_: any, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      const source = edge.source ? nodeMap.get(edge.source) : null;
      const target = edge.target ? nodeMap.get(edge.target) : null;

      const isAnswer = !!edge.sourceHandle;

      const alertData: any = {};
      if (isAnswer && source?.answers && target) {
        const answerIndex = source.answers.findIndex((e) => e.id === edge.sourceHandle);
        const copyAnswers = [...source.answers];
        const answer = copyAnswers[answerIndex];
        if (answer) {
          copyAnswers[answerIndex].nextNode = null;
          alertData.message = `Do you want to delete connection - answer "${answer.text}" of ${source.code} with ${target.code}?`;
          alertData.function = async () => {
            await editAnswerConnection({
              answers: copyAnswers,
              sourceId: source.id,
            });
            updateNodes([source.id, target.id]);
          };
        }
      } else if (!isAnswer && source && target) {
        alertData.message = `Are you sure you want to delete connection ${source.code} with ${target.code}?`;
        alertData.function = async () => {
          await editConnection({
            sourceId: source.id,
            targetId: target.id,
            isDeleting: true,
          });
          updateNodes([source.id, target.id]);
        };
      }
      if (alertData) {
        confirmAlert({
          title: `Confirm deletion`,
          message: alertData.message,
          buttons: [
            {
              label: 'Delete',
              onClick: async () => {
                await toast
                  .promise(
                    () => alertData.function(),

                    {
                      pending: 'Deleting connection',
                      success: 'Connection Deleted',
                      error: "Can't delete this connection now..",
                    },
                  )
                  .then(() => {
                    setEdges((eds) => eds.filter((e) => e.id !== edge.id));
                  });
                edgeUpdateSuccessful.current = true;
              },
            },
            {
              label: 'Cancel',
            },
          ],
        });
      }
    }
  }, []);

  const onConnect = useCallback((params: Edge | Connection) => {
    const source = params.source ? nodeMap.get(params.source) : null;
    const target = params.target ? nodeMap.get(params.target) : null;
    const isAnswer = !!params.sourceHandle;
    const alertData: any = {};
    if (isAnswer && source?.answers && target) {
      const answerIndex = source.answers.findIndex((e) => e.id === params.sourceHandle);
      const copyAnswers = [...source.answers];
      const answer = copyAnswers[answerIndex];
      if (answer) {
        copyAnswers[answerIndex].nextNode = target.id;
        alertData.message = `Do you want to connect answer "${answer.text}" of ${source.code} with ${target.code}?`;
        alertData.function = async () => {
          await editAnswerConnection({
            answers: copyAnswers,
            sourceId: source.id,
          });
          updateNodes([source.id, target.id]);
        };
      }
    } else if (!isAnswer && source && target) {
      alertData.message = `Are you sure you want to connect ${source.code} with ${target.code}?`;
      alertData.function = async () => {
        await editConnection({
          sourceId: source.id,
          targetId: target.id,
        });
        updateNodes([source.id, target.id]);
      };
    }
    if (alertData) {
      confirmAlert({
        title: `Confirm connection`,
        message: alertData.message,
        buttons: [
          {
            label: 'Connect',
            onClick: async () => {
              await toast
                .promise(() => alertData.function(), {
                  pending: 'Connecting nodes',
                  success: 'Nodes connected!',
                  error: "Can't connect it now..",
                })
                .then(() => {
                  setEdges((els) =>
                    addEdge(
                      {
                        ...params,
                        type: 'smoothstep',
                      },
                      els,
                    ),
                  );
                });
            },
          },
          {
            label: 'Cancel',
          },
        ],
      });
    }
  }, []);

  // add node after

  const addNodeAfter = (node: Node<CustomNodeProps>, newNode: NodeType) => {
    const newNodeAfter: Node<CustomNodeProps> = {
      id: newNode.id,
      // we are removing the half of the node width (75) to center the new node
      data: {
        node: newNode,
        showModal: showModal(newNode),
      },
      type: newNode.nodeType || 'default',
      position: {
        x: node.position.x,
        y: node.position.y + (node.height || 300) + 75,
      },
    };

    setNodes((nds) => nds.concat(newNodeAfter));
    setEdges((eds) =>
      eds.concat({
        id: `${node.id}_${newNode.id}`,
        source: node.id,
        target: newNode.id,
      }),
    );
    updateNode(node.id, { nextNode: newNode.id });
    updateNodes([newNode.id, node.id]);
    // refresh();
  };

  const flowProps = locked
    ? {}
    : {
        onEdgesChange: onEdgesChange,
        onEdgeUpdate: onEdgeUpdate,
        onEdgeUpdateStart: onEdgeUpdateStart,
        onEdgeUpdateEnd: onEdgeUpdateEnd,
        onConnect: onConnect,
      };
  return (
    <>
      <ReactFlow
        className='hover:cursor-grab'
        nodeTypes={nodeTypes}
        nodes={nodes}
        fitView
        style={{
          backgroundColor: '#EEE',
          borderRadius: 20,
        }}
        edges={edges}
        onNodesChange={onNodesChange}
        snapToGrid
        onNodeClick={(e, node: Node<CustomNodeProps>) => (nodeRef.current = node)}
        {...flowProps}
      >
        <Controls />
      </ReactFlow>
      {flowModalShown && nodeRef.current && (
        <FlowModal
          content={modalContent}
          hide={(shouldRefresh?: boolean) => {
            setFlowModalShown(false);
            if (shouldRefresh) {
              refresh();
            }
          }}
          updateNodes={updateNodes}
          addNodeAfter={addNodeAfter}
          eventCallerNode={nodeRef.current}
        />
      )}
    </>
  );
};
export default ChallengeNodeFlow;
