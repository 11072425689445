/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { coachIdSchema, cohortIdSchema, cohortMeetingIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import {
  CohortMeetingType,
  CohortMeetingTypeBase,
  CohortMeetingTypeBuilder,
  CohortMeetingTypeNew,
  MeetingTypeEnum,
} from '../modeltypes/cohortMeeting';
import { timestampType } from './shared';

export const meetingTypeEnumSchema = z.nativeEnum(MeetingTypeEnum);

export const cohortMeetingTypeSchema = z.object({
  id: cohortMeetingIdSchema,
  coach: coachIdSchema.optional(),
  cohort: cohortIdSchema.optional(),
  comments: z.array(z.string()).optional(),
  date: timestampType(),
  description: z.string().optional(),
  location: z.string().nullable().optional(),
  rating: z.array(z.string()).optional(),
  type: meetingTypeEnumSchema.optional(),
  url: z.string().optional(),
  title: z.string().optional(),
  lengthMins: z.number().optional(),
});

export const cohortMeetingTypeBaseSchema = cohortMeetingTypeSchema.partial();

export const cohortMeetingTypeBuilderSchema = cohortMeetingTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: cohortMeetingIdSchema.default(() => IdFactory.CohortMeeting()) }));

export const cohortMeetingTypeNewSchema = cohortMeetingTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: cohortMeetingIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: CohortMeetingType = cohortMeetingTypeSchema.parse(undefined);
  const x: CohortMeetingTypeBase = cohortMeetingTypeBaseSchema.parse(undefined);
  const y: CohortMeetingTypeBuilder = cohortMeetingTypeBuilderSchema.parse(undefined);
  const z: CohortMeetingTypeNew = cohortMeetingTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
