// *******************************************************
// Bar Chart
// -------------------------------------------------------
// This is a component to display bar chart
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryLine,
  VictoryZoomContainer,
  VictoryContainer,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from 'victory';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
import { getFill } from '../pages/cohorts/reportCalculations';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface BarChartProps {
  tickValues?: string[];
  chartData: {
    data: { x: string; y: number }[];
    fill?: string;
    name: string;
  }[];
  chartTitle: string | string[];
  max?: number;
  industryAverage?: { value?: number; name: string; fill: string }[];
  xLabel?: string;
  yLabel?: string;
  zoomDomainXMax?: number;
  verticalLabel?: boolean;
  barWidth?: number;
  domainPadding?: number;
  tooltipsEnabled?: boolean;
}

const BarChart = ({
  tickValues,
  chartData,
  chartTitle,
  max,
  industryAverage,
  xLabel,
  yLabel,
  zoomDomainXMax,
  verticalLabel,
  barWidth = 20,
  domainPadding = 50,
  tooltipsEnabled,
}: BarChartProps) => {
  const legendData = [
    chartData.map(({ name, fill }) => ({
      name: name,
      symbol: { fill: fill },
    })),
    industryAverage
      ? industryAverage.map(({ name, fill }) => ({
          name: name,
          symbol: {
            fill: fill,
            type: 'minus',
          },
        }))
      : [],
  ];

  return (
    <VictoryChart
      containerComponent={
        zoomDomainXMax ? (
          <VictoryZoomContainer zoomDimension={'x'} allowZoom={false} zoomDomain={{ x: [0, zoomDomainXMax] }} />
        ) : tooltipsEnabled ? (
          <VictoryVoronoiContainer labelComponent={<VictoryTooltip />} labels={({ datum }) => `${datum.y}`} />
        ) : (
          <VictoryContainer />
        )
      }
      theme={VictoryTheme.material}
      domainPadding={domainPadding}
      width={800}
      height={500}
      padding={{
        left: 100,
        right: 50,
        top: 75,
        bottom: 50,
      }}
      domain={{
        y: [
          0,
          max ||
            Math.max(
              ...chartData
                .map(({ data }) => data)
                .flat()
                .map(({ y }) => y),
              8,
            ) * 1.25,
        ],
      }}
    >
      <VictoryAxis
        dependentAxis
        label={yLabel}
        style={{
          axisLabel: {
            fontSize: 14,
            padding: 60,
          },
        }}
      />
      {industryAverage &&
        industryAverage.map(({ value, fill }) => (
          <VictoryLine
            key={`${value}`}
            interpolation={'linear'}
            label={'Industry Average'}
            style={{
              data: {
                stroke: fill,
                strokeWidth: 1,
              },
            }}
            data={[
              {
                _x: 0,
                _y: value,
              },
              {
                _x: 3,
                _y: value,
              },
              {
                _x: (tickValues?.length || 200) + 1,
                _y: value,
              },
            ]}
            // labels={({ datum }) => {
            //   if (datum._x === 3) return 'Industry Average';
            //   return '';
            // }}
          />
        ))}
      <VictoryGroup offset={24} colorScale={['brown', 'tomato']}>
        {chartData.map(({ data, fill, name }, index) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <VictoryBar
            key={`${index}`}
            style={{
              data: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fill: ({ datum }) => getFill(datum.xName) || fill,
                width: barWidth,
              },
              labels: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fill: ({ datum }) => getFill(datum.xName) || fill,
                fontWeight: 'bold',
              },
            }}
            data={data}
            name={name}
          />
        ))}
      </VictoryGroup>
      <VictoryAxis
        tickLabelComponent={
          verticalLabel ? (
            <VictoryLabel
              angle={90}
              verticalAnchor='middle'
              textAnchor='end'
              style={{
                fontStyle: 'bold',
                fontSize: 10,
              }}
            />
          ) : (
            <VictoryLabel />
          )
        }
        tickValues={tickValues}
        label={xLabel}
        style={{
          axisLabel: {
            fontSize: 14,
            padding: 35,
          },
        }}
      />
      {chartData.length > 1 && (
        <VictoryLegend
          x={300}
          y={50}
          title='Legend'
          centerTitle
          orientation='horizontal'
          itemsPerRow={3}
          style={{ border: { fill: 'white' } }}
          data={legendData.flat()}
        />
      )}

      <VictoryLabel x={80} y={50} text={chartTitle} style={{ fill: '#8A8A8A' }} textAnchor={'middle'} />
    </VictoryChart>
  );
};

export default BarChart;
