import { Timestamp } from 'firebase/firestore';

const MINUTE_IN_MILLIS = 60000;

export const getDateWithTimezone = (date?: Timestamp, tzString?: string) => {
  if (!date) {
    return null;
  }
  return new Date(date.toDate().toLocaleString('en-US', { timeZone: tzString }));
};

export function getTimezoneMinutesOffset(date: Date, timezone: string) {
  const timezoneDate = new Date(
    date.toLocaleString('en-US', {
      timeZone: timezone,
    }),
  );

  return (date.getTime() - timezoneDate.getTime()) / MINUTE_IN_MILLIS;
}

export function parseISOLocal(dateString: string) {
  const b = dateString.split(/\D/).map((el) => +el);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}
