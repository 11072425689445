import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  queryByIdsFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { LESSON_AC_TABLE_NAME } from './tableName';
import { LessonAudioChunkId } from '../modeltypes/id';
import {
  LessonAudioChunkType,
  LessonAudioChunkTypeBase,
  LessonAudioChunkTypeBuilder,
  LessonAudioChunkTypeNew,
  LessonAudioChunkUpdateType,
} from '../modeltypes/lessonAudioChunk';

// noinspection JSUnusedGlobalSymbols
export const getNewLessonAudioChunkId = getNewIdFor<LessonAudioChunkId>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewLessonAudioChunk = addNewFor<LessonAudioChunkId, LessonAudioChunkTypeNew>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewLessonAudioChunk = createNewFor<LessonAudioChunkId, LessonAudioChunkTypeBuilder>(
  LESSON_AC_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const setNewLessonAudioChunk = setNewFor<LessonAudioChunkId, LessonAudioChunkTypeNew>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonAudioChunkRefById = getRefByIdFor<LessonAudioChunkId>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonAudioChunkById = getByIdFor<LessonAudioChunkId, LessonAudioChunkType>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonAudioChunksByIdArray = getByIdArrayFor<LessonAudioChunkId, LessonAudioChunkType>(
  LESSON_AC_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllLessonAudioChunks = getAllFor<LessonAudioChunkType>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonAudioChunksByQuery = getByQueryFor<LessonAudioChunkType>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLessonAudioChunksQuery = getAllQueryFor(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonAudioChunkQueryForIds = queryByIdsFor<LessonAudioChunkId>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateLessonAudioChunk = updateDocumentFor<LessonAudioChunkId, LessonAudioChunkUpdateType>(
  LESSON_AC_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteLessonAudioChunk = deleteDocumentFor<LessonAudioChunkId>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchLessonAudioChunkIdSet = watchIdSetFor<LessonAudioChunkId>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonAudioChunkType = getValidateTypeFor<LessonAudioChunkType>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonAudioChunkTypeBase = getValidateTypeBaseFor<LessonAudioChunkTypeBase>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonAudioChunkTypeBuilder =
  getValidateTypeBuilderFor<LessonAudioChunkTypeBuilder>(LESSON_AC_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonAudioChunkTypeNew = getValidateTypeNewFor<LessonAudioChunkTypeNew>(LESSON_AC_TABLE_NAME);
