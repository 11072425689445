// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { GoalId } from '../modeltypes/id';
import { GoalType, GoalTypeBase, GoalTypeBuilder, GoalTypeNew } from '../modeltypes/goals';
import { GOALS_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewGoalId = getNewIdFor<GoalId>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewGoal = addNewFor<GoalId, GoalTypeNew>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewGoal = createNewFor<GoalId, GoalTypeBuilder>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewGoal = setNewFor<GoalId, GoalTypeNew>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getGoalRefById = getRefByIdFor<GoalId>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getGoalById = getByIdFor<GoalId, GoalType>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getGoalsByIdArray = getByIdArrayFor<GoalId, GoalType>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllGoals = getAllFor<GoalType>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getGoalsByQuery = getByQueryFor<GoalType>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllGoalsQuery = getAllQueryFor(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateGoal = updateDocumentFor<GoalId, GoalTypeBase>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteGoal = deleteDocumentFor<GoalId>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchGoalIdSet = watchIdSetFor<GoalId>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateGoalType = getValidateTypeFor<GoalType>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateGoalTypeBase = getValidateTypeBaseFor<GoalTypeBase>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateGoalTypeBuilder = getValidateTypeBuilderFor<GoalTypeBuilder>(GOALS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateGoalTypeNew = getValidateTypeNewFor<GoalType>(GOALS_TABLE_NAME);
