// *******************************************************
// User List
// -------------------------------------------------------
// This is a Component for listing users
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAccountsByIdArray, getAllAccounts } from '../collections/account';
import { getAllCohorts } from '../collections/cohort';
import { getAllCompanies } from '../collections/company';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/appContext';
import { format } from 'date-fns';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Skeleton from 'react-loading-skeleton';
import { getAllUsers, getUsersByQuery } from '../collections/user';
import SearchBar from './SearchBar';
import { orderBy, documentId } from '../models/dalaccess';
import { limit, startAfter } from 'firebase/firestore';
import { UserType } from '../modeltypes/user';
import { CohortType } from '../modeltypes/cohort';
import { CompanyType } from '../modeltypes/company';
import { AccountType } from '../modeltypes/account';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const UsersList = () => {
  type UserTableData = {
    name: string;
    cohort: string;
    company: string;
    role: string;
    id: string;
    email: string;
    createdAt: Date;
    lastLogin: Date;
    appVersion: string;
    OS: { version: string; type: string };
  };
  const [tableData, setTableData] = useState<UserTableData[]>([]);
  const [sortFilter, setSortFilter] = useState<{ key: keyof UserTableData; order: 'asc' | 'desc' }>();
  const [isLoading, setIsLoading] = useState(true);
  const [endReached, setEndReached] = useState(false);

  const [lastItem, setLastItem] = useState<UserType | null>(null);

  const [cohorts, setCohorts] = useState<CohortType[] | null>(null);
  const [companies, setCompanies] = useState<CompanyType[] | null>(null);

  const { searchTerm } = useAppContext();
  const navigate = useNavigate();

  const filteredItems = React.useMemo(() => {
    if (!tableData?.length) return [];
    return tableData
      .filter((item) => {
        const matchName = item.name && item.name.match(`${searchTerm}`);
        const matchCompany = item.company && item.company.match(`${searchTerm}`);
        const matchCohort = item.cohort && item.cohort.match(`${searchTerm}`);
        const matchRole = item.role && item.role.match(`${searchTerm}`);
        const matchEmail = item.email && item.email.match(`${searchTerm}`);
        const matchId = item.id && item.id.match(`${searchTerm}`);
        const matchVersion = item?.appVersion && item?.appVersion.match(`${searchTerm}`);
        return matchName || matchId || matchCompany || matchCohort || matchRole || matchEmail || matchVersion;
      })
      .sort((a, b) => {
        if (!sortFilter || !a[sortFilter.key]) {
          return 0;
        }
        if (sortFilter.key === 'createdAt' || sortFilter.key === 'lastLogin') {
          if (sortFilter.order === 'desc') {
            return a[sortFilter.key].getTime() - b[sortFilter.key].getTime();
          }
          if (sortFilter.order === 'asc') {
            return b[sortFilter.key].getTime() - a[sortFilter.key].getTime();
          }
        } else {
          if (sortFilter.order === 'desc') {
            return a[sortFilter.key].toString().localeCompare(b[sortFilter.key].toString());
          }
          if (sortFilter.order === 'asc') {
            return b[sortFilter.key].toString().localeCompare(a[sortFilter.key].toString());
          }
        }
        return 0;
      });
  }, [searchTerm, tableData, sortFilter]);

  const mapUsers = (users: UserType[], accounts: AccountType[], _cohorts: CohortType[], _companies: CompanyType[]) => {
    const mappedUsers: UserTableData[] = [];

    for (const user of users) {
      const currAccount = accounts.find((a) => a.id === user.id);
      const currCohort = _cohorts.find((c) => c.id === currAccount?.cohort);
      const currCompany = _companies.find((c) => c.id === currCohort?.company);

      if (currAccount?.email && currAccount?.createdAt) {
        const datum: UserTableData = {
          name: user.firstName + ' ' + user.lastName,
          id: user.id,
          cohort: currCohort?.name || '-',
          company: currCompany?.companyName || '-',
          email: currAccount.email,
          role: currAccount?.role || 'user',
          createdAt: currAccount.createdAt.toDate(),
          lastLogin: currAccount.lastLogin?.toDate() || currAccount.createdAt.toDate(),
          appVersion: currAccount.appVersion || '-',
          OS: currAccount.OS || { type: '-', version: '-' },
        };

        mappedUsers.push(datum);
      }
    }
    return mappedUsers;
  };

  const getAll = async () => {
    if (cohorts && companies) {
      setIsLoading(true);
      const users = await getAllUsers();
      const accounts = await getAllAccounts();

      setTableData(mapUsers(users, accounts, cohorts, companies));
      setEndReached(true);
      setIsLoading(false);
    }
  };

  const getUsers = async () => {
    if (cohorts && companies) {
      const users = await getUsersByQuery(
        orderBy(documentId()),
        ...(lastItem ? [startAfter(lastItem.id)] : []),
        limit(25),
      );
      setLastItem(users.at(users.length - 1) || null);
      const accounts = await getAccountsByIdArray(users.map((el) => el.id));
      const newData: UserTableData[] = [...tableData, ...mapUsers(users, accounts, cohorts, companies)];

      if (users.length !== 25) {
        setEndReached(true);
      }

      setTableData(newData);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [cohorts, companies]);

  useEffect(() => {
    if (!endReached && (searchTerm || sortFilter)) {
      getAll();
    }
  }, [sortFilter, searchTerm, endReached]);

  useEffect(() => {
    const cohortsPromise = getAllCohorts();
    const companiesPromise = getAllCompanies();
    Promise.all([cohortsPromise, companiesPromise]).then(([_cohorts, _companies]) => {
      setCohorts(_cohorts);
      setCompanies(_companies);
    });
  }, []);

  const sortRequest = (fieldName: keyof UserTableData) => {
    let order: 'asc' | 'desc' = 'asc';
    if (sortFilter && sortFilter.key === fieldName && sortFilter.order === 'asc') {
      order = 'desc';
    }
    setSortFilter({
      key: fieldName,
      order: order,
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 '>
                      Id
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('name')}
                    >
                      <p className='group inline-flex'>
                        Name
                        <span
                          className={`${sortFilter?.key !== 'name' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('email')}
                    >
                      <p className='group inline-flex'>
                        Email
                        <span
                          className={`${sortFilter?.key !== 'email' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('company')}
                    >
                      <p className='group inline-flex'>
                        Company
                        <span
                          className={`${sortFilter?.key !== 'company' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('cohort')}
                    >
                      <p className='group inline-flex'>
                        Cohort
                        <span
                          className={`${sortFilter?.key !== 'cohort' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('role')}
                    >
                      <p className='group inline-flex'>
                        Role
                        <span
                          className={`${sortFilter?.key !== 'role' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('createdAt')}
                    >
                      <p className='group inline-flex'>
                        Created At
                        <span
                          className={`${sortFilter?.key !== 'createdAt' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('lastLogin')}
                    >
                      <p className='group inline-flex'>
                        Last Login
                        <span
                          className={`${sortFilter?.key !== 'lastLogin' && 'lastLogin'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                      <p className='group inline-flex'>App Version</p>
                    </th>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                      <p className='group inline-flex'>OS</p>
                    </th>
                    <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {isLoading ? (
                    <>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton height={50} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton count={15} className='mt-3' />{' '}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {filteredItems.map((user) => (
                        <tr key={user.id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4 truncate max-w-[50px]'>
                                <div className='text-gray-500'>{user.id}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{user.name}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{user.email}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='font-medium text-gray-900 text-center'>{user.company}</div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='text-gray-500 text-center'>{user.cohort}</div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{user.role}</td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {user.createdAt ? format(user.createdAt, 'hh:mm, MM/dd/yyyy') : '-'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {user.lastLogin ? format(user.lastLogin, 'hh:mm, MM/dd/yyyy') : '-'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{user.appVersion}</td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {user?.OS?.type} - {user?.OS?.version}
                          </td>

                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <button
                              onClick={() => navigate('/Users/' + user.id + '/Journey')}
                              className='text-indigo-600 hover:text-indigo-900 '
                            >
                              Journey
                            </button>
                          </td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <button
                              onClick={() => navigate('/Users/' + user.id + '/Science')}
                              className='text-indigo-600 hover:text-indigo-900 '
                            >
                              Science
                            </button>
                          </td>

                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <button
                              onClick={() => navigate('/Users/' + user.id + '/Rewards')}
                              className='text-indigo-600 hover:text-indigo-900 '
                            >
                              Rewards
                            </button>
                          </td>

                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                            <button
                              onClick={() => navigate('/Users/' + user.id + '/streaks')}
                              className='text-indigo-600 hover:text-indigo-900 '
                            >
                              Streaks
                            </button>
                          </td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                            <button
                              onClick={() => navigate('/Users/' + user.id)}
                              className='text-indigo-600 hover:text-indigo-900 '
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={10} className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-center'>
                          {!endReached ? (
                            <button
                              onClick={() => {
                                getUsers();
                              }}
                            >
                              Load More
                            </button>
                          ) : (
                            <p>End Reached</p>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
