import { COHORT_MEETING_TABLE_NAME } from './tableName';
import { CohortMeetingId } from '../modeltypes/id';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import {
  CohortMeetingType,
  CohortMeetingTypeBase,
  CohortMeetingTypeBuilder,
  CohortMeetingTypeNew,
  MeetingTypeEnum,
} from '../modeltypes/cohortMeeting';
import { where } from '../models/dalaccess';

// This probably shouldn't be in this file.
export const getLabelForMeetingType = (type: MeetingTypeEnum) => {
  switch (type) {
    case MeetingTypeEnum.DROP_IN:
      return 'Drop in';
    case MeetingTypeEnum.GROUP:
      return 'Group';
    case MeetingTypeEnum.PRIVATE:
      return 'Private';
  }
};

// noinspection JSUnusedGlobalSymbols
export const getNewCohortMeetingsId = getNewIdFor<CohortMeetingId>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewCohortMeetings = addNewFor<CohortMeetingId, CohortMeetingTypeNew>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewCohortMeetings = createNewFor<CohortMeetingId, CohortMeetingTypeBuilder>(
  COHORT_MEETING_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getCohortMeetingsRefById = getRefByIdFor<CohortMeetingId>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCohortMeetingsById = getByIdFor<CohortMeetingId, CohortMeetingType>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCohortMeetingsByIdArray = getByIdArrayFor<CohortMeetingId, CohortMeetingType>(
  COHORT_MEETING_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllCohortMeetings = getAllFor<CohortMeetingType>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCohortMeetingsQuery = getAllQueryFor(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCohortMeetingsByQuery = getByQueryFor<CohortMeetingType>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateCohortMeetings = updateDocumentFor<CohortMeetingId, CohortMeetingTypeBase>(
  COHORT_MEETING_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteCohortMeetings = deleteDocumentFor<CohortMeetingId>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchCohortMeetingsIdSet = watchIdSetFor<CohortMeetingId>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortMeetingType = getValidateTypeFor<CohortMeetingType>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortMeetingTypeBase = getValidateTypeBaseFor<CohortMeetingTypeBase>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortMeetingTypeBuilder =
  getValidateTypeBuilderFor<CohortMeetingTypeBuilder>(COHORT_MEETING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortMeetingTypeNew = getValidateTypeNewFor<CohortMeetingTypeNew>(COHORT_MEETING_TABLE_NAME);

export async function getMeetingsForCohort(cohortId: string): Promise<CohortMeetingType[]> {
  const cohortMeetings = getCohortMeetingsByQuery(where('cohort', '==', cohortId));
  return cohortMeetings;
}
