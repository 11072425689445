// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { ChallengeSwitchId } from '../modeltypes/id';
import {
  ChallengeSwitchType,
  ChallengeSwitchTypeBase,
  ChallengeSwitchTypeBuilder,
  ChallengeSwitchTypeNew,
} from '../modeltypes/challengeswitches';
import { CHALLENGE_SWITCH_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewChallengeSwitchId = getNewIdFor<ChallengeSwitchId>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewChallengeSwitch = addNewFor<ChallengeSwitchId, ChallengeSwitchTypeNew>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewChallengeSwitch = createNewFor<ChallengeSwitchId, ChallengeSwitchTypeBuilder>(
  CHALLENGE_SWITCH_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const setNewChallengeSwitch = setNewFor<ChallengeSwitchId, ChallengeSwitchTypeNew>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengeSwitchRefById = getRefByIdFor<ChallengeSwitchId>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengeSwitchById = getByIdFor<ChallengeSwitchId, ChallengeSwitchType>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengeSwitchesByIdArray = getByIdArrayFor<ChallengeSwitchId, ChallengeSwitchType>(
  CHALLENGE_SWITCH_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllChallengeSwitches = getAllFor<ChallengeSwitchType>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengeSwitchesByQuery = getByQueryFor<ChallengeSwitchType>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllChallengeSwitchesQuery = getAllQueryFor(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateChallengeSwitch = updateDocumentFor<ChallengeSwitchId, ChallengeSwitchTypeBase>(
  CHALLENGE_SWITCH_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteChallengeSwitch = deleteDocumentFor<ChallengeSwitchId>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchChallengeSwitchIdSet = watchIdSetFor<ChallengeSwitchId>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeSwitchType = getValidateTypeFor<ChallengeSwitchType>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeSwitchTypeBase =
  getValidateTypeBaseFor<ChallengeSwitchTypeBase>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeSwitchTypeBuilder =
  getValidateTypeBuilderFor<ChallengeSwitchTypeBuilder>(CHALLENGE_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeSwitchTypeNew = getValidateTypeNewFor<ChallengeSwitchType>(CHALLENGE_SWITCH_TABLE_NAME);
