/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { addNewPathType, getPathTypeById, updatePathType, } from '../../collections/paths';
import { addNewPathType, getPathTypeById, updatePathType } from '../../collections/paths';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { PathType } from '../../modeltypes/paths';
// import { PathType,
//   PathTypeBase
// } from '../../modeltypes/paths';

import { toast } from 'react-toastify';

// type UserSelect = {
//
// }

const PathTypeForm = () => {
  const [path, setThisPathType] = useState<PathType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'PathTypes',
        path: '/pathtypes',
      },
      {
        label: id === 'new' ? 'New PathType' : 'Edit PathType',
        path: `/pathtypes/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    //resetForm
  } = useFormik<{
    name: string;
    pathType: string;
    description: string;
    locked: boolean;
    // id?: string;
  }>({
    initialValues: {
      name: '',
      pathType: '',
      description: '',
      locked: true,
      // id: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      description: yup.string(),
      pathType: yup.string().required('Path Type is required'),
    }),
    onSubmit: async (newPathType) => {
      console.log('newPathType: ', newPathType);

      if (id !== undefined && id !== 'new') {
        toast
          .promise(updatePathType(id, newPathType), {
            pending: `Updating ${newPathType.name} PathType...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newPathType.name} PathType!`,
          })
          .then(async () => {
            navigate('/pathtypes');
          });
      } else if (id === 'new') {
        toast
          .promise(addNewPathType(newPathType), {
            pending: `Adding ${newPathType.name} PathType...`,
            error: "Can't do it now, try again.",
            success: `Added ${newPathType.name} PathType!`,
          })
          .then(async () => {
            navigate('/pathtypes');
          });
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currPathType = await getPathTypeById(id || '');
        if (currPathType === null) {
          navigate('/PathTypes');
          return;
        }

        await setValues({
          name: currPathType.name || '',
          description: currPathType.description || '',
          pathType: currPathType.pathType || '',
          locked: currPathType.locked || false,
        });

        setThisPathType(currPathType);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const disabledForm = false;

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id {id}
                </label>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id saved {path?.id}
                </label>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Name
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='name'
                        id='name'
                        autoComplete='name'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.name ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.name}
                        onChange={handleChange}
                        placeholder={'Path Type Name'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Path Type
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='pathType'
                        id='pathType'
                        autoComplete='pathType'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.pathType ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.pathType}
                        onChange={handleChange}
                        placeholder={'This must be one word (examples: audioLesson, quiz, task, challenge)'}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'Enter Path Description.  The user will see this.'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/pathtypes')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {path ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default PathTypeForm;

// import React, {  } from 'react';
// import Skeleton from 'react-loading-skeleton';
// const PathTypeForm = () => {

//   return (
//         <>
//           <Skeleton height={50} />
//           <Skeleton count={15} className='mt-3' />{' '}
//         </>
//   );
// };

// export default PathTypeForm;
