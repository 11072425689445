import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { INCENTIVE_TEMPLATE_TABLE_NAME } from './tableName';
import {
  IncentiveTemplateType,
  IncentiveTemplateTypeBase,
  IncentiveTemplateTypeBuilder,
  IncentiveTemplateTypeNew,
} from '../modeltypes/incentiveTemplate';
import { IncentiveTemplateId } from '../modeltypes/id';

// noinspection JSUnusedGlobalSymbols
export const getNewIncentivesTemplateId = getNewIdFor<IncentiveTemplateId>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewIncentivesTemplate = addNewFor<IncentiveTemplateId, IncentiveTemplateTypeNew>(
  INCENTIVE_TEMPLATE_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const createNewIncentivesTemplate = createNewFor<IncentiveTemplateId, IncentiveTemplateType>(
  INCENTIVE_TEMPLATE_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getIncentivesTemplateRefById = getRefByIdFor<IncentiveTemplateId>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getIncentivesTemplateById = getByIdFor<IncentiveTemplateId, IncentiveTemplateType>(
  INCENTIVE_TEMPLATE_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getIncentivessTemplateByIdArray = getByIdArrayFor<IncentiveTemplateId, IncentiveTemplateType>(
  INCENTIVE_TEMPLATE_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllIncentivesTemplates = getAllFor<IncentiveTemplateType>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllIncentivesTemplatesQuery = getAllQueryFor(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getIncentivesTemplatesByQuery = getByQueryFor<IncentiveTemplateType>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateIncentivesTemplate = updateDocumentFor<IncentiveTemplateId, IncentiveTemplateTypeBase>(
  INCENTIVE_TEMPLATE_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteIncentivesTemplate = deleteDocumentFor<IncentiveTemplateId>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchIncentivesTemplateIdSet = watchIdSetFor<IncentiveTemplateId>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveTemplateType = getValidateTypeFor<IncentiveTemplateType>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveTemplateTypeBase =
  getValidateTypeBaseFor<IncentiveTemplateTypeBase>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveTemplateTypeBuilder =
  getValidateTypeBuilderFor<IncentiveTemplateTypeBuilder>(INCENTIVE_TEMPLATE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveTemplateTypeNew =
  getValidateTypeNewFor<IncentiveTemplateTypeNew>(INCENTIVE_TEMPLATE_TABLE_NAME);
