// *******************************************************
// TextUpdateModal
// -------------------------------------------------------
// This is a TextUpdateModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { NodeType } from '../../../modeltypes/node';
import { useState } from 'react';
import { updateNode } from '../../../collections/nodes';
import { ChallengeTextBlockType } from 'src/modeltypes/challenges';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface TextUpdateModalProps {
  hide: (shouldRefresh?: boolean) => void;
  node: NodeType;
  updateNodes: (nodeIds: string[]) => void;
}

const TextUpdateModal = ({ hide, node, updateNodes }: TextUpdateModalProps) => {
  const [text, setText] = useState(node.text || '');
  const [texts, setTexts] = useState<ChallengeTextBlockType[]>(node.texts || []);
  const [isPending, setIsPending] = useState(false);

  const onClick = async () => {
    if (text && text !== (node.text || '')) {
      setIsPending(true);
      await updateNode(node.id, { text });
      updateNodes([node.id]);
    }

    if (texts && texts.flat() !== node?.texts?.flat()) {
      setIsPending(true);
      await updateNode(node.id, { texts });
      updateNodes([node.id]);
    }
    hide();
  };

  if (texts?.length) {
    return (
      <>
        <div className={`flex flex-col`}>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
            <label htmlFor='name' className='block text-lg font-medium dark:text-white sm:mt-px sm:py-3'>
              Text Blocks
            </label>
          </div>
          <div>
            {texts?.map((text, index) => (
              <div
                key={text.id}
                className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'
              >
                <label htmlFor='name' className='block text-sm font-medium dark:text-white sm:mt-px sm:pt-2'>
                  Text Block {index + 1}
                </label>
                <div className='max-w-lg flex rounded-md shadow-sm flex flex-col gap-y-1'>
                  <textarea
                    name={`texts[${index}].text`}
                    id={`texts[${index}].text`}
                    autoComplete={`texts`}
                    className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                    value={texts?.[index].text || ''}
                    onChange={(v) => {
                      const x = {
                        ...text,
                        text: v.currentTarget.value,
                      };
                      console.log('x: ', x);
                      const a: ChallengeTextBlockType[] = texts.map((item: ChallengeTextBlockType) =>
                        item?.id === text?.id ? x : item,
                      );
                      setTexts(a);
                    }}
                    placeholder={'Enter Text'}
                  />
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    const temp = [...texts] || [];
                    const index = temp.findIndex((i) => i.id === text.id);
                    if (index > -1) {
                      temp.splice(index, 1);
                      setTexts(temp);
                    }
                  }}
                  className={'dark:text-white'}
                >
                  Delete
                </button>
              </div>
            ))}
            <button
              className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={(e) => {
                e.preventDefault();
                const temp = texts ? [...texts] : [];
                temp.push({
                  id: new Date().getTime().toString(),
                  text: '',
                });
                setTexts(temp);
              }}
            >
              + Add Text Block
            </button>
          </div>
          <div className='flex justify-end text-right pt-2 pr-2  rounded-b border-t border-gray-200 dark:border-gray-600'>
            <button
              disabled={isPending}
              onClick={onClick}
              type='button'
              className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            >
              {texts === (node.texts || '') ? 'Close' : isPending ? 'Updating...' : 'Update'}
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <label htmlFor='cover-photo' className='block text-lg font-medium text-white mb-4'>
          Text
        </label>
        <textarea
          className='w-full h-[250px]'
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={'Text visible for a user in the app'}
        />
      </div>
      <div className='flex justify-end text-right pt-2 pr-2  rounded-b border-t border-gray-200 dark:border-gray-600'>
        <button
          disabled={isPending}
          onClick={onClick}
          type='button'
          className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        >
          {text === (node.text || '') ? 'Close' : isPending ? 'Updating...' : 'Update'}
        </button>
      </div>
    </>
  );
};

export default TextUpdateModal;
