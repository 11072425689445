import React, { useEffect } from 'react';
// import { redirect } from 'react-router-dom';

const MobileRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://equahealth.io';
  }, []);

  return (
    <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img className='mx-auto h-16 w-auto' src={require('../logo-small.png')} alt='Equa' />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>...Redirecting...</h2>
      </div>
    </div>
  );
};

export default MobileRedirect;
