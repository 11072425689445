import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { USER_REWARDS_TABLE_NAME } from './tableName';
import {
  UserRewardsType,
  UserRewardsTypeBase,
  UserRewardsTypeBuilder,
  UserRewardsTypeNew,
  UserRewardsTypeUpdate,
} from '../modeltypes/userRewards';
import { UserRewardsId } from '../modeltypes/id';

// noinspection JSUnusedGlobalSymbols
export const getNewUserRewardsId = getNewIdFor<UserRewardsId>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewUserRewards = addNewFor<UserRewardsId, UserRewardsTypeNew>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewUserRewards = createNewFor<UserRewardsId, UserRewardsTypeBuilder>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewUserRewards = setNewFor<UserRewardsId, UserRewardsTypeNew>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserRewardsRefById = getRefByIdFor<UserRewardsId>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserRewardsById = getByIdFor<UserRewardsId, UserRewardsType>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserRewardsByIdArray = getByIdArrayFor<UserRewardsId, UserRewardsType>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllUserRewards = getAllFor<UserRewardsType>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserRewardsByQuery = getByQueryFor<UserRewardsType>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllUserRewardsQuery = getAllQueryFor(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateUserRewards = updateDocumentFor<UserRewardsId, UserRewardsTypeUpdate>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteUserRewards = deleteDocumentFor<UserRewardsId>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchUserRewardsIdSet = watchIdSetFor<UserRewardsId>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserRewardsType = getValidateTypeFor<UserRewardsType>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserRewardsTypeBase = getValidateTypeBaseFor<UserRewardsTypeBase>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserRewardsTypeBuilder =
  getValidateTypeBuilderFor<UserRewardsTypeBuilder>(USER_REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserRewardsTypeNew = getValidateTypeNewFor<UserRewardsType>(USER_REWARDS_TABLE_NAME);
