import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import { addNewTaskSwitch, getTaskSwitchById, updateTaskSwitch } from '../../collections/taskswitches';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { TaskSwitchType } from '../../modeltypes/taskswitches';
import { toast } from 'react-toastify';
import { getAllTasks } from 'src/collections/tasks';
import { ReactSelectOptions } from '../../types/types';
import { getAllGoals } from '../../collections/goals';
import { uiFormatTimestamp } from '../../collections/shared';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';
// Constants

export type GoalItemType = {
  id: string;
  taskId: string;
};

const TaskSwitchForm = () => {
  const [taskSwitch, setThisTaskSwitch] = useState<TaskSwitchType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectableTasks, setSelectableTasks] = useState<ReactSelectOptions[] | []>([]);
  const [tags, setTags] = useState<ReactSelectOptions[]>([]);
  const [selectedTags, setSelectedTags] = useState<ReactSelectOptions[]>([]);

  // const [switches, setSwitches]= useState< GoalItemType | []>([]);
  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths, setValidateElement } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'TaskSwitches',
        path: '/taskswitches',
      },
      {
        label: id === 'new' ? 'New TaskSwitch' : 'Edit TaskSwitch',
        path: `/taskswitches/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    //resetForm
  } = useFormik<{
    title: string;
    pathType: string;
    description: string;
    locked: boolean;
    goalsLink: { id: string; taskId: string }[];
    tags?: string[];
  }>({
    initialValues: {
      title: '',
      pathType: 'taskSwitch',
      description: '',
      locked: true,
      goalsLink: [],
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required'),
      description: yup.string(),
    }),
    validate: (values) => {
      const errors: any = {};
      for (const goalSwitchItem of values.goalsLink) {
        if (!goalSwitchItem?.taskId) {
          errors.taskLink = "TaskSwitch Items can't be empty!";
        }
      }
      return errors;
    },

    onSubmit: async (newTaskSwitch) => {
      if (id !== undefined && id !== 'new') {
        toast
          .promise(updateTaskSwitch(id, newTaskSwitch), {
            pending: `Updating ${newTaskSwitch.title} TaskSwitch...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newTaskSwitch.title} TaskSwitch!`,
          })
          .then(async () => {
            navigate('/taskswitches');
          });
      } else if (id === 'new') {
        toast
          .promise(addNewTaskSwitch(newTaskSwitch), {
            pending: `Adding ${newTaskSwitch.title} TaskSwitch...`,
            error: "Can't do it now, try again.",
            success: `Added ${newTaskSwitch.title} TaskSwitch!`,
          })
          .then(async () => {
            navigate('/taskswitches');
          });
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currTaskSwitch = await getTaskSwitchById(id || '');
        if (currTaskSwitch === null) {
          navigate('/taskswitches');
          return;
        }

        setThisTaskSwitch(currTaskSwitch);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const disabledForm = false;

  useEffect(() => {
    // create the goals list

    // Get all tasks to select from
    const prepareTableData = async () => {
      if (taskSwitch) {
        const tasks = await getAllTasks();
        const selectableTasks = tasks.map((x) => {
          return {
            label: x.title || 'Bob',
            value: x.id,
          };
        });
        setSelectableTasks(selectableTasks);

        const goals = await getAllGoals();

        const x = goals.map((k) => ({
          id: k.id,
          taskId: taskSwitch.goalsLink.find((el) => el.id === k.id)?.taskId || '',
        }));
        // Save the into the values
        await setValues({
          title: taskSwitch.title || '',
          description: taskSwitch.description || '',
          pathType: 'taskSwitch',
          locked: taskSwitch.locked || false,
          tags: taskSwitch.tags || [],
          goalsLink: x,
        });

        const dbTags = await getAllTags();
        const selectorT = dbTags.map((dbTag: TagType) => ({
          label: `${dbTag.name}`,
          value: dbTag?.id,
        }));
        setTags(selectorT);

        if (taskSwitch?.tags?.length) {
          setSelectedTags(selectorT.filter((t) => taskSwitch?.tags?.includes(t?.value)));
        }
      }
    };
    prepareTableData().then(() => setIsLoading(false));
  }, [taskSwitch]);

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const changeTo = (id: string, to: 'DEV' | 'PROD') => {
    setValidateElement({
      id,
      type: 'taskSwitch',
      to,
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                  Task Switch Details <span style={{ fontSize: '80%' }}>(ID: {taskSwitch?.id || 'not yet'})</span>
                </label>
                <p>
                  <strong>Created:</strong>&nbsp;{uiFormatTimestamp(taskSwitch?.createdAt)}
                  &nbsp;&nbsp;
                  <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(taskSwitch?.updatedAt)}
                </p>
                {taskSwitch ? (
                  <>
                    <div className={'flex flex-row items-center mt-3'}>
                      <p className='font-bold mr-3'>Status:</p>
                      {taskSwitch?.productionStatus || 'WIP'}
                      {taskSwitch?.productionStatus === 'DEV' ? (
                        <button
                          type={'button'}
                          onClick={() => changeTo(taskSwitch?.id, 'PROD')}
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          Change to Prod
                        </button>
                      ) : null}
                      {!taskSwitch?.productionStatus ? (
                        <button
                          type={'button'}
                          onClick={() => changeTo(taskSwitch?.id, 'DEV')}
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          Change to Dev
                        </button>
                      ) : null}
                    </div>
                    <div className={'flex flex-row items-center mt-3'}>
                      <p className='font-bold mr-3'>Locked:</p>
                      {taskSwitch?.locked ? (
                        <CheckIcon className='h-5 w-5 text-green-500' />
                      ) : (
                        <XIcon className='h-5 w-5 text-red-400' />
                      )}
                    </div>
                  </>
                ) : null}

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='title'
                        id='title'
                        autoComplete='title'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.title ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.title}
                        onChange={handleChange}
                        placeholder={'TaskSwitch title'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'What is the purpose of this taskSwitch.  A user might see this'}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Tags:
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={tags || []}
                      isMulti={true}
                      value={selectedTags}
                      onChange={(vals) => {
                        setSelectedTags(vals as ReactSelectOptions[]);
                      }}
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                    />
                  </div>
                </div>

                <div className={`flex flex-col ${errors.goalsLink ? 'bg-red-50' : ''}`}>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                    <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                      Task Switch
                    </label>
                  </div>
                  <div>
                    {values.goalsLink?.map((goalSwitchItem, index) => (
                      <div
                        key={goalSwitchItem.id}
                        className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'
                      >
                        <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                          {goalSwitchItem?.id}
                        </label>
                        <div className='max-w-lg flex rounded-md shadow-sm flex flex-col gap-y-1'>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            <Select
                              options={selectableTasks}
                              value={selectableTasks.find((el) => el.value === goalSwitchItem?.taskId)}
                              onChange={(val) => {
                                setFieldValue(`goalsLink[${index}]`, {
                                  id: goalSwitchItem.id,
                                  taskId: val?.value,
                                });
                              }}
                              className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                            />
                            {errors && errors.goalsLink && (
                              <div className='text-red-600'>{errors?.goalsLink?.toString()}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/taskswitches')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {taskSwitch ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default TaskSwitchForm;
