import { LessonId } from '../modeltypes/id';
import { IdFactory, Timestamp } from '../models/dalaccess';
import React, { useCallback, useState } from 'react';
import { emptyStringToNull } from '../utility/GeneralUtilities';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

export type ExistingLesson = {
  id: LessonId;
  gDocID: string;
  lessonName: string;
  programName: string | null;
  updatedAt?: Timestamp;
};

export type ExistingLessonPickerProps = {
  lessons: ExistingLesson[];
  onSelect: (selectedLessonId: LessonId | null) => void;
};

export default function ExistingLessonPicker({ lessons, onSelect }: ExistingLessonPickerProps) {
  const [selectedId, setSelectedId] = useState<LessonId | null>(null);

  const onSelectChange = useCallback(
    function _onSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.value === '') {
        setSelectedId(null);
        onSelect(null);
      } else {
        const lessonId = IdFactory.LessonIdFromString(event.target.value);
        setSelectedId(lessonId);
        onSelect(lessonId);
      }
    },
    [onSelect],
  );

  if (!lessons.length) {
    return null;
  }

  return (
    <>
      <br />
      <hr />
      <br />

      <span style={{ fontSize: 'larger', fontWeight: 'bolder' }}>
        There are {lessons.length} existing &nbsp;{lessons.length === 1 ? 'lesson' : 'lessons'}&nbsp; imported from this
        same Google Doc previously. Would you like to overwrite/update one? Select it below. Otherwise a new lesson will
        be created.
      </span>
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='mt-8 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope='col' className='center whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='h-10 w-10 flex-shrink-0'>
                          <input
                            type={'radio'}
                            value={''}
                            checked={!selectedId}
                            name={'selectedLesson'}
                            onSelect={onSelectChange}
                            onChange={onSelectChange}
                          />
                        </div>
                        <p className='flex-wrap text-center text-sm font-semibold text-gray-900'>Create New</p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Lesson ID
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Program
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Lesson Title
                      </th>

                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Google Doc ID
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Last Updated At
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {lessons?.map((lesson) => (
                      <tr key={lesson.id} className='hover:bg-stone-200'>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='h-10 w-10 flex-shrink-0'>
                            <input
                              type={'radio'}
                              value={lesson.id}
                              checked={selectedId === lesson.id}
                              name={'selectedLesson'}
                              onSelect={onSelectChange}
                              onChange={onSelectChange}
                            />
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{lesson.id}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{lesson?.programName || '?'}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{lesson.lessonName}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{lesson.gDocID}</div>
                          {!emptyStringToNull(lesson.gDocID) ? null : (
                            <a
                              href={`https://docs.google.com/document/d/${lesson.gDocID}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {' '}
                              <Icon path={mdiOpenInNew} size={1} style={{ display: 'inline-block' }} />
                            </a>
                          )}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{lesson.updatedAt?.toDate().toLocaleString()}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
    </>
  );
}
