import { LearningGameType } from '../../../modeltypes/learningGames';
import { QuizType } from '../../../modeltypes/quizItems';

const REQUIRED_KEYS_LEARNING_GAME: Array<keyof LearningGameType> = ['title'];

export const validateLearningGame = (
  learningGame: LearningGameType | null,
  quizQuestions: QuizType[] | null,
): [string[], string[]] => {
  const errors = [];
  const warnings: string[] = [];

  if (!learningGame) {
    errors.push('CRITICAL - no learning game');
    return [errors, warnings];
  }

  for (const key of REQUIRED_KEYS_LEARNING_GAME) {
    if (!learningGame[key]) {
      errors.push(`Missing ${key}`);
    }
  }

  if (!quizQuestions || quizQuestions.length === 0) {
    errors.push(`LEARNING GAME - No Quiz Items attached!`);
  } else {
    for (const quizItem of quizQuestions) {
      if (!quizItem.correctAnswersIds || quizItem.correctAnswersIds.length === 0) {
        errors.push(`Missing content for node ${quizItem.id} - at least one correct answer) `);
      }

      if (!quizItem.question) {
        errors.push(`Missing content for node ${quizItem.id} - missing question) `);
      }
      if (!quizItem.explanation) {
        warnings.push(`Missing content for node ${quizItem.id} - missing Explanation) `);
      }
    }
  }

  return [errors, warnings];
};
