import { ref, uploadBytes, getDownloadURL, storage } from '../models/storageaccess';

export async function uploadFile(file: File | Blob, finalFileName: string, pathAsArray: string[]): Promise<string> {
  const path = pathAsArray.reduce((prev, next) => `${prev}/${next}/`);
  const storageRef = ref(storage, `${path}${finalFileName}`);
  const response = await uploadBytes(storageRef, file);
  return getDownloadURL(response.ref);
}

export type UploadFileResult = {
  downloadUrl: string | null;
  error?: Error | string | null;
};

export async function uploadFileNoThrow(
  file: File | Blob,
  finalFileName: string,
  pathAsArray: string[],
): Promise<UploadFileResult> {
  try {
    const dlurl = await uploadFile(file, finalFileName, pathAsArray);
    return { downloadUrl: dlurl };
  } catch (e) {
    return {
      downloadUrl: null,
      error: e as Error,
    };
  }
}
