/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useRef } from 'react';
import { useAppContext } from '../contexts/appContext';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebaseConfig';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { emptyCallback } from '../utility/GeneralUtilities';

const SignInPage = () => {
  const context = useAppContext();
  const passRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [signInWithEmailAndPassword, , loading, hookError] = useSignInWithEmailAndPassword(auth);
  const { handleChange, handleSubmit } = useFormik({
    validationSchema: yup.object().shape({
      email: yup.string().required('Email is required'),
      password: yup.string().required('Password is required'),
    }),
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: ({ email, password }) => {
      signInWithEmailAndPassword(email, password).then(emptyCallback);
    },
  });

  return (
    <>
      <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img className='mx-auto h-16 w-auto' src={require('../logo-small.png')} alt='Equa' />
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>Sign in to your account</h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form
              className='space-y-6'
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div>
                <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                  Email address
                </label>
                <div className='mt-1'>
                  <input
                    ref={emailRef}
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-stone-500 focus:border-stone-500 sm:text-sm'
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                  Password
                </label>
                <div className='mt-1'>
                  <input
                    ref={passRef}
                    id='password'
                    name='password'
                    type='password'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-stone-500 focus:border-stone-500 sm:text-sm'
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='flex items-center justify-between'>
                {/*<div className='flex items-center'>*/}
                {/*  <input*/}
                {/*    id='remember-me'*/}
                {/*    name='remember-me'*/}
                {/*    type='checkbox'*/}
                {/*    className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'*/}
                {/*  />*/}
                {/*  <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>*/}
                {/*    Remember me*/}
                {/*  </label>*/}
                {/*</div>*/}

                {/*  <div className='text-sm'>*/}
                {/*    <a href='#' className='font-medium text-gray-600 hover:text-gray-500'>*/}
                {/*      Forgot your password?*/}
                {/*    </a>*/}
                {/*  </div>*/}
              </div>

              <div>
                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stone-400'
                >
                  Sign in
                </button>
              </div>
              <div>
                <p className='text-red-700 text-xl text-center font-bold'>
                  {!loading && (context.error || hookError?.message)}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
