import { Timestamp } from '../models/dalaccess';
import { HasId, WithRequired } from './shared';
import { CoachId, CohortId, CohortMeetingId } from './id';

export enum MeetingTypeEnum {
  DROP_IN = 'drop_in',
  GROUP = 'group',
  PRIVATE = 'private',
}

export type CohortMeetingType = HasId<CohortMeetingId> & {
  coach?: CoachId;
  cohort?: CohortId;
  comments?: string[];
  date: Timestamp;
  description?: string;
  location?: string | null;
  rating?: string[];
  type?: MeetingTypeEnum;
  url?: string;
  title?: string;
  lengthMins?: number;
};

// Base == Everything is optional.
export type CohortMeetingTypeBase = Partial<CohortMeetingType>;

// Builder == Everything except `id` is optional.
export type CohortMeetingTypeBuilder = WithRequired<CohortMeetingTypeBase, 'id'>;

// New == Everything is as normal, except that `id` is optional (because the DB will fill it)
// noinspection JSUnusedGlobalSymbols
export type CohortMeetingTypeNew = Omit<CohortMeetingType, 'id'> & Partial<Pick<CohortMeetingType, 'id'>>;
