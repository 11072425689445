// *******************************************************
// HomeworkAnalysis
// -------------------------------------------------------
// This is a Screen for showing list all Statistics for week to week
// Mat wants the following fds
// Downloads
// Subscriptions
// Active Users
// Average time in lesson per user

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
import { getAllUnits } from '../../collections/units';
import { UnitType } from 'src/modeltypes/unit';
import { getAllTasks } from 'src/collections/tasks';
import { TaskType } from 'src/modeltypes/tasks';
import { TrackType } from 'src/modeltypes/tracks';
import { getAllTracks } from 'src/collections/tracks';
import { getSessionsByQuery } from 'src/collections/session';
import { SessionType } from 'src/modeltypes/session';
import { getAllLessonHomeworks } from 'src/collections/lessonHomework';
import { LessonHomeworkType } from 'src/modeltypes/lessonHomework';
import { where } from 'firebase/firestore';
import { AnalyticsType } from 'src/modeltypes/analytics';
import { isYesterday } from 'date-fns';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

const HomeworkAnalysis = ({ analytics }: { analytics: AnalyticsType[] | undefined }) => {
  const [units, setUnits] = useState<UnitType[] | null>(null);
  const [tasks, setTasks] = useState<TaskType[] | null>(null);
  const [tracks, setTracks] = useState<TrackType[] | null>(null);
  const [doneSesh, setDoneSessions] = useState<SessionType[] | null>(null);
  const [notDoneSesh, setNotDoneSessions] = useState<SessionType[] | null>(null);
  const [lessonHomework, setLessonHomework] = useState<LessonHomeworkType[] | null>(null);

  useEffect(() => {
    getAllUnits().then((fTracks) => setUnits(fTracks));
    getAllTasks().then((fTracks) => setTasks(fTracks));
    getAllTracks().then((fTracks) => setTracks(fTracks));

    getSessionsByQuery(where('homeworkStatus', '==', 'done')).then((sessions) => setDoneSessions(sessions));

    getSessionsByQuery(where('homeworkStatus', '==', 'not done')).then((sessions) => setNotDoneSessions(sessions));

    getAllLessonHomeworks().then((lessonHomework) => setLessonHomework(lessonHomework));
  }, []);

  const nodeDones = doneSesh?.reduce((doneMap: { [nodeId: string]: SessionType[] }, sesh) => {
    (doneMap[sesh.nodeId] = doneMap[sesh.nodeId] || []).push(sesh);
    return doneMap;
  }, {});
  console.log('asdft nodeDones: ', nodeDones);
  // console.log('asdft analytics: ', analytics);
  const getLastTodaysAnalytics = analytics?.filter(
    (analytics) => isYesterday(analytics.createdAt.toDate()) && analytics.type === 'TOTAL',
  );
  console.log('asdft getLastTodaysAnalytics: ', getLastTodaysAnalytics);

  const sessions = useMemo(() => {
    let thisS: { taskId: string; done: SessionType[]; notDone: SessionType[] }[] = [];

    if (tasks?.length && notDoneSesh?.length && doneSesh?.length) {
      const taskItems = tasks.map((task) => {
        const taskId = task.id || 'noId';
        const thisCompletedTasks = doneSesh?.filter((session) => session.nodeId === taskId) || [];
        console.log('asdfthisCompletedTasks?.length: ', thisCompletedTasks?.length);

        const thisNotCompletedTasks = notDoneSesh?.filter((session) => session.nodeId === taskId) || [];
        // console.log('asdf thisNotCompletedTasks?.length: ', thisNotCompletedTasks?.length);

        return { taskId, done: thisCompletedTasks, notDone: thisNotCompletedTasks };
      });

      thisS = taskItems;
    }

    return thisS;
  }, [doneSesh, notDoneSesh, tasks]);

  const lessonHomeworkBreakdown = useMemo(() => {
    let thisS: { taskId: string; done: LessonHomeworkType[] }[] = [];

    if (tasks?.length && notDoneSesh?.length && doneSesh?.length) {
      const taskItems = tasks.map((task) => {
        const taskId = task.id || 'noId';
        const thisCompletedTasks = lessonHomework?.filter((lessonHomework) => lessonHomework.nodeId === taskId) || [];
        console.log('asdfthisCompletedTasks?.length: ', thisCompletedTasks?.length);
        return { taskId, done: thisCompletedTasks };
      });

      thisS = taskItems;
    }

    return thisS;
  }, [lessonHomework, tasks]);

  console.log('lessonHomeworkBreakdown: ', lessonHomeworkBreakdown);

  console.log('sessions?.length: ', sessions?.length);
  console.log('doneSesh?.length: ', doneSesh?.length);
  console.log('notDoneSesh?.length: ', notDoneSesh?.length);
  console.log('lessonHomework?.length: ', lessonHomework?.length);

  const unitsWithTasks = units?.filter((unit) => unit?.elements.some((unitElement) => unitElement.pathType === 'task'));
  // console.log('unitsWithTasks: ', unitsWithTasks);
  // // console.log('units: ', units);
  // // console.log('tasks: ', tasks);

  return (
    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Unit
                </th>
                <th scope='col' className='py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6'>
                  Track
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Tasks
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  #&nbsp;Number of Task Items
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  #&nbsp;Task Items
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Lesson Homework Tags
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Unit Tags
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  #&nbsp;Total
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  #&nbsp;Completed
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  #&nbsp;Not
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {unitsWithTasks?.map((unit) => {
                const thisTrack = tracks?.find((track) => track.units.find((trackUnit) => trackUnit.id === unit.id));
                const thisUnitTask = unit?.elements?.find((unitElement) => unitElement.pathType === 'task');
                const thisTask = tasks?.find((track) => track.id === thisUnitTask?.id);
                const thisLessonHomework = lessonHomeworkBreakdown?.find((lH) => lH?.taskId === thisTask?.id);
                const thisCompletedTasks = doneSesh?.filter((session) => session.nodeId === thisTask?.id) || [];
                const thisNotCompletedTasks = notDoneSesh?.filter((session) => session.nodeId === thisTask?.id) || [];
                console.log('thisCompletedTasks: ', thisCompletedTasks);
                const thisLesson = thisTask?.tags?.find((tag) => tag.includes('LESSON'));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // const matchedLessonTotal = Object.values(getLastTodaysAnalytics[0]);
                const introProgress = getLastTodaysAnalytics?.[0]?.allUsersTotal?.introProgress || [];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const postIntroSeriesProgress = getLastTodaysAnalytics?.[0]?.postIntroSeriesProgress || [];
                const userCount = [...introProgress, ...postIntroSeriesProgress];

                const matchedLessonTotal = userCount?.find((item) => item.label === thisLesson);
                console.log('asdfasdf thisLesson: ' + thisLesson);
                console.log('asdfasdf matchedLessonTotal: ' + matchedLessonTotal);
                console.log('asdfasdf matchedLessonTotal?.label: ' + matchedLessonTotal?.label);
                console.log('asdfasdf matchedLessonTotal?.userCount: ' + matchedLessonTotal?.userCount);

                // console.log('thisCompletedTasks: ' + thisCompletedTasks);
                // console.log('thisNotCompletedTasks: ' + thisNotCompletedTasks);

                // console.log('thisTrack: ' + thisTrack);
                // console.log('unit?.elements: ' + unit?.elements);
                // console.log('thisUnitTask: ' + thisUnitTask);
                // console.log('thisTask: ' + thisTask);

                return (
                  <tr key={unit.id} className='hover:bg-stone-200'>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>
                        {unit.title} ({unit.id})
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>
                        {thisTrack?.title} ({thisTrack?.id})
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='text-gray-500'>
                        <span
                          style={{
                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '250pt',
                          }}
                        >
                          {thisTask?.title} {thisTask?.id}
                        </span>
                      </div>
                    </td>
                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisTask?.taskItems?.length || 0}
                    </td>

                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='ml-4 flex items-center flex-col '>
                        {thisTask?.taskItems?.length ? (
                          <>
                            {thisTask?.taskItems?.map(({ text, minutes, taskType }, idx) => {
                              return (
                                <div key={idx} className='text-gray-500'>
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      width: '250pt',
                                    }}
                                  >
                                    {text} {taskType} - {minutes}minutes
                                  </span>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className='text-gray-500'>-</div>
                        )}
                      </div>
                    </td>
                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisTask?.tags?.length ? (
                        <div>
                          {thisTask?.tags?.length
                            ? thisTask.tags?.map((tag) => (
                                <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                  {tag}
                                </div>
                              ))
                            : 'No Tags'}
                        </div>
                      ) : (
                        <div className='text-gray-500'></div>
                      )}
                    </td>

                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {unit?.tags?.length ? (
                        <div>
                          {unit?.tags?.length
                            ? unit.tags?.map((tag) => (
                                <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                  {tag}
                                </div>
                              ))
                            : 'No Tags'}
                        </div>
                      ) : (
                        <div className='text-gray-500'></div>
                      )}
                    </td>
                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {matchedLessonTotal?.userCount}
                    </td>

                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisLessonHomework?.done?.length}
                    </td>

                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisNotCompletedTasks.length}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  // const getWeek = (currentdate: any) => {
  //   const oneJan: any = new Date(currentdate.getFullYear(), 0, 1);
  //   const numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  //   const result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  //   return result;
  // };

  // // Chart Details
  // const chartDetails = [
  //   {
  //     chart: 'totalUsers',
  //     title: 'Total Users',
  //     xLabel: 'Week',
  //     yLabel: 'User Count',
  //     color: colors.primary,
  //   },
  //   {
  //     chart: 'subscriptions',
  //     title: 'Subscriptions',
  //     xLabel: 'Week',
  //     yLabel: 'User Count',
  //     color: colors.secondary,
  //   },
  //   {
  //     chart: 'averageTimeInLessonsPerUser',
  //     title: 'Average Time In Lessons Per User',
  //     xLabel: 'Week',
  //     yLabel: 'Minutes',
  //     color: colors.yellow,
  //   },
  //   {
  //     chart: 'expiredSubscriptions',
  //     title: 'Expirations per Week',
  //     xLabel: 'Week',
  //     yLabel: 'User Count',
  //     color: colors.primary,
  //   },

  //   {
  //     chart: 'churn',
  //     title: 'Churn Rate ',
  //     xLabel: 'Week',
  //     yLabel: 'Percentage',
  //     color: colors.yellow,
  //   },

  //   {
  //     chart: 'activeWeeklyUsers',
  //     title: 'Weekly Active Users ',
  //     xLabel: 'Week',
  //     yLabel: 'userCount',
  //     color: colors.yellow,
  //   },
  // ];

  // // Put in a selector just in case you want to chage to subscription, cohort, etc.
  // const [currentWeekType, setCurrentWeekType] = useState('ALL');

  // const weeklyBreakdownTotalValues = useMemo(() => {
  //   let thisTotalKey: any;

  //   if (currentWeekType === 'ALL') {
  //     thisTotalKey = 'allUsersTotal';
  //   }
  //   if (currentWeekType === 'B2C') {
  //     thisTotalKey = 'b2cTotal';
  //   }
  //   if (currentWeekType === 'COMPANYS') {
  //     thisTotalKey = 'companyTotal';
  //   }

  //   if (currentWeekType === 'COHORTS') {
  //     thisTotalKey = 'cohortTotal';
  //   }
  //   if (currentWeekType === 'SUBSCRIPTIONS') {
  //     thisTotalKey = 'subscriptionsTotal';
  //   }

  //   if (analytics?.length) {
  //     // FIRST WE ARE GOING TO GET ALL DAILY DATA...
  //     const dailyData = analytics
  //       ?.filter((analytic) => analytic?.type === 'TODAY')
  //       .sort((a: any, b: any) => {
  //         const thisA = a.createdAt.toDate() || 0;
  //         const thisB = b.createdAt.toDate() || 0;
  //         return thisB - thisA;
  //       });

  //     const totalData = analytics
  //       ?.filter((analytic) => analytic?.type === 'TOTAL')
  //       .sort((a: any, b: any) => {
  //         const thisA = a.createdAt.toDate() || 0;
  //         const thisB = b.createdAt.toDate() || 0;
  //         return thisB - thisA;
  //       });

  //     const weeksDaily = dailyData?.reduce((weekMap: { [week: string]: any[] }, data) => {
  //       const date = data.createdAt.toDate();
  //       const thisWeek = getWeek(date);
  //       const thisYear = date.getFullYear();

  //       const thisKey = `${thisYear}-${thisWeek}`;

  //       (weekMap[thisKey] = weekMap[thisKey] || []).push(data);
  //       return weekMap;
  //     }, {});

  //     const weeksTotal = totalData?.reduce((weekMap: { [week: string]: any[] }, data) => {
  //       const date = data.createdAt.toDate();
  //       const thisWeek = getWeek(date);
  //       const thisYear = date.getFullYear();

  //       const thisKey = `${thisYear}-${thisWeek}`;

  //       (weekMap[thisKey] = weekMap[thisKey] || []).push(data);
  //       return weekMap;
  //     }, {});

  //     console.log('weeksTotal: ', weeksTotal);

  //     const desiredKeys = Object.keys(weeksDaily);

  //     const derivedData: any[] = [];

  //     desiredKeys.forEach((thisWeekString) => {
  //       const totalWeekData = weeksTotal?.[thisWeekString];
  //       const totalUserData = totalWeekData?.map((data) => data?.[thisTotalKey]);

  //       const date = totalWeekData?.[totalWeekData.length - 1].createdAt.toDate();
  //       const thisWeek = getWeek(date);
  //       const thisYear = date.getFullYear();
  //       const startDate = totalWeekData?.[totalWeekData.length - 1].createdAt.toDate();
  //       const endDate = totalWeekData?.[0].createdAt.toDate();

  //       const derivedDailyData = {};

  //       const LessonsPerUser = totalUserData.reduce(
  //         (sum: number, { averageLessonsPerUser }: { averageLessonsPerUser: number }) => sum + averageLessonsPerUser,
  //         0,
  //       );

  //       const averageLessonsPerUser = LessonsPerUser / totalUserData.length;

  //       const timeInLessonsPerUser = totalUserData.reduce(
  //         (sum: number, { averageTimeInLessonsPerUser }: { averageTimeInLessonsPerUser: number }) =>
  //           sum + averageTimeInLessonsPerUser,
  //         0,
  //       );

  //       const averageTimeInLessonsPerUser = timeInLessonsPerUser / totalUserData.length;

  //       const firstNewUsers = totalUserData?.[totalUserData.length - 1]?.totalUsers;
  //       const lastNewUsers = totalUserData?.[0]?.totalUsers;

  //       const newUsers = lastNewUsers - firstNewUsers;

  //       const firstExpiredSubscription = totalUserData?.[totalUserData.length - 1]?.expiredSubscriptionAccounts;
  //       const lastExpiredSubscription = totalUserData?.[0]?.expiredSubscriptionAccounts;

  //       const expiredSubscriptions = lastExpiredSubscription - firstExpiredSubscription;

  //       const firstSubscription = totalUserData?.[totalUserData.length - 1]?.subscriptionAccounts;
  //       const lastSubscription = totalUserData?.[0]?.subscriptionAccounts;

  //       const lastWeeklyActiveAccount = totalUserData?.[0]?.weeklyActiveUsers;

  //       console.log('lastWeeklyActiveAccount: ', lastWeeklyActiveAccount);

  //       const totalSubscriptions = lastSubscription - firstSubscription;

  //       const totalUsers = totalUserData?.[totalUserData.length - 1]?.totalUsers;

  //       //FROM https://blog.hubspot.com/service/what-is-churn-rate...
  //       //Customer Churn Rate = (Lost Customers ÷ Total Customers at the Start of Time Period) x 100

  //       const churn = !expiredSubscriptions
  //         ? 0
  //         : !totalSubscriptions
  //         ? 0
  //         : (expiredSubscriptions / totalSubscriptions) * 100;

  //       // console.log('churn totalSubscriptions', totalSubscriptions);
  //       // console.log('churn expiredSubscriptions', expiredSubscriptions);
  //       // console.log(totalStartDate, ' churn : ', churn);

  //       // Downloads
  //       // Subscriptions
  //       // Active Users
  //       // Average time in lesson per user

  //       const derivedTotalData = {
  //         week: thisWeek,
  //         year: thisYear,
  //         id: thisWeekString,
  //         startDate,
  //         endDate,
  //         weekString: `${format(date, 'MMM')}- ${thisWeek - 1}`,
  //         expiredSubscriptions,
  //         averageLessonsPerUser,
  //         averageTimeInLessonsPerUser,
  //         subscriptions: totalSubscriptions,
  //         totalUsers,
  //         newUsers,
  //         activeWeeklyUsers: lastWeeklyActiveAccount,
  //         churn,
  //       };

  //       derivedData.push({ ...derivedDailyData, ...derivedTotalData });
  //     });

  //     console.log('derivedData: ', derivedData);

  //     return derivedData;
  //   }

  //   return null;
  // }, [analytics, currentWeekType]);

  // const orderedData = weeklyBreakdownTotalValues?.sort((a: any, b: any) => {
  //   const thisA = a.startDate || 0;
  //   const thisB = b.startDate || 0;
  //   return thisA - thisB;
  // });

  // const keysWeAreGoingToChart = [
  //   'totalUsers',
  //   'activeWeeklyUsers',
  //   'averageLessonsPerUser',
  //   'averageTimeInLessonsPerUser',
  //   'subscriptions',
  //   'expiredSubscriptions',
  //   'churn',
  // ];

  // console.log('weeklyBreakdownTotalValues : ', weeklyBreakdownTotalValues);

  // return (
  //   <>
  //     <div className='flex'>WEEK ON WEEK</div>
  //     <Select
  //       options={AnalysisTypeSelector}
  //       value={AnalysisTypeSelector.find((e) => currentWeekType === e.value)}
  //       onChange={(option) => {
  //         setCurrentWeekType(option?.value || 'ALL');
  //       }}
  //       className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
  //     />

  //     {keysWeAreGoingToChart.map((thisKey) => {
  //       const thisChartDetails = chartDetails.find((x) => x.chart === thisKey);

  //       const thisKeyChartData =
  //         orderedData?.map((oD) => {
  //           const thisValue = oD?.[thisKey];
  //           const thisLabel = `${oD?.weekString} ${oD?.year}`;

  //           return {
  //             x: thisLabel,
  //             y: thisValue,
  //           };
  //         }) || [];

  //       const tickValues = thisKeyChartData.map((z) => z.y);
  //       console.log('tickValues', tickValues);

  //       return (
  //         <div key={thisKey}>
  //           <BarChart
  //             chartData={[
  //               {
  //                 data: thisKeyChartData,
  //                 fill: thisChartDetails?.color,
  //                 name: thisKey,
  //               },
  //             ]}
  //             chartTitle={thisChartDetails?.title || thisKey}
  //             tickValues={tickValues}
  //             verticalLabel
  //             barWidth={10}
  //             domainPadding={10}
  //             tooltipsEnabled
  //             xLabel={thisChartDetails?.xLabel || 'Week'}
  //             yLabel={thisChartDetails?.yLabel || 'User Count'}
  //           />
  //         </div>
  //       );
  //     })}
  //   </>
  // );
};

export default HomeworkAnalysis;
