import React from 'react';
import Icon from '@mdi/react';
import { mdiAlertOutline as warnOutline } from '@mdi/js';
import { mdiAlert as warnSolid } from '@mdi/js';
import { mdiAlertOctagonOutline as errorOutline } from '@mdi/js';
import { mdiAlertOctagon as errorSolid } from '@mdi/js';

const warningBGColor = '#FFFBE6';
const warningFGColor = '#A56109';
const errorBGColor = '#FFF0F0';
const errorFGColor = 'darkred';

export type WarningErrorListProps = {
  isWarnNotError: boolean;
  items: string[];
};

export default function WarningErrorList({ isWarnNotError, items }: WarningErrorListProps) {
  if (!items.length) {
    return null;
  }

  const bgColor = isWarnNotError ? warningBGColor : errorBGColor;
  const fgColor = isWarnNotError ? warningFGColor : errorFGColor;
  const outlineIcon = isWarnNotError ? warnOutline : errorOutline;
  const solidIcon = isWarnNotError ? warnSolid : errorSolid;

  const headerText = isWarnNotError ? `Warnings (${items.length}):` : `Errors (${items.length}):`;
  return (
    <div className={'w-full'}>
      <div style={{ backgroundColor: bgColor, flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
        <span style={{ color: fgColor, fontWeight: 800, display: 'inline-block' }}>
          <Icon
            path={solidIcon}
            title={isWarnNotError ? 'Warnings' : 'Errors'}
            size={1}
            style={{ display: 'inline-block' }}
          />
          {headerText}
        </span>
      </div>
      {items.map((str, idx) => (
        <div style={{ backgroundColor: bgColor, paddingTop: 5, paddingBottom: 5 }} key={idx.toString()}>
          <hr />
          <span style={{ color: fgColor, fontWeight: 500, display: 'inline-block', paddingLeft: 15 }}>
            <Icon
              path={outlineIcon}
              title={isWarnNotError ? 'Warning' : 'Error'}
              size={1}
              style={{ display: 'inline-block' }}
            />
            {str}
          </span>
        </div>
      ))}
    </div>
  );
}
