import { ChallengeSwitchType } from '../../../modeltypes/challengeswitches';

const REQUIRED_KEYS_CHALLENGE_SWITCH: Array<keyof ChallengeSwitchType> = ['title', 'description', 'goalsLink'];

export const validateChallengeSwitch = (challengeSwitch: ChallengeSwitchType | null) => {
  const errors = [];

  if (!challengeSwitch) {
    errors.push(`CRITICAL CHALLENGE SWITCH - missing challenge switch`);
    return errors;
  }

  for (const key of REQUIRED_KEYS_CHALLENGE_SWITCH) {
    if (!challengeSwitch[key]) {
      errors.push(`CHALLENGE SWITCH - Missing ${key}`);
    }
  }

  if (challengeSwitch.goalsLink.length === 0) {
    errors.push(`CHALLENGE SWITCH - No goal links attached!`);
  } else {
    for (const link of challengeSwitch.goalsLink) {
      if (!link.id) {
        errors.push(`CHALLENGE SWITCH - Missing goal link id for one of the item`);
      } else {
        if (!link.challengeId) {
          errors.push(`CHALLENGE SWITCH - Missing challengeId for ${link.id}`);
        }
      }
    }
  }

  return errors;
};
