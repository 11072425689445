// *******************************************************
// FunctionRunDetails
// -------------------------------------------------------
// This is a FunctionRunDetails
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { FunctionRunType } from '../../modeltypes/functionRun';
import { useEffect, useState } from 'react';
import { getFunctionRunById } from '../../collections/functionRuns';
import { useParams } from 'react-router-dom';
import { getFullDate } from '../../utility/csvUtility';
import { Timestamp } from 'firebase/firestore';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const FunctionRunDetails = () => {
  console.log('FunctionRunDetails');
  const [functionRun, setFunctionRun] = useState<FunctionRunType | null>(null);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getFunctionRunById(id).then((fr) => {
        setFunctionRun(fr);
      });
    }
  }, []);

  const printOutLog = (log: { message: string; date: Timestamp; type: string }, index: number) => {
    let style = '';
    switch (log.type) {
      case 'ERROR': {
        style = 'bg-red-300 font-bold hover:bg-red-200';
        break;
      }
      case 'WARNING': {
        style = 'bg-yellow-300 font-bold hover:bg-yellow-100';
        break;
      }
      default: {
        style = 'text-gray-500 hover:bg-gray-200';
      }
    }
    return (
      <div className={`flex flex-row justify-between ${style} py-2 px-4`}>
        <div className={'flex flex-row'}>
          <p className={'w-[50px]'}>{index + 1}.</p>
          <p>{log.message}</p>
        </div>
        <p>{getFullDate(log.date)}</p>
      </div>
    );
  };

  return (
    <div>
      <div className={'flex flex-row'}>
        <div className={'flex-1'}>
          <p>
            Function: <span className={'font-bold text-xl'}>{functionRun?.functionName}</span>
          </p>
          <p>
            Started at: <span>{getFullDate(functionRun?.startedAt)}</span>
          </p>
          <p>
            Finished at: <span>{getFullDate(functionRun?.finishedAt)}</span>
          </p>
        </div>
        <div className='flex-1 flex items-center justify-center flex-col'>
          <p>Status</p>
          <p className={'font-bold text-lg'}>{functionRun?.status}</p>
        </div>
      </div>
      <div className={'mt-5'}>
        <p className={'text-xl font-bold mb-4'}>Updates</p>
        <div className={'flex flex-row'}>
          <div className={'px-4 overflow-scroll flex-1'}>
            <p>Updated accounts: {functionRun?.updates?.updated?.length || '-'}</p>
          </div>
          <div className={'px-4 overflow-scroll flex-1'}>
            <p>Not updated accounts: {functionRun?.updates?.notUpdated?.length || '-'}</p>
          </div>
        </div>
      </div>
      <div className={'mt-5'}>
        <p className={'text-xl font-bold mb-4'}>Logs</p>
        <div className={'px-4 h-[500px] overflow-scroll '}>{functionRun?.logs?.map(printOutLog)}</div>
      </div>
    </div>
  );
};

export default FunctionRunDetails;
