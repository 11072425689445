/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { audioNodeIdSchema, chapterIdSchema } from './id';
import { timestampType } from './shared';
import { IdFactory } from '../models/dalaccess';
import { ChapterType, ChapterTypeBase, ChapterTypeBuilder, ChapterTypeNew } from '../modeltypes/chapter';
import { AudioNodeId } from '../modeltypes/id';

export const chapterTypeSchema = z.object({
  id: chapterIdSchema,
  createdAt: timestampType().optional(),
  updatedAt: timestampType().optional(),
  title: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  icon: z.string().optional().nullable(),
  grayedIcon: z.string().optional().nullable(),
  comments: z.array(z.string()).optional(),
  audioNodes: z
    .array(audioNodeIdSchema)
    .optional()
    .default(() => new Array<AudioNodeId>()),
  importedFromGDocID: z.string().optional().nullable(),
});

export const chapterTypeBaseSchema = chapterTypeSchema.partial();

export const chapterTypeBuilderSchema = chapterTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: chapterIdSchema.default(() => IdFactory.Chapter()) }));

export const chapterTypeNewSchema = chapterTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: chapterIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: ChapterType = chapterTypeSchema.parse(undefined);
  const x: ChapterTypeBase = chapterTypeBaseSchema.parse(undefined);
  const y: ChapterTypeBuilder = chapterTypeBuilderSchema.parse(undefined);
  const z: ChapterTypeNew = chapterTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
