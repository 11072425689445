import {
  addNewFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getRefByIdFor,
} from './shared';
import { UserId } from '../modeltypes/id';
import { USER_LEADER_BOARD_TABLE_NAME } from './tableName';
import { StreakType, StreakTypeNew } from 'src/modeltypes/streaks';

// This is for the Leaderboard
export const addNewUserLeaderBoard = addNewFor<UserId, StreakTypeNew>(USER_LEADER_BOARD_TABLE_NAME);

export const getUserLeaderBoardRefById = getRefByIdFor<UserId>(USER_LEADER_BOARD_TABLE_NAME);

export const getUserLeaderBoardById = getByIdFor<UserId, StreakType>(USER_LEADER_BOARD_TABLE_NAME);

export const getUserLeaderBoardByIdArray = getByIdArrayFor<UserId, StreakType>(USER_LEADER_BOARD_TABLE_NAME);

export const _getAllUserLeaderBoards = getAllFor<StreakType>(USER_LEADER_BOARD_TABLE_NAME);

export const getAllUserLeaderBoardQuery = getAllQueryFor(USER_LEADER_BOARD_TABLE_NAME);

export const getUserLeaderBoardByQuery = getByQueryFor<StreakType>(USER_LEADER_BOARD_TABLE_NAME);

export const getAllUserLeaderBoard = getAllFor<StreakType>(USER_LEADER_BOARD_TABLE_NAME);
