// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { RewardId } from '../modeltypes/id';
import { RewardType, RewardTypeBase, RewardTypeBuilder, RewardTypeNew } from '../modeltypes/rewards';
import { REWARDS_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewRewardId = getNewIdFor<RewardId>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewReward = addNewFor<RewardId, RewardTypeNew>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewReward = createNewFor<RewardId, RewardTypeBuilder>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewReward = setNewFor<RewardId, RewardTypeBuilder>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getRewardRefById = getRefByIdFor<RewardId>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getRewardById = getByIdFor<RewardId, RewardType>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getRewardsByIdArray = getByIdArrayFor<RewardId, RewardType>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllRewards = getAllFor<RewardType>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getRewardsByQuery = getByQueryFor<RewardType>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllRewardsQuery = getAllQueryFor(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateReward = updateDocumentFor<RewardId, RewardTypeBase>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteReward = deleteDocumentFor<RewardId>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchRewardIdSet = watchIdSetFor<RewardId>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRewardType = getValidateTypeFor<RewardType>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRewardTypeBase = getValidateTypeBaseFor<RewardTypeBase>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRewardTypeBuilder = getValidateTypeBuilderFor<RewardTypeBuilder>(REWARDS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRewardTypeNew = getValidateTypeNewFor<RewardType>(REWARDS_TABLE_NAME);
