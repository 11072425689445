// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { TagId } from '../modeltypes/id';
import { TagType, TagTypeBase, TagTypeBuilder, TagTypeNew } from '../modeltypes/tags';
import { TAGS_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewTagId = getNewIdFor<TagId>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewTag = addNewFor<TagId, TagTypeNew>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewTag = createNewFor<TagId, TagTypeBuilder>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewTag = setNewFor<TagId, TagTypeNew>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTagRefById = getRefByIdFor<TagId>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTagById = getByIdFor<TagId, TagType>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTagsByIdArray = getByIdArrayFor<TagId, TagType>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTags = getAllFor<TagType>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTagsByQuery = getByQueryFor<TagType>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTagsQuery = getAllQueryFor(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateTag = updateDocumentFor<TagId, TagTypeBase>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteTag = deleteDocumentFor<TagId>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchTagIdSet = watchIdSetFor<TagId>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTagType = getValidateTypeFor<TagType>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTagTypeBase = getValidateTypeBaseFor<TagTypeBase>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTagTypeBuilder = getValidateTypeBuilderFor<TagTypeBuilder>(TAGS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTagTypeNew = getValidateTypeNewFor<TagType>(TAGS_TABLE_NAME);
