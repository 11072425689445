// *******************************************************
// Incentives Form
// -------------------------------------------------------
// This is a Component for Editing / adding incentives template
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as yup from 'yup';

// *******************************************
// Component Imports
// -------------------------------------------
// *******************************************
// Hooks Import
// -------------------------------------------
// *******************************************
// Action Imports
// -------------------------------------------
import {
  addNewIncentivesTemplate,
  getIncentivesTemplateById,
  updateIncentivesTemplate,
} from '../../collections/incentiveTemplate';
import { getAllCohorts, updateCohort } from '../../collections/cohort';

// *******************************************
// Styles Imports
// -------------------------------------------
// *******************************************
// Constants
// -------------------------------------------
import { MoneyType } from '../../enums/moneyType';
import { Pages } from '../../enums/pages';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { IncentiveTemplateType } from '../../modeltypes/incentiveTemplate';
import { CohortType } from '../../modeltypes/cohort';
import { toast } from 'react-toastify';

// *******************************************
// Types
// -------------------------------------------

const moneyTypeArray: { value: 'DOLLAR' | 'CREDIT'; label: MoneyType }[] = [
  {
    value: 'DOLLAR',
    label: MoneyType.DOLLAR,
  },
  {
    value: 'CREDIT',
    label: MoneyType.CREDIT,
  },
];

const IncentivesForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [incentive, setIncentive] = useState<IncentiveTemplateType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cohorts, setCohorts] = useState<{ label: string; value: string }[]>([]);
  const [selectedCohortId, setSelectedCohortId] = useState<string | null>(null);

  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Incentives',
        path: '/incentives',
      },
      {
        label: id !== 'new' ? `Edit Incentive` : `New Incentive`,
        path: `/incentives/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const { values, errors, handleSubmit, handleChange, setValues, resetForm } = useFormik<{
    name: string;
    description: string;
    moneyType: 'DOLLAR' | 'CREDIT';
  }>({
    initialValues: {
      name: '',
      description: '',
      moneyType: 'DOLLAR',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      description: yup.string(),
      moneyType: yup.string().required('Money Type is required').equals(['DOLLAR', 'CREDIT'], 'Wrong money type'),
    }),
    onSubmit: (values) => {
      if (incentive && id) {
        const dataToSave: IncentiveTemplateType = {
          ...incentive,
          name: values.name,
          description: values.description,
          moneyType: values.moneyType,
        };
        if (id === 'new' && selectedCohortId) {
          toast
            .promise(addNewIncentivesTemplate(dataToSave), {
              pending: `Adding ${dataToSave.name}...`,
              error: "Can't do it now, try again.",
              success: `Added ${dataToSave.name}!`,
            })
            .then((templateId) => {
              updateCohort(selectedCohortId, { incentives: templateId }).then(() => {
                navigate(`/${Pages.Incentives}`);
              });
            });
        } else if (id !== 'new') {
          toast
            .promise(updateIncentivesTemplate(id, dataToSave), {
              pending: `Updating ${dataToSave.name}...`,
              error: "Can't do it now, try again.",
              success: `Updated ${dataToSave.name}!`,
            })
            .then(() => {
              navigate(`/${Pages.Incentives}`);
            });
        }
      }
    },
  });

  useEffect(() => {
    const prepareData = async () => {
      if (id !== undefined && id !== 'new') {
        await getIncentivesTemplateById(id).then((incentive) => {
          if (incentive) {
            setIncentive(incentive);
            setValues({
              name: incentive.name || '',
              moneyType: incentive.moneyType,
              description: incentive.description || '',
            });
          }
        });
      } else if (id === 'new') {
        await getIncentivesTemplateById('emptyTemplate').then((incentive) => {
          if (incentive) {
            setIncentive(incentive);
          }
        });
        await getAllCohorts().then((dbCohorts) => {
          const filtered = dbCohorts
            .filter((cohort): cohort is Required<CohortType> => !!cohort.id && !!cohort.name && !cohort.incentives)
            .map((cohort) => ({
              value: cohort.id,
              label: cohort.name,
            }));
          setCohorts(filtered);
        });
      }
    };
    prepareData().then(() => setIsLoading(false));
  }, [id, setValues]);

  const updateStageReward = (program: string, day: number, award: string) => {
    const tempStages = incentive?.stages;
    const index = tempStages?.findIndex((stage) => stage.program === program && stage.dayNumber === day);
    if (tempStages && index !== undefined && index >= 0) {
      tempStages[index].amount = Number.parseFloat(award || '0');
      setIncentive({
        ...incentive,
        stages: tempStages,
      });
    }
  };
  if (Object.values(errors).length > 0) {
    // console.log(errors);
  }
  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form
          className='space-y-8 divide-y divide-gray-200'
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                {id === 'new' ? (
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Choose Cohort *
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <Select
                        isClearable
                        options={cohorts}
                        onChange={(val) => {
                          if (val) {
                            setSelectedCohortId(val.value);
                          } else {
                            setSelectedCohortId(null);
                            resetForm();
                          }
                        }}
                        className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                        placeholder={'Type cohort name'}
                      />
                    </div>
                  </div>
                ) : null}
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Name
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        autoComplete='name'
                        className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                          errors.name ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Incentive Type
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={moneyTypeArray}
                      value={{
                        value: values.moneyType || '',
                        label: MoneyType[values.moneyType] || '',
                      }}
                      onChange={(val) => {
                        if (val) {
                          handleChange('moneyType')(val.value);
                        }
                      }}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                    />
                  </div>
                </div>
                <div className='sm:grid sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label
                    htmlFor='company'
                    className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 text-center w-100'
                  >
                    Rewards
                  </label>
                </div>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  {incentive?.stages.map(({ dayNumber, program, amount }) => (
                    <div
                      key={`${dayNumber}-${program}`}
                      className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'
                    >
                      <label
                        htmlFor='name'
                        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-center'
                      >
                        {`${program} - Day ${dayNumber}`}
                      </label>
                      <div className='max-w-lg flex rounded-md shadow-sm'>
                        <input
                          type='number'
                          name='name'
                          id='name'
                          step={incentive?.moneyType === 'DOLLAR' ? 1 : 0.5}
                          autoComplete='name'
                          className='m-auto focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 text-center'
                          value={amount || ''}
                          min={0}
                          onChange={(val) => {
                            updateStageReward(program, dayNumber, val.target.value);
                          }}
                        />
                      </div>
                      <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        {MoneyType[values?.moneyType]}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='pt-5'>
              <div className='flex justify-end'>
                <button
                  type='button'
                  className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => navigate(`/${Pages.Incentives}`)}
                >
                  Cancel
                </button>
                <button
                  disabled={id === 'new' && !selectedCohortId}
                  type='submit'
                  className={`${
                    id === 'new' && !selectedCohortId ? 'opacity-25' : ''
                  } ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  {id === 'new' ? 'Add' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default IncentivesForm;
