// *******************************************************
// LessonAudioChunksList
// -------------------------------------------------------
// This is a LessonAudioChunksList
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TagType } from '../../modeltypes/tags';
import { getAllTags } from '../../collections/tags';
import SearchBar from '../SearchBar';
import Skeleton from 'react-loading-skeleton';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { Pages } from '../../enums/pages';
import { LessonAudioChunkType } from '../../modeltypes/lessonAudioChunk';
import { deleteLessonAudioChunk, getAllLessonAudioChunks } from '../../collections/lessonAudioChunk';
import { confirmAlert } from 'react-confirm-alert';
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import ReactTooltip from 'react-tooltip';
import { getAllUnits } from '../../collections/units';
import { UnitType } from '../../modeltypes/unit';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
type ChunkWithUnit = LessonAudioChunkType & { units?: UnitType[] };
const LessonAudioChunksList = () => {
  const navigate = useNavigate();
  const [lessonAudioChunks, setLessonAudioChunks] = useState<ChunkWithUnit[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<TagType[] | null>(null);

  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    console.debug('LessonList.useEffect called.');
    const fetchData = async () => {
      const fetchedChunks = await getAllLessonAudioChunks();
      fetchedChunks.sort((a, b) => {
        const aUpdated = a.updatedAt ? a.updatedAt.toMillis() : 0;
        const bUpdated = b.updatedAt ? b.updatedAt.toMillis() : 0;
        return aUpdated < bUpdated ? 1 : aUpdated > bUpdated ? -1 : 0;
      });

      // Build a "back-map" so we can give each lesson a list of the programs that reference it.
      // fetchedChunks.forEach((l) => {
      //   l.referencingPrograms = [];
      // });
      // const lessonsById = new Map(fetchedChunks.map((l) => [l.id, l]));

      // Since we don't expect the programs table to get very big, and for the lessons table to
      // grow much bigger relative to programs, it's more efficient to just get them all, instead of
      // doing N 'array-contains' queries.
      const allUnits = await getAllUnits();
      const chunksWithUnits = fetchedChunks.map((chunk) => ({
        ...chunk,
        units: allUnits.filter((unit) => unit.elements.map((el) => el.id).includes(chunk.id)),
      }));
      setLessonAudioChunks(chunksWithUnits);
    };
    fetchData()
      .then(() => setIsLoading(false))
      .catch(console.error);
  }, [setLessonAudioChunks]);

  // async function deleteLessonId(lessonId: LessonId) {
  //   await deleteLessonRecursive(lessonId);
  //   const fetchData = async () => {
  //     const fetchedLessons = await getAllLessons();
  //     setLessonAudioChunks(fetchedLessons);
  //   };
  //   fetchData().catch(console.error);
  // }

  const onClickDelete = (chunkId: string) => {
    confirmAlert({
      title: `Confirm delete chunk (ID: ${chunkId})?`,
      message: 'Are you sure you want to delete this chunk? This operation cannot be undone.',
      buttons: [
        {
          label: 'Delete',
          onClick: async () => {
            await deleteLessonAudioChunk(chunkId);
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  };

  function elementUnits(lesson: ChunkWithUnit) {
    const units = lesson.units || [];
    if (units.length === 0) {
      return <span>None</span>;
    } else if (units.length === 1) {
      return <span>{units[0].title}</span>;
    } else if (units.length > 1) {
      const titles = units.map((p) => '&#x2022; ' + p.title).join('\n<br/>');
      return (
        <>
          <p data-html={true} data-tip={titles}>
            Multiple...
          </p>
          <ReactTooltip />
        </>
      );
    }
  }

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        {' '}
                        Id
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        {' '}
                        Title
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Description
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Tags
                      </th>

                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Status
                      </th>
                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Is Locked?
                      </th>
                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Hide CCE Surveys?
                      </th>

                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Is Movement Lesson?
                      </th>

                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Programs
                      </th>
                      <th
                        scope='col'
                        className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-semibold sm:pr-6'
                      >
                        Edit
                      </th>
                      <th
                        scope='col'
                        className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-semibold sm:pr-6'
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {lessonAudioChunks?.map((chunk) => (
                      <tr key={chunk.id} className='hover:bg-stone-200'>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='font-medium text-gray-900'>{chunk.id}</div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='font-medium text-gray-900'>{chunk.title}</div>
                        </td>
                        <td className=' px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500 flex flex-wrap w-[350px]'>{chunk.description || '-'}</div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {chunk?.tags?.length ? (
                                <div>
                                  {chunk.tags?.map((tag) => (
                                    <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                      {tags?.find((t) => t.id === tag)?.name}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className='text-gray-500'>-</div>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{chunk.productionStatus || 'WIP'}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {chunk.locked ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {chunk.hideCceSurvey ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {chunk.isMovementLesson ? (
                                <CheckIcon className='h-4 w-4' />
                              ) : (
                                <XIcon className='h-4 w-4' />
                              )}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                          {elementUnits(dieIfNullOrUndef(chunk))}
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                          <div
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => navigate(`/${Pages.LessonAudioChunks}/${chunk.id}`)}
                          >
                            Edit
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                          <button
                            disabled={chunk.productionStatus === 'PROD' || chunk.locked}
                            className={`${
                              chunk.productionStatus === 'PROD' || chunk.locked ? 'opacity-25' : 'opacity-100'
                            } text-indigo-600 hover:text-indigo-900`}
                            onClick={() => onClickDelete(dieIfNullOrUndef(chunk.id))}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonAudioChunksList;
