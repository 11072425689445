// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { TaskSwitchId } from '../modeltypes/id';
import {
  TaskSwitchType,
  TaskSwitchTypeBase,
  TaskSwitchTypeBuilder,
  TaskSwitchTypeNew,
} from '../modeltypes/taskswitches';
import { TASK_SWITCH_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewTaskSwitchId = getNewIdFor<TaskSwitchId>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewTaskSwitch = addNewFor<TaskSwitchId, TaskSwitchTypeNew>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewTaskSwitch = createNewFor<TaskSwitchId, TaskSwitchTypeBuilder>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewTaskSwitch = setNewFor<TaskSwitchId, TaskSwitchTypeNew>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTaskSwitchRefById = getRefByIdFor<TaskSwitchId>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTaskSwitchById = getByIdFor<TaskSwitchId, TaskSwitchType>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTaskSwitchesByIdArray = getByIdArrayFor<TaskSwitchId, TaskSwitchType>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTaskSwitches = getAllFor<TaskSwitchType>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTaskSwitchesByQuery = getByQueryFor<TaskSwitchType>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTaskSwitchesQuery = getAllQueryFor(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateTaskSwitch = updateDocumentFor<TaskSwitchId, TaskSwitchTypeBase>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteTaskSwitch = deleteDocumentFor<TaskSwitchId>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchTaskSwitchIdSet = watchIdSetFor<TaskSwitchId>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskSwitchType = getValidateTypeFor<TaskSwitchType>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskSwitchTypeBase = getValidateTypeBaseFor<TaskSwitchTypeBase>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskSwitchTypeBuilder = getValidateTypeBuilderFor<TaskSwitchTypeBuilder>(TASK_SWITCH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskSwitchTypeNew = getValidateTypeNewFor<TaskSwitchType>(TASK_SWITCH_TABLE_NAME);
