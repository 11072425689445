// *******************************************************
// SearchBar
// -------------------------------------------------------
// This is a SearchBar
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { useAppContext } from '../contexts/appContext';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const SearchBar = () => {
  const { searchTerm, setSearchTerm } = useAppContext();

  return (
    <div className='relative w-full text-gray-400 focus-within:text-gray-600 mb-5 md:rounded-lg shadow ring-1 ring-black ring-opacity-5'>
      <div className='absolute inset-y-0 left-2 flex items-center pointer-events-none'>
        <SearchIcon className='h-5 w-5' aria-hidden='true' />
      </div>
      <input
        id='search-field'
        className='rounded-xl block w-full h-full pl-9 pr-3 py-4 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
        placeholder='Search'
        type='search'
        name='search'
        onInput={(e) => setSearchTerm((e.target as HTMLInputElement).value)}
        value={searchTerm}
      />
    </div>
  );
};

export default SearchBar;
