import React, { memo, useEffect, useState } from 'react';
import { Handle, Position, NodeProps } from 'react-flow-renderer';
import { Experience, Goals } from 'src/enums/goals';
import { AudioNodeType, SwitchType } from '../../../modeltypes/audioNode';

export type SwitchTypeTs = {
  GOALS: 'GOALS';
  EXPERIENCE: 'EXPERIENCE';
};

export type SwitchNodeProps = {
  hide: (shouldRefresh?: boolean) => void;
  node: AudioNodeType;
};

const SwitchCustomNode = ({ id, data, isConnectable, type }: NodeProps<SwitchNodeProps>) => {
  const [thisSwitch, setThisSwitch] = useState<any>({});

  useEffect(() => {
    let x = {};

    if (data.node.subType === SwitchType.GOALS) {
      Object.values(Goals)?.map((item) => {
        x = { ...x, [item]: null };
      });
      setThisSwitch(x);
    }
    if (data.node.subType === SwitchType.EXPERIENCE) {
      setThisSwitch(Experience);
    }
  }, []);

  return (
    <div className='bg-white p-5'>
      <Handle
        type='target'
        position={Position.Top}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div className='text-center'>
        <p className='my-2'>{type}</p>
        <p>{data.node.code}</p>
        <p>
          Id: <strong>{id}</strong>
        </p>
        <p className='mt-4'>
          Switch Type: <span>{data.node.subType}</span>
        </p>
        <p className='mt-2'>Available Answers:</p>
        <div className={'bg-gray-100 p-3 my-2'}>
          {Object.keys(thisSwitch)?.map((item) => {
            console.log('item: ', item);
            return (
              <p key={item}>
                <strong>{item}</strong>:{' '}
                <strong>{`${
                  data.node.switchOptions?.find((e) => e.type === item)?.nextNode || 'Not Assigned'
                }`}</strong>
                {/* GOAL <strong>{item}</strong>: <strong>{thisSwitch?.[item] || 'Not Assigned'}</strong> */}
              </p>
            );
          })}
        </div>
      </div>
      <Handle type='source' position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

export default memo(SwitchCustomNode);
