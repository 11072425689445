// *******************************************************
// AudioUpdateModal
// -------------------------------------------------------
// This is a AudioUpdateModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyToCliboardIcon } from '../../../assets/copyToClipboard.svg';
import { FileUploader } from 'react-drag-drop-files';
import { useCallback, useState } from 'react';
import { uploadFileNoThrow } from '../../../firebase/storage';
import { dieIfNullOrUndef } from '../../../utility/GeneralUtilities';
import { toast } from 'react-toastify';
import { AudioNodeType } from '../../../modeltypes/audioNode';
import { updateAudioNode } from '../../../collections/audioNode';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface AudioUpdateModalProps {
  hide: (shouldRefresh?: boolean) => void;
  node: AudioNodeType;
  updateNodes: (nodeIds: string[]) => void;
}

const AudioUpdateModal = ({ hide, node, updateNodes }: AudioUpdateModalProps) => {
  const [newAudioUrl, setNewAudioUrl] = useState<string | null>(null);
  const [newAudioFile, setNewAudioFile] = useState<File | null>(null);
  const [isPending, setIsPending] = useState(false);

  const onClick = async () => {
    if (newAudioFile) {
      setIsPending(true);
      toast
        .promise(
          async () => {
            const storageFileName = `${node.id}-${node.code}-nodeAudio.m4a`;

            const uploadResult = await uploadFileNoThrow(dieIfNullOrUndef(newAudioFile), storageFileName, [
              'assets',
              node.id,
            ]);
            if (uploadResult.error) {
              throw uploadResult.error;
            }
            await updateAudioNode(node.id, { audioUrl: uploadResult.downloadUrl });
            updateNodes([node.id]);
          },
          {
            pending: `Updating Audio...`,
            error: "Can't do it now, try again.",
            success: `Updated Audio!`,
          },
        )
        .then(() => hide(true));
    } else {
      hide();
    }
  };

  const handleUploadDrop = useCallback((file: File): void => {
    const url = URL.createObjectURL(file);
    setNewAudioUrl(url);
    setNewAudioFile(file);
  }, []);
  return (
    <>
      <div>
        <label htmlFor='cover-photo' className='block text-lg font-medium text-white mb-4'>
          Existing Audio
        </label>
        <div className={`flex justify-center pt-5 pb-6 bg-white rounded-xl w-full mt-2`}>
          <div className='space-y-1 text-left'>
            {!node.audioUrl ? (
              <span className='text-sm'>None</span>
            ) : (
              <>
                <ReactAudioPlayer
                  src={node.audioUrl}
                  autoPlay={false}
                  controls={true}
                  style={{ display: 'inline-block' }}
                  controlsList='play volume'
                />
                <a
                  target='_blank'
                  rel='noreferrer noopener'
                  href={node.audioUrl}
                  className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                  onClick={() => false}
                  style={{ display: 'inline-block' }}
                  title={'Link to audio file.'}
                >
                  Audio File
                </a>
                <CopyToClipboard text={node.audioUrl}>
                  <button type={'button'}>
                    <CopyToCliboardIcon />
                  </button>
                </CopyToClipboard>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='w-full'>
        <label htmlFor='cover-photo' className='block text-lg font-medium text-white '>
          Upload Audio
        </label>
        <div className={`flex flex-col items-center  pt-3 pb-3 bg-white rounded-xl w-full mt-2`}>
          <div className={`text-center `}>
            <FileUploader
              handleChange={handleUploadDrop}
              name='file'
              types={['m4a', 'M4A']}
              multiple={false}
              hoverTitle={''}
              maxSize={10}
              minSize={0}
              label={'Select or drag an audio file here.'}
              required
            />
          </div>
          {newAudioUrl && (
            <ReactAudioPlayer
              src={newAudioUrl}
              autoPlay={false}
              controls={true}
              style={{ display: 'inline-block' }}
              controlsList='play volume'
              className='mt-4'
            />
          )}
        </div>
      </div>
      <div className='flex justify-end text-right pt-2 pr-2  rounded-b border-t border-gray-200 dark:border-gray-600'>
        <button
          disabled={isPending}
          onClick={onClick}
          type='button'
          className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        >
          {newAudioUrl && newAudioFile ? (isPending ? 'Uploading...' : 'Upload Audio') : 'Close'}
        </button>
      </div>
    </>
  );
};

export default AudioUpdateModal;
