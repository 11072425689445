// *******************************************************
// QuizCustomNode
// -------------------------------------------------------
// This is a QuizCustomNode
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { NodeType } from '../../modeltypes/node';
import { ModalType } from '../paths/sharedValues';
import { MoveIcon } from '../../assets/tabIcons/tabIcons';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

export interface QuizCustomNodeProps {
  node: NodeType | null;
  showModal: (modal: ModalType) => void;
}

const QuizCustomNode = ({ id, data, type, isConnectable }: NodeProps<QuizCustomNodeProps>) => {
  if (!data.node) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
  const answers = data.node.answers || [];

  return (
    <div className='flex flex-col bg-white p-5 hover:cursor-default text-[8px]'>
      <MoveIcon
        className='custom-drag-handle hover:cursor-grab'
        style={{
          display: 'inline-block',
          width: 15,
          height: 15,
          marginLeft: 5,
        }}
      />
      <Handle
        type='target'
        position={Position.Top}
        isConnectable={isConnectable}
        className='w-4 h-2 rounded-none border-0'
      />
      <div className='text-center'>
        <p className='my-2 text-[10px]'>{type}</p>
        <p>{data.node.code}</p>
        <p>
          Id: <strong>{id}</strong>
        </p>
        <div className='flex flex-col my-5'>
          <button
            onClick={() => data.showModal(ModalType.SHOW_FULL_QUESTION)}
            className='flex justify-center shadow border border-blue-300 rounded-full pl-3 pr-3'
          >
            Show Full Question
          </button>
          <button
            onClick={() => data.showModal(ModalType.UPDATE_QUESTION)}
            className='w-auto border-b border-blue-300 m-2 text-blue-300'
          >
            Update Question
          </button>
        </div>
        <p className='mt-4 font-bold'>{data.node.question}</p>
        <p className='mt-2'>Answers:</p>
        <div className={'mt-2'}>
          {answers.map((item) => (
            <div key={item.id} className='flex justify-between bg-gray-100 mb-1'>
              <p key={item.id}>{item.text}</p>
              {data.node?.correctAnswersIds?.includes(item.id) && <p>correct</p>}
            </div>
          ))}
        </div>
        <button
          onClick={() => data.showModal(ModalType.UPDATE_ANSWERS)}
          className='w-auto border-b border-blue-300 m-2 text-blue-300'
        >
          Update Answers
        </button>
      </div>
      {!data.node.nextNode && (
        <button
          onClick={() => data.showModal(ModalType.ADD_NODE_AFTER)}
          className='w-auto border-b border-blue-300 text-blue-300 self-center'
        >
          Add node after this node
        </button>
      )}
      <Handle type='source' position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

export default QuizCustomNode;
