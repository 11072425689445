// *******************************************************
// NodeForm
// -------------------------------------------------------
// This is a NodeForm
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { ReactSelectOptions } from '../../types/types';
import { TrackType, trackTypeFromString } from '../../modeltypes/audioNode';
import { useFormik } from 'formik';
import ReactAudioPlayer from 'react-audio-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyToCliboardIcon } from '../../assets/copyToClipboard.svg';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import { arrayUnion } from '../../models/dalaccess';
import { AnswerType, NodeType, NodeTypeNew } from '../../modeltypes/node';
import { addNewNode, updateNode } from '../../collections/nodes';
import { updateChallenge } from '../../collections/challenges';
import * as yup from 'yup';
import { uploadFileNoThrow } from '../../firebase/storage';
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import { uiFormatTimestamp } from '../../collections/shared';
import { ChallengeTextBlockType } from 'src/modeltypes/challenges';

// *******************************************
// Component Imports
// -------------------------------------------

import { SketchPicker } from 'react-color';
// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// interface NodeFormProps {
//
// };

const trackTypeOptions: ReactSelectOptions[] = [
  {
    value: TrackType.STORY,
    label: 'Content Node',
  },
  {
    value: TrackType.DECISION,
    label: 'Decision Prompt',
  },
  {
    value: TrackType.QUIZ,
    label: 'Quiz question',
  },
  {
    value: TrackType.STRESS_METER,
    label: 'Stress Meter',
  },
  {
    value: TrackType.MENTOR,
    label: 'Mentor Node',
  },
];

type NodeFormType = {
  texts?: ChallengeTextBlockType[] | null;
  code: string;
  nodeType?: ReactSelectOptions;
  nextNode?: string | null;
  audioUrl?: string | null;
  audioUrlFile?: File | null;
  imageUrl?: string | null;
  imageUrlFile?: File | null;
  question?: string | null;
  answers?: AnswerType[];
  correctAnswersIds?: string[];
  popupMessage?: string | null;
  isBeforePractice?: boolean;
  buttonText?: string | null;
  hint?: string | null;
};

type NodeFormProps = {
  afterSubmitFunction: (nodeId: NodeType) => void;
  node: NodeType | null;
  challengeId: string;
  onCloseFunction: () => void;
};

const NodeForm = ({ afterSubmitFunction, node, challengeId, onCloseFunction }: NodeFormProps) => {
  const [editUrlOn, setEditUrlOn] = useState(false);
  const [audioDeleted, setAudioDeleted] = useState(false);
  const [imageDeleted, setImageDeleted] = useState(false);

  const [isBeforePracticeToggle, setIsBeforePracticeToggle] = useState<boolean>(false);
  const [displayColorPicker, setDisplayColorPicker] = useState<number | null>(null);

  const uploadFile = async ({
    code,
    newNodeId,
    file,
    fileName,
  }: {
    code: string;
    newNodeId: string;
    file?: File | null;
    fileName?: string;
  }) => {
    const storageFileName = fileName || `${newNodeId}-${code}-nodeAudio.m4a`;

    const uploadResult = await uploadFileNoThrow(dieIfNullOrUndef(file), storageFileName, ['assets', newNodeId]);
    if (uploadResult.error) {
      throw uploadResult.error;
    }
    return uploadResult.downloadUrl;
  };

  const { values, setValues, setFieldValue, handleChange, isSubmitting, handleSubmit, errors } =
    useFormik<NodeFormType>({
      validateOnChange: false,
      initialValues: {
        code: '',
        texts: [],
      },
      validationSchema: yup.object().shape({
        code: yup.string().required('Code is required'),
        nodeType: yup.object().required('Select type'),
      }),
      validate: (values) => {
        const errors: any = {};
        if (values.nodeType && values.nodeType.value === TrackType.STORY) {
          if (!(values?.texts?.length || values.audioUrl || values.imageUrl)) {
            errors.text = 'At least one content item is required';
          }
        } else if (
          values.nodeType &&
          (values.nodeType.value === TrackType.DECISION || values.nodeType.value === TrackType.MENTOR)
        ) {
          if (!values.question) {
            errors.question = 'Question is required';
          }
          if (!values.answers) {
            errors.answers = 'Answers are required';
          } else {
            for (const answer of values.answers) {
              if (!answer.text) {
                errors.answers = "Answers can't be empty!";
              }
            }
          }
        } else if (values.nodeType && values.nodeType.value === TrackType.QUIZ) {
          if (!values.correctAnswersIds) {
            errors.correctAnswersIds = 'Correct answers are required';
          } else if (values.correctAnswersIds.length < 1) {
            errors.correctAnswersIds = 'Need at least 1 correct answer';
          }
          if (!values.answers || values.answers.length < 2) {
            errors.answers = 'At least 2 answers are required';
          } else {
            for (const answer of values.answers) {
              if (!answer.text) {
                errors.answers = "Answers can't be empty!";
              }
            }
          }
        }
        return errors;
      },
      onSubmit: (nodeValues) => {
        const newNodeValues: NodeTypeNew = {
          code: nodeValues.code,
          description: null,
          texts: nodeValues.texts,
          title: null,
          question: nodeValues.question,
          answers: nodeValues.answers,
          nodeType: trackTypeFromString(nodeValues.nodeType?.value),
          audioUrl: nodeValues.audioUrl,
          correctAnswersIds: nodeValues.correctAnswersIds,
          popupMessage: nodeValues.popupMessage,
          isBeforePractice: nodeValues.isBeforePractice,
          buttonText: nodeValues.buttonText || null,
          hint: nodeValues.hint || null,
        };
        if (node) {
          toast.promise(
            async () => {
              if (
                node &&
                node?.audioUrl !== nodeValues.audioUrl &&
                nodeValues.audioUrl !== '' &&
                nodeValues.audioUrlFile &&
                node.id
              ) {
                const audioUrl = await uploadFile({
                  code: newNodeValues.code,
                  newNodeId: node.id,
                  file: values.audioUrlFile,
                });
                newNodeValues.audioUrl = dieIfNullOrUndef(audioUrl);
              }

              if (
                node &&
                node?.imageUrl !== nodeValues.imageUrl &&
                nodeValues.imageUrl !== '' &&
                nodeValues.imageUrlFile &&
                node.id
              ) {
                const imageUrl = await uploadFile({
                  code: newNodeValues.code,
                  newNodeId: node.id,
                  file: values.imageUrlFile,
                  fileName: values.imageUrlFile?.name.replace(' ', '_'),
                });

                newNodeValues.imageUrl = dieIfNullOrUndef(imageUrl);
              }

              await updateNode(node.id, {
                ...newNodeValues,
              });
              afterSubmitFunction({ id: node.id, ...newNodeValues });
            },
            {
              pending: `Updating ${nodeValues.code} Node...`,
              error: "Can't do it now, try again.",
              success: `Updated ${nodeValues.code} Node!`,
            },
          );
        } else {
          toast.promise(
            async () => {
              const newNodeId = await addNewNode({
                ...newNodeValues,
              });

              if (nodeValues.audioUrl !== '' && nodeValues.audioUrlFile && newNodeId) {
                const audioUrl = dieIfNullOrUndef(
                  await uploadFile({
                    code: newNodeValues.code,
                    newNodeId: newNodeId,
                    file: nodeValues.audioUrlFile,
                  }),
                );
                await updateNode(newNodeId, { audioUrl });
              }
              if (nodeValues.imageUrl !== '' && nodeValues.imageUrlFile && newNodeId) {
                const imageUrl = dieIfNullOrUndef(
                  await uploadFile({
                    code: newNodeValues.code,
                    newNodeId: newNodeId,
                    file: nodeValues.imageUrlFile,
                    fileName: values.imageUrlFile?.name.replace(' ', '_'),
                  }),
                );
                await updateNode(newNodeId, { imageUrl });
              }
              await updateChallenge(challengeId, { flow: arrayUnion(newNodeId) });
              afterSubmitFunction({ id: newNodeId, ...newNodeValues });
            },
            {
              pending: `Adding ${nodeValues.code} Node...`,
              error: "Can't do it now, try again.",
              success: `Added ${nodeValues.code} Node!`,
            },
          );
        }
      },
    });

  useEffect(() => {
    if (node) {
      setValues({
        ...node,
        code: node?.code || '',
        nodeType: trackTypeOptions.find((e) => e.value === node?.nodeType),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  const handleUploadDrop = useCallback(
    (type: 'image' | 'audio') =>
      (file: File): void => {
        const url = URL.createObjectURL(file);
        const data =
          type === 'image'
            ? {
                imageUrl: url,
                imageUrlFile: file,
              }
            : {
                audioUrl: url,
                audioUrlFile: file,
              };
        setValues({
          ...values,
          ...data,
        });
      },
    [values, setValues],
  );

  const deleteCorrectAnswer = (answerId: string) => {
    if (values.correctAnswersIds) {
      const temp = [...values.correctAnswersIds];
      const index = temp.findIndex((aId) => aId === answerId);
      temp.splice(index, 1);
      setFieldValue('correctAnswersIds', temp);
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      isBeforePractice: isBeforePracticeToggle,
    });
  }, [isBeforePracticeToggle]);

  return (
    <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
      <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
        <div>
          <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
            Node Details <span style={{ fontSize: '80%' }}>(ID: {node?.id || 'not yet'})</span>
          </label>
          <p>
            <strong>Created:</strong>&nbsp;{uiFormatTimestamp(node?.createdAt)}
            &nbsp;&nbsp;
            <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(node?.updatedAt)}
          </p>
          <div className='mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
            <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Node Type
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <Select
                className={`max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                  errors.nodeType ? 'border-red-300' : 'border-gray-300'
                } rounded-md`}
                options={trackTypeOptions}
                value={values.nodeType}
                onChange={(selectedTrackType) => {
                  setValues({
                    ...values,
                    nodeType: selectedTrackType as ReactSelectOptions,
                  });
                }}
              />
            </div>
          </div>
          {/*<p>*/}
          {/*  <strong>Created:</strong>&nbsp;{uiFormatTimestamp(audioNode?.createdAt)}*/}
          {/*  &nbsp;&nbsp;*/}
          {/*  <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(audioNode?.updatedAt)}*/}
          {/*</p>*/}
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
            <label htmlFor='code' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Code
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='code'
                  id='code'
                  autoComplete='code'
                  className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                    errors.code ? 'border-red-300' : 'border-gray-300'
                  }`}
                  value={values.code || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={`flex flex-col ${errors.texts ? 'bg-red-50' : ''}`}>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
              <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                Text Blocks
              </label>
            </div>
            <div>
              {values.texts?.map((text, index) => (
                <div
                  key={text.id}
                  className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'
                >
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Text Block {index + 1}
                  </label>
                  <div className='max-w-lg flex rounded-md shadow-sm flex flex-col gap-y-1'>
                    <textarea
                      // disabled={disabledForm}
                      // type='text'
                      name={`texts[${index}].text`}
                      id={`texts[${index}].text`}
                      autoComplete={`texts`}
                      className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                      value={values?.texts?.[index].text || ''}
                      onChange={handleChange}
                      placeholder={'Type text to show to user'}
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      const temp = values.texts ? [...values.texts] : [];
                      const index = temp.findIndex((i) => i.id === text.id);
                      if (index > -1) {
                        temp.splice(index, 1);
                        setValues({
                          ...values,
                          texts: temp,
                        });
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
              ))}
              <button
                className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={(e) => {
                  e.preventDefault();
                  const temp = values.texts ? [...values.texts] : [];
                  temp.push({
                    id: new Date().getTime().toString(),
                    text: '',
                  });
                  setValues({
                    ...values,
                    texts: temp,
                  });
                }}
              >
                + Add Text Block
              </button>
            </div>
          </div>

          {values.nodeType?.value === 'STORY' && (
            <>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                <label htmlFor='cover-photo' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Existing Audio
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2' style={{ display: 'inline-block' }}>
                  <div className='space-y-1 text-left' style={{ display: 'inline-block' }}>
                    {!node?.audioUrl || audioDeleted ? (
                      <span className='text-sm'>None</span>
                    ) : (
                      <>
                        <ReactAudioPlayer
                          src={node.audioUrl}
                          autoPlay={false}
                          controls={true}
                          style={{ display: 'inline-block' }}
                          controlsList='play volume'
                        />
                        <a
                          target='_blank'
                          rel='noreferrer noopener'
                          href={node.audioUrl}
                          className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                          onClick={() => false}
                          style={{ display: 'inline-block' }}
                          title={'Link to audio file.'}
                        >
                          Audio File
                        </a>
                        <CopyToClipboard text={node.audioUrl}>
                          <button type={'button'}>
                            <CopyToCliboardIcon />
                          </button>
                        </CopyToClipboard>
                        <a
                          href={void 0}
                          className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                          style={{ display: 'inline-block' }}
                          title={'Toggle editing URL directly.'}
                          onClick={() => {
                            setEditUrlOn(!editUrlOn);
                          }}
                        >
                          Upload New
                        </a>
                        <button
                          type={'button'}
                          className='sm:text-sm underline text-red-400 pl-5 pr-5'
                          style={{ display: 'inline-block' }}
                          onClick={() => {
                            setFieldValue('audioUrl', null);
                            setAudioDeleted(true);
                          }}
                        >
                          Delete Audio
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!editUrlOn || audioDeleted ? null : (
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                  <label htmlFor='audioUrl' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Audio Asset URL
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='audioUrl'
                        id='audioUrl'
                        autoComplete='audioUrl'
                        className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                        value={values.audioUrl || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {editUrlOn || audioDeleted ? null : (
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='cover-photo' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Upload Audio
                  </label>
                  <div className={`sm:mt-0 sm:col-span-2`}>
                    <div
                      className={`max-w-lg flex justify-center px-6 pb-6  ${
                        errors.audioUrl ? 'border-red-300 border' : ''
                      }`}
                    >
                      <div className={`space-y-1 text-center `}>
                        <FileUploader
                          handleChange={handleUploadDrop('audio')}
                          name='file'
                          types={['m4a', 'M4A']}
                          multiple={false}
                          hoverTitle={''}
                          maxSize={10}
                          minSize={0}
                          label={'Select or drag an audio file here.'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                <label htmlFor='cover-photo' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Existing Image
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2' style={{ display: 'inline-block' }}>
                  <div className='space-y-1 text-left' style={{ display: 'inline-block' }}>
                    {!node?.imageUrl || imageDeleted ? (
                      <span className='text-sm'>None</span>
                    ) : (
                      <>
                        <a
                          target='_blank'
                          rel='noreferrer noopener'
                          href={node.imageUrl}
                          className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                          onClick={() => false}
                          style={{ display: 'inline-block' }}
                          title={'Link to audio file.'}
                        >
                          Image File
                        </a>
                        <CopyToClipboard text={node.imageUrl}>
                          <button type={'button'}>
                            <CopyToCliboardIcon />
                          </button>
                        </CopyToClipboard>
                        <button
                          type={'button'}
                          className='sm:text-sm underline text-red-400 pl-5 pr-5'
                          style={{ display: 'inline-block' }}
                          onClick={() => {
                            setFieldValue('imageUrl', null);
                            setImageDeleted(true);
                          }}
                        >
                          Delete Audio
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
                <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                  Upload image
                </label>
                <div className={`mt-1 sm:mt-0 sm:col-span-2`}>
                  <div className={`max-w-lg flex justify-center px-6 pt-5 pb-6`}>
                    <div className={`space-y-1 text-center `}>
                      <FileUploader
                        handleChange={handleUploadDrop('image')}
                        name='image'
                        types={['png']}
                        multiple={false}
                        hoverTitle={''}
                        maxSize={10}
                        minSize={0}
                        label={'Select or drag an image here.'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {values.nodeType?.value === TrackType.STRESS_METER && (
            <div className='flex items-center sm:mt-px sm:pt-2'>
              <label htmlFor='name' className='block text-sm font-medium text-gray-700 mr-5 '>
                Is Before Practice (i.e. stress metering before verses after....)
              </label>

              <input
                name='isBeforePractice'
                id='isBeforePractice'
                type={'checkbox'}
                onChange={(v) => setIsBeforePracticeToggle(v?.target.checked)}
                checked={values.isBeforePractice}
              />
            </div>
          )}

          {['DECISION', 'QUIZ', 'MENTOR'].includes(values.nodeType?.value || '') && (
            <div className='mt-2 flex flex-col'>
              <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                      <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:px-2'>
                        Question
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <div className='max-w-lg flex rounded-md shadow-sm'>
                          <input
                            // disabled={disabledForm}
                            type='text'
                            name='question'
                            id='question'
                            autoComplete='question'
                            className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                            value={values?.question || ''}
                            onChange={handleChange}
                            placeholder={'Enter Question'}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        Hint
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <div className='max-w-lg flex rounded-md shadow-sm'>
                          <input
                            type='text'
                            name='hint'
                            id='hint'
                            autoComplete='title'
                            className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                              errors.hint ? 'border-red-300' : 'border-gray-300'
                            }`}
                            defaultValue={values.hint || ''}
                            onChange={handleChange}
                            placeholder={'Give them a hint to make the feel better'}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={`flex flex-col ${errors.correctAnswersIds || errors.answers ? 'bg-red-50' : ''}`}>
                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                        <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                          {node?.nodeType === 'DECISION' ? 'Options' : 'Answers'}
                        </label>
                      </div>
                      <div>
                        {values.answers?.map((answer, index) => (
                          <div key={answer.id}>
                            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                              <label
                                htmlFor='name'
                                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                              >
                                {node?.nodeType === 'DECISION' ? 'Option' : 'Answer'} {index + 1}
                              </label>
                              <div className='max-w-lg flex rounded-md flex flex-col gap-y-1'>
                                <input
                                  // disabled={disabledForm}
                                  type='text'
                                  name={`answers[${index}].text`}
                                  id={`answers[${index}].text`}
                                  autoComplete={`answers`}
                                  className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                                  value={values?.answers?.[index].text || ''}
                                  onChange={handleChange}
                                  placeholder={'Enter Answer'}
                                />
                                {values.nodeType?.value === 'QUIZ' && (
                                  <div className='flex items-center sm:mt-px sm:pt-2'>
                                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 mr-5 '>
                                      Correct answer
                                    </label>
                                    <input
                                      name='correctAnswerId'
                                      id='correctAnswerId'
                                      type={'checkbox'}
                                      onChange={() => {
                                        if (values.correctAnswersIds?.includes(answer.id)) {
                                          deleteCorrectAnswer(answer.id);
                                        } else {
                                          const temp = [...(values.correctAnswersIds || [])];
                                          temp.push(answer.id);
                                          setFieldValue('correctAnswersIds', temp);
                                        }
                                      }}
                                      checked={values.correctAnswersIds?.includes(answer.id)}
                                    />
                                  </div>
                                )}
                              </div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  const temp = values.answers ? [...values.answers] : [];
                                  const index = temp.findIndex((i) => i.id === answer.id);
                                  if (index > -1) {
                                    temp.splice(index, 1);
                                    setValues({
                                      ...values,
                                      answers: temp,
                                    });
                                  }
                                  if (values.correctAnswersIds?.includes(answer.id)) {
                                    deleteCorrectAnswer(answer.id);
                                  }
                                }}
                              >
                                Delete
                              </button>
                            </div>
                            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-5'>
                              <label
                                htmlFor='code'
                                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                              >
                                Expanded text
                              </label>
                              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                                <div className='max-w-lg flex rounded-md shadow-sm'>
                                  <textarea
                                    name={`answers[${index}].expandedText`}
                                    id={`answers[${index}].expandedText`}
                                    className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300`}
                                    value={values?.answers?.[index]?.expandedText || ''}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-5'>
                              <label
                                htmlFor='code'
                                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                              >
                                Is positive?
                                <p className={'text-[12px] text-gray-400'}>
                                  (positive - green, negative - red background)
                                </p>
                              </label>
                              <div className='mt-1 sm:mt-0'>
                                <input
                                  type={'checkbox'}
                                  name={`answers[${index}].isPositive`}
                                  id={`answers[${index}].isPositive`}
                                  className={`block focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none sm:text-sm border-gray-300`}
                                  checked={values?.answers?.[index]?.isPositive || false}
                                  onChange={(event) =>
                                    setFieldValue(`answers[${index}].isPositive`, event.target.checked)
                                  }
                                />
                              </div>
                            </div>
                            {values.nodeType?.value === TrackType.MENTOR && (
                              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-5'>
                                <label
                                  htmlFor='code'
                                  className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                                >
                                  Background color
                                  <p className={'text-[12px] text-gray-400'}>
                                    (optional, overwrites colors from isPositive)
                                  </p>
                                </label>
                                <div className='mt-1 sm:mt-0'>
                                  <div className={'flex flex-row items-center'}>
                                    <div
                                      style={{
                                        padding: '5px',
                                        background: '#fff',
                                        borderRadius: '1px',
                                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => setDisplayColorPicker((prev) => (prev === index ? null : index))}
                                    >
                                      <div
                                        className={`w-[40px] h-[15px]`}
                                        style={{ backgroundColor: answer?.backgroundColor || '#FFF' }}
                                      />
                                    </div>
                                    <button
                                      className={'ml-4'}
                                      type={'button'}
                                      onClick={() => setFieldValue(`answers[${index}].backgroundColor`, null)}
                                    >
                                      Clear
                                    </button>

                                    {displayColorPicker === index ? (
                                      <div
                                        style={{
                                          position: 'absolute',
                                          zIndex: '2',
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px',
                                          }}
                                          onClick={() => setDisplayColorPicker(null)}
                                        />
                                        <SketchPicker
                                          disableAlpha
                                          color={answer?.backgroundColor || '#FFF'}
                                          onChange={(color) =>
                                            handleChange(`answers[${index}].backgroundColor`)(color.hex)
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                        <button
                          className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          onClick={(e) => {
                            e.preventDefault();
                            const temp = values.answers ? [...values.answers] : [];
                            temp.push({
                              id: new Date().getTime().toString(),
                              text: '',
                            });
                            setValues({
                              ...values,
                              answers: temp,
                            });
                          }}
                        >
                          + Add Option
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {values.nodeType?.value === 'QUIZ' && (
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <label htmlFor='summaryText' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Popup message
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <textarea
                  id='popupMessage'
                  name='popupMessage'
                  rows={2}
                  className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  value={values.popupMessage || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
            <label htmlFor='code' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Continue button text
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='buttonText'
                  id='buttonText'
                  autoComplete='buttonText'
                  className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                    errors.buttonText ? 'border-red-300' : 'border-gray-300'
                  }`}
                  value={values.buttonText || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.values(errors).length ? (
        <p className={'text-red-400 text-center pt-5 font-bold '}>
          {Object.values(errors)
            .map((x) => x)
            .join(', ')}
        </p>
      ) : null}
      <div className='pt-5'>
        <div className='flex justify-end'>
          <button
            disabled={isSubmitting}
            type='reset'
            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => onCloseFunction()}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            type='submit'
            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            {node ? (isSubmitting ? 'Updating ' : 'Update') : 'Create'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default NodeForm;
