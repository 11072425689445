import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewLearningGame, getLearningGameById, updateLearningGame } from '../../collections/learningGames';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { LearningGameType } from '../../modeltypes/learningGames';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getAllTags } from 'src/collections/tags';
import { ReactSelectOptions } from 'src/types/types';
import { TagOptions, TagType } from 'src/modeltypes/tags';
import { QuizId, TagId } from 'src/modeltypes/id';
import { getAllQuizItems } from 'src/collections/quizItems';
import { QuizType } from 'src/modeltypes/quizItems';
import { uiFormatTimestamp } from '../../collections/shared';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

// Constants
export type LearningGameAnswerType = {
  id: string;
  text?: string;
};

export enum LearningGameTypeOptionsTs {
  DRAG = 'DRAG',
  CLICK = 'CLICK',
  MIX = 'MIX',
}

const quizTypeOptions: ReactSelectOptions[] = [
  {
    value: LearningGameTypeOptionsTs.DRAG,
    label: 'DRAG',
  },
  {
    value: LearningGameTypeOptionsTs.CLICK,
    label: 'CLICK',
  },
  {
    value: LearningGameTypeOptionsTs.MIX,
    label: 'MIX of Quiz Types',
  },
];

const LearningGameForm = () => {
  const [learningGame, setThisLearningGame] = useState<LearningGameType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<ReactSelectOptions[]>([]);
  const [quizItems, setQuizItems] = useState<ReactSelectOptions[]>([]);
  const [allQuizItems, setAllQuizItems] = useState<QuizType[]>([]);
  const [selectedTags, setSelectedTags] = useState<ReactSelectOptions[]>([]);

  const getTags = async () => {
    const dbTags: TagType[] = await getAllTags();
    const selectorT: TagOptions[] = dbTags.map((dbTag: TagType) => {
      return {
        label: `${dbTag.name}`,
        value: dbTag?.id,
      };
    });
    setTags(selectorT);
  };

  const getQuizItems = async () => {
    const quizItems: QuizType[] = await getAllQuizItems();
    const selectorT: ReactSelectOptions[] = quizItems.map((quizItem: QuizType) => {
      return {
        label: `${quizItem.question} (${quizItem.quizType})`,
        value: quizItem?.id,
      };
    });
    setQuizItems(selectorT);
    setAllQuizItems(quizItems);
  };

  useEffect(() => {
    getTags();
    getQuizItems();
  }, []);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths, setValidateElement } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Learning Games',
        path: '/learningGames',
      },
      {
        label: id === 'new' ? 'New Learning Game' : 'Edit Learning Game',
        path: `/learningGames/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    //resetForm
  } = useFormik<{
    title: string;
    pathType: string;
    description: string;
    locked: boolean;
    subTitle: string;
    quizItems: QuizId[];
    correctAnswersIds: string[];
    popupMessage?: string | null;
    tags?: TagId[];
    mlAttribute?: TagId;
    quizType: LearningGameTypeOptionsTs | null;
    duration: number;
    closingRemark: string;
    countDownDescription: string;
    // id?: string;
  }>({
    initialValues: {
      title: '',
      pathType: 'learningGame',
      description: '',
      locked: true,
      subTitle: '',
      quizItems: [],
      correctAnswersIds: [],
      popupMessage: null,
      quizType: null,
      duration: 2,
      closingRemark: '',
      countDownDescription: '',
      // id: '',
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required'),
      description: yup.string(),
      closingRemark: yup.string(),
      pathType: yup.string().required('Path Type is required'),
      duration: yup.number().required('Duration must be a number'),
    }),
    validate: (values) => {
      const errors: any = {};
      console.log('inside validate: ', values);
      const selectedQuizItems: QuizType[] = allQuizItems.filter((q: QuizType) => values?.quizItems?.includes(q.id));

      if (!values.quizItems.length) {
        errors.quizItems = 'Dude.... you tots need at least one question';
      }
      if (!values.quizItems.length) {
        errors.quizType = 'Dude.... you tots need a  quiz type';
      }

      // Ensure that if the learning game is DRAG that all Questions are DRAG
      if (values.quizType === LearningGameTypeOptionsTs.DRAG) {
        const notHaveDRAG = selectedQuizItems.filter((x: QuizType) => x?.quizType && x.quizType !== 'DRAG');
        const thisLength = notHaveDRAG?.length;
        if (thisLength) {
          errors.quizItems = `Dude... ALL Quiz Items must be Drag!  The following question is not: ${selectedQuizItems?.[0]?.question}`;
        }
      }
      // Ensure that if the learning game is DRAG that all Questions are CLICK

      if (values.quizType === LearningGameTypeOptionsTs.CLICK) {
        const notHaveCLICK = selectedQuizItems.filter((x: QuizType) => x?.quizType && x.quizType !== 'CLICK');
        const thisLength = notHaveCLICK?.length;
        if (thisLength) {
          errors.quizItems = `Dude... ALL Quiz Items must be CLICK!  The following question is not: ${selectedQuizItems?.[0]?.question}`;
        }
      }
      return errors;
    },
    onSubmit: async (newLearningGame) => {
      console.log('newLearningGame: ', newLearningGame);

      if (id !== undefined && id !== 'new') {
        toast
          .promise(updateLearningGame(id, newLearningGame), {
            pending: `Updating ${newLearningGame.title} LearningGame...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newLearningGame.title} LearningGame!`,
          })
          .then(async () => {
            navigate('/learningGames');
          });
      } else if (id === 'new') {
        toast
          .promise(addNewLearningGame(newLearningGame), {
            pending: `Adding ${newLearningGame.title} LearningGame...`,
            error: "Can't do it now, try again.",
            success: `Added ${newLearningGame.title} LearningGame!`,
          })
          .then(async () => {
            navigate('/learningGames');
          });
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currLearningGame = await getLearningGameById(id || '');
        if (currLearningGame === null) {
          navigate('/learningGames');
          return;
        }

        await setValues({
          title: currLearningGame.title || '',
          description: currLearningGame.description || '',
          closingRemark: currLearningGame.closingRemark || '',
          subTitle: currLearningGame.subTitle || '',
          pathType: 'learningGame',
          locked: currLearningGame.locked || false,
          correctAnswersIds: currLearningGame.correctAnswersIds || [],
          quizItems: currLearningGame.quizItems || [],
          tags: currLearningGame.tags || [],
          quizType: currLearningGame.quizType || null,
          duration: currLearningGame.duration || 2,
          countDownDescription: currLearningGame.countDownDescription || '',
        });

        const dbTags = await getAllTags();
        const selectorT = dbTags.map((dbTag: TagType) => ({
          label: `${dbTag.name}`,
          value: dbTag?.id,
        }));
        setTags(selectorT);

        if (currLearningGame?.tags?.length) {
          setSelectedTags(selectorT.filter((t) => currLearningGame?.tags?.includes(t?.value)));
        }
        setThisLearningGame(currLearningGame);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const disabledForm = false;

  const deleteCorrectAnswer = (answerId: string) => {
    if (values.correctAnswersIds) {
      const temp = [...values.correctAnswersIds];
      const index = temp.findIndex((aId) => aId === answerId);
      temp.splice(index, 1);
      setFieldValue('correctAnswersIds', temp);
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
  }, [selectedTags]);

  const changeTo = (id: string, to: 'DEV' | 'PROD') => {
    setValidateElement({
      id,
      type: 'learningGame',
      to,
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                  Learning Game Details <span style={{ fontSize: '80%' }}>(ID: {learningGame?.id || 'not yet'})</span>
                </label>
                <p>
                  <strong>Created:</strong>&nbsp;{uiFormatTimestamp(learningGame?.createdAt)}
                  &nbsp;&nbsp;
                  <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(learningGame?.updatedAt)}
                  {learningGame ? (
                    <>
                      <div className={'flex flex-row items-center mt-3'}>
                        <p className='font-bold mr-3'>Status:</p>
                        {learningGame?.productionStatus || 'WIP'}
                        {learningGame?.productionStatus === 'DEV' ? (
                          <button
                            type={'button'}
                            onClick={() => changeTo(learningGame?.id, 'PROD')}
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          >
                            Change to Prod
                          </button>
                        ) : null}
                        {!learningGame?.productionStatus ? (
                          <button
                            type={'button'}
                            onClick={() => changeTo(learningGame?.id, 'DEV')}
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          >
                            Change to Dev
                          </button>
                        ) : null}
                      </div>
                      <div className={'flex flex-row items-center mt-3'}>
                        <p className='font-bold mr-3'>Locked:</p>
                        {learningGame?.locked ? (
                          <CheckIcon className='h-5 w-5 text-green-500' />
                        ) : (
                          <XIcon className='h-5 w-5 text-red-400' />
                        )}
                      </div>
                    </>
                  ) : null}
                </p>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='title'
                        id='title'
                        autoComplete='title'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.title ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.title}
                        onChange={handleChange}
                        placeholder={'Learning Game title'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Path Type: {values.pathType}
                  </label>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'Learning Game Description.  The user will see this'}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Countdown Screen Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='countDownDescription'
                      name='countDownDescription'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.countDownDescription}
                      onChange={handleChange}
                      placeholder={'Learning Game Description for the countdown screen.  The user will see this'}
                    />
                  </div>
                </div>

                <div className='mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                  <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    LearningGame Type
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      className={`max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                        errors.quizType ? 'border-red-300' : 'border-gray-300'
                      } rounded-md`}
                      options={quizTypeOptions}
                      value={quizTypeOptions.find(({ value }) => value === values.quizType)}
                      onChange={(selectedLearningGameType) => {
                        setValues({
                          ...values,
                          quizType: selectedLearningGameType?.value as LearningGameTypeOptionsTs,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='duration' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Duration of Learning Game(minutes)
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className={`${errors.duration ? 'bg-red-50' : ''} max-w-lg flex rounded-md shadow-sm`}>
                      <input
                        type='number'
                        name='duration'
                        id='duration'
                        autoComplete='duration'
                        className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                        value={values.duration}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Tags:
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={tags || []}
                      isMulti={true}
                      value={selectedTags}
                      onChange={(vals) => {
                        setSelectedTags(vals as ReactSelectOptions[]);
                      }}
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                    />
                  </div>
                </div>

                <div className='mt-2 flex flex-col'>
                  <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                      <div>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                          <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:px-2'>
                            SubTitle
                          </label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            <div className='max-w-lg flex rounded-md shadow-sm'>
                              <input
                                // disabled={disabledForm}
                                type='text'
                                name='subTitle'
                                id='subTitle'
                                autoComplete='none'
                                className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                                value={values?.subTitle || ''}
                                onChange={handleChange}
                                placeholder={'Enter a SubTitle'}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
                          <label htmlFor='singleTapTag' className='text-sm font-medium text-gray-700'>
                            Machine Learning Attribute
                          </label>
                          <div className='flex mt-1 sm:mt-0'>
                            <Select
                              options={tags}
                              value={tags.find((t) => t.value === values.mlAttribute)}
                              onChange={(vals) => {
                                console.log('mlAttribute vals: ', vals);
                                setValues({
                                  ...values,
                                  mlAttribute: vals?.value,
                                });

                                // setSelectedTags(vals as ReactSelectOptions[]);
                              }}
                              className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                            />
                          </div>
                        </div>

                        <div
                          className={`flex flex-col ${errors.correctAnswersIds || errors.quizItems ? 'bg-red-50' : ''}`}
                        >
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                            <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                              Questions
                            </label>
                          </div>
                          <div>
                            {values.quizItems?.map((quizItem, index) => (
                              <div
                                key={quizItem}
                                className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'
                              >
                                <label
                                  htmlFor='name'
                                  className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                                >
                                  Quiz Item {index + 1}
                                </label>
                                <div className='flex-row space-between'>
                                  <div className=' mt-1 sm:mt-0 sm:col-span-2 absolute'>
                                    <Select
                                      isClearable
                                      options={quizItems}
                                      value={quizItems.find((c) => c.value === values.quizItems[index])}
                                      onChange={(val) => {
                                        const temp = values.quizItems ? [...values.quizItems] : [];
                                        temp[index] = val?.value as QuizId;
                                        setValues({
                                          ...values,
                                          quizItems: temp,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const temp = values.quizItems ? [...values.quizItems] : [];
                                    const index = temp.findIndex((i) => i === quizItem);
                                    if (index > -1) {
                                      temp.splice(index, 1);
                                      setValues({
                                        ...values,
                                        quizItems: temp,
                                      });
                                    }
                                    if (values.correctAnswersIds?.includes(quizItem)) {
                                      deleteCorrectAnswer(quizItem);
                                    }
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            ))}
                            <button
                              className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              onClick={(e) => {
                                e.preventDefault();
                                const temp = values.quizItems ? [...values.quizItems] : [];
                                temp.push('');
                                setValues({
                                  ...values,
                                  quizItems: temp,
                                });
                              }}
                            >
                              + Add Option
                            </button>
                          </div>
                        </div>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                          <label
                            htmlFor='closingRemark'
                            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                          >
                            Closing Remark (The final screen of the Learning Game)
                          </label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            <textarea
                              disabled={disabledForm}
                              id='closingRemark'
                              name='closingRemark'
                              rows={3}
                              className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                              defaultValue={values.closingRemark}
                              onChange={handleChange}
                              placeholder={'Learning Closing Remark (The quote at the end).  The user will see this'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {errors &&
            Object.values(errors).length &&
            Object.values(errors).map((c, index) => (
              <div key={`${c}-${index}`} className='border-red-300'>
                <h3>{`Issue ${index + 1} - ${c}`}</h3>
              </div>
            ))}

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/pathtypes')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {learningGame ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default LearningGameForm;
