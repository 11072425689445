import { Edge, Node } from 'react-flow-renderer';
import dagre from 'dagre';

export const DEFAULT_NODE_WIDTH = 90;
export const DEFAULT_NODE_HEIGHT = 40;

export const laidOutNodes = (
  nodes: Node[],
  edges: Edge[],
  nodeWidth: number = DEFAULT_NODE_WIDTH,
  nodeHeight: number = DEFAULT_NODE_HEIGHT,
): Node[] => {
  // Layout the graph in some sensible way.
  const g = new dagre.graphlib.Graph().setGraph({
    ranksep: 20,
    nodesep: (nodeWidth * 2) / 3,
    edgesep: 10,
    rankdir: 'TB',
  });
  g.setDefaultEdgeLabel(function () {
    return {};
  });
  nodes.forEach((n) => {
    g.setNode(n.id, { label: n.data.label, width: nodeWidth, height: nodeHeight });
  });
  edges.forEach((e) => {
    g.setEdge(e.source, e.target);
  });

  dagre.layout(g, { nodesep: 200 });

  nodes.forEach((n) => {
    const gn = g.node(n.id);
    n.position.x = gn.x;
    n.position.y = gn.y;
  });

  return nodes;
};
