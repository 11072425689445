import { ScriptLesson, ScriptSection, ScriptSubsection } from './ScriptModel';
import { hmsDurationToSeconds, rstrip, strip } from './GeneralUtilities';

enum ScriptLabels {
  Author = 'AUTHOR:',
  LastUpdate = 'LAST UPDATE:',
  // ProgName = 'PROGRAM NAME/CODE:',
  LessonTitle = 'LESSON TITLE:',
  LessonSubtitle = 'LESSON SUBTITLE:',
  // LessonNumber = 'LESSON NUMBER:',
  // LessonCategory = 'LESSON CATEGORY:',
  LessonDescription = 'LESSON DESCRIPTION:',
  // LessonIntro = 'LESSON INTRO:',
  // LessonFooter = 'LESSON FOOTER:',
  LessonNominalDuration = 'LESSON NOMINAL DURATION:',
  LessonMinimumDuration = 'LESSON MINIMUM DURATION:',
  LessonMaximumDuration = 'LESSON MAXIMUM DURATION:',
  LessonHomework = 'LESSON HOMEWORK:',
  LessonHomeworkDoneQuestion = 'LESSON HOMEWORK DONE QUESTION:',
  LessonHomeworkNotDoneQuestion = 'LESSON HOMEWORK NOT DONE QUESTION:',
  SectionTitle = 'SECTION TITLE:',
  SubsectionInfo = 'SUBSECTION TITLE:',
  SubsectionSummary = 'SUBSECTION SUMMARY:',
  PathNameCode = 'PATH NAME/CODE:',
  SubsectionSilence = 'SUBSECTION SILENCE:',
  SingleTapDest = 'SINGLE TAP DESTINATION CODE:',
  DoubleTapDest = 'DOUBLE TAP DESTINATION CODE:',
  TripleTapDest = 'TRIPLE TAP DESTINATION CODE:',
  BeginIgnore = 'BEGIN IGNORE',
  EndIgnore = 'END IGNORE',
  NextSubsectionCode = 'NEXT SUBSECTION CODE:',
  HomeworkStatus = 'HOMEWORK STATUS:',
  HomeworkAnswer = 'HOMEWORK ANSWER:',
  LessonEnd = 'LESSON END:',
  Type = 'TYPE:',
  SubType = 'SUB_TYPE:',
  Relief = 'RELIEF:',
  Fulfillment = 'FULFILLMENT:',
  SelfDiscovery = 'SELFDISCOVERY:',
  SelfMastery = 'SELFMASTERY:',
  Connection = 'CONNECTION:',
  Practice = 'PRACTICE:',
  Beginner = 'BEGINNER:',
  Some = 'SOME:',
  Advanced = 'ADVANCED:',
}

const ScriptLabelsVals: Array<string> = Object.keys(ScriptLabels).map(
  (x) => (ScriptLabels as unknown as Record<string, string>)[x],
);

enum HeaderLabels {
  Author = ScriptLabels.Author,
  LastUpdate = ScriptLabels.LastUpdate,
  // ProgName = ScriptLabels.ProgName,
  LessonTitle = ScriptLabels.LessonTitle,
  LessonSubtitle = ScriptLabels.LessonSubtitle,
  // LessonNumber = ScriptLabels.LessonNumber,
  // LessonCategory = ScriptLabels.LessonCategory,
  LessonDescription = ScriptLabels.LessonDescription,
  // LessonIntro = ScriptLabels.LessonIntro,
  // LessonFooter = ScriptLabels.LessonFooter,
  LessonNominalDuration = ScriptLabels.LessonNominalDuration,
  LessonMinimumDuration = ScriptLabels.LessonMinimumDuration,
  LessonMaximumDuration = ScriptLabels.LessonMaximumDuration,
  LessonHomework = ScriptLabels.LessonHomework,
  LessonHomeworkDoneQuestion = ScriptLabels.LessonHomeworkDoneQuestion,
  LessonHomeworkNotDoneQuestion = ScriptLabels.LessonHomeworkNotDoneQuestion,
}

const HeaderLabelsVals: Array<string> = Object.keys(HeaderLabels).map(
  (x) => (HeaderLabels as unknown as Record<string, string>)[x],
);

// noinspection JSUnusedGlobalSymbols
enum RequireCode {
  SectionTitle = ScriptLabels.SectionTitle,
  SubsectionInfo = ScriptLabels.SubsectionInfo,
  PathNameCode = ScriptLabels.PathNameCode,
}

const RequireCodeVals: Array<string> = Object.keys(RequireCode).map(
  (x) => (RequireCode as unknown as Record<string, string>)[x],
);

enum SubsectionHeaderLabels {
  SubsectionInfo = ScriptLabels.SubsectionInfo,
  SubsectionSummary = ScriptLabels.SubsectionSummary,
  PathNameCode = ScriptLabels.PathNameCode,
}

const SubsectionHeaderLabelsVals: Array<string> = Object.keys(SubsectionHeaderLabels).map(
  (x) => (SubsectionHeaderLabels as unknown as Record<string, string>)[x],
);

// noinspection JSUnusedGlobalSymbols
enum SubsectionFooterLabels {
  NextSubsectionCode = ScriptLabels.NextSubsectionCode,
  SubsectionSilence = ScriptLabels.SubsectionSilence,
  SingleTapDest = ScriptLabels.SingleTapDest,
  DoubleTapDest = ScriptLabels.DoubleTapDest,
  TripleTapDest = ScriptLabels.TripleTapDest,
  HomeworkStatus = ScriptLabels.HomeworkStatus,
  HomeworkAnswer = ScriptLabels.HomeworkAnswer,

  // Switch fields
  Type = ScriptLabels.Type,
  SubType = ScriptLabels.SubType,

  // Goal switch
  Relief = ScriptLabels.Relief,
  Fulfillment = ScriptLabels.Fulfillment,
  SelfDiscovery = ScriptLabels.SelfDiscovery,
  SelfMastery = ScriptLabels.SelfMastery,
  Connection = ScriptLabels.Connection,
  Practice = ScriptLabels.Practice,

  // Experience Switch
  Beginner = ScriptLabels.Beginner,
  Some = ScriptLabels.Some,
  Advanced = ScriptLabels.Advanced,
}

const SwitchLinkKeys = [
  ScriptLabels.Relief,
  ScriptLabels.Fulfillment,
  ScriptLabels.SelfDiscovery,
  ScriptLabels.SelfMastery,
  ScriptLabels.Connection,
  ScriptLabels.Practice,
  ScriptLabels.Beginner,
  ScriptLabels.Some,
  ScriptLabels.Advanced,
];

const SubsectionFooterLabelsVals: Array<string> = Object.keys(SubsectionFooterLabels).map(
  (x) => (SubsectionFooterLabels as unknown as Record<string, string>)[x],
);

// noinspection JSUnusedGlobalSymbols
enum SubsectionDoneLabels {
  LessonEnd = ScriptLabels.LessonEnd,
  SectionTitle2 = ScriptLabels.SectionTitle,
}

const SubsectionDoneLabelsVals: Array<string> = Object.keys(SubsectionDoneLabels).map(
  (x) => (SubsectionDoneLabels as unknown as Record<string, string>)[x],
);

const ExtractNameCode = new RegExp('^\\s*([^{}]*)\\s*(?:{([^}]+)})?\\s*$');

const extractStringAndCode = (instr: string | null): [string | null, string | null] => {
  if (instr) {
    const match = ExtractNameCode.exec(instr);
    if (match) {
      return [match[1] || null, match[2] || null];
    }
  }
  return [null, null];
};

type ParagraphNodeJSON = Record<string, Record<string, Array<Record<string, Record<string, string>>>>>;

const lineFromParagraphNode = (paragraphNode: ParagraphNodeJSON): string => {
  const para = paragraphNode['paragraph'];
  let lineAccumulator = '';
  for (const element of para['elements'] || []) {
    const textRun = element['textRun'] || { content: '' };
    const contentString = textRun['content'];
    lineAccumulator += contentString;
  }
  return lineAccumulator;
};

type TableNodeJSON = Record<
  string,
  Record<string, Array<Record<string, Array<Record<string, Array<Record<string, unknown>>>>>>>
>;

const linesFromTable = (tableNode: TableNodeJSON): Array<string> => {
  const lines: Array<string> = new Array<string>();
  const table = tableNode['table'];
  for (const row of table['tableRows'] || []) {
    for (const cell of row['tableCells'] || []) {
      for (const node of cell['content'] || []) {
        if (Object.prototype.hasOwnProperty.call(node, 'paragraph')) {
          lines.push(lineFromParagraphNode(node as ParagraphNodeJSON));
        }
      }
    }
  }
  return lines;
};

export const linesFromContent = (content: Array<Record<string, unknown>>): Array<string> => {
  const lines: Array<string> = [];
  for (const node of content) {
    if (Object.prototype.hasOwnProperty.call(node, 'paragraph')) {
      lines.push(lineFromParagraphNode(node as ParagraphNodeJSON));
    } else if (Object.prototype.hasOwnProperty.call(node, 'table')) {
      lines.push(...linesFromTable(node as TableNodeJSON));
    } else if (Object.prototype.hasOwnProperty.call(node, 'sectionBreak')) {
      // continue;
    } else {
      throw new Error('Unknown node type in linesFromContent.');
    }
  }
  return lines;
};

enum ParserStates {
  BEGINNING = 0,
  HEADER = 1,
  SECTION = 2,
  SUBSECTION = 3,
  TAIL = 4,
  DONE = 5,
}

export class ScriptParser {
  lines: Array<string>;
  state: ParserStates = ParserStates.BEGINNING;
  linesToEat: Array<string> = [];
  outDoc: ScriptLesson | null = null;
  currentSection: ScriptSection | null = null;
  currentSubsection: ScriptSubsection | null = null;
  funcMap: Record<string, () => void> = {};

  constructor(scriptLines: readonly string[]) {
    this.lines = [...scriptLines];
    this.funcMap[ParserStates.BEGINNING] = this.beginning.bind(this);
    this.funcMap[ParserStates.HEADER] = this.header.bind(this);
    this.funcMap[ParserStates.SECTION] = this.section.bind(this);
    this.funcMap[ParserStates.SUBSECTION] = this.subsection.bind(this);
    this.funcMap[ParserStates.TAIL] = this.tail.bind(this);
    this.funcMap[ParserStates.DONE] = () => undefined;
  }

  private get outDocOrDie(): ScriptLesson {
    if (!this.outDoc) {
      throw new Error("outDoc was null when it shouldn't be.");
    }
    return this.outDoc;
  }

  private get currentLine(): number {
    return this.lines.length - this.linesToEat.length;
  }

  private error(message: string): void {
    throw new Error(message);
  }

  private labelAndRestFromLine(inLine: string): [string | null, string | null] {
    const inLine2: string = strip(inLine);
    for (const label of Object.values(ScriptLabels)) {
      if (inLine2.startsWith(label)) {
        const outLabel: string = label;
        const outRest: string = strip(inLine2.substring(label.length));
        if (RequireCodeVals.includes(label)) {
          const code = extractStringAndCode(outRest)[1];
          if (code === null || !code.length) {
            this.error(
              `Value for label ${outLabel} requires a code, but none was found. (around line ${this.currentLine}) Line contents: '${inLine2}'`,
            );
          }
        }
        return [outLabel, outRest];
      }
    }
    // const [label] = inLine.includes(':') ? inLine.split(':') : [null];
    // if (label && label === label.toUpperCase()) {
    //   this.error(`Unexpected label - ${label}`);
    // }
    return [null, inLine];
  }

  private labelAndRestFromNextLine(lines: readonly string[]): [string | null, string | null] {
    return !lines.length ? [null, null] : this.labelAndRestFromLine(lines[0]);
  }

  private consumeComment(): string | null {
    const label = this.labelAndRestFromNextLine(this.linesToEat)[0];
    if (label !== ScriptLabels.BeginIgnore) {
      return null;
    }
    const startingLine = this.currentLine;
    this.linesToEat.shift();
    let comment = '';
    while (this.linesToEat.length) {
      const line: string = this.linesToEat.shift() || '';
      const [label, rest] = this.labelAndRestFromLine(line);
      if (label === ScriptLabels.EndIgnore) {
        return strip(comment) ? comment : null;
      }
      comment += rest;
    }
    this.error(
      `Unterminated comment. Reached end of file expecting '${ScriptLabels.EndIgnore}'. Started around line ${startingLine}.`,
    );
    return null; // Right now this.error throws unconditionally, and therefore this is redundant, but it may not later.
  }

  private beginning(): void {
    const outDoc = this.outDocOrDie;
    while (this.linesToEat.length) {
      const label = this.labelAndRestFromNextLine(this.linesToEat)[0];
      if (label === ScriptLabels.BeginIgnore) {
        const comment = this.consumeComment();
        comment ? outDoc.comments.push(comment) : null;
      } else if (label && HeaderLabelsVals.includes(label)) {
        this.state = ParserStates.HEADER;
        // No validation to do here because all we're doing is ignoring everything until we get to a header label.
        return;
      } else if (label && ScriptLabelsVals.includes(label)) {
        this.error(
          `Unexpected non-header label found: '${label}' while looking for header labels around line ${this.currentLine}`,
        );
      } else {
        this.linesToEat.shift();
      }
    }
  }

  private header(): void {
    const haveSeen: Array<string> = [];
    const outDoc = this.outDocOrDie;
    while (this.linesToEat.length) {
      const [label, rest] = this.labelAndRestFromNextLine(this.linesToEat);
      if (label && HeaderLabelsVals.includes(label)) {
        this.linesToEat.shift();
        if (label === HeaderLabels.Author.toString()) {
          outDoc.author = rest;
        } else if (label === HeaderLabels.LastUpdate.toString()) {
          outDoc.lastUpdate = rest; // TODO: ISO8601 parsing
        } else if (label === HeaderLabels.LessonTitle.toString() && rest) {
          outDoc.lessonTitle = rest;
        } else if (label === HeaderLabels.LessonSubtitle.toString() && rest) {
          outDoc.lessonSubtitle = rest;
        } else if (label === HeaderLabels.LessonDescription.toString() && rest) {
          outDoc.lessonDescription = rest;
        } else if (label === HeaderLabels.LessonNominalDuration.toString() && rest) {
          outDoc.lessonDuration = hmsDurationToSeconds(rest);
        } else if (label === HeaderLabels.LessonMinimumDuration.toString() && rest) {
          outDoc.lessonMinDuration = hmsDurationToSeconds(rest);
        } else if (label === HeaderLabels.LessonMaximumDuration.toString() && rest) {
          outDoc.lessonMaxDuration = hmsDurationToSeconds(rest);
        } else if (label === HeaderLabels.LessonHomework.toString() && rest) {
          outDoc.lessonHomework = rest;
        } else if (label === HeaderLabels.LessonHomeworkDoneQuestion.toString() && rest) {
          outDoc.lessonHomeworkDoneQuestion = rest;
        } else if (label === HeaderLabels.LessonHomeworkNotDoneQuestion.toString() && rest) {
          outDoc.lessonHomeworkNotDoneQuestion = rest;
        }
        label ? haveSeen.push(label) : null;
      } else if (label === ScriptLabels.SectionTitle.toString()) {
        // Validate that we have the required fields.
        if (!haveSeen.includes(ScriptLabels.LessonTitle.toString())) {
          this.error(
            `${ScriptLabels.LessonTitle} is required, but appear to be missing around line ${this.currentLine}`,
          );
        }
        this.state = ParserStates.SECTION;
        return;
      } else if (label === ScriptLabels.BeginIgnore.toString()) {
        const comment = this.consumeComment();
        comment ? outDoc.comments.push(comment) : null;
      } else if (label && ScriptLabelsVals.includes(label)) {
        this.error(`Unexpected label: '${label}' in header around line ${this.currentLine}.`);
      } else {
        this.linesToEat.shift(); // Empty line
        // this.error(`Unexpected line while parsing headers: '${this.linesToEat[0]}' around line ${this.currentLine}.`);
      }
    }
  }

  private section(): void {
    const [_label, _rest] = this.labelAndRestFromNextLine(this.linesToEat);

    if (_label !== ScriptLabels.SectionTitle.toString()) {
      this.error(`Expected '${ScriptLabels.SectionTitle}' but got '${_label}' around line ${this.currentLine}.`);
    }

    const outDoc = this.outDocOrDie;
    const sec = new ScriptSection();
    this.currentSection = sec;
    outDoc.sections.push(sec);

    const secStartLine = this.currentLine;
    const [title, code] = extractStringAndCode(_rest);
    sec.sectionTitle = title ? strip(title) : title;
    sec.sectionCode = code;
    this.linesToEat.shift();

    while (this.linesToEat.length) {
      const line = this.linesToEat[0];
      const [label, rest] = this.labelAndRestFromNextLine(this.linesToEat);
      if (label === ScriptLabels.BeginIgnore.toString()) {
        const comment = this.consumeComment();
        comment ? sec.comments.push(comment) : null;
      } else if (!label && (!rest || !strip(rest).length)) {
        this.linesToEat.shift(); // Empty line
      } else if (label && SubsectionHeaderLabelsVals.includes(label)) {
        this.state = ParserStates.SUBSECTION;
        // No validation to do here because we never would've ended up here if we didn't get the one required label.
        return;
      } else {
        this.error(`Unexpected text in section: '${line}' around line ${this.currentLine}.`);
      }
    }
    if (!sec.subsections.length) {
      this.error(`File ended while parsing section with no subsections. Section began around line ${secStartLine}.`);
    }
    this.state = ParserStates.DONE;
    this.currentSection = null;
  }

  private subsection(): void {
    const label = this.labelAndRestFromNextLine(this.linesToEat)[0];
    if (!label || !SubsectionHeaderLabelsVals.includes(label)) {
      this.error(`Unexpected label: '${label}' around line ${this.currentLine}.`);
    }
    const outDoc = this.outDocOrDie;
    const css: ScriptSubsection = new ScriptSubsection();
    this.currentSubsection = css;
    if (!this.currentSection) {
      throw new Error("currentSection was null when it shouldn't have been.");
    }
    this.currentSection.subsections.push(css);

    const haveSeen: Array<string> = [];
    let spokenText = '';
    let collectingSpokenText = false;
    let readyToFinish = false;
    let sawSilenceSpec = false;
    while (this.linesToEat.length) {
      const [label, rest] = this.labelAndRestFromNextLine(this.linesToEat);
      // Skip blank lines
      if (!label && !collectingSpokenText && (!rest || !strip(rest).length)) {
        this.linesToEat.shift(); // Empty line
        continue;
      }
      // Collect comments
      if (label === ScriptLabels.BeginIgnore.toString()) {
        const comment = this.consumeComment();
        comment ? css.comments.push(comment) : null;
      }

      // Process enders - I know it's not logical to parse the enders at the beginning, but it has to be this way.
      if (
        (label && SubsectionDoneLabelsVals.includes(label)) ||
        (readyToFinish && label && SubsectionHeaderLabelsVals.includes(label))
      ) {
        css.spokenText = rstrip(spokenText) + '\n';
        // Validate
        // Next subsection can't coexist with tap destinations.
        const nextSubsec: boolean = haveSeen.includes(SubsectionFooterLabels.NextSubsectionCode.toString());
        const singleTap: boolean = haveSeen.includes(SubsectionFooterLabels.SingleTapDest.toString());
        const doubleTap: boolean = haveSeen.includes(SubsectionFooterLabels.DoubleTapDest.toString());
        const tripleTap: boolean = haveSeen.includes(SubsectionFooterLabels.TripleTapDest.toString());
        if (nextSubsec && (singleTap || doubleTap || tripleTap)) {
          this.error(
            `Both tap destinations and a next subsection were supplied. These are mutually exclusive. Happened around line ${this.currentLine}. (Spoken text: ${css.spokenText})`,
          );
        }
        if (
          (tripleTap && !(singleTap && doubleTap)) ||
          (doubleTap && !singleTap) ||
          (singleTap && !doubleTap && !tripleTap)
        ) {
          this.error(`Tap destinations look non-sensical. Happened around line ${this.currentLine}.`);
        }
        if (label === ScriptLabels.LessonEnd.toString()) {
          this.state = ParserStates.TAIL;
        }
        if (label === ScriptLabels.SectionTitle.toString()) {
          this.state = ParserStates.SECTION;
        }
        this.currentSubsection = null;

        // provide sensible defaults
        if (!sawSilenceSpec) {
          css.silenceFixedLen = true;
        }

        return;
      }

      // Headers
      if (label && SubsectionHeaderLabelsVals.includes(label)) {
        if (haveSeen.includes(label)) {
          this.error(`Duplicate subsection label: ${label} around line ${this.currentLine}.`);
        }
        label ? haveSeen.push(label) : null;
      }

      if (label === SubsectionHeaderLabels.SubsectionInfo.toString()) {
        const [title, code] = extractStringAndCode(rest);
        css.title = title ? strip(title) : title;
        css.code = code;
        this.linesToEat.shift();
        continue;
      } else if (label === SubsectionHeaderLabels.SubsectionSummary.toString()) {
        const [summary, duration] = extractStringAndCode(rest);
        css.summaryText = !summary ? null : strip(summary).length ? strip(summary) : null;
        css.duration = hmsDurationToSeconds(duration);
        this.linesToEat.shift();
        continue;
      } else if (label === SubsectionHeaderLabels.PathNameCode.toString()) {
        const [path, code] = extractStringAndCode(rest);
        css.path = path;
        css.pathCode = code;
        if (code && !outDoc.paths.includes(code)) {
          outDoc.paths.push(code);
        }
        this.linesToEat.shift();
        continue;
      }

      // Spoken text
      if ((!label || !label.length) && rest && rest.length) {
        readyToFinish = collectingSpokenText = true;
        spokenText += rest;
        this.linesToEat.shift();
        continue;
      }

      // Footers
      if (label && SubsectionFooterLabelsVals.includes(label)) {
        collectingSpokenText = false;
        readyToFinish = true;
        css.spokenText = rstrip(spokenText) + '\n';
        if (label === ScriptLabels.NextSubsectionCode.toString()) {
          css.nextSubsection = rest;
          haveSeen.push(label);
        } else if (label === ScriptLabels.SingleTapDest.toString()) {
          css.singleTap = rest;
          haveSeen.push(label);
        } else if (label === ScriptLabels.DoubleTapDest.toString()) {
          css.doubleTap = rest;
          haveSeen.push(label);
        } else if (label === ScriptLabels.TripleTapDest.toString()) {
          css.tripleTap = rest;
          haveSeen.push(label);
        } else if (label === ScriptLabels.SubsectionSilence.toString()) {
          const [duration, fixed] = extractStringAndCode(rest);
          css.silenceDuration = hmsDurationToSeconds(duration);
          css.silenceFixedLen = fixed === 'FIXED';
          sawSilenceSpec = true;
          haveSeen.push(label);
        } else if (label === ScriptLabels.HomeworkAnswer.toString()) {
          css.homeworkAnswer = rest;
          haveSeen.push(label);
        } else if (label === ScriptLabels.HomeworkStatus) {
          css.homeworkStatus = rest;
          haveSeen.push(label);
        } else if (label === ScriptLabels.Type) {
          haveSeen.push(label);
          css.type = rest;
        } else if (label === ScriptLabels.SubType) {
          haveSeen.push(label);
          css.subType = rest;
        } else if (SwitchLinkKeys.includes(label as ScriptLabels)) {
          haveSeen.push(label);
          css.switchOptions.push({
            type: label.replace(':', '').replace('_', ''),
            nextNode: rest,
          });
        } else {
          this.error(`Unhandled subsection footer. (This is a programming error in the parser)`);
        }
        this.linesToEat.shift();
      }
    }

    // Out of lines
    this.currentSubsection = null;
    this.state = ParserStates.DONE;
  }

  private tail(): void {
    const outDoc = this.outDocOrDie;
    while (this.linesToEat.length) {
      const [label, rest] = this.labelAndRestFromNextLine(this.linesToEat);
      if (label === ScriptLabels.LessonEnd.toString()) {
        // Lesson end tag
        this.linesToEat.shift();
      } else if (!label && (!rest || !strip(rest).length)) {
        // Blank line
        this.linesToEat.shift();
      } else if (label === ScriptLabels.BeginIgnore.toString()) {
        // Comments
        const comment = this.consumeComment();
        comment ? outDoc.comments.push(comment) : null;
      }
    }
    this.state = ParserStates.DONE;
  }

  parse(): ScriptLesson {
    this.linesToEat = [...this.lines];
    this.outDoc = new ScriptLesson();
    while (this.state !== ParserStates.DONE) {
      const func = this.funcMap[this.state];
      func();
    }
    return this.outDoc;
  }
}
