import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { PATH_TYPE_TABLE_NAME } from './tableName';
import { PathTypeId } from '../modeltypes/id';
import { PathType, PathTypeBase, PathTypeBuilder, PathTypeNew } from '../modeltypes/paths';

// noinspection JSUnusedGlobalSymbols
export const getNewPathTypeId = getNewIdFor<PathTypeId>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewPathType = addNewFor<PathTypeId, PathTypeNew>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewPathType = setNewFor<PathTypeId, PathTypeNew>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewPathType = createNewFor<PathTypeId, PathTypeBuilder>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getPathTypeRefById = getRefByIdFor<PathTypeId>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getPathTypeById = getByIdFor<PathTypeId, PathType>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getPathTypeByIdArray = getByIdArrayFor<PathTypeId, PathType>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllPathType = getAllFor<PathType>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllPathTypeQuery = getAllQueryFor(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getPathTypeByQuery = getByQueryFor<PathType>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updatePathType = updateDocumentFor<PathTypeId, PathTypeBase>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deletePathType = deleteDocumentFor<PathTypeId>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchPathTypeIdSet = watchIdSetFor<PathTypeId>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validatePathType = getValidateTypeFor<PathType>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validatePathTypeBase = getValidateTypeBaseFor<PathTypeBase>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validatePathTypeBuilder = getValidateTypeBuilderFor<PathTypeBuilder>(PATH_TYPE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validatePathTypeNew = getValidateTypeNewFor<PathTypeNew>(PATH_TYPE_TABLE_NAME);
