import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAudioNodesByIdArray, deleteAudioNode } from 'src/collections/audioNode';
import { AudioNodeType } from 'src/modeltypes/audioNode';
import cloneDeep from 'clone-deep';
import { LessonAudioChunkType } from '../../modeltypes/lessonAudioChunk';
import { secondsDurationToHmsTolerant } from '../../utility/GeneralUtilities';
import { LessonAudioChunkFlow } from './LessonAudioChunkFlow';

type SumTimeDisplay = {
  min: number;
  sec: number;
};
export const LessonChunkNodesList = ({
  audioChunk,
}: // locked,
{
  audioChunk: LessonAudioChunkType;
  locked: boolean;
}) => {
  const navigate = useNavigate();
  const [audioSummation, setAudioSummation] = useState<SumTimeDisplay | null>(null);
  const [silenceSummation, setSilenceSummation] = useState<SumTimeDisplay | null>(null);
  const [audioNodes, setAudioNodes] = useState<AudioNodeType[]>();

  const getMinAndSecFromNumberArray = (array: number[]) => {
    const timeSummation = array.reduce((sum, duration) => sum + duration, 0);
    const min = Math.floor(timeSummation / 60);
    const sec = timeSummation - min * 60;
    return {
      min,
      sec,
    };
  };

  // Getting the summation of the Audio files for total
  //TODO This does not fully count because it is a tree and not linear!!!! DANG
  const getAudioDurationSummary = async (audioNodeIds: string[] | []) => {
    const audioNodeList: AudioNodeType[] = await getAudioNodesByIdArray(audioNodeIds, true);
    const audioLengths = audioNodeList?.map((c) => c?.duration || 0);
    const silenceLengths = audioNodeList?.map((c) => c?.silenceDuration || 0);
    const audioTotal = getMinAndSecFromNumberArray(audioLengths);
    const silenceTotal = getMinAndSecFromNumberArray(silenceLengths);

    setAudioNodes(audioNodeList);
    setAudioSummation(audioTotal);
    setSilenceSummation(silenceTotal);
  };

  useEffect(() => {
    const fetchData = async () => {
      getAudioDurationSummary(audioChunk.audioNodes);
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioChunk]);

  const checkIfDetached = useCallback(
    (node: AudioNodeType) => {
      const hasParent = audioNodes?.some((el) =>
        [
          el.nextSubsection,
          el.singleTap,
          el.doubleTap,
          el.tripleTap,
          ...(el.switchOptions?.map((option) => option.nextNode || '') || []),
        ].includes(node.id),
      );
      const hasChild =
        (node.nextSubsection && node.nextSubsection.toUpperCase() !== 'END') ||
        node.singleTap ||
        node.doubleTap ||
        node.tripleTap ||
        node.switchOptions?.some((el) => el.nextNode) ||
        false;

      return !hasParent && !hasChild;
    },
    [audioNodes],
  );

  const deleteNode = async (node: AudioNodeType) => {
    if (audioNodes) {
      const audioNodesTemp = cloneDeep(audioNodes);
      const index = audioNodesTemp?.findIndex((el) => el.id === node.id);
      audioNodesTemp.splice(index, 1);
      setAudioNodes(audioNodesTemp);
      deleteAudioNode(node.id);
    }
  };

  return !audioChunk ? null : (
    <>
      <div className='sm:grid sm:items-start sm:pt-5'>
        <div className={'flex flex-row justify-between items-center'}>
          <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2'>
            Audio nodes
          </label>
        </div>
        <div className='mt-5 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full'>
                  <thead className='bg-white'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Node Name
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Title
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        {`Duration\n${audioSummation?.min}min ${audioSummation?.sec}sec`}
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        {`Duration\n${silenceSummation?.min}min ${silenceSummation?.sec}sec`}
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Audio Url
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Type
                      </th>
                      {audioChunk.productionStatus !== 'PROD' ? (
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          To check
                        </th>
                      ) : null}
                      <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <div
                          className='text-center text-indigo-600 hover:text-indigo-900 cursor-pointer'
                          onClick={() => {
                            navigate(`${window.location.pathname}/new`);
                          }}
                        >
                          Add Node
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white'>
                    {(audioNodes || []).map((node, nodeIdx) => (
                      <tr key={node.id} className={`${nodeIdx === 0 ? 'border-gray-300' : 'border-gray-200'} border-t`}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                          {node.id}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {node.code || '<NO CODE SET>'}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{node.title}</td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {secondsDurationToHmsTolerant(node.duration)}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {secondsDurationToHmsTolerant(node.silenceDuration)} {node.silenceFixedLen ? '(F)' : ''}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 overflow-hidden truncate ... max-w-[100px]'>
                          <a target='_blank' rel='noopener noreferrer' href={node.audioUrl || ''}>
                            {node.audioUrl}
                          </a>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{node.nodeType}</td>
                        {audioChunk.productionStatus !== 'PROD' ? (
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500 font-bold'>
                            {node.toCheck ? 'Check required' : null}
                          </td>
                        ) : null}
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <div
                            className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer'
                            onClick={() => {
                              const thisRoute = `${window.location.pathname}/${node.id}`;
                              navigate(thisRoute);
                            }}
                          >
                            Edit
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <button
                            disabled={!checkIfDetached(node)}
                            className={`text-indigo-600 hover:text-indigo-900 ${
                              checkIfDetached(node) ? 'opacity-100' : 'opacity-25'
                            }`}
                            onClick={() => {
                              deleteNode(node);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(audioNodes || []).length === 0 ? null : (
        <LessonAudioChunkFlow
          audioNodes={audioNodes || []}
          setAudioNodes={setAudioNodes}
          locked={audioChunk.productionStatus === 'PROD'}
        />
      )}
    </>
  );
};
