// *******************************************************
// LessonAnalysisPopupRating
// -------------------------------------------------------
// This is a Component for Editing / adding company
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import colors from 'src/assets/colors';
import BarChart from 'src/components/BarChart';
import { VictoryPie } from 'victory';
// *******************************************
// Component Imports
// -------------------------------------------
// *******************************************
// Hooks Import
// -------------------------------------------
// *******************************************
// Action Imports
// -------------------------------------------
// *******************************************
// Styles Imports
// -------------------------------------------
// *******************************************
// Constants
// -------------------------------------------
// const GLOBAL_AVERAGE_BREATH = 16.52;
// *******************************************
// Types
// -------------------------------------------

export interface RatingPopupDataType {
  chartData:
    | {
        x: string;
        y: number;
      }[]
    | null;
  chartTickData: string[] | null;
  unitData?: { name: string; id: string; isV2?: boolean } | null;
}

const LessonAnalysisPopupRating = ({ data, onPressClose }: { data: RatingPopupDataType; onPressClose: () => void }) => {
  console.log('data', data);

  const one = data?.chartData?.[0]?.y || 0;
  const two = data?.chartData?.[1]?.y || 0;
  const three = data?.chartData?.[2]?.y || 0;
  const four = data?.chartData?.[3]?.y || 0;
  const five = data?.chartData?.[4]?.y || 0;

  const stringOne = data?.chartData?.[0]?.x || 0;
  const stringTwo = data?.chartData?.[1]?.x || 0;
  const stringThree = data?.chartData?.[2]?.x || 0;
  const stringFour = data?.chartData?.[3]?.x || 0;
  const stringFive = data?.chartData?.[4]?.x || 0;

  console.log('one', one);
  console.log('two', two);
  console.log('three', three);
  console.log('four', four);
  console.log('five', five);

  const total = one + two + three + four + five;

  const pie1 = one
    ? [{ label: `${stringOne} Stars (${one}-${Math.floor((one / total) * 100)} %)`, y: (one / total) * 100 || 0 }]
    : [];
  const pie2 = two
    ? [{ label: `${stringTwo} Stars (${two}-${Math.floor((two / total) * 100)} %)`, y: (two / total) * 100 || 0 }]
    : [];
  const pie3 = three
    ? [
        {
          label: `${stringThree} Stars (${three}-${Math.floor((three / total) * 100)} %)`,
          y: (three / total) * 100 || 0,
        },
      ]
    : [];
  const pie4 = four
    ? [{ label: `${stringFour} Stars (${four}-${Math.floor((four / total) * 100)} %)`, y: (four / total) * 100 || 0 }]
    : [];
  const pie5 = five
    ? [{ label: `${stringFive} Stars (${five}-${Math.floor((five / total) * 100)} %)`, y: (five / total) * 100 || 0 }]
    : [];

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative  w-full max-w-3xl h-full md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700  p-20'>
          <div
            style={{ background: colors.accLightBlue30 }}
            className='flex text-gray-400  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <button type='button' onClick={() => (onPressClose ? onPressClose() : null)}>
              <svg
                aria-hidden='true'
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
              <span className='sr-only'>Close modal</span>
            </button>
          </div>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <BarChart
              chartData={[
                {
                  data: data?.chartData || [],
                  fill: 'lightblue',
                  name: 'Whos Completed what',
                },
              ]}
              chartTitle={data?.unitData?.name || 'Some Lesson'}
              tickValues={data?.chartTickData || []}
              domainPadding={20}
              tooltipsEnabled
              xLabel={'Rating'}
              yLabel={'# of Votes'}
            />
          </div>
          <div style={{ height: 600, width: 600 }}>
            <VictoryPie
              animate={{
                duration: 2000,
              }}
              colorScale={['tomato', 'orange', 'gold', 'cyan', 'yellow']}
              style={{ labels: { fill: 'black', fontSize: 6, fontWeight: 'bold' } }}
              labelRadius={() => 100}
              data={[...pie1, ...pie2, ...pie3, ...pie4, ...pie5]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonAnalysisPopupRating;
