// *******************************************************
// Quarter on Quarter Analysis
// -------------------------------------------------------
// This is a Screen for showing list all Statistics for month to month
// Mat wants the following fds
// Downloads
// Subscriptions
// Active Users
// Average time in lesson per user

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useMemo, useState } from 'react';
import { AnalyticsType } from 'src/modeltypes/analytics';
import { format, monthsToQuarters } from 'date-fns';
import BarChart from 'src/components/BarChart';
import colors from 'src/assets/colors';
import Select from 'react-select';
import { AnalysisTypeSelector } from './Home';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

const QuarteronQuarterAnalysis = ({ analytics }: { analytics: AnalyticsType[] | undefined }) => {
  console.log('QuarteronQuarterAnalysis analytics: ', analytics);

  // Chart Details
  const chartDetails = [
    {
      chart: 'totalUsers',
      title: 'Total Users',
      xLabel: 'Quarter',
      yLabel: 'User Count',
      color: colors.primary,
    },
    {
      chart: 'subscriptions',
      title: 'Subscriptions',
      xLabel: 'Quarter',
      yLabel: 'User Count',
      color: colors.secondary,
    },
    {
      chart: 'averageTimeInLessonsPerUser',
      title: 'Average Time In Lessons Per User',
      xLabel: 'Quarter',
      yLabel: 'Minutes',
      color: colors.yellow,
    },
    {
      chart: 'expiredSubscriptions',
      title: 'Expirations per Quarter',
      xLabel: 'Quarter',
      yLabel: 'User Count',
      color: colors.primary,
    },

    {
      chart: 'churn',
      title: 'Churn Rate ',
      xLabel: 'Quarter',
      yLabel: 'Percentage',
      color: colors.yellow,
    },

    {
      chart: 'activeQuaterlyUsers',
      title: 'Quaterly Active Users ',
      xLabel: 'Quarter',
      yLabel: 'userCount',
      color: colors.yellow,
    },
  ];

  // Put in a selector just in case you want to chage to subscription, cohort, etc.
  const [currentType, setCurrentMonthType] = useState('ALL');

  const quarterlyBreakdownTotalValues = useMemo(() => {
    let thisTotalKey: any;

    if (currentType === 'ALL') {
      thisTotalKey = 'allUsersTotal';
    }
    if (currentType === 'B2C') {
      thisTotalKey = 'b2cTotal';
    }
    if (currentType === 'COMPANYS') {
      thisTotalKey = 'companyTotal';
    }

    if (currentType === 'COHORTS') {
      thisTotalKey = 'cohortTotal';
    }
    if (currentType === 'SUBSCRIPTIONS') {
      thisTotalKey = 'subscriptionsTotal';
    }

    if (analytics?.length) {
      // FIRST WE ARE GOING TO GET ALL DAILY DATA...
      const dailyData = analytics
        ?.filter((analytic) => analytic?.type === 'TODAY')
        .sort((a: any, b: any) => {
          const thisA = a.createdAt.toDate() || 0;
          const thisB = b.createdAt.toDate() || 0;
          return thisB - thisA;
        });

      const totalData = analytics
        ?.filter((analytic) => analytic?.type === 'TOTAL')
        .sort((a: any, b: any) => {
          const thisA = a.createdAt.toDate() || 0;
          const thisB = b.createdAt.toDate() || 0;
          return thisB - thisA;
        });

      const quaterDaily = dailyData?.reduce((quaterMap: { [month: string]: any[] }, data) => {
        const date = data.createdAt.toDate();
        const thisMonth = date.getMonth();
        const thisQuater = monthsToQuarters(thisMonth) + 1;

        const thisYear = date.getFullYear();

        const thisKey = `${thisYear}-Q${thisQuater}`;

        (quaterMap[thisKey] = quaterMap[thisKey] || []).push(data);
        return quaterMap;
      }, {});

      const quaterTotal = totalData?.reduce((quaterMap: { [month: string]: any[] }, data) => {
        const date = data.createdAt.toDate();
        const thisMonth = date.getMonth();
        const thisQuater = monthsToQuarters(thisMonth) + 1;

        const thisYear = date.getFullYear();

        const thisKey = `${thisYear}-Q${thisQuater}`;

        (quaterMap[thisKey] = quaterMap[thisKey] || []).push(data);
        return quaterMap;
      }, {});

      const desiredKeys = Object.keys(quaterDaily);

      const derivedData: any[] = [];

      desiredKeys.forEach((thisQuaterhString) => {
        console.log('thisQuaterhString: ', thisQuaterhString);
        const dailyQuaterData = quaterDaily?.[thisQuaterhString];
        const totalQuaterData = quaterTotal?.[thisQuaterhString];

        console.log('dailyQuaterData: ', dailyQuaterData);

        const totalUserData = totalQuaterData?.map((data) => data?.[thisTotalKey]);

        const date = totalQuaterData?.[totalQuaterData.length - 1].createdAt.toDate();
        const thisMonth = date.getMonth();
        const thisYear = date.getFullYear();
        const startDate = totalQuaterData?.[totalQuaterData.length - 1].createdAt.toDate();
        const endDate = totalQuaterData?.[0].createdAt.toDate();

        const LessonsPerUser = totalUserData.reduce(
          (sum: number, { averageLessonsPerUser }: { averageLessonsPerUser: number }) => sum + averageLessonsPerUser,
          0,
        );

        const averageLessonsPerUser = LessonsPerUser / totalUserData.length;

        const timeInLessonsPerUser = totalUserData.reduce(
          (sum: number, { averageTimeInLessonsPerUser }: { averageTimeInLessonsPerUser: number }) =>
            sum + averageTimeInLessonsPerUser,
          0,
        );

        const averageTimeInLessonsPerUser = timeInLessonsPerUser / totalUserData.length;

        const firstNewUsers = totalUserData?.[totalUserData.length - 1]?.totalUsers;
        const lastNewUsers = totalUserData?.[0]?.totalUsers;

        const newUsers = lastNewUsers - firstNewUsers;

        const firstExpiredSubscription = totalUserData?.[totalUserData.length - 1]?.expiredSubscriptionAccounts;
        const lastExpiredSubscription = totalUserData?.[0]?.expiredSubscriptionAccounts;

        const expiredSubscriptions = lastExpiredSubscription - firstExpiredSubscription;

        const firstSubscription = totalUserData?.[totalUserData.length - 1]?.subscriptionAccounts;
        const lastSubscription = totalUserData?.[0]?.subscriptionAccounts;

        // I can not get QuaterlyWithout caculating it through the back propogator....
        // Because you do not know which users are repeat users...

        const lastMonthlyActiveAccount = totalUserData?.[0]?.monthlyActiveUsers;

        const totalSubscriptions = lastSubscription - firstSubscription;

        const totalUsers = totalUserData?.[totalUserData.length - 1]?.totalUsers;

        //FROM https://blog.hubspot.com/service/what-is-churn-rate...
        //Customer Churn Rate = (Lost Customers ÷ Total Customers at the Start of Time Period) x 100

        const churn = !expiredSubscriptions
          ? 0
          : !totalSubscriptions
          ? 0
          : (expiredSubscriptions / totalSubscriptions) * 100;

        // console.log('churn totalSubscriptions', totalSubscriptions);
        // console.log('churn expiredSubscriptions', expiredSubscriptions);
        // console.log(totalStartDate, ' churn : ', churn);

        // Downloads
        // Subscriptions
        // Active Users
        // Average time in lesson per user

        const derivedTotalData = {
          month: thisMonth,
          year: thisYear,
          id: thisQuaterhString,
          quater: monthsToQuarters(thisMonth) + 1,
          startDate,
          endDate,
          monthString: format(date, 'MMM'),
          newUsers,
          expiredSubscriptions,
          averageLessonsPerUser,
          averageTimeInLessonsPerUser,
          subscriptions: totalSubscriptions,
          totalUsers,
          activeMonthlyUsers: lastMonthlyActiveAccount,
          churn,
        };

        derivedData.push({ ...derivedTotalData });
      });

      console.log('derivedData: ', derivedData);

      return derivedData;
    }

    return null;
  }, [analytics, currentType]);

  const orderedData = quarterlyBreakdownTotalValues?.sort((a: any, b: any) => {
    const thisA = a.startDate || 0;
    const thisB = b.startDate || 0;
    return thisA - thisB;
  });

  console.log('orderedData: ', orderedData);

  const keysWeAreGoingToChart = [
    'totalUsers',
    'activeQuaterlyUsers',
    'averageLessonsPerUser',
    'averageTimeInLessonsPerUser',
    'subscriptions',
    'expiredSubscriptions',
    'churn',
  ];

  console.log('quarterlyBreakdownTotalValues : ', quarterlyBreakdownTotalValues);

  return (
    <>
      <div className='flex'>QUATER ON QUATER</div>
      <Select
        options={AnalysisTypeSelector}
        value={AnalysisTypeSelector.find((e) => currentType === e.value)}
        onChange={(option) => {
          setCurrentMonthType(option?.value || 'ALL');
        }}
        className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
      />

      {keysWeAreGoingToChart.map((thisKey) => {
        const thisChartDetails = chartDetails.find((x) => x.chart === thisKey);

        const thisKeyChartData =
          orderedData?.map((oD) => {
            const thisValue = oD?.[thisKey];
            const thisQuater = monthsToQuarters(oD?.month || 0) + 1;
            const shortYear = oD?.year ? (oD?.year + '')?.slice(-2) : '?';
            const thisLabel = `${shortYear}-Q${thisQuater}`;

            return {
              x: thisLabel,
              y: thisValue,
            };
          }) || [];

        const tickValues = thisKeyChartData.map((z) => z.y);
        console.log('tickValues', tickValues);

        return (
          <div key={thisKey}>
            <BarChart
              chartData={[
                {
                  data: thisKeyChartData,
                  fill: thisChartDetails?.color,
                  name: thisKey,
                },
              ]}
              chartTitle={thisChartDetails?.title || thisKey}
              tickValues={tickValues}
              verticalLabel
              barWidth={10}
              domainPadding={10}
              tooltipsEnabled
              xLabel={thisChartDetails?.xLabel || 'Quater'}
              yLabel={thisChartDetails?.yLabel || 'User Count'}
            />
          </div>
        );
      })}
    </>
  );
};

export default QuarteronQuarterAnalysis;
