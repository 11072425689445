// *******************************************************
// CreateChunkModal
// -------------------------------------------------------
// This is a CreateChunkModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useState } from 'react';
import { ChapterType } from '../../modeltypes/chapter';
import { AudioNodeType, TrackType } from '../../modeltypes/audioNode';
import { LessonType } from '../../modeltypes/lesson';
import { LessonAudioChunkTypeNew } from '../../modeltypes/lessonAudioChunk';
import { toast } from 'react-toastify';
import { addNewLessonAudioChunk } from '../../collections/lessonAudioChunk';
import { getBlob, ref } from 'firebase/storage';
import { storage } from '../../firebase/firebaseConfig';
import { createNewAudioNode, setNewAudioNode } from '../../collections/audioNode';
import { uploadFileNoThrow } from '../../firebase/storage';
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import cloneDeep from 'clone-deep';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../enums/pages';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
interface CreateChunkModalProps {
  selectedChapters: ChapterType[];
  hide: () => void;
  audioNodes: AudioNodeType[];
  lesson: LessonType;
}

const CreateChunkModal = ({ hide, selectedChapters, audioNodes, lesson }: CreateChunkModalProps) => {
  const [chunkName, setChunkName] = useState<string>(
    selectedChapters.length === 1 ? selectedChapters[0].title || '' : '',
  );
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [newChunkId, setNewChunkId] = useState<string | null>(null);
  const [nodes, setNodes] = useState(audioNodes);

  const duplicateAudioNodes = async (): Promise<string[]> => {
    const newIds = new Map<string, string>();
    const newAudioNodes = [];
    for (const audioNode of nodes) {
      const newAudioNode = cloneDeep(audioNode);
      newAudioNode.id = createNewAudioNode().id;

      newIds.set(audioNode.id, newAudioNode.id);

      const audioRef = audioNode.audioUrl ? ref(storage, audioNode.audioUrl) : null;
      if (audioRef) {
        const audioBlob = await getBlob(audioRef);

        const storageFileName = `${newAudioNode.id}-${audioNode.code}-nodeAudio.m4a`;
        const uploadResult = await uploadFileNoThrow(dieIfNullOrUndef(audioBlob), storageFileName, [
          'assets',
          newAudioNode.id,
        ]);
        newAudioNode.audioUrl = uploadResult.downloadUrl;
      }
      newAudioNodes.push(newAudioNode);
    }

    for (const newAudioNode of newAudioNodes) {
      console.log({
        id: newAudioNode.id,
        singleTap: newIds.get(newAudioNode.singleTap || '') || null,
        doubleTap: newIds.get(newAudioNode.doubleTap || '') || null,
        tripleTap: newIds.get(newAudioNode.tripleTap || '') || null,
      });
      await setNewAudioNode(newAudioNode.id, {
        ...newAudioNode,
        nodeType: newAudioNode.nodeType === 'H_CHECK' ? TrackType.STORY : newAudioNode.nodeType,
        shouldCheckHomework: newAudioNode.nodeType === 'H_CHECK' || null,
        singleTap: newIds.get(newAudioNode.singleTap || '') || null,
        doubleTap: newIds.get(newAudioNode.doubleTap || '') || null,
        tripleTap: newIds.get(newAudioNode.tripleTap || '') || null,
        nextSubsection: newIds.get(newAudioNode.nextSubsection || '') || null,
        switchOptions:
          newAudioNode.switchOptions?.map((el) => ({
            type: el.type,
            nextNode: el.nextNode ? newIds.get(el?.nextNode) || null : null,
          })) || null,
      });
    }
    return Object.values(Array.from(newIds, ([, value]) => value));
  };

  const createChunk = async () => {
    try {
      setIsPending(true);
      toast.info('Creating new chunk', {
        toastId: 'newChunk',
        theme: 'colored',
        autoClose: false,
        closeOnClick: false,
        hideProgressBar: false,
        isLoading: true,
      });
      const newAudioIds = await toast.promise(() => duplicateAudioNodes(), {
        pending: 'Duplicating audio nodes and files',
        error: "Can't duplicate audio node",
        success: 'Duplicated nodes and audio files!',
      });
      const newChunk: LessonAudioChunkTypeNew = {
        audioNodes: newAudioIds,
        title: chunkName,
        importedFromGDocID: lesson.importedFromGDocID,
        voiceArtist: lesson.voiceArtist,
        author: lesson.author,
      };
      await addNewLessonAudioChunk(newChunk).then((id) => {
        setNewChunkId(id);
        toast.update('newChunk', {
          render: 'New Chunk successfully created!',
          type: 'success',
          theme: 'colored',
          autoClose: 5000,
          closeOnClick: true,
          isLoading: false,
        });
      });
      setIsPending(false);
    } catch (e) {
      console.debug(e);
      toast.update('newChunk', {
        render: 'Creating failed!',
        type: 'error',
        isLoading: false,
        theme: 'colored',
        autoClose: 5000,
        closeOnClick: true,
      });
    }
  };

  const changeToCheck = (audioNodeId: string, checked: boolean) => {
    setNodes((prev) =>
      prev.map((node) =>
        node.id === audioNodeId
          ? {
              ...node,
              toCheck: checked,
            }
          : node,
      ),
    );
  };

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[10%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative w-full max-w-6xl h-full md:h-auto'>
        <button className='absolute font-bold right-6 top-5 z-10 text-white text-[1.2rem]' onClick={() => hide()}>
          X
        </button>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex flex-col p-5 rounded-t dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white w-full border-b pb-4'>New chunk</h3>
            <div className={'mx-20'}>
              <div className={'flex flex-row mt-5 items-center '}>
                <label htmlFor='name' className='block text-lg font-bold text-gray-700 dark:text-white w-4/12'>
                  Chunk Title
                </label>
                <div className='w-full flex rounded-md shadow-sm'>
                  <input
                    maxLength={40}
                    type='text'
                    name='title'
                    id='title'
                    autoComplete='title'
                    className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300`}
                    onChange={(e) => setChunkName(e.target.value)}
                    value={chunkName}
                  />
                </div>
              </div>
              <div>
                <p className='text-lg font-bold text-gray-700 dark:text-white mt-4'>Audio Nodes</p>
                <div className={'mt-4 bg-gray-200'}>
                  <table className={'w-full text-center'}>
                    <thead>
                      <tr className={'bg-gray-400'}>
                        <th>To check</th>
                        <th className='text-start p-3'>Title</th>
                        <th className='text-start'>Code</th>
                        <th className='text-start'>From chapter</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nodes.map((audioNode) => (
                        <tr key={audioNode.id}>
                          <td className='p-2'>
                            <input
                              type={'checkbox'}
                              onClick={(e) => changeToCheck(audioNode.id, (e.target as HTMLInputElement).checked)}
                              checked={audioNode.toCheck || false}
                            />
                          </td>
                          <td className='text-start p-3'>{audioNode.title}</td>
                          <td className='text-start p-2'>{audioNode.code}</td>
                          <td className='text-start p-2'>
                            {selectedChapters.find((c) => c.audioNodes?.includes(audioNode.id))?.title}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='pt-5 pb-5'>
              <div className='flex justify-end'>
                <button
                  disabled={isPending}
                  type='button'
                  className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => hide()}
                >
                  Cancel
                </button>
                {newChunkId ? (
                  <button
                    disabled={isPending}
                    type='button'
                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    onClick={() => {
                      navigate(`/${Pages.LessonAudioChunks}/${newChunkId}`);
                    }}
                  >
                    Go to chunk
                  </button>
                ) : (
                  <button
                    disabled={isPending}
                    type='button'
                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    onClick={() => {
                      if (/^ *$/.test(chunkName)) {
                        toast.error("Chunk Name can't be empty");
                      } else {
                        createChunk();
                      }
                    }}
                  >
                    Create
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChunkModal;
