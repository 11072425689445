// *******************************************************
// SubscriptionAnalysis
// -------------------------------------------------------
// This is a Screen for showing list all Subscriptions
// We want to understand who is getting communcation and are they responding to it.

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import { format } from 'date-fns';
import * as React from 'react';
import { useEffect, useState } from 'react';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
import { getAllSubscriptions } from 'src/collections/subscriptions';
import { getUsersByIdArray } from 'src/collections/user';
import { getUserLeaderBoardByIdArray } from 'src/collections/userLeaderBoard';
import { StreakType } from 'src/modeltypes/streaks';
// import { AnalyticsType } from 'src/modeltypes/analytics';
import { SubscriptionType } from 'src/modeltypes/subscriptions';
import { UserType } from 'src/modeltypes/user';
import { VictoryBar, VictoryChart, VictoryGroup, VictoryPie } from 'victory';
// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

const SubscriptionAnalysis = () => {
  // const SubscriptionAnalysis = ({ analytics }: { analytics: AnalyticsType[] | undefined }) => {
  // const [subscriptionss, setSubscriptions] = useState<SubscriptionType[] | null>(null);
  const [subscriptionssTotal, setSubscriptionsTotal] = useState<SubscriptionType[] | null>(null);
  const [leaderBoard, setLeaderBoard] = useState<StreakType[] | null>(null);
  const [users, setUsers] = useState<UserType[] | null>(null);
  // const [subscriptionssStatistics, setSubscriptionsStatistics] = useState(null);
  const [subscriptionsExpired, setSubscriptionsExpired] = useState<SubscriptionType[] | null>(null);
  const [subscriptionsActive, setSubscriptionsActive] = useState<SubscriptionType[] | null>(null);
  const [subscriptionsIos, setSubscriptionIos] = useState<SubscriptionType[] | null>(null);
  const [subscriptionsAndroid, setSubscriptionAndroid] = useState<SubscriptionType[] | null>(null);
  const [subscriptionsTypes, setSubscriptionTypes] = useState<{ [type: string]: any[] } | null>(null);
  const [subscriptionsTypeValues, setSubscriptionTypeValues] = useState<{ [type: string]: number } | null>(null);
  const [subscriptionsExpiredTypeValues, setSubscriptionExpiredTypeValues] = useState<{
    [type: string]: number;
  } | null>(null);
  const [subscriptionsActiveTypeValues, setSubscriptionActiveTypeValues] = useState<{ [type: string]: number } | null>(
    null,
  );

  console.log('leaderBoard', leaderBoard);
  // const [subscriptionsActivePerDate, setSubscriptionActivePerDate] = useState<{ [type: string]: any[] } | null>(null);

  const getLeaderBoard = async (subscriptionIds: string[]) => {
    console.log('about to run leaderboard');
    const leaderBoard = await getUserLeaderBoardByIdArray(subscriptionIds);
    console.log('about to run leaderboard ', leaderBoard);
    setLeaderBoard(leaderBoard);
  };

  const getUsers = async (subscriptionIds: string[]) => {
    console.log('about to run leaderboard');
    const users = await getUsersByIdArray(subscriptionIds);
    console.log('about to run leaderboard ', users);
    setUsers(users);
  };

  useEffect(() => {
    getAllSubscriptions().then(async (subscriptionArray) => {
      subscriptionArray.sort((a, b) => {
        const date1 = a?.createdAt && a?.createdAt?.toDate ? a?.createdAt?.toDate() : new Date();
        const date2 = b?.createdAt && b?.createdAt?.toDate ? b?.createdAt?.toDate() : new Date();
        if (date1 && date2) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return date2 - date1;
        }
        return 1;
      });

      const subscriptionIds = subscriptionArray.map((subscription) => subscription.uid);

      getLeaderBoard(subscriptionIds);
      getUsers(subscriptionIds);
      // setSubscriptions(subscriptionArray);
      const expired: SubscriptionType[] = [];
      const active: SubscriptionType[] = [];
      const isIos: SubscriptionType[] = [];
      const isAndroid: SubscriptionType[] = [];
      const subscriptionTypes: { [type: string]: any[] } = {};
      const subscriptionTypeValues: { [type: string]: number } = {};
      const subscriptionExpiredTypeValues: { [type: string]: number } = {};
      const subscriptionActiveTypeValues: { [type: string]: number } = {};
      // console.log('subscriptionArray,  ', subscriptionArray);

      setSubscriptionsTotal(subscriptionArray);

      await subscriptionArray.map(async (subscript) => {
        if (subscript.isExpired) {
          expired.push(subscript);
          subscriptionExpiredTypeValues[subscript?.type] = (subscriptionExpiredTypeValues?.[subscript?.type] || 0) + 1;
        } else {
          active.push(subscript);
          subscriptionActiveTypeValues[subscript?.type] = (subscriptionActiveTypeValues?.[subscript?.type] || 0) + 1;
        }
        if (subscript.oS === 'ios') {
          isIos.push(subscript);
        } else {
          isAndroid.push(subscript);
        }

        (subscriptionTypes[subscript?.type] = subscriptionTypes[subscript?.type] || []).push(subscript);
        subscriptionTypeValues[subscript?.type] = (subscriptionTypeValues?.[subscript?.type] || 0) + 1;
      });
      setSubscriptionsExpired(expired);
      setSubscriptionsActive(active);
      setSubscriptionIos(isIos);
      setSubscriptionAndroid(isAndroid);
      // console.log('f', f);
      setSubscriptionTypes(subscriptionTypes);
      setSubscriptionTypeValues(subscriptionTypeValues);
      setSubscriptionExpiredTypeValues(subscriptionExpiredTypeValues);
      setSubscriptionActiveTypeValues(subscriptionActiveTypeValues);
    });
  }, []);

  const getSubscriptionType = (thisType: string) => {
    switch (thisType) {
      case 'equa_1499_1m':
      case 'equa_access':
        return 'Monthly';
      case 'equa_9999_1y':
        return 'Yearly';
      case 'equa_lifetime':
        return 'Lifetime';
      default:
        return 'UNKNOWN';
    }
  };

  // console.log('subscriptionsExpired,  ', subscriptionsExpired);
  // console.log('subscriptionsActive ', subscriptionsActive);
  // console.log('subscriptionsIos ', subscriptionsIos);
  // console.log('subscriptionsAndroid ', subscriptionsAndroid);
  console.log('subscriptionsTypes ', subscriptionsTypes);

  const total = subscriptionssTotal?.length || 0;
  const android = subscriptionsAndroid?.length || 0;

  const active = subscriptionsActive?.length || 0;
  const expired = subscriptionsExpired?.length || 0;

  // For android
  const androidExpiredAccess = subscriptionsAndroid?.filter(
    (sub) => sub.isExpired && sub.type === 'equa_access',
  )?.length;
  const androidActiveAccess = subscriptionsAndroid?.filter(
    (sub) => !sub.isExpired && sub.type === 'equa_access',
  )?.length;
  const androidExpiredMonthly = subscriptionsAndroid?.filter(
    (sub) => sub.isExpired && sub.type === 'equa_1499_1m',
  )?.length;
  const androidActiveMonthly = subscriptionsAndroid?.filter(
    (sub) => !sub.isExpired && sub.type === 'equa_1499_1m',
  )?.length;
  const androidActiveLifetime = subscriptionsAndroid?.filter(
    (sub) => !sub.isExpired && sub.type === 'equa_lifetime',
  )?.length;

  // For ios
  const ios = subscriptionsIos?.length || 0;
  const iosExpiredMonthly = subscriptionsIos?.filter((sub) => sub.isExpired && sub.type === 'equa_1499_1m')?.length;
  const iosActiveMonthly = subscriptionsIos?.filter((sub) => !sub.isExpired && sub.type === 'equa_1499_1m')?.length;
  const iosExpiredYearly = subscriptionsIos?.filter((sub) => sub.isExpired && sub.type === 'equa_9999_1y')?.length;
  const iosActiveYearly = subscriptionsIos?.filter((sub) => !sub.isExpired && sub.type === 'equa_9999_1y')?.length;
  const iosActiveLifetime = subscriptionsIos?.filter((sub) => !sub.isExpired && sub.type === 'equa_lifetime')?.length;
  // const nonYearlySubscriptions = ios - (iosActiveYearly || 0);

  const totalYearly = (iosActiveYearly || 0) + (iosExpiredYearly || 0);
  const totalMonthly =
    (iosActiveMonthly || 0) +
    (iosExpiredMonthly || 0) +
    (androidExpiredAccess || 0) +
    (iosExpiredMonthly || 0) +
    (androidExpiredMonthly || 0) +
    (androidActiveMonthly || 0);
  const totalLifetime = (androidActiveLifetime || 0) + (iosActiveLifetime || 0);

  const activeYearly = iosActiveYearly || 0;
  const activeMonthly = (iosActiveMonthly || 0) + (androidExpiredAccess || 0) + (androidActiveMonthly || 0);
  const activeLifetime = (androidActiveLifetime || 0) + (iosActiveLifetime || 0);

  const activeTotal = activeYearly + activeMonthly + activeLifetime;

  const expiredYearly = iosExpiredYearly || 0;
  const expiredMonthly = (iosExpiredMonthly || 0) + (androidExpiredAccess || 0) + (androidExpiredMonthly || 0);

  const expiredTotal = expiredYearly + expiredMonthly;

  // console.log('nonYearlySubscriptions ', nonYearlySubscriptions);

  const getLabel = (dbValue: string) => {
    switch (dbValue) {
      case 'equa_1499_1m':
        return 'IOS\nMonthly';
      case 'equa_9999_1y':
        return 'IOS\nYearly';
      case 'equa_lifetime':
        return 'Lifetime';
      case 'equa_access':
        return 'Android\nMonthly';
      default:
        return dbValue;
    }
  };
  return (
    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Total Subscriptions {subscriptionssTotal?.length}
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Subscription Expired {subscriptionsExpired?.length}
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Subscription Active Today {subscriptionsActive?.length}
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Subscription IOS {subscriptionsIos?.length}
              </th>

              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Subscription Android {subscriptionsAndroid?.length}
              </th>
            </tr>
          </thead>
        </div>
        <VictoryChart>
          <VictoryGroup offset={20} colorScale={'qualitative'}>
            <VictoryBar
              data={Object.keys(subscriptionsTypeValues || {}).map((f) => {
                return { x: getLabel(f), y: subscriptionsTypeValues?.[f] || 0 };
              })}
              labels={Object.keys(subscriptionsTypeValues || {}).map(() => {
                return 'Total';
              })}
              // horizontal={true}
            />
            <VictoryBar
              data={Object.keys(subscriptionsExpiredTypeValues || {}).map((f) => {
                return { x: getLabel(f), y: subscriptionsExpiredTypeValues?.[f] || 0 };
              })}
              labels={Object.keys(subscriptionsExpiredTypeValues || {}).map(() => {
                return 'Expired';
              })}
            />
            <VictoryBar
              data={Object.keys(subscriptionsActiveTypeValues || {}).map((f) => {
                return { x: getLabel(f), y: subscriptionsActiveTypeValues?.[f] || 0 };
              })}
              labels={Object.keys(subscriptionsActiveTypeValues || {}).map(() => {
                return 'Active';
              })}
            />
          </VictoryGroup>
        </VictoryChart>
        <div className='flex-row'>
          <div className='flex-col align-center bg-gray-50 justify-center p-10'>
            <div className='text-center text-lg font-semibold text-gray-900'>Android vs IOS </div>
            <div style={{ height: 600, width: 600 }}>
              <VictoryPie
                animate={{
                  duration: 2000,
                }}
                colorScale={['tomato', 'orange', 'gold', 'cyan', 'navy']}
                data={[
                  { label: `Android-${android}`, x: 'Android', y: (android / total) * 100 || 0 },
                  { label: `IOS-${ios}`, x: 'Ios', y: (ios / total) * 100 || 0 },
                ]}
              />
            </div>
          </div>

          <div className='flex-col align-center bg-gray-50 justify-center p-10'>
            <div className='text-center text-lg font-semibold text-gray-900'>Active vs Expired</div>
            <div style={{ height: 600, width: 600 }}>
              <VictoryPie
                animate={{
                  duration: 2000,
                }}
                colorScale={['tomato', 'orange', 'gold', 'cyan', 'navy']}
                data={[
                  { label: `Active-${active}`, y: (active / total) * 100 || 0 },
                  { label: `Expired-${expired}`, y: (expired / total) * 100 || 0 },
                ]}
              />
            </div>
          </div>

          <div className='flex-col align-center bg-gray-50 justify-center p-10'>
            <div className='text-center text-lg font-semibold text-gray-900'>Total Subscriptions</div>
            <div style={{ height: 600, width: 600 }}>
              <VictoryPie
                animate={{
                  duration: 2000,
                }}
                colorScale={['tomato', 'orange', 'gold', 'cyan', 'navy']}
                data={[
                  { label: `Y${totalYearly}`, y: (totalYearly / total) * 100 || 0 },
                  { label: `M-${totalMonthly}`, y: (totalMonthly / total) * 100 || 0 },
                  { label: `Lifetime-${totalLifetime}`, y: (totalLifetime / total) * 100 || 0 },
                ]}
              />
            </div>
          </div>
        </div>

        <div className='flex-row'>
          <div className='flex-col align-center bg-gray-50 justify-center p-10'>
            <div className='text-center text-lg font-semibold text-gray-900'>Active Subscriptions</div>
            <div style={{ height: 600, width: 600 }}>
              <VictoryPie
                animate={{
                  duration: 2000,
                }}
                colorScale={['tomato', 'orange', 'gold', 'cyan', 'navy']}
                data={[
                  { label: `Yearly${activeYearly}`, y: (activeYearly / activeTotal) * 100 || 0 },
                  { label: `Monthly-${activeMonthly}`, y: (activeMonthly / activeTotal) * 100 || 0 },
                  { label: `Lifetime-${activeLifetime}`, y: (activeLifetime / activeTotal) * 100 || 0 },
                ]}
              />
            </div>
          </div>

          <div className='flex-row align-center bg-gray-50 justify-center p-10'>
            <div className='text-center text-lg font-semibold text-gray-900'>Expired breakdown</div>
            <div style={{ height: 600, width: 600 }}>
              <VictoryPie
                animate={{
                  duration: 2000,
                }}
                colorScale={['tomato', 'orange', 'gold', 'cyan', 'navy']}
                data={[
                  { label: `Yearly${expiredYearly}`, y: (expiredYearly / expiredTotal) * 100 || 0 },
                  { label: `Monthly-${expiredMonthly}`, y: (expiredMonthly / expiredTotal) * 100 || 0 },
                ]}
              />
            </div>
          </div>
        </div>

        {/* <div className="flex flex-row"> */}
        <div className='text-center text-lg font-semibold text-gray-900 font-30'>Android</div>
        <div style={{ height: 600, width: 600, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <VictoryPie
            colorScale={['tomato', 'orange', 'gold', 'cyan', 'yellow']}
            style={{ labels: { fill: 'black', fontSize: 6, fontWeight: 'bold' } }}
            labelRadius={() => 100}
            data={[
              { label: `Expired Access-${androidExpiredAccess}`, y: ((androidExpiredAccess || 0) / android) * 100 },
              { label: `Active Access-${androidActiveAccess}`, y: ((androidActiveAccess || 0) / android) * 100 },
              { label: `Expired Monthly-${androidExpiredMonthly}`, y: ((androidExpiredMonthly || 0) / android) * 100 },
              { label: `Active Monthly-${androidActiveMonthly}`, y: ((androidActiveMonthly || 0) / android) * 100 },
              { label: `Active Lifetime-${androidActiveLifetime}`, y: ((androidActiveLifetime || 0) / android) * 100 },
            ]}
          />
        </div>
        <div className='text-center text-lg font-semibold text-gray-900'>IOS</div>
        <div style={{ height: 600, width: 600, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <VictoryPie
            colorScale={['tomato', 'orange', 'gold', 'cyan', 'navy']}
            style={{ labels: { fill: 'black', fontSize: 6, fontWeight: 'bold' } }}
            labelRadius={() => 30}
            data={[
              { label: `Expired Monthly-${iosExpiredMonthly}`, y: ((iosExpiredMonthly || 0) / ios) * 100 },
              { label: `Active Monthly-${iosActiveMonthly}`, y: ((iosActiveMonthly || 0) / ios) * 100 },
              {
                label: `Expired Yearly-${iosExpiredYearly}                            `,
                y: ((iosExpiredYearly || 0) / ios) * 100,
              },
              { label: `Active Yearly-${iosActiveYearly}`, y: ((iosActiveYearly || 0) / ios) * 100 },
              { label: `Active Lifetime-${iosActiveLifetime}`, y: ((iosActiveLifetime || 0) / ios) * 100 },
            ]}
          />
        </div>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  User Name
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  email
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Account Created At
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Last Login
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Last Notification
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Is Expired
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Highest Streak
                </th>

                <th scope='col' className='py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6'>
                  Type
                </th>
                {/* <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Is Legacy
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  Os
                </th> */}
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  Brand
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {subscriptionssTotal?.map((subscription) => {
                const leaderBoardStreak = leaderBoard?.length
                  ? leaderBoard.find((lb) => lb.userId === subscription.uid)
                  : null;
                const user = users?.length ? users.find((lb) => lb.email === subscription.email) : null;
                // console.log('asdfasdf subscription: ', subscription);
                return (
                  <tr key={subscription.uid} className='hover:bg-stone-200'>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>
                        {user ? `${user?.firstName} ${user?.lastName}` : 'UNKNOWN'}
                      </div>
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{subscription.email}</div>
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      {subscription?.createdAt ? format(subscription?.createdAt?.toDate(), 'hh:mm, MM/dd/yyyy') : '-'}
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      {subscription?.lastLogin ? format(subscription.lastLogin.toDate(), 'hh:mm, MM/dd/yyyy') : '-'}
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      {user?.lastNotification?.sentAt
                        ? format(user?.lastNotification?.sentAt.toDate(), 'hh:mm, MM/dd/yyyy')
                        : user?.lessonStreak?.updateDate?.toDate
                        ? format(user?.lessonStreak?.updateDate.toDate(), 'hh:mm, MM/dd/yyyy')
                        : '-'}
                    </td>

                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{subscription.isExpired ? 'True' : 'False'}</div>
                    </td>

                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{leaderBoardStreak?.highestStreak || 'UNKNOWN'}</div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>{getSubscriptionType(subscription?.type)}</div>
                    </td>
                    {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>{subscription?.legacyAccount ? 'True' : 'False'}</div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>{subscription?.oS}</div>
                    </td> */}
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>{subscription?.brand}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAnalysis;
