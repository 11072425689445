// *******************************************************
// UserScienceDataList
// -------------------------------------------------------
// This is a list of scienceDataItem data
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// *******************************************
// Component Imports
// -------------------------------------------
// import { ChevronDownIcon } from '@heroicons/react/solid';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
// import { getBlob, StorageReference } from 'firebase/storage';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
// import { toast } from 'react-toastify';
import { ScienceDataType } from 'src/modeltypes/scienceData';
import { UserType } from 'src/modeltypes/user';
import { UnitElementType, UnitType } from 'src/modeltypes/unit';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

export interface UserScienceDataListType {
  unit?: UnitType | null;
  element?: UnitElementType | null;
  unitIndex?: number | null;
  user?: UserType | null;
  sciencePopupData?: ScienceDataType[] | null;
  onPressClose?: () => void;
}

const UserScienceDataList = ({
  unit,
  unitIndex,
  element,
  user,
  sciencePopupData,
  onPressClose,
}: UserScienceDataListType) => {
  const navigate = useNavigate();

  console.log('element: ', element);
  const isLoading = false;
  // const downloadFile = async (path: StorageReference | null | undefined) => {
  //   if (path) {
  //     const blob = await getBlob(path);
  //     const a = document.createElement('a');
  //     document.body.appendChild(a);
  //     const url = window.URL.createObjectURL(blob);
  //     a.href = url;
  //     a.download = path.parent?.name || path.name;
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   }
  // };

  // const toastWrap = (downloadFunction: Promise<void>) => {
  //   toast.promise(downloadFunction, {
  //     pending: `Preparing file...`,
  //     error: "Can't do it now, try again.",
  //     success: `File is being downloaded!`,
  //   });
  // };

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative p-4 w-full  h-full md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>
              Science Data {unit?.title} (Unit {unitIndex || '-'}) {element?.id}
            </h3>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='font-medium text-gray-900'>{user?.email}</div>
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='text-gray-500'>
                {user?.firstName} {user?.lastName}
              </div>
            </td>

            <button
              type='button'
              className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
              onClick={() => (onPressClose ? onPressClose() : null)}
            >
              <svg
                aria-hidden='true'
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
              <span className='sr-only'>Close modal</span>
            </button>
          </div>
          <div className='px-4 sm:px-6 lg:px-8'>
            <div className='mt-8 flex flex-col'>
              <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  {isLoading ? (
                    <>
                      <Skeleton height={50} />
                      <Skeleton count={15} className='mt-3' />{' '}
                    </>
                  ) : (
                    <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                      <table className='min-w-full divide-y divide-gray-300'>
                        <thead className='bg-gray-50'>
                          <tr>
                            <th
                              scope='col'
                              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Created At</p>
                            </th>
                            {/* <th
                              scope='col'
                              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>
                                User Email
                                <span
                                  className={`ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                                >
                                  <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                                </span>
                              </p>
                            </th>
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>User Name</p>
                            </th> */}
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Lesson</p>
                            </th>
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Path Type</p>
                            </th>

                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Type</p>
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              SessionId
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              Lesson Id
                            </th>
                            <th scope='col' className='px-3 py-3.5 texst-left text-sm font-semibold text-gray-900'>
                              Resp Change
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              Disturbed
                            </th>
                            <th scope='col' colSpan={3} className='text-center'>
                              <p>Zone Mins</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {sciencePopupData?.map((scienceDataItem) => (
                            <tr key={scienceDataItem.id} className='hover:bg-stone-200'>
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>
                                      {format(
                                        scienceDataItem?.createdAt ? scienceDataItem.createdAt.toDate() : Date.now(),
                                        'dd MMMM  yy, HH:mm',
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {scienceDataItem.session}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{element?.pathType}</td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div
                                  className={`text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                  }`}
                                  // onClick={() => {
                                  //   if (scienceDataItem.audioFile) {
                                  //     toastWrap(downloadFile(scienceDataItem.audioRef));
                                  //   }
                                  // }}
                                >
                                  {typeof scienceDataItem?.dataType === 'string' ? scienceDataItem?.dataType : '-'}
                                </div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div className={`text-indigo-600`}>{scienceDataItem?.sessionId}</div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div
                                  className={`text-indigo-600 `}
                                  onClick={() => {
                                    navigate(`/rawData/${scienceDataItem.id}/${scienceDataItem.sessionId}`);
                                  }}
                                >
                                  {scienceDataItem?.lessonId}
                                </div>
                              </td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-500'>{}</div>
                                {typeof scienceDataItem?.audioUrl === 'string' ? scienceDataItem?.audioUrl : '-'}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-500'>
                                  {typeof scienceDataItem?.dataType === 'string' ? `${scienceDataItem.dataType}` : '-'}
                                </div>
                              </td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {JSON.stringify(scienceDataItem?.breakPoints)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(UserScienceDataList);
