// *******************************************
// Types
// -------------------------------------------
import { AudioNodeId } from '../modeltypes/id';
import { LessonType } from '../modeltypes/lesson';
import { getChaptersByIdArray } from '../collections/chapter';
import { ChapterType } from '../modeltypes/chapter';
import { AudioNodeType } from '../modeltypes/audioNode';
import { getAudioNodesByIdArray } from '../collections/audioNode';
import { dieIfNullOrUndef, fileNameAndExt } from './GeneralUtilities';
import { uploadFileNoThrow } from '../firebase/storage';

export type UploadedFile = { file?: File; url?: string | null };
export type UploadedFileList = UploadedFile[];

type UploadResult = {
  file: File | null;
  url: string | null;
  downloadUrl: string | null;
  matchingNodeId: AudioNodeId | null;
  message: string | null;
  uploadedSuccessfully: boolean;
  associatedSuccessfully: boolean;
  audioDuration: number | null;
};

function uploadedFileFailureResult(failFile: UploadedFile, message?: string | null): UploadResult {
  const resultValue = {
    file: failFile.file || null,
    url: failFile.url || null,
    downloadUrl: null,
    matchingNodeId: null,
    message:
      message && message.length
        ? message
        : `Failed to match file named '${failFile.file?.name || 'UNKNOWN_FILE'}' to any node.`,
    uploadedSuccessfully: false,
    associatedSuccessfully: false,
    audioDuration: null,
  };
  return resultValue;
}

export async function getAudioDuration(fileBlobUrl: string, timeout = 10000): Promise<number> {
  // NB: This funtion does not respond well to being "debugged". Specifically, the Audio
  // object seems to only work correctly if the browser running it is in the foreground.
  // My guess is that since Audio() is *technically* an HTML element, and Chrome likely pauses
  // HTML rendering when it's backgrounded, I suppose this shouldn't be that much of a
  // surprise, but I wasted a good two hours figuring this out, so... leaving some breadcrumbs.
  const rv = new Promise<number>((resolve, reject) => {
    let didTimeout = false;
    const aud = new Audio();
    try {
      aud.muted = true;
      aud.preload = 'metadata';
      const timeoutId = setTimeout(() => {
        console.debug(`getAudioDuration: timeout called for ${fileBlobUrl}`);
        didTimeout = true;
        reject(`getAudioDuration timed out after 10s`);
      }, timeout);

      const listener = function () {
        console.debug(`getAudioDuration: listener called. Duration: ${aud.duration} URL: ${fileBlobUrl}`);
        aud.removeEventListener('loadedmetadata', listener);
        clearTimeout(timeoutId);
        const dur = aud.duration;
        aud.src = '';
        if (!didTimeout) {
          resolve(dur);
        }
      };

      aud.addEventListener('loadedmetadata', listener);
      aud.src = fileBlobUrl;
      console.debug(
        `getAudioDuration: setting src to ${fileBlobUrl} currrentSrc: ${aud.currentSrc} error: ${aud.error}`,
      );
    } catch (e) {
      console.error(`getAudioDuration: top level try caught ${e}. Audio.error: ${aud.error}`);
      didTimeout = true;
      reject(e);
    }
  });
  return rv;
}

export async function handleAudioUpload(lesson: LessonType, fileList: UploadedFileList): Promise<UploadResult[]> {
  const results: UploadResult[] = new Array<UploadResult>();
  const chapters = lesson.chapters && (await getChaptersByIdArray(lesson.chapters, true));

  const nodeIds = (chapters || []).map((ch: ChapterType) => ch.audioNodes || []).flat();
  const nodes: AudioNodeType[] = await getAudioNodesByIdArray(nodeIds, true);

  const nodesByCode = new Map<string, AudioNodeType>(
    nodes
      .reverse()
      .filter((n) => n.code?.length)
      .map((n) => [dieIfNullOrUndef(n.code), n]),
  );

  // Dupes or missing codes.
  if (nodesByCode.size < nodes.length) {
    console.warn(`handleBulkUpload: Some nodes in this lesson do not have codes assigned or there are 
    duplicate codes. Continuing on anyway.`);
  }

  // Nothing to work with; everything fails.
  if (nodesByCode.size === 0) {
    console.warn(`We appear to have no nodes (or rather, nodes with codes) to work with.`);
    fileList.forEach((uf) => results.push(uploadedFileFailureResult(uf)));
    return results;
  }

  // Do the matching...
  const filesByName = new Map<string, UploadedFile>(
    fileList.filter((fle) => fle.file?.name).map((fle) => [dieIfNullOrUndef(fle.file?.name), fle]),
  );
  for (const [fileName, theFile] of filesByName.entries()) {
    const [fn, ext] = fileNameAndExt(fileName);

    // Bad filetype
    if (ext?.toLowerCase() !== '.m4a') {
      results.push(uploadedFileFailureResult(theFile));
      continue;
    }

    const matchingNode = !fn ? undefined : nodesByCode.get(fn);
    if (matchingNode) {
      const uf = dieIfNullOrUndef(filesByName.get(fileName));

      // Update the duration from the file.
      const duration = uf.url ? await getAudioDuration(uf.url) : null;
      // console.log(`duration ${duration}`);

      // Do the upload op here.
      const storageFileName = `${matchingNode.id}-${matchingNode.code}-nodeAudio.m4a`;
      const uploadResult = await uploadFileNoThrow(dieIfNullOrUndef(theFile.file), storageFileName, [
        'assets',
        matchingNode.id,
      ]);
      if (uploadResult.error) {
        results.push(
          uploadedFileFailureResult(
            theFile,
            `Upload of ${theFile.file?.name || 'UNKNOWN-FILE'} failed to write to storage.`,
          ),
        );
        continue;
      }
      const storageUrl = uploadResult.downloadUrl;
      const resultValue = {
        file: uf.file || null,
        url: uf.url || null,
        downloadUrl: storageUrl,
        matchingNodeId: matchingNode.id,
        message: `Matched file named '${fileName}' to node with code ${matchingNode.code} and ID: ${matchingNode.id}`,
        uploadedSuccessfully: true,
        associatedSuccessfully: true,
        audioDuration: duration || null,
      };

      results.push(resultValue);
    } else {
      results.push(uploadedFileFailureResult(theFile));
    }
  }

  return results;
}
