// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';

// *******************************************
// Component Imports
// -------------------------------------------
import MeetingList from '../../components/coach/CohortMeetingList';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/appContext';
import { useEffect } from 'react';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const CohortMeetingsPage = () => {
  const navigate = useNavigate();
  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([{ label: 'Cohort Sessions', path: '/cohortSessions' }]);
  }, [setBreadcrumbPaths]);

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Cohort Sessions</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            onClick={() => navigate('/cohortSessions/new')}
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Add Session
          </button>
        </div>
      </div>
      <MeetingList />
    </>
  );
};

export default CohortMeetingsPage;
