/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { AccountRole, AccountType, AccountTypeBase, AccountTypeBuilder, AccountTypeNew } from '../modeltypes/account';
import { timestampType } from './shared';
import { userIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';

export const accountRoleSchema = z.nativeEnum(AccountRole);

export const accountTypeSchema = z.object({
  id: userIdSchema,
  createdAt: timestampType().optional(),
  updatedAt: timestampType().optional(),
  uid: z.string(),
  email: z.string().optional(),
  onboardFinished: z.boolean().default(false),
  trial: z.boolean().optional(),
  expoToken: z.string().optional().nullable().default(null),
  configuring: z.boolean().optional(),
  verificationCode: z.string().optional(),
  companyId: z.string().optional().nullable().default(null),
  paid: z.boolean().default(false),
  cohort: z.string().optional().nullable().default(null),
  allowedInvites: z.number().optional(),
  role: accountRoleSchema.default(AccountRole.User),
  legacyAccount: z.boolean().default(false),
  OS: z.object({
    type: z.string(),
    version: z.string(),
  }),
  appVersion: z.string(),
  subscription: z
    .object({
      expirationDate: timestampType(),
      type: z.string(),
    })
    .nullable(),
  lastLogin: timestampType().nullable(),
  isTestUser: z.boolean().optional(),
  unsubscribedMails: z
    .object({
      date: timestampType(),
      reason: z.string().nullable(),
    })
    .nullable(),
});

export const accountTypeBaseSchema = accountTypeSchema.partial();

export const accountTypeBuilderSchema = accountTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: userIdSchema.default(() => IdFactory.User()) }));

export const accountTypeNewSchema = accountTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: userIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: AccountType = accountTypeSchema.parse(undefined);
  const x: AccountTypeBase = accountTypeBaseSchema.parse(undefined);
  const y: AccountTypeBuilder = accountTypeBuilderSchema.parse(undefined);
  const z: AccountTypeNew = accountTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
