import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  queryByIdsFor,
  setNewFor,
  watchIdSetFor,
} from './shared';
import { LESSON_HOMEWORK_TABLE_NAME } from './tableName';
import { LessonHomeworkId } from '../modeltypes/id';
import {
  LessonHomeworkType,
  LessonHomeworkTypeBase,
  LessonHomeworkTypeBuilder,
  LessonHomeworkTypeNew,
} from '../modeltypes/lessonHomework';

// noinspection JSUnusedGlobalSymbols
export const getNewLessonHomeworkId = getNewIdFor<LessonHomeworkId>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewLessonHomework = addNewFor<LessonHomeworkId, LessonHomeworkTypeNew>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewLessonHomework = createNewFor<LessonHomeworkId, LessonHomeworkTypeBuilder>(
  LESSON_HOMEWORK_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const setNewLessonHomework = setNewFor<LessonHomeworkId, LessonHomeworkTypeNew>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonHomeworkRefById = getRefByIdFor<LessonHomeworkId>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonHomeworkById = getByIdFor<LessonHomeworkId, LessonHomeworkType>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonHomeworksByIdArray = getByIdArrayFor<LessonHomeworkId, LessonHomeworkType>(
  LESSON_HOMEWORK_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllLessonHomeworks = getAllFor<LessonHomeworkType>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonHomeworksByQuery = getByQueryFor<LessonHomeworkType>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLessonHomeworksQuery = getAllQueryFor(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonHomeworkQueryForIds = queryByIdsFor<LessonHomeworkId>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteLessonHomework = deleteDocumentFor<LessonHomeworkId>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchLessonHomeworkIdSet = watchIdSetFor<LessonHomeworkId>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonHomeworkType = getValidateTypeFor<LessonHomeworkType>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonHomeworkTypeBase =
  getValidateTypeBaseFor<LessonHomeworkTypeBase>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonHomeworkTypeBuilder =
  getValidateTypeBuilderFor<LessonHomeworkTypeBuilder>(LESSON_HOMEWORK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonHomeworkTypeNew = getValidateTypeNewFor<LessonHomeworkTypeNew>(LESSON_HOMEWORK_TABLE_NAME);
