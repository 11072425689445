import { COMPANY_TABLE_NAME } from './tableName';
import { CompanyId } from '../modeltypes/id';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { CompanyType, CompanyTypeBase, CompanyTypeBuilder, CompanyTypeNew } from '../modeltypes/company';

// noinspection JSUnusedGlobalSymbols
export const getNewCompanyId = getNewIdFor<CompanyId>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewCompany = addNewFor<CompanyId, CompanyTypeNew>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewCompany = createNewFor<CompanyId, CompanyTypeBuilder>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCompanyRefById = getRefByIdFor<CompanyId>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCompanyById = getByIdFor<CompanyId, CompanyType>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCompaniesByIdArray = getByIdArrayFor<CompanyId, CompanyType>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCompanies = getAllFor<CompanyType>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCompaniesQuery = getAllQueryFor(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCompaniesByQuery = getByQueryFor<CompanyType>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateCompany = updateDocumentFor<CompanyId, CompanyTypeBase>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteCompany = deleteDocumentFor<CompanyId>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchCompanyIdSet = watchIdSetFor<CompanyId>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCompanyType = getValidateTypeFor<CompanyType>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCompanyTypeBase = getValidateTypeBaseFor<CompanyTypeBase>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCompanyTypeBuilder = getValidateTypeBuilderFor<CompanyTypeBuilder>(COMPANY_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCompanyTypeNew = getValidateTypeNewFor<CompanyTypeNew>(COMPANY_TABLE_NAME);
