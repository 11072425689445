// *******************************************************
// NotificationsList
// -------------------------------------------------------
// This is a NotificationsList
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import SearchBar from '../SearchBar';
import Skeleton from 'react-loading-skeleton';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/appContext';
import { useNavigate } from 'react-router-dom';
import { getAllNotification } from '../../collections/notifications';
import { NotificationType } from 'src/modeltypes/notification';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// type TableData = {
//   title?: string;
//   id?: string;
//   type?: string;
//   message?: string;
//   dayCount?: number | undefined;
// };

const NotificationsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<NotificationType[] | []>([]);

  const { searchTerm } = useAppContext();
  const navigate = useNavigate();

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchIncentive = item.type && item.type.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId || matchIncentive;
    });
  }, [searchTerm, tableData]);

  useEffect(() => {
    setIsLoading(true);
    getAllNotification().then((fNotifications) => {
      console.log(' fNotifications : ', fNotifications);
      const sortedNotifications = fNotifications.sort((a, b) => {
        const thisB = b.dayCount || 0;
        const thisA = a.dayCount || 0;

        return thisA - thisB;
      });
      setTableData(sortedNotifications);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Day
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Type
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Message
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((notification) => (
                      <tr key={notification.id}>
                        <td className='whitespace-nowrap py-4 pl-4 text-sm sm:pl-6 w-[15%]'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{notification.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{notification.title}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{notification.dayCount}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{notification.type}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{notification.message}</div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <div
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => navigate('/notifications/' + notification.id)}
                          >
                            Edit
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsList;
