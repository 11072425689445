// *******************************************************
// HomeNonAuth Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
const HomeNonAuth = () => {
  return (
    <>
      <div className='basis-4/12 mx-auto mb-5 sm:mb-0 self-start '>
        <h1 className='font-bold text-3xl mb-2'>Welcome to Equa</h1>
        <h4 className='font-light text-xl text-gray-700 mb-4'>Please contact us if you have any issues</h4>
        <div className='flex flex-col px-8 py-4 border-[#BEE2FF] border rounded-lg'>
          But more importantly you should not be here :){' '}
        </div>
      </div>
    </>
  );
};

export default HomeNonAuth;
