import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseConfig';

export const runOneSync = httpsCallable<{ runId?: string }, void>(functions, 'runOneSync');
export const changeTrackInUserJourney = httpsCallable<
  { userIds: string[]; newTrackId: string; oldTrackId: string },
  void
>(functions, 'changeTrackInUserJourney');

export const collectScience = httpsCallable<void, void>(functions, 'collectScience');
