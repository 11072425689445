import { MoneyType } from '../enums/moneyType';
import { INCENTIVE_TABLE_NAME } from './tableName';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { IncentiveType, IncentiveTypeBase, IncentiveTypeBuilder, IncentiveTypeNew } from '../modeltypes/incentive';
import { UserId } from '../modeltypes/id';

// noinspection JSUnusedGlobalSymbols
export const getNewUserId = getNewIdFor<UserId>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewIncentive = addNewFor<UserId, IncentiveTypeNew>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewIncentive = createNewFor<UserId, IncentiveTypeBuilder>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getIncentiveRefById = getRefByIdFor<UserId>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getIncentiveById = getByIdFor<UserId, IncentiveType>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getIncentivesByIdArray = getByIdArrayFor<UserId, IncentiveType>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllIncentives = getAllFor<IncentiveType>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllIncentivesQuery = getAllQueryFor(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getIncentivesByQuery = getByQueryFor<IncentiveType>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateIncentive = updateDocumentFor<UserId, IncentiveTypeBase>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteIncentive = deleteDocumentFor<UserId>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchUserIdSet = watchIdSetFor<UserId>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveType = getValidateTypeFor<IncentiveType>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveTypeBase = getValidateTypeBaseFor<IncentiveTypeBase>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveTypeBuilder = getValidateTypeBuilderFor<IncentiveTypeBuilder>(INCENTIVE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateIncentiveTypeNew = getValidateTypeNewFor<IncentiveTypeNew>(INCENTIVE_TABLE_NAME);

// This probably doesn't belong here, but...
export function formatMoney(amount: number | null | undefined, type: MoneyType | string | null | undefined): string {
  if (!amount || amount === 0 || type === MoneyType.NONE || type === '' || type === null || type === undefined) {
    return '-';
  }
  if (type === MoneyType.DOLLAR) {
    return `$${amount}`;
  } else {
    return `${amount} credits`;
  }
}
