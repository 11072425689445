import { deleteLesson, getAllLessons, getLessonsByIdArray } from './lesson';
import { deleteChapter, getAllChapters } from './chapter';
import { deleteAudioNode } from './audioNode';
import {
  deepEqual,
  DefaultMap,
  dieIfNullOrUndef,
  excWrap,
  sortedEntityArrayFromIdArray,
} from '../utility/GeneralUtilities';
import { AudioNodeId, ChapterId, LessonId, ProgramId } from '../modeltypes/id';
import { ChapterType } from '../modeltypes/chapter';
import { LessonType } from '../modeltypes/lesson';
import { arrayRemove, arrayUnion, serverTimestamp, updateDoc } from '../models/dalaccess';
import { getProgramById, getProgramRefById } from './program';

/*
 * I broke this out into another file to avoid circular imports.
 */

export async function deleteLessonRecursive(lessonId: LessonId) {
  // Figure out which chapters and audio nodes would be orphaned if this went away, and delete them.
  // Note: This does NOT remove the lessonId from any programs. This is intentional (to support the update case).
  // FWIW: This is the exact kind of case that illustrates how we're using Firebase wrong.

  const allLessons = await getAllLessons();
  const theLesson = dieIfNullOrUndef(allLessons.find((l) => l.id === lessonId));
  const lessonsById = new Map<LessonId, LessonType>();
  allLessons.forEach((l) => lessonsById.set(dieIfNullOrUndef(l.id), l));
  const lessonChapterIds = new Set<ChapterId>(theLesson.chapters || []);

  const allChapters = await getAllChapters();
  const chaptersById = new Map<ChapterId, ChapterType>();
  allChapters.forEach((c) => chaptersById.set(dieIfNullOrUndef(c.id), c));

  const lessonAudioNodeIds = new Set<AudioNodeId>();
  lessonChapterIds.forEach((cid) => {
    const chap = dieIfNullOrUndef(chaptersById.get(cid));
    (chap.audioNodes || []).forEach(lessonAudioNodeIds.add, lessonAudioNodeIds);
  });

  // calc ref counts for all the audio nodes and chapters, so if they're used elsewhere, we don't delete them.
  const audioNodeRefCounts: Map<AudioNodeId, number> = new DefaultMap(() => 0);
  allChapters.forEach((c) => {
    c.audioNodes?.forEach((anid) => {
      if (lessonAudioNodeIds.has(anid)) {
        audioNodeRefCounts.set(anid, dieIfNullOrUndef(audioNodeRefCounts.get(anid)) + 1);
      }
    });
  });

  const chapterRefCounts: Map<ChapterId, number> = new DefaultMap(() => 0);
  allLessons.forEach((l) => {
    l.chapters?.forEach((chid) => {
      if (lessonChapterIds.has(chid)) {
        chapterRefCounts.set(chid, dieIfNullOrUndef(chapterRefCounts.get(chid)) + 1);
      }
    });
  });

  // Figure out who's up to get the axe
  const audioNodeIdsToDelete = Array.from(lessonAudioNodeIds).filter(
    (anid) => dieIfNullOrUndef(audioNodeRefCounts.get(anid)) <= 1,
  );
  const chapterIdsToDelete = Array.from(lessonChapterIds).filter(
    (chid) => dieIfNullOrUndef(chapterRefCounts.get(chid)) <= 1,
  );

  // Do the deed.
  const promises = [];
  for (const anid of audioNodeIdsToDelete) {
    promises.push(deleteAudioNode(anid));
  }

  for (const chid of chapterIdsToDelete) {
    promises.push(deleteChapter(chid));
  }

  promises.push(deleteLesson(dieIfNullOrUndef(theLesson.id)));

  return Promise.all(promises);
}

// Sometimes we end up with stale LessonIds in our lessons list. This cleans those out.
export const cleanLessonArrayForProgram = excWrap(async (programId: ProgramId) => {
  const prog = await getProgramById(programId);
  if (!prog) {
    throw new Error(`Program ID: ${programId} was not found in the database.`);
  }

  const qLessons = await getLessonsByIdArray(prog.lessons || []);
  if (qLessons.length === (prog.lessons || []).length) {
    return;
  }

  const lessons = sortedEntityArrayFromIdArray(prog.lessons || [], qLessons);
  const lessonExistance = new Set(lessons.map((l) => l.id));
  const cleanedLessonIDs: LessonId[] = lessons.filter((l) => lessonExistance.has(l.id)).map((l) => l.id);
  if (!deepEqual(prog.lessons || [], cleanedLessonIDs, { strict: true })) {
    await updateDoc(dieIfNullOrUndef(getProgramRefById(programId)), {
      lessons: cleanedLessonIDs,
      updatedAt: serverTimestamp(),
    });
  }
});

export const appendLessonToProgram = excWrap(async (programId: ProgramId, lessonId: LessonId) => {
  const docRef = dieIfNullOrUndef(getProgramRefById(programId));
  await updateDoc(docRef, {
    lessons: arrayUnion(lessonId),
    updatedAt: serverTimestamp(),
  });
  await cleanLessonArrayForProgram(programId);
});

export const removeLessonFromProgram = excWrap(async (programId: ProgramId, lessonId: LessonId) => {
  const docRef = dieIfNullOrUndef(getProgramRefById(programId));
  await updateDoc(docRef, {
    lessons: arrayRemove(lessonId),
    updatedAt: serverTimestamp(),
  });
  await cleanLessonArrayForProgram(programId);
});

export const reorderLessonInProgram = excWrap(async (programId: ProgramId, lessonId: LessonId, move: 1 | -1) => {
  const docRef = dieIfNullOrUndef(getProgramRefById(programId));
  const prog = await getProgramById(programId);
  if (!prog) {
    throw new Error('Program deleted?');
  }
  const lessArray = [...(prog.lessons || [])];
  const currentIndex = lessArray.indexOf(lessonId);
  if (currentIndex === -1 || lessArray.length < 1) {
    throw new Error('Lesson not in program?');
  }

  if (lessArray.length === 1) {
    return; // Nothing to do.
  }

  if (move < 0) {
    if (currentIndex === 0) {
      return; // Nothing to do
    }
    const newIndex = currentIndex - 1;
    lessArray.splice(newIndex, 0, lessArray.splice(currentIndex, 1)[0]);
  } else if (move > 0) {
    if (currentIndex === lessArray.length - 1) {
      return; // Nothing to do
    }
    const newIndex = currentIndex + 1;
    lessArray.splice(newIndex, 0, lessArray.splice(currentIndex, 1)[0]);
  } else {
    throw new Error('move is 0? What are you trying to do?');
  }

  await updateDoc(docRef, {
    lessons: lessArray,
    updatedAt: serverTimestamp(),
  });
  await cleanLessonArrayForProgram(programId);
});
