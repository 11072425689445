/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { addNewQuiz, getQuizById, updateQuiz, } from '../../collections/paths';
import { addNewQuiz, getQuizById, updateQuiz } from '../../collections/quizItems';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { QuizType } from '../../modeltypes/quizItems';
// import { Quiz,
//   QuizBase
// } from '../../modeltypes/paths';
import Select from 'react-select';

import { toast } from 'react-toastify';
import { getAllTags } from 'src/collections/tags';
import { ReactSelectOptions } from 'src/types/types';
import { TagOptions, TagType } from 'src/modeltypes/tags';
import { TagId } from 'src/modeltypes/id';

// type UserSelect = {
//
// }

export enum QuizTypeOptionsTs {
  DRAG = 'DRAG',
  CLICK = 'CLICK',
}

export enum DragIconsTs {
  SEE = 'SEE',
  HEAR = 'HEAR',
  FEEL = 'FEEL',
  YES = 'YES',
  NO = 'NO',
}

const dragIconOptions: ReactSelectOptions[] = [
  {
    value: 'NONE',
    label: 'No Icon',
  },
  {
    value: DragIconsTs.HEAR,
    label: 'HEAR',
  },
  {
    value: DragIconsTs.FEEL,
    label: 'FEEL',
  },
  {
    value: DragIconsTs.YES,
    label: 'YES',
  },
  {
    value: DragIconsTs.NO,
    label: 'NO',
  },
];

// Constants
export type QuizAnswerType = {
  id: string;
  text?: string;
  icon?: DragIconsTs | null;
  explaination?: string;
};

const quizTypeOptions: ReactSelectOptions[] = [
  {
    value: QuizTypeOptionsTs.DRAG,
    label: 'DRAG',
  },
  {
    value: QuizTypeOptionsTs.CLICK,
    label: 'CLICK',
  },
];

const QuizForm = () => {
  const [path, setThisQuiz] = useState<QuizType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<ReactSelectOptions[]>([]);
  const [selectedTags, setSelectedTags] = useState<ReactSelectOptions[]>([]);

  const getTags = async () => {
    const dbTags: TagType[] = await getAllTags();
    const selectorT: TagOptions[] = dbTags.map((dbTag: TagType) => {
      return {
        label: `${dbTag.name}`,
        value: dbTag?.id,
      };
    });
    setTags(selectorT);
  };

  useEffect(() => {
    getTags();
  }, []);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'QuizItems',
        path: '/quizItems',
      },
      {
        label: id === 'new' ? 'New Quiz' : 'Edit Quiz',
        path: `/quizItems/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    //resetForm
  } = useFormik<{
    title: string;
    pathType: string;
    description: string;
    locked: boolean;
    question: string;
    answers: QuizAnswerType[];
    correctAnswersIds: string[];
    popupMessage?: string | null;
    tags?: TagId[];
    mlAttribute?: TagId;
    explanation: string;
    quizType: QuizTypeOptionsTs | null;
    cardDescription?: string | null;
    cardTitle?: string | null;
    // id?: string;
  }>({
    initialValues: {
      title: '',
      pathType: 'quiz',
      description: '',
      locked: true,
      question: '',
      answers: [],
      correctAnswersIds: [],
      popupMessage: null,
      quizType: null,
      explanation: '',
      // id: '',
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required'),
      description: yup.string(),
      explanation: yup.string(),
      pathType: yup.string().required('Path Type is required'),
    }),
    validate: (values) => {
      const errors: any = {};
      if (!values.question) {
        errors.question = 'Question is required';
      }
      if (!values.answers) {
        errors.answers = 'Answers are required';

        if (!values.correctAnswersIds) {
          errors.correctAnswersIds = 'Correct answers are required';
        } else if (values.correctAnswersIds.length < 1) {
          errors.correctAnswersIds = 'Need at least 1 correct answer';
        }
        // if (!values.answers || values.answers.length < 2) {
        //   errors.answers = 'At least 2 answers are required';
        // } else {
        //   for (const answer of values.answers) {
        //     if (!answer.text) {
        //       errors.answers = "Answers can't be empty!";
        //     }
        //   }
        // }
      }
      return errors;
    },
    onSubmit: async (newQuiz) => {
      console.log('newQuiz: ', newQuiz);

      if (id !== undefined && id !== 'new') {
        toast
          .promise(updateQuiz(id, newQuiz), {
            pending: `Updating ${newQuiz.title} Quiz...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newQuiz.title} Quiz!`,
          })
          .then(async () => {
            navigate('/quizItems');
          });
      } else if (id === 'new') {
        toast
          .promise(addNewQuiz(newQuiz), {
            pending: `Adding ${newQuiz.title} Quiz...`,
            error: "Can't do it now, try again.",
            success: `Added ${newQuiz.title} Quiz!`,
          })
          .then(async () => {
            navigate('/quizItems');
          });
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currQuiz = await getQuizById(id || '');
        if (currQuiz === null) {
          navigate('/quizItems');
          return;
        }

        await setValues({
          title: currQuiz.title || '',
          description: currQuiz.description || '',
          explanation: currQuiz.explanation || '',
          question: currQuiz.question || '',
          pathType: 'quiz',
          locked: currQuiz.locked || false,
          correctAnswersIds: currQuiz.correctAnswersIds || [],
          answers: currQuiz.answers || [],
          tags: currQuiz.tags || [],
          quizType: currQuiz.quizType || null,
          cardTitle: currQuiz.cardTitle || null,
          cardDescription: currQuiz.cardDescription || null,
        });

        const dbTags = await getAllTags();
        const selectorT = dbTags.map((dbTag: TagType) => ({
          label: `${dbTag.name}`,
          value: dbTag?.id,
        }));
        setTags(selectorT);

        if (currQuiz?.tags?.length) {
          setSelectedTags(selectorT.filter((t) => currQuiz?.tags?.includes(t?.value)));
        }
        setThisQuiz(currQuiz);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const disabledForm = false;

  const deleteCorrectAnswer = (answerId: string) => {
    if (values.correctAnswersIds) {
      const temp = [...values.correctAnswersIds];
      const index = temp.findIndex((aId) => aId === answerId);
      temp.splice(index, 1);
      setFieldValue('correctAnswersIds', temp);
    }
  };

  const addIconAnswer = (answerId: string, icon: string) => {
    // console.log('addIconAnswer answerId: ', answerId);
    // console.log('addIconAnswer values.answers): ', values.answers);
    // console.log('addIconAnswer icon: ', icon);
    console.log('values.answers.length', values.answers.length);
    console.log('values.answers', values.answers);

    if (values.answers.length) {
      console.log('INSIDE values.answers', values.answers.length);

      const temp = [...values.answers];
      const newThis = temp.map((aId) => {
        console.log('aId: ', aId);
        const thisIcon = icon as DragIconsTs;
        if (aId.id === answerId) return { ...aId, icon: thisIcon };
        return aId;
      });
      console.log('addIconAnswer newThis: ', newThis);

      setValues({
        ...values,
        answers: newThis || [],
      });
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id {id}
                </label>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id saved {path?.id}
                </label>
                <div className='mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                  <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Quiz Type
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      isMulti={false}
                      className={`max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                        errors.quizType ? 'border-red-300' : 'border-gray-300'
                      } rounded-md`}
                      options={quizTypeOptions}
                      value={quizTypeOptions.find(({ value }) => value === values.quizType)}
                      onChange={(selectedQuizType) => {
                        setValues({
                          ...values,
                          quizType: selectedQuizType?.value as QuizTypeOptionsTs,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='title'
                        id='title'
                        autoComplete='title'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.title ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.title}
                        onChange={handleChange}
                        placeholder={'Quiz title'}
                      />
                    </div>
                  </div>
                </div>

                {values.quizType === 'DRAG' && (
                  <>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        Card Title
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <div className='max-w-lg flex rounded-md shadow-sm'>
                          <input
                            disabled={disabledForm}
                            type='text'
                            name='cardTitle'
                            id='cardTitle'
                            autoComplete='title'
                            className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                              errors.title ? 'border-red-300' : 'border-gray-300'
                            }`}
                            defaultValue={values.cardTitle || ''}
                            onChange={handleChange}
                            placeholder={'Drag Quiz card title'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        Card Description
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2'>
                        <div className='max-w-lg flex rounded-md shadow-sm'>
                          <input
                            disabled={disabledForm}
                            type='text'
                            name='cardDescription'
                            id='cardDescription'
                            autoComplete='title'
                            className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                              errors.title ? 'border-red-300' : 'border-gray-300'
                            }`}
                            defaultValue={values.cardDescription || ''}
                            onChange={handleChange}
                            placeholder={'Drag Quiz card description'}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Path Type: {values.pathType}
                  </label>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'Quiz Description.  The user will see this'}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='explanation' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Explanation
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='explanation'
                      name='explanation'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.explanation}
                      onChange={handleChange}
                      placeholder={
                        'Explanation (for correction screen if you get an answer wrong).  The user will see this'
                      }
                    />
                  </div>
                </div>

                <div className='mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                  <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Quiz Type
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      className={`max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                        errors.quizType ? 'border-red-300' : 'border-gray-300'
                      } rounded-md`}
                      options={quizTypeOptions}
                      value={quizTypeOptions.find(({ value }) => value === values.quizType)}
                      onChange={(selectedQuizType) => {
                        setValues({
                          ...values,
                          quizType: selectedQuizType?.value as QuizTypeOptionsTs,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Tags:
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={tags || []}
                      isMulti={true}
                      value={selectedTags}
                      onChange={(vals) => {
                        setSelectedTags(vals as ReactSelectOptions[]);
                      }}
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                    />
                  </div>
                </div>

                <div className='mt-2 flex flex-col'>
                  <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                      <div>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                          <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:px-2'>
                            Question
                          </label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            <div className='max-w-lg flex rounded-md shadow-sm'>
                              <input
                                // disabled={disabledForm}
                                type='text'
                                name='question'
                                id='question'
                                autoComplete='question'
                                className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                                value={values?.question || ''}
                                onChange={handleChange}
                                placeholder={'Enter Question'}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
                          <label htmlFor='singleTapTag' className='text-sm font-medium text-gray-700'>
                            Machine Learning Attribute
                          </label>
                          <div className='flex mt-1 sm:mt-0'>
                            <Select
                              options={tags}
                              value={tags.find((t) => t.value === values.mlAttribute)}
                              onChange={(vals) => {
                                console.log('mlAttribute vals: ', vals);
                                setValues({
                                  ...values,
                                  mlAttribute: vals?.value,
                                });

                                // setSelectedTags(vals as ReactSelectOptions[]);
                              }}
                              className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                            />
                          </div>
                        </div>

                        <div
                          className={`flex flex-col ${errors.correctAnswersIds || errors.answers ? 'bg-red-50' : ''}`}
                        >
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                            <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                              {values.quizType === 'DRAG' ? 'Buckets' : 'Options'}
                            </label>
                          </div>
                          <div>
                            {values.answers?.map((answer, index) => (
                              <div
                                key={answer.id}
                                className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'
                              >
                                <label
                                  htmlFor='name'
                                  className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                                >
                                  {values.quizType === 'DRAG' ? 'Bucket' : 'Option'} {index + 1}
                                </label>
                                <div className='max-w-lg flex rounded-md shadow-sm flex flex-col gap-y-1'>
                                  <input
                                    // disabled={disabledForm}
                                    type='text'
                                    name={`answers[${index}].text`}
                                    id={`answers[${index}].text`}
                                    autoComplete={`answers`}
                                    className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                                    value={values?.answers?.[index].text || ''}
                                    onChange={handleChange}
                                    placeholder={`Enter ${values.quizType === 'DRAG' ? 'Bucket Name' : 'Option Name'}
                                    `}
                                  />
                                  <input
                                    // disabled={disabledForm}
                                    type='text'
                                    name={`answers[${index}].explaination`}
                                    id={`answers[${index}].explaination`}
                                    autoComplete={`answers`}
                                    className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                                    value={values?.answers?.[index].explaination || ''}
                                    onChange={handleChange}
                                    placeholder={`Enter The Answer Explaination if you get it wrong'}
                                    `}
                                  />

                                  {values.quizType === 'DRAG' && (
                                    <>
                                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                                        <div>
                                          <label
                                            htmlFor='name'
                                            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                                          >
                                            Icon
                                          </label>
                                        </div>
                                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                                          <Select
                                            options={dragIconOptions || []}
                                            isMulti={true}
                                            value={
                                              dragIconOptions.find((x) => x.value === values.answers?.[index]?.icon) ||
                                              null
                                            }
                                            onChange={(val) => {
                                              addIconAnswer(answer?.id, val?.[0]?.value);
                                            }}
                                            className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className='flex items-center sm:mt-px sm:pt-2'>
                                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 mr-5 '>
                                      {`Correct ${values.quizType === 'DRAG' ? 'Bucket' : 'Answer'}`}
                                    </label>
                                    <input
                                      name='correctAnswerId'
                                      id='correctAnswerId'
                                      type={'checkbox'}
                                      onChange={() => {
                                        if (values.correctAnswersIds?.includes(answer.id)) {
                                          deleteCorrectAnswer(answer.id);
                                        } else {
                                          const temp = [...(values.correctAnswersIds || [])];
                                          temp.push(answer.id);
                                          setFieldValue('correctAnswersIds', temp);
                                        }
                                      }}
                                      checked={values.correctAnswersIds?.includes(answer.id)}
                                    />
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const temp = values.answers ? [...values.answers] : [];
                                    const index = temp.findIndex((i) => i.id === answer.id);
                                    if (index > -1) {
                                      temp.splice(index, 1);
                                      setValues({
                                        ...values,
                                        answers: temp,
                                      });
                                    }
                                    if (values.correctAnswersIds?.includes(answer.id)) {
                                      deleteCorrectAnswer(answer.id);
                                    }
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            ))}
                            <button
                              className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              onClick={(e) => {
                                e.preventDefault();
                                const temp = values.answers ? [...values.answers] : [];
                                temp.push({
                                  id: new Date().getTime().toString(),
                                  text: '',
                                  icon: null,
                                });
                                setValues({
                                  ...values,
                                  answers: temp,
                                });
                              }}
                            >
                              {`+ Add ${values.quizType === 'DRAG' ? 'Bucket' : 'Option'}`}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/pathtypes')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {path ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default QuizForm;
