/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { sessionIdSchema } from './id';
import { timestampType } from './shared';
import { IdFactory } from '../models/dalaccess';
// import { SessionType, SessionTypeBase, SessionTypeBuilder, SessionTypeNew } from '../modeltypes/session';

// Note: This approach wasn't working, but I think I'm close, so I'm going to leave it here.
// export const constructor = z.function();
// const dataConverterSchema = z.object({
//   fromFirestore: z.function(),
//   toFirestore: z.function(),
// });
//
// const docRefDocDataSchema =
//   z.object({
//     constructor,
//     // converter: z.union([dataConverterSchema, z.null()]),
//     firestore: z.object({ constructor }),
//     id: z.string(),
//     parent: z.object({ constructor }),
//     path: z.string(),
//     type: z.literal('document'),
//     withConverter: z.function(),
//   });

const sessionAnswerSchema = z.object({
  answer: z.number(),
  decisionTime: timestampType(),
  showed: timestampType(),
});

export const sessionTypeSchema = z
  .object({
    id: sessionIdSchema,
    createdAt: timestampType().optional(),
    updatedAt: timestampType().optional(),
    // IPMcC: I can't figure out how to make Zod handle DocRefs, so I'm giving up for now.
    // Asked SO: https://stackoverflow.com/questions/74346759/
    // afterSessionSurvey: docRefDocDataSchema.required().default(() => doc(AFTER_SESSION_SURVEY_TABLE_NAME, "a")),
    // dailyDiary: z.unknown().default(() => doc(DAILY_DIARIES_TABLE_NAME, "b")),
    // date: timestampType(),
    // homeworkStatus: z.union([z.literal('done'), z.literal('not done')]).optional(),
    // lessonFinished: z.boolean(),
    // nameOfSession: z.string(),
    // pauseTime: z.number(),
    // platform: z.string(),
    // sessionEnd: timestampType().optional(),
    // sessionStart: timestampType(),
    // trial: z.union([z.literal(false), z.string()]),
    // uid: z.string(),
    // answers: z.record(sessionAnswerSchema).optional(),
  })
  .passthrough(); // We have to make this a passthrough because I don't know how to deal with DocRefs.

export const sessionTypeBaseSchema = sessionTypeSchema.partial().augment({
  answers: z.record(sessionAnswerSchema.partial()).optional(),
});

export const sessionTypeBuilderSchema = sessionTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: sessionIdSchema.default(() => IdFactory.Sessions()) }));

export const sessionTypeNewSchema = sessionTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: sessionIdSchema.optional() }));

// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// // noinspection JSUnusedLocalSymbols
// function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
//   // This should NEVER be called. It's just here so that we get an error if the inferred
//   // types of the schemas parse methods get out of sync with the types they're parsing to.

//   // Regarding 'afterSessionSurvey' and 'dailyDiary': I can't figure out how to make Zod
//   // handle Firestore DocumentReferences, so I'm basically just ignoring them.
//   const w: Omit<SessionType, 'afterSessionSurvey' | 'dailyDiary' | 'processedRespirationData' | 'respirationData' | 'timeInLesson' | 'trackId' | 'trial' | 'uid' | 'unitId' | 'nodeId' | 'audioFile'> = sessionTypeSchema.parse(undefined);
//   const x: SessionTypeBase = sessionTypeBaseSchema.parse(undefined);
//   const y: SessionTypeBuilder = sessionTypeBuilderSchema.parse(undefined);
//   const z: Omit<SessionTypeNew, 'afterSessionSurvey' | 'dailyDiary'> = sessionTypeNewSchema.parse(undefined);
//   return !!w || (!!x && !!y && !!z);
// }
