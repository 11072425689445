// *******************************************************
// AddLessonModal
// -------------------------------------------------------
// This is a AddLessonModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { getAllLessons } from '../../collections/lesson';
import { LessonType } from '../../modeltypes/lesson';
import { getAllTasks } from '../../collections/tasks';
import { TaskType } from '../../modeltypes/tasks';
import { LearningGameType } from '../../modeltypes/learningGames';
import { getAllLearningGames } from '../../collections/learningGames';
import { getAllChallengeSwitches } from '../../collections/challengeswitches';
import { ChallengeSwitchType } from '../../modeltypes/challengeswitches';
import { ChallengeType } from '../../modeltypes/challenges';
import { getAllChallenges } from '../../collections/challenges';
import SearchBar from '../SearchBar';
import { useAppContext } from '../../contexts/appContext';
import { LessonAudioChunkType } from '../../modeltypes/lessonAudioChunk';
import { getAllLessonAudioChunks } from '../../collections/lessonAudioChunk';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface AddElementModalProps {
  addElement: (element: LessonType | ChallengeType, pathType: string) => void;
  unitElementsIds: string[];
  hide: () => void;
}

enum Tabs {
  AUDIO_LESSONS,
  CHALLENGES,
  TASKS,
  LEARNING_GAMES,
  CHALLENGE_SWITCHES,
  CHUNKS,
}

const AddElementModal = ({ hide, unitElementsIds, addElement }: AddElementModalProps) => {
  const [lessons, setLessons] = useState<LessonType[] | null>(null);
  const [currentTab, setCurrentTab] = useState(Tabs.AUDIO_LESSONS);

  const [challenges, setChallenges] = useState<ChallengeType[] | null>(null);
  const [challengeSwitches, setChallengeSwitches] = useState<ChallengeSwitchType[] | null>(null);
  const [tasks, setTasks] = useState<TaskType[] | null>(null);
  const [learningGames, setLearningGame] = useState<LearningGameType[] | null>(null);
  const [chunks, setChunks] = useState<LessonAudioChunkType[] | null>(null);
  const [showId, setShowId] = useState(false);

  const { searchTerm } = useAppContext();

  useEffect(() => {
    getAllLessons().then((lessonArray) => {
      setLessons(lessonArray);
    });
    getAllChallengeSwitches().then((challengeSwitchArray) => setChallengeSwitches(challengeSwitchArray));
    getAllTasks().then((tasksArray) => setTasks(tasksArray));
    getAllLearningGames().then((learningGamesArray) => setLearningGame(learningGamesArray));
    getAllChallenges().then((challengeArray) => setChallenges(challengeArray));
    getAllLessonAudioChunks().then((chunksArray) => setChunks(chunksArray));
  }, []);

  const content = useMemo(
    () =>
      currentTab === Tabs.CHALLENGES
        ? challenges
        : currentTab === Tabs.TASKS
        ? tasks
        : currentTab === Tabs.LEARNING_GAMES
        ? learningGames
        : currentTab === Tabs.CHALLENGE_SWITCHES
        ? challengeSwitches
        : currentTab === Tabs.CHUNKS
        ? chunks
        : lessons,
    [currentTab, challenges, tasks, learningGames, challengeSwitches, chunks, lessons],
  ) as (ChallengeSwitchType & LessonType & ChallengeType & LearningGameType & LessonAudioChunkType)[];

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!content?.length) return [];
    return content.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchCompany = item.subtitle && item.subtitle.match(regex);
      const matchCohort = item.description && item.description.match(regex);
      const matchRole = item.id && item.id.match(regex);
      const matchTags = item.tags && item.tags.some((tag) => tag.match(regex));
      return matchName || matchCompany || matchCohort || matchRole || matchTags;
    });
  }, [searchTerm, content]);

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
      onClick={() => hide()}
    >
      <div
        className='relative w-full max-w-[1500px] h-full md:h-auto my-auto mx-auto'
        onClick={(e) => e.stopPropagation()}
      >
        <button className='absolute right-5 top-4 z-10 text-white text-[1.2rem]' onClick={() => hide()}>
          X
        </button>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Add path elements to unit </h3>
          </div>
          <div className={'flex justify-center gap-x-5 my-4'}>
            <button
              className={`${
                currentTab === Tabs.AUDIO_LESSONS ? 'bg-stone-400 text-white' : ' bg-white text-black'
              } inline-flex focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              onClick={() => setCurrentTab(Tabs.AUDIO_LESSONS)}
            >
              Audio Lessons
            </button>
            <button
              className={`${
                currentTab === Tabs.CHALLENGE_SWITCHES ? 'bg-stone-400 text-white' : ' bg-white text-black'
              } inline-flex focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              onClick={() => setCurrentTab(Tabs.CHALLENGE_SWITCHES)}
            >
              Challenge Switch
            </button>
            <button
              className={`${
                currentTab === Tabs.TASKS ? 'bg-stone-400 text-white' : ' bg-white text-black'
              } inline-flex focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              onClick={() => setCurrentTab(Tabs.TASKS)}
            >
              Tasks
            </button>
            <button
              className={`${
                currentTab === Tabs.LEARNING_GAMES ? 'bg-stone-400 text-white' : ' bg-white text-black'
              } inline-flex focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              onClick={() => setCurrentTab(Tabs.LEARNING_GAMES)}
            >
              Learning Games
            </button>
            <button
              className={`${
                currentTab === Tabs.CHALLENGES ? 'bg-stone-400 text-white' : ' bg-white text-black'
              } inline-flex focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              onClick={() => setCurrentTab(Tabs.CHALLENGES)}
            >
              Challenges
            </button>
            <button
              className={`${
                currentTab === Tabs.CHUNKS ? 'bg-stone-400 text-white' : ' bg-white text-black'
              } inline-flex focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              onClick={() => setCurrentTab(Tabs.CHUNKS)}
            >
              Chunks
            </button>
          </div>
          <div className='px-6'>
            {filteredItems ? (
              <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                <SearchBar />
                <button
                  className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-3'
                  onClick={() => setShowId(!showId)}
                >
                  {showId ? 'Hide IDs' : 'Show IDs'}
                </button>
                <div className='overflow-scroll shadow ring-1 ring-black ring-opacity-5 rounded-lg h-[60vh] bg-gray-100'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-white'>
                      <tr>
                        {showId ? (
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                          >
                            ID
                          </th>
                        ) : null}
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Icon
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Title
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Subtitle
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Description
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Tag
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Status
                        </th>
                        <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                          {/*<span className='sr-only'>Edit</span>*/}
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white '>
                      {filteredItems
                        .filter((el) => !unitElementsIds.includes(el.id))
                        .map((element) => (
                          <tr key={element.id} className='hover:bg-stone-200'>
                            {showId && (
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='font-medium text-gray-900'>{element.id}</div>
                              </td>
                            )}
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                              <div className='h-10 w-10 flex-shrink-0'>
                                <img className='h-10 w-10 rounded-full' src={element.icon || undefined} alt='' />
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='font-medium text-gray-900'>{element.title}</div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='font-medium text-gray-900'>{element.subtitle || '-'}</div>
                            </td>
                            <td className=' px-3 py-4 text-sm text-gray-500 box max-w-[300px]'>
                              <div className='font-medium text-gray-900 '>{element.description}</div>
                            </td>
                            <td className='flex flex-wrap flex-row px-3 py-4 text-sm text-gray-500'>
                              {element?.tags?.length ? (
                                <div>
                                  {element.tags?.map((tag) => (
                                    <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                      {tag}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className='text-gray-500'></div>
                              )}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='font-medium text-gray-900'>{element?.productionStatus || 'WIP'}</div>
                            </td>
                            <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                              <div
                                className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                onClick={() =>
                                  addElement(
                                    element,
                                    currentTab === Tabs.AUDIO_LESSONS
                                      ? 'audioLesson'
                                      : currentTab === Tabs.TASKS
                                      ? 'task'
                                      : currentTab === Tabs.LEARNING_GAMES
                                      ? 'learningGame'
                                      : currentTab === Tabs.CHALLENGE_SWITCHES
                                      ? 'challengeSwitch'
                                      : currentTab === Tabs.CHUNKS
                                      ? 'audioChunk'
                                      : 'challenge',
                                  )
                                }
                              >
                                Add
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <div className='flex justify-end text-right p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600'>
            <button
              onClick={hide}
              type='button'
              className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddElementModal;
