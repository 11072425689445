/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { getAllCohorts } from '../../collections/cohort';
import { getAllCompanies } from '../../collections/company';
import {
  addNewAccessCode,
  checkIfUniqueAccessCode,
  generateAccessCode,
  getAccessCodeById,
  updateAccessCode,
} from '../../collections/accessCode';
import { useAppContext } from '../../contexts/appContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Skeleton from 'react-loading-skeleton';
import { AccessCodeType, AccessCodeTypeNew } from '../../modeltypes/accessCode';
import { CompanyType } from '../../modeltypes/company';
import { CohortType } from '../../modeltypes/cohort';
import { toast } from 'react-toastify';
import { UserId } from 'src/modeltypes/id';
import ReactDatePicker from 'react-datepicker';
import { Timestamp } from 'firebase/firestore';

const AccessCodeForm = () => {
  type CohortSelect = {
    label: string;
    value: CohortType;
  };

  type CompanySelect = {
    label: string;
    value: CompanyType;
  };

  const [accessCode, setAccessCode] = useState<AccessCodeType | null>(null);
  const [originalCode, setOriginalCode] = useState<string | null>(null);
  const [cohorts, setCohorts] = useState<CohortSelect[]>([]);
  const [companies, setCompanies] = useState<CompanySelect[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  enum ExpirationTypes {
    DATE = 'DATE',
    TIME_INTERVAL = 'TIME_INTERVAL',
    NEVER_ENDING = 'NEVER_ENDING',
  }

  enum AccessCodeTypes {
    USER = 'USER',
    COMPANY = 'COMPANY',
    COHORT = 'COHORT',
    OPEN = 'OPEN',
  }

  const accessCodeTypes = [
    {
      label: 'Open to anyone and Everyone',
      value: AccessCodeTypes.OPEN,
    },

    {
      label: 'User Access (single)',
      value: AccessCodeTypes.USER,
    },
    {
      label: 'Company Access Code',
      value: AccessCodeTypes.COMPANY,
    },
    {
      label: 'Cohort Access Code',
      value: AccessCodeTypes.COHORT,
    },
  ];

  const accessCodeNotes = {
    [AccessCodeTypes.OPEN]: 'This means that it is open to anyone and everyone!  It is dangerous',
    [AccessCodeTypes.USER]: 'This means that it is open to a specific user list!',
    [AccessCodeTypes.COMPANY]: 'This means that it is open to anyone in a specified company!',
    [AccessCodeTypes.COHORT]: 'This means that it is open to anyone in a specified cohort!',
  };

  const expirationTypes = [
    {
      label: 'Date',
      value: ExpirationTypes.DATE,
    },
    {
      label: 'Time Interval',
      value: ExpirationTypes.TIME_INTERVAL,
    },
    {
      label: 'Never Ending Access Code',
      value: ExpirationTypes.NEVER_ENDING,
    },
  ];

  const expirationNotes = {
    [ExpirationTypes.DATE]: 'This means the code will expire on the specified date',
    [ExpirationTypes.TIME_INTERVAL]: 'This means that the code will expire x number of days after the user signs up',
    [ExpirationTypes.NEVER_ENDING]: 'This means that the code is never ending!  This is dangerous!',
  };

  const days = [...Array(360).keys()].map((day) => ({
    label: `${day} Days`,
    value: day,
  }));

  const userLimit = [...Array(250).keys()].map((userNumber) => ({
    label: userNumber === 0 ? 'No Limit' : `${userNumber} Users`,
    value: userNumber,
  }));

  const { id } = useParams();

  const navigate = useNavigate();
  const { setBreadcrumbPaths } = useAppContext();

  const updateData = async (newAccessCode: AccessCodeType) => {
    await updateAccessCode(newAccessCode.id, newAccessCode);
  };

  const createData = async (newAccessCode: AccessCodeTypeNew) => {
    const newId = await addNewAccessCode(newAccessCode);
    await setAccessCode({
      ...newAccessCode,
      id: newId,
    });
  };

  const { values, errors, handleSubmit, handleChange, setValues, setFieldValue } = useFormik<{
    id?: string;
    code: string;
    from?: string;
    description: string;
    to?: string;
    used?: boolean;
    invited?: boolean;
    signedUp?: boolean;
    expireType?: ExpirationTypes | string;
    expireValue?: number | Timestamp;
    userLimit?: number;
    discountAssossiation?: number;
    benifits?: any[];
    userList?: UserId[];
    cohortId?: string;
    companyId?: string;
    accessCodeType?: AccessCodeTypes | string;
    createdAt?: Timestamp;
    startDate?: Timestamp;
  }>({
    initialValues: {
      code: '',
      description: '',
      cohortId: '',
      companyId: '',
      expireType: ExpirationTypes.DATE,
      accessCodeType: AccessCodeTypes.OPEN,
    },
    validationSchema: yup.object().shape({
      code: yup.string().required('Code is required').max(6).min(6).uppercase(),
      expireType: yup.string(),
    }),
    onSubmit: async (values) => {
      console.log('onSubmit values: ', values);
      console.log('onSubmit originalCode: ', originalCode);
      console.log('onSubmit values.code: ', values.code);

      if (values.code !== originalCode) {
        console.log('checking code uniqueness!');
        const haveUniqueCode = await checkIfUniqueAccessCode(values.code);
        if (!haveUniqueCode) {
          toast('This Code is Not original. Please Try Again!');
          return null;
        }
        const regex = new RegExp(/[a-z]/);

        if (regex.test(values?.code)) {
          toast.error('The new code can not contain lower case letters. Please Try Again!');
          return null;
        }
        const regex2 = new RegExp(/[0O]/);
        if (regex2.test(values?.code)) {
          toast.error("The new code can not contain 0's or O's. Please Try Again!");
          return null;
        }
      }

      if (accessCode?.id) {
        const update: AccessCodeType = {
          id: accessCode.id,
          ...values,
        };

        return toast
          .promise(updateData(update), {
            pending: `Updating ${accessCode?.code}...`,
            error: "Can't do it now, try again.",
            success: `Updated ${accessCode?.code}!`,
          })
          .then(() => {
            navigate('/AccessCodes');
          });
      }
      if (!accessCode?.id) {
        return toast
          .promise(createData(values), {
            pending: `Updating ${accessCode?.code}...`,
            error: "Can't do it now, try again.",
            success: `Updated ${accessCode?.code}!`,
          })
          .then(() => {
            navigate('/AccessCodes');
          });
      }
    },
  });

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'AccessCodes',
        path: '/AccessCodes',
      },
      {
        label: id === 'new' ? 'New AccessCode' : 'Edit AccessCode',
        path: `/AccessCodes/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  useEffect(() => {
    const getData = async () => {
      const allCohorts = await getAllCohorts();
      setCohorts(
        allCohorts.map((c) => {
          return {
            label: c.name || '',
            value: c,
          };
        }),
      );
      const allCompanies = await getAllCompanies();
      setCompanies(
        allCompanies
          .filter((c) => c.companyName)
          .map((c) => {
            return {
              label: c.companyName || '',
              value: c,
            };
          }),
      );

      if (id !== 'new') {
        const currAccessCode = await getAccessCodeById(id || null);
        if (currAccessCode !== null) {
          setAccessCode(currAccessCode);
          const currCohort = allCohorts.find((c) => c.id === currAccessCode?.cohortId);
          const currCompany = allCompanies.find((c) => c.id === currAccessCode?.companyId);
          await setValues({
            code: currAccessCode?.code || '',
            description: currAccessCode?.description || '',
            expireType: currAccessCode?.expireType as ExpirationTypes,
            companyId: currCompany?.id || '',
            cohortId: currCohort?.id || '',
            from: currAccessCode?.from || '',
            to: currAccessCode?.to || '',
            used: currAccessCode?.used,
            invited: currAccessCode?.invited,
            signedUp: currAccessCode?.signedUp,
            expireValue: currAccessCode?.expireValue,
            userLimit: currAccessCode?.userLimit,
            discountAssossiation: currAccessCode?.discountAssossiation,
            benifits: currAccessCode?.benifits || [],
            userList: currAccessCode?.userList || [],
            createdAt: currAccessCode?.createdAt || Timestamp.now(),
            startDate: currAccessCode?.startDate || Timestamp.now(),
            accessCodeType: currAccessCode?.accessCodeType || AccessCodeTypes.OPEN,
          });
        }
        setOriginalCode(currAccessCode?.code || null);
      }

      if (id === 'new') {
        let haveUniqueCode = false;
        let thisCode = '';
        while (!haveUniqueCode) {
          const code = await generateAccessCode();
          haveUniqueCode = await checkIfUniqueAccessCode(code);
          thisCode = code;
        }
        setValues({
          ...values,
          code: thisCode,
        });
        setOriginalCode(thisCode);
      }
    };
    getData().then(() => setIsLoading(false));
  }, [id, setValues]);

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='code' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Access Code
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='code'
                      id='code'
                      autoComplete='code'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                        errors.code ? 'border-red-300' : 'border-gray-300'
                      }`}
                      defaultValue={values.code}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                </div>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <textarea
                    id='description'
                    name='description'
                    rows={3}
                    className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                    onChange={handleChange}
                    value={values.description || ''}
                  />
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='accessCode' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Access Code Type
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    options={accessCodeTypes}
                    value={accessCodeTypes.find((exT) => exT.value === values.accessCodeType)}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        accessCodeType: val?.value || AccessCodeTypes.OPEN,
                      });
                    }}
                  />
                  {values.accessCodeType && values.accessCodeType in accessCodeNotes
                    ? accessCodeNotes[values.accessCodeType as AccessCodeTypes]
                    : ''}
                </div>
              </div>

              {(values?.accessCodeType === AccessCodeTypes.COMPANY ||
                values?.accessCodeType === AccessCodeTypes.COHORT) && (
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='company' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Company
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      isClearable
                      options={companies}
                      value={companies.find((c) => c.value.id === values.companyId)}
                      onChange={(val) => {
                        if (!cohorts.find((c) => values.cohortId === c.value.id && c.value.company === val?.value.id)) {
                          setValues({
                            ...values,
                            cohortId: val?.value.id || '',
                          });
                        }
                        setValues({
                          ...values,
                          companyId: val?.value.id || '',
                          cohortId: '',
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              {values?.accessCodeType === AccessCodeTypes.COHORT && (
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='cohort' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Cohort
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      isDisabled={!values.companyId}
                      isClearable
                      options={cohorts.filter((c) => c.value.company === values.companyId)}
                      value={cohorts.find((c) => c.value.id === values.cohortId)}
                      onChange={(val) => {
                        setValues({
                          ...values,
                          cohortId: val?.value.id || '',
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Expirartion Type
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    options={expirationTypes}
                    value={expirationTypes.find((exT) => exT.value === values.expireType)}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        expireType: val?.value || ExpirationTypes.DATE,
                      });
                    }}
                  />
                  {values.expireType && values.expireType in expirationNotes
                    ? expirationNotes[values.expireType as ExpirationTypes]
                    : ''}
                </div>
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                User Limit
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <Select
                  options={userLimit}
                  value={userLimit.find((exT) => exT.value === values.userLimit)}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      userLimit: val?.value || 0,
                    });
                  }}
                />
              </div>
            </div>

            {values?.expireType === ExpirationTypes.DATE && (
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='expire-date' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Dates that Code is Valid (inclusive)
                </label>
                <div className='mt-1 sm:mt-0 '>
                  <ReactDatePicker
                    selected={values.startDate?.toDate()}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      setFieldValue('startDate', start ? Timestamp.fromDate(start) : undefined);
                      setFieldValue('expireValue', end ? Timestamp.fromDate(end) : undefined);
                    }}
                    startDate={values.startDate?.toDate()}
                    endDate={typeof values.expireValue === 'number' ? null : values.expireValue?.toDate()}
                    selectsRange
                    className={`max-w-lg shadow-sm w-6/12 block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                      errors.expireValue || errors.startDate ? 'border-red-300' : 'border-gray-300'
                    } rounded-md`}
                  />
                </div>
              </div>
            )}

            {values?.expireType === ExpirationTypes.TIME_INTERVAL && (
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='time-interval' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Number of Days from The Day the User Signs UP
                </label>
                <div className='mt-1 sm:mt-0 '>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={days}
                      value={days.find((exT) => exT.value === values.expireValue)}
                      onChange={(val) => {
                        setValues({
                          ...values,
                          expireValue: val?.value || 14,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className='pt-5'>
              <div className='flex justify-end'>
                <button
                  type='button'
                  className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => navigate('/sessions')}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  {id === 'new' ? 'Create' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default AccessCodeForm;
