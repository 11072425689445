import { LessonType } from '../../../modeltypes/lesson';
import { ChapterType } from '../../../modeltypes/chapter';
import { AudioNodeType, TrackType } from '../../../modeltypes/audioNode';

const REQUIRED_KEYS_LESSON: Array<keyof LessonType> = ['title'];
const OPTIONAL_KEYS_LESSON: Array<keyof LessonType> = ['icon', 'grayedIcon', 'subtitle', 'description'];

const REQUIRED_KEYS_CHAPTER: Array<keyof ChapterType> = ['title', 'code'];

const REQUIRED_KEYS_AUDIO_NODE: Array<keyof AudioNodeType> = ['title', 'code', 'nodeType'];
const REQUIRED_KEYS_DECISION: Array<keyof AudioNodeType> = ['singleTap', 'doubleTap', 'audioUrl'];
const REQUIRED_KEYS_STORY: Array<keyof AudioNodeType> = ['nextSubsection', 'audioUrl'];
const REQUIRED_KEYS_SWITCH: Array<keyof AudioNodeType> = ['subType', 'switchOptions'];

export const validateLesson = (
  lesson: LessonType | null,
  chapters: ChapterType[],
  audioNodes: AudioNodeType[],
): [string[], string[]] => {
  const errors: string[] = [];
  const warnings: string[] = [];

  if (!lesson) {
    errors.push('CRITICAL - no lesson');
    return [errors, warnings];
  }

  for (const key of REQUIRED_KEYS_LESSON) {
    if (!lesson[key]) {
      errors.push(`LESSON - Missing ${key}`);
    }
  }

  for (const key of OPTIONAL_KEYS_LESSON) {
    if (!lesson[key]) {
      warnings.push(`LESSON - Missing ${key}`);
    }
  }

  if (chapters.length === 0) {
    errors.push(`LESSON - No chapters attached!`);
  } else {
    for (const chapter of chapters) {
      for (const key of REQUIRED_KEYS_CHAPTER) {
        if (!chapter[key]) {
          errors.push(`CHAPTER - ${chapter.id} - Missing ${key}`);
        }
      }
    }
  }

  if (audioNodes.length === 0) {
    errors.push(`LESSON - No audio Nodes attached!`);
  } else {
    for (const audioNode of audioNodes) {
      for (const key of REQUIRED_KEYS_AUDIO_NODE) {
        if (!audioNode[key]) {
          errors.push(`AUDIO NODE - ${audioNode.id} - Missing ${key}`);
        }
      }

      if (audioNode?.nodeType === TrackType.DECISION) {
        for (const key of REQUIRED_KEYS_DECISION) {
          if (!audioNode[key]) {
            errors.push(`AUDIO NODE DECISION - ${audioNode.id} - Missing ${key}`);
          }
        }
      } else if (audioNode?.nodeType === TrackType.STORY) {
        for (const key of REQUIRED_KEYS_STORY) {
          if (!audioNode[key]) {
            errors.push(`AUDIO NODE STORY - ${audioNode.id} - Missing ${key}`);
          }
        }
      } else if (audioNode?.nodeType === TrackType.SWITCH) {
        for (const key of REQUIRED_KEYS_SWITCH) {
          if (!audioNode[key]) {
            errors.push(`AUDIO NODE SWITCH - ${audioNode.id} - Missing ${key}`);
          }
        }
        if (audioNode.switchOptions) {
          for (const option of audioNode.switchOptions) {
            if (!option.type) {
              errors.push(`AUDIO NODE SWITCH - ${audioNode.id} - Missing type for one of the options`);
            }
            if (!option.nextNode) {
              errors.push(`AUDIO NODE SWITCH - ${audioNode.id} - Missing nextNode for one of the options`);
            }
          }
        }
      }
    }
  }
  return [errors, warnings];
};
