import { ChallengeType } from '../../../modeltypes/challenges';
import { NodeType } from '../../../modeltypes/node';

const REQUIRED_KEYS_CHALLENGE: Array<keyof ChallengeType> = ['title'];

export const validateChallenge = (challenge: ChallengeType, nodes: NodeType[]): string[] => {
  const errors = [];
  // const warnings: string[] = [];

  for (const key of REQUIRED_KEYS_CHALLENGE) {
    if (!challenge[key]) {
      errors.push(`Missing ${key}`);
    }
  }

  let alreadyFoundEnd = false;

  if (nodes.length === 0) {
    errors.push(`CHALLENGE - No nodes attached!`);
  } else {
    for (const node of nodes) {
      if (node.nodeType === 'STORY') {
        if (!node.audioUrl && !node.imageUrl && node.text?.length === 0) {
          errors.push(`Missing content for node ${node.id} - at least one item should be filled (audio,text,image) `);
        }
        if (!node.nextNode) {
          if (alreadyFoundEnd) {
            errors.push(`Missing next node (connection) for node ${node.id}`);
          } else {
            alreadyFoundEnd = true;
          }
        }
      } else if (node.nodeType === 'DECISION') {
        if (!node.question) {
          errors.push(`Missing question for node ${node.id}`);
        }
        if (!node.answers || node.answers.length < 2) {
          errors.push(`Not enough amount of answers for node ${node.id} - at least 2`);
        }
        for (const answer of node?.answers || []) {
          if (!answer.nextNode) {
            errors.push(`Missing answer next node for nodeId: ${node.id}, answerId: ${answer.id}`);
          }
          if (!answer.text) {
            errors.push(`Missing answer text for nodeId: ${node.id}, answerId: ${answer.id}`);
          }
        }
      } else if (node.nodeType === 'QUIZ') {
        if (!node.nextNode) {
          errors.push(`Missing next node (connection) for node ${node.id}`);
        }
        if (!node.question) {
          errors.push(`Missing question for node ${node.id}`);
        }
        if (!node.correctAnswersIds || node.correctAnswersIds.length === 0) {
          errors.push(`Not enough correct answers for node ${node.id} - at least 1`);
        }
        if (!node.answers || node.answers.length === 0) {
          errors.push(`Not enough correct answers for node ${node.id} - at least 1`);
        }
      }
    }
  }

  return errors;
};
