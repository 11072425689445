import {
  addDoc,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  QuerySnapshot,
  updateDoc,
  where,
  setDoc,
} from 'firebase/firestore';
import { ref } from 'firebase/storage';
import { firestore, storage } from './firebaseConfig';
import { prepareChunks } from '../collections/shared';
import { idFromString, idToString } from '../utility/GeneralUtilities';

export const getStorageRef = (path: string) => ref(storage, path);

// These functions probably should not be used by higher-level code. They avoid (most of) the type checking
// we've gone to such pains to build. They are very low level. (I think they are being used in the mobile app
// pre DAL-migration.

/**
 * @deprecated The method should not be used. See above.
 */
// noinspection JSUnusedGlobalSymbols
export const getObjectByDocumentId = async <T,>(collectionName: string, id: string | null): Promise<T | null> => {
  if (id === null) return null;
  const docRef = doc(firestore, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const v = docSnap.data();
    v.id = id;
    return v as T;
  } else {
    return null;
  }
};

/**
 * @deprecated The method should not be used. See above.
 */
// noinspection JSUnusedGlobalSymbols
export async function getObjectsForCollection<T>(
  collectionName: string,
  ...queryConstraints: QueryConstraint[]
): Promise<T[]> {
  let collectionSnapshot: QuerySnapshot;
  if (queryConstraints && queryConstraints.length !== 0) {
    const q = query(collection(firestore, collectionName), ...queryConstraints);
    collectionSnapshot = await getDocs(q);
  } else {
    collectionSnapshot = await getDocs(collection(firestore, collectionName));
  }
  const data: T[] = [];
  collectionSnapshot.forEach((doc) => {
    const v = doc.data();
    v.id = doc.id;
    data.push(v as T);
  });
  return data;
}

// This is intended to reduce the code duplication of having chunking everywhere.
// It doesn't cover every case, but hopefully it covers enough to be useful.
/**
 * @deprecated The method should not be used. See above.
 */
// noinspection JSUnusedGlobalSymbols
export async function getObjectsForCollectionIdsIn<T>(collectionName: string, ids: string[]): Promise<T[]> {
  if (ids.length === 0) {
    // Why bother?
    return [];
  }
  if (ids.length <= 10) {
    // One batch is fine.
    return getObjectsForCollection<T>(collectionName, where(documentId(), 'in', ids));
  }

  // Chunked.
  const chunks = prepareChunks(ids);
  const chunkPromises = chunks.map((chunk) =>
    getObjectsForCollection<T>(collectionName, where(documentId(), 'in', chunk)),
  );
  const resolvedChunks = await Promise.all(chunkPromises);
  const flat = resolvedChunks.flat(1);
  return flat;
}

/**
 * @deprecated The method should not be used. See above.
 */
// noinspection JSUnusedGlobalSymbols
export const addObjectToCollection = async <T extends object, IdT = string>(
  collectionName: string,
  data: T,
): Promise<IdT> => {
  const ref = await addDoc(collection(firestore, collectionName), data);
  return idFromString<IdT>(ref.id);
};

/**
 * @deprecated The method should not be used. See above.
 */
// noinspection JSUnusedGlobalSymbols
export const updateObjectInCollection = async <T extends object, IdT = string>(
  collectionName: string,
  data: T,
  id: IdT,
): Promise<void> => {
  const docRef = doc(firestore, collectionName, idToString(id));
  return updateDoc(docRef, data);
};

/**
 * @deprecated The method should not be used. See above.
 */
// noinspection JSUnusedGlobalSymbols
export const setCollectionObject = async <T extends object, IdT = string>(collectionName: string, data: T, id: IdT) => {
  return setDoc(doc(firestore, collectionName, idToString(id)), data);
};
