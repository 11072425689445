import * as React from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getChaptersByIdArray, updateChapter, deleteChapter as dbDeleteChapter } from '../../collections/chapter';
import { Pages } from '../../enums/pages';
import { LessonChapterAudioNodeList } from './LessonChapterAudioNodeList';
import { LessonAudioNodesComponent } from './LessonAudioNodesComponent';
import { sortedEntityArrayFromIdArray } from '../../utility/GeneralUtilities';
import { ChapterType } from '../../modeltypes/chapter';
import { LessonType } from '../../modeltypes/lesson';
import { getAudioNodesByIdArray, deleteAudioNode } from 'src/collections/audioNode';
import { AudioNodeType } from 'src/modeltypes/audioNode';
import { updateLesson } from '../../collections/lesson';
import cloneDeep from 'clone-deep';
import { arrayRemove } from '@firebase/firestore';
import CreateChunkModal from './CreateChunkModal';
import { toast } from 'react-toastify';

type SumTimeDisplay = {
  min: number;
  sec: number;
};
export const LessonChapterList = ({ lesson, locked }: { lesson: LessonType; locked: boolean }) => {
  const navigate = useNavigate();
  const [chapters, setChapters] = useState<ChapterType[]>([]);
  const [audioSummation, setAudioSummation] = useState<SumTimeDisplay | null>(null);
  const [silenceSummation, setSilenceSummation] = useState<SumTimeDisplay | null>(null);
  const [audioNodes, setAudioNodes] = useState<AudioNodeType[]>();
  const [choppingEnabled, setChoppingEnabled] = useState(false);

  const [selectedChapters, setSelectedChapters] = useState<ChapterType[]>([]);
  const [createChunk, setCreateChunk] = useState(false);
  const getMinAndSecFromNumberArray = (array: number[]) => {
    const timeSummation = array.reduce((sum, duration) => sum + duration, 0);
    const min = Math.floor(timeSummation / 60);
    const sec = timeSummation - min * 60;
    return {
      min,
      sec,
    };
  };

  // Getting the summation of the Audio files for total
  //TODO This does not fully count because it is a tree and not linear!!!! DANG
  const getAudioDurationSummary = async (audioNodeIds: string[] | []) => {
    const audioNodeList: AudioNodeType[] = await getAudioNodesByIdArray(audioNodeIds, true);
    const audioLengths = audioNodeList?.map((c) => c?.duration || 0);
    const silenceLengths = audioNodeList?.map((c) => c?.silenceDuration || 0);
    const audioTotal = getMinAndSecFromNumberArray(audioLengths);
    const silenceTotal = getMinAndSecFromNumberArray(silenceLengths);

    setAudioNodes(audioNodeList);
    setAudioSummation(audioTotal);
    setSilenceSummation(silenceTotal);
  };

  useEffect(() => {
    const chapterIds = lesson?.chapters?.length ? lesson.chapters : [];
    const fetchData = async () => {
      const chapters = await getChaptersByIdArray(chapterIds, true);
      const sortedChapters = sortedEntityArrayFromIdArray(chapterIds, chapters as { id: string }[]);
      setChapters(sortedChapters);
      const audioNodeIds = chapters?.map((c) => c?.audioNodes || []);
      const audioNodeIdsFlat = audioNodeIds.length ? audioNodeIds.flat() : [];
      getAudioDurationSummary(audioNodeIdsFlat);
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson, lesson.chapters, setChapters]);

  const checkIfDetached = useCallback(
    (node: AudioNodeType) => {
      const hasParent = audioNodes?.some((el) =>
        [
          el.nextSubsection,
          el.singleTap,
          el.doubleTap,
          el.tripleTap,
          ...(el.switchOptions?.map((option) => option.nextNode || '') || []),
        ].includes(node.id),
      );
      const hasChild =
        (node.nextSubsection && node.nextSubsection.toUpperCase() !== 'END') ||
        node.singleTap ||
        node.doubleTap ||
        node.tripleTap ||
        node.switchOptions?.some((el) => el.nextNode) ||
        false;

      return !hasParent && !hasChild;
    },
    [audioNodes],
  );

  const deleteNode = async (node: AudioNodeType) => {
    const chapterIndex = chapters.findIndex((el) => el.audioNodes?.includes(node.id));
    if (chapterIndex >= 0) {
      const chapter = cloneDeep(chapters[chapterIndex]);
      const tempChapters = chapters.map((el) =>
        el.id === chapter.id
          ? {
              ...el,
              audioNodes: el.audioNodes?.filter((an) => an !== node.id),
            }
          : el,
      );
      setChapters(tempChapters);

      updateChapter(chapter.id, { audioNodes: arrayRemove(node.id) });
    }
    if (audioNodes) {
      const audioNodesTemp = cloneDeep(audioNodes);
      const index = audioNodesTemp?.findIndex((el) => el.id === node.id);
      audioNodesTemp.splice(index, 1);
      setAudioNodes(audioNodesTemp);
      deleteAudioNode(node.id);
    }
  };

  const deleteChapter = async (chapter: ChapterType) => {
    if (lesson.chapters) {
      const tempChapters = cloneDeep(chapters);
      const chapterIndex = tempChapters.findIndex((el) => el.id === chapter.id);
      tempChapters.splice(chapterIndex, 1);
      setChapters(tempChapters);

      updateLesson(lesson.id, { chapters: arrayRemove(chapter.id) });
      dbDeleteChapter(chapter.id);
    }
  };

  const selectChapter = (chapter: ChapterType) => {
    const isAlreadyIn = !!selectedChapters.find((el) => el.id === chapter.id);
    if (isAlreadyIn) {
      const newArray = selectedChapters.filter((c) => chapter.id !== c.id);
      setSelectedChapters(newArray);
    } else {
      const tempChapters = cloneDeep(selectedChapters);
      tempChapters.push(chapter);
      setSelectedChapters(tempChapters);
    }
  };

  return !lesson ? null : (
    <>
      <div className='sm:grid sm:items-start sm:pt-5'>
        <div className={'flex flex-row justify-between items-center'}>
          <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2'>
            Chapters and nodes
          </label>
          {choppingEnabled ? (
            <div>
              <span>Select chapter/s for new chunk </span>
              <button
                type='button'
                className='text-center text-red-600 hover:text-red-900 cursor-pointer mx-4'
                onClick={() => {
                  setChoppingEnabled(false);
                  setSelectedChapters([]);
                }}
              >
                Cancel
              </button>
              <button
                type='button'
                className='text-center text-indigo-600 hover:text-indigo-900 cursor-pointer font-bold'
                onClick={() => {
                  if (selectedChapters.length) {
                    setCreateChunk(true);
                  } else {
                    toast.error('Select at least 1 chapter first!');
                  }
                }}
              >
                Create
              </button>
            </div>
          ) : (
            <button
              className='text-center text-indigo-600 hover:text-indigo-900 cursor-pointer font-bold'
              onClick={() => setChoppingEnabled(true)}
            >
              Create chunk
            </button>
          )}
        </div>
        {/*{choppingEnabled ? <div>*/}
        {/*  <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2'>*/}
        {/*    New Chunks*/}
        {/*  </label>*/}
        {/*  <div>*/}
        {/*    {newChunks.map((chunk, index) =>*/}
        {/*      <div key={chunk.id} className={'flex flex-row gap-x-2 mb-3 items-center text-sm'}>*/}
        {/*        <p>{index + 1}</p>*/}
        {/*        <input*/}
        {/*          maxLength={40}*/}
        {/*          type='text'*/}
        {/*          name='title'*/}
        {/*          id='title'*/}
        {/*          autoComplete='title'*/}
        {/*          placeholder={'Chunk Name'}*/}
        {/*          className={` max-w-lg flex rounded-md shadow-sm flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm`}*/}
        {/*        />*/}
        {/*        <button*/}
        {/*          className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}*/}
        {/*          onClick={()=>removeChunk(chunk.id)}*/}
        {/*        >*/}
        {/*          Remove*/}
        {/*        </button>*/}
        {/*        {!chunk.name ? <p className={'text-red-400 font-bold'}>Chunk needs a name!</p> : null}*/}
        {/*      </div>,*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  <div className='pt-5 pb-5'>*/}
        {/*      <button*/}
        {/*        type='button'*/}
        {/*        className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'*/}
        {/*        onClick={() => {*/}
        {/*          setNewChunks([]);*/}
        {/*          setChoppingEnabled(false);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Cancel*/}
        {/*      </button>*/}
        {/*      <button*/}
        {/*        onClick={addNewChunk}*/}
        {/*        type='button'*/}
        {/*        className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}*/}
        {/*      >*/}
        {/*        Add chunk*/}
        {/*      </button>*/}
        {/*  </div>*/}
        {/*</div> : null}*/}
        <div className='mt-5 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full'>
                  <thead className='bg-white'>
                    <tr>
                      {choppingEnabled ? (
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Select
                        </th>
                      ) : null}
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Node Name
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Title
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        {`Duration\n${audioSummation?.min}min ${audioSummation?.sec}sec`}
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        {`Duration\n${silenceSummation?.min}min ${silenceSummation?.sec}sec`}
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Audio Url
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Type
                      </th>
                      <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <div
                          className='text-center text-indigo-600 hover:text-indigo-900 cursor-pointer'
                          onClick={() => {
                            navigate(`${window.location.pathname}/${Pages.Chapter}/new&${lesson.id}`);
                          }}
                        >
                          Add Chapter
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white'>
                    {(chapters || []).map((chapter) => (
                      <Fragment key={chapter.id}>
                        <tr className='bg-gray-50 border-t border-gray-200'>
                          {choppingEnabled ? (
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                              <input
                                checked={!!selectedChapters.find((c) => c.id === chapter.id)}
                                onClick={() => selectChapter(chapter)}
                                type={'checkbox'}
                              />
                            </td>
                          ) : null}
                          <th
                            colSpan={6}
                            scope='colgroup'
                            className=' px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6'
                          >
                            {chapter.title} ({chapter.code || '< NO CODE SET>'})
                          </th>
                          <td scope='colgroup' colSpan={1} className='py-2 px-3 '>
                            {chapter.description}
                          </td>
                          <td colSpan={2}>
                            <div className={'flex'}>
                              <button
                                className='text-center text-indigo-600 hover:text-indigo-900'
                                onClick={() => {
                                  navigate(`${window.location.pathname}/${Pages.AudioNode}/new/${chapter.id}`);
                                }}
                              >
                                Add node
                              </button>
                              <button
                                disabled={lesson.productionStatus === 'PROD'}
                                className={`text-center text-indigo-600 hover:text-indigo-900 ml-4 ${
                                  lesson.productionStatus === 'PROD' ? 'opacity-25' : 'opacity-100'
                                }`}
                                onClick={() => {
                                  deleteChapter(chapter);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                        <LessonChapterAudioNodeList
                          chapter={chapter}
                          audioNodes={audioNodes?.filter((el) => chapter.audioNodes?.includes(el.id)) || []}
                          checkIfDetached={checkIfDetached}
                          deleteNode={deleteNode}
                          choppingEnabled={choppingEnabled}
                        />
                      </Fragment>
                    ))}
                    {(chapters || []).length === 0 ? null : (
                      <tr className='bg-gray-50 border-t border-gray-200'>
                        <td colSpan={6}>
                          <LessonAudioNodesComponent chapters={chapters || []} locked={locked} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {createChunk && (
        <CreateChunkModal
          hide={() => setCreateChunk(false)}
          selectedChapters={selectedChapters}
          audioNodes={
            audioNodes?.filter((audioNode) => selectedChapters.some((c) => c.audioNodes?.includes(audioNode.id))) || []
          }
          lesson={lesson}
        />
      )}
    </>
  );
};
