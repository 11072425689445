// *******************************************************
// InsightTips
// -------------------------------------------------------
// This is a InsightTips
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const textSkeleton = [
  {
    title: 'What is it?',
    text: '',
  },
  {
    title: 'How do I increase it in the company?',
    text: '',
  },
  {
    title: 'Extra Tips along the way:',
    text: '',
  },
  {
    title: 'See also:',
    text: '',
  },
];

const InsightTips = () => {
  console.log('InsightTips');
  return (
    <>
      {textSkeleton.map(({ text, title }) => (
        <div key={title}>
          <p className='text-xl text-gray-500 dark:text-white'>{title}</p>
          <p className='min-h-[100px] dark:text-white text-gray-500'>{text}</p>
        </div>
      ))}
    </>
  );
};

export default InsightTips;
