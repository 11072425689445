import { ScriptLesson, ScriptSubsection } from './ScriptModel';
import {
  allPossibleDestinations,
  ExoChoosePracticeRE,
  ExoHomeworkRE,
  lessonHomeworkSpecIsValid,
} from './ScriptUtilities';
import { strip } from './GeneralUtilities';

export type ScriptValidateResult = [boolean, string[], string[]];

export async function validateScriptLesson(
  inLesson: ScriptLesson,
  warningsBlockValidity = false,
): Promise<ScriptValidateResult> {
  const warnMsgs: Array<string> = [];
  const errMsgs: Array<string> = [];
  const rv: ScriptValidateResult = [true, warnMsgs, errMsgs];
  const homeworkCheck = {
    done: {
      hasQuestion: false,
      responses: 0,
    },
    notDone: {
      hasQuestion: false,
      responses: 0,
    },
  };

  if (inLesson.version !== 1) {
    errMsgs.push(`Unknown version: ${inLesson.version}. This is a programming error, not a script error.`);
  }

  if (!inLesson.author) {
    warnMsgs.push(`AUTHOR: Field is missing or empty.`);
  }

  if (!inLesson.lastUpdate) {
    warnMsgs.push(`LAST UPDATE: Field is missing or empty.`);
  }

  if (inLesson.lessonHomeworkNotDoneQuestion) {
    homeworkCheck.notDone.hasQuestion = true;
  }

  if (inLesson.lessonHomeworkDoneQuestion) {
    homeworkCheck.done.hasQuestion = true;
  }

  if (!inLesson.lessonTitle) {
    errMsgs.push(`LESSON TITLE: Field is missing or empty.`);
  }

  if (!inLesson.lessonSubtitle) {
    errMsgs.push(`LESSON SUBTITLE: Field is missing or empty.`);
  }

  if (!inLesson.lessonDescription) {
    errMsgs.push(`LESSON DESCRIPTION: Field is missing or empty.`);
  }

  if (!lessonHomeworkSpecIsValid(inLesson.lessonHomework)) {
    errMsgs.push(
      `LESSON HOMEWORK: Field value '${inLesson.lessonHomework}' is invalid. 
      Use the form: 'Microhit: 0, Formal: 0, Background: 0'.`,
    );
  }

  // Now we need to validate the sections and subsections.
  if (!inLesson.sections.length) {
    errMsgs.push('No sections in script!');
  }

  const allSections: Array<string> = [];
  const allSubsecs: Array<string> = [];
  const allSubsectionsFlat: Array<ScriptSubsection> = [];
  const subsectionsByCode: Map<string, ScriptSubsection> = new Map<string, ScriptSubsection>();
  const duplicateSubsectionCodes: Array<string> = [];

  for (const sec of inLesson.sections) {
    let secName = `${sec.sectionTitle} {${sec.sectionCode}}`;
    if (!sec.sectionTitle) {
      errMsgs.push(`SECTION TITLE: Title is missing.`);
      secName = `<UNNAMED SECTION>`;
    }
    if (!sec.sectionCode) {
      errMsgs.push(`SECTION TITLE {code}: Code is missing or empty.`);
    } else {
      if (allSections.includes(sec.sectionCode)) {
        errMsgs.push(
          `SECTION TITLE {code}: Code ${sec.sectionCode} is duplicated in script. (duplicate in section '${secName}')`,
        );
      } else {
        allSections.push(sec.sectionCode);
      }
    }

    if (sec.sectionCode?.toUpperCase() === 'END') {
      errMsgs.push(`SECTION TITLE {code}: Code is 'END', which is a reserved word.`);
    }

    if (!sec.subsections.length) {
      errMsgs.push(`SECTION: '${secName} {${sec.sectionCode}}' has no subsections.`);
    }
    for (const ssec of sec.subsections) {
      allSubsectionsFlat.push(ssec);
      let ssecName = `${ssec.title} {${ssec.code}}`;
      if (!ssec.title) {
        errMsgs.push(`SUBSECTION TITLE: Title is missing. (in section '${secName}')`);
        ssecName = `<UNNAMED SUBSECTION> {${ssec.code}}`;
      }
      if (!ssec.code) {
        errMsgs.push(`SUBSECTION TITLE {code}: Code is missing. (in section '${secName}')`);
      } else {
        if (allSubsecs.includes(ssec.code)) {
          errMsgs.push(
            `SUBSECTION TITLE {code}: Code ${ssec.code} is duplicated in script. (duplicate in section '${secName}')`,
          );
          if (!duplicateSubsectionCodes.includes(ssec.code)) {
            duplicateSubsectionCodes.push(ssec.code);
          }
        } else {
          allSubsecs.push(ssec.code);
          subsectionsByCode.set(ssec.code, ssec);
        }
      }

      if (typeof ssec.duration === 'number' && ssec.duration <= 0) {
        warnMsgs.push(`SUBSECTION SUMMARY: Duration provided is <= zero.`);
      }

      if (!ssec.spokenText) {
        warnMsgs.push(`SUBSECTION '${ssecName}': Spoken text is missing or empty.`);
      }

      // Footer.
      if (ssec.silenceDuration !== null && ssec.silenceDuration <= 0) {
        warnMsgs.push(`SUBSECTION '${ssecName}': Silence is specified, but is <= 0 seconds.`);
      }

      const hasNextSubsectionCode = !!ssec.nextSubsection;
      const hasSingleTap = !!ssec.singleTap;
      const hasDoubleTap = !!ssec.doubleTap;
      const hasTripleTap = !!ssec.tripleTap;

      const hasSelfDiscovery = ssec.switchOptions.find((i) => i.type === 'SELFDISCOVERY' && i.nextNode);
      const hasSelfMastery = ssec.switchOptions.find((i) => i.type === 'SELFMASTERY' && i.nextNode);
      const hasRelief = ssec.switchOptions.find((i) => i.type === 'RELIEF' && i.nextNode);
      const hasFulfillment = ssec.switchOptions.find((i) => i.type === 'FULFILLMENT' && i.nextNode);
      const hasConnection = ssec.switchOptions.find((i) => i.type === 'CONNECTION' && i.nextNode);
      const hasPractice = ssec.switchOptions.find((i) => i.type === 'PRACTICE' && i.nextNode);

      const hasBeginner = ssec.switchOptions.find((i) => i.type === 'BEGINNER' && i.nextNode);
      const hasSome = ssec.switchOptions.find((i) => i.type === 'SOME' && i.nextNode);
      const hasAdvanced = ssec.switchOptions.find((i) => i.type === 'ADVANCED' && i.nextNode);

      if (hasNextSubsectionCode && (hasSingleTap || hasDoubleTap || hasTripleTap)) {
        errMsgs.push(
          `SUBSECTION footer in '${ssecName}' has both "NEXT SUBSECTION CODE:" and one or more tap destinations set. 
          It should have one or the other. (in section '${secName}')`,
        );
      }

      if (hasSingleTap && !hasDoubleTap && !hasTripleTap) {
        errMsgs.push(
          `SUBSECTION footer in '${ssecName}' has a single tap destination, but no double/triple option.
           (in section '${secName}')`,
        );
      }

      if ((hasTripleTap && (!hasDoubleTap || !hasSingleTap)) || (hasDoubleTap && !hasSingleTap)) {
        errMsgs.push(
          `SUBSECTION footer in '${ssecName}' has missing tap destinations. They should count upward, i.e. 
          single, then double, then triple (in section '${secName}')`,
        );
      }
      if (ssec.type === 'SWITCH') {
        if (
          ssec.subType === 'GOALS' &&
          (!hasRelief || !hasFulfillment || !hasSelfDiscovery || !hasSelfMastery || !hasConnection || !hasPractice)
        ) {
          errMsgs.push(
            `SUBSECTION footer in '${ssecName}' is missing GOAL switch destinations. Missing items: ${
              !hasRelief ? 'RELIEF,' : ''
            } ${!hasFulfillment ? 'FULFILLMENT,' : ''} ${!hasSelfDiscovery ? 'SELFDISCOVERY,' : ''} ${
              !hasSelfMastery ? 'SELFMASTERY,' : ''
            } ${!hasConnection ? 'CONNECTION,' : ''} ${!hasPractice ? 'PRACTICE' : ''}`,
          );
        } else if (ssec.subType === 'EXPERIENCE' && (!hasBeginner || !hasAdvanced || !hasSome)) {
          errMsgs.push(
            `SUBSECTION footer in '${ssecName}' is missing switch destinations. Missing items: ${
              !hasAdvanced ? 'ADVANCED,' : ''
            } ${!hasSome ? 'SOME,' : ''} ${!hasBeginner ? 'BEGINNER,' : ''}`,
          );
        }
      }
    }
  }

  // Do basic subsection reference validation
  for (const ssec of subsectionsByCode.values()) {
    const ssecName = ssec.title ? `${ssec.title} {${ssec.code}}` : `<UNNAMED SUBSECTION> {${ssec.code}}`;
    if (ssec.singleTap && !subsectionsByCode.has(ssec.singleTap)) {
      errMsgs.push(
        `SUBSECTION '${ssecName}': Single tap destination '${ssec.singleTap}' doesn't correspond to any other 
        subsection in the script.`,
      );
    }
    if (ssec.doubleTap && !subsectionsByCode.has(ssec.doubleTap)) {
      errMsgs.push(
        `SUBSECTION '${ssecName}': Double tap destination '${ssec.doubleTap}' doesn't correspond to any other 
        subsection in the script.`,
      );
    }
    if (ssec.tripleTap && !subsectionsByCode.has(ssec.tripleTap)) {
      errMsgs.push(
        `SUBSECTION '${ssecName}': Triple tap destination '${ssec.tripleTap}' doesn't correspond to any other 
        subsection in the script.`,
      );
    }
    if (ssec.nextSubsection) {
      if (
        'END' !== ssec.nextSubsection.toUpperCase() &&
        !ssec.nextSubsection.startsWith('$HOMEWORK') &&
        !ssec.nextSubsection.startsWith('$CHOOSE_PRACTICE') &&
        !subsectionsByCode.has(ssec.nextSubsection)
      ) {
        errMsgs.push(
          `SUBSECTION '${ssecName}': Next subsection code '${ssec.nextSubsection}' doesn't correspond to any other
           subsection in the script, nor any special values.`,
        );
      } else {
        if (ssec.nextSubsection.startsWith('$HOMEWORK')) {
          const match = ExoHomeworkRE.exec(ssec.nextSubsection);
          if (match) {
            const parts = match[1].split(',').map(strip);
            if (parts.length < 2) {
              errMsgs.push(
                `SUBSECTION '${ssecName}': Homework spec: '${ssec.nextSubsection}' doesn't have the two 
                destinations required.`,
              );
            } else {
              let hasDone = false;
              let hasNotDone = false;
              for (const dest of parts) {
                if (!dest) {
                  errMsgs.push(
                    `SUBSECTION '${ssecName}': Homework spec: '${ssec.nextSubsection}' doesn't provide the 
                  two destinations required.`,
                  );
                }
                const subsection = subsectionsByCode.get(strip(dest));
                if (!subsection) {
                  errMsgs.push(
                    `SUBSECTION '${ssecName}': Homework spec: '${ssec.nextSubsection}' destination
                   '${strip(dest)} does not exist.`,
                  );
                } else {
                  if (subsection.homeworkStatus === 'Done') {
                    homeworkCheck.done.responses++;
                    if (!hasDone) {
                      hasDone = true;
                    }
                  } else if (subsection.homeworkStatus === 'Not Done') {
                    homeworkCheck.notDone.responses++;
                    if (!hasNotDone) {
                      hasNotDone = true;
                    }
                  }
                }
              }
              if (!hasDone || !hasNotDone) {
                errMsgs.push(
                  `HOMEWORK RESPONSE: Each response should have at least one node: has not done - ${hasNotDone}, has done - ${hasDone}`,
                );
              }
            }
          } else {
            errMsgs.push(`SUBSECTION '${ssecName}': Homework spec: '${ssec.nextSubsection}' is invalid.`);
          }
        } else if (ssec.nextSubsection.startsWith('$CHOOSE_PRACTICE')) {
          // TODO: Validate practice choice
          const match = ExoChoosePracticeRE.exec(ssec.nextSubsection);
          if (match) {
            const parts = match[1].split(',').map(strip);
            if (parts.length <= 1) {
              errMsgs.push(
                `SUBSECTION '${ssecName}': Practice choice spec: '${ssec.nextSubsection}' needs more than 
                one destination.`,
              );
            } else {
              for (const dest of parts) {
                if (!subsectionsByCode.has(strip(dest))) {
                  errMsgs.push(
                    `SUBSECTION '${ssecName}': Practice choice spec: '${ssec.nextSubsection}' destination 
                    ${strip(dest)} does not exist in the script.`,
                  );
                }
              }
            }
          } else {
            errMsgs.push(`SUBSECTION '${ssecName}': Practice choice spec: '${ssec.nextSubsection}' is invalid.`);
          }
        }
      }
    }
  }

  if (!homeworkCheck.notDone.hasQuestion && homeworkCheck.notDone.responses > 1) {
    errMsgs.push(
      `HOMEWORK RESPONSE QUESTION: Detected more than one response - ${homeworkCheck.notDone.responses} - for Not Done Homework. LESSON HOMEWORK NOT DONE QUESTION is required in that situation!`,
    );
  }

  if (!homeworkCheck.done.hasQuestion && homeworkCheck.done.responses > 1) {
    errMsgs.push(
      `HOMEWORK RESPONSE QUESTION: Detected more than one response - ${homeworkCheck.done.responses} - for Homework Done. LESSON HOMEWORK DONE QUESTION is required in that situation!`,
    );
  }

  // Do path validation
  if (duplicateSubsectionCodes.length) {
    errMsgs.push(
      `Script has multiple subsections with the same code (${duplicateSubsectionCodes.join(
        ', ',
      )}), so we can't check the graph integrity.`,
    );
  } else {
    if (allSubsectionsFlat.length) {
      // Create node graph
      const g: Map<string, Array<string>> = new Map<string, Array<string>>();
      allSubsectionsFlat.forEach((ssec) => {
        if (ssec.code)
          g.set(
            ssec.code,
            allPossibleDestinations(ssec).map((dst) => dst.target),
          );
      });

      // Check for orphans
      const firstSubsectionCode = allSubsectionsFlat.length ? allSubsectionsFlat[0].code : null;
      const allDests: Set<string> = new Set<string>();
      [...g.values()].forEach((dests) => dests.forEach((dest) => allDests.add(dest)));
      [...g.keys()].forEach((k) => {
        if (!allDests.has(k) && k !== firstSubsectionCode) {
          warnMsgs.push(
            `SUBSECTION: with code '${k}' appears to be an orphan (i.e. not the first node, and no other nodes 
            pointing to it.)`,
          );
        }
      });

      // Check for cycles
      const getCycle = (graph: Map<string, Array<string>>, node: string, path: Array<string>): void => {
        if (path.includes(node)) {
          throw new Error(`${path.slice(path.indexOf(node)).concat(node).join('<-')}`);
        }
        path.push(node);
        return (graph.get(node) || []).forEach((next) => getCycle(graph, next, path.slice(0)));
      };

      const validate = (graph: Map<string, Array<string>>): void => {
        [...graph.keys()].forEach((n) => getCycle(graph, n, []));
      };

      try {
        validate(g);
      } catch (e) {
        const msg = typeof e === 'string' ? e : e instanceof Error ? e.message : '';
        if (msg) {
          errMsgs.push(
            `Script has a graph cycle (there may be others, but we stop when we hit the first one): '${msg}'`,
          );
        }
      }
    }
  }

  // Final boolean result...
  const isValid = !errMsgs.length && (!warnMsgs.length || !warningsBlockValidity);
  rv[0] = isValid;
  return rv;
}
