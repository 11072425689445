import { deleteField, IdFactory, where, writeBatch } from '../models/dalaccess';
import {
  // addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getCollection,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
} from './shared';
import { AccountRole, AccountType, AccountTypeBase, AccountTypeBuilder, AccountTypeNew } from '../modeltypes/account';
import { ACCOUNT_TABLE_NAME } from './tableName';
import { UserId, CohortId, CompanyId } from '../modeltypes/id';
import { dieIfNullOrUndef } from '../utility/GeneralUtilities';

export const getAccountCollection = getCollection<AccountType>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNewAccountId = getNewIdFor<UserId>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
// export const addNewAccount = addNewFor<UserId, AccountTypeNew>(ACCOUNT__NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewAccount = createNewFor<UserId, AccountTypeBuilder>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccountRefById = getRefByIdFor<UserId>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccountById = getByIdFor<UserId, AccountType>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccountsByIdArray = getByIdArrayFor<UserId, AccountType>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccountsByQuery = getByQueryFor<AccountType>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllAccounts = getAllFor<AccountType>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllAccountsQuery = getAllQueryFor(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateAccount = updateDocumentFor<UserId, AccountTypeBase>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteAccount = deleteDocumentFor<UserId>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccountType = getValidateTypeFor<AccountType>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccountTypeBase = getValidateTypeBaseFor<AccountTypeBase>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccountTypeBuilder = getValidateTypeBuilderFor<AccountTypeBuilder>(ACCOUNT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccountTypeNew = getValidateTypeNewFor<AccountTypeNew>(ACCOUNT_TABLE_NAME);

export const getAccountsForCompany = async (companyId: CompanyId | null): Promise<AccountType[]> => {
  if (!companyId) {
    return [];
  }
  return await getAccountsByQuery(where('companyId', '==', companyId));
};

export const getAccountsForCohort = async (cohortId: CohortId | null) => {
  if (!cohortId) {
    return [];
  }
  return await getAccountsByQuery(where('cohort', '==', cohortId));
};

// Realistically, until we go back and conform all the data, this function is effectively useless, as the vast
// majority of accounts do not have *any* role set, and Firebase quite literally has no way to query for
// "records where this particular field isn't populated." You just have to get them all and then filter yourself.
// This is part of the reason that conforming the data to a specific schema is important.
export async function getAllAccountsInRole(role: AccountRole | null): Promise<AccountType[]> {
  if (!role) {
    return [];
  }
  const accounts = getAccountsByQuery(where('role', '==', role.toString()));
  return accounts;
}

export const setCohortForAccounts = async (accountIds: UserId[], cohortId: CohortId | null) => {
  if (accountIds.length === 0) {
    return;
  }

  const accts = await getAccountsByIdArray(accountIds);
  const acctIds = accts.map((a) => IdFactory.UserIdFromString(a.id));

  const batch = writeBatch();

  acctIds.forEach((accId: UserId) => {
    batch.update(dieIfNullOrUndef(getAccountRefById(accId)), {
      cohort: cohortId === null ? deleteField() : cohortId,
    });
  });

  await batch.commit();
};

export const updateCompanyForAccounts = async (accountIds: string[], companyId: string) => {
  const batch = writeBatch();

  for (const accountId of accountIds) {
    const docRef = getAccountRefById(accountId);
    batch.update(dieIfNullOrUndef(docRef), { companyId: companyId } as AccountType);
  }

  await batch.commit();
};
