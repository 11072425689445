import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { NOTIFICATIONS_TABLE_NAME } from './tableName';
import { NotificationId } from '../modeltypes/id';
import {
  NotificationType,
  NotificationTypeBase,
  NotificationTypeBuilder,
  NotificationTypeNew,
} from '../modeltypes/notification';

// noinspection JSUnusedGlobalSymbols
export const getNewNotificationId = getNewIdFor<NotificationId>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewNotification = addNewFor<NotificationId, NotificationTypeNew>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewNotification = setNewFor<NotificationId, NotificationTypeNew>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewNotification = createNewFor<NotificationId, NotificationTypeBuilder>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNotificationRefById = getRefByIdFor<NotificationId>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNotificationById = getByIdFor<NotificationId, NotificationType>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNotificationByIdArray = getByIdArrayFor<NotificationId, NotificationType>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllNotification = getAllFor<NotificationType>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllNotificationQuery = getAllQueryFor(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNotificationByQuery = getByQueryFor<NotificationType>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateNotification = updateDocumentFor<NotificationId, NotificationTypeBase>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteNotification = deleteDocumentFor<NotificationId>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchNotificationIdSet = watchIdSetFor<NotificationId>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNotification = getValidateTypeFor<NotificationType>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNotificationBase = getValidateTypeBaseFor<NotificationTypeBase>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNotificationTypeBuilder =
  getValidateTypeBuilderFor<NotificationTypeBuilder>(NOTIFICATIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNotificationTypeNew = getValidateTypeNewFor<NotificationTypeNew>(NOTIFICATIONS_TABLE_NAME);
