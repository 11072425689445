import { arrayRemove, arrayUnion, Timestamp, updateDoc } from '../models/dalaccess';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  queryByIdsFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { dieIfNullOrUndef, emptyCallback, excWrap } from '../utility/GeneralUtilities';
import { LESSON_TABLE_NAME } from './tableName';
import { ChapterId, LessonId } from '../modeltypes/id';
import { LessonType, LessonTypeBase, LessonTypeBuilder, LessonTypeNew, LessonUpdateType } from '../modeltypes/lesson';

// noinspection JSUnusedGlobalSymbols
export const getNewLessonId = getNewIdFor<LessonId>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewLesson = addNewFor<LessonId, LessonTypeNew>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewLesson = createNewFor<LessonId, LessonTypeBuilder>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewLesson = setNewFor<LessonId, LessonTypeNew>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonRefById = getRefByIdFor<LessonId>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonById = getByIdFor<LessonId, LessonType>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonsByIdArray = getByIdArrayFor<LessonId, LessonType>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLessons = getAllFor<LessonType>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonsByQuery = getByQueryFor<LessonType>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLessonsQuery = getAllQueryFor(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonQueryForIds = queryByIdsFor<LessonId>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateLesson = updateDocumentFor<LessonId, LessonUpdateType>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteLesson = deleteDocumentFor<LessonId>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchLessonIdSet = watchIdSetFor<LessonId>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonType = getValidateTypeFor<LessonType>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonTypeBase = getValidateTypeBaseFor<LessonTypeBase>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonTypeBuilder = getValidateTypeBuilderFor<LessonTypeBuilder>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonTypeNew = getValidateTypeNewFor<LessonTypeNew>(LESSON_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const appendChapterToLesson = excWrap((lessonId: LessonId, chapterId: ChapterId) => {
  if (chapterId === 'new') {
    throw new Error("You probably didn't mean to create a 'new' chapter.");
  }
  const docRef = dieIfNullOrUndef(getLessonRefById(lessonId));
  const rv = updateDoc(docRef, {
    chapters: arrayUnion(chapterId),
    updatedAt: Timestamp.now(),
  });
  return rv;
});

// noinspection JSUnusedGlobalSymbols
export const removeChapterFromLesson = excWrap((lessonId: LessonId, chapterId: ChapterId) => {
  const docRef = dieIfNullOrUndef(getLessonRefById(lessonId));
  return updateDoc(docRef, {
    chapters: arrayRemove(chapterId),
    updatedAt: Timestamp.now(),
  });
});

// noinspection JSUnusedGlobalSymbols
export const reorderChapterInLesson = excWrap((lessonId: LessonId, chapterId: ChapterId, move: 1 | -1) => {
  const docRef = dieIfNullOrUndef(getLessonRefById(lessonId));
  getLessonById(lessonId).then((lesson) => {
    if (!lesson) {
      throw new Error('Lesson deleted?');
    }
    const chapArray = [...(lesson.chapters || [])];
    const currentIndex = chapArray.indexOf(chapterId);
    if (currentIndex === -1 || chapArray.length < 1) {
      throw new Error('Chapter not in lesson?');
    }

    if (chapArray.length === 1) {
      return; // Nothing to do.
    }

    if (move < 0) {
      if (currentIndex === 0) {
        return; // Nothing to do
      }
      const newIndex = currentIndex - 1;
      chapArray.splice(newIndex, 0, chapArray.splice(currentIndex, 1)[0]);
    } else if (move > 0) {
      if (currentIndex === chapArray.length - 1) {
        return; // Nothing to do
      }
      const newIndex = currentIndex + 1;
      chapArray.splice(newIndex, 0, chapArray.splice(currentIndex, 1)[0]);
    } else {
      throw new Error('move is 0? What are you trying to do?');
    }

    updateDoc(docRef, {
      chapters: chapArray,
      updatedAt: Timestamp.now(),
    }).then(emptyCallback);
  });
});
