// *******************************************************
// lessons Page
// -------------------------------------------------------
// This is a Screen for showing list of lessons
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../contexts/appContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
import GLogin from '../../components/auth/GLogin';
import GLogout from '../../components/auth/GLogout';
import { gapi, loadAuth2 } from 'gapi-script';
import { linesFromContent, ScriptParser } from '../../utility/ScriptParse';
import { validateScriptLesson } from '../../utility/ScriptValidate';
import WarningErrorList from '../../components/WarningErrorList';
import { setGoogleDocId } from '../../utility/ScriptUtilities';
import convert, {
  updateExistingLessonWithImportedLesson,
  writeImportedLessonToDatabase,
} from '../../utility/ScriptModelToDBConverter';
import { getLessonsByQuery } from '../../collections/lesson';
import ExistingLessonPicker, { ExistingLesson } from '../../components/ExistingLessonPicker';
import { Timestamp, where } from '../../models/dalaccess';
import { LessonId } from '../../modeltypes/id';
import { useNavigate } from 'react-router-dom';
import { getProgramsByQuery } from '../../collections/program';
import { CheckIcon } from '@heroicons/react/solid';
import { ScriptLesson } from '../../utility/ScriptModel';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const API_KEY = process.env.REACT_APP_API_KEY;

enum STEP_ID {
  AUTHENTICATION,
  IMPORT,
  SAVE,
}

async function findExistingLessonsFromGDoc(gDocID: string): Promise<ExistingLesson[]> {
  const matches = await getLessonsByQuery(where('importedFromGDocID', '==', gDocID));
  const rv: ExistingLesson[] = [];

  for (const m of matches) {
    const programs = await getProgramsByQuery(where('lessons', 'array-contains', m.id));
    const program = programs[0];

    rv.push({
      id: m.id,
      gDocID: gDocID,
      lessonName: m.title || '&lt;TITLE MISSING&gt;',
      updatedAt: m.updatedAt,
      programName: program.title || '',
    });
  }
  const defaultTS = Timestamp.fromMillis(0).toDate();
  const sorted = rv.sort((a, b) =>
    (a?.updatedAt || defaultTS) < (b?.updatedAt || defaultTS)
      ? 1
      : (a?.updatedAt || defaultTS) > (b?.updatedAt || defaultTS)
      ? -1
      : 0,
  );
  return sorted;
}

const steps = [
  {
    id: STEP_ID.AUTHENTICATION,
    name: 'Authentication',
  },
  {
    id: STEP_ID.IMPORT,
    name: 'Import and validation',
  },
  {
    id: STEP_ID.SAVE,
    name: 'Save',
  },
];

export const LessonImportPage = () => {
  const { setBreadcrumbPaths } = useAppContext();
  const navigate = useNavigate();

  const [warningList, setWarningList] = useState<string[]>([]);
  const [errorList, setErrorList] = useState<string[]>([]);
  const [existingLessonsWithGDocID, setExistingLessonsWithGDocID] = useState<ExistingLesson[]>([]);
  const [selectedExistingLesson, setSelectedExistingLesson] = useState<LessonId | null>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [didImport, setDidImport] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [didWriteToDB, setDidWriteToDB] = useState<boolean>(false);
  const [gDocID, setGDocID] = useState<string | null>(null);
  const [gDocContent, setGDocContent] = useState<ScriptLesson | null>(null);
  const [lessonId, setLessonId] = useState<string | null>(null);

  const [googleConfigured, setGoogleConfigured] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { handleSubmit, handleChange, values, isSubmitting } = useFormik<{
    docURL: string;
  }>({
    initialValues: {
      docURL: '',
    },
    onSubmit: async (values) => {
      // console.log(`Submit button pressed: ${JSON.stringify(values, null, 2)}!`);
      setDidImport(false);
      setIsValid(false);
      setWarningList([]);
      setErrorList([]);
      setExistingLessonsWithGDocID([]);
      selectedExistingLesson === null || setSelectedExistingLesson(null);

      const preValidationErrorList: string[] = [];

      if (!values.docURL.startsWith('https://docs.google.com/document/d/')) {
        preValidationErrorList.push(`The URL provided does not look like a Google Docs URL. It should start with: 
        'https://docs.google.com/document/d/'. (URL was: '${values.docURL}')`);
        setErrorList(preValidationErrorList);
        return;
      }

      const docID = values.docURL.split('/')[5];
      const url = 'https://docs.googleapis.com/v1/documents/' + docID;
      let result: Record<string, unknown> | null = null;
      try {
        result = await gapi.client.request({ path: url });
      } catch (err) {
        console.error('err', err);
        preValidationErrorList.push(
          `An error occurred while trying to fetch the document from Google Docs. Error: ${JSON.stringify(err)}`,
        );
        setErrorList(preValidationErrorList);
        return;
      }

      if (!result) {
        preValidationErrorList.push(`When fetching the document from Google Docs, we got a null result.`);
        setErrorList(preValidationErrorList);
        return;
      }

      const body: string = result['body'] as string;
      const jsonResultBody = JSON.parse(body);
      const jsonBody = jsonResultBody['body'];
      const content = jsonBody['content'];
      const lfc = linesFromContent(content);
      const sp = new ScriptParser(lfc);
      let scriptIsValid = true;
      const warningMessages: string[] = [];
      const errorMessages: string[] = [];

      try {
        const doc = sp.parse();

        setGoogleDocId(doc, docID); // So we can tell if we've imported this before...
        const [_scriptIsValid, _warningMessages, _errorMessages] = await validateScriptLesson(doc);
        scriptIsValid = _scriptIsValid;
        warningMessages.push(..._warningMessages);
        errorMessages.push(..._errorMessages);
        setGDocContent(doc);
      } catch (e) {
        scriptIsValid = false;
        errorMessages.push(
          `Parsing of script failed. Errors that followed must be corrected before parsing can complete.`,
        );
        if (e instanceof Error && e.message && e.message.length) {
          errorMessages.push(e.message);
        } else if (e) {
          errorMessages.push(JSON.stringify(e));
        } else {
          errorMessages.push('UNKNOWN ERROR. Contact Engineering.');
        }
      }

      setDidImport(true);
      setIsValid(scriptIsValid);
      setWarningList(warningMessages);
      setErrorList(errorMessages);
      setGDocID(docID);
      const matchingLessons = await findExistingLessonsFromGDoc(docID);
      setExistingLessonsWithGDocID(matchingLessons);
    },
    validationSchema: yup.object().shape({
      docURL: yup.string().required(),
    }),
  });

  const onWriteLessonToDatabase = useCallback(async () => {
    if (isSaving || !isValid || !gDocID || !gDocContent) {
      console.warn(
        `Not writing to DB because: isImporting: ${isSaving}, gDocID: ${gDocID}, isValid: ${isValid}, gDocContent: ${!!gDocContent}`,
      );
      return;
    }

    setIsSaving(true);

    try {
      const preImport = await convert(gDocContent, gDocID);
      if (!selectedExistingLesson || selectedExistingLesson === '') {
        await writeImportedLessonToDatabase(preImport);
        setLessonId(preImport?.lesson?.id || '');
      } else {
        await updateExistingLessonWithImportedLesson(selectedExistingLesson, preImport);
      }

      setDidWriteToDB(true);
      setCurrentStepIndex((prev) => prev + 1);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsSaving(false);
    }
  }, [gDocContent, isSaving, isValid, gDocID, selectedExistingLesson]);

  const onExistingLessonSelected = useCallback(function _onExistingLessonSelected(lessonId: LessonId | null) {
    setSelectedExistingLesson(lessonId);
    setLessonId(lessonId);
  }, []);

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Lessons',
        path: '/lessons',
      },
      {
        label: 'Import',
        path: `/lessons/import`,
      },
    ]);

    function start() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/documents',
        })
        .then(() => {
          loadAuth2(gapi, CLIENT_ID || '', 'https://www.googleapis.com/auth/documents').then((auth2) => {
            if (auth2.isSignedIn.get()) {
              const currentUser = auth2.currentUser.get();
              setUserEmail(currentUser.getBasicProfile().getEmail());
            }
            setGoogleConfigured(true);
          });
        });
    }

    gapi.load('client:auth2', start);
  }, [setBreadcrumbPaths]);

  useEffect(() => {
    let listener: any;
    if (googleConfigured) {
      const auth2 = gapi.auth2.getAuthInstance();
      listener = auth2.isSignedIn.listen((isSignedIn: boolean) => {
        if (isSignedIn) {
          const currentUser = auth2.currentUser.get();
          setUserEmail(currentUser.getBasicProfile().getEmail());
        } else {
          setUserEmail(null);
        }
      });
    }
    return () => (listener ? listener?.remove() : null);
  }, [googleConfigured]);

  const currentStep = useMemo(
    () => (currentStepIndex < steps.length ? steps[currentStepIndex] : null),
    [currentStepIndex],
  );

  const renderNextButton = (isBack?: boolean) => (
    <button
      onClick={() => setCurrentStepIndex((prev) => (isBack ? prev - 1 : prev + 1))}
      // disabled={renderProps.disabled}
      className={`mt-2 mb-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    >
      {isBack ? 'Back' : 'Next'}
    </button>
  );

  const renderAuthenticationStep = () => (
    <>
      {!userEmail ? <GLogin /> : <GLogout />}
      {userEmail && (
        <>
          <p className=''>
            Authenticated as <span className='font-bold'>{userEmail}</span>
          </p>
          {renderNextButton()}
        </>
      )}
    </>
  );

  const renderImportStep = () => (
    <>
      <form
        className='space-y-8 divide-y divide-gray-200'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className='flex w-full sm:gap-2 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5 mb-5'>
          <div className='flex-shrink'>
            <label htmlFor='documentURL' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Script Google Doc URL:
            </label>
          </div>
          <div className='flex-grow'>
            <div className='flex-grow flex rounded-md shadow-sm'>
              <input
                type='text'
                name='docURL'
                id='docURL'
                autoComplete='docURL'
                className='flex-1 block focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-l-md rounded-r-md sm:text-sm border-gray-300'
                onChange={handleChange}
                value={values.docURL}
              />
            </div>
          </div>
          <div className='flex-shrink'>
            <button
              disabled={isSubmitting}
              type='submit'
              className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {isSubmitting ? 'Importing' : didImport ? 'Retry' : 'Import'}
            </button>
          </div>
        </div>
      </form>
      {didImport ? (
        <>
          {!isValid ? (
            <>
              {/* This stuff shows if it IS NOT valid.*/}
              <span>There were warnings or errors! (These must be rectified before we can add this lesson.)</span>
              <WarningErrorList isWarnNotError={true} items={warningList} />
              <WarningErrorList isWarnNotError={false} items={errorList} />
            </>
          ) : (
            <>
              {/* This stuff shows if it IS valid.*/}
              <span
                style={{
                  fontSize: 'larger',
                  fontWeight: 'bolder',
                }}
              >
                Looks like the script was valid! Yay!{' '}
                {warningList.length ? '(There were some warnings though...)' : null}
                <br />
              </span>
              <WarningErrorList isWarnNotError={true} items={warningList} />
            </>
          )}
        </>
      ) : null}
      <div className='flex gap-x-2'>
        {renderNextButton(true)}
        {isValid ? renderNextButton() : null}
      </div>
    </>
  );
  // last 1,5 month was spent on preparing new version of the app with new core designs, new program format that looks like duolingo
  // and also webpage for content team to allow building this content live.
  // as it was predicted we finished both last friday. now its being tested by tester and ceo stuff looking for any bugs and ui/ux tweaks
  // that has to be done. currently our goal is to have as most stable version of the app as possible by the end of this week and push it out to stores
  // on monday for two meditation groups that will start course.

  const renderSaveStep = () => (
    <>
      <p>Lesson Details:</p>
      <div className='flex gap-x-2'>
        <p>Lesson Title:</p>
        <p className='font-bold'>{gDocContent?.lessonTitle}</p>
      </div>
      <div className='flex gap-x-2'>
        <p>Author:</p>
        <p className='font-bold'>{gDocContent?.author}</p>
      </div>

      {existingLessonsWithGDocID.length === 0 ? null : (
        <ExistingLessonPicker lessons={existingLessonsWithGDocID} onSelect={onExistingLessonSelected} />
      )}
      <>
        <button
          id='commitToDb'
          disabled={isSaving}
          onClick={onWriteLessonToDatabase}
          className={`mt-2 mb-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          {selectedExistingLesson ? 'Overwrite selected lesson' : 'Add Lesson'}
        </button>
        <p className='text-sml font-semibold text-gray-300'>
          (By clicking add lesson, it does not mean the lesson is live! We will walk you through making the lessons live
          when it is time.)
        </p>
        <div>{renderNextButton(true)}</div>
      </>

      {!isSaving ? null : (
        <>
          <span
            style={{
              fontSize: 'larger',
              fontWeight: 'bolder',
            }}
          >
            Attempting to add lesson... Please wait...
          </span>
          <br />
        </>
      )}
    </>
  );

  return (
    <>
      <ol role='list' className='divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 mt-3'>
        {steps.map((step, stepIdx) => (
          <li key={step.name} className='relative md:flex md:flex-1'>
            {stepIdx < currentStepIndex ? (
              <div className='group flex w-full items-center'>
                <span className='flex items-center px-6 py-4 text-sm font-medium'>
                  <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600'>
                    <CheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </span>
                  <span className='ml-4 text-sm font-medium text-gray-900'>{step.name}</span>
                </span>
              </div>
            ) : stepIdx === currentStepIndex ? (
              <div className='flex items-center px-6 py-4 text-sm font-medium' aria-current='step'>
                <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600'>
                  <span className='text-indigo-600'>{stepIdx + 1}</span>
                </span>
                <span className='ml-4 text-sm font-medium text-indigo-600'>{step.name}</span>
              </div>
            ) : (
              <div className='group flex items-center'>
                <span className='flex items-center px-6 py-4 text-sm font-medium'>
                  <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300'>
                    <span className='text-gray-500'>{stepIdx + 1}</span>
                  </span>
                  <span className='ml-4 text-sm font-medium text-gray-500'>{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className='absolute top-0 right-0 hidden h-full w-5 md:block' aria-hidden='true'>
                  <svg
                    className='h-full w-full text-gray-300'
                    viewBox='0 0 22 80'
                    fill='none'
                    preserveAspectRatio='none'
                  >
                    <path
                      d='M0 -2L20 40L0 82'
                      vectorEffect='non-scaling-stroke'
                      stroke='currentcolor'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
      <hr className='my-4' />

      <div className='sm:flex-auto mt-3'>
        {currentStep?.id === STEP_ID.AUTHENTICATION && renderAuthenticationStep()}
        {currentStep?.id === STEP_ID.IMPORT && renderImportStep()}
        {currentStep?.id === STEP_ID.SAVE && renderSaveStep()}
        {didWriteToDB && (
          <>
            <p
              className='mt-2'
              style={{
                fontSize: 'larger',
                fontWeight: 'bolder',
                color: 'darkgreen',
              }}
            >
              The Imported Lesson was added.
            </p>

            <button
              id='commitToDb'
              disabled={isSaving}
              onClick={() => navigate(`/lessons/${lessonId}`)}
              className={`mt-2 mb-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Go to lesson
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default LessonImportPage;
