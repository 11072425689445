import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'react-flow-renderer';
import { MoveIcon } from 'src/assets/tabIcons/tabIcons';
import { NodeType } from '../../modeltypes/node';

export type DecisionNodeProps = {
  node: NodeType | null;
};

const DecisionCustomNode = ({ id, data, isConnectable, type }: NodeProps<DecisionNodeProps>) => {
  if (!data.node) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
  const answers = data.node.answers || [];
  return (
    <div className='bg-white p-5 hover:cursor-default text-[8px] flex flex-col'>
      <MoveIcon
        className='custom-drag-handle hover:cursor-grab'
        style={{
          display: 'inline-block',
          width: 15,
          height: 15,
          marginLeft: 5,
        }}
      />
      <Handle
        type='target'
        position={Position.Top}
        isConnectable={isConnectable}
        className='w-4 h-2 rounded-none border-0'
      />
      <div className='text-center'>
        <p className='my-2 text-[10px]'>{type}</p>
        <p>{data.node.code}</p>
        <p>
          Id: <strong>{id}</strong>
        </p>
        <p className='mt-4 max-w-[150px] self-center'>
          Question: <span>{data.node.question}</span>
        </p>
        <p className='mt-2'>Available Answers:</p>
        <div className={'bg-gray-100 p-3 my-2'}>
          {answers.map((item) => (
            <p key={item.id}>
              Option <strong>{item.text}</strong>: <strong>{item.nextNode}</strong>
            </p>
          ))}
        </div>
      </div>
      {answers.map((option, index) => (
        <Handle
          key={option.id}
          id={option.id}
          type='source'
          position={Position.Bottom}
          isConnectable={isConnectable}
          style={{
            bottom: -15,
            left: `${100 * ((index + 1) / (answers.length + 1))}%`,
          }}
          className='text-center h-auto w-auto bg-white rounded-none border-2 border-gray-300 px-4'
        >
          <p>{option.text}</p>
        </Handle>
      ))}
    </div>
  );
};

export default memo(DecisionCustomNode);
