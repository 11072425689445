import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  queryByIdsFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { AUDIO_NODE_TABLE_NAME } from './tableName';
import { AudioNodeId } from '../modeltypes/id';
import { AudioNodeType, AudioNodeTypeBase, AudioNodeTypeBuilder, AudioNodeTypeNew } from '../modeltypes/audioNode';
import { HasCreateUpdateDbTs } from '../modeltypes/shared';

// noinspection JSUnusedGlobalSymbols
export const getNewAudioNodeId = getNewIdFor<AudioNodeId>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewAudioNode = addNewFor<AudioNodeId, AudioNodeTypeNew>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewAudioNode = createNewFor<AudioNodeId, AudioNodeTypeBuilder>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewAudioNode = setNewFor<AudioNodeId, AudioNodeTypeNew>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAudioNodeRefById = getRefByIdFor<AudioNodeId>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAudioNodeById = getByIdFor<AudioNodeId, AudioNodeType>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAudioNodesByIdArray = getByIdArrayFor<AudioNodeId, AudioNodeType>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllAudioNodes = getAllFor<AudioNodeType>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAudioNodesByQuery = getByQueryFor<AudioNodeType>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAudioNodeQueryForIds = queryByIdsFor<AudioNodeId>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateAudioNode = updateDocumentFor<AudioNodeId, HasCreateUpdateDbTs<AudioNodeTypeBase>>(
  AUDIO_NODE_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteAudioNode = deleteDocumentFor<AudioNodeId>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchAudioNodeIdSet = watchIdSetFor<AudioNodeId>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAudioNodeType = getValidateTypeFor<AudioNodeType>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAudioNodeTypeBase = getValidateTypeBaseFor<AudioNodeTypeBase>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAudioNodeTypeBuilder = getValidateTypeBuilderFor<AudioNodeTypeBuilder>(AUDIO_NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAudioNodeTypeNew = getValidateTypeNewFor<AudioNodeTypeNew>(AUDIO_NODE_TABLE_NAME);
