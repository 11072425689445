// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { DetailedSessionId } from '../modeltypes/id';
import { DETAILED_SESSIONS_TABLE_NAME } from './tableName';
import {
  DetailedSessionType,
  DetailedSessionTypeBase,
  DetailedSessionTypeBuilder,
  DetailedSessionTypeNew,
} from '../modeltypes/detailedSession';

// noinspection JSUnusedGlobalSymbols
export const getNewDetailedSessionId = getNewIdFor<DetailedSessionId>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewDetailedSession = addNewFor<DetailedSessionId, DetailedSessionTypeNew>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewDetailedSession = createNewFor<DetailedSessionId, DetailedSessionTypeBuilder>(
  DETAILED_SESSIONS_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const setNewDetailedSession = setNewFor<DetailedSessionId, DetailedSessionTypeNew>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getDetailedSessionRefById = getRefByIdFor<DetailedSessionId>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getDetailedSessionById = getByIdFor<DetailedSessionId, DetailedSessionType>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getDetailedSessionsByIdArray = getByIdArrayFor<DetailedSessionId, DetailedSessionType>(
  DETAILED_SESSIONS_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllDetailedSessions = getAllFor<DetailedSessionType>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getDetailedSessionsByQuery = getByQueryFor<DetailedSessionType>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllDetailedSessionsQuery = getAllQueryFor(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateDetailedSession = updateDocumentFor<DetailedSessionId, DetailedSessionTypeBase>(
  DETAILED_SESSIONS_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteDetailedSession = deleteDocumentFor<DetailedSessionId>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchDetailedSessionIdSet = watchIdSetFor<DetailedSessionId>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateDetailedSessionType = getValidateTypeFor<DetailedSessionType>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateDetailedSessionTypeBase =
  getValidateTypeBaseFor<DetailedSessionTypeBase>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateDetailedSessionTypeBuilder =
  getValidateTypeBuilderFor<DetailedSessionTypeBuilder>(DETAILED_SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateDetailedSessionTypeNew = getValidateTypeNewFor<DetailedSessionType>(DETAILED_SESSIONS_TABLE_NAME);
