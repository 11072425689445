// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { EmailId } from '../modeltypes/id';
import { EmailType, EmailTypeBase, EmailTypeBuilder, EmailTypeNew } from '../modeltypes/email';
import { EMAIL_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewEmailId = getNewIdFor<EmailId>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewEmail = addNewFor<EmailId, EmailTypeNew>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewEmail = createNewFor<EmailId, EmailTypeBuilder>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewEmail = setNewFor<EmailId, EmailTypeNew>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getEmailRefById = getRefByIdFor<EmailId>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getEmailById = getByIdFor<EmailId, EmailType>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getEmailItemsByIdArray = getByIdArrayFor<EmailId, EmailType>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllEmailItems = getAllFor<EmailType>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getEmailItemsByQuery = getByQueryFor<EmailType>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllEmailItemsQuery = getAllQueryFor(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateEmail = updateDocumentFor<EmailId, EmailTypeBase>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteEmail = deleteDocumentFor<EmailId>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchEmailIdSet = watchIdSetFor<EmailId>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateEmailType = getValidateTypeFor<EmailType>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateEmailTypeBase = getValidateTypeBaseFor<EmailTypeBase>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateEmailTypeBuilder = getValidateTypeBuilderFor<EmailTypeBuilder>(EMAIL_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateEmailTypeNew = getValidateTypeNewFor<EmailType>(EMAIL_TABLE_NAME);
