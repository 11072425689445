/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { cohortIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { CohortType, CohortTypeBase, CohortTypeBuilder, CohortTypeNew } from '../modeltypes/cohort';
import { timestampType } from './shared';

const DEFAULT_TIME_ZONE = {
  offset: -4,
  name: 'America/Detroit',
};

export const cohortConfigTypeSchema = z.object({
  showBpmResults: z.boolean().default(false),
  accelerometerEnabled: z.boolean().default(false),
  audioRecordingEnabled: z.boolean().default(false),
  gyroscopeEnabled: z.boolean().default(false),
});
export const cohortTypeSchema = z.object({
  id: cohortIdSchema,
  coach: z.array(z.string()).optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  image: z.string().optional(),
  incentives: z.string().optional(),
  company: z.string().optional(),
  creationDate: timestampType(),
  timezone: z
    .object({
      offset: z.number().optional(),
      name: z.string(),
    })
    .default(DEFAULT_TIME_ZONE),
  endTime: z.union([timestampType(), z.null()]).optional(),
  config: cohortConfigTypeSchema.nullable().optional().default(null),
  isTestCohort: z.boolean().optional(),
  isObfuscated: z.boolean().optional(),
  isEliteUserGroup: z.boolean().optional(),

  removeFromStatistics: z.boolean().optional(),
});

export const cohortTypeBaseSchema = cohortTypeSchema.deepPartial();

export const cohortTypeBuilderSchema = cohortTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: cohortIdSchema.default(() => IdFactory.Cohort()) }));

export const cohortTypeNewSchema = cohortTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: cohortIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: CohortType = cohortTypeSchema.parse(undefined);
  const x: CohortTypeBase = cohortTypeBaseSchema.parse(undefined);
  const y: CohortTypeBuilder = cohortTypeBuilderSchema.parse(undefined);
  const z: CohortTypeNew = cohortTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
