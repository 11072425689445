import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAudioNodesByIdArray } from '../../collections/audioNode';
import LessonNodeFlow from './LessonNodeFlow';
import { sortedEntityArrayFromIdArray } from '../../utility/GeneralUtilities';
import { ChapterType } from '../../modeltypes/chapter';
import { AudioNodeType } from '../../modeltypes/audioNode';
import { AudioNodeId } from '../../modeltypes/id';
import cloneDeep from 'clone-deep';

export const LessonAudioNodesComponent = ({ chapters, locked }: { chapters: ChapterType[]; locked: boolean }) => {
  const [audioNodes, setAudioNodes] = useState<AudioNodeType[]>([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!show) {
      setShow(true);
    }
  }, [show]);

  useEffect(() => {
    setShow(false);
  }, [chapters]);

  const updateNodes = async (nIds: string[]) => {
    const nodesToUpdate = await getAudioNodesByIdArray(nIds);
    if (audioNodes) {
      const nodesClone = cloneDeep(audioNodes);
      for (const node of nodesToUpdate) {
        const index = nodesClone.findIndex((i) => i.id === node.id);
        if (index !== -1) {
          nodesClone.splice(index, 1, node);
        } else {
          nodesClone.push(node);
        }
      }
      setAudioNodes(nodesClone);
    } else {
      setAudioNodes(nodesToUpdate);
    }
    // setShow(false);
  };

  useEffect(() => {
    console.debug('LessonAudioNodesComponent.useEffect called.');
    const anIds: AudioNodeId[] = chapters.flatMap((chapter) => chapter.audioNodes || []);
    // This is apparently the best-practice way to call an async function from useEffect.
    // I don't really understand WHY... but the Internet has spoken.
    const fetchData = async () => {
      const audioNodes = await getAudioNodesByIdArray(anIds, true);
      const sortedAudioNodes = sortedEntityArrayFromIdArray(anIds, audioNodes as { id: string }[]);
      setAudioNodes(sortedAudioNodes);
    };

    fetchData().catch(console.error);
  }, [setAudioNodes, chapters]);

  return !audioNodes?.length ? null : (
    <div
      className='sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 h-[800px]'
      onContextMenu={(e) => e.preventDefault()}
    >
      <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
        Flow
      </label>
      {show && (
        <LessonNodeFlow
          rawNodes={audioNodes}
          refresh={() => setShow(false)}
          updateNodes={updateNodes}
          locked={locked}
        />
      )}
    </div>
  );
};
