export const INSIGHTS_ELEMENTS: { [name: string]: { title: string; field: string; image: string } } = {
  sleep: {
    title: 'Sleep', //'Sensory Clarity',
    field: 'sleep',
    image: require('../../assets/skills/restfulness.png'),
  },
  mood: {
    title: 'Mood',
    field: 'mood',
    image: require('../../assets/skills/happiness.png'),
  },
  focus: {
    title: 'Focus',
    field: 'focus',
    image: require('../../assets/skills/distractability.png'),
  },
  clarity: {
    title: 'Clarity', //'Sensory Clarity',
    field: 'clarity',
    image: require('../../assets/skills/clarity.png'),
  },
  equanimity: {
    title: 'Equanimity',
    field: 'equanimity',
    image: require('../../assets/skills/equanimity.png'),
  },
  concentration: {
    title: 'Concentration',
    field: 'concentration',
    image: require('../../assets/skills/concentration.png'),
  },
  restfulness: {
    title: 'Restfulness',
    field: 'restfulness',
    image: require('../../assets/skills/restfulness.png'),
  },
  latency: {
    title: 'Sleep Onset',
    field: 'latency',
    image: require('../../assets/skills/latency.png'),
  },
  happiness: {
    title: 'Happiness',
    field: 'happiness',
    image: require('../../assets/skills/happiness.png'),
  },
  depression: {
    title: 'Depression',
    field: 'depression',
    image: require('../../assets/skills/depression.png'),
  },
  distractibility: {
    title: 'Focus',
    field: 'distractibility',
    image: require('../../assets/skills/distractability.png'),
  },
  overwhelm: {
    title: 'Overwhelm',
    field: 'overwhelm',
    image: require('../../assets/skills/overwhelm.png'),
  },
};

export const INSIGHTS_BREAKDOWN: { [name: string]: { positive: string; negative: string } } = {
  focus: {
    positive: 'distractibility',
    negative: 'overwhelm',
  },
  mood: {
    positive: 'happiness',
    negative: 'depression',
  },
  sleep: {
    positive: 'restfulness',
    negative: 'latency',
  },
};
