// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { TrackId } from '../modeltypes/id';
import { TrackType, TrackTypeBase, TrackTypeBuilder, TrackTypeNew } from '../modeltypes/tracks';
import { TRACK_TABLE_NAME } from './tableName';
import { getUnitById } from './units';
import { getLessonById } from './lesson';
import { getChallengeById } from './challenges';
import { getTaskById } from './tasks';
import { UnitWithDetailsType } from '../modeltypes/unit';
import { LessonType } from '../modeltypes/lesson';
import { ChallengeType } from '../modeltypes/challenges';
import { TaskType } from '../modeltypes/tasks';

// noinspection JSUnusedGlobalSymbols
export const getNewTrackId = getNewIdFor<TrackId>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewTrack = addNewFor<TrackId, TrackTypeNew>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewTrack = createNewFor<TrackId, TrackTypeBuilder>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewTrack = setNewFor<TrackId, TrackTypeNew>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTrackRefById = getRefByIdFor<TrackId>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTrackById = getByIdFor<TrackId, TrackType>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTracksByIdArray = getByIdArrayFor<TrackId, TrackType>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTracks = getAllFor<TrackType>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTracksByQuery = getByQueryFor<TrackType>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTracksQuery = getAllQueryFor(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateTrack = updateDocumentFor<TrackId, TrackTypeBase>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteTrack = deleteDocumentFor<TrackId>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchTrackIdSet = watchIdSetFor<TrackId>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTrackType = getValidateTypeFor<TrackType>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTrackTypeBase = getValidateTypeBaseFor<TrackTypeBase>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTrackTypeBuilder = getValidateTypeBuilderFor<TrackTypeBuilder>(TRACK_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTrackTypeNew = getValidateTypeNewFor<TrackType>(TRACK_TABLE_NAME);

export const getTrackWithLessons = async (trackId: string) => {
  const track = await getTrackById(trackId);
  if (track) {
    // const lessons = [];
    const trackUnits = [...track.units]
      .sort((prev, next) => prev.order - next.order)
      .map(async (unit) => {
        const unitDoc = await getUnitById(unit?.id);
        if (!unitDoc) {
          Promise.reject(`unknown unit with id: ${unit.id}`);
          return null;
        }
        return {
          ...unitDoc,
          elements: await Promise.all(
            [...(unitDoc?.elements || [])]
              .sort((prev, next) => prev.order - next.order)
              .map(async (element) => {
                if (element.pathType === 'audioLesson') {
                  return { ...(await getLessonById(element.id)), pathType: element.pathType };
                }
                if (element.pathType === 'challenge') {
                  return { ...getChallengeById(element.id), pathType: element.pathType };
                }
                if (element.pathType === 'task') {
                  return { ...getTaskById(element.id), pathType: element.pathType };
                }
                Promise.reject(`unknown element with id: ${element.id}, ${element.pathType}`);
                return null;
              }),
          ),
        };
      });

    const unitsData = await Promise.all(trackUnits);
    const fixedUnitsData = unitsData
      .filter((unit): unit is UnitWithDetailsType => !!unit)
      .map((unit) => ({
        ...unit,
        elements: unit.elements.filter(
          (el): el is (LessonType | ChallengeType | TaskType) & { pathType: string } => !!el,
        ),
      }));
    return {
      ...track,
      units: fixedUnitsData,
    };
  }
  return null;
};
