// *******************************************************
// AnswersUpdateModal
// -------------------------------------------------------
// This is a AnswersUpdateModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { AnswerType, NodeType } from '../../../modeltypes/node';
import { useFormik } from 'formik';
import { updateNode } from '../../../collections/nodes';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface AnswersUpdateModalProps {
  hide: (shouldRefresh?: boolean) => void;
  node: NodeType;
  updateNodes: (nodeIds: string[]) => void;
}

const AnswersUpdateModal = ({ node, hide, updateNodes }: AnswersUpdateModalProps) => {
  const { values, handleChange, errors, setFieldValue, isSubmitting, handleSubmit } = useFormik<{
    answers?: AnswerType[];
    correctAnswersIds?: string[];
  }>({
    initialValues: {
      answers: node.answers,
      correctAnswersIds: node.correctAnswersIds,
    },
    onSubmit: async (values) => {
      await updateNode(node.id, { answers: values.answers, correctAnswersIds: values.correctAnswersIds });
      updateNodes([node.id]);
      hide();
    },
    validate: () => {
      const errors: any = {};
      if (!values.correctAnswersIds) {
        errors.correctAnswersIds = 'Correct answers are required';
      } else if (values.correctAnswersIds.length < 1) {
        errors.correctAnswersIds = 'Need at least 1 correct answer';
      }
      if (!values.answers || values.answers.length < 2) {
        errors.answers = 'At least 2 answers are required';
      } else {
        for (const answer of values.answers) {
          if (!answer.text) {
            errors.answers = "Answers can't be empty!";
          }
        }
      }
      return errors;
    },
  });

  const deleteCorrectAnswer = (answerId: string) => {
    if (values.correctAnswersIds) {
      const temp = [...values.correctAnswersIds];
      const index = temp.findIndex((aId) => aId === answerId);
      temp.splice(index, 1);
      setFieldValue('correctAnswersIds', temp);
    }
  };

  return (
    <>
      <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
        <div>
          <label htmlFor='cover-photo' className='block text-lg font-medium text-white mb-4'>
            Question
          </label>
          <div className='mt-2 flex flex-col bg-white p-5'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                <div>
                  <p
                    id='question'
                    className={`text-center text-2xl font-bold flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0`}
                  >
                    {node.question}
                  </p>
                  <div className={`flex flex-col ${errors.correctAnswersIds || errors.answers ? 'bg-red-50' : ''}`}>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                      <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                        {node?.nodeType === 'DECISION' ? 'Options' : 'Answers'}
                      </label>
                    </div>
                    <div>
                      {values.answers?.map((answer, index) => (
                        <div
                          key={answer.id}
                          className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'
                        >
                          <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                            {node?.nodeType === 'DECISION' ? 'Option' : 'Answer'} {index + 1}
                          </label>
                          <div className='max-w-lg flex rounded-md flex flex-col gap-y-1'>
                            <input
                              // disabled={disabledForm}
                              type='text'
                              name={`answers[${index}].text`}
                              id={`answers[${index}].text`}
                              autoComplete={`answers`}
                              className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300 border-gray-300`}
                              value={values?.answers?.[index].text || ''}
                              onChange={handleChange}
                              placeholder={'Enter Answer'}
                            />
                            <div className='flex items-center sm:mt-px sm:pt-2'>
                              <label htmlFor='name' className='block text-sm font-medium text-gray-700 mr-5 '>
                                Correct answer
                              </label>
                              <input
                                name='correctAnswerId'
                                id='correctAnswerId'
                                type={'checkbox'}
                                onChange={() => {
                                  if (values.correctAnswersIds?.includes(answer.id)) {
                                    deleteCorrectAnswer(answer.id);
                                  } else {
                                    const temp = [...(values.correctAnswersIds || [])];
                                    temp.push(answer.id);
                                    setFieldValue('correctAnswersIds', temp);
                                  }
                                }}
                                checked={values.correctAnswersIds?.includes(answer.id)}
                              />
                            </div>
                          </div>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              const temp = values.answers ? [...values.answers] : [];
                              const index = temp.findIndex((i) => i.id === answer.id);
                              if (index > -1) {
                                temp.splice(index, 1);
                                setFieldValue('answers', temp);
                              }
                              if (values.correctAnswersIds?.includes(answer.id)) {
                                deleteCorrectAnswer(answer.id);
                              }
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <button
                    className='mt-2 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={(e) => {
                      e.preventDefault();
                      const temp = values.answers ? [...values.answers] : [];
                      temp.push({
                        id: new Date().getTime().toString(),
                        text: '',
                      });
                      setFieldValue('answers', temp);
                    }}
                  >
                    + Add Option
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end text-right pt-2 pr-2  rounded-b border-t border-gray-200 dark:border-gray-600'>
          <button
            disabled={isSubmitting}
            type='button'
            onClick={() => hide()}
            className='sm:mr-2 text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            type='submit'
            className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default AnswersUpdateModal;
