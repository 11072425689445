/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { dailyDiaryIdSchema } from './id';
import { timestampType } from './shared';
import { IdFactory } from '../models/dalaccess';
import { DailyDiaryType, DailyDiaryTypeBase, DailyDiaryTypeBuilder, DailyDiaryTypeNew } from '../modeltypes/dailyDiary';

export const dailyDiaryAnswersSchema = z.object({
  depression: z.number(),
  distractability: z.number(),
  happiness: z.number(),
  latency: z.number(),
  overwhelm: z.number(),
  restfulness: z.number(),
});

export const dailyDiaryTypeSchema = z.object({
  id: dailyDiaryIdSchema,
  answers: dailyDiaryAnswersSchema,
  skipped: z.boolean().default(false),
  endTime: timestampType(),
  showTime: timestampType(),
});

// Workaround for the lack of a "deep partial" Zod function here.
export const dailyDiaryTypeBaseSchema = dailyDiaryTypeSchema.partial().augment({
  answers: dailyDiaryAnswersSchema.partial(),
});

export const dailyDiaryTypeBuilderSchema = dailyDiaryTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: dailyDiaryIdSchema.default(() => IdFactory.DailyDiary()) }));

export const dailyDiaryTypeNewSchema = dailyDiaryTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: dailyDiaryIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: DailyDiaryType = dailyDiaryTypeSchema.parse(undefined);
  const x: DailyDiaryTypeBase = dailyDiaryTypeBaseSchema.parse(undefined);
  const y: DailyDiaryTypeBuilder = dailyDiaryTypeBuilderSchema.parse(undefined);
  const z: DailyDiaryTypeNew = dailyDiaryTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
