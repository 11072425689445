// *******************************************************
// AudioNodesFormPage
// -------------------------------------------------------
// This is a AudioNodesFormPage
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import AudioNodesForm from './AudioNodesForm';
import { useEffect, useState } from 'react';
import { getAudioNodeById } from '../../collections/audioNode';
import { AudioNodeType } from '../../modeltypes/audioNode';
import { useNavigate, useParams } from 'react-router-dom';
import { AudioNodeId, ChapterId } from '../../modeltypes/id';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const AudioNodesFormPage = () => {
  const { audioNodeId, chapterId } = useParams<{
    audioNodeId: AudioNodeId | 'new';
    chapterId?: ChapterId;
    id: string;
  }>();
  const navigate = useNavigate();

  const [audioNode, setAudioNode] = useState<AudioNodeType | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const an = await getAudioNodeById(audioNodeId);

      setAudioNode(an);
    };

    fetchData().catch(console.error);
  }, [setAudioNode, audioNodeId]);

  return (
    <AudioNodesForm
      audioNode={audioNode}
      chapterId={chapterId}
      afterSubmitFunction={() => {
        /*navigate(`/lessons/${id}`)}*/
      }}
      onCloseFunction={() => navigate(-1)}
      setAudioNode={setAudioNode}
    />
  );
};

export default AudioNodesFormPage;
