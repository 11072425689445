// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllCompanies } from '../../collections/company';
import { Pages } from '../../enums/pages';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import SearchBar from '../SearchBar';
import { PencilIcon, ClipboardIcon, ChartBarIcon } from '@heroicons/react/solid';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
const CompanyList = () => {
  type TableData = {
    companyName?: string;
    id?: string;
    domain?: string;
    sso?: string[];
  };

  const navigate = useNavigate();
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.companyName && item.companyName.match(regex);
      const matchDomain = item.domain && item.domain.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId || matchDomain;
    });
  }, [searchTerm, tableData]);

  useEffect(() => {
    const prepareTableData = async () => {
      const newTableData: TableData[] = [];
      const companies = await getAllCompanies();
      for (const company of companies) {
        if (company.companyName) {
          const data: TableData = {
            companyName: company.companyName,
            id: company.id,
            domain: company.domain,
            sso: company.sso,
          };

          newTableData.push(data);
        }
      }
      setTableData(newTableData);
    };

    prepareTableData().then(() => setIsLoading(false));
  }, []);
  return (
    <div className='lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className=' overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Name
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Domain
                      </th>
                      <th scope='col' colSpan={3} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((company) => (
                      <tr key={company.id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='font-medium text-gray-900'>{company.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='font-medium text-gray-900'>{company.companyName}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-900'>{company.domain}</div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <ClipboardIcon
                            className='cursor-pointer h-7 w-7 fill-indigo-600 hover:fill-indigo-900'
                            aria-hidden='true'
                            onClick={() => navigate(`/${Pages.Companies}/${company.id}/dashboard`)}
                          />
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <ChartBarIcon
                            className='cursor-pointer h-7 w-7 fill-indigo-600 hover:fill-indigo-900'
                            aria-hidden='true'
                            onClick={() => navigate(`/${Pages.Companies}/${company.id}/statistics`)}
                          />
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <PencilIcon
                            className='cursor-pointer h-7 w-7 fill-indigo-600 hover:fill-indigo-900'
                            aria-hidden='true'
                            onClick={() => navigate(`/${Pages.Companies}/${company.id}`)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyList;
