import { NotificationId } from './id';
import { HasCreateUpdate, HasId, WithRequired } from './shared';

export enum NotificationsType {
  REWARDS = 'Rewards',
  GOALS = 'Goals',
  TIP = 'Tip',
  STREAK = 'Streak',
  NEXT_UP = 'Next Up',
  COMEBACK = 'Comeback',
}

export type NotificationType = HasId<NotificationId> &
  HasCreateUpdate & {
    // id?: NotificationId;
    type: NotificationsType;
    title: string;
    message?: string;

    assignedTo?: string | null;

    streak?: { count: number; isUserActive: boolean } | null;
    dayCount: number | null;
  };

// Base == Everything is optional.
export type NotificationTypeBase = Partial<NotificationType>;

// Builder == Everything except `id` is optional.
export type NotificationTypeBuilder = WithRequired<NotificationTypeBase, 'id'>;

// New == Everything is as normal, except that `id` is optional (because the DB will fill it)
// noinspection JSUnusedGlobalSymbols
export type NotificationTypeNew = Omit<NotificationType, 'id'> & Partial<Pick<NotificationType, 'id'>>;
