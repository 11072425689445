/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { scienceDataIdSchema } from './id';
import { timestampType } from './shared';
import { IdFactory } from '../models/dalaccess';
import {
  ScienceDataType,
  ScienceDataTypeBase,
  ScienceDataTypeBuilder,
  ScienceDataTypeNew,
} from '../modeltypes/scienceData';

export const scienceDataTypeSchema = z.object({
  id: scienceDataIdSchema,
  createdAt: timestampType().optional(),
  updatedAt: timestampType().optional(),
  dataType: z.literal('SESSION'),
  dataUrl: z.string(),
  audioUrl: z.string(),
  hexoDataUrl: z.string().nullable().default(null),
  lesson: z.number(),
  session: z.string(),
  user: z.string(),
});

export const scienceDataTypeBaseSchema = scienceDataTypeSchema.partial();

export const scienceDataTypeBuilderSchema = scienceDataTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: scienceDataIdSchema.default(() => IdFactory.ScienceData()) }));

// noinspection JSUnusedGlobalSymbols
export const scienceDataTypeNewSchema = scienceDataTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: scienceDataIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: ScienceDataType = scienceDataTypeSchema.parse(undefined);
  const x: ScienceDataTypeBase = scienceDataTypeBaseSchema.parse(undefined);
  const y: ScienceDataTypeBuilder = scienceDataTypeBuilderSchema.parse(undefined);
  const z: ScienceDataTypeNew = scienceDataTypeNewSchema.parse(undefined);
  return !!w || (!!x && !!y && !!z);
}
