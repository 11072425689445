import { NodeProgressId } from 'src/modeltypes/id';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getCollection,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { NODE_PROGRESS_TABLE_NAME } from './tableName';
import {
  NodeProgressType,
  NodeProgressTypeBase,
  NodeProgressTypeBuilder,
  NodeProgressTypeNew,
} from '../modeltypes/nodeProgress';

export const getNodeProgressCollection = getCollection<NodeProgressType>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNewNodeProgressId = getNewIdFor<NodeProgressId>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewNodeProgress = addNewFor<NodeProgressId, NodeProgressTypeNew>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewNodeProgress = createNewFor<NodeProgressId, NodeProgressTypeBuilder>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewNodeProgress = setNewFor<NodeProgressId, NodeProgressTypeNew>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodeProgressRefById = getRefByIdFor<NodeProgressId>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodeProgressById = getByIdFor<NodeProgressId, NodeProgressType>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodeProgressByIdArray = getByIdArrayFor<NodeProgressId, NodeProgressType>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllNodeProgress = getAllFor<NodeProgressType>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodeProgressByQuery = getByQueryFor<NodeProgressType>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllNodeProgressQuery = getAllQueryFor(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateNodeProgress = updateDocumentFor<NodeProgressId, NodeProgressTypeBase>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteNodeProgress = deleteDocumentFor<NodeProgressId>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchNodeProgressIdSet = watchIdSetFor<NodeProgressId>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeProgressType = getValidateTypeFor<NodeProgressType>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeProgressTypeBase = getValidateTypeBaseFor<NodeProgressTypeBase>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeProgressTypeBuilder =
  getValidateTypeBuilderFor<NodeProgressTypeBuilder>(NODE_PROGRESS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeProgressTypeNew = getValidateTypeNewFor<NodeProgressType>(NODE_PROGRESS_TABLE_NAME);
