import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  queryByIdsFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { NODE_TABLE_NAME } from './tableName';
import { NodeId } from '../modeltypes/id';
import { NodeType, NodeTypeBase, NodeTypeBuilder, NodeTypeNew } from '../modeltypes/node';

// noinspection JSUnusedGlobalSymbols
export const getNewNodeId = getNewIdFor<NodeId>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewNode = addNewFor<NodeId, NodeTypeNew>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewNode = createNewFor<NodeId, NodeTypeBuilder>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewNode = setNewFor<NodeId, NodeTypeNew>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodeRefById = getRefByIdFor<NodeId>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodeById = getByIdFor<NodeId, NodeType>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodesByIdArray = getByIdArrayFor<NodeId, NodeType>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllNodes = getAllFor<NodeType>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodesByQuery = getByQueryFor<NodeType>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getNodeQueryForIds = queryByIdsFor<NodeId>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateNode = updateDocumentFor<NodeId, NodeTypeBase>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteNode = deleteDocumentFor<NodeId>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchNodeIdSet = watchIdSetFor<NodeId>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeType = getValidateTypeFor<NodeType>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeTypeBase = getValidateTypeBaseFor<NodeTypeBase>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeTypeBuilder = getValidateTypeBuilderFor<NodeTypeBuilder>(NODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateNodeTypeNew = getValidateTypeNewFor<NodeTypeNew>(NODE_TABLE_NAME);
