import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { getAllTracks, deleteTrack, updateTrack } from 'src/collections/tracks';
import { confirmAlert } from 'react-confirm-alert';
import { dieIfNullOrUndef } from 'src/utility/GeneralUtilities';
import { TrackType } from '../../modeltypes/tracks';
import { UnitType } from '../../modeltypes/unit';
import { getUnitsByIdArray } from '../../collections/units';
import { toast } from 'react-toastify';
import { getAllGoals } from 'src/collections/goals';
import { GoalType } from 'src/modeltypes/goals';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';
import { Pages } from '../../enums/pages';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

const TrackList = ({ showArchived }: { showArchived?: boolean }) => {
  const [tracks, setTracks] = useState<TrackType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();
  const [defaultTrack, setDefaultTrack] = useState<string | null>(null);

  const [units, setUnits] = useState<UnitType[] | null>(null);
  const [goals, setGoals] = useState<GoalType[]>([]);
  const [tags, setTags] = useState<TagType[] | null>(null);

  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  const getGoals = async () => {
    const dbGoals = await getAllGoals();
    setGoals(dbGoals);
  };

  useEffect(() => {
    getTags();
    getGoals();
  }, []);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tracks?.length) return [];
    return tracks.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId;
    });
  }, [searchTerm, tracks]);

  useEffect(() => {
    const prepareTableData = async () => {
      const fTracks = await getAllTracks();
      await getUnitsByIdArray(fTracks.map((t) => t.units.map((u) => u.id)).flat()).then((fUnits) => setUnits(fUnits));
      const track = fTracks.find((t) => t.defaultTrack);
      if (track) {
        setDefaultTrack(track.id);
      }
      setTracks(fTracks);
    };
    prepareTableData().then(() => setIsLoading(false));
  }, []);

  const navigate = useNavigate();

  const confirmDefaultTrackChange = (track: TrackType) => {
    if (track.productionStatus === 'PROD') {
      confirmAlert({
        title: `Confirm change`,
        message: `Do you want to set ${track.title} as a default (starting) Track for new users?`,
        buttons: [
          {
            label: 'Change',
            onClick: async () => {
              const oldTrackId = defaultTrack;
              await toast
                .promise(
                  async () => {
                    if (oldTrackId) {
                      await updateTrack(oldTrackId, { defaultTrack: false });
                    }
                    await updateTrack(track.id, { defaultTrack: true });
                  },
                  {
                    pending: 'Updating default track..',
                    success: 'Updated default track',
                    error: "Can't update it now..",
                  },
                )
                .then(() => {
                  setDefaultTrack(track.id);
                });
            },
          },
          {
            label: 'Cancel',
          },
        ],
      });
    } else {
      toast.error('Track has to be in PRODUCTION to make it default!!!');
    }
  };

  const onClickDelete = (trackId: string) => {
    confirmAlert({
      title: `Confirm delete path (ID: ${trackId})?`,
      message: 'Are you sure you want to delete this path type? This operation cannot be undone.',
      buttons: [
        {
          label: 'Delete',
          onClick: async () => {
            await deleteTrack(trackId);
            window.location.reload();
          },
        },
        {
          label: 'Cancel',
          onClick: () => alert('Never mind then.'),
        },
      ],
    });
  };

  const navigateToUnit = (id: string) => {
    navigate('/' + Pages.Unit + '/' + id);
  };

  const changeTrackVisibleInRouter = async (isChecked: boolean, trackId: string) => {
    await updateTrack(trackId, { showInRouter: isChecked });
  };
  const changeIsArchived = async (isChecked: boolean, track: TrackType, trackId: string) => {
    if (track?.showInRouter) {
      return confirmAlert({
        title: `Oooops`,
        message: `Please untoggle "showInRouter" on the item ${track.title} before archiving. This will ensure it is not in the App... You filthy animal!`,
        buttons: [
          {
            label: 'Okay',
            onClick: async () => {
              window.location.reload();
            },
          },
        ],
      });
    } else {
      await updateTrack(trackId, { isArchived: isChecked });
      window.location.reload();
    }
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope='col' className='py-3 text-sm font-semibold text-gray-900 sm:pl-6 '>
                        {'Default\nTrack'}
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Goal Type
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Units
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Tags
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Status
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Locked
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-center'
                      >
                        Visible in router
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-center'
                      >
                        Archived
                      </th>

                      <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((track) => {
                      if (!showArchived && track.isArchived) return null;
                      if (showArchived && !track.isArchived) return null;
                      return (
                        <tr key={track.id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <input
                              type={'radio'}
                              checked={track.id === defaultTrack}
                              onChange={() => confirmDefaultTrackChange(track)}
                            />
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500 max-w-[75px] truncate'>{track.id}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{track?.title}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {track?.goal
                                  ? goals?.find((x) => x.id === track?.goal)?.name
                                  : track?.isIntroSeries
                                  ? 'No Goal- Intro Series'
                                  : 'Error'}
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='ml-4 flex items-center flex-col '>
                              {track?.units?.length ? (
                                <>
                                  {track.units?.map(({ id }) => {
                                    const unit = units?.find((fU) => fU.id === id);
                                    return (
                                      <button
                                        key={unit?.title}
                                        className='text-gray-500 p-1 m-1 border rounded hover:bg-gray-100'
                                        onClick={() => navigateToUnit(id)}
                                      >
                                        {unit?.title}
                                      </button>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className='text-gray-500'>-</div>
                              )}
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {track?.tags?.length ? (
                                  <div>
                                    {track.tags?.map((tag) => (
                                      <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                        {tags?.find((t) => t.id === tag)?.name}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className='text-gray-500'></div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{track.productionStatus || 'WIP'}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {track.locked ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              {track?.isArchived ? (
                                <div>NOPE</div>
                              ) : (
                                <div className='ml-4'>
                                  <input
                                    type={'checkbox'}
                                    checked={track.showInRouter}
                                    onChange={(event) => changeTrackVisibleInRouter(event.target.checked, track.id)}
                                  />
                                  {/*{track.showInRouter ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}*/}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <input
                                  type={'checkbox'}
                                  checked={track.isArchived}
                                  onChange={(event) => changeIsArchived(event.target.checked, track, track.id)}
                                />
                                {/*{track.showInRouter ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}*/}
                              </div>
                            </div>
                          </td>

                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <div onClick={() => navigate('/tracks/' + track.id)} style={{ cursor: 'pointer' }}>
                              Edit
                            </div>
                          </td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                            <button
                              disabled={track.productionStatus === 'PROD' || track.locked}
                              className={`${
                                track.productionStatus === 'PROD' || track.locked ? 'opacity-25' : 'opacity-100'
                              } text-indigo-600 hover:text-indigo-900`}
                              onClick={() => onClickDelete(dieIfNullOrUndef(track.id))}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackList;
