import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'react-flow-renderer';
import { ModalType, truncateMiddle } from '../../paths/sharedValues';
import { AudioNodeType } from '../../../modeltypes/audioNode';
import { MoveIcon } from '../../../assets/tabIcons/tabIcons';

export type DecisionNodeProps = {
  node: AudioNodeType | null;
  showModal: (modal: ModalType) => void;
};

export const TAPS = [
  {
    id: 'singleTap',
    text: 'Single Tap',
  },
  {
    id: 'doubleTap',
    text: 'Double Tap',
  },
  {
    id: 'tripleTap',
    text: 'Triple Tap*',
  },
];

const DecisionCustomNode = ({ id, data, isConnectable, type }: NodeProps<DecisionNodeProps>) => {
  if (!data.node) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <div className='flex flex-col bg-white p-5 hover:cursor-default rounded-xl shadow-sm text-[8px]'>
      <MoveIcon
        className='custom-drag-handle hover:cursor-move'
        style={{
          display: 'inline-block',
          width: 15,
          height: 15,
        }}
      />
      <div className='text-center flex flex-col items-center'>
        <Handle
          type='target'
          position={Position.Top}
          isConnectable={isConnectable}
          className='w-4 h-2 rounded-none border-0'
        />
        <div className='text-center'>
          <p className='my-2'>{type}</p>
          <p>{data.node.code}</p>
          <p>
            Id: <strong>{id}</strong>
          </p>
          <div className='flex flex-col mb-2 gap-y-1 max-w-[150px]'>
            <div className='flex flex-col mr-4'>
              {data.node.audioUrl ? <audio src={data.node.audioUrl || ''} controls /> : null}
              <button onClick={() => data.showModal(ModalType.UPDATE_AUDIO)} className=' underline m-1 text-blue-300'>
                Update Audio
              </button>
            </div>
            <div className='flex flex-col'>
              <button
                onClick={() => data.showModal(ModalType.SHOW_FULL_TEXT)}
                className='flex justify-center shadow border border-blue-300 rounded-full pl-3 pr-3 max-w-[150px]'
              >
                Show Full Text
              </button>
              <button
                onClick={() => data.showModal(ModalType.UPDATE_TEXT)}
                className='w-auto underline m-1 text-blue-300'
              >
                Update Text
              </button>
            </div>
          </div>
        </div>
        <p className='mb-2 max-w-[1500px] overflow-hidden ellipsis'>{truncateMiddle(data.node?.spokenText || '')}</p>
      </div>
      {TAPS.map((option, index) => (
        <Handle
          key={option.id}
          id={option.id}
          type='source'
          position={Position.Bottom}
          isConnectable={isConnectable}
          style={{
            bottom: -15,
            left: `${100 * ((index + 1) / (TAPS.length + 1))}%`,
          }}
          className='text-center h-auto w-auto bg-white rounded-none border-2 border-gray-300 px-4'
        >
          <p>{option.text}</p>
        </Handle>
      ))}
    </div>
  );
};

export default memo(DecisionCustomNode);
