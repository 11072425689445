export const Goals: { [name: string]: string } = {
  RELIEF: 'RELIEF',
  FULFILLMENT: 'FULFILLMENT',
  // SHAWN_CHANGES_FOR TRANSPILER
  // SELF_DISCOVERY: 'SELF_DISCOVERY',
  // SELF_MASTERY: 'SELF_MASTERY',
  SELFDISCOVERY: 'SELFDISCOVERY',
  SELFMASTERY: 'SELFMASTERY',
  CONNECTION: 'CONNECTION',
  PRACTICE: 'PRACTICE',
};

export const Experience: { [name: string]: string } = {
  BEGINNER: 'Beginner',
  SOME: 'Some',
  ADVANCED: 'Advanced',
};

export enum GoalTs {
  RELIEF = 'RELIEF',
  FULFILLMENT = 'FULFILLMENT',
  SELFDISCOVERY = 'SELFDISCOVERY',
  SELFMASTERY = 'SELFMASTERY',
  CONNECTION = 'CONNECTION',
  PRACTICE = 'PRACTICE',
}

export enum ExperienceTs {
  BEGINNER = 'Beginner',
  SOME = 'Some',
  ADVANCED = 'Advanced',
}
