/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { lessonIdSchema, programIdSchema } from './id';
import { productionStatusValueSchema, timestampType } from './shared';
import { IdFactory } from '../models/dalaccess';
import { ProgramType, ProgramTypeBase, ProgramTypeBuilder, ProgramTypeNew } from '../modeltypes/program';

export const programTypeSchema = z.object({
  id: programIdSchema,
  createdAt: timestampType().optional(),
  updatedAt: timestampType().optional(),
  productionStatus: productionStatusValueSchema.optional(),
  title: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  icon: z.string().optional().nullable(),
  grayedIcon: z.string().optional().nullable(),
  lessons: z.array(lessonIdSchema).optional(),
});

export const programTypeBaseSchema = programTypeSchema.partial();

export const programTypeBuilderSchema = programTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: programIdSchema.default(() => IdFactory.Program()) }));

export const programTypeNewSchema = programTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: programIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: ProgramType = programTypeSchema.parse(undefined);
  const x: ProgramTypeBase = programTypeBaseSchema.parse(undefined);
  const y: ProgramTypeBuilder = programTypeBuilderSchema.parse(undefined);
  const z: ProgramTypeNew = programTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
