// *******************************************************
// LessonAudioChunksForm
// -------------------------------------------------------
// This is a LessonAudioChunksForm
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useAppContext } from '../../contexts/appContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LessonId } from '../../modeltypes/id';
import { useCallback, useEffect, useState } from 'react';
import { ReactSelectOptions } from '../../types/types';
import { dieIfNullOrUndef, emptyStringToNull, nullToUndef } from '../../utility/GeneralUtilities';
import { getAllTags } from '../../collections/tags';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { uiFormatTimestamp } from '../../collections/shared';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import Select from 'react-select';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';
import { FileUploader } from 'react-drag-drop-files';
import {
  LessonAudioChunkType,
  LessonAudioChunkTypeBase,
  LessonAudioChunkTypeBuilder,
} from '../../modeltypes/lessonAudioChunk';
import {
  addNewLessonAudioChunk,
  getLessonAudioChunkById,
  updateLessonAudioChunk,
} from '../../collections/lessonAudioChunk';
import { LessonChunkNodesList } from '../../components/lessonAudioChunks/LessonChunkNodesList';
import { handleAudioUpload, UploadedFileList } from '../../utility/uploadHandler';
import { AudioNodeTypeBase } from '../../modeltypes/audioNode';
import { updateAudioNode } from '../../collections/audioNode';
import { serverTimestamp } from '../../models/dalaccess';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const LessonAudioChunksForm = () => {
  const { setBreadcrumbPaths, setValidateElement } = useAppContext();
  const navigate = useNavigate();
  const { id } = useParams<{ id: LessonId | 'new' }>();

  // const lessonRef = id ? getLessonRefById(id) : getLessonRefById('notarealid');
  // const { data: lesson } = useFirestoreDocData(dieIfNullOrUndef(lessonRef), { idField: 'id' });
  const [audioLessonChunk, setAudioLessonChunk] = useState<LessonAudioChunkType | undefined>(undefined);
  const [tags, setTags] = useState<ReactSelectOptions[] | null>(null);

  useEffect(() => {
    console.debug('LessonDetails.useEffect called.');
    getLessonAudioChunkById(id).then((chunk) => setAudioLessonChunk(nullToUndef(chunk)));
    getAllTags().then((fTags) =>
      setTags(
        fTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        })),
      ),
    );
  }, [setAudioLessonChunk, id]);

  const { handleSubmit, handleChange, values, setValues, errors, isSubmitting, setFieldValue } = useFormik<
    LessonAudioChunkTypeBase & { bulkUploads?: UploadedFileList }
  >({
    initialValues: {
      title: '',
      subtitle: '',
      description: '',
      author: '',
      voiceArtist: '',
      hideCceSurvey: null,
      isMovementLesson: null,
      productionStatus: null,
      importedFromGDocID: undefined,
      scriptLastUpdate: undefined,
    },
    onSubmit: async (values) => {
      dieIfNullOrUndef(audioLessonChunk);
      await toast
        .promise(
          async () => {
            let lessonAudioChunkId = audioLessonChunk?.id;
            if (!lessonAudioChunkId) {
              lessonAudioChunkId = await addNewLessonAudioChunk();
            }

            const updateData: LessonAudioChunkTypeBuilder = {
              id: lessonAudioChunkId,
              title: values.title,
              description: values.description,
              subtitle: values.subtitle,
              author: values.author,
              voiceArtist: values.voiceArtist,
              hideCceSurvey: values.hideCceSurvey,
              isMovementLesson: values.isMovementLesson,
              // icon: urls.icon || (audioLessonChunk ? audioLessonChunk.icon : ''),
              // grayedIcon: urls.grayedIcon || (audioLessonChunk ? audioLessonChunk.grayedIcon : ''),
              productionStatus: values.productionStatus,
              tags: values.tags || [],
              duration: values.duration,
            };

            if (audioLessonChunk?.importedFromGDocID) {
              updateData.importedFromGDocID = values.importedFromGDocID;
              updateData.scriptLastUpdate = values.scriptLastUpdate;
            }

            await updateLessonAudioChunk(lessonAudioChunkId, updateData);

            if (values.bulkUploads?.length) {
              toast.info('Preparing audio to be updated!', {
                toastId: 'audio',
                theme: 'colored',
                autoClose: false,
                closeOnClick: false,
                hideProgressBar: false,
                isLoading: true,
              });
              let progress = 0;
              // We may need to give the user some feedback here, as this could take a while, but one thing at a time.
              const bulkResults = await handleAudioUpload(
                dieIfNullOrUndef(audioLessonChunk),
                dieIfNullOrUndef(values.bulkUploads),
              );
              for (const br of bulkResults) {
                // Update the audio nodes.
                const audioUpdateData: AudioNodeTypeBase = {};
                if (br.matchingNodeId && br.downloadUrl) {
                  audioUpdateData.audioUrl = br.downloadUrl;
                  audioUpdateData.id = br.matchingNodeId;
                }
                // if (br.audioDuration) {
                // This is just a tiny bit of slop so that we don't round up things that are practically dead on.
                // audioUpdateData.duration = Math.ceil(br.audioDuration - 0.05);
                // }
                if (audioUpdateData.audioUrl) {
                  await updateAudioNode(dieIfNullOrUndef(audioUpdateData.id), {
                    ...audioUpdateData,
                    updatedAt: serverTimestamp(),
                  });
                  const index = bulkResults.findIndex((i) => i.url === br.url);
                  progress = (index + 1) / bulkResults.length;
                  toast.update('audio', {
                    render: 'Uploading...',
                    progress,
                  });
                }
              }

              // Refresh the current page instead of navingating to lessons.
              const fetchData = async () => {
                const les = await getLessonAudioChunkById(id);
                setAudioLessonChunk(nullToUndef(les));
              };

              await fetchData().catch(console.error);
              toast.update('audio', {
                render: 'Uploaded audio!',
                theme: 'colored',
                type: 'success',
                autoClose: 5000,
                closeOnClick: true,
                isLoading: false,
              });
            }
          },
          {
            pending: `Updating ${values.title}! Please wait...`,
            success: 'Successfully Updated Lesson!',
            error: 'Something went wrong while Updating Lesson',
          },
        )
        .then(() => navigate('/lessonAudioChunks'));
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      homeworkAssigned: yup
        .string()
        .trim()
        .homeworkSpecValid(`Homework spec should look like: 'Microhit: #, Formal: #, Background: #`)
        .optional(),
    }),
  });

  // useEffect(() => {
  //   if (audioLessonChunk && tags) {
  //     setFieldValue(
  //       'tags',
  //       tags.filter((el) => audioLessonChunk.tags?.includes(el.value)),
  //     );
  //   }
  // }, [setFieldValue, tags, audioLessonChunk]);

  useEffect(() => {
    if (audioLessonChunk) {
      setValues({
        title: audioLessonChunk.title || '',
        description: audioLessonChunk.description || '',
        subtitle: audioLessonChunk.subtitle || '',
        author: audioLessonChunk.author || '',
        voiceArtist: audioLessonChunk.voiceArtist || '',
        hideCceSurvey: audioLessonChunk.hideCceSurvey || null,
        isMovementLesson: audioLessonChunk.isMovementLesson || null,
        importedFromGDocID: audioLessonChunk.importedFromGDocID || '',
        scriptLastUpdate: audioLessonChunk.scriptLastUpdate || '',
        productionStatus: audioLessonChunk.productionStatus || null,
        duration: audioLessonChunk.duration,
      });
    }
    setBreadcrumbPaths([
      {
        label: 'Lesson Audio Chunks',
        path: '/lessonAudioChunks',
      },
      {
        label: audioLessonChunk?.title || 'New Lesson Audio Chunk',
        path: `/lessonAudioChunks/${id}`,
      },
    ]);
  }, [id, audioLessonChunk, setValues, setBreadcrumbPaths]);

  const handleUploadDrop = useCallback(
    (file: File | FileList): void => {
      let fl: FileList;
      if (file instanceof File) {
        // Single
        fl = new FileList();
        fl[0] = file;
      } else {
        fl = file;
      }

      const listForFomik = [];
      for (const uploadedFile of fl) {
        // console.log(uploadedFile);
        const url = URL.createObjectURL(uploadedFile);
        const lffItem = {
          file: uploadedFile,
          url: url,
        };
        listForFomik.push(lffItem);
      }

      // This stashes the references away in Formik, and we'll handle them on submit.
      setValues({
        ...values,
        bulkUploads: listForFomik,
      });
    },
    [values, setValues],
  );

  const changeTo = (id: string, to: 'DEV' | 'PROD') => {
    setValidateElement({
      id,
      type: 'audioChunk',
      to,
    });
  };

  // noinspection DuplicatedCode
  return !audioLessonChunk ? null : (
    <div>
      <form
        className='space-y-8 divide-y divide-gray-200'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
          <div>
            <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
              Lesson Audio Chunk Details <span style={{ fontSize: '80%' }}>(ID: {audioLessonChunk.id})</span>
            </label>
            <p>
              <strong>Created:</strong>&nbsp;{uiFormatTimestamp(audioLessonChunk?.createdAt)}
              &nbsp;&nbsp;
              <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(audioLessonChunk?.updatedAt)}
              {audioLessonChunk ? (
                <>
                  <div className={'flex flex-row items-center mt-3'}>
                    <p className='font-bold mr-3'>Status:</p>
                    {audioLessonChunk?.productionStatus || 'WIP'}
                    {audioLessonChunk?.productionStatus === 'DEV' ? (
                      <button
                        type={'button'}
                        onClick={() => changeTo(audioLessonChunk?.id, 'PROD')}
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Change to Prod
                      </button>
                    ) : null}
                    {!audioLessonChunk?.productionStatus ? (
                      <button
                        type={'button'}
                        onClick={() => changeTo(audioLessonChunk?.id, 'DEV')}
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Change to Dev
                      </button>
                    ) : null}
                  </div>
                  <div className={'flex flex-row items-center mt-3'}>
                    <p className='font-bold mr-3'>Locked:</p>
                    {audioLessonChunk?.locked ? (
                      <CheckIcon className='h-5 w-5 text-green-500' />
                    ) : (
                      <XIcon className='h-5 w-5 text-red-400' />
                    )}
                  </div>
                </>
              ) : null}
            </p>
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                    This information will be displayed publicly so be careful what you share
                  </p>
                </div>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      maxLength={40}
                      type='text'
                      name='title'
                      id='title'
                      autoComplete='title'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                        errors?.title ? 'border-red-300' : 'border-gray-300'
                      }`}
                      onChange={handleChange}
                      value={values.title || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Subtitle
                  </label>
                  <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                    This information will be displayed publicly so be careful what you share
                  </p>
                </div>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='subtitle'
                      id='subtitle'
                      autoComplete='subtitle'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                        errors?.title ? 'border-red-300' : 'border-gray-300'
                      }`}
                      onChange={handleChange}
                      value={values.subtitle || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Description
                </label>
                <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                  This information will be displayed publicly so be careful what you share
                </p>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <textarea
                  id='description'
                  name='description'
                  rows={3}
                  className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  onChange={handleChange}
                  value={values.description || ''}
                />
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <div>
                <label htmlFor='author' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Author
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='author'
                    id='author'
                    autoComplete='author'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    onChange={handleChange}
                    value={values.author || ''}
                  />
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Voice Artist
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='voiceArtist'
                    id='voiceArtist'
                    autoComplete='voiceArtist'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    onChange={handleChange}
                    value={values.voiceArtist || ''}
                  />
                </div>
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Dont show CCE Survey (Pre or Post)
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type={'checkbox'}
                    checked={values?.hideCceSurvey || false}
                    onChange={(event) =>
                      setValues({
                        ...values,
                        hideCceSurvey: event?.target?.checked,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Duration (min)
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='number'
                    name='duration'
                    id='duration'
                    autoComplete='duration'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    onChange={handleChange}
                    defaultValue={0}
                    value={values.duration}
                  />
                </div>
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Is this a Movement Lesson (This will change how we show the chart for the biosignal. We will mention
                  in the respiration chart that it is inaccurate. At least until we sort out the ML for moving)
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type={'checkbox'}
                    checked={values?.isMovementLesson || false}
                    onChange={(event) =>
                      setValues({
                        ...values,
                        isMovementLesson: event?.target?.checked,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Tags:
                </label>
              </div>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <Select
                  options={tags || []}
                  isMulti={true}
                  value={tags?.filter((el) => values.tags?.includes(el.value))}
                  onChange={(vals) => {
                    setFieldValue(
                      'tags',
                      vals.map((el) => el.value),
                    );
                  }}
                  className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                />
              </div>
            </div>
          </div>
          {!values.importedFromGDocID?.length ? null : (
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='importedFromGDocID' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Google Doc ID
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='importedFromGDocID'
                    id='importedFromGDocID'
                    autoComplete='importedFromGDocID'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    value={values.importedFromGDocID}
                    onChange={handleChange}
                  />
                  {!emptyStringToNull(values.importedFromGDocID) ? null : (
                    <a
                      href={`https://docs.google.com/document/d/${values.importedFromGDocID}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {' '}
                      <Icon path={mdiOpenInNew} size={1} style={{ display: 'inline-block' }} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
            <div>
              <label htmlFor='bulkAudio' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Bulk Audio Upload
              </label>
            </div>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm' style={{ display: 'block' }}>
                <div className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  <p>
                    Note: This feature is experimental. Files must be M4A files, and must be named
                    <strong> exactly </strong>with the &apos;code&apos; of the Audio Node they&apos;ll be attached to
                    (i.e. a file for a node with a code of &apos;TPS1&apos; needs to be named &apos;TPS1.m4a&apos;). It
                    will update the Node with a duration measured from each audio file, as well as the Internet-facing
                    download URL.
                  </p>
                </div>
                <br />
                <FileUploader
                  handleChange={handleUploadDrop}
                  id={'bulkAudio'}
                  name='file'
                  types={['m4a', 'M4A']}
                  multiple={true}
                  hoverTitle={''}
                  maxSize={10}
                  minSize={0}
                  label={'Select or drag one or more audio files here.'}
                />
              </div>
            </div>
          </div>

          <div className='pt-5 pb-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                disabled={isSubmitting}
                type='submit'
                className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
      <LessonChunkNodesList audioChunk={audioLessonChunk} locked={audioLessonChunk.productionStatus === 'PROD'} />
    </div>
  );
};

export default LessonAudioChunksForm;
