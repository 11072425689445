import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { getAllTags } from '../../collections/paths';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { getAllTags, deleteTag } from 'src/collections/tags';
import { confirmAlert } from 'react-confirm-alert';
import { dieIfNullOrUndef } from 'src/utility/GeneralUtilities';

const TagList = () => {
  type TableData = {
    name?: string;
    id?: string;
    description?: string;
    locked?: boolean;
    text?: string;
  };
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.name && item.name.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId;
    });
  }, [searchTerm, tableData]);
  useEffect(() => {
    const prepareTableData = async () => {
      const tags = await getAllTags();
      setTableData(tags);
    };
    prepareTableData().then(() => setIsLoading(false));
  }, []);

  const navigate = useNavigate();

  const onClickDelete = (tagId: string) => {
    confirmAlert({
      title: `Confirm delete path (ID: ${tagId})?`,
      message: 'Are you sure you want to delete this path type? This operation cannot be undone.',
      buttons: [
        {
          label: 'Delete',
          onClick: async () => {
            await deleteTag(tagId);
            window.location.reload();
          },
        },
        {
          label: 'Cancel',
          onClick: () => alert('Never mind then.'),
        },
      ],
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Name
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Text
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Locked
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Value
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Parent Of (Tags)
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((tag) => (
                      <tr key={tag.id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{tag.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{tag.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{tag.text}</div>
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{tag.locked ? 'Locked' : 'Not Locked'}</div>
                            </div>
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <div onClick={() => navigate('/tags/' + tag.id)} style={{ cursor: 'pointer' }}>
                            Edit
                          </div>
                        </td>
                        {tag?.locked ? null : (
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                            <div
                              className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                              onClick={() => onClickDelete(dieIfNullOrUndef(tag.id))}
                            >
                              Delete
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagList;
