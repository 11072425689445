// *******************************************************
// AddLessonModal
// -------------------------------------------------------
// This is a AddLessonModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { UnitType } from '../../modeltypes/unit';
import { getAllUnits } from '../../collections/units';
import SearchBar from '../SearchBar';
import { useAppContext } from '../../contexts/appContext';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface AddUnitModalProps {
  addUnit: (unitId: string) => void;
  unitsIds: string[];
  hide: () => void;
}

const AddUnitModal = ({ hide, unitsIds, addUnit }: AddUnitModalProps) => {
  const [units, setUnits] = useState<UnitType[] | null>(null);

  const { searchTerm } = useAppContext();

  useEffect(() => {
    getAllUnits().then((unitArray) => {
      setUnits(unitArray);
    });
  }, []);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    console.log(units);
    if (!units?.length) return [];
    return units.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchRole = item.id && item.id.match(regex);
      return matchName || matchRole;
    });
  }, [searchTerm, units]);

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[10%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative w-full max-w-2xl h-full md:h-auto'>
        <button className='absolute right-5 top-4 z-10 text-white text-[1.2rem]' onClick={() => hide()}>
          X
        </button>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Add lessons to program </h3>
          </div>
          <div className='px-6 my-2'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <SearchBar />
              <div className='overflow-scroll shadow ring-1 ring-black ring-opacity-5 rounded-lg h-96 bg-gray-100'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-white'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Icon
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Title
                      </th>
                      <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        {/*<span className='sr-only'>Edit</span>*/}
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white '>
                    {filteredItems
                      ?.filter((el) => !unitsIds.includes(el.id))
                      .map((unit) => (
                        <tr key={unit.id} className='hover:bg-stone-200'>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='h-10 w-10 flex-shrink-0'>
                              <img className='h-10 w-10 rounded-full' src={unit.icon || undefined} alt='' />
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='font-medium text-gray-900'>{unit.title}</div>
                          </td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                            <div
                              className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                              onClick={() => addUnit(unit.id || '')}
                            >
                              Add
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='flex justify-end text-right p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600'>
            <button
              onClick={hide}
              type='button'
              className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUnitModal;
