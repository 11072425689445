// *******************************************************
// Incentives List
// -------------------------------------------------------
// This is a Component for listing cohorts and assigned incentives templates
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
// *******************************************
// Component Imports
// -------------------------------------------
// *******************************************
// Hooks Import
// -------------------------------------------
// *******************************************
// Action Imports
// -------------------------------------------
import { getCohortsWithIncentives } from '../collections/cohort';
import { getCompanyById } from '../collections/company';
import { getIncentivesTemplateById } from '../collections/incentiveTemplate';
import { useAppContext } from '../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import SearchBar from './SearchBar';
// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const IncentivesList = () => {
  type TableData = {
    name?: string;
    id?: string;
    incentives: string;
    company?: string;
    incentivesName?: string;
  };

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { searchTerm } = useAppContext();

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.name && item.name.match(regex);
      const matchCompany = item.company && item.company.match(regex);
      const matchIncentive = item.incentivesName && item.incentivesName.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId || matchCompany || matchIncentive;
    });
  }, [searchTerm, tableData]);

  useEffect(() => {
    const prepareTableData = async () => {
      const newTableData: TableData[] = [];
      const cohorts = await getCohortsWithIncentives();
      for (const cohort of cohorts) {
        const data: TableData = {
          name: cohort.name,
          id: cohort.id,
          incentives: cohort.incentives || '',
        };
        const company = await getCompanyById(cohort.company || '');
        data.company = company?.companyName;
        if (cohort.incentives) {
          const incentives = await getIncentivesTemplateById(cohort.incentives);
          data.incentivesName = incentives?.name;
        }

        newTableData.push(data);
      }
      setTableData(newTableData);
    };

    prepareTableData().then(() => setIsLoading(false));
  }, []);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Company
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Cohort
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Incentives
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((cohort) => (
                      <tr key={cohort.id}>
                        <td className='whitespace-nowrap py-4 pl-4 text-sm sm:pl-6 w-[15%]'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{cohort.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{cohort.company}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{cohort.name}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{cohort.incentivesName}</div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <div
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => '/Incentives/' + cohort.incentives}
                          >
                            Edit
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncentivesList;
