// *******************************************************
// CommunicationAnalysis
// -------------------------------------------------------
// This is a Screen for showing list all Communications
// We want to understand who is getting communcation and are they responding to it.

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { format, isThisMonth, isThisWeek, isToday } from 'date-fns';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
import { getAllCommunications } from 'src/collections/communication';
// import { AnalyticsType } from 'src/modeltypes/analytics';
import { CommunicationType } from 'src/modeltypes/communication';
// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

const CommunicationAnalysis = () => {
  // const CommunicationAnalysis = ({ analytics }: { analytics: AnalyticsType[] | undefined }) => {
  // const [communications, setCommunications] = useState<CommunicationType[] | null>(null);
  const [communicationsThisMonth, setCommunicationsThisMonth] = useState<CommunicationType[] | null>(null);
  const [communicationsSent, setCommunicationsSent] = useState<number | null>(null);
  const [communicationsOpened, setCommunicationsOpened] = useState<number | null>(null);
  const [communicationsOpenedThisMonth, setCommunicationsOpenedThisMonth] = useState<number | null>(null);
  const [communicationsOpenedThisWeek, setCommunicationsOpenedThisWeek] = useState<number | null>(null);
  const [communicationsOpenedToday, setCommunicationsOpenedToday] = useState<number | null>(null);

  useEffect(() => {
    getAllCommunications().then(async (fCommunication) => {
      // setCommunications(fCommunication);
      const thisMonth: CommunicationType[] = [];
      const thisWeek: CommunicationType[] = [];
      const today: CommunicationType[] = [];
      const opened = [];

      const f = await fCommunication.map((comm) => {
        if (isThisMonth(comm.sentAt.toDate())) {
          thisMonth.push(comm);
        }
        if (isThisWeek(comm.sentAt.toDate())) {
          thisWeek.push(comm);
        }
        if (isToday(comm.sentAt.toDate())) {
          today.push(comm);
        }
        if (comm.tapped) {
          opened.push(comm);
        }
      });

      console.log('f', f);
      // const thisMonthB = fCommunication.filter(c => c.sentAt && isThisMonth(c.sentAt.toDate()));
      // const thisWeekB = fCommunication.filter(c => c.sentAt && isThisWeek(c.sentAt.toDate()));
      // const todayB = fCommunication.filter(c => c.sentAt && isToday(c.sentAt.toDate()));
      const sent = fCommunication?.length || 0;
      // const opened = fCommunication?.filter(c => c.tapped)?.length
      setCommunicationsThisMonth(
        thisMonth.sort((a, b) => {
          const date1 = a.sentAt.toDate();
          const date2 = b.sentAt.toDate();
          if (date1 && date2) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return date2 - date1;
          }
          return 1;
        }),
      );
      setCommunicationsThisMonth(thisMonth);
      setCommunicationsSent(sent);
      setCommunicationsOpened(opened?.length);
      setCommunicationsOpenedThisMonth(thisMonth?.filter((c) => c?.tapped)?.length);
      setCommunicationsOpenedThisWeek(thisWeek?.filter((c) => c?.tapped)?.length);
      setCommunicationsOpenedToday(today?.filter((c) => c?.tapped)?.length);
    });
  }, []);

  // console.log('communications: ', communications);
  // console.log('analytics:.length ', analytics);

  return (
    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Communication Sent {communicationsSent}
              </th>

              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Communication Opened {communicationsOpened}
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Communication Opened Today {communicationsOpenedToday}
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Communication Opened This Week {communicationsOpenedThisWeek}
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Communication This Month {communicationsThisMonth?.length}
              </th>

              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Communication Opened This Month {communicationsOpenedThisMonth}
              </th>
            </tr>
          </thead>
        </div>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Sent At
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Id
                </th>
                <th scope='col' className='py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6'>
                  Type
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  User Id
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  Tapped
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {communicationsThisMonth?.map((communication) => {
                // console.log('asdfasdf communication: ', communication);

                return (
                  <tr key={communication.id} className='hover:bg-stone-200'>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      {communication.sentAt ? format(communication.sentAt.toDate(), 'hh:mm, MM/dd/yyyy') : '-'}
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{communication.id}</div>
                    </td>

                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>{communication?.type}</div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>{communication?.uid}</div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>{communication?.tapped ? 'Yes' : 'No'}</div>
                    </td>

                    {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='text-gray-500'>
                        <span
                          style={{
                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '250pt',
                          }}
                        >
                          {thisTask?.title} {thisTask?.id}
                        </span>
                      </div>
                    </td>
                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisTask?.taskItems?.length || 0}
                    </td>

                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='ml-4 flex items-center flex-col '>
                        {thisTask?.taskItems?.length ? (
                          <>
                            {thisTask?.taskItems?.map(({ text, minutes, taskType }, idx) => {
                              return (
                                <div key={idx} className='text-gray-500'>
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      width: '250pt',
                                    }}
                                  >
                                    {text} {taskType} - {minutes}minutes
                                  </span>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className='text-gray-500'>-</div>
                        )}
                      </div>
                    </td>
                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisTask?.tags?.length ? (
                        <div>
                          {thisTask?.tags?.length
                            ? thisTask.tags?.map((tag) => (
                                <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                  {tag}
                                </div>
                              ))
                            : 'No Tags'}
                        </div>
                      ) : (
                        <div className='text-gray-500'></div>
                      )}
                    </td>

                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {unit?.tags?.length ? (
                        <div>
                          {unit?.tags?.length
                            ? unit.tags?.map((tag) => (
                                <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                  {tag}
                                </div>
                              ))
                            : 'No Tags'}
                        </div>
                      ) : (
                        <div className='text-gray-500'></div>
                      )}
                    </td>
                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {matchedLessonTotal?.userCount}
                    </td>

                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisLessonHomework?.done?.length}
                    </td>

                    <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                      {thisNotCompletedTasks.length}
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CommunicationAnalysis;
