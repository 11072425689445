// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { UnitId } from '../modeltypes/id';
import { UnitType, UnitTypeBase, UnitTypeBuilder, UnitTypeNew } from '../modeltypes/unit';
import { UNIT_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewUnitId = getNewIdFor<UnitId>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewUnit = addNewFor<UnitId, UnitTypeNew>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewUnit = createNewFor<UnitId, UnitTypeBuilder>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewUnit = setNewFor<UnitId, UnitTypeNew>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUnitRefById = getRefByIdFor<UnitId>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUnitById = getByIdFor<UnitId, UnitType>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUnitsByIdArray = getByIdArrayFor<UnitId, UnitType>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllUnits = getAllFor<UnitType>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUnitsByQuery = getByQueryFor<UnitType>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllUnitsQuery = getAllQueryFor(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateUnit = updateDocumentFor<UnitId, UnitTypeBase>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteUnit = deleteDocumentFor<UnitId>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchUnitIdSet = watchIdSetFor<UnitId>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUnitType = getValidateTypeFor<UnitType>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUnitTypeBase = getValidateTypeBaseFor<UnitTypeBase>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUnitTypeBuilder = getValidateTypeBuilderFor<UnitTypeBuilder>(UNIT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUnitTypeNew = getValidateTypeNewFor<UnitType>(UNIT_TABLE_NAME);
