import {
  collection,
  documentId,
  getDocs,
  getUserLastLoginDate,
  IdFactory,
  query,
  updateDoc,
  where,
} from '../models/dalaccess';
import { getAccountRefById, getAccountsByQuery, getAllAccountsInRole, validateAccountType } from './account';
import {
  addNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  prepareChunks,
  updateDocumentFor,
} from './shared';
import {
  canParseToInt,
  DefaultMap,
  dieIfNullOrUndef,
  dumpDebugCollections,
  idsToStrings,
  idToString,
  notNullish,
  objectHasOwnProperty,
  validateObject,
} from '../utility/GeneralUtilities';
import { CasualUser, UserType, UserTypeBase, UserTypeBuilder, UserTypeNew } from '../modeltypes/user';
import { z } from 'zod';
import { CohortId, CompanyId, UserId } from '../modeltypes/id';
import { CALENDAR_TABLE_NAME, USER_TABLE_NAME } from './tableName';
import { userTypeSchema } from '../modelschemas/user';
import { AccountRole, AccountType } from '../modeltypes/account';
import { UserDetailsType } from '../modeltypes/composed';
import { IncentiveType } from '../modeltypes/incentive';
import { CalendarType } from '../modeltypes/calendar';
import { getIncentivesByQuery, validateIncentiveType } from './incentive';
import { UserJourneyType } from '../modeltypes/userJourney';
import { getUserJourneysByQuery } from './userJourney';

// This is commented out because User is somewhat special in that the ID has to
// match the ID from the Firebase Authentication system, so we should NEVER be
// generating a new ID for a User record.
// export const getNewUserId = getNewIdFor<UserId>(USER_TABLE_NAME);

// addNew => Creates a new document, written to the database, with the given data (including `id`)
// noinspection JSUnusedGlobalSymbols
export const addNewUser = addNewFor<UserId, UserTypeNew>(USER_TABLE_NAME);

// createNew => Creates a new object, locally, with an ID, but does NOT hit/store on the DB
// This is commented out because User is somewhat special in that the ID has to
// match the ID from the Firebase Authentication system, so we should NEVER be
// generating a new ID for a User record.
// export const createNewUser = createNewFor<UserId, UserTypeBuilder>(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUserRefById = getRefByIdFor<UserId>(USER_TABLE_NAME);

export const getUserById = getByIdFor<UserId, UserType>(USER_TABLE_NAME);

export const getUsersByIdArray = getByIdArrayFor<UserId, UserType>(USER_TABLE_NAME);

export const _getAllUsers = getAllFor<UserType>(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllUsersQuery = getAllQueryFor(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getUsersByQuery = getByQueryFor<UserType>(USER_TABLE_NAME);

const _updateUser = updateDocumentFor<UserId, UserTypeBase>(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteUser = deleteDocumentFor<UserId>(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserTypeBase = getValidateTypeBaseFor<UserTypeBase>(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserTypeBuilder = getValidateTypeBuilderFor<UserTypeBuilder>(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserType = getValidateTypeFor<UserType>(USER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateUserTypeNew = getValidateTypeNewFor<UserTypeNew>(USER_TABLE_NAME);

// This wraps `_updateUser` and exists to fix up our `age`-string problem.
export async function updateUser(id: UserId, updateData: UserTypeBase) {
  if (objectHasOwnProperty(updateData, 'age')) {
    const ageVal = (updateData as Record<string, unknown>)['age'];
    if (typeof ageVal === 'string') {
      if (canParseToInt(ageVal)) {
        const ageInt = parseInt(ageVal);
        const newData = { ...updateData, age: ageInt };
        return _updateUser(id, newData);
      }
    }
  }
  return _updateUser(id, updateData);
}

// 'Temporary' wrapping of _getAllUsers to do more rich validations.
export async function getAllUsers(): Promise<UserType[]> {
  const users = await _getAllUsers();
  const unrecognizedKeys = new Set<string>();
  const otherIssues = new DefaultMap<UserType['id'], z.ZodIssue[]>(() => new Array<z.ZodIssue>());
  const sanitized = users.map((u) => validateObject(u, userTypeSchema, unrecognizedKeys, otherIssues));
  dumpDebugCollections<UserId, UserType>(unrecognizedKeys, otherIssues, USER_TABLE_NAME);
  return sanitized;
}

export const getUserIdsByCompanyId = async (companyId: CompanyId): Promise<UserId[]> => {
  const users = await getAccountsByQuery(where('companyId', '==', companyId));
  const rv = users.map((u) => IdFactory.UserIdFromString(u.uid)).filter(notNullish);
  return rv;
};

export const getUserIdsByCohortId = async (cohortId: CohortId, isTestCohort?: boolean): Promise<UserId[]> => {
  const users = await getAccountsByQuery(where('cohort', '==', cohortId));
  const rv = users
    .filter((u) => !u.role || u.role === 'user')
    .filter((u) => (!isTestCohort && !u.isTestUser) || isTestCohort)
    .map((u) => u.uid)
    .filter(notNullish);
  return rv;
};

// noinspection JSUnusedGlobalSymbols
export const removeUserFromCompany = async (userId: UserId | null): Promise<void> => {
  if (userId === null) {
    return;
  }
  const docRef = dieIfNullOrUndef(getAccountRefById(idToString(userId)));
  await updateDoc(docRef, {
    companyId: null,
    cohort: null,
  });
};

// This function should do the flattening itself... no point in making all the consumers flatten themselves.
// ... it's not as if they're going to have to wait any longer.
export const getUserDetailsByIds = async (ids: UserId[]) => {
  const chunks = prepareChunks<UserId>(ids);

  // Set up hash maps so that we only iterate these collections once.
  const usersById = new Map<UserId, UserType>();
  const accountsById = new Map<UserId, AccountType>();
  const incentivesById = new Map<string, IncentiveType>();
  const calendarById = new Map<string, CalendarType[]>();
  const userJourneyById = new Map<string, UserJourneyType>();
  const lastLoginDatesById = new Map<UserId, Date>();

  await Promise.all(
    chunks.map(async (chunkOfIds) => {
      const userQuery = where(documentId(), 'in', idsToStrings(chunkOfIds));

      const qCalendar = query(collection(CALENDAR_TABLE_NAME), userQuery);

      const accountsDocs = await getAccountsByQuery(userQuery);
      const incentivesDocs = await getIncentivesByQuery(userQuery);
      const usersDocs = await getUsersByQuery(userQuery);
      const userJourneys = await getUserJourneysByQuery(userQuery);
      const calendarDocs = await getDocs(qCalendar);
      const loginDates = await getUserLastLoginDate({ ids: idsToStrings(chunkOfIds).map((id) => ({ uid: id })) });
      accountsDocs.forEach((a) => {
        const acct = validateAccountType(a, false, true);
        accountsById.set(a.id, acct);
      });

      usersDocs.forEach((u) => {
        const user = validateUserType(u, false, true);
        usersById.set(user.id, user);
      });

      userJourneys.forEach((uj) => {
        userJourneyById.set(uj.id, uj);
      });

      incentivesDocs.forEach((incentive) => {
        const inc = validateIncentiveType(incentive, false, true);
        incentivesById.set(inc.id, inc);
      });

      calendarDocs.forEach((calendar) => {
        const fetchedCalendar: CalendarType[] = [];
        const data = calendar.data();
        Object.keys(data).forEach((dayId) =>
          fetchedCalendar.push({
            id: dayId,
            value: data[dayId],
          }),
        );
        calendarById.set(calendar.id, fetchedCalendar);
      });

      // There has GOT to be a better way to do this, but...
      loginDates.data.dates.forEach(({ lastLogin, uid }) => {
        lastLoginDatesById.set(uid, new Date(lastLogin));
      });
    }),
  );

  // Now use those maps to assemble our return array in one go.
  const usersDetails: UserDetailsType[] = [];

  [...usersById.keys()].forEach((id: UserId) => {
    const rv = {
      account: accountsById.get(id),
      incentives: incentivesById.get(id) || undefined,
      user: usersById.get(id),
      calendar: calendarById.get(id),
      lastLoginDate: lastLoginDatesById.get(id),
      userJourney: userJourneyById.get(id),
      uid: id,
    };

    // Need a schema here to enforce defined-ness, but for now...
    usersDetails.push(rv as UserDetailsType);
  });

  return usersDetails;
};

export const getCasualUsers = async (): Promise<CasualUser[]> => {
  const accounts = await getAllAccountsInRole(AccountRole.User);
  const uids = accounts.map((a) => (a.uid ? IdFactory.UserIdFromString(a.uid) : undefined)).filter(notNullish);
  const users = await getUsersByIdArray(uids, true);
  return users.map((u): CasualUser => {
    return {
      uid: u.id,
      firstName: u.firstName,
      lastName: u.lastName,
    };
  });
};
