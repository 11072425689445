import { addDays, format, startOfToday } from 'date-fns';
import { AfterSessionType } from '../../collections/afterSessionSurvey';
import { DailyDiaryType } from '../../modeltypes/dailyDiary';

export type ShortStats = {
  name: string;
  stat: string | number;
};

export type DetailedStats = {
  name: string;
  stat: string | number;
  previousStat: string | number | null;
  changeType?: 'increase' | 'decrease' | 'same';
  change?: string | number;
};

const averageOfTwo = (first?: number, second?: number) => ((first || 0) + (second || 0)) / 2;

// What about number.toFixed(2)?
export const roundToTwo = (number?: number) => Math.round((number || 0) * 100) / 100;

export const prepareOverview = ({
  users,
  started,
  ends,
}: {
  users: number;
  started?: Date;
  ends?: Date;
}): ShortStats[] => {
  return [
    {
      name: 'In Cohort',
      stat: users,
    },
    {
      name: 'Started',
      stat: started ? format(started, 'dd MMMM yyyy') : 'not set',
    },
    {
      name: 'Ends',
      stat: ends ? format(ends, 'dd MMMM yyyy') : started ? format(addDays(started, 15), 'dd MMMM yyyy') : 'not set',
    },
  ];
};

export const prepareStatistics = ({
  lessonCompleteNow,
  lessonCompletedBefore,
  totalSessionsNow,
  sessionsBefore,
  ratings,
}: {
  lessonCompleteNow: number;
  lessonCompletedBefore: number;
  totalSessionsNow: number;
  sessionsBefore: number;
  ratings?: number;
}): DetailedStats[] => {
  const lessonChange = lessonCompletedBefore
    ? Math.ceil(((lessonCompleteNow - lessonCompletedBefore) / lessonCompletedBefore) * 100)
    : 0;
  const sessionChange = sessionsBefore ? Math.ceil(((totalSessionsNow - sessionsBefore) / sessionsBefore) * 100) : 0;
  return [
    {
      name: 'Lesson Completed',
      stat: lessonCompleteNow,
      previousStat: lessonCompletedBefore,
      changeType: lessonChange > 0 ? 'increase' : lessonChange < 0 ? 'decrease' : 'same',
      change: `${lessonChange}%`,
    },
    {
      name: 'Total Sessions',
      stat: totalSessionsNow,
      previousStat: sessionsBefore,
      changeType: sessionChange > 0 ? 'increase' : sessionChange < 0 ? 'decrease' : 'same',
      change: `${sessionChange}%`,
    },
    {
      name: 'Ratings',
      stat: roundToTwo(ratings),
      previousStat: null,
    },
  ];
};

export const prepareInAppSurveys = ({
  dailyDiaryNow,
  dailyDiaryBefore,
  afterSessionNow,
  afterSessionBefore,
}: {
  dailyDiaryNow: number;
  dailyDiaryBefore: number;
  afterSessionNow: number;
  afterSessionBefore: number;
}): DetailedStats[] => {
  const asChange = afterSessionBefore
    ? Math.ceil(((afterSessionNow - afterSessionBefore) / afterSessionBefore) * 100)
    : 0;
  const ddChange = dailyDiaryBefore ? Math.ceil(((dailyDiaryNow - dailyDiaryBefore) / dailyDiaryBefore) * 100) : 0;
  return [
    {
      name: 'Daily Question Submitted',
      stat: dailyDiaryNow,
      previousStat: dailyDiaryBefore,
      changeType: ddChange > 0 ? 'increase' : ddChange < 0 ? 'decrease' : 'same',
      change: `${ddChange}%`,
    },
    {
      name: 'Skill Questions Submitted',
      stat: afterSessionNow,
      previousStat: afterSessionBefore,
      changeType: asChange > 0 ? 'increase' : asChange < 0 ? 'decrease' : 'same',
      change: `${asChange}%`,
    },
    // {
    //   name: 'Daily Hits Completed',
    //   stat: 5,
    //   previousStat: 2,
    //   changeType: 'increase',
    //   change: '150%',
    // }
  ];
};

export const prepareDailyDiaries = (dailyDiaries: DailyDiaryType[]): DetailedStats[] => {
  const focusNow = dailyDiaries
    .filter(({ skipped, endTime }) => !skipped && endTime)
    .map(({ answers }) => averageOfTwo(answers?.overwhelm, answers?.distractability))
    .reduce((prev, next) => averageOfTwo(prev, next), 0);
  const focusBefore = dailyDiaries
    .filter(({ endTime, skipped }) => !skipped && endTime && endTime.toDate() < startOfToday())
    .map(({ answers }) => averageOfTwo(answers?.overwhelm, answers?.distractability))
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const moodBefore = dailyDiaries
    .filter(({ endTime, skipped }) => !skipped && endTime && endTime.toDate() < startOfToday())
    .map(({ answers }) => averageOfTwo(answers?.happiness, answers?.depression))
    .reduce((prev, next) => averageOfTwo(prev, next), 0);
  const moodNow = dailyDiaries
    .filter(({ skipped, endTime }) => !skipped && endTime)
    .map(({ answers }) => averageOfTwo(answers?.happiness, answers?.depression))
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const sleepBefore = dailyDiaries
    .filter(({ endTime, skipped }) => !skipped && endTime && endTime.toDate() < startOfToday())
    .map(({ answers }) => averageOfTwo(answers?.restfulness, answers?.latency))
    .reduce((prev, next) => averageOfTwo(prev, next), 0);
  const sleepNow = dailyDiaries
    .filter(({ skipped, endTime }) => !skipped && endTime)
    .map(({ answers }) => averageOfTwo(answers?.restfulness, answers?.latency))
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const sleepChange = roundToTwo(sleepNow) - roundToTwo(sleepBefore);
  const moodChange = roundToTwo(moodNow) - roundToTwo(moodBefore);
  const focusChange = roundToTwo(focusNow) - roundToTwo(focusBefore);
  return [
    {
      name: 'Sleep',
      stat: roundToTwo(sleepNow),
      previousStat: roundToTwo(sleepBefore),
      changeType: sleepChange > 0 ? 'increase' : sleepChange < 0 ? 'decrease' : 'same',
      change: `${roundToTwo(sleepChange)}`,
    },
    {
      name: 'Mood',
      stat: roundToTwo(moodNow),
      previousStat: roundToTwo(moodBefore),
      changeType: moodChange > 0 ? 'increase' : moodChange < 0 ? 'decrease' : 'same',
      change: `${moodChange.toFixed(2)}`,
    },
    {
      name: 'Focus',
      stat: roundToTwo(focusNow),
      previousStat: roundToTwo(focusBefore),
      changeType: focusChange > 0 ? 'increase' : focusChange < 0 ? 'decrease' : 'same',
      change: `${roundToTwo(focusChange)}`,
    },
  ];
};

export const prepareSkills = (
  afterSessions: AfterSessionType[], //: { equanimityNow: number; equanimityBefore: number; concentrationNow: number; concentrationBefore: number; clarityNow: number; clarityBefore: number }
): DetailedStats[] => {
  const clarityBefore = afterSessions
    .filter((val) => val && !val.skipped && val.endTime && val.endTime.toDate() < startOfToday())
    .map(({ answers }) => answers?.sensory_clarity)
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const clarityNow = afterSessions
    .filter((val) => val && !val.skipped && val.endTime)
    .map(({ answers }) => answers?.sensory_clarity)
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const concentrationBefore = afterSessions
    .filter((val) => val && !val.skipped && val.endTime && val.endTime.toDate() < startOfToday())
    .map(({ answers }) => answers?.concentration)
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const concentrationNow = afterSessions
    .filter((val) => val && !val.skipped && val.endTime)
    .map(({ answers }) => answers?.concentration)
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const equanimityBefore = afterSessions
    .filter((val) => val && !val.skipped && val.endTime && val.endTime.toDate() < startOfToday())
    .map(({ answers }) => answers?.equanimity)
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const equanimityNow = afterSessions
    .filter((val) => val && !val.skipped && val.endTime)
    .map(({ answers }) => answers?.equanimity)
    .reduce((prev, next) => averageOfTwo(prev, next), 0);

  const equanimityChange = roundToTwo(equanimityNow) - roundToTwo(equanimityBefore);
  const concentrationChange = roundToTwo(concentrationNow) - roundToTwo(concentrationBefore);
  const clarityChange = roundToTwo(clarityNow) - roundToTwo(clarityBefore);

  return [
    {
      name: 'Equanimity',
      stat: roundToTwo(equanimityNow),
      previousStat: roundToTwo(equanimityBefore),
      changeType: equanimityChange > 0 ? 'increase' : equanimityChange < 0 ? 'decrease' : 'same',
      change: roundToTwo(equanimityChange),
    },
    {
      name: 'Concentration',
      stat: roundToTwo(concentrationNow),
      previousStat: roundToTwo(concentrationBefore),
      changeType: concentrationChange > 0 ? 'increase' : concentrationChange < 0 ? 'decrease' : 'same',
      change: roundToTwo(concentrationChange),
    },
    {
      name: 'Clarity',
      stat: roundToTwo(clarityNow),
      previousStat: roundToTwo(clarityBefore),
      changeType: clarityChange > 0 ? 'increase' : clarityChange < 0 ? 'decrease' : 'same',
      change: roundToTwo(clarityChange),
    },
  ];
};
