import { AudioNodeType, TrackType } from '../../../modeltypes/audioNode';
import { LessonAudioChunkType } from '../../../modeltypes/lessonAudioChunk';

const REQUIRED_KEYS_CHUNK: Array<keyof LessonAudioChunkType> = ['title'];

const REQUIRED_KEYS_AUDIO_NODE: Array<keyof AudioNodeType> = ['title', 'code', 'nodeType'];
const REQUIRED_KEYS_DECISION: Array<keyof AudioNodeType> = ['singleTap', 'doubleTap', 'audioUrl'];
const REQUIRED_KEYS_STORY: Array<keyof AudioNodeType> = ['audioUrl'];
const REQUIRED_KEYS_SWITCH: Array<keyof AudioNodeType> = ['subType', 'switchOptions'];

const CONNECTION_KEYS: Array<keyof AudioNodeType> = ['nextSubsection', 'tripleTap', 'doubleTap', 'singleTap'];

export const validateLessonAudioChunk = (
  audioChunk: LessonAudioChunkType | null,
  audioNodes: AudioNodeType[],
): [string[], string[]] => {
  const errors: string[] = [];
  const warnings: string[] = [];

  if (!audioChunk) {
    errors.push('CRITICAL - no audio chunk');
    return [errors, warnings];
  }

  for (const key of REQUIRED_KEYS_CHUNK) {
    if (!audioChunk[key]) {
      errors.push(`LESSON - Missing ${key}`);
    }
  }

  if (audioNodes.length === 0) {
    errors.push(`LESSON - No audio Nodes attached!`);
  } else {
    const nodesWithoutNext: string[] = [];
    for (const audioNode of audioNodes) {
      for (const key of REQUIRED_KEYS_AUDIO_NODE) {
        if (!audioNode[key]) {
          errors.push(`AUDIO NODE - ${audioNode.id} - Missing ${key}`);
        }
      }

      if (audioNode.toCheck) {
        errors.push(
          `AUDIO NODE - ${audioNode.id} - Required check of audio node values! (if you did it, you have to update toCheck flag in the form)`,
        );
      }

      for (const key of CONNECTION_KEYS) {
        const nextNodeId = audioNode[key] as string | null;
        if (nextNodeId && !audioChunk.audioNodes.includes(nextNodeId)) {
          errors.push(
            `AUDIO NODE ${audioNode.nodeType} - ${audioNode.id} - node id under ${key} , is not part of the chunk!`,
          );
        }
      }

      if (audioNode?.nodeType === TrackType.DECISION) {
        for (const key of REQUIRED_KEYS_DECISION) {
          const value = audioNode[key] as string;
          if (!value) {
            errors.push(`AUDIO NODE DECISION - ${audioNode.id} - Missing ${key}`);
          }
        }
      } else if (audioNode?.nodeType === TrackType.STORY) {
        for (const key of REQUIRED_KEYS_STORY) {
          if (!audioNode[key]) {
            errors.push(`AUDIO NODE STORY - ${audioNode.id} - Missing ${key}`);
          }
        }
        if (!audioNode.nextSubsection) {
          nodesWithoutNext.push(audioNode.id);
        }
      } else if (audioNode?.nodeType === TrackType.SWITCH) {
        for (const key of REQUIRED_KEYS_SWITCH) {
          if (!audioNode[key]) {
            errors.push(`AUDIO NODE SWITCH - ${audioNode.id} - Missing ${key}`);
          }
        }
        if (audioNode.switchOptions) {
          for (const option of audioNode.switchOptions) {
            if (!option.type) {
              errors.push(`AUDIO NODE SWITCH - ${audioNode.id} - Missing type for one of the options`);
            }
            if (!option.nextNode) {
              errors.push(`AUDIO NODE SWITCH - ${audioNode.id} - Missing nextNode for one of the options`);
            }
          }
        }
      }
    }
    if (nodesWithoutNext.length > 1) {
      errors.push(
        `AUDIO NODE STORY - ${nodesWithoutNext.join(
          ', ',
        )} - More than 1 node without connection (to fix, reduce to one)!`,
      );
    }
  }

  return [errors, warnings];
};
