// *******************************************************
// Not Auth Router
// -------------------------------------------------------
// This is a router for not auth user
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// *******************************************
// Component Imports
// -------------------------------------------
import SignIn from '../pages/SignInPage';
import InviteFriendsPage from '../pages/inviteFriends/InviteFriendsPage';
import MobileRedirect from 'src/pages/MobileRedirect';
import UnsubscribePage from '../pages/unsubscribe/UnsubscribePage';
// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
const NotAuthRouter = () => {
  return (
    <Routes>
      <Route path={'/'} element={<SignIn />} />
      <Route path={'invite/:token'} element={<InviteFriendsPage />} />
      <Route path={'mobile/*'} element={<MobileRedirect />} />
      <Route path={'unsubscribe/:token'} element={<UnsubscribePage />} />
      <Route path={'/*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default NotAuthRouter;
