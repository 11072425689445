import { UnitType } from '../../../modeltypes/unit';
import { ChallengeType } from '../../../modeltypes/challenges';
import { LessonType } from '../../../modeltypes/lesson';
import { TaskType } from '../../../modeltypes/tasks';
import { LessonAudioChunkType } from '../../../modeltypes/lessonAudioChunk';

const REQUIRED_KEYS_UNIT: Array<keyof UnitType> = ['title'];

export const validateUnit = (
  unit: UnitType | null,
  elements: Array<ChallengeType | LessonType | TaskType | LessonAudioChunkType>,
  to: 'DEV' | 'PROD',
) => {
  const errors = [];

  if (!unit) {
    errors.push(`CRITICAL UNIT - missing unit`);
    return errors;
  }

  for (const key of REQUIRED_KEYS_UNIT) {
    if (!unit[key]) {
      errors.push(`UNIT - Missing ${key}`);
    }
  }

  if (elements.length === 0) {
    errors.push(`ELEMENT - No elements attached!`);
  } else {
    for (const element of elements) {
      if (to === 'PROD' && element.productionStatus !== 'PROD') {
        const unitElement = unit.elements.find((el) => el.id === element.id);
        errors.push(
          `ELEMENT - ${element.title || element.id} ${unitElement?.pathType || 'element'} is not set to PROD`,
        );
      } else if (to === 'DEV' && !element.productionStatus) {
        const unitElement = unit.elements.find((el) => el.id === element.id);
        errors.push(
          `ELEMENT - ${element.title || element.id} ${
            unitElement?.pathType || 'element'
          } needs to have at least DEV status`,
        );
      }
    }
  }
  return errors;
};
