// *******************************************************
// QuestionUpdateModal
// -------------------------------------------------------
// This is a QuestionUpdateModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { NodeType } from '../../../modeltypes/node';
import { useState } from 'react';
import { updateNode } from '../../../collections/nodes';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface QuestionUpdateModalProps {
  hide: (shouldRefresh?: boolean) => void;
  node: NodeType;
  updateNodes: (nodeIds: string[]) => void;
}

const QuestionUpdateModal = ({ node, hide, updateNodes }: QuestionUpdateModalProps) => {
  const [question, setQuestion] = useState(node.question || '');
  const [isPending, setIsPending] = useState(false);

  const onClick = async () => {
    if (question !== (node.question || '') && question.trim().length > 0) {
      setIsPending(true);
      await updateNode(node.id, { question });
      updateNodes([node.id]);
    }
    hide();
  };

  return (
    <>
      <div>
        <label htmlFor='cover-photo' className='block text-lg font-medium dark:text-white mb-4'>
          Question
        </label>
        <input
          className='w-full px-2.5 py-1 rounded-md border-1 border-gray-300 border'
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder={'Quiz question'}
        />
      </div>
      <div className='flex justify-end text-right pt-2 pr-2  rounded-b border-t border-gray-200 dark:border-gray-600'>
        <button
          disabled={isPending}
          onClick={onClick}
          type='button'
          className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        >
          {question === (node.question || '') || question.trim().length === 0
            ? 'Close'
            : isPending
            ? 'Updating...'
            : 'Update'}
        </button>
      </div>
    </>
  );
};

export default QuestionUpdateModal;
