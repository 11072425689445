import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import { Pages } from '../../enums/pages';
import { secondsDurationToHmsTolerant } from '../../utility/GeneralUtilities';
import { ChapterType } from '../../modeltypes/chapter';
import { AudioNodeType } from '../../modeltypes/audioNode';

export const LessonChapterAudioNodeList = ({
  chapter,
  audioNodes,
  checkIfDetached,
  deleteNode,
  choppingEnabled,
}: {
  chapter: ChapterType;
  audioNodes: AudioNodeType[];
  checkIfDetached: (node: AudioNodeType) => boolean;
  deleteNode: (node: AudioNodeType) => Promise<void>;
  choppingEnabled: boolean;
}) => {
  const navigate = useNavigate();

  return !chapter || !audioNodes.length ? null : (
    <>
      {audioNodes.map((node: AudioNodeType, nodeIdx: number) => {
        const isDetached = checkIfDetached(node);
        return (
          <tr key={node.id} className={`${nodeIdx === 0 ? 'border-gray-300' : 'border-gray-200'} border-t`}>
            {choppingEnabled ? (
              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                <div />
              </td>
            ) : null}
            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>{node.id}</td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{node.code || '<NO CODE SET>'}</td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{node.title}</td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              {secondsDurationToHmsTolerant(node.duration)}
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              {secondsDurationToHmsTolerant(node.silenceDuration)} {node.silenceFixedLen ? '(F)' : ''}
            </td>

            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 overflow-hidden truncate ... max-w-[100px]'>
              <a target='_blank' rel='noopener noreferrer' href={node.audioUrl || ''}>
                {node.audioUrl}
              </a>
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{node.nodeType}</td>
            <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
              <div
                className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer'
                onClick={() => {
                  const thisRoute = `${window.location.pathname}/${Pages.AudioNode}/${node.id}/${chapter.id}`;
                  navigate(thisRoute);
                }}
              >
                Edit
              </div>
            </td>
            <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
              <button
                disabled={!isDetached}
                className={`text-indigo-600 hover:text-indigo-900 ${isDetached ? 'opacity-100' : 'opacity-25'}`}
                onClick={() => {
                  deleteNode(node);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};
