import React, { useEffect, useState } from 'react';
import './App.css';
import Sidebar from './components/Sidebar';
import './main.css';
import './firebase/firebaseConfig';
import { useAppContext } from './contexts/appContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase/firebaseConfig';
import NotAuthRouter from './components/NotAuthRouter';
import { HelmetProvider } from 'react-helmet-async';
import { getAccountRefById } from './collections/account';
import { onSnapshot, Unsubscribe } from './models/dalaccess';
import Spinner from './components/Spinner';
import ScrollToTop from './components/ScrollToTop';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { dieIfNullOrUndef, emptyCallback } from './utility/GeneralUtilities';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const App = () => {
  const context = useAppContext();
  const [authUser, authLoading, authError] = useAuthState(auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let unsubscribe: Unsubscribe;
    if (!authLoading) {
      if (authUser) {
        unsubscribe = onSnapshot(dieIfNullOrUndef(getAccountRefById(authUser.uid)), (doc) => {
          const account = doc.data();
          if (!account || !account.role) {
            auth.signOut().then(emptyCallback);
          } else {
            context.setRole(account.role);
            context.setUser(authUser);
          }
          setLoading(false);
        });
      } else {
        context.setUser(null);
        setLoading(false);
      }
      if (authError) {
        context.setError(authError.message);
        setLoading(false);
      }
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading, authUser, authError]);

  if (loading || authLoading) {
    return (
      <div className='flex h-[100vh] justify-center items-center' role='status'>
        <Spinner />
      </div>
    );
  }

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          {...{
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: false,
            theme: 'colored',
          }}
        />
        <>
          <ScrollToTop />
          {context.user ? <Sidebar /> : <NotAuthRouter />}
        </>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
