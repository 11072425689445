// *******************************************************
// User List
// -------------------------------------------------------
// This is a Component for listing users
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { format } from 'date-fns';
import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
export type UserTableData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: Date;
  cohort: string | null;
};

interface UserListProps {
  users: UserTableData[];
  removeUser: (id: string) => void;
  selectedUsers: UserTableData[];
  selectUser: (user: UserTableData) => void;
}

const CompanyUserList = ({ users, removeUser, selectUser, selectedUsers }: UserListProps) => {
  const [sortFilter, setSortFilter] = useState<{ key: keyof UserTableData; order: 'asc' | 'desc' }>();

  const sortedItems = React.useMemo(() => {
    if (!users?.length) return [];
    return users.sort((a, b) => {
      if (!sortFilter || !a[sortFilter.key]) {
        return 0;
      }
      if (sortFilter.key === 'createdAt') {
        if (sortFilter.order === 'desc') {
          return a[sortFilter.key].getTime() - b[sortFilter.key].getTime();
        }
        if (sortFilter.order === 'asc') {
          return b[sortFilter.key].getTime() - a[sortFilter.key].getTime();
        }
      }
      return 0;
    });
  }, [users, sortFilter]);

  const sortRequest = (fieldName: keyof UserTableData) => {
    let order: 'asc' | 'desc' = 'asc';
    if (sortFilter && sortFilter.key === fieldName && sortFilter.order === 'asc') {
      order = 'desc';
    }
    setSortFilter({
      key: fieldName,
      order: order,
    });
  };

  return (
    <div className='flex flex-col'>
      <div className='-my-2 overflow-x-auto '>
        <div className='inline-block min-w-full py-2 align-middle'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <tr>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <span className='sr-only'>Edit</span>
                  </th>
                  <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                    First Name
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Last Name
                  </th>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                    onClick={() => sortRequest('createdAt')}
                  >
                    <p className='group inline-flex'>
                      Created At
                      <span
                        className={`${sortFilter?.key !== 'createdAt' && 'invisible'} ${
                          sortFilter?.order === 'asc' && 'rotate-180'
                        } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                      >
                        <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    </p>
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Email
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Cohort
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <span className='sr-only'>Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {sortedItems?.map((user) => (
                  <tr key={user.id}>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <input
                        type={'checkbox'}
                        defaultChecked={selectedUsers.map((el) => el.id).includes(user.id)}
                        checked={selectedUsers.map((el) => el.id).includes(user.id)}
                        onClick={() => selectUser(user)}
                      />
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='flex items-center'>
                        <div className='ml-4'>
                          <div className='font-medium text-gray-900'>{user.firstName}</div>
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='text-gray-900'>{user.lastName}</div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='text-gray-900'>
                        {user.createdAt ? format(user.createdAt, 'dd MMM yyyy, hh:mm aaa') : '-'}
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='text-gray-900'>{user.email}</div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='text-gray-900'>{user.cohort || '-'}</div>
                    </td>
                    <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                      <div
                        className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                        onClick={() => removeUser(user.id)}
                      >
                        Remove
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyUserList;
