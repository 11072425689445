// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { LearningGameId } from '../modeltypes/id';
import {
  LearningGameType,
  LearningGameTypeBase,
  LearningGameTypeBuilder,
  LearningGameTypeNew,
} from '../modeltypes/learningGames';
import { LEARNING_GAME_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewLearningGameId = getNewIdFor<LearningGameId>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewLearningGame = addNewFor<LearningGameId, LearningGameTypeNew>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewLearningGame = createNewFor<LearningGameId, LearningGameTypeBuilder>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewLearningGame = setNewFor<LearningGameId, LearningGameTypeNew>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLearningGameRefById = getRefByIdFor<LearningGameId>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLearningGameById = getByIdFor<LearningGameId, LearningGameType>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLearningGamesByIdArray = getByIdArrayFor<LearningGameId, LearningGameType>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLearningGames = getAllFor<LearningGameType>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLearningGamesByQuery = getByQueryFor<LearningGameType>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLearningGamesQuery = getAllQueryFor(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateLearningGame = updateDocumentFor<LearningGameId, LearningGameTypeBase>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteLearningGame = deleteDocumentFor<LearningGameId>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchLearningGameIdSet = watchIdSetFor<LearningGameId>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLearningGameType = getValidateTypeFor<LearningGameType>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLearningGameTypeBase = getValidateTypeBaseFor<LearningGameTypeBase>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLearningGameTypeBuilder =
  getValidateTypeBuilderFor<LearningGameTypeBuilder>(LEARNING_GAME_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLearningGameTypeNew = getValidateTypeNewFor<LearningGameType>(LEARNING_GAME_TABLE_NAME);
