// *******************************************************
// NotificationsPage
// -------------------------------------------------------
// This is a NotificationsPage
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { Pages } from '../../enums/pages';
import { useNavigate } from 'react-router-dom';
import NotificationsList from '../../components/notifications/NotificationsList';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const NotificationsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Notifications</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            onClick={() => {
              navigate(`/${Pages.Notifications}/new`);
            }}
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Add Notification
          </button>
        </div>
      </div>
      <NotificationsList />
    </>
  );
};

export default NotificationsPage;
