import { FUNCTION_RUNS_TABLE_NAME } from './tableName';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { FunctionRunId } from 'src/modeltypes/id';
import {
  FunctionRunType,
  FunctionRunTypeBase,
  FunctionRunTypeBuilder,
  FunctionRunTypeNew,
} from '../modeltypes/functionRun';

// noinspection JSUnusedGlobalSymbols
export const getNewFunctionRunId = getNewIdFor<FunctionRunId>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewFunctionRun = addNewFor<FunctionRunId, FunctionRunTypeNew>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewFunctionRun = createNewFor<FunctionRunId, FunctionRunTypeBuilder>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getFunctionRunRefById = getRefByIdFor<FunctionRunId>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getFunctionRunById = getByIdFor<FunctionRunId, FunctionRunType>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getFunctionRunsByIdArray = getByIdArrayFor<FunctionRunId, FunctionRunType>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllFunctionRuns = getAllFor<FunctionRunType>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllFunctionRunsQuery = getAllQueryFor(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getFunctionRunsByQuery = getByQueryFor<FunctionRunType>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateFunctionRun = updateDocumentFor<FunctionRunId, FunctionRunTypeBase>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteFunctionRun = deleteDocumentFor<FunctionRunId>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchFunctionRunIdSet = watchIdSetFor<FunctionRunId>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateFunctionRunType = getValidateTypeFor<FunctionRunType>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateFunctionRunTypeBase = getValidateTypeBaseFor<FunctionRunTypeBase>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateFunctionRunTypeBuilder =
  getValidateTypeBuilderFor<FunctionRunTypeBuilder>(FUNCTION_RUNS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateFunctionRunTypeNew = getValidateTypeNewFor<FunctionRunTypeNew>(FUNCTION_RUNS_TABLE_NAME);
