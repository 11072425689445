import { addDays, format, getWeek, startOfDay, startOfWeek } from 'date-fns';
import { prepareDailyDiaries, prepareSkills, roundToTwo } from './dashboardsStats';
import { AfterSessionType } from '../../collections/afterSessionSurvey';
import { SessionType } from '../../modeltypes/session';
import { LessonRatingType } from '../../modeltypes/lessonRating';
import { DailyDiaryType } from '../../modeltypes/dailyDiary';
import { getIndustryAverages } from '../../collections/industryAverage';
import { TrackWithDetailsType } from '../../modeltypes/tracks';
import { NodeProgressType } from '../../modeltypes/nodeProgress';

// Inverting a star value ... 5 stars becomes 1... 1 star. becomes 5...
const inverseEq = (v: number) => 5 + 1 - v;

// For calculating star values for the average of two values where one is an inverse (e.x. Sleep = restfulness + inverse of Sleep onset (aka Latency);
export const calculate = (star: number, toInverseStar: number) => ((star + inverseEq(toInverseStar)) / 2).toFixed(2);

export const getFill = (originalTitle: string): string | undefined => {
  let fill = undefined;
  const title = originalTitle.toLowerCase();
  switch (title) {
    case 'depression':
    case 'concentration':
      fill = '#B8DF99';
      break;
    case 'focus':
    case 'distractability':
    case 'equanimity':
      fill = '#BEC8FF';
      break;
    case 'happiness':
    case 'clarity':
      fill = '#BEE2FF';
      break;
    case 'sleep onset':
    case 'latency':
      fill = '#FFD694';
      break;
    case 'overwhelm':
      fill = '#FFD1D1';
      break;
    case 'restfulness':
      fill = '#D8D9CA';
      break;
  }

  return fill;
};

export const prepareWeekSessionData = ({
  sessions,
  weekNumber,
}: {
  sessions?: SessionType[];
  weekNumber: number;
}): {
  tickValues: string[];
  chartData: {
    data: { x: string; y: number }[];
    fill?: string;
    name: string;
  }[];
} => {
  const filtered = sessions?.filter(({ date }) => weekNumber === getWeek(date.toDate()));
  const sessionsData: { x: string; y: number }[] = [];
  const lessonsData: { x: string; y: number }[] = [];
  const tickValues: string[] = [];
  for (let tempDate = 0; tempDate < 7; tempDate += 1) {
    tickValues.push(`${format(addDays(startOfWeek(new Date()), tempDate), 'EE')}`);
  }
  filtered?.forEach((session) => {
    const sIndex = sessionsData.findIndex((element) => element.x === `${format(session.date.toDate(), 'EE')}`);
    const lIndex = lessonsData.findIndex((element) => element.x === `${format(session.date.toDate(), 'EE')}`);

    if (sIndex < 0) {
      sessionsData.push({
        x: `${format(session.date.toDate(), 'EE')}`,
        y: 1,
      });
    } else {
      sessionsData[sIndex] = {
        x: sessionsData[sIndex].x,
        y: sessionsData[sIndex].y + 1,
      };
    }
    if (session.lessonFinished) {
      if (lIndex < 0) {
        lessonsData.push({
          x: `${format(session.date.toDate(), 'EE')}`,
          y: 1,
        });
      } else {
        lessonsData[lIndex] = {
          x: lessonsData[lIndex].x,
          y: lessonsData[lIndex].y + 1,
        };
      }
    }
  });

  return {
    tickValues: tickValues,
    chartData: [
      {
        data: lessonsData,
        fill: '#ffbebe',
        name: 'Lessons',
      },
      {
        data: sessionsData,
        fill: '#BEE2FF',
        name: 'Sessions',
      },
    ],
  };
};

export const prepareAllSessionData = ({
  sessions,
  creationDate,
}: {
  sessions?: SessionType[];
  creationDate?: Date;
}): {
  tickValues: string[];
  chartData: {
    data: { x: string; y: number }[];
    fill?: string;
    name: string;
  }[];
} => {
  const sessionsData: { x: string; y: number }[] = [];
  const lessonsData: { x: string; y: number }[] = [];
  const tickValues: string[] = [];
  for (let tempDate = startOfDay(creationDate || new Date()); tempDate <= new Date(); tempDate = addDays(tempDate, 1)) {
    tickValues.push(`${tempDate.getMonth() + 1}/${tempDate.getDate()}`);
  }
  sessions?.forEach((session) => {
    const sIndex = sessionsData.findIndex(
      (element) => element.x === `${session.date.toDate().getMonth() + 1}/${session.date.toDate().getDate()}`,
    );
    const lIndex = lessonsData.findIndex(
      (element) => element.x === `${session.date.toDate().getMonth() + 1}/${session.date.toDate().getDate()}`,
    );

    if (sIndex < 0) {
      sessionsData.push({
        x: `${session.date.toDate().getMonth() + 1}/${session.date.toDate().getDate()}`,
        y: 1,
      });
    } else {
      sessionsData[sIndex] = {
        x: sessionsData[sIndex].x,
        y: sessionsData[sIndex].y + 1,
      };
    }
    if (session.lessonFinished) {
      if (lIndex < 0) {
        lessonsData.push({
          x: `${session.date.toDate().getMonth() + 1}/${session.date.toDate().getDate()}`,
          y: 1,
        });
      } else {
        lessonsData[lIndex] = {
          x: lessonsData[lIndex].x,
          y: lessonsData[lIndex].y + 1,
        };
      }
    }
  });

  return {
    tickValues: tickValues,
    chartData: [
      {
        data: lessonsData,
        fill: '#ffbebe',
        name: 'Lessons',
      },
      {
        data: sessionsData,
        fill: '#BEE2FF',
        name: 'Sessions',
      },
    ],
  };
};

export const prepareUsersPerDay = ({ sessions, creationDate }: { sessions: SessionType[]; creationDate?: Date }) => {
  const tickValues: string[] = [];
  for (let tempDate = startOfDay(creationDate || new Date()); tempDate <= new Date(); tempDate = addDays(tempDate, 1)) {
    tickValues.push(`${tempDate.getMonth() + 1}/${tempDate.getDate()}`);
  }
  const groupedByDate = sessions.reduce((usersPerDay: { [date: string]: string[] }, item) => {
    const date = `${item.date.toDate().getMonth() + 1}/${item.date.toDate().getDate()}`;
    if (item) {
      if (!usersPerDay[date]) usersPerDay[date] = [];
      if (item.uid && !usersPerDay[date].find((s) => s === item.uid)) {
        usersPerDay[date].push(item.uid);
      }
    }
    return usersPerDay;
  }, {});
  return {
    tickValues: tickValues,
    chartData: [
      {
        data: Object.entries(groupedByDate).map(([name, values]) => ({
          x: name,
          y: values.length,
        })),
        fill: 'rgba(69,125,255,0.53)',
        name: 'Users',
      },
    ],
  };
};

type MergedSessionDailyDiary = Omit<SessionType, 'answers'> & Partial<DailyDiaryType>;

export const preparePositiveDiaries = ({
  sessions,
  weekNumber,
  dailyDiaries,
}: {
  sessions: SessionType[];
  weekNumber: number;
  dailyDiaries: DailyDiaryType[];
}): {
  tickValues: string[];
  chartData: {
    data: { x: string; y: number }[];
    fill?: string;
    name: string;
  }[];
} => {
  const mergedArray: MergedSessionDailyDiary[] = sessions
    .filter(({ date }) => weekNumber === getWeek(date.toDate()))
    .filter(({ dailyDiary }) => dailyDiary)
    .map((item) => {
      delete item.answers;
      return { ...item, ...dailyDiaries.find(({ id }) => item?.dailyDiary?.id === id) } as MergedSessionDailyDiary;
    });

  const happinessData: { x: string; y: number }[] = [];
  const restfulnessData: { x: string; y: number }[] = [];
  const distractiblityData: { x: string; y: number }[] = [];

  const tickValues: string[] = [];
  for (let tempDate = 0; tempDate < 7; tempDate += 1) {
    tickValues.push(`${format(addDays(startOfWeek(new Date()), tempDate), 'EE')}`);
  }
  mergedArray
    .filter(({ answers }) => answers && Object.values(answers).length > 0)
    .forEach((session) => {
      const hIndex = happinessData.findIndex((element) => element.x === `${format(session.date.toDate(), 'EE')}`);
      const rIndex = restfulnessData.findIndex((element) => element.x === `${format(session.date.toDate(), 'EE')}`);
      const disIndex = distractiblityData.findIndex(
        (element) => element.x === `${format(session.date.toDate(), 'EE')}`,
      );

      if (hIndex < 0) {
        happinessData.push({
          x: `${format(session.date.toDate(), 'EE')}`,
          y: session.answers?.happiness || 0,
        });
      } else {
        happinessData[hIndex] = {
          x: happinessData[hIndex].x,
          y: roundToTwo((happinessData[hIndex].y + (session.answers?.happiness || 0)) / 2),
        };
      }
      if (rIndex < 0) {
        restfulnessData.push({
          x: `${format(session.date.toDate(), 'EE')}`,
          y: session.answers?.restfulness || 0,
        });
      } else {
        restfulnessData[rIndex] = {
          x: restfulnessData[rIndex].x,
          y: roundToTwo((restfulnessData[rIndex].y + (session.answers?.restfulness || 0)) / 2),
        };
      }
      if (disIndex < 0) {
        distractiblityData.push({
          x: `${format(session.date.toDate(), 'EE')}`,
          y: session.answers?.distractability || 0, // Math.abs((session.answers?.distractability || 6) - 6),
        });
      } else {
        distractiblityData[disIndex] = {
          x: distractiblityData[disIndex].x,
          y: roundToTwo((distractiblityData[disIndex].y + (session.answers?.restfulness || 0)) / 2), //+ Math.abs((session.answers?.distractability || 6) - 6)) / 2),
        };
      }
    });

  return {
    tickValues: tickValues,
    chartData: [
      {
        data: restfulnessData,
        fill: '#D8D9CA',
        name: 'Restfulness',
      },
      {
        data: happinessData,
        fill: '#BEE2FF',
        name: 'Happiness',
      },
      {
        data: distractiblityData,
        fill: '#BEC8FF',
        name: 'Focus',
      },
    ],
  };
};

export const prepareNegativeDiaries = ({
  sessions,
  dailyDiaries,
  weekNumber,
}: {
  sessions: SessionType[];
  weekNumber: number;
  dailyDiaries: DailyDiaryType[];
}): {
  tickValues: string[];
  chartData: {
    data: { x: string; y: number }[];
    fill?: string;
    name: string;
  }[];
} => {
  const mergedArray: MergedSessionDailyDiary[] = sessions
    .filter(({ date }) => weekNumber === getWeek(date.toDate()))
    .filter(({ dailyDiary }) => dailyDiary)
    .map((item) => {
      delete item.answers;
      return { ...item, ...dailyDiaries.find(({ id }) => item?.dailyDiary?.id === id) } as MergedSessionDailyDiary;
    });
  const depressionData: { x: string; y: number }[] = [];
  const overwhelmData: { x: string; y: number }[] = [];
  const latencyData: { x: string; y: number }[] = [];

  const tickValues: string[] = [];
  for (let tempDate = 0; tempDate < 7; tempDate += 1) {
    tickValues.push(`${format(addDays(startOfWeek(new Date()), tempDate), 'EE')}`);
  }
  mergedArray
    .filter(({ answers }) => answers && Object.values(answers).length > 0)
    .forEach((session) => {
      const depIndex = depressionData.findIndex((element) => element.x === `${format(session.date.toDate(), 'EE')}`);
      const oIndex = overwhelmData.findIndex((element) => element.x === `${format(session.date.toDate(), 'EE')}`);
      const lIndex = latencyData.findIndex((element) => element.x === `${format(session.date.toDate(), 'EE')}`);

      if (depIndex < 0) {
        depressionData.push({
          x: `${format(session.date.toDate(), 'EE')}`,
          y: session.answers?.depression || 0,
        });
      } else {
        depressionData[depIndex] = {
          x: depressionData[depIndex].x,
          y: roundToTwo((depressionData[depIndex].y + (session.answers?.depression || 0)) / 2),
        };
      }
      if (oIndex < 0) {
        overwhelmData.push({
          x: `${format(session.date.toDate(), 'EE')}`,
          y: session.answers?.overwhelm || 0,
        });
      } else {
        overwhelmData[oIndex] = {
          x: overwhelmData[oIndex].x,
          y: roundToTwo((overwhelmData[oIndex].y + (session.answers?.overwhelm || 0)) / 2),
        };
      }
      if (lIndex < 0) {
        latencyData.push({
          x: `${format(session.date.toDate(), 'EE')}`,
          y: session.answers?.latency || 0,
        });
      } else {
        latencyData[lIndex] = {
          x: latencyData[lIndex].x,
          y: roundToTwo((latencyData[lIndex].y + (session.answers?.latency || 0)) / 2),
        };
      }
    });

  return {
    tickValues: tickValues,
    chartData: [
      {
        data: depressionData,
        fill: '#B8DF99',
        name: 'Depression',
      },
      {
        data: overwhelmData,
        fill: '#FFD1D1',
        name: 'Overwhelm',
      },
      {
        data: latencyData,
        fill: '#FFD694',
        name: 'Sleep Onset',
      },
    ],
  };
};

type MergedSessionAfter = Omit<SessionType, 'answers'> & Partial<AfterSessionType>;

export const prepareBestAndWorstInsights = ({
  sessions,
  dailyDiaries,
  afterSessions,
}: {
  sessions: SessionType[];
  dailyDiaries: DailyDiaryType[];
  afterSessions: AfterSessionType[];
}) => {
  const mergedWithAfters: MergedSessionAfter[] = sessions
    .filter(({ afterSessionSurvey }) => afterSessionSurvey)
    .map((item) => {
      delete item.answers;
      return { ...item, ...afterSessions.find(({ id }) => item?.afterSessionSurvey?.id === id) } as MergedSessionAfter;
    });

  const concentrationData: { date: string; value: number }[] = [];
  const equanimityData: { date: string; value: number }[] = [];
  const clarityData: { date: string; value: number }[] = [];
  const happinessData: { date: string; value: number }[] = [];
  const restfulnessData: { date: string; value: number }[] = [];
  const depressionData: { date: string; value: number }[] = [];
  const overwhelmData: { date: string; value: number }[] = [];
  const latencyData: { date: string; value: number }[] = [];
  const distractiblityData: { date: string; value: number }[] = [];

  mergedWithAfters
    .filter(({ answers }) => answers && Object.values(answers).length > 0)
    .forEach((session) => {
      const conIndex = concentrationData.findIndex(
        (element) => element.date === `${format(session.date.toDate(), 'EEEE')}`,
      );
      const eIndex = equanimityData.findIndex((element) => element.date === `${format(session.date.toDate(), 'EEEE')}`);
      const claIndex = clarityData.findIndex((element) => element.date === `${format(session.date.toDate(), 'EEEE')}`);

      if (conIndex < 0) {
        concentrationData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.concentration || 0,
        });
      } else {
        concentrationData[conIndex] = {
          date: concentrationData[conIndex].date,
          value: roundToTwo((concentrationData[conIndex].value + (session.answers?.concentration || 0)) / 2),
        };
      }
      if (eIndex < 0) {
        equanimityData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.equanimity || 0,
        });
      } else {
        equanimityData[eIndex] = {
          date: equanimityData[eIndex].date,
          value: roundToTwo((equanimityData[eIndex].value + (session.answers?.equanimity || 0)) / 2),
        };
      }
      if (claIndex < 0) {
        clarityData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.sensory_clarity || 0,
        });
      } else {
        clarityData[claIndex] = {
          date: clarityData[claIndex].date,
          value: roundToTwo((clarityData[claIndex].value + (session.answers?.sensory_clarity || 0)) / 2),
        };
      }
    });
  const mergedArray: MergedSessionDailyDiary[] = sessions
    .filter(({ dailyDiary }) => dailyDiary)
    .map((item) => {
      delete item.answers;
      return { ...item, ...dailyDiaries.find(({ id }) => item?.dailyDiary?.id === id) } as MergedSessionDailyDiary;
    });

  mergedArray
    .filter(({ answers }) => answers && Object.values(answers).length > 0)
    .forEach((session) => {
      const hIndex = happinessData.findIndex((element) => element.date === `${format(session.date.toDate(), 'EEEE')}`);
      const rIndex = restfulnessData.findIndex(
        (element) => element.date === `${format(session.date.toDate(), 'EEEE')}`,
      );
      const depIndex = depressionData.findIndex(
        (element) => element.date === `${format(session.date.toDate(), 'EEEE')}`,
      );
      const oIndex = overwhelmData.findIndex((element) => element.date === `${format(session.date.toDate(), 'EEEE')}`);
      const lIndex = latencyData.findIndex((element) => element.date === `${format(session.date.toDate(), 'EEEE')}`);
      const disIndex = distractiblityData.findIndex(
        (element) => element.date === `${format(session.date.toDate(), 'EEEE')}`,
      );

      if (hIndex < 0) {
        happinessData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.happiness || 0,
        });
      } else {
        happinessData[hIndex] = {
          date: happinessData[hIndex].date,
          value: roundToTwo((happinessData[hIndex].value + (session.answers?.happiness || 0)) / 2),
        };
      }
      if (rIndex < 0) {
        restfulnessData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.restfulness || 0,
        });
      } else {
        restfulnessData[rIndex] = {
          date: restfulnessData[rIndex].date,
          value: roundToTwo((restfulnessData[rIndex].value + (session.answers?.restfulness || 0)) / 2),
        };
      }
      if (depIndex < 0) {
        depressionData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.depression || 0,
        });
      } else {
        depressionData[depIndex] = {
          date: depressionData[depIndex].date,
          value: roundToTwo((depressionData[depIndex].value + (session.answers?.depression || 0)) / 2),
        };
      }
      if (oIndex < 0) {
        overwhelmData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.overwhelm || 0,
        });
      } else {
        overwhelmData[oIndex] = {
          date: overwhelmData[oIndex].date,
          value: roundToTwo((overwhelmData[oIndex].value + (session.answers?.overwhelm || 0)) / 2),
        };
      }
      if (lIndex < 0) {
        latencyData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.latency || 0,
        });
      } else {
        latencyData[lIndex] = {
          date: latencyData[lIndex].date,
          value: roundToTwo((latencyData[lIndex].value + (session.answers?.latency || 0)) / 2),
        };
      }
      if (disIndex < 0) {
        distractiblityData.push({
          date: `${format(session.date.toDate(), 'EEEE')}`,
          value: session.answers?.distractability || 0, //Math.abs((session.answers?.distractability || 6) - 6),
        });
      } else {
        distractiblityData[disIndex] = {
          date: distractiblityData[disIndex].date,
          value: roundToTwo(
            (distractiblityData[disIndex].value + (session.answers?.distractability || 0)) / 2, //Math.abs((session.answers?.distractability || 6) - 6)) / 2,
          ),
        };
      }
    });

  const restulness = {
    highest: restfulnessData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
    lowest: restfulnessData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
  };
  const depression = {
    highest: depressionData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
    lowest: depressionData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
  };

  const latency = {
    highest: latencyData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
    lowest: latencyData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
  };

  const happiness = {
    highest: happinessData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
    lowest: happinessData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
  };

  const distractibility = {
    highest: distractiblityData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
    lowest: distractiblityData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
  };

  const overwhelm = {
    highest: overwhelmData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
    lowest: overwhelmData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
  };

  const cohortAverages = [...prepareDailyDiaries(dailyDiaries), ...prepareSkills(afterSessions)];
  const averagesPromise = getIndustryAverages();
  return Promise.all([averagesPromise]).then(([averages]) => {
    return {
      concentration: {
        best: concentrationData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
        worst: concentrationData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
        cohortAverage: cohortAverages.find((item) => item.name.toLowerCase() === 'concentration')?.stat,
        average: averages.concentration,
      },
      clarity: {
        best: clarityData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
        worst: clarityData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
        cohortAverage: cohortAverages.find((item) => item.name.toLowerCase() === 'clarity')?.stat,
        average: averages.sensory_clarity,
      },
      equanimity: {
        best: equanimityData.reduce((prev, next) => (prev.value > next.value ? prev : next)),
        worst: equanimityData.reduce((prev, next) => (prev.value < next.value ? prev : next)),
        cohortAverage: cohortAverages.find((item) => item.name.toLowerCase() === 'equanimity')?.stat,
        average: averages.equanimity,
      },
      sleep: {
        best: {
          value: calculate(restulness.highest.value, latency.lowest.value),
          date: restulness.highest.date,
        },
        worst: {
          value: calculate(restulness.lowest.value, latency.highest.value),
          date: latency.highest.date,
        },
        cohortAverage: cohortAverages.find((item) => item.name.toLowerCase() === 'sleep')?.stat,
        average: calculate(averages.restfulness, averages.latency),
      },
      focus: {
        best: {
          value: calculate(distractibility.highest.value, overwhelm.lowest.value),
          date: distractibility.highest.date,
        },
        worst: {
          value: calculate(distractibility.lowest.value, overwhelm.highest.value),
          date: overwhelm.highest.date,
        },
        cohortAverage: cohortAverages.find((item) => item.name.toLowerCase() === 'focus')?.stat,
        average: calculate(averages.distractability, averages.overwhelm),
      },
      mood: {
        best: {
          value: calculate(happiness.highest.value, depression.lowest.value),
          date: happiness.highest.date,
        },
        worst: {
          value: calculate(happiness.lowest.value, depression.highest.value),
          date: depression.highest.date,
        },
        cohortAverage: cohortAverages.find((item) => item.name.toLowerCase() === 'mood')?.stat,
        average: calculate(averages.happiness, averages.depression),
      },
      restfulness: {
        best: restulness.highest,
        worst: restulness.lowest,
        average: averages.restfulness,
      },
      depression: {
        best: depression.lowest,
        worst: depression.highest,
        average: averages.depression,
      },
      latency: {
        best: latency.lowest,
        worst: latency.highest,
        average: averages.latency,
      },
      happiness: {
        best: happiness.highest,
        worst: happiness.lowest,
        average: averages.happiness,
      },
      distractibility: {
        best: distractibility.highest,
        worst: distractibility.lowest,
        average: averages.distractability,
      },
      overwhelm: {
        best: overwhelm.lowest,
        worst: overwhelm.highest,
        average: averages.overwhelm,
      },
    };
  });
};

export const prepareSkillsPerLesson = ({
  sessions,
  afterSession,
  trackDetails,
}: {
  sessions: SessionType[];
  afterSession: AfterSessionType[];
  trackDetails: TrackWithDetailsType;
}) => {
  const units = trackDetails.units;
  const lessons = units.flatMap((el) => el.elements);

  const mergedArray = sessions
    .filter(({ afterSessionSurvey }) => afterSessionSurvey)
    .map((item) => {
      delete item.answers;
      return { ...item, ...afterSession.find(({ id }) => item?.afterSessionSurvey?.id === id) };
    });

  const groupedBySkillAndLesson = mergedArray.reduce(
    (
      lessons: {
        [key: string]: { [key: string]: number[] };
      },
      item,
    ) => {
      if (item.answers) {
        Object.entries(item.answers).forEach(([name, value]) => {
          const { nodeId } = item;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!lessons[name]) lessons[name] = {};
          if (value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!lessons[name][nodeId]) lessons[name][nodeId] = [];

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lessons[name][nodeId].push(value);
          }
        });
      }
      return lessons;
    },
    {},
  );

  return Object.entries<{ [key: string]: number[] }>(groupedBySkillAndLesson).map(([s, answers]) => {
    const name = s === 'sensory_clarity' ? 'Clarity' : s.charAt(0).toUpperCase() + s.slice(1);
    return {
      name: name,
      data: lessons
        .filter((lesson) => lesson.pathType === 'audioLesson')
        .map((lesson) => ({
          x: `${lesson.title || lesson.id}`,
          y: answers[lesson.id]?.reduce((prev, next) => prev + next) / answers[lesson.id]?.length || 0,
        })),
      fill: getFill(name),
    };
  });
};

export const prepareDailyPerLesson = ({
  sessions,
  dailyDiaries,
  trackDetails,
}: {
  sessions: SessionType[];
  dailyDiaries: DailyDiaryType[];
  trackDetails: TrackWithDetailsType;
}) => {
  const units = trackDetails.units;
  const lessons = units.flatMap((el) => el.elements);

  const mergedArray = sessions
    .filter(({ dailyDiary }) => dailyDiary)
    .map((item) => {
      delete item.answers;
      return { ...item, ...dailyDiaries.find(({ id }) => item?.dailyDiary?.id === id) };
    });
  const groupedBySkillAndLesson = mergedArray.reduce(
    (
      lessons: {
        [key: string]: { [key: string]: number[] };
      },
      item,
    ) => {
      if (item.answers) {
        Object.entries(item.answers).forEach(([name, value]) => {
          const { nodeId } = item;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!lessons[name]) lessons[name] = {};
          if (value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!lessons[name][nodeId]) lessons[name][nodeId] = [];

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lessons[name][nodeId].push(/*name === 'distractability' ? Math.abs(value - 6) : */ value);
          }
        });
      }
      return lessons;
    },
    {},
  );

  return Object.entries<{ [key: string]: number[] }>(groupedBySkillAndLesson).map(([s, answers]) => {
    const name =
      s === 'distractability' ? 'Focus' : s === 'latency' ? 'Sleep Onset' : s.charAt(0).toUpperCase() + s.slice(1);
    return {
      name: name,
      data: lessons
        .filter((lesson) => lesson.pathType === 'audioLesson')
        .map((lesson) => ({
          x: `${lesson.title || lesson.id}`,
          y: answers[lesson.id]?.reduce((prev, next) => prev + next) / answers[lesson.id]?.length || 0,
        })),
      fill: getFill(name),
    };
  });
};

export const prepareRatingsPerLesson = (ratings: LessonRatingType[], trackDetails: TrackWithDetailsType) => {
  const units = trackDetails.units;
  const lessons = units.flatMap((el) => el.elements);

  const groupedByLesson = ratings.reduce((lessons: { [key: string]: number[] }, item) => {
    if (item) {
      const { lessonId } = item;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!lessons[lessonId]) lessons[lessonId] = [];
      if (item.rate) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lessons[lessonId].push(item.rate);
      }
    }
    return lessons;
  }, {});

  return [
    {
      name: 'Lessons',
      data: lessons
        .filter((lesson) => lesson.pathType === 'audioLesson')
        .map((lesson) => ({
          x: `${lesson.title || lesson.id}`,
          y: groupedByLesson[lesson.id]?.reduce((prev, next) => prev + next) / groupedByLesson[lesson.id]?.length || 0,
        })),
      fill: '#ff9393',
    },
  ];
};

export const prepareUsersProgramProgress = (nodesProgress: NodeProgressType[], trackDetails: TrackWithDetailsType) => {
  const units = trackDetails.units;
  const lessons = units.flatMap((el) => el.elements);

  return [
    {
      name: 'Lessons',
      data: lessons.flatMap((element, index) => ({
        x: element.title || `Node ${index + 1} - ${element.pathType}`,
        y: nodesProgress.filter((progress) => progress.nodeId === element.id && progress.finished).length,
      })),
      fill: '#96c545',
    },
  ];
};
