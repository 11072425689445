// *******************************************************
// TextUpdateModal
// -------------------------------------------------------
// This is a TextUpdateModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useState } from 'react';
import { AudioNodeType } from '../../../modeltypes/audioNode';
import { updateAudioNode } from '../../../collections/audioNode';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface TextUpdateModalProps {
  hide: (shouldRefresh?: boolean) => void;
  node: AudioNodeType;
  updateNodes: (nodeIds: string[]) => void;
}

const TextUpdateModal = ({ hide, node, updateNodes }: TextUpdateModalProps) => {
  const [text, setText] = useState(node.spokenText || '');
  const [isPending, setIsPending] = useState(false);

  const onClick = async () => {
    if (text !== (node.spokenText || '')) {
      setIsPending(true);
      await updateAudioNode(node.id, { spokenText: text });
      updateNodes([node.id]);
    }
    hide();
  };

  return (
    <>
      <div>
        <label htmlFor='cover-photo' className='block text-lg font-medium text-white mb-4'>
          Text
        </label>
        <textarea
          className='w-full h-[250px]'
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={'Text visible for a user in the app'}
        />
      </div>
      <div className='flex justify-end text-right pt-2 pr-2  rounded-b border-t border-gray-200 dark:border-gray-600'>
        <button
          disabled={isPending}
          onClick={onClick}
          type='button'
          className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        >
          {text === (node.spokenText || '') ? 'Close' : isPending ? 'Updating...' : 'Update'}
        </button>
      </div>
    </>
  );
};

export default TextUpdateModal;
