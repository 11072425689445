// *******************************************************
// UserJourney
// -------------------------------------------------------
// This is a UserJourney
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { getUserById, updateUser } from '../../collections/user';
import { getUserJourneyById, updateUserJourney } from '../../collections/userJourney';
import { addNewNodeProgress, getNodeProgressCollection, updateNodeProgress } from '../../collections/nodeProgress';
import { query, where } from 'firebase/firestore';
import { NodeProgressType } from '../../modeltypes/nodeProgress';
import { getChallengesByIdArray } from '../../collections/challenges';
import { getLessonsByIdArray } from '../../collections/lesson';
import { getTasksByIdArray } from '../../collections/tasks';
import { onSnapshot } from '../../models/dalaccess';
import { UnitType } from '../../modeltypes/unit';
import { TaskItemType } from '../../modeltypes/tasks';
import { arrayRemove, arrayUnion } from '@firebase/firestore';
import { TrackType } from 'src/modeltypes/tracks';
import { getAllTracks } from 'src/collections/tracks';
import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { getAllGoals } from 'src/collections/goals';
import { GoalType } from 'src/modeltypes/goals';
import { getUnitById } from 'src/collections/units';
import { getRespirationDataByUserId } from 'src/collections/respirationData';
import { UserId } from 'src/modeltypes/id';
import { getSessionDataByUserId } from 'src/collections/session';
import { getScienceDataByUserId } from 'src/collections/scienceData';
import { SessionType } from 'src/modeltypes/session';
import { RespirationDataType } from 'src/modeltypes/respirationData';
import { ScienceDataType } from 'src/modeltypes/scienceData';
import UserRespirationDataList, { UserRespirationDataListType } from './UserRespirationDataList';
import UserScienceDataList, { UserScienceDataListType } from './UserScienceDataList';
import UserSessionList, { UserSessionListType } from './UserSessionList';
import { prepareCSVforUser, ResearchCSVType } from '../../utility/csvUtility';
import { AfterSessionType, getAfterSessionsWithRef } from 'src/collections/afterSessionSurvey';
import { CSVDownload } from 'react-csv';
import { getBlob } from 'firebase/storage';
import { getStorageRef } from 'src/firebase/utils';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { getLessonAudioChunksByIdArray } from 'src/collections/lessonAudioChunk';
import { getLearningGamesByIdArray } from 'src/collections/learningGames';
import { toast } from 'react-toastify';
// import { prepareCSV, ResearchCSVType } from '../../utility/csvUtility';
// /import { saveAs } from 'file-saver';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
export type UserJourneyElementType = {
  id: string;
  title: string;
  taskItems?: TaskItemType[];
  isPractice?: boolean;
  duration?: number | null;
};

const TrackList = ({
  tracks,
  isLoading,
  goals,
  addTrackToJourney,
  isAdding,
}: {
  tracks: TrackType[];
  isLoading: boolean;
  goals: GoalType[];
  addTrackToJourney: (track: TrackType) => void;
  isAdding: boolean;
}) => {
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Goal Type
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Tags
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Status
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      ></th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {tracks.map((track) => {
                      console.log('goals: ', goals);
                      console.log('track.goal: ', track.goal);
                      if (track.isArchived) return null;
                      if (!track.showInRouter) return null;
                      return (
                        <tr key={track.id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500 max-w-[75px] truncate'>{track.id}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{track?.title}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {track?.goal
                                  ? goals?.find((x) => x.id === track?.goal)?.name
                                  : track?.isIntroSeries
                                  ? 'No Goal- Intro Series'
                                  : 'Error'}
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {track?.tags?.length ? (
                                  <div>
                                    {track?.tags?.length
                                      ? track.tags?.map((tag) => (
                                          <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                            {tag}
                                          </div>
                                        ))
                                      : 'No Tags'}
                                  </div>
                                ) : (
                                  <div className='text-gray-500'></div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{track.productionStatus || 'WIP'}</div>
                              </div>
                            </div>
                          </td>
                          <td className={'center'}>
                            <button
                              disabled={isAdding}
                              className='w-full inline-flex justify-center align-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 align-middle'
                              onClick={() => {
                                addTrackToJourney(track);
                              }}
                            >
                              + Add to Journey
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserJourney = () => {
  const { id } = useParams();

  const [nodeProgresses, setNodeProgresses] = useState<NodeProgressType[] | null>(null);
  const [elements, setElements] = useState<UserJourneyElementType[] | null>(null);

  console.log('elements: ', elements);
  const [showTracks, setShowTracks] = useState<boolean | null>(null);
  const [isAdding, setIsAdding] = useState(false);

  // For the Respiration Data
  const [showRespiration, setShowRespiration] = useState<boolean>(false);
  const initialData = { node: null, user: null, unit: null, respirationData: null, unitIndex: null };
  const [respirationPopupData, setRespirationPopupData] = useState<UserRespirationDataListType | null>(initialData);

  // For the Respiration Data
  const [showScience, setShowScience] = useState<boolean>(false);
  const initialScienceData = { node: null, user: null, unit: null, scienceData: null, unitIndex: null };
  const [sciencePopupData, setSciencePopupData] = useState<UserScienceDataListType | null>(initialScienceData);

  // For the Session Data
  const [showSession, setShowSession] = useState<boolean>(false);
  const initialSessionData = {
    node: null,
    user: null,
    unit: null,
    sessionData: null,
    unitIndex: null,
    scienceData: null,
    respirationData: null,
  };
  const [sessionPopupData, setSessionPopupData] = useState<UserSessionListType | null>(initialSessionData);

  // For downloading CSVS
  const [isDownloading, setIsDownloading] = useState(false);
  const [csvData, setCsvData] = useState<null | ResearchCSVType[]>(null);
  const [isPreparingSensorsData, setIsPreparingSensorsData] = useState(false);

  //---------------------
  const { data: tracks, isLoading: isLoadingTracks } = useQuery({
    queryFn: getAllTracks,
    queryKey: ['tracks'],
    retry: 2,
  });

  const { data: goals, isLoading: isLoadingGoals } = useQuery({
    queryFn: getAllGoals,
    queryKey: ['goals'],
    retry: 2,
  });

  const { data: user, refetch: refetchUser } = useQuery({
    queryFn: () => getUserById(id),
    queryKey: ['user'],
    initialData: null,
  });

  const { data: respData, refetch: refectchRespData } = useQuery({
    queryFn: () => getRespirationDataByUserId(id as UserId),
    queryKey: ['respirationData'],
    initialData: null,
  });

  const { data: seshData, refetch: refectchSessionData } = useQuery({
    queryFn: () => getSessionDataByUserId(id as UserId),
    queryKey: ['sessionData'],
    initialData: null,
  });

  const { data: sciData, refetch: refectchScienceData } = useQuery({
    queryFn: () => getScienceDataByUserId(id as UserId),
    queryKey: ['scienceData'],
    initialData: null,
  });

  // console.log('respData: ', respData);
  console.log('sciData: ', sciData);
  // console.log('seshData: ', seshData);

  const { data: userJourney, refetch: refetchUserJourney } = useQuery({
    queryFn: () => getUserJourneyById(id),
    queryKey: ['journey'],
    initialData: null,
  });

  const tracksUserDontHave = useMemo(
    () =>
      tracks && userJourney
        ? tracks.filter(
            (track) => track.productionStatus === 'PROD' && !userJourney.alreadyUsedTracks.includes(track.id),
          )
        : [],
    [tracks, userJourney],
  );

  const downloadZippedSensorsData = async () => {
    setIsPreparingSensorsData(true);

    const userSession = await getSessionDataByUserId(id || '');

    const sessionsWithFiles = userSession?.filter((x) => x.accelFile || x.gyroFile);
    console.log('sessionsWithFiles: ', sessionsWithFiles);

    if (sessionsWithFiles?.length) {
      toast.info('Preparing sensors files to be downloaded', {
        toastId: 'sensors',
        autoClose: false,
        closeOnClick: false,
        isLoading: true,
        progress: 0,
      });
      const zip = new JSZip();

      //   const csvData = prepareCSV({
      //     afterSessions,
      //     beforeCCE,
      //     sessions,
      //     users: userDetails?.map((el) => el.account) || null,
      //     timezone: cohort?.timezone?.name,
      //   });

      for (const idx in sessionsWithFiles) {
        const index = +idx;
        toast.update('sensors', { progress: (index + 1) / sessionsWithFiles.length });
        const session = sessionsWithFiles[index];
        const accelerometerRef = session.accelFile ? getStorageRef(session.accelFile) : null;
        // const gryorRef = session.gyroFile ? getStorageRef(session.gyroFile) : null;

        if (accelerometerRef) {
          const accelFile = await getBlob(accelerometerRef);
          const accelString = await accelFile.text();
          const accelData = JSON.parse(accelString);
          // const csvSession = csvData?.find((el) => el.sessionId === session.id) || {};
          const fullData = {
            // csv: csvSession,
            accelerometer: accelData,
          };
          console.log('about to get fileWithCsv', session.accelFile);
          const fileWithCsv = await new Blob([JSON.stringify(fullData, null, 20)]);

          const fileBreakdown = session?.accelFile?.split('/');

          const fileName = fileBreakdown?.[fileBreakdown?.length - 2];
          console.log('about to zip file fileWithCsv', session.accelFile);
          try {
            zip.file(`accelerometer/${fileName}-${accelerometerRef.name}`, fileWithCsv, {
              comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
            });
          } catch (error) {
            console.log('Zip Failed for , ', fileName, ' error - ', error);
          }
        } else {
          console.log(`session ${session.id}, doesnt have accel data`);
        }

        // if (gyroRef) {
        //   const accelFile = await getBlob(gyroRef);
        //   const accelString = await accelFile.text();
        //   const accelData = JSON.parse(accelString);
        //   // const csvSession = csvData?.find((el) => el.sessionId === session.id) || {};
        //   const fullData = {
        //     // csv: csvSession,
        //     accelerometer: accelData,
        //   };
        //   const fileWithCsv = new Blob([JSON.stringify(fullData, null, 20)]);
        //   zip.file(`gyroscope/${session.id}-${gyroRef.name}`, fileWithCsv, {
        //     comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
        //   });
        // } else {
        //   console.log(`session ${session.id}, doesnt have accel data`);
        // }
      }

      if (Object.values(zip.files).length > 0) {
        toast.info('Zipping files...', {
          toastId: 'zipping',
          isLoading: true,
          closeOnClick: false,
        });
        zip
          .generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              /* compression level ranges from 1 (best speed) to 9 (best compression) */
              level: 9,
            },
          })
          .then(function (blob) {
            saveAs(blob, `CHAGE___THIS____-${new Date().toISOString().replace('T', '/').split('.')[0]}`);
            toast.update('zipping', {
              type: 'success',
              render: 'Downloaded zipped sensors data...',
              closeOnClick: true,
              isLoading: false,
              autoClose: 5000,
            });
          });
      } else {
        toast.update('zipping', {
          type: 'error',
          render: 'There is no sensors files to be downloaded',
          closeOnClick: true,
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
    setIsPreparingSensorsData(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (id) {
        if (userJourney) {
          const elementArray = userJourney.journey.flatMap((unit) =>
            unit.elements.map((el) => ({
              id: el.id,
              pathType: el.pathType,
            })),
          );
          const challengesIds = elementArray.filter((el) => el.pathType === 'challenge').map((el) => el.id);
          const lessonIds = elementArray.filter((el) => el.pathType === 'audioLesson').map((el) => el.id);

          const audioChunklessonIds = elementArray
            .filter((el) => el.pathType === 'audioChunk' || el.pathType === 'practice')
            .map((el) => el.id);

          const learningGameIds = elementArray.filter((el) => el.pathType === 'learningGame').map((el) => el.id);

          console.log('lessonIds : ', lessonIds);

          const taskIds = elementArray.filter((el) => el.pathType === 'task').map((el) => el.id);

          const challengePromise = getChallengesByIdArray(challengesIds);
          const lessonPromise = getLessonsByIdArray(lessonIds);
          const audioChunklessonPromise = getLessonAudioChunksByIdArray(audioChunklessonIds);
          const learningGamePromise = getLearningGamesByIdArray(learningGameIds);

          const taskPromise = getTasksByIdArray(taskIds);

          const [challenges, lessons, tasks, audioChunks, learningGame] = await Promise.all([
            challengePromise,
            lessonPromise,
            taskPromise,
            audioChunklessonPromise,
            learningGamePromise,
          ]);

          const temp: UserJourneyElementType[] = [
            ...challenges.map((el) => ({
              id: el.id,
              title: el.title || '',
              duration: el?.duration || null,
            })),
            ...lessons.map((el) => ({
              id: el.id,
              title: el.title || '',
              duration: el?.duration || null,
            })),
            ...audioChunks.map((el) => ({
              id: el.id,
              title: el.title || '',
              duration: el?.duration || null,
            })),
            ...learningGame.map((el) => ({
              id: el.id,
              title: el.title || '',
              duration: el?.duration || null,
            })),

            ...tasks.map((el) => ({
              id: el.id,
              title: el.title || '',
              taskItems: el.taskItems,
              duration: el?.duration || null,
            })),
          ];
          setElements(temp);
        }
      }
    };
    getData();

    const snapshot = onSnapshot<NodeProgressType>(
      query(getNodeProgressCollection, where('uid', '==', id)),
      (snapshot) => {
        const values: NodeProgressType[] = [];
        snapshot.forEach((doc) => {
          const v = doc.data();
          v.id = doc.id;
          // Reading from DB, so only log, don't throw.
          values.push(v as NodeProgressType);
        });
        setNodeProgresses(values);
      },
      (error) => {
        console.error(error);
      },
    );

    return () => snapshot();
  }, [userJourney]);

  if (!id) {
    return null;
  }

  const setUserCurrentLesson = async (currentLesson: {
    nodeId: string;
    unitId: string;
    trackId: string;
    type: string;
  }) => {
    if (id) {
      await updateUser(id, {
        currentLesson: currentLesson,
      });
      await refetchUser();
      toast.success('Changed current lesson for user!');
    }
  };

  const updateProgress = async ({
    progress,
    field,
    value,
    unit,
    nodeId,
    type,
  }: {
    progress?: NodeProgressType;
    field: 'locked' | 'finished';
    value: boolean;
    unit: UnitType;
    nodeId: string;
    type: string;
  }) => {
    if (progress) {
      const props: any = { [field]: value };
      if (type === 'task' && field === 'finished') {
        if (value) {
          props.tasks = progress.tasks?.map((el) => ({
            ...el,
            timeSpent: el.minutes * 60,
          }));
        } else {
          props.tasks = progress.tasks?.map((el) => ({
            ...el,
            timeSpent: 0,
          }));
        }
      }
      await updateNodeProgress(progress.id, props);
    } else {
      const props: any = {
        uid: id,
        nodeId: nodeId,
        unitId: unit.id,
        locked: true,
        finished: false,
      };
      if (type === 'task') {
        const element = elements?.find((el) => el.id === nodeId);
        if (element?.taskItems) {
          props.tasks = element.taskItems?.map((item) => ({
            taskType: item.taskType,
            id: item.id,
            text: item.text,
            minutes: item.minutes,
            timeSpent: field === 'finished' && value ? (item.minutes || 1) * 60 : 0,
          }));
        }
      }
      props[field] = value;
      await addNewNodeProgress(props);
    }
    toast.success(`Changed progress ${field} to ${value}!`);
  };

  const removeAlreadyFinishedTrackId = (trackId: string) => {
    if (userJourney) {
      updateUserJourney(userJourney.id, { alreadyFinishedTracks: arrayRemove(trackId) }).catch((e) => console.error(e));
      toast.success(`Removed finished track!`);
      refetchUserJourney().catch((e) => console.error(e));
    }
  };

  const finishTrack = (trackId: string) => {
    if (userJourney) {
      updateUserJourney(userJourney.id, { alreadyFinishedTracks: arrayUnion(trackId) }).catch((e) => console.error(e));
      toast.success(`Set track as finished!`);
      refetchUserJourney().catch((e) => console.error(e));
    }
  };

  // --------------- CSV ---------------------------//
  // FOR DOWNLoADING CSVS AND WHAT NOT

  const prepareSurveysData = async (sessions: SessionType[]) => {
    if (sessions) {
      const afterSessionsRefs = sessions
        .filter((session: SessionType) => session.afterSessionSurvey)
        .map((session: SessionType) => session.afterSessionSurvey);

      const beforeCCE = sessions.filter((session) => session.beforeCCE).map((session) => session.beforeCCE);

      const afterSessions = await Promise.all(afterSessionsRefs.map(async (ref) => getAfterSessionsWithRef(ref))).then(
        (asArray) => asArray.filter((el): el is AfterSessionType => !!el?.id),
      );

      const bCCE = await Promise.all(beforeCCE.map(async (ref) => getAfterSessionsWithRef(ref))).then((bcceArray) =>
        bcceArray.filter((el): el is AfterSessionType => !!el?.id),
      );

      return {
        afterSessions,
        bCCE,
      };
    }
    return Promise.resolve({
      afterSessions: [],
      bCCE: [],
    });
  };

  // --------------------UI Renders  --------------------------

  const renderNode = (
    id: string,
    type: string,
    unit: UnitType & { trackId: string },
    unitIndex: number,
    sessions: SessionType[],
    respiration: RespirationDataType[],
    science: ScienceDataType[],
  ) => {
    const progress = nodeProgresses?.find((el) => el.nodeId === id && el.unitId === unit.id);
    const element = elements?.find((el) => el.id === id);
    const audioElement = unit.elements?.find((el) => el.id === id);

    return (
      <Fragment key={id}>
        <tr className='bg-gray-50 border-t border-gray-200'>
          <td colSpan={1} scope='colgroup' className=' px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6'>
            {id}
          </td>
          <td scope='colgroup' colSpan={1} className='py-2 px-3 '>
            {element?.title}
          </td>
          <td colSpan={1}>
            <p>{type}</p>
          </td>
          <td scope='colgroup' colSpan={1} className='py-2 px-3 '>
            {element?.duration}
          </td>

          <td colSpan={1} className='text-center'>
            <input
              type={'radio'}
              checked={user?.currentLesson?.nodeId === id}
              onChange={() =>
                setUserCurrentLesson({
                  nodeId: id,
                  unitId: unit.id,
                  trackId: unit.trackId,
                  type,
                })
              }
            />
          </td>
          <td colSpan={1} className='text-center'>
            <input
              type={'checkbox'}
              checked={progress ? progress.locked : true}
              onChange={(event) =>
                updateProgress({
                  progress,
                  unit,
                  nodeId: id,
                  type,
                  value: event.target.checked,
                  field: 'locked',
                })
              }
            />
          </td>
          <td colSpan={1} className='text-center'>
            <input
              type={'checkbox'}
              checked={progress ? progress.finished : false}
              onChange={(event) =>
                updateProgress({
                  progress,
                  unit,
                  nodeId: id,
                  type,
                  value: event.target.checked,
                  field: 'finished',
                })
              }
            />
          </td>
          {/* Nmber of sessions */}
          <td colSpan={1} className='text-center'>
            <p
              onClick={() => {
                if (sessions?.length) {
                  setSessionPopupData({
                    user,
                    unit,
                    element: audioElement,
                    sessionData: sessions,
                    unitIndex: unitIndex + 1,
                    sciencePopupData: science,
                    respirationData: respiration,
                  });
                  setShowSession(true);
                }
              }}
            >
              {' '}
              {sessions?.length || 0}
            </p>
          </td>
          <td colSpan={1} className='text-center'>
            <p
              onClick={() => {
                if (respData?.length) {
                  setSciencePopupData({
                    user,
                    unit,
                    element: audioElement,
                    sciencePopupData: science,
                    unitIndex: unitIndex + 1,
                  });
                  setShowScience(true);
                }
              }}
            >
              {science?.length || 0}
            </p>
          </td>

          <td colSpan={1} className='text-center'>
            <p
              onClick={() => {
                if (respData?.length) {
                  console.log('element: ', element);
                  setRespirationPopupData({
                    user,
                    unit,
                    element: audioElement,
                    respirationData: respiration,
                    unitIndex: unitIndex + 1,
                  });
                  setShowRespiration(true);
                }
              }}
            >
              {respiration?.length || 0}{' '}
            </p>
          </td>
        </tr>
      </Fragment>
    );
  };

  const renderJourney = () => {
    return userJourney?.journey?.map((unit, index) => {
      return (
        <Fragment key={unit.id}>
          <tr className='bg-gray-200 border-t border-b border-gray-500'>
            <th
              colSpan={6}
              scope='colgroup'
              className='text-center px-4 py-2 text-left font-semibold text-gray-900 sm:px-6 relative '
            >
              <p className='text-sm'>
                {unit.title} - Unit {index + 1}
              </p>
              <div className='text-[10px] font-normal'>
                <p>ID: {unit.id}</p>
                <p>
                  Track:{' '}
                  <span className={'font-bold'}>{tracks?.find((track) => track.id === unit.trackId)?.title || ''}</span>{' '}
                  ({unit.trackId})
                </p>
                <p>Intro: {unit.defaultUnit ? 'true' : 'false'}</p>
              </div>
              <div className={'absolute right-10 top-[30%]'}>
                {userJourney?.alreadyFinishedTracks?.includes(unit.trackId) ? (
                  <p>Already finished</p>
                ) : (
                  <button className={`text-indigo-600 hover:text-indigo-900`} onClick={() => finishTrack(unit.trackId)}>
                    Set as finished
                  </button>
                )}
              </div>
            </th>
          </tr>
          <>
            {unit.elements?.map((el) => {
              // First we are going to find the sessions that have the unitId in it
              const sessionData =
                seshData && typeof seshData === 'object'
                  ? seshData.filter((x) => {
                      // if(unit.id === x.unitId){
                      // console.log('el.id: ', el.id);
                      // console.log('x.nodeId: ', x.nodeId);
                      // }
                      return el.id === x.nodeId;
                    })
                  : [];

              const respirationData =
                sessionData && sessionData?.length && respData && respData?.length
                  ? respData?.filter((x) => {
                      return sessionData.some((y) => y.id === x.sessionId);
                    })
                  : [];

              const scienceData =
                sessionData && sessionData?.length && sciData && sciData?.length
                  ? sciData?.filter((x) => {
                      return sessionData.some((y) => y.id === x.sessionId);
                    })
                  : [];

              if (sessionData?.length) {
                console.log('sessionData: ', sessionData);
                console.log('sessionData?.length: ', sessionData?.length);
              }

              if (respirationData?.length) {
                console.log('respirationData: ', respirationData);
                console.log('respirationData?.length: ', respirationData?.length);
              }

              return renderNode(el.id, el.pathType, unit, index, sessionData, respirationData || [], scienceData || []);
            })}
          </>
        </Fragment>
      );
    });
  };

  const addTrackToJourney = async (track: TrackType) => {
    toast.loading('Adding track to the user journey', { toastId: 'addTrack' });
    setIsAdding(true);
    const units: (UnitType & { trackId: string })[] = [];

    for (const unit of track.units) {
      if (unit.id) {
        const unitData = await getUnitById(unit.id);
        if (unitData) {
          delete unitData?.createdAt;
          delete unitData?.updatedAt;

          units.push({ ...unitData, trackId: track.id });
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await updateUserJourney(user!.id, { journey: arrayUnion(...units), alreadyUsedTracks: arrayUnion(track.id) });
    if (units[0]?.elements[0]?.id) {
      addNewNodeProgress({
        uid: user!.id,
        nodeId: units[0].elements[0].id,
        unitId: units[0].id,
        locked: false,
        finished: false,
      }).catch();
      await updateUser(user!.id, {
        currentLesson: {
          nodeId: units[0].elements[0].id,
          unitId: units[0].id,
          type: units[0].elements[0].pathType,
          trackId: track.id,
        },
      });
    }

    await refetchUserJourney();
    await refetchUser();
    await refectchRespData();
    await refectchSessionData();
    await refectchScienceData();

    setIsAdding(false);
    toast.update('addTrack', {
      render: 'Successfully added new track to the journey!',
      type: 'success',
      isLoading: false,
      closeOnClick: true,
      autoClose: 5000,
    });
  };

  const renderDownloadButtons = () => {
    return (
      <div className='flex flex-row gap-x-7 self-center'>
        <button
          disabled={isDownloading}
          className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
          onClick={async () => {
            if (seshData?.length && user) {
              const surveyData = await prepareSurveysData(seshData);

              toast.info('Preparing CSV data', { toastId: 'csv' });

              const thisCsvData: ResearchCSVType[] | null = prepareCSVforUser({
                afterSessions: surveyData?.afterSessions,
                beforeCCE: surveyData?.bCCE,
                sessions: seshData,
                user: user,
                timezone: undefined,
              });

              if (thisCsvData) {
                toast.update('csv', {
                  type: 'success',
                  render: 'Downloading...',
                });
                setCsvData(thisCsvData);
              } else {
                toast.update('csv', {
                  type: 'error',
                  render: 'Not enough data to prepare CSV!',
                });
              }

              setIsDownloading(false);
            }
          }}
        >
          Download CSV
        </button>
        <button
          disabled={isPreparingSensorsData}
          className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
          onClick={() => downloadZippedSensorsData()}
        >
          Download Sensors data
        </button>
        {csvData && (
          <CSVDownload
            data={csvData}
            asyncOnClick
            onClick={(x, done) => {
              console.log('x');
              done(false);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div>
        <p>User:</p>
        <p className='font-bold text-xl'>{user?.email}</p>
        <p className='font-bold text-xl'>{user?.id}</p>

        <p className='font-bold text-xl'>
          {user?.firstName} {user?.lastName}
        </p>
      </div>
      <div className='my-5'>
        <p>Alerady finished tracks:</p>
        {userJourney?.alreadyFinishedTracks?.map((trackId) => (
          <p key={trackId}>
            • {trackId}
            <button
              className='ml-2 font-bold text-red-600 text-lg'
              onClick={() => removeAlreadyFinishedTrackId(trackId)}
            >
              -
            </button>
          </p>
        ))}
        <button
          disabled={isPreparingSensorsData}
          className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
          onClick={() => downloadZippedSensorsData()}
        >
          Download Sensors data
        </button>

        {!userJourney?.alreadyFinishedTracks || userJourney.alreadyFinishedTracks?.length === 0 ? (
          <p>There is no finished tracks</p>
        ) : null}
        {renderDownloadButtons()}
      </div>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <table className='min-w-full'>
              <thead className='bg-white'>
                <tr>
                  <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                    Id
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Name
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Type
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Duration (min)
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-gray-900'>
                    Currently on
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <p>Locked</p>
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <p>Finished</p>
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <p># Sesh ({seshData?.length || 0})</p>
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <p>Sci Data ({sciData?.length || 0})</p>
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <p>Resp Data ({respData?.length || 0})</p>
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white'>{renderJourney()}</tbody>
            </table>
          </div>
        </div>
      </div>
      <button
        className='mt-5 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        onClick={() => {
          return showTracks ? setShowTracks(false) : setShowTracks(true);
        }}
      >
        {!showTracks ? '+ Add New Track to User Journey' : 'Close Tracks'}
      </button>

      {showRespiration ? (
        <UserRespirationDataList
          {...respirationPopupData}
          onPressClose={() => {
            setShowRespiration(false);
            setRespirationPopupData(initialData);
          }}
        />
      ) : null}

      {showScience ? (
        <UserScienceDataList
          {...sciencePopupData}
          onPressClose={() => {
            setShowScience(false);
            setSciencePopupData(initialScienceData);
          }}
        />
      ) : null}
      {showSession ? (
        <UserSessionList
          {...sessionPopupData}
          onPressClose={() => {
            setShowSession(false);
            setSessionPopupData(initialSessionData);
          }}
        />
      ) : null}

      {showTracks ? (
        <TrackList
          tracks={tracksUserDontHave || []}
          isLoading={isLoadingTracks || isLoadingGoals}
          goals={goals || []}
          addTrackToJourney={addTrackToJourney}
          isAdding={isAdding}
        />
      ) : null}
    </div>
  );
};

export default UserJourney;
