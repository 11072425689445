// *******************************************************
// ValidateElementModal
// -------------------------------------------------------
// This is a ValidateElementModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useAppContext } from '../../contexts/appContext';
import { useEffect, useState } from 'react';
import { getChallengeById, updateChallenge } from '../../collections/challenges';
import { getNodesByIdArray } from '../../collections/nodes';
import Spinner from '../Spinner';
import { validateChallenge } from './validateFunctions/validateChallenge';
import WarningErrorList from '../WarningErrorList';
import { getLessonById, updateLesson } from '../../collections/lesson';
import { getTaskById, updateTask } from '../../collections/tasks';
import { getTrackById, updateTrack } from '../../collections/tracks';
import { getUnitById, getUnitsByIdArray, updateUnit } from '../../collections/units';
import { toast } from 'react-toastify';
import { getChaptersByIdArray } from '../../collections/chapter';
import { getAudioNodesByIdArray } from '../../collections/audioNode';
import { validateLesson } from './validateFunctions/validateLesson';
import { useNavigate } from 'react-router-dom';
import { validateTrack } from './validateFunctions/validateTrack';
import { validateUnit } from './validateFunctions/validateUnit';
import { validateTask } from './validateFunctions/validateTask';
import { getChallengeSwitchById, updateChallengeSwitch } from '../../collections/challengeswitches';
import { validateChallengeSwitch } from './validateFunctions/validateChallengeSwitch';
import { getLessonAudioChunkById, updateLessonAudioChunk } from '../../collections/lessonAudioChunk';
import { validateLessonAudioChunk } from './validateFunctions/validateAudioChunk';
import { getLearningGameById, updateLearningGame } from 'src/collections/learningGames';
import { validateLearningGame } from './validateFunctions/validateLearningGame';
import { getQuizItemsByIdArray } from '../../collections/quizItems';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const ValidateElementModal = () => {
  const { validateElement, setValidateElement } = useAppContext();
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);

  const [isValidating, setIsValidating] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const validate = async () => {
      if (validateElement) {
        setIsValidating(true);
        switch (validateElement.type) {
          case 'challenge': {
            const challenge = await getChallengeById(validateElement.id);
            if (challenge) {
              const nodes = await getNodesByIdArray(challenge.flow);
              const _errors = validateChallenge(challenge, nodes);
              setErrors(_errors);
              setIsValidating(false);
            }
            break;
          }
          case 'lesson': {
            const lesson = await getLessonById(validateElement.id);
            const chapters = lesson?.chapters ? await getChaptersByIdArray(lesson?.chapters) : [];
            const audioNodesIds = chapters.flatMap((e) => e.audioNodes) as string[];
            const audioNodes = await getAudioNodesByIdArray(audioNodesIds);
            const [_errors, _warnings] = validateLesson(lesson, chapters, audioNodes);
            setErrors(_errors);
            setWarnings(_warnings);
            setIsValidating(false);
            break;
          }
          case 'task': {
            const task = await getTaskById(validateElement.id);
            const _errors = validateTask(task);
            setErrors(_errors);
            setIsValidating(false);
            break;
          }
          case 'track': {
            const track = await getTrackById(validateElement.id);
            const units = track?.units ? await getUnitsByIdArray(track?.units.map((el) => el.id)) : [];
            const _errors = validateTrack(track, units, validateElement.to);
            setErrors(_errors);
            setIsValidating(false);
            break;
          }
          case 'unit': {
            const unit = await getUnitById(validateElement.id);
            const elements = [];
            for (const element of unit?.elements || []) {
              let data;
              if (element.pathType === 'audioLesson') {
                data = await getLessonById(element.id);
              } else if (element.pathType === 'challenge') {
                data = await getChallengeById(element.id);
              } else if (element.pathType === 'task') {
                data = await getTaskById(element.id);
              } else if (['practice', 'audioChunk'].includes(element.pathType)) {
                data = await getLessonAudioChunkById(element.id);
              }
              if (data) {
                elements.push(data);
              }
            }
            const _errors = validateUnit(unit, elements, validateElement.to);
            setErrors(_errors);
            setIsValidating(false);
            break;
          }
          case 'learningGame': {
            const learningGame = await getLearningGameById(validateElement.id);
            const quizQustions = learningGame?.quizItems ? await getQuizItemsByIdArray(learningGame?.quizItems) : [];
            const [_errors, _warnings] = validateLearningGame(learningGame, quizQustions);
            setErrors(_errors);
            setWarnings(_warnings);
            setIsValidating(false);
            break;
          }
          case 'challengeSwitch': {
            const challengeSwitch = await getChallengeSwitchById(validateElement.id);
            const _errors = validateChallengeSwitch(challengeSwitch);
            setErrors(_errors);
            setIsValidating(false);
            break;
          }

          case 'audioChunk': {
            const audioChunk = await getLessonAudioChunkById(validateElement.id);
            const audioNodes = audioChunk ? await getAudioNodesByIdArray(audioChunk?.audioNodes) : [];

            const [_errors, _warnings] = validateLessonAudioChunk(audioChunk, audioNodes);
            setErrors(_errors);
            setWarnings(_warnings);
            setIsValidating(false);
            break;
          }
          default:
            setValidateElement(null);
        }
      }
    };
    validate();
  }, [validateElement]);

  const onPress = async () => {
    if (validateElement) {
      switch (validateElement.type) {
        case 'challenge': {
          await updateChallenge(validateElement.id, { productionStatus: validateElement.to });
          break;
        }
        case 'lesson': {
          await updateLesson(validateElement.id, { productionStatus: validateElement.to });
          break;
        }
        case 'task': {
          await updateTask(validateElement.id, { productionStatus: validateElement.to });

          break;
        }
        case 'track': {
          await updateTrack(validateElement.id, { productionStatus: validateElement.to });

          break;
        }
        case 'unit': {
          await updateUnit(validateElement.id, { productionStatus: validateElement.to });

          break;
        }
        case 'challengeSwitch': {
          await updateChallengeSwitch(validateElement.id, { productionStatus: validateElement.to });
          break;
        }
        case 'learningGame': {
          await updateLearningGame(validateElement.id, { productionStatus: validateElement.to });
          break;
        }

        case 'audioChunk': {
          await updateLessonAudioChunk(validateElement.id, { productionStatus: validateElement.to });
          break;
        }
      }
      setValidateElement(null);
      navigate(-1);
      toast.success('Successfully changed status to PROD!');
    }
  };

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[10%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative w-full max-w-2xl h-full md:h-auto'>
        <button
          className='absolute right-5 top-4 z-10 dark:text-white text-[1.2rem]'
          onClick={() => setValidateElement(null)}
        >
          X
        </button>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>
              Validate {validateElement?.type || ''}
            </h3>
          </div>
          <div className='px-6'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 pb-8'>
              <div className='flex flex-col overflow-scroll rounded-lg min-h-[100px] items-center'>
                {isValidating ? (
                  <div className='flex h-[100%] justify-center  mt-5'>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {errors.length > 0 ? (
                      <>
                        <p className='dark:text-white font-bold my-4'>
                          There were errors! (These must be rectified before we can make it {validateElement?.to})
                        </p>
                        {warnings.length > 0 ? <WarningErrorList isWarnNotError={true} items={warnings} /> : null}
                        <WarningErrorList isWarnNotError={false} items={errors} />
                      </>
                    ) : (
                      <>
                        <p className='dark:text-white font-bold mt-4'>It is ready to switch to {validateElement?.to}</p>
                        {validateElement?.to === 'PROD' ? (
                          <p className='dark:text-white font-bold mt-2'>
                            {"Once it's live, some of the fields may no longer be editable!"}
                          </p>
                        ) : null}

                        {warnings.length > 0 ? (
                          <div className='mt-4 w-full'>
                            <WarningErrorList isWarnNotError={true} items={warnings} />
                          </div>
                        ) : null}

                        <button
                          className='mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          onClick={onPress}
                        >
                          Change to {validateElement?.to}
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateElementModal;
