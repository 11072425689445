// *******************************************************
// LessonAudioChunksPage
// -------------------------------------------------------
// This is a LessonAudioChunksPage
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import LessonAudioChunksList from '../../components/lessonAudioChunks/LessonAudioChunksList';
import { useAppContext } from '../../contexts/appContext';
import { useEffect } from 'react';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const LessonAudioChunksPage = () => {
  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Lesson Audio Chunks',
        path: '/lessonAudioChunks',
      },
    ]);
  }, [setBreadcrumbPaths]);

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Lesson Audio Chunks</h1>
        </div>
        {/*<div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>*/}
        {/*  <button*/}
        {/*    onClick={() => navigate('/lessons/import')}*/}
        {/*    type='button'*/}
        {/*    className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 mx-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'*/}
        {/*  >*/}
        {/*    Import Lesson*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
      <LessonAudioChunksList />
    </>
  );
};

export default LessonAudioChunksPage;
