// *******************************************************
// Cohort Report Page
// -------------------------------------------------------
// This is a Screen for showing charts for a cohort
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
// *******************************************
// Component Imports
// -------------------------------------------
import BarChart from '../../components/BarChart';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
import { useAppContext } from '../../contexts/appContext';
import Spinner from '../../components/Spinner';
// import { TrackType } from '../../modeltypes/tracks';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const AllCohortReportPage = () => {
  const [stickkyData, setStickyData] = useState<any>();

  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Cohorts',
        path: '/cohorts',
      },
      {
        label: 'Report',
        path: `/cohorts/statistics`,
      },
    ]);
  }, [setBreadcrumbPaths]);

  useEffect(() => {
    console.log('add data');
    setStickyData(null);
  }, []);

  return (
    <div>
      <h3 className='text-3xl leading-6 font-medium text-sky-700 text-center mb-10'>{'Sticky Data'}</h3>

      <p className='text-2xl font-medium mt-5 mb-5 text-sky-500'>{'Since the beginning of cohort'}</p>
      {stickkyData ? (
        <BarChart chartTitle={['%Complete', 'Lessons']} {...stickkyData} zoomDomainXMax={10} />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default AllCohortReportPage;
