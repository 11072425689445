// *******************************************************
// Audio Nodes Form
// -------------------------------------------------------
// This is a Screen for editing audioNode
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { addNewAudioNode, updateAudioNode } from '../../collections/audioNode';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ReactSelectOptions } from '../../types/types';
import { appendAudioNodeToChapter } from '../../collections/chapter';
import { ReactComponent as CopyToCliboardIcon } from '../../assets/copyToClipboard.svg';
import ReactAudioPlayer from 'react-audio-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileUploader } from 'react-drag-drop-files';
import { mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';

import {
  appendBreadcrumbIfNecessary,
  dieIfNullOrUndef,
  emptyStringToNull,
  hmsDurationToSeconds,
  nullUndefToEmptyString,
  replaceUndefNull,
  secondsDurationToHms,
  undefOrEmptyStringToNull,
} from '../../utility/GeneralUtilities';
import { useAppContext } from '../../contexts/appContext';
import { uploadFileNoThrow } from '../../firebase/storage';
import {
  AudioNodeType,
  HomeworkStatus,
  SwitchType,
  switchTypeFromString,
  TrackType,
  trackTypeFromString,
} from '../../modeltypes/audioNode';
import { getAudioDuration } from '../../utility/uploadHandler';
import { uiFormatTimestamp } from '../../collections/shared';
import { SwitchOption } from 'src/utility/ScriptModel';
import { toast } from 'react-toastify';
import { GoalType } from '../../modeltypes/goals';
import { getAllGoals } from '../../collections/goals';
import { Experience } from 'src/enums/goals';
import { TagId } from 'src/modeltypes/id';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';

// *******************************************
// Constants
// -------------------------------------------

const trackTypeOptions: ReactSelectOptions[] = [
  {
    value: TrackType.H_CHECK,
    label: 'Homework Check',
  },
  {
    value: TrackType.STORY,
    label: 'Spoken Word',
  },
  {
    value: TrackType.DECISION,
    label: 'Decision Prompt',
  },
  {
    value: TrackType.CHOOSE_PRACTICE,
    label: 'Choose Practice',
  },
  {
    value: TrackType.SWITCH,
    label: 'Switch Logic',
  },
];

const switchTypes: ReactSelectOptions[] = [
  {
    value: SwitchType.GOALS,
    label: 'Goals',
  },
  {
    value: SwitchType.EXPERIENCE,
    label: 'Experience',
  },
];

const homeworkStatusOptions: ReactSelectOptions[] = [
  {
    value: HomeworkStatus.DONE,
    label: 'Homework Done',
  },
  {
    value: HomeworkStatus.NOT_DONE,
    label: 'Homework Not Done',
  },
];
// *******************************************
// Types
// -------------------------------------------

interface AudioNodesFormProps {
  afterSubmitFunction: (node: AudioNodeType) => void;
  audioNode: AudioNodeType | null;
  chapterId?: string;
  onCloseFunction: () => void;
  setAudioNode?: (audioNode: AudioNodeType) => void;
}

const AudioNodesForm = ({
  audioNode,
  afterSubmitFunction,
  onCloseFunction,
  chapterId,
  setAudioNode,
}: AudioNodesFormProps) => {
  const { breadcrumbPaths, setBreadcrumbPaths } = useAppContext();
  const [editUrlOn, setEditUrlOn] = useState<boolean>(false);
  const [isHomeworkResponse, setIsHomeworkResponse] = useState(false);
  const [isNodeTypeSelected, setIsNodeTypeSelected] = useState(false);
  const [tags, setTags] = useState<ReactSelectOptions[]>([]);

  const getTags = async () => {
    const dbTags = await getAllTags();
    const selectorT = dbTags.map((dbTag: TagType) => ({
      label: `${dbTag.name}`,
      value: dbTag?.id,
    }));
    setTags(selectorT);
  };

  useEffect(() => {
    getTags();
  }, []);

  const [goals, setGoals] = useState<GoalType[] | null>(null);

  const experiences = Object.values(Experience).map((v) => {
    return {
      name: v,
      id: v.toUpperCase(),
    };
  });
  // const anRef = audioNodeId ? getAudioNodeRefById(audioNodeId) : getAudioNodeRefById('notarealid');
  // const { data: audioNode } = useFirestoreDocData(dieIfNullOrUndef(anRef), { idField: 'id' });

  const { values, handleSubmit, handleChange, setValues, isSubmitting } = useFormik<{
    parentChapterId?: string;
    title: string;
    code: string;
    summaryText: string;
    duration: string;
    spokenText?: string;
    singleTap?: string;
    doubleTap?: string;
    tripleTap?: string;
    mlAttribute?: TagId | null;
    singleTapTagId?: TagId | null;
    doubleTapTagId?: TagId | null;
    tripleTapTagId?: TagId | null;
    nextSubsection?: string;
    path?: string;
    pathCode?: string;
    silenceDuration?: string;
    silenceFixedLen?: boolean;
    importedFromGDocID?: string;
    nodeType?: ReactSelectOptions;
    audioUrl: string;
    audioUrlFile: File | null;
    homeworkStatus?: ReactSelectOptions | null;
    homeworkAnswer?: string | null;
    subType?: ReactSelectOptions | null;
    switchOptions?: Array<SwitchOption> | null;
    shouldCheckHomework: boolean;
    toCheck: boolean;
  }>({
    initialValues: {
      parentChapterId: chapterId,
      title: '',
      code: '',
      summaryText: '',
      duration: '0',
      spokenText: '',
      singleTap: '',
      doubleTap: '',
      tripleTap: '',
      singleTapTagId: null,
      mlAttribute: null,
      doubleTapTagId: null,
      tripleTapTagId: null,
      nextSubsection: '',
      path: '',
      pathCode: '',
      silenceDuration: '0',
      silenceFixedLen: true,
      importedFromGDocID: '',
      audioUrl: '',
      audioUrlFile: null,
      subType: null,
      switchOptions: null,
      shouldCheckHomework: false,
      toCheck: false,
    },
    onSubmit: async (values) => {
      console.log('onSubmit values; : ', values);
      let uploadedFileDuration: number | null = null;

      if (audioNode && audioNode?.audioUrl !== values.audioUrl && values.audioUrl !== '' && values.audioUrlFile) {
        const storageFileName = `${audioNode.id}-${audioNode.code}-nodeAudio.m4a`;

        const uploadResult = await uploadFileNoThrow(dieIfNullOrUndef(values.audioUrlFile), storageFileName, [
          'assets',
          audioNode.id,
        ]);
        if (uploadResult.error) {
          throw uploadResult.error;
        }
        values.audioUrl = dieIfNullOrUndef(uploadResult.downloadUrl);
        // Update the duration from the file.
        uploadedFileDuration = uploadResult.downloadUrl ? await getAudioDuration(uploadResult.downloadUrl) : null;
      }

      const durationToUse = uploadedFileDuration ? uploadedFileDuration : hmsDurationToSeconds(values.duration);

      const newAudioNodeValues = {
        title: values.title,
        code: emptyStringToNull(values.code),
        summaryText: values.summaryText,
        duration: durationToUse,
        spokenText: values.spokenText,
        singleTap: emptyStringToNull(values.singleTap),
        doubleTap: emptyStringToNull(values.doubleTap),
        tripleTap: emptyStringToNull(values.tripleTap),
        singleTapTagId: values.singleTapTagId,
        mlAttribute: values.mlAttribute,
        doubleTapTagId: values.doubleTapTagId,
        tripleTapTagId: values.tripleTapTagId,
        nextSubsection: emptyStringToNull(values.nextSubsection),
        path: emptyStringToNull(values.path),
        pathCode: emptyStringToNull(values.pathCode),
        silenceDuration: hmsDurationToSeconds(undefOrEmptyStringToNull(values.silenceDuration)),
        silenceFixedLen: !!values.silenceFixedLen,
        importedFromGDocID: emptyStringToNull(values.importedFromGDocID),
        audioUrl: values.audioUrl,
        subType: values.subType?.value ? switchTypeFromString(values.subType.value) : null,
        nodeType: trackTypeFromString(values.nodeType?.value) || null,
        homeworkStatus:
          isHomeworkResponse && values.homeworkStatus?.value ? (values.homeworkStatus.value as HomeworkStatus) : null,
        homeworkAnswer:
          isHomeworkResponse && values.homeworkAnswer && values.homeworkAnswer.length > 0
            ? values.homeworkAnswer
            : null,
        shouldCheckHomework: values.shouldCheckHomework,
        toCheck: values.toCheck,
      };
      console.log('onSubmit newAudioNodeValues; : ', newAudioNodeValues);

      if (!audioNode) {
        const newID = await addNewAudioNode(newAudioNodeValues);
        if (chapterId) {
          await appendAudioNodeToChapter(chapterId, newID);
        }
        toast.success(`Audio Node - ${values.title} - successfully created!`);
        afterSubmitFunction({ id: newID, ...newAudioNodeValues });
        setAudioNode
          ? setAudioNode({
              ...newAudioNodeValues,
              id: newID,
            })
          : null;
      } else {
        const updateAudioNodeValues: AudioNodeType = {
          id: audioNode.id,
          ...newAudioNodeValues,
        };
        await updateAudioNode(audioNode.id, updateAudioNodeValues);
        toast.success(`Audio Node - ${values.title} - successfully updated!`);
        afterSubmitFunction({ ...updateAudioNodeValues });
        setAudioNode ? setAudioNode(updateAudioNodeValues) : null;
      }
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
    }),
  });

  useEffect(() => {
    if (audioNode) {
      if (audioNode?.nodeType) {
        setIsNodeTypeSelected(true);
      }
      const valsToSet = {
        title: nullUndefToEmptyString(audioNode.title),
        code: nullUndefToEmptyString(audioNode.code),
        summaryText: nullUndefToEmptyString(audioNode.summaryText),
        duration: secondsDurationToHms(audioNode.duration || 0),
        spokenText: nullUndefToEmptyString(audioNode.spokenText),
        singleTap: nullUndefToEmptyString(audioNode.singleTap),
        doubleTap: nullUndefToEmptyString(audioNode.doubleTap),
        tripleTap: nullUndefToEmptyString(audioNode.tripleTap),
        singleTapTagId: audioNode.singleTapTagId,
        doubleTapTagId: audioNode.doubleTapTagId,
        tripleTapTagId: audioNode.tripleTapTagId,
        mlAttribute: audioNode.mlAttribute,
        nextSubsection: nullUndefToEmptyString(audioNode.nextSubsection),
        path: nullUndefToEmptyString(audioNode.path),
        pathCode: nullUndefToEmptyString(audioNode.pathCode),
        silenceDuration: secondsDurationToHms(audioNode.silenceDuration || 0),
        silenceFixedLen: replaceUndefNull(audioNode.silenceFixedLen, false),
        importedFromGDocID: nullUndefToEmptyString(audioNode.importedFromGDocID),
        audioUrl: nullUndefToEmptyString(audioNode.audioUrl),
        audioUrlFile: null,
        nodeType: trackTypeOptions.find(({ value }) => audioNode.nodeType === value),
        subType: switchTypes.find(({ value }) => audioNode.subType === value),
        homeworkStatus: homeworkStatusOptions.find(({ value }) => value === audioNode.homeworkStatus),
        switchOptions: audioNode.switchOptions,
        shouldCheckHomework: audioNode.shouldCheckHomework || false,
        toCheck: audioNode.toCheck || false,
      };
      setValues(valsToSet);
      setIsHomeworkResponse(!!audioNode.homeworkStatus);
      const thisBreadcrumb = {
        label: nullUndefToEmptyString(audioNode.title),
        path: window.location.pathname,
      };
      appendBreadcrumbIfNecessary(thisBreadcrumb, breadcrumbPaths, setBreadcrumbPaths);
    } else {
      const thisBreadcrumb = {
        label: 'New Audio Node',
        path: window.location.pathname,
      };
      appendBreadcrumbIfNecessary(thisBreadcrumb, breadcrumbPaths, setBreadcrumbPaths);
    }
  }, [setValues, audioNode, breadcrumbPaths, setBreadcrumbPaths]);

  useEffect(() => {
    getAllGoals().then((fGoals) => {
      setGoals(fGoals);
    });
  }, []);

  const handleUploadDrop = useCallback(
    (file: File): void => {
      const url = URL.createObjectURL(file);
      setValues({
        ...values,
        audioUrl: url,
        audioUrlFile: file,
      });
    },
    [values, setValues],
  );

  if (!isNodeTypeSelected) {
    return (
      <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
        <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
          <div className='space-y-6 sm:space-y-5'>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start'>
              <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Node Type
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <Select
                  options={trackTypeOptions}
                  value={values.nodeType}
                  onChange={(selectedTrackType) => {
                    setValues({
                      ...values,
                      nodeType: selectedTrackType as ReactSelectOptions,
                    });
                    setIsNodeTypeSelected(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  return (
    <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
      {audioNode?.createdAt && audioNode?.updatedAt ? (
        <p>
          <strong>Created:</strong>&nbsp;{uiFormatTimestamp(audioNode?.createdAt)}
          &nbsp;&nbsp;
          <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(audioNode?.updatedAt)}
        </p>
      ) : null}
      <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start'>
          <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
            Node Type
          </label>
          <div className='block flex z-40 mt-1 sm:mt-0 sm:col-span-2'>
            <Select
              menuPosition={'fixed'}
              options={trackTypeOptions}
              value={values.nodeType}
              onChange={(selectedTrackType) => {
                setValues({
                  ...values,
                  nodeType: selectedTrackType as ReactSelectOptions,
                  subType: null,
                  switchOptions: null,
                });
              }}
            />
          </div>
          <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
            {audioNode?.id}
          </label>
        </div>

        {/* For Switch Node show the slector of switches */}
        {values.nodeType?.value === 'SWITCH' && (
          <div className='mt-8 flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                <div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Switch Type
                    </label>
                    <div className='py-5 sm:space-y-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start'>
                      <div className='flex mt-1 sm:mt-0 sm:col-span-2'>
                        <Select
                          menuPosition={'fixed'}
                          className={`max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                          options={switchTypes}
                          value={values.subType}
                          onChange={(x) => {
                            setValues({
                              ...values,
                              subType: x,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='flex sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                    <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'></label>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* This is for every Node */}

        <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Title
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='title'
                  id='title'
                  autoComplete='title'
                  className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='code' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Code
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='code'
                  id='code'
                  autoComplete='code'
                  className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                  value={values.code}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='summaryText' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Summary Text
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <textarea
                id='summaryText'
                name='summaryText'
                rows={2}
                className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                value={values.summaryText}
                onChange={handleChange}
              />
            </div>
          </div>

          {values.nodeType?.value === TrackType.STORY ? (
            <>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='duration' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Duration (H:M:S)
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='duration'
                      id='duration'
                      autoComplete='duration'
                      className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                      value={values.duration}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='spokenText' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Spoken Text
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <textarea
                    id='spokenText'
                    name='spokenText'
                    rows={3}
                    className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                    value={values.spokenText}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </>
          ) : null}

          {values.nodeType?.value === TrackType.DECISION ? (
            <>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
                <label htmlFor='singleTapTag' className='text-sm font-medium text-gray-700'>
                  Machine Learning Attribute
                </label>
                <div className='flex mt-1 sm:mt-0'>
                  <Select
                    options={tags}
                    value={tags.find((t) => t.value === values.mlAttribute)}
                    onChange={(vals) => {
                      console.log('mlAttribute vals: ', vals);
                      setValues({
                        ...values,
                        mlAttribute: vals?.value,
                      });

                      // setSelectedTags(vals as ReactSelectOptions[]);
                    }}
                    className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                  />
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='duration' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Duration (H:M:S)
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='duration'
                      id='duration'
                      autoComplete='duration'
                      className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                      value={values.duration}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='spokenText' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Spoken Text
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <textarea
                    id='spokenText'
                    name='spokenText'
                    rows={3}
                    className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                    value={values.spokenText}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='singleTap' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Single Tap Destination
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      disabled
                      type='text'
                      name='singleTap'
                      id='singleTap'
                      autoComplete='singleTap'
                      className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                      value={values.singleTap}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
                    <label htmlFor='singleTapTag' className='text-sm font-medium text-gray-700'>
                      Single Tap Tag
                    </label>
                    <div className='flex mt-1 sm:mt-0'>
                      <Select
                        options={tags}
                        value={tags.find((t) => t.value === values.singleTapTagId)}
                        onChange={(vals) => {
                          console.log('single tap tag vals: ', vals);
                          setValues({
                            ...values,
                            singleTapTagId: vals?.value,
                          });

                          // setSelectedTags(vals as ReactSelectOptions[]);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='doubleTap' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Double Tap Destination
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      disabled
                      type='text'
                      name='doubleTap'
                      id='doubleTap'
                      autoComplete='doubleTap'
                      className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                      value={values.doubleTap}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
                    <label htmlFor='singleTapTag' className='text-sm font-medium text-gray-700'>
                      Double Tap Tag
                    </label>
                    <div className='flex mt-1 sm:mt-0'>
                      <Select
                        options={tags}
                        value={tags.find((t) => t.value === values.doubleTapTagId)}
                        onChange={(vals) => {
                          console.log('doubleTapTagId tap tag vals: ', vals);
                          setValues({
                            ...values,
                            doubleTapTagId: vals?.value,
                          });

                          // setSelectedTags(vals as ReactSelectOptions[]);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='tripleTap' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Triple Tap Destination
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      disabled
                      type='text'
                      name='tripleTap'
                      id='tripleTap'
                      autoComplete='tripleTap'
                      className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                      value={values.tripleTap}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
                    <label htmlFor='singleTapTag' className='text-sm font-medium text-gray-700'>
                      Tripple Tap Tag
                    </label>
                    <div className='flex mt-1 sm:mt-0'>
                      <Select
                        options={tags}
                        value={tags.find((t) => t.value === values.tripleTapTagId)}
                        onChange={(vals) => {
                          console.log('tripple tap tag vals: ', vals);
                          setValues({
                            ...values,
                            tripleTapTagId: vals?.value,
                          });

                          // setSelectedTags(vals as ReactSelectOptions[]);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {values.nodeType?.value === 'SWITCH' ? (
            <>
              {values.subType?.value === 'GOALS' && (
                <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Set GOAL Destination
                  </label>

                  {goals?.map((goal) => {
                    const thisValue = values?.switchOptions?.find((x) => x.type === goal.id)?.nextNode;
                    return (
                      <div
                        key={`${goal.id}`}
                        className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'
                      >
                        <label htmlFor='tripleTap' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                          {goal.name}
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <div className='max-w-lg flex rounded-md shadow-sm'>
                            <input
                              disabled
                              type='text'
                              name='switchOptions '
                              id='switchOptions '
                              autoComplete='switchOptions '
                              className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                              value={thisValue || ''}
                              onChange={(x) => {
                                const newSwitchOptions = values.switchOptions
                                  ? values.switchOptions?.filter((x) => x?.type !== goal.id)
                                  : [];
                                setValues({
                                  ...values,
                                  switchOptions: [
                                    ...newSwitchOptions,
                                    {
                                      type: goal.id,
                                      nextNode: x?.target?.value,
                                    },
                                  ],
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {values.subType?.value === 'EXPERIENCE' && (
                <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Set Experience Destination
                  </label>

                  {experiences?.map((experience) => {
                    const thisValue = values?.switchOptions?.find((x) => x.type === experience.id)?.nextNode;
                    return (
                      <div
                        key={`${experience.id}`}
                        className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'
                      >
                        <label htmlFor='tripleTap' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                          {experience.name}
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <div className='max-w-lg flex rounded-md shadow-sm'>
                            <input
                              disabled
                              type='text'
                              name='switchOptions '
                              id='switchOptions '
                              autoComplete='switchOptions '
                              className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                              value={thisValue || ''}
                              onChange={(x) => {
                                const newSwitchOptions = values.switchOptions
                                  ? values.switchOptions?.filter((x) => x?.type !== experience.id)
                                  : [];
                                setValues({
                                  ...values,
                                  switchOptions: [
                                    ...newSwitchOptions,
                                    {
                                      type: experience.id,
                                      nextNode: x?.target?.value,
                                    },
                                  ],
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='nextSubsection' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Next/Special Destination
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    disabled
                    type='text'
                    name='nextSubsection'
                    id='nextSubsection'
                    autoComplete='nextSubsection'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    value={values.nextSubsection}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}

          {values.nodeType?.value === TrackType.STORY && (
            <>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='silenceFixedLen' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Should check homework?
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md'>
                    <input
                      type='checkbox'
                      name='shouldCheckHomework'
                      id='shouldCheckHomework'
                      onChange={handleChange}
                      checked={values.shouldCheckHomework}
                    />
                  </div>
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='silenceFixedLen' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Is Homework Response Node?
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='checkbox'
                      name='homeworkResStatus'
                      onChange={() => setIsHomeworkResponse(!isHomeworkResponse)}
                      checked={isHomeworkResponse}
                    />
                  </div>
                </div>
              </div>
              {!isHomeworkResponse ? null : (
                <>
                  <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
                    <div className='space-y-6 sm:space-y-5'>
                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start'>
                        <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                          Homework Status
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <Select
                            options={homeworkStatusOptions}
                            value={values.homeworkStatus}
                            onChange={(selectedTrackType) => {
                              setValues({
                                ...values,
                                homeworkStatus: selectedTrackType as ReactSelectOptions,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Homework Response Answer
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <div className='max-w-lg flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='homeworkAnswer'
                          id='homeworkAnswer'
                          autoComplete='homeworkAnswer'
                          className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                          value={values.homeworkAnswer || ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Following Silence Duration (H:M:S)
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='silenceDuration'
                  id='silenceDuration'
                  autoComplete='silenceDuration'
                  className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                  value={values.silenceDuration}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='silenceFixedLen' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Silence Duration is Fixed?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <input
                type='checkbox'
                name='silenceFixedLen'
                onChange={handleChange}
                checked={replaceUndefNull(values.silenceFixedLen, false)}
              />
            </div>
          </div>

          {(values.nodeType?.value === TrackType.STORY || values.nodeType?.value === TrackType.H_CHECK) && (
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='cover-photo' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Existing Audio
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2' style={{ display: 'inline-block' }}>
                <div className='space-y-1 text-left' style={{ display: 'inline-block' }}>
                  {!audioNode?.audioUrl || audioNode?.audioUrl.startsWith('blob') ? (
                    <span className='text-sm'>None</span>
                  ) : (
                    <>
                      <ReactAudioPlayer
                        src={audioNode?.audioUrl}
                        autoPlay={false}
                        controls={true}
                        style={{ display: 'inline-block' }}
                      />
                      <a
                        href={audioNode?.audioUrl}
                        className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                        onClick={() => false}
                        style={{ display: 'inline-block' }}
                        title={'Link to audio file.'}
                      >
                        Audio File
                      </a>
                      <CopyToClipboard text={audioNode?.audioUrl}>
                        <button type={'button'}>
                          <CopyToCliboardIcon />
                        </button>
                      </CopyToClipboard>
                      <a
                        href={void 0}
                        className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                        style={{ display: 'inline-block' }}
                        title={'Toggle editing URL directly.'}
                        onClick={() => {
                          setEditUrlOn(!editUrlOn);
                        }}
                      >
                        Edit URL
                      </a>
                    </>
                  )}
                </div>
              </div>
              {!editUrlOn ? null : (
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='audioUrl' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Audio Asset URL
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='audioUrl'
                        id='audioUrl'
                        autoComplete='audioUrl'
                        className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                        value={values.audioUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {editUrlOn ? null : (
                <div className='flex sm:grid sm:col-span-3 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='cover-photo' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Upload Audio
                  </label>
                  <div className='pt-8 space-y-6 sm:pt-5 sm:space-y-5'>
                    <div className='flex flex-row gap-x-6 justify-center px-6 pb-6  '>
                      <div className='space-y-1 text-center'>
                        <FileUploader
                          handleChange={handleUploadDrop}
                          name='file'
                          types={['m4a', 'M4A']}
                          multiple={false}
                          hoverTitle={''}
                          maxSize={10}
                          minSize={0}
                          label={'Select or drag an audio file here.'}
                        />
                      </div>
                      {audioNode?.audioUrl && audioNode.audioUrl !== values.audioUrl && (
                        <ReactAudioPlayer src={values?.audioUrl} autoPlay={false} controls={true} />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {!values.importedFromGDocID?.length ? null : (
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='importedFromGDocID' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Google Doc ID
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='importedFromGDocID'
                    id='importedFromGDocID'
                    autoComplete='importedFromGDocID'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                    value={values.importedFromGDocID}
                    onChange={handleChange}
                  />
                  {!emptyStringToNull(values.importedFromGDocID) ? null : (
                    <a
                      href={`https://docs.google.com/document/d/${values.importedFromGDocID}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {' '}
                      <Icon path={mdiOpenInNew} size={1} style={{ display: 'inline-block' }} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {audioNode?.toCheck ? (
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='silenceFixedLen' className='block text-sm font-medium text-red-500 sm:mt-px sm:pt-2'>
              Check required
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <input type='checkbox' name='toCheck' onChange={handleChange} checked={values.toCheck} />
            </div>
          </div>
        ) : null}
      </div>

      <div className='pt-5'>
        <div className='flex justify-end'>
          <button
            disabled={isSubmitting}
            type='reset'
            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => onCloseFunction()}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            type='submit'
            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            {audioNode ? (isSubmitting ? 'Updating ' : 'Update') : 'Create'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AudioNodesForm;
