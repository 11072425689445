/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { incentiveTemplateIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { incentiveStageSchema } from './incentive';
import {
  IncentiveTemplateType,
  IncentiveTemplateTypeBase,
  IncentiveTemplateTypeBuilder,
  IncentiveTemplateTypeNew,
} from '../modeltypes/incentiveTemplate';

export const incentiveTemplateTypeSchema = z.object({
  id: incentiveTemplateIdSchema,
  name: z.string().optional(),
  description: z.string().optional(),
  enabled: z.boolean().optional(),
  moneyType: z.union([z.literal('DOLLAR'), z.literal('CREDIT')]).default('CREDIT'),
  stages: z.array(incentiveStageSchema).default(() => []),
});

export const incentiveTemplateTypeBaseSchema = incentiveTemplateTypeSchema.partial();

export const incentiveTemplateTypeBuilderSchema = incentiveTemplateTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: incentiveTemplateIdSchema.default(() => IdFactory.IncentiveTemplate()) }));

export const incentiveTemplateTypeNewSchema = incentiveTemplateTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: incentiveTemplateIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: IncentiveTemplateType = incentiveTemplateTypeSchema.parse(undefined);
  const x: IncentiveTemplateTypeBase = incentiveTemplateTypeBaseSchema.parse(undefined);
  const y: IncentiveTemplateTypeBuilder = incentiveTemplateTypeBuilderSchema.parse(undefined);
  const z: IncentiveTemplateTypeNew = incentiveTemplateTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
