// *******************************************************
// AddUserModal
// -------------------------------------------------------
// This is a AddUserModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, SearchIcon } from '@heroicons/react/solid';
import { getAllAccounts } from '../../collections/account';
import { getUsersByIdArray } from '../../collections/user';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
type UserTableData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

interface AddUserModalProps {
  hide: () => void;
  addSelectedUsers: (userIds: string[]) => Promise<void>;
}

const AddUserModal = ({ addSelectedUsers, hide }: AddUserModalProps) => {
  const [users, setUsers] = useState<UserTableData[]>([]);

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [sortFilter, setSortFilter] = useState<{ key: keyof UserTableData; order: 'asc' | 'desc' }>();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!users?.length) return [];
    return users
      .filter((item) => {
        const matchFirstName = item.firstName && item.firstName.match(regex);
        const matchLastName = item.lastName && item.lastName.match(regex);
        const matchEmail = item.email && item.email.match(regex);
        return matchFirstName || matchLastName || matchEmail;
      })
      .sort((a, b) => {
        if (!sortFilter || !a[sortFilter.key]) {
          return 0;
        }
        if (sortFilter.order === 'desc') {
          return a[sortFilter.key].toString().localeCompare(b[sortFilter.key].toString());
        }
        if (sortFilter.order === 'asc') {
          return b[sortFilter.key].toString().localeCompare(a[sortFilter.key].toString());
        }
        return 0;
      });
  }, [searchTerm, users, sortFilter]);

  useEffect(() => {
    const prepareData = async () => {
      const allAccounts = await getAllAccounts();
      const filtered = allAccounts.filter((account) => !account.companyId);
      const users = await getUsersByIdArray(filtered.map((acc) => acc.id || ''));
      const tempArray: UserTableData[] = [];
      for (const account of filtered) {
        const user = users.find((user) => user.id === account.uid);
        if (user && account.email) {
          tempArray.push({
            id: account.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: account.email,
          });
        }
      }
      setUsers(tempArray);
    };
    prepareData().then(() => setIsLoading(false));
  }, []);

  const selectRemoveUser = (user: UserTableData) => {
    setSelectedUsers((su) => {
      if (su.includes(user.id)) {
        return su.filter((el) => el !== user.id);
      }
      return [...su, user.id];
    });
  };

  const sortRequest = (fieldName: keyof UserTableData) => {
    let order: 'asc' | 'desc' = 'asc';
    if (sortFilter && sortFilter.key === fieldName && sortFilter.order === 'asc') {
      order = 'desc';
    }
    setSortFilter({
      key: fieldName,
      order: order,
    });
  };

  const selectRemoveAll = (target: HTMLInputElement) => {
    if (target.checked) {
      setSelectedUsers(users.map((el) => el.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const _addSelectedUsers = () => {
    setIsPending(true);
    addSelectedUsers(selectedUsers).catch((error) => {
      setIsPending(false);
      console.error(error);
    });
  };

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative p-4 w-full max-w-3xl h-full md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Add users to company </h3>
            <button
              type='button'
              className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
              onClick={hide}
            >
              <svg
                aria-hidden='true'
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
              <span className='sr-only'>Close modal</span>
            </button>
          </div>
          <div className='p-6 space-y-6'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              {isLoading ? (
                <p className='text-white'>Loading</p>
              ) : (
                <>
                  <div className='relative w-full text-gray-400 focus-within:text-gray-600 mb-5 md:rounded-lg'>
                    <div className='absolute inset-y-0 left-2 flex items-center pointer-events-none'>
                      <SearchIcon className='h-5 w-5' aria-hidden='true' />
                    </div>
                    <input
                      id='search-field'
                      className='rounded-xl block w-full h-full pl-9 pr-3 py-4 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                      placeholder='Search'
                      type='search'
                      name='search'
                      onInput={(e) => setSearchTerm((e.target as HTMLInputElement).value)}
                      value={searchTerm}
                    />
                  </div>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[30vw] overflow-y-auto'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead className='bg-white'>
                        <tr>
                          <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                            <input
                              type={'checkbox'}
                              onChange={(e) => selectRemoveAll(e.target)}
                              checked={selectedUsers.length === users.length}
                            />
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            onClick={() => sortRequest('firstName')}
                          >
                            <p className='group inline-flex'>
                              First Name
                              <span
                                className={`${sortFilter?.key !== 'firstName' && 'invisible'} ${
                                  sortFilter?.order === 'asc' && 'rotate-180'
                                } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                              >
                                <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                              </span>
                            </p>
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            onClick={() => sortRequest('lastName')}
                          >
                            <p className='group inline-flex'>
                              Last Name
                              <span
                                className={`${sortFilter?.key !== 'lastName' && 'invisible'} ${
                                  sortFilter?.order === 'asc' && 'rotate-180'
                                } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                              >
                                <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                              </span>
                            </p>
                          </th>

                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            onClick={() => sortRequest('email')}
                          >
                            <p className='group inline-flex'>
                              Email
                              <span
                                className={`${sortFilter?.key !== 'email' && 'invisible'} ${
                                  sortFilter?.order === 'asc' && 'rotate-180'
                                } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                              >
                                <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                              </span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        {filteredItems.map((user) => (
                          <tr key={user.id} className='hover:bg-stone-200'>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                              <input
                                type={'checkbox'}
                                checked={selectedUsers.includes(user.id)}
                                onChange={() => selectRemoveUser(user)}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='font-medium text-gray-900'>{user.firstName}</div>
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='font-medium text-gray-900'>{user.lastName}</div>
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='font-medium text-gray-900'>{user.email}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600'>
            <button
              disabled={selectedUsers.length === 0 || isPending}
              onClick={_addSelectedUsers}
              type='button'
              className={`${
                selectedUsers.length === 0 ? 'opacity-25' : ''
              } w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
            >
              {isPending ? 'Adding...' : 'Add'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
