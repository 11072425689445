// This file contains other items that have been abstracted but needed a type.

import { ReactSelectOptions } from 'src/types/types';

/**
 * @type PositionType
 * This is for mobile Types and where to place them in the mobile app
 */
export type PositionType = 'center' | 'left' | 'right';

/**
 * @type MobilePosition
 * This is for mobile Types and where to place them in the mobile app
 */

export enum MobilePosition {
  center = 'center',
  left = 'left',
  right = 'right',
}

/**
 * @type MobilePositionOptions
 * This is for mobile Types and where to place them in the mobile app
 */

export const mobilePositionOptions: ReactSelectOptions[] = Object.keys(MobilePosition).map((item: string) => {
  return {
    value: item,
    label: item,
  };
});
