// *******************************************************
// NotificationsForm
// -------------------------------------------------------
// This is a NotificationsForm
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { NotificationsType, NotificationType, NotificationTypeBase } from '../../modeltypes/notification';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../contexts/appContext';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { addNewNotification, getNotificationById, updateNotification } from '../../collections/notifications';
import Skeleton from 'react-loading-skeleton';
import { uiFormatTimestamp } from '../../collections/shared';
import Select from 'react-select';
import { RewardType } from '../../modeltypes/rewards';
import { GoalType } from '../../modeltypes/goals';
import { getAllRewards } from '../../collections/rewards';
import { getAllGoals } from '../../collections/goals';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------
const NOTIFICATION_TYPE_OPTIONS = [
  {
    value: NotificationsType.TIP,
    label: NotificationsType.TIP,
  },
  {
    value: NotificationsType.GOALS,
    label: NotificationsType.GOALS,
  },
  {
    value: NotificationsType.REWARDS,
    label: NotificationsType.REWARDS,
  },
  {
    value: NotificationsType.STREAK,
    label: NotificationsType.STREAK,
  },
  {
    value: NotificationsType.COMEBACK,
    label: NotificationsType.COMEBACK,
  },
];

// *******************************************
// Types
// -------------------------------------------

const NotificationsForm = () => {
  const [notification, setNotification] = useState<NotificationType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [rewards, setRewards] = useState<RewardType[] | null>(null);
  const [goals, setGoals] = useState<GoalType[] | null>(null);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();

  const { values, errors, handleSubmit, handleChange, setValues, setFieldValue } = useFormik<NotificationTypeBase>({
    validateOnChange: false,
    initialValues: {
      title: '',
      message: '',
      type: undefined,
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      if (!values.title || values.title.trim().length === 0) {
        errors.title = 'Title is required';
      }
      if (!values.message || values.message.trim().length === 0) {
        errors.message = 'Message is required';
      }
      if (!values.type) {
        errors.type = 'Type is required';
      } else {
        if (
          (values.type === NotificationsType.REWARDS || values.type === NotificationsType.GOALS) &&
          !values.assignedTo
        ) {
          errors.assignedTo = 'Assigned item is required';
        } else if ([NotificationsType.STREAK, NotificationsType.COMEBACK].includes(values.type) && !values.dayCount) {
          errors.dayCount = 'Streak count is required';
        }
      }
      return errors;
    },
    onSubmit: async (challengeValues) => {
      toast
        .promise(
          async () => {
            let notificationId = notification?.id;

            if (!notificationId) {
              notificationId = await addNewNotification();
            }
            console.log(values.streak?.isUserActive);

            const updateData: NotificationTypeBase = {
              title: challengeValues.title,
              type: values.type,
              message: values.message,
              assignedTo: values.assignedTo || null,
              dayCount: values.dayCount || null,
            };

            await updateNotification(notificationId, {
              ...updateData,
            });
          },
          {
            pending: `${notification ? 'Updating' : 'Adding'} ${challengeValues.title} Notification...`,
            error: "Can't do it now, try again.",
            success: `${notification ? 'Updated' : 'Added'} ${challengeValues.title} Notification!`,
          },
        )
        .then(() => {
          navigate('/notifications');
        });
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const fNotification = await getNotificationById(id);
        if (fNotification === null) {
          navigate('/Challenges');
          return;
        }
        setNotification(fNotification);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  useEffect(() => {
    if (notification) {
      setValues({
        title: notification.title || '',
        message: notification.message || '',
        type: notification.type,
        assignedTo: notification.assignedTo,
        dayCount: notification.dayCount,
      });
      setBreadcrumbPaths([
        {
          label: 'Notifications',
          path: '/notifications',
        },
        {
          label: notification?.title || 'New Notification',
          path: `/notifications/${id}`,
        },
      ]);
    }
  }, [notification]);

  useEffect(() => {
    if (values.type === NotificationsType.REWARDS && !rewards) {
      getAllRewards().then((fRewards) => setRewards(fRewards));
    }
    if (values.type === NotificationsType.GOALS && !goals) {
      getAllGoals().then((fGoals) => setGoals(fGoals));
    }
  }, [values.type]);

  const renderFormWithType = () => {
    if (values.type === NotificationsType.STREAK || values.type === NotificationsType.COMEBACK) {
      return (
        <>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Day count
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='max-w-lg flex rounded-md shadow-sm'>
                <input
                  maxLength={3}
                  type='number'
                  name='dayCount'
                  id='dayCount'
                  autoComplete='dayCount'
                  className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                    errors.dayCount ? 'border-red-300' : 'border-gray-300'
                  }`}
                  value={values.dayCount || 0}
                  onChange={(val) => {
                    if (+val.target.value >= 0) handleChange(val);
                  }}
                  placeholder={'Number'}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else if (values.type === NotificationsType.REWARDS || values.type === NotificationsType.GOALS) {
      const options =
        values.type === NotificationsType.REWARDS
          ? rewards?.map((el) => ({
              value: el.id,
              label: el.title,
            }))
          : goals?.map((el) => ({
              value: el.id,
              label: el.name,
            }));
      if (!options) {
        return (
          <>
            <Skeleton width={'100%'} className='mt-3' />
            <Skeleton width={'100%'} />
          </>
        );
      }
      return (
        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
          <div>
            <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Assigned to:
            </label>
          </div>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <Select
              options={options}
              value={options.find((el) => el.value === values.assignedTo)}
              onChange={(val) => {
                setFieldValue('assignedTo', val?.value);
              }}
              className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <>
          <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
            <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
              <div>
                <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                  Notification Details <span style={{ fontSize: '80%' }}>(ID: {notification?.id || 'not yet'})</span>
                </label>
                <p>
                  <strong>Created:</strong>&nbsp;{uiFormatTimestamp(notification?.createdAt)}
                  &nbsp;&nbsp;
                  <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(notification?.updatedAt)}
                </p>
                <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Title
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <div className='max-w-lg flex rounded-md shadow-sm'>
                        <input
                          maxLength={40}
                          type='text'
                          name='title'
                          id='title'
                          autoComplete='title'
                          className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                            errors.title ? 'border-red-300' : 'border-gray-300'
                          }`}
                          defaultValue={values.title || ''}
                          onChange={handleChange}
                          placeholder={'Notification Title'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <div>
                      <label htmlFor='message' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        Message
                      </label>
                    </div>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <textarea
                        id='message'
                        name='message'
                        rows={3}
                        className={`max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md ${
                          errors.message ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.message}
                        onChange={handleChange}
                        placeholder={'Type Notification Message'}
                      />
                      {values.type === 'Rewards' && (
                        <p className='text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                          Use #nextlevel to add it to the message
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                    <div>
                      <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        Type:
                      </label>
                    </div>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <Select
                        options={NOTIFICATION_TYPE_OPTIONS}
                        value={NOTIFICATION_TYPE_OPTIONS.find((el) => el.value === values.type)}
                        onChange={(val) => {
                          setFieldValue('type', val?.value);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                    </div>
                  </div>
                  {values.type ? renderFormWithType() : null}
                </div>
              </div>
            </div>
            {Object.values(errors).length ? (
              <p className={'text-red-400 text-center pt-5 font-bold '}>
                {Object.values(errors)
                  .map((x) => x)
                  .join(', ')}
              </p>
            ) : null}
            <div className='pt-5'>
              <div className='flex justify-end'>
                <button
                  type='button'
                  className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => navigate('/notifications')}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  {notification ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default NotificationsForm;
