/* eslint-disable @typescript-eslint/no-unused-vars */

// This exists to provide access to the various validating schemas for each type.
// Much is commented out, and will be uncommented as we make more progress migrating
// the system to use these validations. See src/models/README.md for more information
// on the schema validation philosophy.
//
// From the type-nerd perspective: this is necessary because of TS type erasure. We
// don't know the type of an object at runtime, so I'm using the tableName to tie
// types to schemas, which can be elegant because of the code that curries the
// tableName into the functions we re-vend to allow the rest of the app to interact
// with objects of the type at runtime. (see getUserById/getByIdFor... things like
// that)

import { z } from 'zod';
import { userTypeBaseSchema, userTypeBuilderSchema, userTypeNewSchema, userTypeSchema } from '../modelschemas/user';
import {
  sessionTypeSchema,
  sessionTypeBaseSchema,
  sessionTypeBuilderSchema,
  sessionTypeNewSchema,
} from '../modelschemas/session';
import {
  accountTypeBaseSchema,
  accountTypeBuilderSchema,
  accountTypeNewSchema,
  accountTypeSchema,
} from '../modelschemas/account';
import {
  audioNodeTypeBaseSchema,
  audioNodeTypeBuilderSchema,
  audioNodeTypeNewSchema,
  audioNodeTypeSchema,
} from '../modelschemas/audioNode';
import {
  chapterTypeBaseSchema,
  chapterTypeBuilderSchema,
  chapterTypeNewSchema,
  chapterTypeSchema,
} from '../modelschemas/chapter';
import {
  programTypeBaseSchema,
  programTypeBuilderSchema,
  programTypeNewSchema,
  programTypeSchema,
} from '../modelschemas/program';
import {
  lessonTypeBaseSchema,
  lessonTypeBuilderSchema,
  lessonTypeNewSchema,
  lessonTypeSchema,
} from '../modelschemas/lesson';

import {
  ACCESS_CODE_TABLE_NAME,
  ACCESS_REQUEST_TABLE_NAME,
  ACCESS_TABLE_NAME,
  ACCOUNT_TABLE_NAME,
  ADMIN_PAGE_TABLE_NAME,
  AFTER_SESSION_SURVEY_TABLE_NAME,
  AUDIO_NODE_TABLE_NAME,
  AUDIO_RECORDING_WHITELIST_TABLE_NAME,
  CALENDAR_TABLE_NAME,
  CHAPTER_TABLE_NAME,
  COACH_TABLE_NAME,
  COHORT_MEETING_TABLE_NAME,
  COHORT_TABLE_NAME,
  COMPANY_TABLE_NAME,
  DAILY_DIARY_TABLE_NAME,
  INCENTIVE_TABLE_NAME,
  INCENTIVE_TEMPLATE_TABLE_NAME,
  LESSON_HOMEWORK_TABLE_NAME,
  LESSON_PROGRESS_TEMPLATE_TABLE_NAME,
  LESSON_RATING_TABLE_NAME,
  LESSON_TABLE_NAME,
  MAIL_TABLE_NAME,
  NOTIFICATION_TEMPLATE_TABLE_NAME,
  PROGRAM_TABLE_NAME,
  REMOTE_CONFIG_TABLE_NAME,
  SCIENCE_DATA_TABLE_NAME,
  SESSIONS_TABLE_NAME,
  STATISTICS_DATA_TABLE_NAME,
  SURVEY_TABLE_NAME,
  USER_TABLE_NAME,
} from './tableName';
import {
  lessonRatingTypeBaseSchema,
  lessonRatingTypeBuilderSchema,
  lessonRatingTypeNewSchema,
  lessonRatingTypeSchema,
} from '../modelschemas/lessonRating';
import {
  incentiveTemplateTypeBaseSchema,
  incentiveTemplateTypeBuilderSchema,
  incentiveTemplateTypeNewSchema,
  incentiveTemplateTypeSchema,
} from '../modelschemas/incentiveTemplate';
import {
  cohortTypeBaseSchema,
  cohortTypeBuilderSchema,
  cohortTypeNewSchema,
  cohortTypeSchema,
} from '../modelschemas/cohort';
import {
  companyTypeBaseSchema,
  companyTypeBuilderSchema,
  companyTypeNewSchema,
  companyTypeSchema,
} from '../modelschemas/company';
import {
  scienceDataTypeBaseSchema,
  scienceDataTypeBuilderSchema,
  scienceDataTypeNewSchema,
  scienceDataTypeSchema,
} from '../modelschemas/scienceData';
import {
  cohortMeetingTypeBaseSchema,
  cohortMeetingTypeBuilderSchema,
  cohortMeetingTypeNewSchema,
  cohortMeetingTypeSchema,
} from '../modelschemas/cohortMeeting';
import {
  coachTypeBaseSchema,
  coachTypeBuilderSchema,
  coachTypeNewSchema,
  coachTypeSchema,
} from '../modelschemas/coach';
import {
  accessCodeTypeBaseSchema,
  accessCodeTypeBuilderSchema,
  accessCodeTypeNewSchema,
  accessCodeTypeSchema,
} from '../modelschemas/accessCode';
import {
  dailyDiaryTypeBaseSchema,
  dailyDiaryTypeBuilderSchema,
  dailyDiaryTypeNewSchema,
  dailyDiaryTypeSchema,
} from '../modelschemas/dailyDiary';
import {
  incentiveTypeBaseSchema,
  incentiveTypeBuilderSchema,
  incentiveTypeNewSchema,
  incentiveTypeSchema,
} from '../modelschemas/incentive';

export type SchemaRecord = {
  base: z.SomeZodObject;
  builder: z.SomeZodObject;
  fullyFormed: z.SomeZodObject;
  new: z.SomeZodObject;
};

export const Schemas: Record<string, SchemaRecord> = Object.freeze({
  [ACCESS_CODE_TABLE_NAME]: {
    base: accessCodeTypeBaseSchema,
    builder: accessCodeTypeBuilderSchema,
    fullyFormed: accessCodeTypeSchema,
    new: accessCodeTypeNewSchema,
  },

  // This whole table is a mess, and there's nothing here that accesses it, so I'm going to leave it.
  // [ACCESS_REQUESTS_TABLE_NAME]: {
  //   base: accessRequestsTypeBaseSchema,
  //   builder: accessRequestsTypeBuilderSchema,
  //   fullyFormed: accessRequestsTypeSchema,
  //   new: accessRequestsTypeNewSchema,
  // },
  //

  // This is not used by either the admin interface or the app.
  // TODO: Delete this table all together?
  // [ACCESS_TABLE_NAME]: {
  //   base: accessTypeBaseSchema,
  //   builder: accessTypeBuilderSchema,
  //   fullyFormed: accessTypeSchema,
  //   new: accessTypeNewSchema,
  // },

  [ACCOUNT_TABLE_NAME]: {
    base: accountTypeBaseSchema,
    builder: accountTypeBuilderSchema,
    fullyFormed: accountTypeSchema,
    new: accountTypeNewSchema,
  },

  // This table contains what amounts to configuration data, but it seems unlikely that
  // we'll need standard data access for it.
  // [ADMIN_PAGE_TABLE_NAME]: {
  //   base: adminPageTypeBaseSchema,
  //   builder: adminPageTypeBuilderSchema,
  //   fullyFormed: adminPageTypeSchema,
  //   new: adminPageTypeNewSchema,
  // },
  //

  // This table appears to be vestigial, and the SURVEY table appears to have taken over.
  // TODO: Delete this table?
  // [AFTER_SESSION_SURVEY_TABLE_NAME]: {
  //   base: afterSessionSurveyTypeBaseSchema,
  //   builder: afterSessionSurveyTypeBuilderSchema,
  //   fullyFormed: afterSessionSurveyTypeSchema,
  //   new: afterSessionSurveyTypeNewSchema,
  // },

  [AUDIO_NODE_TABLE_NAME]: {
    base: audioNodeTypeBaseSchema,
    builder: audioNodeTypeBuilderSchema,
    fullyFormed: audioNodeTypeSchema,
    new: audioNodeTypeNewSchema,
  },

  // This is not accessed by the admin page at this time. So I'm going to punt
  // on implementing data access files for it, for now.
  // [AUDIO_RECORDING_WHITELIST_TABLE_NAME]: {
  //   base: audioRecordingWhitelistTypeBaseSchema,
  //   builder: audioRecordingWhitelistTypeBuilderSchema,
  //   fullyFormed: audioRecordingWhitelistTypeSchema,
  //   new: audioRecordingWhitelistTypeNewSchema,
  // },
  //

  // Calendar is a special beast. We probably need to migrate off of this design, but maybe not today.
  // [CALENDAR_TABLE_NAME]: {
  //   base: calendarTypeBaseSchema,
  //   builder: calendarTypeBuilderSchema,
  //   fullyFormed: calendarTypeSchema,
  //   new: calendarTypeNewSchema,
  // },

  [CHAPTER_TABLE_NAME]: {
    base: chapterTypeBaseSchema,
    builder: chapterTypeBuilderSchema,
    fullyFormed: chapterTypeSchema,
    new: chapterTypeNewSchema,
  },

  [COACH_TABLE_NAME]: {
    base: coachTypeBaseSchema,
    builder: coachTypeBuilderSchema,
    fullyFormed: coachTypeSchema,
    new: coachTypeNewSchema,
  },

  [COHORT_MEETING_TABLE_NAME]: {
    base: cohortMeetingTypeBaseSchema,
    builder: cohortMeetingTypeBuilderSchema,
    fullyFormed: cohortMeetingTypeSchema,
    new: cohortMeetingTypeNewSchema,
  },

  [COHORT_TABLE_NAME]: {
    base: cohortTypeBaseSchema,
    builder: cohortTypeBuilderSchema,
    fullyFormed: cohortTypeSchema,
    new: cohortTypeNewSchema,
  },

  [COMPANY_TABLE_NAME]: {
    base: companyTypeBaseSchema,
    builder: companyTypeBuilderSchema,
    fullyFormed: companyTypeSchema,
    new: companyTypeNewSchema,
  },

  [DAILY_DIARY_TABLE_NAME]: {
    base: dailyDiaryTypeBaseSchema,
    builder: dailyDiaryTypeBuilderSchema,
    fullyFormed: dailyDiaryTypeSchema,
    new: dailyDiaryTypeNewSchema,
  },

  [INCENTIVE_TABLE_NAME]: {
    base: incentiveTypeBaseSchema,
    builder: incentiveTypeBuilderSchema,
    fullyFormed: incentiveTypeSchema,
    new: incentiveTypeNewSchema,
  },

  [INCENTIVE_TEMPLATE_TABLE_NAME]: {
    base: incentiveTemplateTypeBaseSchema,
    builder: incentiveTemplateTypeBuilderSchema,
    fullyFormed: incentiveTemplateTypeSchema,
    new: incentiveTemplateTypeNewSchema,
  },

  // This is not accessed by the admin page at this time. So I'm going to punt
  // on implementing data access files for it, for now. (It IS clearly in active
  // use by the app though.)
  // [LESSON_HOMEWORK_TABLE_NAME]: {
  //   base: lessonHomeworkTypeBaseSchema,
  //   builder: lessonHomeworkTypeBuilderSchema,
  //   fullyFormed: lessonHomeworkTypeSchema,
  //   new: lessonHomeworkTypeNewSchema,
  // },
  //
  // This is not accessed by the admin page at this time. So I'm going to punt
  // on implementing data access files for it, for now. (It IS clearly in active
  // use by the app though.)
  // [LESSON_PROGRESS_TEMPLATE_TABLE_NAME]: {
  //   base: lessonProgressTemplateTypeBaseSchema,
  //   builder: lessonProgressTemplateTypeBuilderSchema,
  //   fullyFormed: lessonProgressTemplateTypeSchema,
  //   new: lessonProgressTemplateTypeNewSchema,
  // },
  //
  [LESSON_RATING_TABLE_NAME]: {
    base: lessonRatingTypeBaseSchema,
    builder: lessonRatingTypeBuilderSchema,
    fullyFormed: lessonRatingTypeSchema,
    new: lessonRatingTypeNewSchema,
  },

  [LESSON_TABLE_NAME]: {
    base: lessonTypeBaseSchema,
    builder: lessonTypeBuilderSchema,
    fullyFormed: lessonTypeSchema,
    new: lessonTypeNewSchema,
  },

  // This is not accessed by the admin page at this time. So I'm going to punt
  // on implementing data access files for it, for now. (It IS clearly in active
  // use by the app though.)
  // [MAIL_TABLE_NAME]: {
  //   base: mailTypeBaseSchema,
  //   builder: mailTypeBuilderSchema,
  //   fullyFormed: mailTypeSchema,
  //   new: mailTypeNewSchema,
  // },
  //
  // This is not accessed by the admin page at this time. So I'm going to punt
  // on implementing data access files for it, for now. (It IS clearly in active
  // use by the app though.)
  // [NOTIFICATION_TEMPLATE_TABLE_NAME]: {
  //   base: notificationTemplatesTypeBaseSchema,
  //   builder: notificationTemplatesTypeBuilderSchema,
  //   fullyFormed: notificationTemplatesTypeSchema,
  //   new: notificationTemplatesTypeNewSchema,
  // },

  [PROGRAM_TABLE_NAME]: {
    base: programTypeBaseSchema,
    builder: programTypeBuilderSchema,
    fullyFormed: programTypeSchema,
    new: programTypeNewSchema,
  },

  // This table appears to be vestigial. The app mentions it in a method noone ever calls.
  // We should be using Firebase Remote Config anyway.
  // TODO: Delete this table
  // [REMOTE_CONFIG_TABLE_NAME]: {
  //   base: remoteConfigTypeBaseSchema,
  //   builder: remoteConfigTypeBuilderSchema,
  //   fullyFormed: remoteConfigTypeSchema,
  //   new: remoteConfigTypeNewSchema,
  // },
  //
  [SCIENCE_DATA_TABLE_NAME]: {
    base: scienceDataTypeBaseSchema,
    builder: scienceDataTypeBuilderSchema,
    fullyFormed: scienceDataTypeSchema,
    new: scienceDataTypeNewSchema,
  },

  [SESSIONS_TABLE_NAME]: {
    base: sessionTypeBaseSchema,
    builder: sessionTypeBuilderSchema,
    fullyFormed: sessionTypeSchema,
    new: sessionTypeNewSchema,
  },

  // This appears to have been folded into Sessions. Not mentioned in either admin or app.
  // TODO: Delete this table
  // [STATISTICS_DATA_TABLE_NAME]: {
  //   base: statisticsDataTypeBaseSchema,
  //   builder: statisticsDataTypeBuilderSchema,
  //   fullyFormed: statisticsDataTypeSchema,
  //   new: statisticsDataTypeNewSchema,
  // },

  // This is not accessed by the admin page at this time. So I'm going to punt
  // on implementing data access files for it, for now. (It IS clearly in active
  // use by the app though.)
  // [SURVEY_TABLE_NAME]: {
  //   base: surveyTypeBaseSchema,
  //   builder: surveyTypeBuilderSchema,
  //   fullyFormed: surveyTypeSchema,
  //   new: surveyTypeNewSchema,
  // },
  //

  [USER_TABLE_NAME]: {
    base: userTypeBaseSchema,
    builder: userTypeBuilderSchema,
    fullyFormed: userTypeSchema,
    new: userTypeNewSchema,
  },
});

// Shut up, TypeScript compiler! - I tried to solve this a number of other ways, but this was
// expedient, and only *mildly* stupid.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
const _x = [
  ACCESS_CODE_TABLE_NAME,
  ACCESS_REQUEST_TABLE_NAME,
  ACCESS_TABLE_NAME,
  ACCOUNT_TABLE_NAME,
  ADMIN_PAGE_TABLE_NAME,
  AFTER_SESSION_SURVEY_TABLE_NAME,
  AUDIO_NODE_TABLE_NAME,
  AUDIO_RECORDING_WHITELIST_TABLE_NAME,
  CALENDAR_TABLE_NAME,
  CHAPTER_TABLE_NAME,
  COACH_TABLE_NAME,
  COHORT_MEETING_TABLE_NAME,
  COHORT_TABLE_NAME,
  COMPANY_TABLE_NAME,
  DAILY_DIARY_TABLE_NAME,
  INCENTIVE_TABLE_NAME,
  INCENTIVE_TEMPLATE_TABLE_NAME,
  LESSON_HOMEWORK_TABLE_NAME,
  LESSON_PROGRESS_TEMPLATE_TABLE_NAME,
  LESSON_RATING_TABLE_NAME,
  LESSON_TABLE_NAME,
  MAIL_TABLE_NAME,
  NOTIFICATION_TEMPLATE_TABLE_NAME,
  PROGRAM_TABLE_NAME,
  REMOTE_CONFIG_TABLE_NAME,
  SCIENCE_DATA_TABLE_NAME,
  SESSIONS_TABLE_NAME,
  STATISTICS_DATA_TABLE_NAME,
  SURVEY_TABLE_NAME,
  USER_TABLE_NAME,
];
