/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { userIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { IncentiveType, IncentiveTypeBase, IncentiveTypeBuilder, IncentiveTypeNew } from '../modeltypes/incentive';

export const incentiveStageSchema = z.object({
  amount: z.number().nullable(),
  dayNumber: z.number(),
  finished: z.boolean(),
  program: z.string(),
});

export const incentiveTypeSchema = z.object({
  id: userIdSchema,
  enabled: z.boolean(),
  moneyType: z.union([z.literal('DOLLAR'), z.literal('CREDIT'), z.literal(''), z.null()]),
  stages: z.array(incentiveStageSchema).default(() => []),
});

export const incentiveTypeBaseSchema = incentiveTypeSchema.partial();

export const incentiveTypeBuilderSchema = incentiveTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: userIdSchema.default(() => IdFactory.User()) }));

export const incentiveTypeNewSchema = incentiveTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: userIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: IncentiveType = incentiveTypeSchema.parse(undefined);
  const x: IncentiveTypeBase = incentiveTypeBaseSchema.parse(undefined);
  const y: IncentiveTypeBuilder = incentiveTypeBuilderSchema.parse(undefined);
  const z: IncentiveTypeNew = incentiveTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
