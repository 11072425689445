// *******************************************************
// AddNodeAfterModal
// -------------------------------------------------------
// This is a AddNodeAfterModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import NodeForm from '../../../pages/nodes/NodeForm';
import { NodeType } from '../../../modeltypes/node';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface AddNodeAfterModalProps {
  hide: (shouldRefresh?: boolean) => void;
  challengeId: string;
  addNodeAfter: (newNode: NodeType) => void;
}

const AddNodeAfterModal = ({ addNodeAfter, challengeId, hide }: AddNodeAfterModalProps) => {
  return (
    <>
      <label htmlFor='cover-photo' className='block text-lg font-medium dark:text-white mb-4'>
        New Node Form
      </label>
      <div className={'bg-white px-3 pb-3 rounded-md'}>
        <NodeForm
          afterSubmitFunction={(newNode) => {
            addNodeAfter(newNode);
            hide();
          }}
          node={null}
          challengeId={challengeId}
          onCloseFunction={() => hide()}
        />
      </div>
    </>
  );
};

export default AddNodeAfterModal;
