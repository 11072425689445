import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { AudioNodeType } from 'src/modeltypes/audioNode';
import { ModalType, truncateMiddle } from '../../paths/sharedValues';
import { MoveIcon } from '../../../assets/tabIcons/tabIcons';

export type StoryNodeProps = {
  node: AudioNodeType | null;
  showModal: (modal: ModalType) => void;
};

const AudioLessonStoryCustomNode = ({ id, data, type, isConnectable }: NodeProps<StoryNodeProps>) => {
  if (!data.node) {
    return (
      <div>
        <p>Loading..asdfasdf.</p>
      </div>
    );
  }

  return (
    <div className='flex flex-col bg-white p-5 hover:cursor-default rounded-xl shadow-sm text-[8px]'>
      <MoveIcon
        className='custom-drag-handle hover:cursor-move'
        style={{
          display: 'inline-block',
          width: 15,
          height: 15,
          marginLeft: 5,
        }}
      />
      <Handle
        type='target'
        position={Position.Top}
        isConnectable={isConnectable}
        className='w-4 h-2 rounded-none border-0'
      />
      <div className='flex flex-col items-center text-center font-semibold'>
        <p>{data.node.code}</p>

        <p className=' font-light'>{type} Node</p>
        {/* <p>{data.title}</p> */}
        <p className='text-center bg-red'>{id}</p>
        <div className='flex flex-col mb-2 gap-y-1 max-w-[150px]'>
          <div className='flex flex-col '>
            {data.node.audioUrl ? <audio src={data.node.audioUrl || ''} controls /> : null}
            <button onClick={() => data.showModal(ModalType.UPDATE_AUDIO)} className=' underline text-blue-300'>
              Update Audio
            </button>
          </div>
          <div className='flex flex-col'>
            <button
              onClick={() => data.showModal(ModalType.SHOW_FULL_TEXT)}
              className='flex justify-center shadow border border-blue-300 rounded-full pl-3 pr-3 max-w-[150px]'
            >
              Show Full Text
            </button>
            <button onClick={() => data.showModal(ModalType.UPDATE_TEXT)} className='w-auto underline m1 text-blue-300'>
              Update Text
            </button>
          </div>
        </div>
        <p className='my-2 max-w-[250px] overflow-hidden ellipsis self-center'>
          {truncateMiddle(data.node.spokenText || '')}
        </p>
        {data.node.nextSubsection && <p>Connecting to: {data.node.nextSubsection}</p>}
      </div>
      {/*{!data.node.nextSubsection && (*/}
      {/*  <button*/}
      {/*    onClick={() => data.showModal(ModalType.ADD_NODE_AFTER)}*/}
      {/*    className='w-auto border-b border-blue-300 text-sm text-blue-300 sm:text-sm self-center'*/}
      {/*  >*/}
      {/*    Add node after this node*/}
      {/*  </button>*/}
      {/*)}*/}
      <Handle type='source' position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

export default memo(AudioLessonStoryCustomNode);
