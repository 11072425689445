// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { ChallengeId } from '../modeltypes/id';
import { ChallengeType, ChallengeTypeBase, ChallengeTypeBuilder, ChallengeTypeNew } from '../modeltypes/challenges';
import { CHALLENGES_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewChallengeId = getNewIdFor<ChallengeId>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewChallenge = addNewFor<ChallengeId, ChallengeTypeNew>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewChallenge = createNewFor<ChallengeId, ChallengeTypeBuilder>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewChallenge = setNewFor<ChallengeId, ChallengeTypeNew>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengeRefById = getRefByIdFor<ChallengeId>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengeById = getByIdFor<ChallengeId, ChallengeType>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengesByIdArray = getByIdArrayFor<ChallengeId, ChallengeType>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllChallenges = getAllFor<ChallengeType>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChallengesByQuery = getByQueryFor<ChallengeType>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllChallengesQuery = getAllQueryFor(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateChallenge = updateDocumentFor<ChallengeId, ChallengeTypeBase>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteChallenge = deleteDocumentFor<ChallengeId>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchChallengeIdSet = watchIdSetFor<ChallengeId>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeType = getValidateTypeFor<ChallengeType>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeTypeBase = getValidateTypeBaseFor<ChallengeTypeBase>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeTypeBuilder = getValidateTypeBuilderFor<ChallengeTypeBuilder>(CHALLENGES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChallengeTypeNew = getValidateTypeNewFor<ChallengeType>(CHALLENGES_TABLE_NAME);
