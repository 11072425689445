export enum ModalType {
  UPDATE_AUDIO,
  SHOW_FULL_TEXT,
  UPDATE_TEXT,
  SHOW_FULL_QUESTION,
  UPDATE_QUESTION,
  UPDATE_ANSWERS,
  ADD_NODE_AFTER,
  LESSON_ADD_NODE_AFTER,
}

export const truncateMiddle = (text: string) => {
  if (text.length > 100) {
    const start = text.substring(0, 25);
    const end = text.substring(text.length - 25, text.length);
    return `${start} (...) ${end}`;
  }
  return text;
};
