// *******************************************************
// Line Chart
// -------------------------------------------------------
// This is a component to display line chart
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
} from 'victory';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface LineChartProps {
  tickValues?: string[];
  chartData: {
    data: { x: string; y: number }[];
    fill?: string;
    name: string;
  }[];
  chartTitle: string | string[];
  max?: number;
  xLabel?: string;
  verticalLabel?: boolean;
}

const LineChart = ({ chartData, max, tickValues, xLabel, verticalLabel }: LineChartProps) => {
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={20}
      width={800}
      height={500}
      domain={{
        y: [
          0,
          (max ||
            Math.max(
              ...chartData
                .map(({ data }) => data)
                .flat()
                .map(({ y }) => y),
              8,
            )) * 1.1,
        ],
      }}
    >
      <VictoryAxis dependentAxis tickCount={max} tickFormat={(x: number) => Math.round(x)} />

      {chartData.map(({ data, fill, name }) => (
        <VictoryLine
          label={''}
          key={name}
          interpolation={'natural'}
          data={data}
          style={{
            data: {
              stroke: fill,
            },
          }}
        />
      ))}
      <VictoryAxis
        tickLabelComponent={
          verticalLabel ? (
            <VictoryLabel
              angle={90}
              verticalAnchor='middle'
              textAnchor='end'
              style={{ fontStyle: 'bold', fontSize: 10 }}
            />
          ) : (
            <VictoryLabel />
          )
        }
        tickValues={tickValues}
        label={xLabel}
        style={{
          axisLabel: {
            fontSize: 14,
            padding: 35,
          },
        }}
      />
      {chartData.map(({ data, fill, name }) => (
        <VictoryScatter key={name} data={data} size={3} style={{ data: { fill: fill } }} />
      ))}

      {chartData.length > 1 && (
        <VictoryLegend
          x={300}
          y={50}
          title={'Legend'}
          centerTitle
          orientation='horizontal'
          itemsPerRow={3}
          style={{ border: { fill: 'white' } }}
          data={chartData.map(({ name, fill }) => ({
            name: name,
            symbol: { fill: fill },
          }))}
        />
      )}
    </VictoryChart>
  );
};

export default LineChart;
