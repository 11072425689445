import { ScriptLesson, SwitchOption } from './ScriptModel';
import { canParseToInt, startsWithAnyOf, strip } from './GeneralUtilities';
import { AnswerType } from '../modeltypes/node';

export const lessonHomeworkSpecIsValid = (homework: string | null | undefined): boolean => {
  if (!homework) {
    return true;
  }
  const parts: string[] = homework.split(',').map(strip);
  for (const part of parts) {
    if (!startsWithAnyOf(part, 'Microhit:', 'Formal:', 'Background:')) {
      return false;
    }
    const subparts = part.split(':').map(strip);
    if (subparts.length != 2) {
      return false;
    }
    if (!canParseToInt(subparts[1])) {
      return false;
    }
  }
  return true;
};

export const ExoHomeworkRE = new RegExp('^\\$HOMEWORK\\(([^)]+)\\)$');
export const ExoChoosePracticeRE = new RegExp('^\\$CHOOSE_PRACTICE\\(([^)]+)\\)$');

export const getHomeworkDestinations = (nssec: string | null): Array<string> => {
  const match = nssec ? ExoHomeworkRE.exec(nssec) : null;
  if (match) {
    const parts = match[1].split(',').map(strip);
    return parts;
  }
  return [];
};

export const getChoosePracticeDestinations = (nssec: string | null): Array<string> => {
  const match = nssec ? ExoChoosePracticeRE.exec(nssec) : null;
  if (match) {
    const parts = match[1].split(',').map(strip);
    return parts;
  }
  return [];
};

export const nextSubsectionIsSpecial = (nssec: string | null): boolean => {
  return (
    nssec !== null &&
    (nssec.toUpperCase() === 'END' || !!ExoHomeworkRE.exec(nssec) || !!ExoChoosePracticeRE.exec(nssec))
  );
};

export type DestinationHaving = {
  singleTap?: string | null;
  doubleTap?: string | null;
  tripleTap?: string | null;
  nextSubsection?: string | null;
  switchOptions?: SwitchOption[] | null;
  nextNode?: string | null;
  answers?: AnswerType[] | null;
};

export const allPossibleDestinations = (ssec: DestinationHaving): Array<{ target: string; sourceHandle?: string }> => {
  const isSpecial = ssec.nextSubsection ? nextSubsectionIsSpecial(ssec.nextSubsection) : false;
  if (!isSpecial) {
    const rv = [];
    if (ssec.singleTap) {
      rv.push({
        target: strip(ssec.singleTap),
        sourceHandle: 'singleTap',
      });
    }
    if (ssec.doubleTap) {
      rv.push({
        target: strip(ssec.doubleTap),
        sourceHandle: 'doubleTap',
      });
    }
    if (ssec.tripleTap) {
      rv.push({
        target: strip(ssec.tripleTap),
        sourceHandle: 'tripleTap',
      });
    }
    if (ssec.nextSubsection) {
      rv.push({
        target: strip(ssec.nextSubsection),
      });
    }
    if (ssec.switchOptions) {
      for (const option of ssec.switchOptions) {
        if (option.nextNode) {
          rv.push({ target: strip(option.nextNode) });
        }
      }
    }
    if (ssec.nextNode) {
      rv.push({ target: strip(ssec.nextNode) });
    }
    if (ssec.answers) {
      for (const answer of ssec.answers) {
        if (answer.nextNode) {
          rv.push({
            target: strip(answer.nextNode),
            sourceHandle: answer.id,
          });
        }
      }
    }
    return rv;
  } else {
    const hwDests = ssec.nextSubsection
      ? getHomeworkDestinations(ssec.nextSubsection).map((dest) => ({ target: dest }))
      : [];
    const cpDests = ssec.nextSubsection
      ? getChoosePracticeDestinations(ssec.nextSubsection).map((dest) => ({ target: dest }))
      : [];
    return [...hwDests, ...cpDests];
  }
};

export const setGoogleDocId = (lesson: ScriptLesson, GDId: string | null): void => {
  lesson.importedFromGDocID = GDId;
  lesson.sections.forEach((sec) => {
    sec.importedFromGDocID = GDId;
    sec.subsections.forEach((ssec) => {
      ssec.importedFromGDocID = GDId;
    });
  });
};
