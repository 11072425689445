import React, { useEffect, useState } from 'react';
import { getCohortById } from '../../collections/cohort';
import { useNavigate } from 'react-router-dom';
import { getCoachById } from '../../collections/coach';
import { deleteCohortMeetings, getAllCohortMeetings, getLabelForMeetingType } from '../../collections/cohortMeeting';
import { useAppContext } from '../../contexts/appContext';
import { format, isToday } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import SearchBar from '../SearchBar';
import { TrashIcon, UserGroupIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';
import ConfirmModal, { ConfirmModalProps } from '../modals/ConfirmModal';
import { toast } from 'react-toastify';
import { getDateWithTimezone } from '../../utility/timeUtility';

const MeetingList = () => {
  type TableData = {
    cohort?: string;
    date: Date;
    dateForCohort: Date | null;
    coach?: string;
    type?: string;
    id?: string;
    url?: string;
    title?: string;
    timezoneName?: string;
  };
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteSession, setDeleteSession] = useState<ConfirmModalProps | null>(null);

  const { searchTerm, role } = useAppContext();
  const navigate = useNavigate();

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchType = item.type && item.type.match(regex);
      const matchCohort = item.cohort && item.cohort.match(regex);
      const matchCoach = item.coach && item.coach.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchType || matchId || matchCohort || matchCoach;
    });
  }, [searchTerm, tableData]);
  useEffect(() => {
    const prepareTableData = async () => {
      const newTableData: TableData[] = [];
      const meetings = await getAllCohortMeetings();
      for (const meeting of meetings) {
        const cohort = await getCohortById(meeting.cohort || null);
        const coach = await getCoachById(meeting.coach || null);
        const data: TableData = {
          cohort: cohort?.name,
          date: meeting.date?.toDate(),
          dateForCohort: cohort?.timezone ? getDateWithTimezone(meeting.date, cohort.timezone.name) : null,
          id: meeting.id,
          coach: coach?.name || '',
          type: meeting.type !== undefined ? getLabelForMeetingType(meeting.type) : '',
          url: meeting.url,
          title: meeting.title,
          timezoneName: cohort?.timezone?.name,
        };
        newTableData.push(data);
      }
      setTableData(newTableData.sort((prev, next) => +next.date - +prev.date));
    };

    prepareTableData().then(() => setIsLoading(false));
  }, []);

  const clearDeleteSession = () => setDeleteSession(null);
  const fillDeleteSession = (meetingName: string, meetingId: string, cohortName: string) => {
    const onConfirm = () => {
      toast
        .promise(deleteCohortMeetings(meetingId), {
          success: `${meetingName} deleted!`,
          error: `Can't delete it now, try again...`,
          pending: 'Deleting...',
        })
        .then(() => {
          const tempTable = [...tableData];
          const index = tempTable.findIndex((meeting) => meeting.id === meetingId);
          tempTable.splice(index, 1);
          setTableData(tempTable);
          clearDeleteSession();
        });
    };
    setDeleteSession({
      onConfirm,
      onCancel: clearDeleteSession,
      title: 'Are you sure?',
      body: `Delete ${meetingName} for ${cohortName} Cohort`,
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope='col' className='py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Cohort Name
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        Date
                        <br />
                        (cohort timezone)
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Coach
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Type
                      </th>
                      <th scope='col' colSpan={3} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((meeting) => (
                      <tr key={meeting.id}>
                        <td className='whitespace-nowrap py-4 pl-4 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500 max-w-[50px] truncate'>{meeting.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500 max-w-[150px] truncate'>{meeting.title}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{meeting.cohort}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {isToday(meeting.date) ? 'Today!' : ''}
                          <div className='font-medium text-gray-900'>
                            {meeting.dateForCohort
                              ? `${format(meeting.dateForCohort, 'hh:mm aaaa, MM/dd')}`
                              : 'Timezone not set'}
                          </div>
                          <p>{meeting.timezoneName || ''}</p>
                          <p className={'text-[11px]'}>Your time: {format(meeting.date, 'hh:mm aaaa, MM/dd')}</p>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>{meeting.coach}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{meeting.type}</td>
                        <td className='relative whitespace-nowrap  text-sm font-medium'>
                          <PencilIcon
                            className='cursor-pointer h-7 w-7 fill-indigo-600 hover:fill-indigo-900 mr-2'
                            aria-hidden='true'
                            onClick={() => navigate('/cohortSessions/' + meeting.id)}
                          />
                        </td>
                        {role === 'admin' ? (
                          <td className='relative whitespace-nowrap text-sm font-medium'>
                            {meeting.url ? (
                              <a target='_blank' rel='noreferrer' href={meeting.url}>
                                <UserGroupIcon
                                  className='h-7 w-7 mr-2 stroke-indigo-600 hover:stroke-indigo-900'
                                  aria-hidden='true'
                                />
                              </a>
                            ) : (
                              <p>Invalid Url</p>
                            )}
                          </td>
                        ) : null}
                        <td className='relative whitespace-nowrap text-sm font-medium '>
                          <TrashIcon
                            className='cursor-pointer stroke-red-600 h-7 w-7 hover:stroke-red-900'
                            aria-hidden='true'
                            onClick={() =>
                              meeting.title && meeting.id && meeting.cohort
                                ? fillDeleteSession(meeting.title, meeting.id, meeting.cohort)
                                : null
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {deleteSession ? <ConfirmModal {...deleteSession} /> : null}
    </div>
  );
};

export default MeetingList;
