import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAudioNodesByIdArray } from '../../collections/audioNode';
import { AudioNodeType } from '../../modeltypes/audioNode';
import cloneDeep from 'clone-deep';
import LessonNodeFlow from '../lessons/LessonNodeFlow';

export const LessonAudioChunkFlow = ({
  audioNodes,
  setAudioNodes,
  locked,
}: {
  audioNodes: AudioNodeType[];
  setAudioNodes: (newNodes: AudioNodeType[]) => void;
  locked: boolean;
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!show) {
      setShow(true);
    }
  }, [show]);

  useEffect(() => {
    setShow(false);
  }, [audioNodes]);

  const updateNodes = async (nIds: string[]) => {
    const nodesToUpdate = await getAudioNodesByIdArray(nIds);
    if (audioNodes) {
      const nodesClone = cloneDeep(audioNodes);
      for (const node of nodesToUpdate) {
        const index = nodesClone.findIndex((i) => i.id === node.id);
        if (index !== -1) {
          nodesClone.splice(index, 1, node);
        } else {
          nodesClone.push(node);
        }
      }
      setAudioNodes(nodesClone);
    } else {
      setAudioNodes(nodesToUpdate);
    }
    // setShow(false);
  };

  return !audioNodes?.length ? null : (
    <div
      className='sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 h-[800px]'
      onContextMenu={(e) => e.preventDefault()}
    >
      <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
        Flow
      </label>
      {show && (
        <LessonNodeFlow
          rawNodes={audioNodes}
          refresh={() => setShow(false)}
          updateNodes={updateNodes}
          locked={locked}
        />
      )}
    </div>
  );
};
