// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { QuizId } from '../modeltypes/id';
import { QuizType, QuizTypeBase, QuizTypeBuilder, QuizTypeNew } from '../modeltypes/quizItems';
import { QUIZZES_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewQuizId = getNewIdFor<QuizId>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewQuiz = addNewFor<QuizId, QuizTypeNew>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewQuiz = createNewFor<QuizId, QuizTypeBuilder>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewQuiz = setNewFor<QuizId, QuizTypeNew>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getQuizRefById = getRefByIdFor<QuizId>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getQuizById = getByIdFor<QuizId, QuizType>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getQuizItemsByIdArray = getByIdArrayFor<QuizId, QuizType>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllQuizItems = getAllFor<QuizType>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getQuizItemsByQuery = getByQueryFor<QuizType>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllQuizItemsQuery = getAllQueryFor(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateQuiz = updateDocumentFor<QuizId, QuizTypeBase>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteQuiz = deleteDocumentFor<QuizId>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchQuizIdSet = watchIdSetFor<QuizId>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateQuizType = getValidateTypeFor<QuizType>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateQuizTypeBase = getValidateTypeBaseFor<QuizTypeBase>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateQuizTypeBuilder = getValidateTypeBuilderFor<QuizTypeBuilder>(QUIZZES_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateQuizTypeNew = getValidateTypeNewFor<QuizType>(QUIZZES_TABLE_NAME);
