/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { addNewEmail, getEmailById, updateEmail, } from '../../collections/paths';
import { addNewEmail, getEmailById, updateEmail } from '../../collections/email';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { EmailRecipientType, EmailType, EmailTypeBase } from '../../modeltypes/email';
import { toast } from 'react-toastify';
import { uiFormatTimestamp } from '../../collections/shared';
import Select from 'react-select';
import { getAllGoals } from '../../collections/goals';
import { GoalType } from 'src/modeltypes/goals';

// Constants

export type EmailItemSelector = {
  value: string;
  label: string;
};

const emailTypeSelector: EmailItemSelector[] = [
  {
    label: 'Test',
    value: 'TEST',
  },
  {
    label: 'Everyone',
    value: 'EVERYONE',
  },
  {
    label: 'Active Subscriptions',
    value: 'ACTIVE_SUBSCRIPTIONS',
  },
  {
    label: 'PostIntro',
    value: 'POST_INTRO',
  },
  {
    label: 'Intro',
    value: 'INTRO',
  },
  {
    label: 'Goal',
    value: 'GOAL',
  },
  {
    label: 'Experience',
    value: 'EXPERIENCE',
  },
  {
    label: 'Expired Subscriptions',
    value: 'EXPERIENCE',
  },
];

const experienceSelector: EmailItemSelector[] = [
  {
    label: 'Beginner',
    value: 'BEGINNER',
  },
  {
    label: 'Some Experience',
    value: 'SOME',
  },
  {
    label: 'Advanced',
    value: 'ADVANCED',
  },
];

const EmailForm = () => {
  const [email, setThisEmail] = useState<EmailType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [goals, setGoals] = useState<GoalType[] | null>(null);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'App Emails',
        path: '/email',
      },
      {
        label: id === 'new' ? 'New Emails' : 'Edit Emails',
        path: `/email/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    //resetForm
  } = useFormik<{
    subject: string;
    emailBody: string;
    locked: boolean;
    testEmail: string;
    recipient: EmailRecipientType;
    subRecipient?: any;
  }>({
    initialValues: {
      subject: '',
      emailBody: '',
      locked: true,
      testEmail: '',
      recipient: 'POST_INTRO',
      subRecipient: null,
      // id: '',
    },
    validationSchema: yup.object().shape({
      subject: yup.string().required('Subject is required'),
      emailBody: yup.string().required('Email Body is required'),
    }),
    validate: (values) => {
      const errors: any = {};
      if (!values?.subject) {
        errors.subject = 'Subject is required';
      }
      if (!values?.emailBody) {
        errors.emailBody = 'You have to send a body!  It is required';
      }
      if (!values?.recipient) {
        errors.recipient = 'You have to send it to a Recipient';
      }

      return errors;
    },

    onSubmit: async (newEmail) => {
      let appUpdateId = email?.id;
      const isNew = !email?.id;
      console.log('appUpdateId: ', appUpdateId);
      if (!appUpdateId) {
        appUpdateId = await addNewEmail();
      }
      console.log('isNew: ', isNew);

      const update: EmailTypeBase = {
        id: appUpdateId,
        subject: values.subject,
        emailBody: values.emailBody,
        locked: values.locked,
        testEmail: values.testEmail,
        recipient: values.recipient,
        subRecipient: values.subRecipient,
      };

      toast
        .promise(updateEmail(appUpdateId, update), {
          pending: `Updating ${newEmail.subject} Email...`,
          error: "Can't do it now, try again.",
          success: `Updated ${newEmail.subject} Email!`,
        })
        .then(async () => {
          navigate('/email');
        });
    },
  });

  console.log('values.recipient : ', values.recipient);

  const goalSelector = goals
    ? goals.map((goal: GoalType) => {
        return {
          label: goal?.name,
          value: goal?.id,
        };
      })
    : null;

  console.log('goalSelector : ', goalSelector);

  useEffect(() => {
    if (values.recipient === 'GOAL' && !goals) {
      getAllGoals().then((fGoals) => setGoals(fGoals));
    }
  }, [values.recipient]);

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currEmail = await getEmailById(id || '');
        if (currEmail === null) {
          navigate('/email');
          return;
        }

        await setValues({
          subject: currEmail.subject || '',
          emailBody: currEmail.emailBody || '',
          locked: currEmail.locked || false,
          recipient: currEmail.recipient || [],
          testEmail: currEmail.testEmail || '',
          subRecipient: currEmail.subRecipient || null,
        });

        setThisEmail(currEmail);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                Email Details <span style={{ fontSize: '80%' }}>(ID: {email?.id || 'not yet'})</span>
              </label>
              <p>
                <strong>Created:</strong>&nbsp;{uiFormatTimestamp(email?.createdAt)}
                &nbsp;&nbsp;
                <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(email?.updatedAt)}
              </p>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='subject' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Email Subject
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='subject'
                        id='subject'
                        autoComplete='subject'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.subject ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.subject}
                        onChange={handleChange}
                        placeholder={'Email Subject'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='emailBody' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Email Body
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      id='emailBody'
                      name='emailBody'
                      rows={10}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.emailBody}
                      onChange={handleChange}
                      placeholder={'Enter the body of the email'}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='subject' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Test Email
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='testEmail'
                        id='testEmail'
                        autoComplete='testEmail'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.testEmail ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.testEmail}
                        onChange={handleChange}
                        placeholder={'Enter Test Email Address'}
                      />
                    </div>
                  </div>
                </div>

                <div className={`flex flex-col sm:border-t sm:border-gray-200 `}>
                  <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                    Who will receive this
                  </label>
                  <div>
                    <Select
                      options={emailTypeSelector}
                      value={emailTypeSelector.find((e) => values.recipient === e.value)}
                      onChange={(option) => {
                        setFieldValue(`recipient`, option?.value);
                      }}
                      className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                    />
                  </div>
                </div>

                {values?.recipient === 'GOAL' ? (
                  <div className={`flex flex-col sm:border-t sm:border-gray-200 `}>
                    <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                      Enter a Sub Recipient
                    </label>
                    <div>
                      <Select
                        options={goalSelector || []}
                        value={goalSelector?.find((gS) => gS.value === values.subRecipient) || null}
                        onChange={(option) => {
                          setFieldValue(`subRecipient`, option?.value);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                    </div>
                  </div>
                ) : null}

                {values?.recipient === 'EXPERIENCE' ? (
                  <div className={`flex flex-col sm:border-t sm:border-gray-200 `}>
                    <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                      Enter a Sub Recipient
                    </label>
                    <div>
                      <Select
                        options={experienceSelector || []}
                        value={experienceSelector?.find((gS) => gS.value === values.subRecipient) || null}
                        onChange={(option) => {
                          setFieldValue(`subRecipient`, option?.value);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/email')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {email ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default EmailForm;
