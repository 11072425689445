// *******************************************************
// Company Form
// -------------------------------------------------------
// This is a Component for Editing / adding company
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
// *******************************************
// Component Imports
// -------------------------------------------
// *******************************************
// Hooks Import
// -------------------------------------------
// *******************************************
// Action Imports
// -------------------------------------------
import { addNewCompany, getCompanyById, updateCompany } from '../../collections/company';

// *******************************************
// Styles Imports
// -------------------------------------------
// *******************************************
// Constants
// -------------------------------------------
import { Pages } from '../../enums/pages';
import CompanyUserList, { UserTableData } from '../../components/company/CompanyUserList';
import { useAppContext } from '../../contexts/appContext';
import { removeUserFromCompany } from '../../collections/user';
import Skeleton from 'react-loading-skeleton';
import AddUserModal from '../../components/modals/AddUserModal';
import { getAccountsByIdArray, updateAccount, updateCompanyForAccounts } from '../../collections/account';
import { getUserIdsByCompanyId, getUsersByIdArray } from '../../collections/user';
import { CompanyType } from '../../modeltypes/company';
import { emptyCallback } from '../../utility/GeneralUtilities';
import { toast } from 'react-toastify';
import { getCohortsByQuery } from '../../collections/cohort';
import { where } from 'firebase/firestore';
import { CohortType } from '../../modeltypes/cohort';
import AddUsersToCohortModal from '../../components/modals/AddUsersToCohortModal';

// *******************************************
// Types
// -------------------------------------------

const CompanyForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState<CompanyType>();
  const [users, setUsers] = useState<UserTableData[]>([]);
  const [companyCohorts, setCompanyCohorts] = useState<CohortType[]>([]);

  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [showAddUsersToCohortModal, setShowAddUsersToCohortModal] = useState(false);

  const [selectedUsers, setSelectedUserIds] = useState<UserTableData[]>([]);

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Companies',
        path: '/companies',
      },
      {
        label: id === 'new' ? 'New Company' : 'Edit Company',
        path: `/companies/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const { values, setValues, handleChange, errors, handleSubmit } = useFormik<{
    companyName: string;
    domain: string;
    isScience?: boolean;
  }>({
    initialValues: {
      companyName: '',
      domain: '',
      isScience: false,
    },
    validationSchema: yup.object().shape({
      companyName: yup.string().required(),
      domain: yup
        .string()
        .required()
        .matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, 'Must be domain'),
    }),
    onSubmit: (values) => {
      if (id && id !== 'new' && company?.id) {
        toast
          .promise(
            updateCompany(id, {
              ...company,
              companyName: values.companyName,
              domain: values.domain,
              isScience: values.isScience || false,
            }),
            {
              pending: 'Updating...',
              error: "Can't do it now, try again.",
              success: 'Updated!',
            },
          )
          .then(() => {
            navigate(`/${Pages.Companies}`);
          });
      } else {
        toast
          .promise(
            addNewCompany({
              companyName: values.companyName,
              domain: values.domain,
              isScience: values.isScience || false,
            }),
            {
              pending: 'Adding new Company...',
              success: `Added ${values.companyName}`,
              error: "Can't do it now, try again.",
            },
          )
          .then(() => {
            navigate(`/${Pages.Companies}`);
          });
      }
    },
  });

  const prepareUsersData = async () => {
    if (company) {
      const userIds = await getUserIdsByCompanyId(company.id);
      const usersDb = await getUsersByIdArray(userIds);
      const accountsDb = await getAccountsByIdArray(userIds);
      const cohorts = await getCohortsByQuery(where('company', '==', company.id));

      setUsers(
        usersDb
          .map(
            (user): UserTableData => ({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              createdAt: accountsDb.find((el) => el.id === user.id)!.createdAt!.toDate()!,
              email: user.email!,
              cohort: cohorts.find((el) => el.id === accountsDb.find((el) => el.id === user.id)?.cohort)?.name || null,
            }),
          )
          .sort((prev, next) => next.createdAt!.getTime()! - prev.createdAt!.getTime()!),
      );
      setCompanyCohorts(cohorts);
    }
  };

  useEffect(() => {
    const prepareData = async () => {
      if (id && id !== 'new') {
        const company = await getCompanyById(id);
        if (company) {
          setCompany(company);
          setValues({
            companyName: company.companyName || '',
            domain: company.domain,
            isScience: company.isScience || false,
          }).then(emptyCallback);
        }
      }
    };

    prepareData().then(() => setIsLoading(false));
  }, [id, setValues]);

  useEffect(() => {
    prepareUsersData();
  }, [company]);

  const addSelectedUsers = async (userIdsToAdd: string[]) => {
    if (company) {
      toast
        .promise(updateCompanyForAccounts(userIdsToAdd, company.id), {
          pending: 'Adding users to Company...',
          success: 'Added users!',
          error: "Can't do it now, try again.",
        })
        .then(
          () => {
            prepareUsersData();
            setShowAddUsersModal(false);
          },
          (error) => {
            console.error(error);
          },
        );
    }
  };

  const removeUser = (_id: string) => {
    toast
      .promise(removeUserFromCompany(_id), {
        pending: 'Removing user...',
        success: 'Removed user!',
        error: "Can't do it now, try again.",
      })
      .then(() => {
        setUsers((u) => {
          const temp = [...u];
          const index = u.findIndex(({ id }) => id === _id);
          temp.splice(index, 1);
          return temp;
        });
      });
  };

  const selectUser = (user: UserTableData) => {
    setSelectedUserIds((prevState) => {
      if (prevState.map((el) => el.id).includes(user.id)) {
        return prevState.filter(({ id }) => id !== user.id);
      }
      return [...prevState, user];
    });
  };

  const updateUsersCohort = async (userIds: string[], cohortId: string) => {
    console.time('s');
    const finalCohortId = cohortId === 'remove' ? null : cohortId;
    await Promise.all(userIds.map((userId) => updateAccount(userId, { cohort: finalCohortId })));
    console.timeEnd('s');

    prepareUsersData();
    setSelectedUserIds([]);
    console.log(userIds, cohortId);
  };

  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form
          className='space-y-8 divide-y divide-gray-200'
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Company Name
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='companyName'
                        id='companyName'
                        autoComplete='name'
                        className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                          errors.companyName ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.companyName}
                        onChange={handleChange}
                        placeholder={'Please enter the Company Name'}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Domain
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <span className='inline-flex items-center px-3 text-lg text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300'>
                        @
                      </span>
                      <input
                        type='text'
                        name='domain'
                        id='domain'
                        autoComplete='domain'
                        className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm ${
                          errors.domain ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.domain}
                        onChange={handleChange}
                        placeholder={'example: equahealth.io'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Is Science Company?
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type='checkbox'
                      name='isScience'
                      id='isScience'
                      autoComplete='isScience'
                      checked={values.isScience}
                      onChange={handleChange}
                      placeholder={'Please enter the Company Name'}
                    />
                  </div>
                </div>
              </div>
            </div>
            {id && id !== 'new' && company && (
              <>
                <div className=' sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <div className='flex justify-between'>
                    <label htmlFor='name' className='block text-md font-bold text-gray-700 sm:mt-px sm:pt-2 '>
                      Users
                    </label>
                    {selectedUsers.length ? (
                      <button
                        type={'button'}
                        className='px-6 inline-flex justify-center py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        onClick={() => setShowAddUsersToCohortModal(true)}
                      >
                        Add users to cohort
                      </button>
                    ) : null}
                  </div>
                  <div className='w-full px-4 lg:px-8 mt-8'>
                    <CompanyUserList
                      users={users}
                      removeUser={removeUser}
                      selectUser={selectUser}
                      selectedUsers={selectedUsers}
                    />
                    <button
                      type={'button'}
                      className='px-4 mt-5 w-full inline-flex justify-center py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={() => setShowAddUsersModal(true)}
                    >
                      {'Add users'}
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className='pt-5'>
              <div className='flex justify-end'>
                <button
                  type='button'
                  className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => navigate(`/${Pages.Companies}`)}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  {id === 'new' ? 'Add' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      {showAddUsersModal && company && (
        <AddUserModal addSelectedUsers={addSelectedUsers} hide={() => setShowAddUsersModal(false)} />
      )}

      {showAddUsersToCohortModal && (
        <AddUsersToCohortModal
          cohorts={companyCohorts}
          users={selectedUsers}
          hide={() => setShowAddUsersToCohortModal(false)}
          addUsersToCohort={updateUsersCohort}
        />
      )}
    </div>
  );
};

export default CompanyForm;
