import { ScriptLesson, ScriptSubsection } from './ScriptModel';
import { getLessonRefById, getNewLessonId } from '../collections/lesson';
import { getChapterRefById, getNewChapterId } from '../collections/chapter';
import { getAudioNodeRefById, getNewAudioNodeId } from '../collections/audioNode';
import { dieIfNullOrUndef } from './GeneralUtilities';
import { ChapterType } from '../modeltypes/chapter';
import { AudioNodeType, HomeworkStatus, SwitchType, TrackType, trackTypeFromString } from '../modeltypes/audioNode';
import { LessonTypeBase } from '../modeltypes/lesson';
import { LessonId } from '../modeltypes/id';
import { deleteLessonRecursive } from '../collections/relationship';
import { serverTimestamp, writeBatch } from '../models/dalaccess';
import { HasCreateUpdateDbTs } from '../modeltypes/shared';
import { getChoosePracticeDestinations, getHomeworkDestinations, nextSubsectionIsSpecial } from './ScriptUtilities';

export type ConvertedScriptTypeForDB = {
  // In the database, the Program refers to the Lesson as a child; the child is not aware of a single parent Program.
  // This allows Lesson reuse between programs.
  lesson: HasCreateUpdateDbTs<LessonTypeBase>;
  chapters: HasCreateUpdateDbTs<ChapterType>[];
  audioNodes: HasCreateUpdateDbTs<AudioNodeType>[];
};

const getNodeTypeForImport = (ssec: ScriptSubsection): string => {
  if (ssec.nextSubsection?.includes('HOMEWORK')) {
    return TrackType.H_CHECK.toString();
  }
  if (ssec.nextSubsection?.includes('PRACTICE')) {
    return TrackType.CHOOSE_PRACTICE.toString();
  }
  if (ssec.singleTap) {
    return TrackType.DECISION.toString();
  }
  return TrackType.STORY.toString();
};

async function convert(scriptLesson: ScriptLesson, gDocId: string): Promise<ConvertedScriptTypeForDB> {
  const validatedGDocId = gDocId || undefined;

  const rv: ConvertedScriptTypeForDB = {
    lesson: {},
    chapters: [],
    audioNodes: [],
  };

  const newLessonId = getNewLessonId();
  Object.assign(rv.lesson, {
    id: newLessonId,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    productionStatus: null,

    title: scriptLesson.lessonTitle || '',
    description: scriptLesson.lessonDescription || '',
    author: scriptLesson.author,
    voiceArtist: '',
    subtitle: scriptLesson.lessonSubtitle || '',
    icon: null,
    grayedIcon: null,
    duration: scriptLesson.lessonDuration,
    minDuration: scriptLesson.lessonMinDuration,
    maxDuration: scriptLesson.lessonMaxDuration,
    homeworkAssigned: scriptLesson.lessonHomework,
    chapters: [],
    paths: [],
    comments: [...scriptLesson.comments],
    importedFromGDocID: validatedGDocId,
    scriptLastUpdate: scriptLesson.lastUpdate,
    homeworkDoneQuestion: scriptLesson.lessonHomeworkDoneQuestion,
    homeworkNotDoneQuestion: scriptLesson.lessonHomeworkNotDoneQuestion,
  });

  for (const section of scriptLesson.sections) {
    const newChapterId = getNewChapterId();
    const chapter: HasCreateUpdateDbTs<ChapterType> = {
      id: newChapterId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      title: section.sectionTitle || '',
      code: section.sectionCode,
      description: '',
      comments: [],
      audioNodes: [],
      importedFromGDocID: validatedGDocId,
    };

    dieIfNullOrUndef(rv.lesson?.chapters).push(newChapterId);
    rv.chapters.push(chapter);

    for (const ssec of section.subsections) {
      const newAudioNodeId = getNewAudioNodeId();
      const audioNode: HasCreateUpdateDbTs<AudioNodeType> = {
        id: newAudioNodeId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        title: ssec.title,
        code: ssec.code,
        summaryText: ssec.summaryText,
        duration: ssec.duration,
        spokenText: ssec.spokenText,
        singleTap: ssec.singleTap,
        doubleTap: ssec.doubleTap,
        tripleTap: ssec.tripleTap,
        nextSubsection: ssec.nextSubsection,
        path: ssec.path,
        pathCode: ssec.pathCode,
        silenceDuration: ssec.silenceDuration,
        silenceFixedLen: ssec.silenceFixedLen,
        comments: [...ssec.comments],
        importedFromGDocID: validatedGDocId,
        nodeType: (ssec.type as TrackType) || trackTypeFromString(getNodeTypeForImport(ssec)) || null,
        subType: (ssec.subType as SwitchType) || null,
        audioUrl: null,
        homeworkStatus: (ssec.homeworkStatus?.toUpperCase() as HomeworkStatus) || null,
        homeworkAnswer: ssec.homeworkAnswer,
        switchOptions: ssec.switchOptions.length > 0 ? ssec.switchOptions : null,
      };
      dieIfNullOrUndef(chapter.audioNodes).push(newAudioNodeId);
      rv.audioNodes.push(audioNode);
    }
  }

  for (const index in rv.audioNodes) {
    const temp = { ...rv.audioNodes[index] };
    const { singleTap, doubleTap, tripleTap, nextSubsection, switchOptions } = temp;

    const singleTapId = rv.audioNodes.find((item) => item.code === singleTap)?.id || null;
    const doubleTapId = rv.audioNodes.find((item) => item.code === doubleTap)?.id || null;
    const tripleTapId = rv.audioNodes.find((item) => item.code === tripleTap)?.id || null;
    const isSpecial = nextSubsection ? nextSubsectionIsSpecial(nextSubsection) : false;

    let nextSubsectionId;
    if (isSpecial) {
      const hwDests = nextSubsection ? getHomeworkDestinations(nextSubsection) : [];
      const cpDests = nextSubsection ? getChoosePracticeDestinations(nextSubsection) : [];
      if (hwDests.length > 0) {
        const hwIds = hwDests.map((code) => rv.audioNodes.find((item) => item.code === code)?.id);
        nextSubsectionId = `$HOMEWORK(${hwIds.join(',')})`;
      } else if (cpDests.length > 0) {
        const cpIds = cpDests.map((code) => rv.audioNodes.find((item) => item.code === code)?.id);
        nextSubsectionId = `CHOOSE_PRACTICE(${cpIds.join(',')})`;
      } else {
        nextSubsectionId = nextSubsection;
      }
    } else {
      nextSubsectionId = rv.audioNodes.find((item) => item.code === nextSubsection)?.id || nextSubsection;
    }

    let tempSwitchOptions = null;
    if (switchOptions) {
      tempSwitchOptions = switchOptions;
      for (const index in tempSwitchOptions) {
        const option = tempSwitchOptions[index];
        tempSwitchOptions[index].nextNode = rv.audioNodes.find((item) => item.code === option.nextNode)?.id || null;
      }
    }

    rv.audioNodes[index] = {
      ...temp,
      singleTap: singleTapId,
      doubleTap: doubleTapId,
      tripleTap: tripleTapId,
      nextSubsection: nextSubsectionId,
      switchOptions: tempSwitchOptions,
    };
  }
  return rv;
}

export async function writeImportedLessonToDatabase(forImport: ConvertedScriptTypeForDB): Promise<void> {
  // Do the DB stuff...
  const lessonRef = dieIfNullOrUndef(getLessonRefById(forImport.lesson.id));
  const chapterRefs = forImport.chapters.map((chap) => dieIfNullOrUndef(getChapterRefById(chap.id)));
  const audioNodeRefs = forImport.audioNodes.map((an) => dieIfNullOrUndef(getAudioNodeRefById(an.id)));

  const batch = writeBatch();

  batch.set(lessonRef, forImport.lesson);

  chapterRefs.forEach((chapterRef, idx) => {
    const data = forImport.chapters[idx];
    batch.set(chapterRef, data);
  });

  audioNodeRefs.forEach((anRef, idx) => {
    const data = forImport.audioNodes[idx];
    batch.set(anRef, data);
  });

  await batch.commit();
}

export async function updateExistingLessonWithImportedLesson(
  existingLessonId: LessonId,
  forImport: ConvertedScriptTypeForDB,
): Promise<void> {
  // OK... so, we need to do a cascade delete on the existing lesson, then recreate it with the same ID.
  await deleteLessonRecursive(existingLessonId);
  forImport.lesson.id = existingLessonId;
  await writeImportedLessonToDatabase(forImport);
}

export default convert;
