import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { getTrackById, updateTrack, addNewTrack } from '../../collections/tracks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { TrackType, TrackTypeBuilder } from '../../modeltypes/tracks';
import { toast } from 'react-toastify';
import { ReactSelectOptions } from '../../types/types';
import { UnitType } from 'src/modeltypes/unit';
import { createNewUnit, getUnitsByIdArray } from 'src/collections/units';
import { TagType } from 'src/modeltypes/tags';
import { getAllTags } from 'src/collections/tags';
import { uiFormatTimestamp, uiStatusString } from '../../collections/shared';
import { TagId, UnitId } from '../../modeltypes/id';
import { Pages } from '../../enums/pages';
import { dieIfNullOrUndef, emptyCallback } from '../../utility/GeneralUtilities';
import { ReactSortable } from 'react-sortablejs';
import AddUnitModal from '../../components/modals/AddUnitModal';
import { arrayUnion } from '@firebase/firestore';
import { getAllGoals } from 'src/collections/goals';
import { GoalType } from 'src/modeltypes/goals';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

type ElementWithMetaDataType = UnitType & { order: number };

const ElementsListItem = ({
  element,
  onRemoveUnitClick,
  index,
}: {
  element: ElementWithMetaDataType;
  onRemoveUnitClick: (uId: UnitId) => void;
  index: number;
}) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate('/' + Pages.Unit + '/' + element.id);
  };

  const unitId = dieIfNullOrUndef(element?.id);
  return !element ? null : (
    <div key={unitId} className={'flex flex-row hover:bg-gray-50 hover:cursor-move w-full'}>
      <p className='w-2/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex items-center '>
        {index + 1}
      </p>
      <div className='w-4/12 whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer' onClick={() => navigateTo()}>
          {element.title || `(ID: ${unitId})`}
        </div>
      </div>
      <div className='w-4/12 whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{uiStatusString(element)}</div>

      <div className='w-2/12 relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
        <div
          className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer'
          onClick={() => onRemoveUnitClick(unitId)}
        >
          Remove
        </div>
      </div>
    </div>
  );
};

const ElementsList = ({
  elements,
  onAddUnitClick,
  onRemoveUnitClick,
  setElements,
}: {
  elements: ElementWithMetaDataType[];
  setElements: (elements: ElementWithMetaDataType[]) => void;
  onAddUnitClick: () => void;
  onRemoveUnitClick: (uId: UnitId) => void;
}) => {
  return !elements ? null : (
    <div className='sm:grid sm:items-start sm:pt-5'>
      <div className='flex items-center justify-between'>
        <label htmlFor='name' className='block text-lg font-medium text-gray-700'>
          Units and order
        </label>
        <div className='relative flex justify-evenly font-bold'>
          <button
            type={'button'}
            className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            onClick={onAddUnitClick}
          >
            Add Existing Unit
          </button>
        </div>
      </div>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <div className='flex flex-row '>
                <p className='w-2/12  py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>Order</p>
                <p className='w-4/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>Title</p>
                <p className='w-4/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>status</p>
                <div className='w-2/12 relative py-3.5 pl-3 pr-4 sm:pr-6 flex justify-evenly' />
              </div>
              <ReactSortable
                ghostClass={'lightBlueBackground'}
                list={elements}
                setList={setElements}
                sort={true}
                // removeOnSpill={true} onSpill={(e) => console.log(e.item)}
              >
                {(elements || []).map((element, index) => {
                  return (
                    <div key={element.id} className={'w-full'} style={{ width: '100%' }}>
                      <ElementsListItem
                        index={index}
                        element={element}
                        key={element.id}
                        onRemoveUnitClick={onRemoveUnitClick}
                      />
                    </div>
                  );
                })}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const GoalTypeSelector = Object.keys(Goals).map((key) => {
//   return {
//     label: Goals[key],
//     value: key,
//   };
// });

const TrackForm = () => {
  const [track, setTrack] = useState<TrackType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [units, setUnits] = useState<ElementWithMetaDataType[]>([]);

  const [tags, setTags] = useState<ReactSelectOptions[]>([]);
  const [selectedTags, setSelectedTags] = useState<ReactSelectOptions[]>([]);
  const [goals, setGoals] = useState<ReactSelectOptions[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<ReactSelectOptions | null>(null);
  const [thisLocked, setIsLocked] = useState<boolean>(false);
  const [thisIsIntroSeries, setIsIntroSeries] = useState<boolean>(false);

  const [showAddUnitModal, setShowAddUnitModal] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths, setValidateElement } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Tracks',
        path: '/tracks',
      },
      {
        label: id === 'new' ? 'New Track' : 'Edit Track',
        path: `/tracks/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  useEffect(() => {
    const fetchData = async () => {
      if (track) {
        const units = track.units?.length ? track.units : [];
        const unitsId = units.map((el) => el.id);

        const fetchedUnits = await getUnitsByIdArray(unitsId);

        const unitsToSet = track.units.map((e) => ({
          ...e,
          ...(fetchedUnits.find((ec) => ec.id === e.id) as UnitType),
        }));

        setUnits(unitsToSet.sort((prev, next) => prev.order - next.order));
      }
    };

    fetchData().catch(console.error);
  }, [track, track?.units]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    //resetForm
  } = useFormik<{
    title: string;
    description: string;
    locked: boolean;
    tags?: TagId[];
    goal?: string | null;
    isIntroSeries?: boolean;
  }>({
    initialValues: {
      title: '',
      description: '',
      locked: true,
      isIntroSeries: false,
    },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required'),
      description: yup.string(),
    }),
    validate: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors: any = {};

      if (!values.title || values.title.length < 1) {
        errors.title = 'The track title must be present';
      }

      return errors;
    },
    onSubmit: async (newTrack) => {
      const isNew = !track;
      const trackToUpdate = track ? track : createNewUnit();
      const update: TrackTypeBuilder = {
        id: dieIfNullOrUndef(trackToUpdate.id),
        title: values.title,
        description: values.description,
        tags: values.tags || [],
        goal: values?.goal || '',
        locked: values?.locked || false,
        isIntroSeries: values?.isIntroSeries || false,
      };

      if (isNew) {
        toast
          .promise(
            addNewTrack({
              ...update,
              units: [],
            }),
            {
              pending: `Updating ${newTrack.title} Track...`,
              error: "Can't do it now, try again.",
              success: `Updated ${newTrack.title} Track!`,
            },
          )
          .then(async () => {
            navigate('/tracks');
          });
      } else {
        toast
          .promise(
            updateTrack(trackToUpdate.id, {
              ...update,
              units: units.map((el, index) => ({
                id: el.id,
                order: index,
              })),
            }),
            {
              pending: `Adding ${newTrack.title} Track...`,
              error: "Can't do it now, try again.",
              success: `Added ${newTrack.title} Track!`,
            },
          )
          .then(async () => {
            navigate('/tracks');
          });
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      const dbTags = await getAllTags();
      const selectorT = dbTags.map((dbTag: TagType) => ({
        label: `${dbTag.name}`,
        value: dbTag?.id,
      }));
      setTags(selectorT);
      if (id !== 'new') {
        const currTrack = await getTrackById(id || '');
        if (currTrack === null) {
          navigate('/Tracks');
          return;
        }

        await setValues({
          title: currTrack.title || '',
          description: currTrack.description || '',
          locked: currTrack.locked || false,
          tags: currTrack.tags || undefined,
          goal: currTrack.goal || undefined,
          isIntroSeries: currTrack.isIntroSeries || false,
        });

        setTrack(currTrack);

        if (currTrack?.tags?.length) {
          setSelectedTags(selectorT.filter((t) => currTrack?.tags?.includes(t?.value)));
        }

        if (currTrack?.goal) {
          const sGoal = goals.find((x) => x.value === currTrack?.goal) || null;
          setSelectedGoal(sGoal);
        }
        if (currTrack?.isIntroSeries) {
          setIsIntroSeries(currTrack?.isIntroSeries);
        }
        if (currTrack?.locked) {
          setIsLocked(currTrack?.locked);
        }
      }
    };

    getData().then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  useEffect(() => {
    if (selectedGoal) {
      setValues({
        ...values,
        goal: selectedGoal.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal]);

  const addUnitToTrack = useCallback(
    async (unitId: string) => {
      if (track) {
        try {
          await updateTrack(dieIfNullOrUndef(track.id), {
            units: arrayUnion({
              id: unitId,
              order: track.units.length,
            }),
          }).then(emptyCallback);
          const fTrack = await getTrackById(track.id);
          setTrack(fTrack);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [track],
  );

  const removeUnitFromTrack = useCallback(
    async (unitId: UnitId) => {
      if (units) {
        setUnits((prev) => {
          const index = units.findIndex((e) => e.id === unitId);
          const temp = [...prev];
          temp.splice(index, 1);
          return temp;
        });
      }
    },
    [units],
  );

  const getGoals = async () => {
    const dbGoals = await getAllGoals();
    const selectorG: ReactSelectOptions[] = dbGoals.map((dbGoal: GoalType) => ({
      label: `${dbGoal.name}`,
      value: dbGoal?.id,
    }));
    setGoals(selectorG);
    if (values?.goal) {
      const sGoal = goals.find((x) => x.value === values?.goal) || null;
      setSelectedGoal(sGoal);
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      isIntroSeries: thisIsIntroSeries,
    });
  }, [thisIsIntroSeries]);

  useEffect(() => {
    setValues({
      ...values,
      locked: thisLocked,
    });
  }, [thisLocked]);

  useEffect(() => {
    getGoals();
  }, []);

  const changeTo = (id: string, to: 'PROD' | 'DEV') => {
    setValidateElement({
      id,
      type: 'track',
      to,
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                  Track Details <span style={{ fontSize: '80%' }}>(ID: {track?.id})</span>
                </label>
                <p>
                  <strong>Created:</strong>&nbsp;{uiFormatTimestamp(track?.createdAt)}
                  &nbsp;&nbsp;
                  <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(track?.updatedAt)}
                </p>
                {track ? (
                  <>
                    <div className={'flex flex-row items-center mt-3'}>
                      <p className='font-bold mr-3'>Status:</p>
                      {track?.productionStatus || 'WIP'}
                      {track?.productionStatus === 'DEV' ? (
                        <button
                          type={'button'}
                          onClick={() => changeTo(track?.id, 'PROD')}
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          Change to Prod
                        </button>
                      ) : null}
                      {!track?.productionStatus ? (
                        <button
                          type={'button'}
                          onClick={() => changeTo(track?.id, 'DEV')}
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          Change to Dev
                        </button>
                      ) : null}
                    </div>
                    <div className={'flex flex-row items-center mt-3'}>
                      <p className='font-bold mr-3'>Locked:</p>
                      {track?.locked ? (
                        <CheckIcon className='h-5 w-5 text-green-500' />
                      ) : (
                        <XIcon className='h-5 w-5 text-red-400' />
                      )}
                    </div>
                  </>
                ) : null}
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        maxLength={40}
                        type='text'
                        title='title'
                        id='title'
                        autoComplete='title'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.title ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.title}
                        onChange={handleChange}
                        placeholder={'Track Title'}
                      />
                    </div>
                  </div>
                  {errors && errors.title && <div className='text-red-600'>{errors?.title?.toString()}</div>}
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type='checkbox'
                      title='locked'
                      onChange={() => setIsLocked(!thisLocked)}
                      checked={values.locked}
                    />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Is This Track Part of the Intro Series
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type='checkbox'
                      title='isIntroSeries'
                      onChange={() => setIsIntroSeries(!thisIsIntroSeries)}
                      checked={values.isIntroSeries}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      maxLength={160}
                      id='description'
                      title='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'Enter a description.  The users might see this'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>*/}
          {/*  <label htmlFor='tracks' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>*/}
          {/*    Goal Type*/}
          {/*  </label>*/}
          {/*  <div className='mt-1 sm:mt-0 sm:col-span-2'>*/}
          {/*    <Select*/}
          {/*      options={GoalTypeSelector || []}*/}
          {/*      value={values.goalType}*/}
          {/*      onChange={(vals) => {*/}
          {/*        setSelectedTags(vals as ReactSelectOptions[]);*/}
          {/*      }}*/}
          {/*      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}*/}
          {/*    />*/}
          {/*    <div className='flex flex-row items-center max-w-lg justify-between mt-4'>*/}
          {/*      <h4 className=''>If you do not see the tag you are looking for, create it</h4>*/}
          {/*      <button*/}
          {/*        onClick={() => navigate('/tags/new')}*/}
          {/*        className={`justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}*/}
          {/*      >*/}
          {/*        Add Tags*/}
          {/*      </button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='tracks' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Add Tags
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <Select
                options={tags || []}
                isMulti={true}
                value={selectedTags}
                onChange={(vals) => {
                  setSelectedTags(vals as ReactSelectOptions[]);
                }}
                className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
              />
              <div className='flex flex-row items-center max-w-lg justify-between mt-4'>
                <h4 className=''>If you do not see the tag you are looking for, create it</h4>
                <button
                  onClick={() => navigate('/tags/new')}
                  className={`justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  Add Tags
                </button>
              </div>
            </div>
          </div>
          <div className='mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
            <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Goal
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <Select
                className={`max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                  errors.goal ? 'border-red-300' : 'border-gray-300'
                } rounded-md`}
                options={goals}
                value={selectedGoal}
                onChange={(value) => {
                  setSelectedGoal(value);
                }}
              />
            </div>
          </div>

          {track && (
            <ElementsList
              elements={units}
              setElements={setUnits}
              onAddUnitClick={() => setShowAddUnitModal(true)}
              onRemoveUnitClick={removeUnitFromTrack}
            />
          )}

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/tracks')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {track ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
      {showAddUnitModal && track && (
        <AddUnitModal
          addUnit={addUnitToTrack}
          unitsIds={track.units.map((u) => u.id)}
          hide={() => setShowAddUnitModal(false)}
        />
      )}
    </>
  );
};

export default TrackForm;
