import { z } from 'zod';

export const industryAverageTypeSchema = z.object({
  depression: z.number(),
  distractability: z.number(),
  happiness: z.number(),
  latency: z.number(),
  overwhelm: z.number(),
  restfulness: z.number(),
  concentration: z.number(),
  equanimity: z.number(),
  sensory_clarity: z.number(),
});

export const industryAverageTypeBaseSchema = industryAverageTypeSchema.partial();
