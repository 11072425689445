// *******************************************************
// Invite Friends Page
// -------------------------------------------------------
// This is a Page for sending invites to friends
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAccountById } from '../../collections/account';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createAccessCode } from '../../collections/accessCode';
import { useJwt } from 'react-jwt';
// *******************************************
// Component Imports
// -------------------------------------------
import { Helmet } from 'react-helmet-async';
import { AccountType } from '../../modeltypes/account';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const InviteFriendsPage = () => {
  const params = useParams<{ token: string }>();
  const { decodedToken } = useJwt<{ userId: string }>(params?.token || '');
  const [account, setAccount] = useState<AccountType | null>();
  const [refresh, setRefresh] = useState(false);
  const [info, setInfo] = useState<string>();
  const [pending, setPending] = useState(false);

  const { handleChange, values, errors, handleSubmit, resetForm } = useFormik<{ email: string }>({
    validationSchema: yup.object().shape({
      email: yup.string().email('Has to be email').required(`It's required`),
    }),
    initialValues: { email: '' },
    onSubmit: (values) => {
      setInfo('');
      setPending(true);
      if (account) {
        createAccessCode({
          from: account,
          to: values.email,
        }).then(
          () => {
            setInfo('Invite has been sent');
            resetForm();
            setRefresh(!refresh);
            setPending(false);
          },
          () => {
            setInfo('Something went wrong, try again');
            setPending(false);
          },
        );
      }
    },
  });

  useEffect(() => {
    if (decodedToken) {
      getAccountById(decodedToken.userId).then((account) => {
        setAccount(account);
      });
    }
  }, [refresh, decodedToken]);

  if (decodedToken && !decodedToken?.userId && account === null) {
    return <Navigate to={'/'} replace={true} />;
  }

  return (
    <div className={'flex block h-screen relative sm:justify-center '}>
      <Helmet title={'Invite Your Friends - Equa Health'} />
      <div className='absolute sm:relative sm:self-center h-min min-w-[360px] w-[400px] animate-fadeIn'>
        <img
          className={'mt-4 opacity-25 sm:opacity-100 sm:mt-[-8rem]'}
          src={require('../../assets/inviteImages/Shapes1.png')}
          alt={'shape1'}
        />
        <img
          className={'mt-[-70px] ml-auto opacity-50 sm:opacity-100 '}
          src={require('../../assets/inviteImages/Shapes2.png')}
          alt={'shape2'}
        />
        <img
          className={'mt-[-20px] opacity-75 sm:opacity-100'}
          src={require('../../assets/inviteImages/Shapes3.png')}
          alt={'shape3'}
        />
      </div>
      <div
        className={`flex w-max min-w-[354px] flex-col z-10 ml-auto mr-auto mt-[60%] sm:pr-2 sm:pl-2 sm:mt-0 sm:ml-[80px] sm:self-center sm:mr-5 transition-opacity duration-1000 ${
          account ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {account?.allowedInvites ? (
          <>
            <p className={'text-3xl font-bold'} style={{ color: '#4E5BA9' }}>
              Share Equa with a friend
            </p>
            <p className={'mt-1 text-sm'}>{`A mindfulness coach in your pocket`}</p>
            <div className={'mt-10 mb-10'}>
              <label htmlFor='password' className='block text-sm text-gray-500'>
                Please enter an email
              </label>
              <div className='mt-3'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className={`appearance-none block w-full px-3 py-2 border ${
                    errors.email ? 'border-red-300' : 'border-sky-400'
                  } rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sky-300 focus:border-sky-300 sm:text-sm`}
                  placeholder={'Email address'}
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
              {errors.email && <p className='block text-sm text-red-500 mt-3'>{errors.email}</p>}
              <p className='block text-sm text-gray-500 mt-3'>{`You have ${account.allowedInvites} shares available`}</p>
            </div>
            <button
              disabled={pending}
              onClick={() => {
                handleSubmit();
              }}
              type='button'
              className='w-full inline-flex items-center justify-center rounded-full border border-transparent bg-black px-4 py-2 font-bold text-white shadow-sm hover:bg-stone-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2'
            >
              Invite
            </button>

            {info && <p className='block text-lg text-sky-700 font-bold mt-3'>{info}</p>}
          </>
        ) : (
          account && (
            <>
              <p className={'text-3xl font-bold'} style={{ color: '#4E5BA9' }}>
                {'Sorry,\nyou have no invites left'}
              </p>
              <p className={'mt-1 text-xl font-bold '}>{`Contact us for more!`}</p>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default InviteFriendsPage;
