// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { TaskId } from '../modeltypes/id';
import { TaskType, TaskTypeBase, TaskTypeBuilder, TaskTypeNew } from '../modeltypes/tasks';
import { TASKS_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewTaskId = getNewIdFor<TaskId>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewTask = addNewFor<TaskId, TaskTypeNew>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewTask = createNewFor<TaskId, TaskTypeBuilder>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewTask = setNewFor<TaskId, TaskTypeNew>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTaskRefById = getRefByIdFor<TaskId>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTaskById = getByIdFor<TaskId, TaskType>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTasksByIdArray = getByIdArrayFor<TaskId, TaskType>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTasks = getAllFor<TaskType>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getTasksByQuery = getByQueryFor<TaskType>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllTasksQuery = getAllQueryFor(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateTask = updateDocumentFor<TaskId, TaskTypeBase>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteTask = deleteDocumentFor<TaskId>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchTaskIdSet = watchIdSetFor<TaskId>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskType = getValidateTypeFor<TaskType>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskTypeBase = getValidateTypeBaseFor<TaskTypeBase>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskTypeBuilder = getValidateTypeBuilderFor<TaskTypeBuilder>(TASKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateTaskTypeNew = getValidateTypeNewFor<TaskType>(TASKS_TABLE_NAME);
