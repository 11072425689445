import { addDoc, collection } from '../models/dalaccess';
import { createTokenWithUID } from '../firebase/firebaseConfig';
import { getUserById } from './user';
import { AccountType } from '../modeltypes/account';
import { MAIL_TABLE_NAME } from './tableName';

export const sendInvitationMail = async ({ from, to, code }: { from: AccountType; to: string; code: string }) => {
  const people = ['Mat', 'Shawn', 'Ian', 'Bob'];
  const randomPerson = people[Math.floor(Math.random() * people.length)];
  if (from.uid) {
    const user = await getUserById(from.uid);
    await addDoc(collection(MAIL_TABLE_NAME), {
      to: to,
      message: {
        subject: 'Equa Health App Invitation',
        html: `
                <div dir='3D"ltr"'>
                  <img src='https://app.equahealth.io/shapes-header.png' alt='shapes' width='900' height='300' style='margin-left: auto;margin-right: auto; display: block;'>
                  <div style='margin-left: 10px; margin-top: 10px;'>
                      <p style='font-size: 16px'>Hi there,</p>

                      <p style='font-size: 16px'>This is ${randomPerson} from <b>Equa Health</b>.  You are receiving this email because <b>${user?.firstName} ${user?.lastName}</b> has given you a free code to meditate with Equa.</p>

                      <p style='font-size: 16px'>Want to free your best? Use the following code to signup:</p>

                      <p style='font-size: 20px;'><b>${code}</b></p>

                      <p style='font-size: 16px'>Apple user? Download the app from the App Store <a href='https://apps.apple.com/us/app/equa/id1541398915'> here</a>.</p>

                      <p style='font-size: 16px'>Android user?  Download the app from the Play Store <a href='https://play.google.com/store/apps/details?id=com.imeditate.equaapp'> here</a>.</p>
                  </div>
               </div>
             `,
      },
    });
  }
};

export const sendUserInvitationURL = async ({ uid, email }: { uid: string; email: string }) => {
  const { data } = await createTokenWithUID({ uid: uid });
  const invitationPageUrl = `https://app.equahealth.io/invite/${data.token}`;
  await addDoc(collection(MAIL_TABLE_NAME), {
    to: email,
    message: {
      subject: 'Equa Health App Invitation',
      html: `
             <div dir='3D"ltr"'>
                  <img src='https://app.equahealth.io/shapes-header.png' alt='shapes' width='900' height='300' style='margin-left: auto;margin-right: auto; display: block;'>
                <div style='margin-left: 10px; margin-top: 10px;'>
                    <p style='font-size: 16px'>Hi there,</p>

                    <p style='font-size: 16px'>We're incredibly grateful for your participation in our mindfulness program with Equa Health. So grateful, in fact, that we're excited to offer you the chance to share Equa with a few of your friends, family and coworkers!</p>
                     
                    <p style='font-size: 16px'>Click the link below to send free access codes to 3 people you think might benefit from giving Equa a try.</p>
                      
                    <p style='font-size: 16px'>Send your sharable codes <a href='${invitationPageUrl}' style='font-size: 18px;'>here</a><p></p>
                    
                    <p style='font-size: 16px'>Link above not working? Feel free to copy and paste the url below into your browser.</p>
                    
                    ${invitationPageUrl}

                    <p style='font-size: 16px'>Yours,</p>
                    <p style='font-size: 16px'><b>The Equa Health Team</b></p>
                    
                    <p style='font-size: 16px'>Visit <b><a href='https://equahealth.io/'>Equa Health</a></b></p>

                </div>
             </div>
           `,
    },
  });
};
