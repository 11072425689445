/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCoachById } from '../../collections/coach';
import {
  addNewCohortMeetings,
  getCohortMeetingsById,
  getLabelForMeetingType,
  updateCohortMeetings,
} from '../../collections/cohortMeeting';
import Select, { SingleValue } from 'react-select';
import { getAllCohorts } from '../../collections/cohort';
import ReactDatePicker from 'react-datepicker';
// noinspection ES6PreferShortImport
import { Timestamp } from '../../models/dalaccess';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { CohortType } from '../../modeltypes/cohort';
import { CohortMeetingType, CohortMeetingTypeNew, MeetingTypeEnum } from '../../modeltypes/cohortMeeting';
import { CoachType } from '../../modeltypes/coach';
import { getDateWithTimezone, getTimezoneMinutesOffset } from '../../utility/timeUtility';
import { addMinutes, format } from 'date-fns';

const CohortMeetingForm = () => {
  type CohortSelect = {
    label: string;
    value: CohortType;
  };

  type CoachSelect = {
    label: string;
    value: CoachType;
  };

  const [meeting, setMeeting] = useState<CohortMeetingType | null>(null);
  const [allCoaches, setAllCoaches] = useState<CoachType[]>([]);
  const [cohorts, setCohorts] = useState<CohortSelect[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cohortsDetails, setCohortsDetails] = useState<CohortType[] | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Cohort Sessions',
        path: '/cohortSessions',
      },
      {
        label: id === 'new' ? 'New Cohort Session' : 'Edit Cohort Session',
        path: `/cohortSessions/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const { values, errors, handleSubmit, handleChange, setValues } = useFormik<{
    location: string;
    title: string;
    url: string;
    cohort: string;
    description: string;
    lengthMins: number;
    date: Date;
    type: MeetingTypeEnum;
    coach: string;
  }>({
    initialValues: {
      type: MeetingTypeEnum.GROUP,
      location: '',
      title: '',
      url: '',
      description: '',
      cohort: '',
      coach: '',
      lengthMins: 0,
      date: new Date(),
    },
    validationSchema: yup.object().shape({
      type: yup.string().required('Type is required').equals(['drop_in', 'group', 'private'], 'Wrong type'),
      title: yup
        .string()
        .test('empty-check', "Title can't be empty", (title) => title != undefined && title.length > 0),
      cohort: yup.string().required('Cohort is required'),
      url: yup.string().required('Meeting URL is required'),
      coach: yup.string().required('Coach is required'),
    }),
    onSubmit: (values) => {
      const newMeeting: CohortMeetingTypeNew = {
        date: Timestamp.fromDate(values.date),
      };

      newMeeting.type = values.type;
      newMeeting.location = values.location;
      newMeeting.title = values.title;
      newMeeting.url = values.url;
      newMeeting.cohort = values.cohort;
      newMeeting.description = values.description;
      newMeeting.lengthMins = values.lengthMins;
      newMeeting.coach = values.coach;

      if (meeting) {
        newMeeting.id = meeting.id;
        updateCohortMeetings(meeting.id, newMeeting).then(() => navigate('/cohortSessions'));
      } else {
        addNewCohortMeetings(newMeeting).then(() => navigate('/cohortSessions'));
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      const allCohorts = await getAllCohorts();
      setCohortsDetails(allCohorts);
      setCohorts(
        allCohorts.map((c) => {
          return {
            label: c.name || '',
            value: c,
          };
        }),
      );
      if (id !== 'new') {
        const currMeeting = await getCohortMeetingsById(id || null);
        if (currMeeting !== null) {
          setMeeting(currMeeting);
          // const currCoach = await getCoachById(meeting?.coach || null);
          // setCoach(currCoach);

          await setValues({
            ...values,
            title: currMeeting.title || '',
            location: currMeeting.location || '',
            type: currMeeting.type || MeetingTypeEnum.GROUP,
            url: currMeeting.url || '',
            cohort: currMeeting.cohort || '',
            description: currMeeting.description || '',
            lengthMins: currMeeting.lengthMins || 0,
            date: currMeeting.date.toDate(),
            coach: currMeeting.coach || '',
          });
        }
      }
    };
    getData().then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cohort = cohorts.find(({ value }) => value.id === values.cohort);
    if (cohort?.value?.coach) {
      const coachesArray = cohort.value.coach.map(async (id) => {
        const coach = await getCoachById(id);
        if (coach) {
          return Promise.resolve(coach);
        } else {
          return Promise.reject();
        }
      });
      Promise.all(coachesArray).then((coaches) => {
        setAllCoaches(coaches);
      });
    }
  }, [cohorts, values.cohort]);

  const handleCohortChange = (val: SingleValue<CohortSelect>) => {
    setValues({
      ...values,
      coach: '',
      cohort: val?.value.id || '',
    });
  };

  const handleCoachChange = (val: SingleValue<CoachSelect>) => {
    setValues({
      ...values,
      coach: val?.value.id || '',
    });
  };

  const selectedCohortDetails = useMemo(() => {
    return cohortsDetails?.find((el) => el.id === values.cohort);
  }, [cohortsDetails, values.cohort]);

  const cohortTimezoneMinutesDifference = useMemo(() => {
    if (selectedCohortDetails?.timezone) {
      return getTimezoneMinutesOffset(new Date(), selectedCohortDetails.timezone.name);
    }
    return null;
  }, [selectedCohortDetails?.timezone]);

  const setDate = (cohortDate: Date) => {
    let creatorDate = new Date(cohortDate);
    if (cohortTimezoneMinutesDifference) {
      creatorDate = addMinutes(creatorDate, cohortTimezoneMinutesDifference);
    }
    setValues({
      ...values,
      date: creatorDate,
    });
  };
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Title
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='title'
                      id='title'
                      autoComplete='title'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                        errors.title ? 'border-red-300' : 'border-gray-300'
                      }`}
                      defaultValue={values.title}
                      onChange={handleChange}
                      placeholder={'example: 30 minute practice session'}
                    />
                  </div>
                </div>
              </div>

              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='url' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    URL
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='url'
                        id='url'
                        autoComplete='url'
                        className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.url ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.url}
                        onChange={handleChange}
                        placeholder={'location of online meeting or online info'}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='lengthMins' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Length (minutes)
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='number'
                        name='lengthMins'
                        id='lengthMins'
                        autoComplete='lengthMins'
                        className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                        defaultValue={values.lengthMins}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='location' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Location
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='location'
                      id='location'
                      autoComplete='location'
                      className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                      defaultValue={values.location}
                      onChange={handleChange}
                      placeholder={'"Zoom" or "258 Read St. Los Angeles"'}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Description
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <textarea
                    id='description'
                    name='description'
                    rows={3}
                    className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                    defaultValue={values.description}
                    onChange={handleChange}
                    placeholder={'This is a 25 minute practice session with 5 minutes Q&A at the end"'}
                  />
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Cohort
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    isClearable
                    options={cohorts}
                    value={cohorts.find((c) => c.value.id === values.cohort)}
                    onChange={handleCohortChange}
                    className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  />
                  {errors && errors.cohort && <div className='text-red-600'>{errors.cohort.toString()}</div>}
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='coach' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Coach
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    isDisabled={!values.cohort}
                    isClearable
                    options={allCoaches
                      .filter((coach) => coach.name && coach.id)
                      .map((coach) => ({
                        label: coach.name || '',
                        value: coach,
                      }))}
                    value={allCoaches
                      .filter((coach) => coach.name && coach.id)
                      .map((coach) => ({
                        label: coach.name || '',
                        value: coach,
                      }))
                      .find((c) => c.value.id === values.coach)}
                    onChange={handleCoachChange}
                    className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  />
                  {errors && errors.cohort && <div className='text-red-600'>{errors?.coach?.toString()}</div>}
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Type
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    options={[
                      {
                        label: getLabelForMeetingType(MeetingTypeEnum.GROUP),
                        value: MeetingTypeEnum.GROUP,
                      },
                      {
                        label: getLabelForMeetingType(MeetingTypeEnum.PRIVATE),
                        value: MeetingTypeEnum.PRIVATE,
                      },
                      {
                        label: getLabelForMeetingType(MeetingTypeEnum.DROP_IN),
                        value: MeetingTypeEnum.DROP_IN,
                      },
                    ]}
                    value={{
                      value: values.type,
                      label: getLabelForMeetingType(values.type),
                    }}
                    onChange={(val) => {
                      if (val) handleChange('type')(val.value);
                    }}
                    className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  />
                  {errors && errors.type && <div className='text-red-600'>{errors.type.toString()}</div>}
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Date (in cohort timezone)
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  {selectedCohortDetails?.timezone ? (
                    <>
                      <ReactDatePicker
                        selected={getDateWithTimezone(
                          Timestamp.fromDate(values.date),
                          selectedCohortDetails.timezone.name,
                        )}
                        onChange={(c) => {
                          if (c) {
                            setDate(c);
                          }
                        }}
                        showTimeSelect
                        dateFormat='MMMM d, yyyy h:mm aa'
                        className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      />
                      <p className={'text-[12px] mt-3'}>Your time: {format(values.date, 'MMMM d, yyyy h:mm aa')}</p>
                    </>
                  ) : (
                    <p>Select cohort with timezone</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/cohortSessions')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {meeting ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default CohortMeetingForm;
