/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { cohortIdSchema, companyIdSchema, lessonRatingIdSchema, userIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import {
  LessonRatingType,
  LessonRatingTypeBase,
  LessonRatingTypeBuilder,
  LessonRatingTypeNew,
} from '../modeltypes/lessonRating';
import { timestampType } from './shared';

export const lessonRatingTypeSchema = z.object({
  id: lessonRatingIdSchema,
  cohort: z.union([cohortIdSchema, z.literal('not assigned')]),
  company: z.union([companyIdSchema, z.literal('not assigned')]),
  lesson: z.number(),
  program: z.string(),
  lessonId: z.string(),
  unitId: z.string(),
  rate: z.number(),
  user: userIdSchema,
  createdAt: timestampType().optional(),
});

export const lessonRatingTypeBaseSchema = lessonRatingTypeSchema.partial();

export const lessonRatingTypeBuilderSchema = lessonRatingTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: lessonRatingIdSchema.default(() => IdFactory.LessonRating()) }));

export const lessonRatingTypeNewSchema = lessonRatingTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: lessonRatingIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: LessonRatingType = lessonRatingTypeSchema.parse(undefined);
  const x: LessonRatingTypeBase = lessonRatingTypeBaseSchema.parse(undefined);
  const y: LessonRatingTypeBuilder = lessonRatingTypeBuilderSchema.parse(undefined);
  const z: LessonRatingTypeNew = lessonRatingTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
