/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { addNewAppUpdateInfo, getAppUpdateInfoById, updateAppUpdateInfo, } from '../../collections/paths';
import { addNewAppUpdateInfo, getAppUpdateInfoById, updateAppUpdateInfo } from '../../collections/appUpdateInfo';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { AppUpdateInfoPageType, AppUpdateInfoType, AppUpdateInfoTypeBase } from '../../modeltypes/appUpdateInfo';
import { toast } from 'react-toastify';
import { uiFormatTimestamp } from '../../collections/shared';
// import Select from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyToCliboardIcon } from '../../assets/copyToClipboard.svg';
import { FileUploader } from 'react-drag-drop-files';
import { uploadFile } from '../../firebase/storage';

// Constants

export type AppUpdateInfoItemSelector = {
  value: string;
  label: string;
};

// const imageTypeSelector: AppUpdateInfoItemSelector[] = [
//   {
//     label: 'Full Screen',
//     value: 'full',
//   },
//   {
//     label: 'Half Screen',
//     value: 'half',
//   },
//   {
//     label: 'Quarter Screen',
//     value: 'quarter',
//   },
// ];

const AppUpdateInfoForm = () => {
  const [appUpdateInfo, setThisAppUpdateInfo] = useState<AppUpdateInfoType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'App Update Onboarding',
        path: '/appUpdateInfo',
      },
      {
        label: id === 'new' ? 'New App Update Onboarding' : 'Edit App Update Onboarding',
        path: `/appUpdateInfo/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    //resetForm
  } = useFormik<{
    title: string;
    description: string;
    locked: boolean;
    pages: (AppUpdateInfoPageType & { imageFile?: File })[];
    version: string;
    features: string[];
  }>({
    initialValues: {
      title: '',
      description: '',
      locked: true,
      pages: [],
      version: '',
      features: [],
      // id: '',
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Name is required'),
      description: yup.string(),
    }),
    validate: (values) => {
      const errors: any = {};
      if (!values?.pages?.length) {
        errors.pages = 'At least one page in the update notification item is required';
      } else {
        for (const page of values.pages) {
          if (!page.text) {
            return (errors.pages = "AppUpdateInfo Items can't be empty!");
          }
        }
      }
      return errors;
    },

    onSubmit: async (newAppUpdateInfo) => {
      let appUpdateId = appUpdateInfo?.id;
      const isNew = !appUpdateInfo?.id;
      console.log('appUpdateId: ', appUpdateId);
      if (!appUpdateId) {
        appUpdateId = await addNewAppUpdateInfo();
      }
      console.log('rewardId after: ', appUpdateId);
      console.log('isNew: ', isNew);

      const newPages: AppUpdateInfoPageType[] = [];

      for (const pageIndex in values.pages) {
        const page = values.pages[pageIndex];
        const oldBadge = appUpdateInfo?.pages?.find((p) => p.id === page.id);
        if ((!oldBadge || page.imageUrl !== oldBadge.imageUrl) && page.imageFile) {
          const iconUrl = await uploadFile(
            page.imageFile,
            `${appUpdateId}-page${pageIndex + 1}.${page.imageFile.name.split('.').pop()}`,
            ['assets', 'badgeIcons'],
          );
          delete page.imageFile;

          newPages.push({
            ...page,
            imageUrl: iconUrl,
          });
        } else {
          delete page.imageFile;
          newPages.push(page);
        }
      }

      const update: AppUpdateInfoTypeBase = {
        id: appUpdateId,
        title: values.title,
        description: values.description,
        locked: values.locked,
        pages: newPages,
        version: values.version,
        features: values.features,
      };

      toast
        .promise(updateAppUpdateInfo(appUpdateId, update), {
          pending: `Updating ${newAppUpdateInfo.title} AppUpdateInfo...`,
          error: "Can't do it now, try again.",
          success: `Updated ${newAppUpdateInfo.title} AppUpdateInfo!`,
        })
        .then(async () => {
          navigate('/appUpdateInfo');
        });
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currAppUpdateInfo = await getAppUpdateInfoById(id || '');
        if (currAppUpdateInfo === null) {
          navigate('/appUpdateInfo');
          return;
        }

        await setValues({
          title: currAppUpdateInfo.title || '',
          description: currAppUpdateInfo.description || '',
          locked: currAppUpdateInfo.locked || false,
          pages: currAppUpdateInfo.pages || [],
          version: currAppUpdateInfo.version || '',
          features: currAppUpdateInfo.features || [],
        });

        setThisAppUpdateInfo(currAppUpdateInfo);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const handleUploadDrop = useCallback(
    (pageId: string) =>
      (imageFile: File): void => {
        const imageUrl = URL.createObjectURL(imageFile);
        const pages = values.pages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                imageUrl,
                imageFile,
              }
            : page,
        );
        setValues({
          ...values,
          pages,
        });
      },
    [values, setValues],
  );

  const removeFeature = (index: number) => {
    const tempFeatures = [...values.features];
    tempFeatures.splice(index, 1);
    setFieldValue('features', tempFeatures);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                AppUpdate Details <span style={{ fontSize: '80%' }}>(ID: {appUpdateInfo?.id || 'not yet'})</span>
              </label>
              <p>
                <strong>Created:</strong>&nbsp;{uiFormatTimestamp(appUpdateInfo?.createdAt)}
                &nbsp;&nbsp;
                <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(appUpdateInfo?.updatedAt)}
              </p>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='title'
                        id='title'
                        autoComplete='title'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.title ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.title}
                        onChange={handleChange}
                        placeholder={'AppUpdateInfo title'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Version
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='version'
                        id='version'
                        autoComplete='version'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.version ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.version}
                        onChange={handleChange}
                        placeholder={'App version (examp; : 2.0.3)'}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'What is the purpose of this task.  A user might see this'}
                    />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Features
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2 flex flex-col max-w-lg flex'>
                    {values.features.map((feature, index) => (
                      <div key={`feature-${index}`} className={'flex flex-row'}>
                        <div className='flex-1  rounded-md shadow-sm mt-2'>
                          <input
                            type='text'
                            name={`features.[${index}]`}
                            id={`features.[${index}]`}
                            autoComplete={`features.[${index}]`}
                            className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                              errors.version ? 'border-red-300' : 'border-gray-300'
                            }`}
                            value={feature}
                            onChange={handleChange}
                            placeholder={'Feature (examp; New Design)'}
                          />
                        </div>
                        <button
                          className={'mx-2'}
                          onClick={() => {
                            removeFeature(index);
                          }}
                        >
                          -
                        </button>
                      </div>
                    ))}
                    <button
                      className={'flex self-center mt-2'}
                      onClick={() => {
                        setFieldValue('features', [...values.features, '']);
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className={`flex flex-col sm:border-t sm:border-gray-200 `}>
                  <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                    App Update Pages
                  </label>
                  <div>
                    {values.pages?.map((page, index) => (
                      <div key={page.id} className={` ${index > 0 ? 'sm:border-t sm:border-gray-200 ' : ''}`}>
                        <label
                          htmlFor='name'
                          className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 sm:border-gray-200'
                        >
                          Page {index + 1}
                        </label>

                        <div key={page.id} className={`flex flex-row items-center justify-evenly sm:py-5`}>
                          <div className='min-w-[700px] flex rounded-md flex flex-col'>
                            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:border-gray-200 sm:py-5'>
                              <label
                                htmlFor='cover-photo'
                                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                              >
                                Title
                              </label>
                              <input
                                type='text'
                                name={`pages.[${index}].title`}
                                id={`pages.[${index}].title`}
                                autoComplete={`pages`}
                                className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300 border-gray-300`}
                                value={values?.pages?.[index].title || ''}
                                onChange={handleChange}
                                placeholder={'Enter AppUpdateInfo Item'}
                              />
                            </div>

                            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:border-gray-200 sm:py-5'>
                              <label
                                htmlFor='cover-photo'
                                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                              >
                                Text
                              </label>
                              <input
                                type='text'
                                name={`pages.[${index}].text`}
                                id={`pages.[${index}].text`}
                                autoComplete={`pages`}
                                className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300 border-gray-300`}
                                value={values?.pages?.[index].text || ''}
                                onChange={handleChange}
                                placeholder={'Enter AppUpdateInfo Item'}
                              />
                            </div>
                            {/*<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>*/}
                            {/*  <label*/}
                            {/*    htmlFor='cover-photo'*/}
                            {/*    className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'*/}
                            {/*  >*/}
                            {/*    Image size*/}
                            {/*  </label>*/}
                            {/*  <Select*/}
                            {/*    options={imageTypeSelector}*/}
                            {/*    value={imageTypeSelector.find((e) => page?.imageSize === e.value)}*/}
                            {/*    onChange={(option) => {*/}
                            {/*      setFieldValue(`pages.${index}.imageSize`, option?.value);*/}
                            {/*    }}*/}
                            {/*    className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}*/}
                            {/*  />*/}
                            {/*</div>*/}
                            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                              <label
                                htmlFor='cover-photo'
                                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                              >
                                Existing Image
                              </label>
                              <div className='mt-1 sm:mt-0 sm:col-span-2' style={{ display: 'inline-block' }}>
                                <div className='space-y-1 text-left' style={{ display: 'inline-block' }}>
                                  {!page?.imageUrl ? (
                                    <span className='text-sm'>None</span>
                                  ) : (
                                    <>
                                      <a
                                        target='_blank'
                                        rel='noreferrer noopener'
                                        href={page.imageUrl}
                                        className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                                        onClick={() => false}
                                        style={{ display: 'inline-block' }}
                                        title={'Link to audio file.'}
                                      >
                                        Image File
                                      </a>
                                      <CopyToClipboard text={page.imageUrl}>
                                        <button type={'button'}>
                                          <CopyToCliboardIcon />
                                        </button>
                                      </CopyToClipboard>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
                              <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                                Upload Image
                              </label>
                              <div className={`mt-1 sm:mt-0 sm:col-span-2`}>
                                <FileUploader
                                  handleChange={handleUploadDrop(page.id)}
                                  name='icon'
                                  types={['png', 'svg']}
                                  multiple={false}
                                  hoverTitle={''}
                                  maxSize={10}
                                  minSize={0}
                                  label={'Select or drag an image here.'}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={(e) => {
                              e.preventDefault();
                              const temp = values.pages ? [...values.pages] : [];
                              const index = temp.findIndex((i) => i.id === page.id);
                              if (index > -1) {
                                temp.splice(index, 1);
                                setValues({
                                  ...values,
                                  pages: temp,
                                });
                              }
                            }}
                          >
                            Delete
                          </button>
                          {errors && errors.pages && <div className='text-red-600'>{errors?.pages?.toString()}</div>}
                        </div>
                      </div>
                    ))}
                    <button
                      className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={(e) => {
                        e.preventDefault();
                        const temp = values.pages ? [...values.pages] : [];
                        temp.push({
                          id: new Date().getTime().toString(),
                          text: '',
                          title: '',
                          imageUrl: '',
                          imageSize: 'quarter',
                        });
                        setValues({
                          ...values,
                          pages: temp,
                        });
                      }}
                    >
                      + Add Page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/appUpdateInfo')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {appUpdateInfo ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default AppUpdateInfoForm;
