// *******************************************************
// Marketing
// -------------------------------------------------------
// This is a Screen for showing list Marketing

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { CSVDownload } from 'react-csv';
import { toast } from 'react-toastify';

// *******************************************
// Component Imports
// -------------------------------------------
import { ChevronDownIcon } from '@heroicons/react/solid';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
// import { getAllStreaks } from 'src/collections/streaks';
// import { AnalyticsType } from 'src/modeltypes/analytics';
import { getAllUsers } from 'src/collections/user';
import { UserType } from 'src/modeltypes/user';
import { getSessionsForUserIdArray } from 'src/collections/session';
import { SessionType } from 'src/modeltypes/session';
// import { VictoryBar, VictoryChart, VictoryGroup, VictoryPie } from 'victory';
// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------
export type DetailedMarketingDownloadType = {
  // id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  age?: number | string | null;
  wants1?: string;
  wants2?: string;
  wants3?: string;
  timeInApp?: number | string | null;
  minInApp?: number | string | null;
};

const Marketing = () => {
  // const Marketing = ({ analytics }: { analytics: AnalyticsType[] | undefined }) => {
  // const [streakss, setStreaks] = useState<StreakType[] | null>(null);
  const [users, setUsers] = useState<UserType[] | null>(null);
  // const [accounts, setAccounts] = useState<AccountType[] | null>(null);

  const [sortFilter, setSortFilter] = useState<{ key: keyof UserType; order: 'asc' | 'desc' }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // For downloading CSVS
  const [isDownloading, setIsDownloading] = useState(false);
  const [sessions, setSessions] = useState<SessionType[][] | null>(null);

  // console.log('sessions?.length', sessions?.length);
  const [csvData, setCsvData] = useState<DetailedMarketingDownloadType[] | null>(null);

  const downloadCsv = () => {
    toast.promise(
      async () => {
        const detailedMarketing: DetailedMarketingDownloadType[] | null = !users?.map?.length
          ? null
          : users?.map((user) => {
              const userSessions =
                sessions?.map((session) => session?.filter((thisS) => thisS.uid === user?.id) || []).flat(1) || [];
              const timeInApp = userSessions?.reduce((acc, session) => acc + (session?.timeInLesson || 0), 0) || 0;
              // console.log(user?.id, ' - timeInApp', timeInApp);
              const minInApp = typeof timeInApp === 'number' && timeInApp > 0 ? Math.ceil(timeInApp / 60) : '-';

              return {
                email: user?.email || '-',
                firstName: user?.firstName || '-',
                lastName: user?.lastName || '-',
                age: user?.age || '-',
                wants1: user?.wants?.[0] || '-',
                wants2: user?.wants?.[1] || '-',
                wants3: user?.wants?.[2] || '-',
                minInApp,
              };
            });

        setCsvData(detailedMarketing);
      },
      {
        pending: 'Preparing data...',
        success: 'Data prepared! Downloading...',
        error: 'Something went wrong. Try again',
      },
    );
  };

  const getUserSessionsDb = async (userIds: string[]) => {
    const sessions = await getSessionsForUserIdArray(userIds);

    setSessions(sessions);
  };

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  // console.log('users: ', users);

  const getUsers = async () => {
    const users = await getAllUsers();

    const usersWeWant = users.filter((user) => {
      // We want them to have an age
      const theyHaveAnAge = (user?.age || 0) > 0;

      const theHaveWants = user?.wants?.length;

      const notTestAccount = ![user?.email, user?.firstName, user?.lastName].some(
        (x) => x?.includes('test') || x?.includes('Test'),
      );

      return theyHaveAnAge && theHaveWants && notTestAccount;
    });
    console.log('usersWeWant.length ', usersWeWant.length);
    const userIds = usersWeWant?.map((user) => user?.id);

    getUserSessionsDb(userIds);

    setUsers(usersWeWant);
    setIsLoading(false);
  };

  // const getAccounts = async () => {
  //   console.log('about to run leaderboard');
  //   const accounts = await getAccountsByIdArray(subscriptionIds);
  //   // console.log('about to run leaderboard ', accounts);
  //   setAccounts(accounts);
  // };

  useEffect(() => {
    getUsers();
  }, []);

  const sortRequest = (fieldName: keyof UserType) => {
    let order: 'asc' | 'desc' = 'asc';
    if (sortFilter && sortFilter.key === fieldName && sortFilter.order === 'asc') {
      order = 'desc';
    }
    setSortFilter({
      key: fieldName,
      order: order,
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <button
        disabled={isDownloading}
        className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
        onClick={async () => {
          setIsDownloading(true);
          await downloadCsv();
          setIsDownloading(false);
        }}
      >
        Download CSV
      </button>

      {csvData && (
        <CSVDownload
          data={csvData}
          asyncOnClick
          onClick={() => {
            downloadCsv();
          }}
        />
      )}

      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 '>
                      Id
                    </th>

                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('email')}
                    >
                      <p className='group inline-flex'>
                        Email
                        <span
                          className={`${sortFilter?.key !== 'email' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>

                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('firstName')}
                    >
                      <p className='group inline-flex'>
                        First Name
                        <span
                          className={`${sortFilter?.key !== 'firstName' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('lastName')}
                    >
                      <p className='group inline-flex'>
                        Last Name
                        <span
                          className={`${sortFilter?.key !== 'lastName' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>

                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('age')}
                    >
                      <p className='group inline-flex'>
                        Age
                        <span
                          className={`${sortFilter?.key !== 'age' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>

                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Mins in App</p>
                    </th>

                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('currentLesson')}
                    >
                      <p className='group inline-flex'>
                        Current Lesson
                        <span
                          className={`${sortFilter?.key !== 'currentLesson' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>

                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('wants')}
                    >
                      <p className='group inline-flex'>
                        Wants
                        <span
                          className={`${sortFilter?.key !== 'wants' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {isLoading ? (
                    <>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton height={50} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton count={15} className='mt-3' />{' '}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {users?.length &&
                        users.map((user) => {
                          const userSessions =
                            sessions
                              ?.map((session) => session?.filter((thisS) => thisS.uid === user?.id) || [])
                              .flat(1) || [];

                          const timeInApp =
                            userSessions?.reduce((acc, session) => acc + (session?.timeInLesson || 0), 0) || 0;
                          const minInApp =
                            typeof timeInApp === 'number' && timeInApp > 0 ? Math.ceil(timeInApp / 60) : '-';

                          return (
                            <tr key={user.id}>
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4 truncate max-w-[50px]'>
                                    <div className='text-gray-500'>{user.id}</div>
                                  </div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>{user.email}</div>
                                  </div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>{user.firstName}</div>
                                  </div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>{user.lastName}</div>
                                  </div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>{user.age}</div>
                                  </div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>{minInApp}</div>
                                  </div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>{user?.currentLesson?.nodeId}</div>
                                  </div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    {user?.wants?.length ? (
                                      <div>
                                        {user?.wants?.map((want) => (
                                          <div key={want || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                            {want}
                                          </div>
                                        ))}
                                      </div>
                                    ) : (
                                      <div className='text-gray-500'>-</div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      <tr></tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
