/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { addNewTask, getTaskById, updateTask, } from '../../collections/paths';
import { addNewTask, getTaskById, updateTask } from '../../collections/tasks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { TaskItemType, TaskType } from '../../modeltypes/tasks';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { uiFormatTimestamp } from '../../collections/shared';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { ReactSelectOptions } from 'src/types/types';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';

// Constants

const TASK_TYPES: { [name: string]: string } = {
  MICROHIT: 'Microhit',
  FORMAL: 'Formal Meditation',
  BACKGROUND: 'Background Meditation',
  PERSONALIZED: 'Personalized Practice',
};

export type TaskItemSelector = {
  value: string;
  label: string;
};

const TaskTypeSelector = Object.keys(TASK_TYPES).map((t) => {
  return {
    label: TASK_TYPES[t],
    value: t,
  };
});

const TaskForm = () => {
  const [task, setThisTask] = useState<TaskType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<ReactSelectOptions[]>([]);
  const [selectedTags, setSelectedTags] = useState<ReactSelectOptions[]>([]);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths, setValidateElement } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Tasks',
        path: '/tasks',
      },
      {
        label: id === 'new' ? 'New Task' : 'Edit Task',
        path: `/tasks/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    //resetForm
  } = useFormik<{
    title: string;
    pathType: string;
    description: string;
    locked: boolean;
    taskItems: TaskItemType[];
    popupMessage?: string | null;
    duration?: number | null;
    tags?: string[];
    // id?: string;
  }>({
    initialValues: {
      title: '',
      pathType: 'task',
      description: '',
      locked: true,
      taskItems: [],
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Name is required'),
      description: yup.string(),
      pathType: yup.string().required('Path Type is required'),
    }),
    validate: (values) => {
      const errors: any = {};
      if (!values?.taskItems?.length) {
        errors.taskItems = 'At least one task item is required';
      } else {
        console.log('inside else');
        for (const taskItem of values.taskItems) {
          if (!taskItem.text) {
            return (errors.taskItems = "Task Items can't be empty!");
          }
        }
      }
      return errors;
    },

    onSubmit: async (newTask) => {
      console.log('newTask: ', newTask);

      if (id !== undefined && id !== 'new') {
        toast
          .promise(updateTask(id, newTask), {
            pending: `Updating ${newTask.title} Task...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newTask.title} Task!`,
          })
          .then(async () => {
            navigate('/tasks');
          });
      } else if (id === 'new') {
        toast
          .promise(addNewTask(newTask), {
            pending: `Adding ${newTask.title} Task...`,
            error: "Can't do it now, try again.",
            success: `Added ${newTask.title} Task!`,
          })
          .then(async () => {
            navigate('/tasks');
          });
      }
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currTask = await getTaskById(id || '');
        if (currTask === null) {
          navigate('/Tasks');
          return;
        }

        await setValues({
          title: currTask.title || '',
          description: currTask.description || '',
          pathType: 'task',
          locked: currTask.locked || false,
          taskItems: currTask.taskItems || [],
          duration: currTask.duration || null,
          tags: currTask.tags || undefined,
        });

        const dbTags = await getAllTags();
        const selectorT = dbTags.map((dbTag: TagType) => ({
          label: `${dbTag.name}`,
          value: dbTag?.id,
        }));
        setTags(selectorT);

        if (currTask?.tags?.length) {
          setSelectedTags(selectorT.filter((t) => currTask?.tags?.includes(t?.value)));
        }
        setThisTask(currTask);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const changeTo = (id: string, to: 'PROD' | 'DEV') => {
    setValidateElement({
      id,
      type: 'task',
      to,
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                Task Details <span style={{ fontSize: '80%' }}>(ID: {task?.id || 'not yet'})</span>
              </label>
              <p>
                <strong>Created:</strong>&nbsp;{uiFormatTimestamp(task?.createdAt)}
                &nbsp;&nbsp;
                <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(task?.updatedAt)}
              </p>
              {task ? (
                <>
                  <div className={'flex flex-row items-center mt-3'}>
                    <p className='font-bold mr-3'>Status:</p>
                    {task?.productionStatus || 'WIP'}
                    {task?.productionStatus === 'DEV' ? (
                      <button
                        type={'button'}
                        onClick={() => changeTo(task?.id, 'PROD')}
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Change to Prod
                      </button>
                    ) : null}
                    {!task?.productionStatus ? (
                      <button
                        type={'button'}
                        onClick={() => changeTo(task?.id, 'DEV')}
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Change to Dev
                      </button>
                    ) : null}
                  </div>
                  <div className={'flex flex-row items-center mt-3'}>
                    <p className='font-bold mr-3'>Locked:</p>
                    {task?.locked ? (
                      <CheckIcon className='h-5 w-5 text-green-500' />
                    ) : (
                      <XIcon className='h-5 w-5 text-red-400' />
                    )}
                  </div>
                </>
              ) : null}
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='title'
                        id='title'
                        autoComplete='title'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.title ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.title}
                        onChange={handleChange}
                        placeholder={'Task title'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'What is the purpose of this task.  A user might see this'}
                    />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Duration
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='number'
                        name='duration'
                        id='duration'
                        autoComplete='duration'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.duration ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values?.duration || 0}
                        onChange={handleChange}
                        placeholder={'How long will this task take'}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Tags:
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={tags || []}
                      isMulti={true}
                      value={selectedTags}
                      onChange={(vals) => {
                        setSelectedTags(vals as ReactSelectOptions[]);
                      }}
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                    />
                  </div>
                </div>

                <div className={`flex flex-col sm:border-t sm:border-gray-200 `}>
                  <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                    Task Items
                  </label>
                  <div>
                    {values.taskItems?.map((taskItem, index) => (
                      <div
                        key={taskItem.id}
                        className={`flex flex-row items-center justify-evenly sm:py-5 ${
                          index > 0 ? 'sm:border-t sm:border-gray-200 ' : ''
                        }`}
                      >
                        <label
                          htmlFor='name'
                          className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:border-gray-200'
                        >
                          Task Item {index + 1}
                        </label>
                        <div className='min-w-[700px] flex rounded-md flex flex-col'>
                          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:border-gray-200 sm:py-5'>
                            <label
                              htmlFor='cover-photo'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Text
                            </label>
                            <input
                              type='text'
                              name={`taskItems.[${index}].text`}
                              id={`taskItems.[${index}].text`}
                              autoComplete={`taskItems`}
                              className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300 border-gray-300`}
                              value={values?.taskItems?.[index].text || ''}
                              onChange={handleChange}
                              placeholder={'Enter Task Item'}
                            />
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                            <label
                              htmlFor='cover-photo'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Minutes
                            </label>{' '}
                            <input
                              type='number'
                              name={`taskItems.[${index}].minutes`}
                              id={`taskItems.[${index}].minutes`}
                              autoComplete={`taskItems`}
                              className={`disabled flex-1 block w-[75px] focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300 border-gray-300`}
                              value={values?.taskItems?.[index].minutes}
                              onChange={handleChange}
                              placeholder={'Enter minutes'}
                            />
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                            <label
                              htmlFor='cover-photo'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Type of task
                            </label>
                            <Select
                              options={TaskTypeSelector}
                              value={TaskTypeSelector.find((e) => taskItem?.taskType === e.value)}
                              onChange={(option) => {
                                setFieldValue(`taskItems.${index}.taskType`, option?.value);
                              }}
                              className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                            />
                          </div>
                        </div>
                        <button
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          onClick={(e) => {
                            e.preventDefault();
                            const temp = values.taskItems ? [...values.taskItems] : [];
                            const index = temp.findIndex((i) => i.id === taskItem.id);
                            if (index > -1) {
                              temp.splice(index, 1);
                              setValues({
                                ...values,
                                taskItems: temp,
                              });
                            }
                          }}
                        >
                          Delete
                        </button>
                        {errors && errors.taskItems && (
                          <div className='text-red-600'>{errors?.taskItems?.toString()}</div>
                        )}
                      </div>
                    ))}
                    <button
                      className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={(e) => {
                        e.preventDefault();
                        const temp = values.taskItems ? [...values.taskItems] : [];
                        temp.push({
                          id: new Date().getTime().toString(),
                          text: '',
                          minutes: 1,
                        });
                        setValues({
                          ...values,
                          taskItems: temp,
                        });
                      }}
                    >
                      + Add Option
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/tasks')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {task ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default TaskForm;
