// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import CompanyList from '../../components/company/CompanyList';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../enums/pages';
import { useAppContext } from '../../contexts/appContext';
import { useEffect } from 'react';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const CompanyPage = () => {
  const navigate = useNavigate();
  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([{ label: 'Companies', path: '/companies' }]);
  }, [setBreadcrumbPaths]);
  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Companies</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            onClick={() => navigate(`/${Pages.Companies}/new`)}
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Add Company
          </button>
        </div>
      </div>
      <CompanyList />
    </>
  );
};

export default CompanyPage;
