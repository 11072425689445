import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { getAllRewards, deleteReward } from 'src/collections/rewards';
import { confirmAlert } from 'react-confirm-alert';
import { dieIfNullOrUndef } from 'src/utility/GeneralUtilities';
import { RewardType } from 'src/modeltypes/rewards';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';
import { getAllUserRewards } from 'src/collections/userRewards';
import { Achievement, UserRewardsType } from 'src/modeltypes/userRewards';
// import { TagId } from '../modeltypes/id';
// import { getAccountsByIdArray } from '../../collections/account';

const RewardList = () => {
  // type TableData = {
  //   title?: string;
  //   id?: string;
  //   description?: string;
  //   locked?: boolean;
  //   tags?: TagId[];
  // };
  const [tableData, setTableData] = useState<RewardType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();
  const [tags, setTags] = useState<TagType[] | null>(null);
  const [userRewards, setRewards] = useState<UserRewardsType[] | null>(null);

  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  const getRwards = async () => {
    const dbRewards = await getAllUserRewards();
    setRewards(dbRewards);
  };

  useEffect(() => {
    getTags();
    getRwards();
  }, []);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId;
    });
  }, [searchTerm, tableData]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const levels: { [string]: string[] } = {};

  const rewardsBreakdownUserCount = !userRewards
    ? null
    : userRewards.reduce((useRwardsMap: any, userR: any) => {
        if (userR?.awards) {
          // Okay, our first step is to see who has what userRewards on any level
          const awardKeys = Object.keys(userR?.awards);
          for (const awardKey in awardKeys) {
            const award = awardKeys[awardKey];
            (useRwardsMap[`${award}`] = useRwardsMap?.[`${award}`] || []).push(userR.id);

            const thisAchievements = userR?.awards?.[award]?.achievements;
            // THis gives us an array of unique levels...
            // Bcause there are multiple level of the same levels in an array, we need to know what are the unique ones.
            // It returns [1,2,3,4]
            const uniqueAchievementLevels = [...new Set(thisAchievements.map((item: Achievement) => item.level))];
            // Now we get the first ones we find
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const uniqueAchievements = uniqueAchievementLevels.map((item: number) =>
              thisAchievements.find((achvItem: Achievement) => item === achvItem?.level),
            );

            for (const thisLevel in uniqueAchievements) {
              const thisAward = uniqueAchievements[thisLevel];
              const level = thisAward?.level || 0;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (levels[`${award}-Level_${level + 1}`] = levels?.[`${award}-Level_${level + 1}`] || []).push(userR.id);
            }
          }
        } else {
          (useRwardsMap['none'] = useRwardsMap?.['none'] || []).push(userR.id);
        }
        return useRwardsMap;
      }, {});

  // console.log('asdf rewardsBreakdownUserCount : ', rewardsBreakdownUserCount);
  // console.log('asdf levels : ', levels);

  const levelKeys: string[] = Object.keys(levels);

  // console.log('levels: ', levels);

  useEffect(() => {
    const prepareTableData = async () => {
      const rewards = await getAllRewards();
      console.log('rewards: ', rewards);
      setTableData(rewards);
    };
    prepareTableData().then(() => setIsLoading(false));
  }, []);

  const navigate = useNavigate();

  const onClickDelete = (rewardId: string) => {
    confirmAlert({
      title: `Confirm delete path (ID: ${rewardId})?`,
      message: 'Are you sure you want to delete this path type? This operation cannot be undone.',
      buttons: [
        {
          label: 'Delete',
          onClick: async () => {
            await deleteReward(rewardId);
            window.location.reload();
          },
        },
        {
          label: 'Cancel',
          onClick: () => alert('Never mind then.'),
        },
      ],
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Description
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Levels
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Level Achiements
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Total Users Achieved
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Locked
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Tags
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Users
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((reward) => {
                      const theseLevels = levelKeys.filter((level) => level.includes(reward.id));
                      console.log('asdfasdf theseLevels: ', theseLevels);

                      return (
                        <tr key={reward.id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{reward.id}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{reward?.title}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{reward?.description || '-'}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {reward?.badges?.length ? (
                                  <div>
                                    {reward.badges.map((badge, index) => (
                                      <div key={badge.id} className='text-gray-500 p-1 m-1 border rounded'>
                                        Level {index + 1}: {reward.badges[index - 1]?.maxValue + 1 || 0} -{' '}
                                        {badge.maxValue}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className='text-gray-500'></div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {theseLevels?.length ? (
                                  <div>
                                    {theseLevels?.map((level: string, idx) => {
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore

                                      const thisLevel = levels?.[`${level}`]?.length || '-';
                                      return (
                                        <div key={level || idx} className='text-gray-500 p-1 m-1 border rounded'>
                                          {level} - {thisLevel}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className='text-gray-500'></div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>
                                  {rewardsBreakdownUserCount?.[reward?.id]?.length || '-'}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{reward.locked ? 'Locked' : 'Not Locked'}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {reward?.tags?.length ? (
                                  <div>
                                    {reward.tags?.map((tag, idx) => (
                                      <div key={tag || idx} className='text-gray-500 p-1 m-1 border rounded'>
                                        {tags?.find((t) => t.id === tag)?.name}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className='text-gray-500'></div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <div onClick={() => navigate('/rewards/' + reward.id)} style={{ cursor: 'pointer' }}>
                              Edit
                            </div>
                          </td>
                          {reward?.locked ? null : (
                            <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                              <div
                                className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                onClick={() => onClickDelete(dieIfNullOrUndef(reward.id))}
                              >
                                Delete
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardList;
