import { where } from '../models/dalaccess';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { COHORT_TABLE_NAME } from './tableName';
import { CoachId, CohortId, UserId } from '../modeltypes/id';
import { CohortType, CohortTypeBase, CohortTypeBuilder, CohortTypeNew } from '../modeltypes/cohort';
import { idToString } from '../utility/GeneralUtilities';

// noinspection JSUnusedGlobalSymbols
export const getNewCohortId = getNewIdFor<CohortId>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const _addNewCohort = addNewFor<CohortId, CohortTypeNew>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewCohort = createNewFor<CohortId, CohortTypeBuilder>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCohortRefById = getRefByIdFor<CohortId>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCohortById = getByIdFor<CohortId, CohortType>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCohortsByIdArray = getByIdArrayFor<CohortId, CohortType>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCohorts = getAllFor<CohortType>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCohortsQuery = getAllQueryFor(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCohortsByQuery = getByQueryFor<CohortType>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateCohort = updateDocumentFor<CohortId, CohortTypeBase>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteCohort = deleteDocumentFor<CohortId>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchCohortIdSet = watchIdSetFor<CohortId>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortType = getValidateTypeFor<CohortType>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortTypeBase = getValidateTypeBaseFor<CohortTypeBase>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortTypeBuilder = getValidateTypeBuilderFor<CohortTypeBuilder>(COHORT_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCohortTypeNew = getValidateTypeNewFor<CohortTypeNew>(COHORT_TABLE_NAME);

export const getCohortsForCoach = async (coachId?: CoachId): Promise<CohortType[]> => {
  if (coachId) {
    const rv = await getCohortsByQuery(where('coach', 'array-contains', idToString(coachId)));
    return rv;
  }
  const rv = await getAllCohorts();
  return rv;
};

// Wrapping the standard method becuase of the nonstandard creationDate thing.
export const addNewCohort = async (data: CohortTypeNew): Promise<string> => {
  return await _addNewCohort({
    ...data,
    // We should standardize on createdAt/updatedAt.
    // creationDate: Timestamp.now(),
  });
};

// noinspection JSUnusedGlobalSymbols
export const checkIfIncentivesAlreadyAssigned = async (incentivesId: UserId) => {
  const docs = await getCohortsByQuery(where('incentives', '==', idToString(incentivesId)));
  return docs.length > 0;
};

// I'm leaving this, but this very likely doesn't work. Firestore does not allow you to query for the absence of
// a value. Null may be special, but I'm not sure.
export const getCohortsWithIncentives = async () => {
  return await getCohortsByQuery(where('incentives', '!=', null));
};
