// *******************************************************
// FunctionsPage
// -------------------------------------------------------
// This is a FunctionsPage
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useAppContext } from '../../contexts/appContext';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FunctionRunsList from '../../components/FunctionRunsList';
import { FunctionRunType } from '../../modeltypes/functionRun';
import { addNewFunctionRun, getAllFunctionRuns, getFunctionRunsByQuery } from '../../collections/functionRuns';
import { toast } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react';
import { Timestamp, where } from 'firebase/firestore';
import cloneDeep from 'clone-deep';
import { addMinutes } from 'date-fns';
import ChangeTrackForUserModal from '../../components/modals/ChangeTrackForUserModal';
import { runOneSync } from '../../firebase/functions';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const activeStatuses = ['CREATED', 'IN PROGRESS'];

const FunctionRunsPage = () => {
  const navigate = useNavigate();
  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Functions runs',
        path: '/function-runs',
      },
    ]);
  }, [setBreadcrumbPaths]);

  const [functionRuns, setFunctionRuns] = useState<FunctionRunType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [changeTrackModalOpen, setChangeTrackModalOpen] = useState(false);

  const checkNotFinished = async () => {
    const range = addMinutes(new Date(), -5);
    const updates = await getFunctionRunsByQuery(where('createdAt', '>', range));
    if (updates.length) {
      let clone = cloneDeep(functionRuns);
      for (const update of updates) {
        clone = clone.filter((el) => el.id !== update.id);
        clone.push(update);
      }
      setFunctionRuns(clone);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllFunctionRuns().then((_functionRuns) => {
      setFunctionRuns(_functionRuns);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!!functionRuns && functionRuns.some((el) => activeStatuses.includes(el.status))) {
      interval = setInterval(() => {
        checkNotFinished();
      }, 1000);
    }

    return () => {
      interval ? clearInterval(interval) : null;
    };
  }, [functionRuns]);

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Function Runs</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <Menu as='div' className='ml-3 relative'>
            <div>
              <Menu.Button className='max-w-xs bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                <div className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'>
                  Run function
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                  {({ active }) => (
                    <p
                      onClick={() => {
                        if (functionRuns.some((run) => run.functionName === 'one-sync' && !run.finishedAt)) {
                          toast.warning('Previous run is not finished.');
                        } else {
                          const confirmed = confirm('Do you want to start One Sync function?');
                          if (confirmed) {
                            addNewFunctionRun({
                              functionName: 'one-sync',
                              createdAt: Timestamp.now(),
                              startedAt: Timestamp.now(),
                              updatedAt: Timestamp.now(),
                              status: 'CREATED',
                            }).then((id) => {
                              checkNotFinished();
                              runOneSync({ runId: id });
                            });
                          }
                        }
                        navigate('/function-runs');
                      }}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer`}
                    >
                      One-Sync
                    </p>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <p
                      onClick={() => {
                        if (functionRuns.some((run) => run.functionName === 'one-sync' && !run.finishedAt)) {
                          toast.warning('Previous run is not finished.');
                        } else {
                          setChangeTrackModalOpen(true);
                        }
                      }}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer`}
                    >
                      Change track for user
                    </p>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <FunctionRunsList functionRuns={functionRuns} isLoading={isLoading} />
      {changeTrackModalOpen && (
        <ChangeTrackForUserModal hide={() => setChangeTrackModalOpen(false)} start={() => checkNotFinished()} />
      )}
    </>
  );
};

export default FunctionRunsPage;
