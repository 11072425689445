import { TaskType } from '../../../modeltypes/tasks';

const REQUIRED_KEYS_TASK: Array<keyof TaskType> = ['title', 'taskItems', 'description'];

export const validateTask = (task: TaskType | null) => {
  const errors = [];

  if (!task) {
    errors.push(`CRITICAL TASK - missing track`);
    return errors;
  }

  for (const key of REQUIRED_KEYS_TASK) {
    if (!task[key]) {
      errors.push(`TASK - Missing ${key}`);
    }
  }

  if (task.taskItems.length === 0) {
    errors.push(`TASK - No task items assigned!`);
  } else {
    for (const taskItem of task.taskItems) {
      if (!taskItem.taskType) {
        errors.push(`TASK - taskId ${taskItem.id} has no taskType assigned`);
      }
      if (!taskItem.minutes) {
        errors.push(`TASK - taskId ${taskItem.id} has no minutes assigned`);
      }
      if (!taskItem.text) {
        errors.push(`TASK - taskId ${taskItem.id} has no text assigned`);
      }
    }
  }

  return errors;
};
