// *******************************************************
// FlowModal
// -------------------------------------------------------
// This is a FlowModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { ModalType } from './sharedValues';
import { NodeType } from '../../modeltypes/node';
import AudioUpdateModal from './flowModals/AudioUpdateModal';
import TextUpdateModal from './flowModals/TextUpdateModal';
import QuestionUpdateModal from './flowModals/QuestionUpdateModal';
import AnswersUpdateModal from './flowModals/AnswersUpdateModal';
import { CustomNodeProps } from './ChallengeNodeFlow';
import { Node } from 'react-flow-renderer';
import AddNodeAfterModal from './flowModals/AddNodeAfterModal';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

export type ModalContentType = {
  type: ModalType;
  node: NodeType;
  challengeId: string;
};

interface FlowModalProps {
  hide: (shouldRefresh?: boolean) => void;
  content: ModalContentType | null;
  updateNodes: (nodeIds: string[]) => void;
  addNodeAfter: (node: Node<CustomNodeProps>, newNode: NodeType) => void;
  eventCallerNode: Node<CustomNodeProps>;
}

const FlowModal = ({ hide, content, updateNodes, eventCallerNode, addNodeAfter }: FlowModalProps) => {
  console.log(eventCallerNode.id);
  const renderTextModal = (text: string, title: string) => (
    <>
      <label htmlFor='cover-photo' className='block text-lg font-medium dark:text-white mb-4'>
        {title}
      </label>
      <p className='dark:text-white text-black'>{text}</p>
      <div className='flex justify-end text-right p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600'>
        <button
          onClick={() => hide()}
          type='button'
          className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        >
          Close
        </button>
      </div>
    </>
  );

  return (
    <div
      id='defaultModal'
      tabIndex={1}
      aria-hidden='true'
      className='flex pt-[10%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative w-full max-w-2xl h-full md:h-auto'>
        <button className='absolute right-5 top-5 z-10 dark:text-white text-[1.2rem] font-bold' onClick={() => hide()}>
          X
        </button>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='p-6 space-y-6'>
            {content?.type === ModalType.UPDATE_AUDIO && (
              <AudioUpdateModal hide={hide} node={content.node} updateNodes={updateNodes} />
            )}
            {content?.type === ModalType.UPDATE_TEXT && (
              <TextUpdateModal hide={hide} node={content.node} updateNodes={updateNodes} />
            )}
            {content?.type === ModalType.SHOW_FULL_TEXT &&
              renderTextModal(content?.node?.texts?.map((el) => el.text).join('\n\n') || '', 'Text')}
            {content?.type === ModalType.SHOW_FULL_QUESTION &&
              renderTextModal(content?.node?.question || '', 'Question')}
            {content?.type === ModalType.UPDATE_QUESTION && (
              <QuestionUpdateModal hide={hide} node={content.node} updateNodes={updateNodes} />
            )}
            {content?.type === ModalType.UPDATE_ANSWERS && (
              <AnswersUpdateModal hide={hide} node={content.node} updateNodes={updateNodes} />
            )}
            {content?.type === ModalType.ADD_NODE_AFTER && (
              <AddNodeAfterModal
                hide={hide}
                challengeId={content.challengeId}
                addNodeAfter={(newNode) => addNodeAfter(eventCallerNode, newNode)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowModal;
