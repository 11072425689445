// *******************************************************
// InsightPanel
// -------------------------------------------------------
// This is a InsightPanel
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { ModalType, useInsightsContext } from '../../contexts/insightsContext';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface InsightPanelProps {
  name: string;
  image: string;
  bestDay?: { value: string | number; date?: string };
  worstDay?: { value: string | number; date?: string };
  cohortAverage?: number | string;
  industryAverage?: number | string;
  moreButtonShown?: boolean;
  getInsightTips?: () => void;
  getInsightDetails?: () => void;
  disabledMargin?: boolean;
}

const InsightPanel = ({
  name,
  image,
  worstDay,
  bestDay,
  cohortAverage,
  industryAverage,
  moreButtonShown = true,
  disabledMargin = false,
}: InsightPanelProps) => {
  const { showModal } = useInsightsContext();

  return (
    <div
      className={`shadow-lg bg-white border rounded-xl w-[100%] md:w-[300px] md:grow-0 md:shrink-0 px-4 md:px-6 py-2 flex flex-col ${
        disabledMargin ? '' : 'mb-3 md:mr-8 md:mb-5'
      }`}
    >
      <p className='self-center text-2xl font-bold text-black'>{name}</p>
      <img src={image} height={80} width={80} className='self-center my-4' alt={`${name} icon`} />
      {bestDay ? (
        <div className='flex flex-col md:flex-row mb-2 md:justify-between md:items-center'>
          <div className='flex flex-col mb-2'>
            <p className='text-lg text-gray-600'>Best Day</p>
            <p className='text-sm text-sky-600'>{`${bestDay.date} (${bestDay.value})`}</p>
          </div>
          <button
            className='w-auto border rounded-xl px-4 py-1 text-sm text-gray-600 hover:bg-gray-50 shadow'
            onClick={() =>
              showModal({
                type: ModalType.TIPS,
                insightName: name,
              })
            }
          >
            Get insights
          </button>
        </div>
      ) : null}
      {worstDay ? (
        <div className='flex flex-col md:flex-row mb-2 md:justify-between md:items-center'>
          <div className='flex flex-col  mb-2'>
            <p className='text-lg text-gray-600'>Worst Day</p>
            <p className='text-sm text-red-800'>{`${worstDay.date} (${worstDay.value})`}</p>
          </div>
          <button
            className='border rounded-xl px-4 py-1 text-sm text-gray-600 hover:bg-gray-50 shadow'
            onClick={() =>
              showModal({
                type: ModalType.TIPS,
                insightName: name,
              })
            }
          >
            Get insights
          </button>
        </div>
      ) : null}
      <div className='border-b border-gray-200 w-[80%] self-center my-2 ' />
      <div className='flex justify-evenly mb-2'>
        <div className='flex flex-col items-center'>
          <p className='text-center leading-5 my-2 text-sm text-gray-600'>
            Cohort
            <br />
            Average
          </p>
          <p className='text-gray-600'>{cohortAverage || '-'}</p>
        </div>
        <div className='flex flex-col items-center '>
          <p className='text-center leading-5 my-2 text-sm text-gray-600'>
            Industry
            <br />
            Average
          </p>
          <p className='text-gray-600'>{industryAverage || '-'}</p>
        </div>
      </div>
      {moreButtonShown ? (
        <>
          <div className='border-b border-gray-200 w-[80%] self-center mt-1 mb-3 ' />
          <button
            className='border rounded-xl pl-5 pr-1 py-1 flex text-[14px] items-center justify-between text-gray-600 hover:bg-gray-50 shadow'
            onClick={() =>
              showModal({
                type: ModalType.DETAILS,
                insightName: name,
              })
            }
          >
            Get more insights <ChevronRightIcon height={30} width={30} />
          </button>
        </>
      ) : null}
    </div>
  );
};

export default InsightPanel;
