import { arrayRemove, arrayUnion, Timestamp, updateDoc } from '../models/dalaccess';
import {
  addNewFor,
  deleteDocumentFor,
  getAllFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  queryByIdsFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { dieIfNullOrUndef, excWrap } from '../utility/GeneralUtilities';
import { CHAPTER_TABLE_NAME } from './tableName';
import { AudioNodeId, ChapterId } from '../modeltypes/id';
import { ChapterType, ChapterTypeBase, ChapterTypeBuilder, ChapterTypeNew } from '../modeltypes/chapter';

// noinspection JSUnusedGlobalSymbols
export const getNewChapterId = getNewIdFor<ChapterId>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewChapter = addNewFor<ChapterId, ChapterTypeNew>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewChapter = addNewFor<ChapterId, ChapterTypeBuilder>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewChapter = setNewFor<ChapterId, ChapterTypeNew>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChapterRefById = getRefByIdFor<ChapterId>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChapterById = getByIdFor<ChapterId, ChapterType>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChaptersByIdArray = getByIdArrayFor<ChapterId, ChapterType>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChapterQueryForIds = queryByIdsFor<ChapterId>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllChapters = getAllFor<ChapterType>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getChaptersByQuery = getByQueryFor<ChapterType>(CHAPTER_TABLE_NAME);

export const updateChapter = updateDocumentFor<ChapterId, ChapterTypeBase>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteChapter = deleteDocumentFor<ChapterId>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchChapterIdSet = watchIdSetFor<ChapterId>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChapterType = getValidateTypeFor<ChapterType>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChapterTypeBase = getValidateTypeBaseFor<ChapterTypeBase>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChapterTypeBuilder = getValidateTypeBuilderFor<ChapterTypeBuilder>(CHAPTER_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateChapterTypeNew = getValidateTypeNewFor<ChapterTypeNew>(CHAPTER_TABLE_NAME);

export const appendAudioNodeToChapter = excWrap((chapterId: ChapterId, audioNodeId: AudioNodeId) => {
  if (audioNodeId === 'new') {
    throw new Error("You probably didn't mean to create a 'new' audioNode.");
  }
  const docRef = dieIfNullOrUndef(getChapterRefById(chapterId));
  const rv = updateDoc(docRef, {
    audioNodes: arrayUnion(audioNodeId),
    updatedAt: Timestamp.now(),
  });
  return rv;
});

// noinspection JSUnusedGlobalSymbols
export const removeAudioNodeFromChapter = excWrap((chapterId: ChapterId, audioNodeId: AudioNodeId) => {
  const docRef = dieIfNullOrUndef(getChapterRefById(chapterId));
  return updateDoc(docRef, {
    audioNodes: arrayRemove(audioNodeId),
    updatedAt: Timestamp.now(),
  });
});
