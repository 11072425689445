import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { deleteLearningGame, getAllLearningGames } from 'src/collections/learningGames';
import { confirmAlert } from 'react-confirm-alert';
import { dieIfNullOrUndef } from 'src/utility/GeneralUtilities';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';
import { LearningGameTypeOptionsTs } from 'src/pages/paths/LearningGameForm';
import { QuizId, TagId } from 'src/modeltypes/id';
import { QuizType } from 'src/modeltypes/quizItems';
import { getQuizItemsByIdArray } from 'src/collections/quizItems';
// import { getAccountsByIdArray } from '../../collections/account';

const LearningGameList = () => {
  type TableData = {
    title?: string;
    pathType?: string;
    quizType: LearningGameTypeOptionsTs | null;
    quizItems?: QuizId[];
    id?: string;
    description?: string;
    tags?: TagId[];
    locked?: boolean;
  };
  const { searchTerm } = useAppContext();

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<TagType[] | null>(null);
  const [quizItems, setQuizItems] = useState<QuizType[] | null>(null);

  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  const getQuizItemsbyId = async (ids: string[]) => {
    const dbQuizes = await getQuizItemsByIdArray(ids);
    setQuizItems(dbQuizes);
  };

  useEffect(() => {
    getTags();
  }, []);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId;
    });
  }, [searchTerm, tableData]);
  useEffect(() => {
    const prepareTableData = async () => {
      const learningGames = await getAllLearningGames();
      console.log('learningGames: ', learningGames);
      setTableData(learningGames);

      const quizItems = learningGames?.length ? learningGames.map((lg) => lg.quizItems).flat() : [];
      getQuizItemsbyId(quizItems);
    };
    prepareTableData().then(() => setIsLoading(false));
  }, []);

  const navigate = useNavigate();

  const onClickDelete = (pathTypeId: string) => {
    confirmAlert({
      title: `Confirm delete path (ID: ${pathTypeId})?`,
      message: 'Are you sure you want to delete this path type? This operation cannot be undone.',
      buttons: [
        {
          label: 'Delete',
          onClick: async () => {
            await deleteLearningGame(pathTypeId);
            window.location.reload();
          },
        },
        {
          label: 'Cancel',
          onClick: () => alert('Never mind then.'),
        },
      ],
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Tags
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Locked
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Type
                      </th>
                      <th
                        scope='col'
                        colSpan={2}
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        # of Qs
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((learningGame) => (
                      <tr key={learningGame.id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{learningGame.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{learningGame.title}</div>
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {learningGame?.tags?.length ? (
                                <div>
                                  {learningGame.tags?.map((tag) => (
                                    <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                      {tags?.find((t) => t.id === tag)?.name}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className='text-gray-500'></div>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{learningGame.locked ? 'Locked' : 'Not Locked'}</div>
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{learningGame.quizType || '-'}</div>
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{learningGame?.quizItems?.length || '-'}</div>
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='ml-4 flex items-center flex-col '>
                            {learningGame?.quizItems?.length ? (
                              <>
                                {learningGame?.quizItems?.map((quizId) => {
                                  return (
                                    <button
                                      key={quizId}
                                      className='text-gray-500 p-1 m-1 border rounded hover:bg-gray-100'
                                      onClick={() => navigate('/quizItems/' + quizId)}
                                    >
                                      {quizItems?.find((q) => q.id === quizId)?.question}
                                    </button>
                                  );
                                })}
                              </>
                            ) : (
                              <div className='text-gray-500'>-</div>
                            )}
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <div
                            onClick={() => navigate('/learningGames/' + learningGame.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            Edit
                          </div>
                        </td>
                        {learningGame?.locked ? null : (
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                            <div
                              className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                              onClick={() => onClickDelete(dieIfNullOrUndef(learningGame.id))}
                            >
                              Delete
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningGameList;
