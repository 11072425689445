// *******************************************************
// UnsubscribePage
// -------------------------------------------------------
// This is a UnsubscribePage
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getAccountById, updateAccount } from '../../collections/account';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../../components/Spinner';
import { useState } from 'react';
import { Timestamp } from '@firebase/firestore';
import { toast } from 'react-toastify';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const UnsubscribePage = () => {
  const params = useParams<{ token: string }>();

  const { data, isLoading } = useQuery({
    queryKey: ['unsubscribeAccount'],
    queryFn: () => getAccountById(params.token),
  });
  const [showConfirm, setShowConfirm] = useState(false);

  const [reason, setReason] = useState('');

  const [justUnsubscribed, setJustUnsubscribed] = useState(false);

  if (isLoading && !data) {
    return (
      <div className='flex h-[100vh] justify-center items-center' role='status'>
        <Spinner />
      </div>
    );
  }

  const unsubscribe = (accountId: string) => {
    toast
      .promise(
        updateAccount(accountId, {
          unsubscribedMails: {
            reason,
            date: Timestamp.now(),
          },
        }),
        {
          error: 'Something went wrong. Try again',
          pending: 'Updating ...',
        },
      )
      .then(() => {
        setJustUnsubscribed(true);
        setShowConfirm(false);
      });
  };

  const renderContent = () => {
    if (justUnsubscribed) {
      return <p>You just unsubscribed. If you need any help, please contact us.</p>;
    }
    if (!data) {
      return <p>{"Can't find your account"}</p>;
    }
    if (data?.unsubscribedMails) {
      return <p>You already did it</p>;
    }
    return (
      <button
        className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stone-400'
        onClick={() => setShowConfirm(true)}
      >
        Unsubscribe
      </button>
    );
  };

  return (
    <>
      <div className={`min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8`}>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img className='mx-auto h-16 w-auto' src={require('../../logo-small.png')} alt='Equa' />
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>Do you want to unsubscribe?</h2>
        </div>
        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>{renderContent()}</div>
        </div>
      </div>
      {showConfirm && (
        <div
          id='defaultModal'
          tabIndex={-1}
          aria-hidden='true'
          className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
          onClick={() => setShowConfirm(false)}
        >
          <div className='relative p-4 w-full max-w-3xl h-full md:h-auto'>
            <div className='relative bg-white rounded-lg shadow dark:bg-gray-700' onClick={(e) => e.stopPropagation()}>
              <div className='flex justify-between items-start p-4 pl-6 rounded-t border-b dark:border-gray-600'>
                <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Do you want to tell us why?</h3>
                <button
                  type='button'
                  className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                  onClick={() => setShowConfirm(false)}
                >
                  <svg
                    aria-hidden='true'
                    className='w-5 h-5'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    />
                  </svg>
                  <span className='sr-only'>Close modal</span>
                </button>
              </div>
              <div className='p-6'>
                <div className=' w-full py-2 align-middle md:px-6 lg:px-8'>
                  <textarea
                    rows={3}
                    className='shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                    onChange={(e) => setReason(e.target.value)}
                    placeholder={'Because ...'}
                    value={reason}
                  />
                </div>
              </div>
              <div className='px-6 pb-6'>
                <div className='flex justify-end'>
                  <button
                    type='button'
                    className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => setShowConfirm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type='button'
                    className={`ml-3 min-w-[90px] inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    onClick={() => unsubscribe(data!.id)}
                  >
                    {reason.length ? 'Confirm' : 'Skip'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnsubscribePage;
