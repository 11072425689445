/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTagById, updateTag, setNewTag } from '../../collections/tags';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { TagType } from '../../modeltypes/tags';
import { toast } from 'react-toastify';

const TagForm = () => {
  const [tag, setThisTag] = useState<TagType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Tags',
        path: '/tags',
      },
      {
        label: id === 'new' ? 'New Tag' : 'Edit Tag',
        path: `/tags/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    //resetForm
  } = useFormik<{
    name: string;
    text: string;
    description: string;
    locked: boolean;
  }>({
    initialValues: {
      name: '',
      text: '',
      description: '',
      locked: true,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      description: yup.string(),
    }),
    validate: (values) => {
      const errors: any = {};
      const re = new RegExp('^[A-Za-z0-9]*$');

      if (!values.name) {
        errors.name = 'The tag must be present';
      }
      if (!values.text) {
        errors.text = 'The tag must be present';
      }

      if (!values.name.match(re)) {
        errors.name = 'The tag must be a single word';
      }
      return errors;
    },
    onSubmit: async (newTag) => {
      console.log('newTag: ', newTag);
      if (id !== undefined && id !== 'new') {
        toast
          .promise(updateTag(id, newTag), {
            pending: `Updating ${newTag.name} Tag...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newTag.name} Tag!`,
          })
          .then(async () => {
            navigate('/tags');
          });
      } else if (id === 'new') {
        const newId = newTag?.name?.toUpperCase() || 'NoName';
        toast
          .promise(setNewTag(newId, newTag), {
            pending: `Adding ${newTag.name} Tag...`,
            error: "Can't do it now, try again.",
            success: `Added ${newTag.name} Tag!`,
          })
          .then(async () => {
            navigate('/tags');
          });
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currTag = await getTagById(id || '');
        if (currTag === null) {
          navigate('/Tags');
          return;
        }

        await setValues({
          name: currTag.name || '',
          text: currTag.text || '',
          description: currTag.description || '',
          locked: currTag.locked || false,
        });

        setThisTag(currTag);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const disabledForm = false;

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id {id}
                </label>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id saved {tag?.id}
                </label>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Name
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='name'
                        id='name'
                        autoComplete='name'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.name ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.name}
                        onChange={handleChange}
                        placeholder={'Tag Name'}
                      />
                    </div>
                  </div>
                  {errors && errors.name && <div className='text-red-600'>{errors?.name?.toString()}</div>}
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Text
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='text'
                        id='text'
                        autoComplete='name'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.text ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.text}
                        onChange={handleChange}
                        placeholder={'Tag Text (what people will see)'}
                      />
                    </div>
                  </div>
                  {errors && errors.name && <div className='text-red-600'>{errors?.name?.toString()}</div>}
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'Enter Tag Description.  The user will see this.'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/tags')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {tag ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default TagForm;

// import React, {  } from 'react';
// import Skeleton from 'react-loading-skeleton';
// const TagForm = () => {

//   return (
//         <>
//           <Skeleton height={50} />
//           <Skeleton count={15} className='mt-3' />{' '}
//         </>
//   );
// };

// export default TagForm;
