// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { UnitType } from '../../modeltypes/unit';
import { getAllUnits } from '../../collections/units';
import { jsonEquality } from '../../utility/GeneralUtilities';
import { Pages } from '../../enums/pages';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const UnitList = () => {
  const navigate = useNavigate();
  const [units, setUnits] = useState<UnitType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<TagType[] | null>(null);

  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    console.debug('UnitList.useEffect called.');
    const fetchData = async () => {
      const fUnits = await getAllUnits();
      if (!jsonEquality(units, fUnits)) {
        setUnits(fUnits);
      }
    };
    fetchData()
      .then(() => setIsLoading(false))
      .catch(console.error);
  }, [units, setUnits]);

  const getPage = async (pageType: string) => {
    switch (pageType) {
      case 'audioLesson':
        return Pages.Lessons;
      case 'challenge':
        return Pages.Challenges;
      case 'task':
        return Pages.Tasks;
      case 'taskSwitch':
        return Pages.TaskSwitches;
      case 'challengeSwitch':
        return Pages.ChallengeSwitches;
      case 'audioChunk':
        return Pages.LessonAudioChunks;
      default:
        return 'unknown';
    }
  };

  const navigateToUnit = async (id: string, thisType: string) => {
    const page = await getPage(thisType);
    navigate('/' + page + '/' + id);
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        {/* <SearchBar /> */}
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Icon
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Name
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Description
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                        #&nbsp;Number of Elements
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                        #&nbsp;Elements
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                        Tags
                      </th>

                      <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                        Status
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                        Locked
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Details</span>
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {units?.map((unit) => (
                      <tr key={unit.id} className='hover:bg-stone-200'>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='h-10 w-10 flex-shrink-0'>
                            <img className='h-10 w-10 rounded-full' src={unit.icon || undefined} alt='' />
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>
                            {unit.title} ({unit.id})
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            <span
                              style={{
                                display: 'inline-block',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '250pt',
                              }}
                            >
                              {unit.description}
                            </span>
                          </div>
                        </td>
                        <td className='whitespace-nowrap  text-center px-3 py-4 text-sm text-gray-500'>
                          {unit?.elements?.length || 0}
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='ml-4 flex items-center flex-col '>
                            {unit?.elements?.length ? (
                              <>
                                {unit?.elements?.map(({ id, pathType }) => {
                                  return (
                                    <button
                                      key={id}
                                      className='text-gray-500 p-1 m-1 border rounded hover:bg-gray-100'
                                      onClick={() => navigateToUnit(id, pathType)}
                                    >
                                      {pathType}
                                    </button>
                                  );
                                })}
                              </>
                            ) : (
                              <div className='text-gray-500'>-</div>
                            )}
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {unit?.tags?.length ? (
                                <div>
                                  {unit.tags?.map((tag) => (
                                    <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                      {tags?.find((t) => t.id === tag)?.name}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className='text-gray-500'></div>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{unit.productionStatus || 'WIP'}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {unit.locked ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}
                            </div>
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                          <div
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => navigate(`/${Pages.Unit}/${unit.id}`)}
                          >
                            Edit
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitList;
