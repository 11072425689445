// *******************************************************
// FlowModal
// -------------------------------------------------------
// This is a FlowModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { ModalType } from '../paths/sharedValues';
import { AudioNodeType } from '../../modeltypes/audioNode';
import { LessonCustomNodeProps } from './LessonNodeFlow';
import { Node } from 'react-flow-renderer';
import AudioUpdateModal from './lessonFlowModals/AudioUpdateModal';
import TextUpdateModal from './lessonFlowModals/TextUpdateModal';
import LessonAddNodeAfterModal from './lessonFlowModals/LessonAddNodeAfterModal';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

export type LessonModalContentType = {
  type: ModalType;
  node?: AudioNodeType;
  chapterId?: string;
};

interface FlowModalProps {
  hide: (shouldRefresh?: boolean) => void;
  content: LessonModalContentType | null;
  updateNodes: (nodeIds: string[]) => void;
  addNodeAfter: (node: Node<LessonCustomNodeProps>, newNode: AudioNodeType) => void;
  eventCallerNode: Node<LessonCustomNodeProps>;
}

const LessonFlowModal = ({ hide, content, updateNodes, eventCallerNode, addNodeAfter }: FlowModalProps) => {
  console.log(eventCallerNode.id);
  const renderTextModal = (text: string, title: string) => (
    <>
      <label htmlFor='cover-photo' className='block text-lg font-medium text-white mb-4'>
        {title}
      </label>
      <p className='text-white'>{text}</p>
      <div className='flex justify-end text-right p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600'>
        <button
          onClick={() => hide()}
          type='button'
          className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        >
          Close
        </button>
      </div>
    </>
  );

  return (
    <div
      id='defaultModal'
      tabIndex={1}
      aria-hidden='true'
      className='flex pt-[10%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative w-full max-w-2xl h-full md:h-auto'>
        <button className='absolute right-5 top-5 z-10 text-white text-[1.2rem]' onClick={() => hide()}>
          X
        </button>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='p-6 space-y-6'>
            {content?.type === ModalType.UPDATE_AUDIO && content.node && (
              <AudioUpdateModal hide={hide} node={content.node} updateNodes={updateNodes} />
            )}
            {content?.type === ModalType.UPDATE_TEXT && content.node && (
              <TextUpdateModal hide={hide} node={content.node} updateNodes={updateNodes} />
            )}
            {content?.type === ModalType.SHOW_FULL_TEXT && renderTextModal(content?.node?.spokenText || '', 'Text')}
            {content?.type === ModalType.ADD_NODE_AFTER && content.chapterId && (
              <LessonAddNodeAfterModal
                hide={hide}
                addNodeAfter={(newNode) => addNodeAfter(eventCallerNode, newNode)}
                chapterId={content.chapterId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonFlowModal;
