// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { ProgramId } from '../modeltypes/id';
import { ProgramType, ProgramTypeBase, ProgramTypeBuilder, ProgramTypeNew } from '../modeltypes/program';
import { PROGRAM_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewProgramId = getNewIdFor<ProgramId>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewProgram = addNewFor<ProgramId, ProgramTypeNew>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewProgram = createNewFor<ProgramId, ProgramTypeBuilder>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewProgram = setNewFor<ProgramId, ProgramTypeNew>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getProgramRefById = getRefByIdFor<ProgramId>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getProgramById = getByIdFor<ProgramId, ProgramType>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getProgramsByIdArray = getByIdArrayFor<ProgramId, ProgramType>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllPrograms = getAllFor<ProgramType>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getProgramsByQuery = getByQueryFor<ProgramType>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllProgramsQuery = getAllQueryFor(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateProgram = updateDocumentFor<ProgramId, ProgramTypeBase>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteProgram = deleteDocumentFor<ProgramId>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchProgramIdSet = watchIdSetFor<ProgramId>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateProgramType = getValidateTypeFor<ProgramType>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateProgramTypeBase = getValidateTypeBaseFor<ProgramTypeBase>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateProgramTypeBuilder = getValidateTypeBuilderFor<ProgramTypeBuilder>(PROGRAM_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateProgramTypeNew = getValidateTypeNewFor<ProgramType>(PROGRAM_TABLE_NAME);
