// *******************************************************
// NewStatBox
// -------------------------------------------------------
// This is a NewStatBox
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface NewStatBoxProps {
  title: string;
  subtitle: string;
  data: any;
  // data: { [name: string]: string | number};
  isLoading?: boolean;
}

const NewStatBox = ({ subtitle, title, data, isLoading }: NewStatBoxProps) => {
  const renderRows = () => {
    const rv = [];
    for (const valueKey in data) {
      const value = data?.[valueKey];
      const title = valueKey;
      if (!isLoading) {
        rv.push(
          <div key={title} className='flex flex-row w-full justify-between items-center my-1'>
            <p className='font-normal text-gray-600 w-10/12'>{title}</p>
            <p className='text-gray-600'>{value?.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') || '-'}</p>
          </div>,
        );
      } else {
        rv.push(<Skeleton key={title} height={24} />);
      }
    }
    return rv;
  };

  return (
    <div className='self-start '>
      <h1 className='font-bold text-3xl mb-2'>{title}</h1>
      <h4 className='font-light text-xl text-gray-700 mb-4'>{subtitle}</h4>
      <div className='flex flex-col px-8 py-4 border-[#BEE2FF] border rounded-lg'>{renderRows()}</div>
    </div>
  );
};

export default NewStatBox;
