import { updateAccount } from './account';
import { Timestamp, increment, where } from '../models/dalaccess';
import { sendInvitationMail } from './mail';
import { AccountType } from '../modeltypes/account';
import { ACCESS_CODE_TABLE_NAME } from './tableName';
import {
  addNewFor,
  deleteDocumentFor,
  getAllFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  queryByIdsFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { AccessCodeId } from '../modeltypes/id';
import { AccessCodeType, AccessCodeTypeBase, AccessCodeTypeBuilder, AccessCodeTypeNew } from '../modeltypes/accessCode';

const CODE_LENGTH = 6;

// noinspection JSUnusedGlobalSymbols
export const getNewAccessCodeId = getNewIdFor<AccessCodeId>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewAccessCode = addNewFor<AccessCodeId, AccessCodeTypeNew>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewAccessCode = addNewFor<AccessCodeId, AccessCodeTypeBuilder>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccessCodeRefById = getRefByIdFor<AccessCodeId>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccessCodeById = getByIdFor<AccessCodeId, AccessCodeType>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccessCodesByIdArray = getByIdArrayFor<AccessCodeId, AccessCodeType>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccessCodeQueryForIds = queryByIdsFor<AccessCodeId>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllAccessCodes = getAllFor<AccessCodeType>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAccessCodesByQuery = getByQueryFor<AccessCodeType>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateAccessCode = updateDocumentFor<AccessCodeId, AccessCodeTypeBase>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteAccessCode = deleteDocumentFor<AccessCodeId>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchAccessCodeIdSet = watchIdSetFor<AccessCodeId>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccessCodeType = getValidateTypeFor<AccessCodeType>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccessCodeTypeBase = getValidateTypeBaseFor<AccessCodeTypeBase>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccessCodeTypeBuilder = getValidateTypeBuilderFor<AccessCodeTypeBuilder>(ACCESS_CODE_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAccessCodeTypeNew = getValidateTypeNewFor<AccessCodeTypeNew>(ACCESS_CODE_TABLE_NAME);

export function generateAccessCode(): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < CODE_LENGTH; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function checkIfUniqueAccessCode(accessCode: string): Promise<boolean> {
  const codes = await getAccessCodesByQuery(where('code', '==', accessCode));
  const isUnique = codes.length === 0;
  return isUnique;
}

export async function createAccessCode({ from, to }: { from: AccountType; to: string }): Promise<void> {
  let unique = false;
  let generatedCode: string = generateAccessCode();
  while (!unique) {
    const codes = await getAccessCodesByQuery(where('code', '==', generatedCode));
    unique = codes.length === 0;
    if (!unique) {
      generatedCode = generateAccessCode();
    }
  }

  if (generatedCode) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await updateAccount(from.uid, { allowedInvites: increment(-1) });

    await addNewAccessCode({
      code: generatedCode,
      used: false,
      from: from.email,
      to: to,
      invited: true,
      createdAt: Timestamp.now(),
    });

    await sendInvitationMail({
      code: generatedCode,
      to: to,
      from: from,
    });
  }
}
