// *******************************************************
// UserSessionList
// -------------------------------------------------------
// This is a list of session data
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// *******************************************
// Component Imports
// -------------------------------------------
// import { ChevronDownIcon } from '@heroicons/react/solid';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
import { getBlob, StorageReference } from 'firebase/storage';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { UserType } from 'src/modeltypes/user';
import { UnitElementType, UnitType } from 'src/modeltypes/unit';
import { SessionType } from 'src/modeltypes/session';
import { getStorageRef } from 'src/firebase/utils';
import { ScienceDataType } from 'src/modeltypes/scienceData';
import { RespirationDataType } from 'src/modeltypes/respirationData';
import { useState } from 'react';
import BpmPopupChart, { BPmPopupChartType } from '../BpmPopupChart';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

export interface UserSessionListType {
  unit?: UnitType | null;
  element?: UnitElementType | null;
  unitIndex?: number | null;
  user?: UserType | null;
  sessionData?: SessionType[] | null;
  onPressClose?: () => void;
  sciencePopupData?: ScienceDataType[] | null;
  respirationData?: RespirationDataType[] | null;
}

const UserSessionList = ({
  unit,
  unitIndex,
  element,
  user,
  sessionData,
  onPressClose,
  sciencePopupData,
  respirationData,
}: UserSessionListType) => {
  const navigate = useNavigate();

  const [showBPM, setShowBPM] = useState<boolean>(false);
  const initialBPMData = {
    plot1: null,
  };
  const [bpmPopupData, setBPMPopupData] = useState<BPmPopupChartType | null>(initialBPMData);

  const isLoading = false;
  const downloadFile = async (path: StorageReference | null | undefined) => {
    if (path) {
      const blob = await getBlob(path);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = path.parent?.name || path.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const toastWrap = (downloadFunction: Promise<void>) => {
    toast.promise(downloadFunction, {
      pending: `Preparing file...`,
      error: "Can't do it now, try again.",
      success: `File is being downloaded!`,
    });
  };

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative p-4 w-full h-full md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>
              Session Data {unit?.title} (Unit {unitIndex || '-'}) {element?.id}
            </h3>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='font-medium text-gray-900'>{user?.email}</div>
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='text-gray-500'>
                {user?.firstName} {user?.lastName}
              </div>
            </td>

            <button
              type='button'
              className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
              onClick={() => (onPressClose ? onPressClose() : null)}
            >
              <svg
                aria-hidden='true'
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
              <span className='sr-only'>Close modal</span>
            </button>
          </div>
          <div className='px-4 sm:px-6 lg:px-8'>
            <div className='mt-8 flex flex-col'>
              <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  {isLoading ? (
                    <>
                      <Skeleton height={50} />
                      <Skeleton count={15} className='mt-3' />{' '}
                    </>
                  ) : (
                    <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                      <table className='min-w-full divide-y divide-gray-300'>
                        <thead className='bg-gray-50'>
                          <tr>
                            <th
                              scope='col'
                              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Item</p>
                            </th>
                            <th
                              scope='col'
                              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Created At</p>
                            </th>
                            {/* <th
                              scope='col'
                              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>
                                User Email
                                <span
                                  className={`ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                                >
                                  <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                                </span>
                              </p>
                            </th>
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>User Name</p>
                            </th> */}
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Lesson</p>
                            </th>{' '}
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Lesson Id</p>
                            </th>{' '}
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Path Type</p>
                            </th>{' '}
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Accel Data</p>
                            </th>
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Accel BPM (Raw)</p>
                            </th>
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>Accel BPM (Smoothed)</p>
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              Gryo Data
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              ML Version
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              Session Id
                            </th>
                            <th scope='col' className='px-3 py-3.5 texst-left text-sm font-semibold text-gray-900'>
                              Time in Lesson (s)
                            </th>
                            <th scope='col' className='px-3 py-3.5 texst-left text-sm font-semibold text-gray-900'>
                              Time in Lesson (min)
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              Disturbed
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              <p>Finished Lesson</p>
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              <p>Has Resp Data</p>
                            </th>
                            <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                              <p>Has Sci Data</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {sessionData?.map((session, index) => (
                            <tr key={session.id} className='hover:bg-stone-200'>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='ml-4'>
                                  <div className='text-gray-500'>{index + 1}</div>
                                </div>
                              </td>

                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                <div className='flex items-center'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>
                                      {format(session?.createdAt?.toDate() || Date.now(), 'dd MMMM  yy, HH:mm')}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {session?.nameOfSession?.length > 20
                                  ? `${session?.nameOfSession.substring(0, 20)}...`
                                  : session?.nameOfSession}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{element?.id}</td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{element?.pathType}</td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div
                                  className={`text-indigo-600 ${
                                    session.accelFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                                  }`}
                                  onClick={() => {
                                    if (session?.accelFile) {
                                      const acceloRef = session?.accelFile ? getStorageRef(session?.accelFile) : null;
                                      toastWrap(downloadFile(acceloRef));
                                    }
                                  }}
                                >
                                  {typeof session?.accelFile === 'string' ? session?.accelFile : '-'}
                                  {/* {typeof session?.accelFile === 'string' ? session?.accelFile.substring(0, 10) : '-'} */}
                                </div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div
                                  className={`text-indigo-600 ${
                                    session.accelPreProcessed ? 'hover:text-indigo-900 cursor-pointer' : ''
                                  }`}
                                  onClick={() => {
                                    if (session?.accelPreProcessed?.length) {
                                      setBPMPopupData({
                                        plot1: session?.accelRespirationData,
                                        plot2: session?.accelPreProcessed,
                                        sessionData: session,
                                      });
                                      setShowBPM(true);
                                    }
                                  }}
                                >
                                  {session?.accelPreProcessed?.length ? 'Yes' : '-'}
                                </div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div
                                  className={`text-indigo-600 ${
                                    session.accelRespirationData ? 'hover:text-indigo-900 cursor-pointer' : ''
                                  }`}
                                  onClick={() => {
                                    if (session?.accelPreProcessed?.length) {
                                      setBPMPopupData({
                                        plot1: session?.accelRespirationData,
                                        plot2: session?.accelPreProcessed,
                                        sessionData: session,
                                      });
                                      setShowBPM(true);
                                    }
                                  }}
                                >
                                  {session?.accelRespirationData?.length ? 'Yes' : '-'}
                                </div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div
                                  className={`text-indigo-600 ${
                                    session.gyroFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                                  }`}
                                  onClick={() => {
                                    if (session?.gyroFile) {
                                      const gyrooRef = session?.gyroFile ? getStorageRef(session?.gyroFile) : null;
                                      toastWrap(downloadFile(gyrooRef));
                                    }
                                  }}
                                >
                                  {typeof session?.gyroFile === 'string' ? session?.gyroFile.substring(0, 10) : '-'}
                                </div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div className={`text-indigo-600`}>{session?.mlVersion}</div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div className={`text-indigo-600`}>{session?.id}</div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div
                                  className={`text-indigo-600 `}
                                  onClick={() => {
                                    navigate(`/rawData/${session.id}/${session.id}`);
                                  }}
                                >
                                  {session?.timeInLesson}
                                </div>
                              </td>

                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                <div className={`text-indigo-600 `}>
                                  {session?.timeInLesson ? (session.timeInLesson / 60).toFixed(1) : '-'}
                                </div>
                              </td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-500'>{}</div>
                                {typeof session?.disturbanceDetected === 'boolean'
                                  ? `${session.disturbanceDetected}`
                                  : '-'}
                              </td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-500'>{`${session?.lessonFinished}`}</div>
                              </td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-500'>
                                  {`${respirationData?.some((x) => x.sessionId === session?.id)}`}
                                </div>
                              </td>

                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-500'>
                                  {`${sciencePopupData?.some((x) => x.sessionId === session?.id)}`}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showBPM ? (
          <BpmPopupChart
            {...bpmPopupData}
            onPressClose={() => {
              setShowBPM(false);
              setBPMPopupData(initialBPMData);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
export default React.memo(UserSessionList);
