import { updateAccount } from './account';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { IdFactory } from '../models/dalaccess';
import { COACH_TABLE_NAME } from './tableName';
import { AccountRole } from '../modeltypes/account';
import { CoachId, UserId } from '../modeltypes/id';
import { CoachType, CoachTypeBase, CoachTypeBuilder, CoachTypeNew } from '../modeltypes/coach';

// noinspection JSUnusedGlobalSymbols
export const getNewCoachesId = getNewIdFor<CoachId>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const _addNewCoach = addNewFor<CoachId, CoachTypeNew>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewCoach = setNewFor<CoachId, CoachTypeNew>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewCoach = createNewFor<CoachId, CoachTypeBuilder>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCoachRefById = getRefByIdFor<CoachId>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCoachById = getByIdFor<CoachId, CoachType>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCoachesByIdArray = getByIdArrayFor<CoachId, CoachType>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCoaches = getAllFor<CoachType>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllCoachesQuery = getAllQueryFor(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getCoachesBy = getByQueryFor<CoachType>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateCoach = updateDocumentFor<CoachId, CoachTypeBase>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteCoach = deleteDocumentFor<CoachId>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchCoachesIdSet = watchIdSetFor<CoachId>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCoachType = getValidateTypeFor<CoachType>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCoachTypeBase = getValidateTypeBaseFor<CoachTypeBase>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCoachTypeBuilder = getValidateTypeBuilderFor<CoachTypeBuilder>(COACH_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateCoachTypeNew = getValidateTypeNewFor<CoachTypeNew>(COACH_TABLE_NAME);

// Re-implementing addNewCoach because of the need for a multi-table update.
export async function addNewCoach(data: CoachType, id: UserId): Promise<string> {
  // TODO: Should this be in a transaction/batchWrite? Probably.
  await setNewCoach(id, data);
  await updateAccount(id, { role: AccountRole.Coach, id: IdFactory.UserIdFromString(id) });
  return id;
}
