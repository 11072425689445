// *******************************************************
// ChangeTrackForUserModal
// -------------------------------------------------------
// This is a ChangeTrackForUserModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { getTracksByQuery } from '../../collections/tracks';
import { where } from 'firebase/firestore';
import { TrackType } from '../../modeltypes/tracks';
import { getAllUserJourneys } from '../../collections/userJourney';
import { changeTrackInUserJourney } from '../../firebase/functions';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface ChangeTrackForUserModalProps {
  hide: () => void;
  start: () => void;
}

const ChangeTrackForUserModal = ({ hide, start }: ChangeTrackForUserModalProps) => {
  const [oldTrackId, setOldTrackId] = useState<string | null>(null);
  const [newTrackId, setNewTrackId] = useState<string | null>(null);
  const [userJourneys, setUserJourneys] = useState<'all' | string[]>('all');
  const [isPending, setIsPending] = useState(false);

  const [tracks, setTracks] = useState<TrackType[]>([]);

  useEffect(() => {
    getTracksByQuery(where('productionStatus', '==', 'PROD')).then((tracks) => {
      setTracks(tracks);
    });
  }, []);

  const selectOptions = useMemo(
    () =>
      tracks.map((el) => ({
        value: el.id,
        label: `${el.title} (${el.id})`,
      })),
    [tracks],
  );

  const callChangeTrackFunction = async () => {
    if (newTrackId && oldTrackId) {
      const userIds: string[] = [];

      setIsPending(true);
      if (userJourneys === 'all') {
        const allJourneys = await getAllUserJourneys();
        userIds.push(...allJourneys.map((el) => el.id));
      } else {
        console.error('not implemented yet!');
      }
      console.log(userIds);
      changeTrackInUserJourney({ userIds, oldTrackId, newTrackId });
      setTimeout(() => {
        start();
        hide();
      }, 4000);
    }
  };

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[10%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative w-full max-w-6xl h-full md:h-auto'>
        <button className='absolute font-bold right-6 top-5 z-10 text-white text-[1.2rem]' onClick={() => hide()}>
          X
        </button>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex flex-col p-5 rounded-t dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white w-full border-b pb-4'>
              Change Track For User
            </h3>
            <div className={'mx-20'}>
              <div className={'flex flex-row mt-5 items-center '}>
                <label htmlFor='name' className='block text-lg font-bold text-gray-700 dark:text-white w-4/12'>
                  Old track
                </label>
                <div className='w-full flex rounded-md shadow-sm'>
                  <Select
                    options={selectOptions.filter((el) => el.value !== newTrackId)}
                    isMulti={false}
                    onChange={(selected) => (selected ? setOldTrackId(selected.value) : null)}
                    className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300`}
                  />
                </div>
              </div>
              <div className={'flex flex-row mt-5 items-center '}>
                <label htmlFor='name' className='block text-lg font-bold text-gray-700 dark:text-white w-4/12'>
                  New Track
                </label>
                <div className='w-full flex rounded-md shadow-sm'>
                  <Select
                    options={selectOptions.filter((el) => el.value !== oldTrackId)}
                    isMulti={false}
                    className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300`}
                    onChange={(selected) => (selected ? setNewTrackId(selected.value) : null)}
                  />
                </div>
              </div>
              <div className={'flex flex-row justify-center mt-5 gap-x-10'}>
                <div className={'flex items-center gap-x-2'}>
                  <input type={'radio'} checked={userJourneys === 'all'} onClick={() => setUserJourneys('all')} />
                  <span className={'font-medium text-gray-900 dark:text-white'}>All users</span>
                </div>
                <div className={'flex items-center gap-x-2'}>
                  <input type={'radio'} checked={userJourneys !== 'all'} onClick={() => setUserJourneys([])} disabled />
                  <span className={'font-medium text-gray-900 dark:text-white'}>Selected users</span>
                </div>
              </div>
            </div>
            <div className='pt-5 pb-5'>
              <div className='flex justify-end'>
                <button
                  disabled={isPending}
                  type='button'
                  className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => hide()}
                >
                  Cancel
                </button>
                <button
                  disabled={isPending}
                  type='button'
                  className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  onClick={callChangeTrackFunction}
                >
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeTrackForUserModal;
