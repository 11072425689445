import React, { PropsWithChildren, useCallback, useState } from 'react';

export enum ModalType {
  TIPS,
  DETAILS,
}

type ShowFunctionProps = { type: ModalType; insightName: string };

type BestAndWorstInsightsType = {
  [field: string]: {
    best: { date?: string; value: string | number };
    worst: { date?: string; value: string | number };
    cohortAverage?: number | string;
    average?: number | string;
  };
};

export type InsightsContextType = {
  modalShown: boolean;
  modalType: ModalType | null;
  showModal: (props: ShowFunctionProps) => void;
  hideModal: () => void;
  insightName: string | null;
  insightsStats: BestAndWorstInsightsType | null;
  setInsightsStats: (data: BestAndWorstInsightsType) => void;
};

const initialValues: InsightsContextType = {
  modalShown: false,
  showModal: ({ type, insightName }) => console.warn('No showModal function attached', type, insightName),
  modalType: null,
  hideModal: () => console.warn('No hideModal function attached'),
  insightName: null,
  insightsStats: null,
  setInsightsStats: () => console.warn('No setInsightsStats function attached'),
};

export const InsightsContext = React.createContext<InsightsContextType>(initialValues);

export const InsightsContextProvider = ({ children }: PropsWithChildren) => {
  const [modalShown, setModalShown] = useState(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [insightName, setInsightName] = useState<string | null>(null);
  const [insightsStats, setInsightsStats] = useState<BestAndWorstInsightsType | null>(null);

  const showModal = useCallback(({ insightName: _insightName, type }: ShowFunctionProps) => {
    setModalType(type);
    setModalShown(true);
    setInsightName(_insightName);
  }, []);

  const hideModal = useCallback(() => {
    setModalShown(false);
    setModalType(null);
    setInsightName(null);
  }, []);

  const value: InsightsContextType = {
    modalShown,
    showModal,
    hideModal,
    modalType,
    insightName,
    insightsStats,
    setInsightsStats,
  };

  return <InsightsContext.Provider value={value}>{children}</InsightsContext.Provider>;
};
export const useInsightsContext = () => React.useContext(InsightsContext);
