// *******************************************************
// Money Type
// -------------------------------------------------------
// Money type used in incentives
// -------------------------------------------

export enum MoneyType {
  DOLLAR = '$',
  CREDIT = 'credits',
  NONE = 'None',
}
