import { WithRequired } from '../utility/GeneralUtilities';
import { UserId } from './id';
import { HasCreateUpdate, HasId } from './shared';
import { Timestamp } from '../models/dalaccess';

export enum AccountRole {
  User = 'user',
  Science = 'science',
  Audio = 'audio',
  Coach = 'coach',
  Admin = 'admin',
  Marketing = 'marketing',
}

export type AccountType = HasId<UserId> &
  HasCreateUpdate & {
    uid: UserId;
    email?: string;
    onboardFinished: boolean;
    trial?: boolean;
    expoToken?: string | null;
    configuring?: boolean;
    verificationCode?: string;
    companyId: string | null;
    paid: boolean;
    cohort: string | null;
    allowedInvites?: number;
    role: AccountRole;
    legacyAccount: boolean;
    appVersion?: string;
    OS?: { type: string; version: string };
    subscription: { expirationDate: Timestamp; type: string } | null;
    lastLogin: Timestamp | null;
    isTestUser?: boolean;
    unsubscribedMails: { date: Timestamp; reason: string | null } | null;
  };

// Base == Everything is optional.
export type AccountTypeBase = Partial<AccountType>;

// Builder == Everything except `id` is optional.
export type AccountTypeBuilder = WithRequired<AccountTypeBase, 'id'>;

// New == Everything is as normal, except that `id` is optional (because the DB will fill it)
// noinspection JSUnusedGlobalSymbols
export type AccountTypeNew = Omit<AccountType, 'id'> & Partial<Pick<AccountType, 'id'>>;
