import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { getAllChallenges } from '../../collections/paths';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { getAllChallenges } from 'src/collections/challenges';
// import { confirmAlert } from 'react-confirm-alert';
// import { dieIfNullOrUndef } from 'src/utility/GeneralUtilities';
import { ChallengeType } from '../../modeltypes/challenges';
// import { getAccountsByIdArray } from '../../collections/account';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';

const ChallengeList = () => {
  // type TableData = {
  //   name?: string;
  //   pathType?: string;
  //   id?: string;
  //   description?: string;
  //   locked?: boolean;
  // };
  const [tableData, setTableData] = useState<ChallengeType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();
  const [tags, setTags] = useState<TagType[] | null>(null);

  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  useEffect(() => {
    getTags();
  }, []);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId;
    });
  }, [searchTerm, tableData]);
  useEffect(() => {
    const prepareTableData = async () => {
      const pathTypes = await getAllChallenges();
      setTableData(pathTypes);
    };
    prepareTableData().then(() => setIsLoading(false));
  }, []);

  const navigate = useNavigate();

  // const onClickDelete = (pathTypeId: string) => {
  //   confirmAlert({
  //     title: `Confirm delete path (ID: ${pathTypeId})?`,
  //     message: 'Are you sure you want to delete this path type? This operation cannot be undone.',
  //     buttons: [
  //       {
  //         label: 'Delete',
  //         onClick: async () => {
  //           await deleteChallenge(pathTypeId);
  //           window.location.reload();
  //         },
  //       },
  //       {
  //         label: 'Cancel',
  //         onClick: () => alert('Never mind then.'),
  //       },
  //     ],
  //   });
  // };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Name
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Description
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Status
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Tags
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Locked
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Type
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((challenge) => (
                      <tr key={challenge.id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{challenge.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{challenge.title}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            <span
                              style={{
                                display: 'inline-block',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '250pt',
                              }}
                            >
                              {challenge.description}
                            </span>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{challenge.productionStatus || 'WIP'}</div>
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {challenge?.tags?.length ? (
                                <div>
                                  {challenge.tags?.map((tag) => (
                                    <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                      {tags?.find((t) => t.id === tag)?.name}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className='text-gray-500'></div>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {challenge.locked ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}
                            </div>
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                          <div
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => navigate(`${window.location.pathname}/${challenge.id}`)}
                          >
                            Edit
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeList;
