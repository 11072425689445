/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { chapterIdSchema, lessonIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { ChapterId } from '../modeltypes/id';
import { timestampType } from './shared';
import { LessonType, LessonTypeBase, LessonTypeBuilder, LessonTypeNew } from '../modeltypes/lesson';

export const productionStatusValueSchema = z.union([z.literal('DEV'), z.literal('PROD')]);

export const lessonTypeSchema = z.object({
  id: lessonIdSchema,
  createdAt: timestampType().optional(),
  updatedAt: timestampType().optional(),
  productionStatus: productionStatusValueSchema.optional().nullable().default(null),
  title: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  author: z.string().optional().nullable(),
  voiceArtist: z.string().optional().nullable(),
  subtitle: z.string().optional().nullable(),
  category: z.string().optional().nullable(),
  introUiText: z.string().optional().nullable(),
  footerUiText: z.string().optional().nullable(),
  icon: z.string().optional().nullable(),
  grayedIcon: z.string().optional().nullable(),
  duration: z.number().optional().nullable(),
  minDuration: z.number().optional().nullable(),
  maxDuration: z.number().optional().nullable(),
  homeworkAssigned: z.string().optional().nullable(),
  chapters: z.array(chapterIdSchema).default(() => new Array<ChapterId>()),
  paths: z.array(z.string()).default(() => new Array<string>()),
  comments: z.array(z.string()).default(() => new Array<string>()),
  importedFromGDocID: z.string().optional().nullable().default(null),
  scriptLastUpdate: z.string().optional().nullable().default(null),
  homeworkDoneQuestion: z.string().optional().nullable(),
  homeworkNotDoneQuestion: z.string().optional().nullable(),
  tags: z.array(z.string()).optional(),
  locked: z.boolean().optional(),
});

export const lessonTypeBaseSchema = lessonTypeSchema.partial();

export const lessonTypeBuilderSchema = lessonTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: lessonIdSchema.default(() => IdFactory.Lesson()) }));

export const lessonTypeNewSchema = lessonTypeSchema
  .omit({ id: true })
  .merge(z.object({ id: lessonIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: LessonType = lessonTypeSchema.parse(undefined);
  const x: LessonTypeBase = lessonTypeBaseSchema.parse(undefined);
  const y: LessonTypeBuilder = lessonTypeBuilderSchema.parse(undefined);
  const z: LessonTypeNew = lessonTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
