// /* eslint-disable @typescript-eslint/no-unused-vars */

/*
* These functions are a lovely choke-point for data access. There are a couple of things
* worth noting.
*
* First off: Whether documentIds should be duplicated in document fields named 'id'
* inside the document itself. IPMcC: I am pretty adamant that they should not, but there
* are lingering concerns that we might rely on this in places like the cloud functions, so
* we're mostly leaving them for now.
*
* Second off: We should probably be taking better advantage of the FirestoreDataConverters
* to handle things like WithFieldValue and PartialWithFieldValue, instead of implementing
* hacks to achieve this ourselves. Now's not a good time for broad sweeping changes though.
* See also: shorturl.at/hoDNV
*
*/

import { z } from 'zod';
import {
  // addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  DocumentReference,
  FirestoreError,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  Query,
  QueryConstraint,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from '../models/dalaccess';
import {
  deleteUndefinedFields,
  dieIfNullOrUndef,
  excWrap,
  flatten,
  idFromString,
  idNullToString,
  idToString,
  removeUndefinedFields
} from '../utility/GeneralUtilities';
import { Schemas } from './schemaAccess';
import {
  ProductionStatus,
  HasCreateUpdate,
  HasCreateUpdateDbTs,
  HasId,
  HasOptIdCreateUpdate,
  HasOptionalId
} from '../modeltypes/shared';
// import stableStringify from 'json-stable-stringify';
import { CollectionReference } from '@firebase/firestore';

export type SnapshotCallback = (docs: QuerySnapshot) => void;

export type UnsubThunk = () => void;

export const stripId = <IdT, T extends object & HasOptionalId<IdT>>(inObj: T): Omit<T, 'id'> => {
  delete inObj['id'];
  return inObj;
};

// Validation
// The idea here is that we never want to WRITE an invalid object to the database,
// and we want to be notified when we READ an invalid object from the database
// (although I don't think we should be 'automatically' trying to correct arbitrary
// objects, at least until we have mature backup and migration strategies in place).

function validationFailed(tableName: string, data: unknown, schema: z.SomeZodObject | undefined | null, schemaName: string, err: unknown, throwOnError = true, logOnError = true): void {
  if (logOnError) {
    // const dataWithID = data as {id?: string};
    // const id: string = dataWithID.id ? dataWithID.id : '<UNKNOWN ID>';
    // console.warn(`Validation failed for table: '${tableName}'(ID: ${id}) Schema: ${schemaName} error: ${err} data:\n${stableStringify(data)}`);
  }
  if (throwOnError) {
    throw err;
  }
}

function _validateAgainstSchema<T>(tableName: string, data: T, schema: z.SomeZodObject | undefined | null, schemaName: string, throwOnError = true, logOnError = true): T {
  if (!schema) {
    if (logOnError) {
      // console.warn(`No ${schemaName} schema for table '${tableName}'. Continuing as if everything is fine.`);
    }
    return data;
  }
  try {
    const rv: any = schema.parse(data);
    return rv;
  }
  catch (e) {
    validationFailed(tableName, data, schema, schemaName, e, throwOnError, logOnError);
    return data;
  }
}

export function validateAgainstSchema<T>(entityName: string, data: T, schema: z.SomeZodObject | undefined | null, throwOnError = true, logOnError = true): T {
  if (!schema) {
    if (throwOnError) {
      if (logOnError) {
        // console.warn(`No schema provided for entity '${entityName}'.`);
      }
      // throw new Error(`No schema provided for entity '${entityName}'.`);
    }
    else if (logOnError) {
      // console.warn(`No schema provided for entity '${entityName}'. Continuing as if everything is fine.`);
    }
    return data;
  }
  try {
    const rv: any = schema.parse(data);
    return rv;
  }
  catch (e) {
    validationFailed(entityName, data, schema, '<Provided Schema>', e, throwOnError, logOnError);
    return data;
  }
}

function validateBaseType<T>(tableName: string, data: T, throwOnError = true, logOnError = true): T {
  const schema = Schemas[tableName]?.base;
  return _validateAgainstSchema(tableName, data, schema, 'base', throwOnError, logOnError);
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getValidateTypeBaseFor<T>(tableName: string) {
  return (data: T, throwOnError = true, logOnError = true) => {
    return validateBaseType<T>(tableName, data, throwOnError, logOnError);
  };
}

function validateTypeBuilder<T>(tableName: string, data: T, throwOnError = true, logOnError= true): T {
  const schema = Schemas[tableName]?.builder;
  return _validateAgainstSchema(tableName, data, schema, 'builder', throwOnError, logOnError);
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getValidateTypeBuilderFor<T>(tableName: string) {
  return (data: T, throwOnError = true, logOnError = true) => {
    return validateTypeBuilder<T>(tableName, data, throwOnError, logOnError);
  };
}

function validateTypeNew<T>(tableName: string, data: T, throwOnError = true, logOnError= true): T {
  const schema = Schemas[tableName]?.new;
  return _validateAgainstSchema(tableName, data, schema, 'builder', throwOnError, logOnError);
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getValidateTypeNewFor<T>(tableName: string) {
  return (data: T, throwOnError = true, logOnError = true) => {
    return validateTypeNew<T>(tableName, data, throwOnError, logOnError);
  };
}

function validateType<T>(tableName: string, data: T, throwOnError = true, logOnError= true): T {
  const schema = Schemas[tableName]?.fullyFormed;
  return _validateAgainstSchema(tableName, data, schema, 'type', throwOnError, logOnError);
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getValidateTypeFor<T>(tableName: string) {
  return (data: T, throwOnError = true, logOnError = true) => {
    return validateType<T>(tableName, data, throwOnError, logOnError);
  };
}

export function prepareChunks<T>(array: T[], max?:number) {
  const rv = array.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / (max || 10));

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return rv;
}

export function getCollection<T>(tableName:string) {
  return collection(tableName) as CollectionReference<T>;
}

function _getNewId<IdT>(tableName: string): IdT {
  return doc(collection(tableName)).id as unknown as IdT;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getNewIdFor<IdT>(tableName: string) {
  return () => {
    return excWrap(_getNewId<IdT>)(tableName);
  };
}

// addNew => Creates a new document in the database with the given data
async function _addNew<IdT, CT extends HasOptionalId<IdT>>(tableName: string, newDoc: CT): Promise<IdT> {
  const data: CT & HasOptIdCreateUpdate<IdT> = {...newDoc};

  data.id = data.id || _getNewId<IdT>(tableName);
  data.createdAt = data.updatedAt = Timestamp.now();

  removeUndefinedFields(data);

  const validData = validateType(tableName, data, true, true);

  const validWithServerTs : HasCreateUpdateDbTs<typeof validData> = validData;
  validWithServerTs.createdAt = validWithServerTs.updatedAt = serverTimestamp();

  // OK, We're commenting this out for now, but it is generally not recommended to have the documentID
  // also in a field in the document itself. The danger is that they're two ways to get the same info,
  // different people might choose one way or the other, but not both, so if they happen to get out of
  // sync (and over a long enough timeline, they WILL get out of sync) you could have some very confusing
  // bugs. That said, we have some current trials that may depend on the id being in the document,
  // const woId = stripId<IdT, typeof validWithServerTs>(validWithServerTs);

  await setDoc(doc(tableName,`${data.id}`), validWithServerTs);
  return idFromString<IdT>(`${data.id}`);
}

// addNew => Creates a new document, written to the database, with the given data
// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function addNewFor<IdT, CT extends HasOptionalId<IdT>>(tableName: string) {
  return async (newDoc?: CT): Promise<IdT> => {
    return excWrap(_addNew<IdT, CT>)(tableName, newDoc || ({} as unknown as CT));
  };
}

// setNew => Creates a new document with id passed via parameters in the database with the given data
async function _setNew<IdT, CT extends HasOptionalId<IdT>>(tableName: string, newDoc: CT, docId: IdT): Promise<void> {
  const data: CT & HasOptIdCreateUpdate<IdT> = {...newDoc};
  data.id = dieIfNullOrUndef(docId);

  removeUndefinedFields(data);

  const validData = validateType(tableName, data, true, true);

  // This is so the validation schemas don't need to understand FieldValue because of serverTimestamp();
  const validWithServerTs : HasCreateUpdateDbTs<typeof validData> = validData;
  validWithServerTs.createdAt = validWithServerTs.updatedAt = serverTimestamp();

  // OK, We're commenting this out for now, but it is generally not recommended to have the documentID
  // also in a field in the document itself. The danger is that they're two ways to get the same info,
  // different people might choose one way or the other, but not both, so if they happen to get out of
  // sync (and over a long enough timeline, they WILL get out of sync) you could have some very confusing
  // bugs. That said, we have some current trials that may depend on the id being in the document,
  // const woId = stripId<IdT, typeof validWithServerTs>(validWithServerTs);

  await setDoc(doc(tableName, idToString(docId)), validData);
}

// setNew => Creates a new document with id passed via parameters in the database with the given data
// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function setNewFor<IdT, CT extends HasOptionalId<IdT>>(tableName: string) {
  return async (docId:IdT, newDoc?: CT): Promise<void> => {
    return excWrap(_setNew<IdT, CT>)(tableName, newDoc || ({} as unknown as CT), docId);
  };
}

// createNew => Creates a new object, locally, with an ID, but does NOT hit/store on the DB
function _createNew<IdT, BuilderT extends HasId<IdT>>(tableName: string): BuilderT {
  const newId: IdT = _getNewId(tableName);
  // This should almost certainly not be necessary, given the def'n of a "builder type",
  // but I'm putting it here for consistency.
  const rv = validateTypeBuilder(tableName, {'id': newId} as BuilderT, true, true);
  return rv;
}

// createNew => Creates a new object, locally, with an ID, but does NOT hit/store on the DB
// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function createNewFor<IdT, BuilderT extends HasId<IdT>>(tableName: string) {
  return (): BuilderT => {
    return excWrap(_createNew<IdT, BuilderT>)(tableName);
  };
}

function _getRefById<IdT>(tableName: string, id?: IdT | null): DocumentReference | null {
  if (!id) {
    return null;
  }
  const docRef = doc(tableName, idToString(id));
  return docRef;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getRefByIdFor<IdT>(tableName: string) {
  return (id?: IdT | null): DocumentReference | null => {
    return excWrap(_getRefById<IdT>)(tableName, id);
  };
}

async function _getById<IdT, DocT extends HasId<IdT>>(tableName: string, id?: IdT | null): Promise<DocT | null> {
  if (!id) {
    return null;
  }
  const docRef = doc(tableName, idToString(id));
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const v = docSnap.data() as DocT;
    v.id = id;
    // This is a READ operation, so we won't throw, but will log if the doc is invalid.
    const rv = validateType<DocT>(tableName, v as DocT, false, true);
    return rv;
  }
  return null;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getByIdFor<IdT, DocT extends HasId<IdT>>(tableName: string){
  return async (id?: IdT | null): Promise<DocT | null> => {
    return excWrap(_getById<IdT, DocT>)(tableName, id);
  };
}


async function _getByIdArray<IdT, DocT extends HasId<IdT>>(
  tableName: string,
  docIds: IdT[],
  ordered = true,
): Promise<DocT[]> {
  if (!docIds.length) {
    return [];
  }
  const stringIds = docIds.map<string>((docId) => idToString(docId));
  const chunks = prepareChunks(stringIds);
  const chapterDocs = chunks.map(async (chunkOfIds: string[]) => {
    const q = query(collection(tableName), where(documentId(), 'in', chunkOfIds));
    const docs = await getDocs(q);
    const chaptersArray: DocT[] = [];
    docs.forEach((doc) => {
      if (doc.exists()) {
        const objWithId = {
          ...(doc.data() as DocT),
          id: doc.id,
        };
        // This is a READ operation, so we won't throw, but will log if the doc is invalid.
        const vo = validateType<DocT>(tableName, objWithId, false, true);
        chaptersArray.push(vo);
      }
    });
    return Promise.resolve(chaptersArray);
  });

  const chunkedResponses = await Promise.all(chapterDocs);

  const rv: DocT[] = [...flatten(chunkedResponses)] as DocT[];

  if (ordered) {
    // Put them into the same order they came in the array of IDs
    const idxById: Record<string, number> = {};
    stringIds.map((cid, idx) => {
      idxById[cid] = idx;
    });
    rv.sort((a, b) => {
      const aidx = idxById[idNullToString(a.id)] || 9999;
      const bidx = idxById[idNullToString(b.id)] || 9999;
      return aidx < bidx ? -1 : bidx > aidx ? 1 : 0;
    });
  }
  return rv;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getByIdArrayFor<IdT, DocT extends HasId<IdT>>(tableName: string) {
  return async (docIds: IdT[], ordered = true): Promise<DocT[]> => {
    return excWrap(_getByIdArray<IdT, DocT>)(tableName, docIds, ordered);
  };
}

async function _getAll<DocT>(tableName: string): Promise<DocT[]> {
  const programDocs = await getDocs(collection(tableName));
  const data: DocT[] = [];
  programDocs.forEach((doc) => {
    const v = doc.data();
    v.id = doc.id;
    // This is a READ operation, so we won't throw, but will log if the doc is invalid.
    const vo = validateType<DocT>(tableName, v as DocT, false, true);
    data.push(vo);
  });
  return data;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getAllFor<DocT>(tableName: string) {
  return async (): Promise<DocT[]> => {
    return excWrap(_getAll<DocT>)(tableName);
  };
}

function _getAllQuery(tableName: string): Query {
  const qry = query(collection(tableName));
  return qry;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function getAllQueryFor(tableName: string) {
  return (): Query => {
    return excWrap(_getAllQuery)(tableName);
  };
}

async function _getByQuery<T>(tableName: string, ...constraints: QueryConstraint[]): Promise<T[]> {
  let collectionSnapshot: QuerySnapshot;
  if (constraints && constraints.length !== 0) {
    const q = query(collection(tableName), ...constraints);
    collectionSnapshot = await getDocs(q);
  } else {
    collectionSnapshot = await getDocs(collection(tableName));
  }

  const values: T[] = [];
  collectionSnapshot.forEach((doc) => {
    const v = doc.data();
    v.id = doc.id;
    // Reading from DB, so only log, don't throw.
    const validatedObject = validateType<T>(tableName, v as T, false, true);
    values.push(validatedObject as T);
  });

  return values;
}

export function getByQueryFor<T,>(tableName: string) {
  return async (...constraints: QueryConstraint[]): Promise<T[]> => {
    return excWrap(_getByQuery<T>)(tableName, ...constraints);
  };
}

// If deleteUndef is true, any keys specifically set to "undefined" (which is to say:
// the key is present in the object but its value is, literally, "undefined" -- which
// is different from the key merely being absent from the object -- then any existing
// value should be deleted. I'm not sure if I'm completely sold on this approach, but...)
async function _updateDocument<IdT, DocUpdateT extends object>(
  tableName: string,
  hasUpdatedAt: boolean,
  id: IdT,
  updateData: DocUpdateT,
  deleteUndef = true,
) {
  // TODO: Doing a "base" validation on this data is better than nothing, but...
  // The issue here is that Firebase supports, and we occasionally do, "partial" (or
  // "sparse") updates, where a sparse version of the doc is used to update a subset
  // of keys. My belief is that this is used to reduce network payloads. As a design
  // principle, I'd like to see us stick to using small documents so we can always
  // deal in "entire objects". That, of course, falls apart with array operations, but
  // we can burn that bridge when we come to it. Another option would be to have two
  // different functions with two different validations.
  const docRef = doc(tableName, idToString(id));
  const data: DocUpdateT = hasUpdatedAt ? {...updateData, updatedAt: serverTimestamp(), id: id} : {...updateData, id: id};
  deleteUndef ? deleteUndefinedFields(data) : removeUndefinedFields(data);

  const validated = validateBaseType(tableName, data, false, true);
  const woId = stripId(validated);
  const rv = updateDoc(docRef, woId);
  return rv;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function updateDocumentFor<IdT, DocUpdateT extends object>(tableName: string, hasUpdatedAt = true){
  return async (id: IdT, updateData: DocUpdateT) => {
    return excWrap(_updateDocument<IdT, DocUpdateT>)(tableName, hasUpdatedAt, id, updateData);
  };
}

// NOTE: Deleting a document does not cascade-delete any subcollections. We don't
// seem to use many subcollections, but there are a couple.
// For details see: https://firebase.google.com/docs/firestore/manage-data/delete-data
async function _deleteDocument<IdT>(tableName: string, id: IdT): Promise<void> {
  const docRef = doc(tableName, idToString(id));
  return deleteDoc(docRef);
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function deleteDocumentFor<IdT>(tableName: string){
  return async (id: IdT): Promise<void> => {
    if (!id) {
      return;
    }
    return excWrap(_deleteDocument<IdT>)(tableName, id);
  };
}

export function nullUnsubThunk(): void {
  // NB: There is no way to make an arrow function that returns void apparently
  // https://stackoverflow.com/questions/48360422/why-cant-i-make-a-void-arrow-function
  return;
}

function _watchIdSet<IdT>(tableName: string, idsToWatch: IdT[], onChange: SnapshotCallback): UnsubThunk {
  if (!idsToWatch.length) {
    return nullUnsubThunk;
  }
  const qry = query(collection(tableName), where(documentId(), 'in', idsToWatch));
  const unsubscribe = onSnapshot(qry, onChange, excWrap((e: FirestoreError) => {
    throw new Error(e.toString());
  }));
  return () => unsubscribe();
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function watchIdSetFor<IdT>(tableName: string) {
  return (idsToWatch: IdT[], onChange: SnapshotCallback): UnsubThunk => {
    if (!idsToWatch.length) {
      return () => undefined;
    }
    return excWrap(_watchIdSet<IdT>)(tableName, idsToWatch, onChange);
  };
}

function _queryByIds<IdT>(tableName: string, ids: IdT[]): Query {
  const qry = query(collection(tableName), where(documentId(), 'in', ids));
  return qry;
}

// This is a currying adapter. Call it to get the real function with `tableName` baked in.
export function queryByIdsFor<IdT>(tableName: string){
  return (ids: IdT[]): Query => {
    return excWrap(_queryByIds<IdT>)(tableName, ids);
  };
}

// noinspection JSUnusedGlobalSymbols
export function coalesceSnapshot<T>(docs: QuerySnapshot): T[] {
  const rv: T[] = [];
  docs.forEach((doc) => {
    if (doc.exists()) {
      rv.push({
        ...(doc.data() as T),
        id: doc.id,
      });
    }
  });
  return rv;
}

export function uiStatusString(input: ProductionStatus | null | undefined): string {
  if (input === null || input === undefined) {
    // console.warn(`Unexpected ${typeof input} object passed to uiStatusString()`);
    return '';
  }
  if (!input.productionStatus) {
    return 'WIP';
  }

  return input.productionStatus.toString();
}

export function uiFormatTimestamp(ts?: Timestamp | null | undefined): string {
  if (!ts) {
    return "<UNKNOWN>";
  }
  return ts.toDate().toLocaleString();
}

// noinspection JSUnusedGlobalSymbols
export function setUpdatedAt<T extends HasCreateUpdate>(entity: T, ts?: Timestamp): T {
  const val = ts ? ts : Timestamp.now();
  entity.updatedAt = val;
  return entity;
}

