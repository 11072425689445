import { STREAKS_TABLE_NAME } from './tableName';
import { StreakType, StreakTypeBase, StreakTypeBuilder, StreakTypeNew } from '../modeltypes/streaks';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { StreakId } from 'src/modeltypes/id';
import { limit, orderBy } from 'firebase/firestore';

// noinspection JSUnusedGlobalSymbols

export const getNewStreakId = getNewIdFor<StreakId>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewStreak = addNewFor<StreakId, StreakTypeNew>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewStreak = createNewFor<StreakId, StreakTypeBuilder>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewStreak = setNewFor<StreakId, StreakTypeNew>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getStreakRefById = getRefByIdFor<StreakId>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getStreakById = getByIdFor<StreakId, StreakType>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getStreaksByIdArray = getByIdArrayFor<StreakId, StreakType>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllStreaks = getAllFor<StreakType>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getStreaksByQuery = getByQueryFor<StreakType>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllStreaksQuery = getAllQueryFor(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateStreak = updateDocumentFor<StreakId, StreakTypeBase>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteStreak = deleteDocumentFor<StreakId>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchStreakIdSet = watchIdSetFor<StreakId>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateStreakType = getValidateTypeFor<StreakType>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateStreakTypeBase = getValidateTypeBaseFor<StreakTypeBase>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateStreakTypeBuilder = getValidateTypeBuilderFor<StreakTypeBuilder>(STREAKS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateStreakTypeNew = getValidateTypeNewFor<StreakType>(STREAKS_TABLE_NAME);

export const getStreakTopScoresAnalytics = async () => {
  const analytics = await getStreaksByQuery(orderBy('highestStreak', 'desc'), limit(50));
  return analytics;
};
