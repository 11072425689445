// *******************************************************
// BPmPopupChart
// -------------------------------------------------------
// This is a Component for Editing / adding company
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLine } from 'victory';
import { BreakPointsTs, RespirationDataType } from 'src/modeltypes/respirationData';
import { ScienceDataType } from 'src/modeltypes/scienceData';
import { SessionType } from 'src/modeltypes/session';
// *******************************************
// Component Imports
// -------------------------------------------
// *******************************************
// Hooks Import
// -------------------------------------------
// *******************************************
// Action Imports
// -------------------------------------------
// *******************************************
// Styles Imports
// -------------------------------------------
// *******************************************
// Constants
// -------------------------------------------
const MEDITATION_MAX = 9; // 11.71;
const MEDITATION_MIN = 3; // 5;
// const GLOBAL_AVERAGE_BREATH = 16.52;
// *******************************************
// Types
// -------------------------------------------
type BpmType = {
  bpm: number;
  time: number;
};
export interface BPmPopupChartType {
  plot1?: BpmType[] | null;
  plot2?: BpmType[];
  plot3?: BpmType[];
  breakPoints?: BreakPointsTs;
  sessionData?: SessionType;
  respirationData?: RespirationDataType;
  scienceData?: ScienceDataType;
  onPressClose?: () => void;
}

const BPmPopupChart = ({
  plot1,
  plot2,
  plot3,
  sessionData,
  respirationData,
  breakPoints,
  scienceData,
  onPressClose,
}: BPmPopupChartType) => {
  const asdf = plot2 && plot3 && sessionData && scienceData && breakPoints;
  console.log('asdf: ', asdf);

  console.log('plot1: ', plot1);
  console.log('plot2: ', plot2);

  const timesPlot1 = plot1?.length ? plot1.map((x) => x.time) : null;
  const bpmsPlot1 = plot1?.length ? plot1?.map((x) => x.bpm) : null;

  const maxTimePlot1 = timesPlot1 ? Math.max(...timesPlot1) : null;
  const maxBPMPlot1 = bpmsPlot1 ? Math.max(...bpmsPlot1) : null;

  const timesPlot2 = plot2?.length ? plot2.map((x) => x.time) : null;
  const bpmsPlot2 = plot2?.length ? plot2?.map((x) => x.bpm) : null;

  const maxTimePlot2 = timesPlot2 ? Math.max(...timesPlot2) : null;
  const maxBPMPlot2 = bpmsPlot2 ? Math.max(...bpmsPlot2) : null;

  const yMax = maxBPMPlot1 || maxBPMPlot2 || 20;
  const xMax = maxTimePlot1 || maxTimePlot2 || 620;
  const xMin = 4;

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative  w-full max-w-3xl h-full md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <button
            type='button'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
            onClick={() => (onPressClose ? onPressClose() : null)}
          >
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
            <span className='sr-only'>Close modal</span>
          </button>

          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div>
                <VictoryChart
                  maxDomain={{
                    y: yMax,
                    x: xMax,
                  }}
                  minDomain={{ y: 0, x: xMin }}
                  padding={{ left: 25, top: 50, right: 50, bottom: 50 }}
                  animate
                >
                  <VictoryArea
                    y={() => MEDITATION_MAX}
                    y0={() => MEDITATION_MIN}
                    style={{ data: { fill: '#D8D8', opacity: 0.4 } }}
                  />
                  {/* {breakPoints && breakPoints.intro > 0 && (
                    <VictoryArea
                      labels={['Intro']}
                      x={() => breakPoints.intro / 2}
                      style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
                    />
                  )}
                  {breakPoints && breakPoints.intro > 0 && (
                    <VictoryArea
                      x={() => breakPoints.intro}
                      y={() => 1}
                      style={{ data: { fill: '#D6D6D6' }, labels: { fill: '#D6D6D6' } }}
                    />
                  )}
                  {breakPoints && breakPoints.meditation > 0 && (
                    <VictoryArea
                      labels={['Meditation']}
                      x={() =>
                        (breakPoints.meditation - breakPoints.intro) / 2 +
                        breakPoints.intro
                      }
                      style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
                    />
                  )}
                  {breakPoints && breakPoints.meditation > 0 && (
                    <VictoryArea
                      x={() => breakPoints.meditation}
                      y={() => 1}
                      style={{ data: { fill: '#D6D6D6' }, labels: { fill: '#D6D6D6' } }}
                    />
                  )}
                  {breakPoints && breakPoints.practice > 0 ? (
                    <VictoryArea
                      labels={['Interaction']}
                      x={() =>
                        (breakPoints.practice - breakPoints.meditation) / 2 +
                        breakPoints.meditation
                      }
                      style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
                    />
                  ) : breakPoints && breakPoints.meditation > 0 ? (
                    <VictoryArea
                      labels={['Interaction']}
                      x={() =>
                        ((plot1?.length || plot2?.length || 0) - breakPoints.meditation) / 2 +
                        breakPoints.meditation
                      }
                      style={{ data: { fill: '#D6D6D6', opacity: 0 }, labels: { fill: '#D6D6D6' } }}
                    />
                  ) : null} */}
                  {plot1?.length && (
                    <VictoryLine
                      data={plot1?.map((plot) => ({ x: plot?.time, y: plot?.bpm }))}
                      style={{ data: { stroke: '#000000', strokeWidth: 1 } }}
                      interpolation={'natural'}
                    />
                  )}
                  {plot2?.length && (
                    <VictoryLine
                      data={plot2?.map((plot) => ({ x: plot?.time, y: plot?.bpm }))}
                      style={{ data: { stroke: 'blue', strokeWidth: 1 } }}
                      interpolation={'natural'}
                    />
                  )}
                  <VictoryAxis
                    dependentAxis
                    style={{
                      axis: { stroke: '#000000' },
                      tickLabels: { fill: '#000000' },
                    }}
                  />
                  <VictoryAxis
                    style={{
                      axis: { stroke: '#000000' },
                      tickLabels: { fill: '#000000' },
                    }}
                    tickValues={[60, 120, 180, 240, 300, 360, 420, 480, 540, 600]}
                  />
                </VictoryChart>
              </div>
            </div>
            <div className=' sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Session Data
              </label>
              <p>{sessionData ? JSON.stringify(sessionData) : 'No Data'}</p>
            </div>
            <div className=' sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Respiration Data
              </label>
              <p>{respirationData ? JSON.stringify(respirationData) : 'No Data'}</p>
              <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                {`Breaths Per Minute ${respirationData?.breathRate ? respirationData?.breathRate.toFixed(1) : '---'}`}
              </label>
              <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                {`Zone Minutes ${respirationData?.zoneMinutes}`}
              </label>
            </div>

            <div className=' sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                Science Data
              </label>
              <p>{scienceData ? JSON.stringify(scienceData) : 'No Data'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BPmPopupChart;
