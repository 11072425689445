/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewChallenge, getChallengeById, updateChallenge } from '../../collections/challenges';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { ChallengeTextBlockType, ChallengeType, ChallengeTypeBase } from '../../modeltypes/challenges';
import { toast } from 'react-toastify';
import { UploadedFile } from '../../utility/uploadHandler';
import ChallengeNodeList from '../../components/paths/ChallengeNodeList';
import { uploadFile } from '../../firebase/storage';
import Select from 'react-select';
import { ReactSelectOptions } from '../../types/types';
import { getAllTags } from '../../collections/tags';
import { uiFormatTimestamp } from '../../collections/shared';
import { TagId } from 'src/modeltypes/id';
import { TagType } from 'src/modeltypes/tags';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { ProductionStatusValue } from '../../modeltypes/shared';
import { NodeType } from '../../modeltypes/node';
import { deleteNode as deleteNodeDB } from '../../collections/nodes';

// type UserSelect = {
//
// }

type ChallengeFormType = {
  title?: string | null;
  texts?: ChallengeTextBlockType[] | null;
  icon?: UploadedFile;
  grayedIcon?: UploadedFile;
  productionStatus?: ProductionStatusValue | null;
  tags?: TagId[];
  duration: number;
  description: string;
};

const ChallengeForm = () => {
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<ReactSelectOptions[]>([]);
  const [selectedTags, setSelectedTags] = useState<ReactSelectOptions[]>([]);
  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths, setValidateElement } = useAppContext();

  const { values, errors, handleSubmit, handleChange, setValues, setFieldValue } = useFormik<ChallengeFormType>({
    validateOnChange: false,
    initialValues: {
      title: '',
      texts: [],
      productionStatus: 'DEV',
      duration: 1,
      description: '',
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required'),
    }),
    onSubmit: async (challengeValues) => {
      toast
        .promise(
          async () => {
            let challengeId = challenge?.id;

            if (!challengeId) {
              challengeId = await addNewChallenge();
            }

            const urls: { icon?: string; grayedIcon?: string } = {
              icon: undefined,
              grayedIcon: undefined,
            };
            if (
              challenge?.grayedIcon !== values.grayedIcon?.url &&
              values.grayedIcon?.url !== '' &&
              values.grayedIcon?.file
            ) {
              urls.grayedIcon = await uploadFile(values.grayedIcon.file, 'grayIcon', ['assets', challengeId]);
            }
            if (challenge?.icon !== values.icon?.url && values.icon?.url !== '' && values.icon?.file) {
              urls.icon = await uploadFile(values.icon.file, 'icon', ['assets', challengeId]);
            }
            const updatedChallenge: ChallengeTypeBase = {
              title: challengeValues.title,
              texts: challengeValues.texts,
              productionStatus: challengeValues.productionStatus,
              grayedIcon: urls.grayedIcon,
              icon: urls.icon,
              pathType: 'challenge',
              tags: values.tags || [],
              duration: challengeValues.duration,
              description: challengeValues.description,
            };

            await updateChallenge(challengeId, {
              ...updatedChallenge,
            });
          },
          {
            pending: `${challenge ? 'Updating' : 'Adding'} ${challengeValues.title} Challenge...`,
            error: "Can't do it now, try again.",
            success: `${challenge ? 'Updated' : 'Added'} ${challengeValues.title} Challenge!`,
          },
        )
        .then(() => {
          navigate('/challenges');
        });
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currChallenge = await getChallengeById(id || '');
        if (currChallenge === null) {
          navigate('/Challenges');
          return;
        }
        setChallenge(currChallenge);
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  useEffect(() => {
    const setT = async (thisChallenge: ChallengeType) => {
      const dbTags = await getAllTags();
      const selectorT = dbTags.map((dbTag: TagType) => ({
        label: `${dbTag.name}`,
        value: dbTag?.id,
      }));
      setTags(selectorT);
      if (thisChallenge?.tags?.length) {
        setSelectedTags(selectorT.filter((t) => thisChallenge?.tags?.includes(t?.value)));
      }
    };

    if (challenge) {
      setValues({
        title: challenge.title || '',
        texts: challenge.texts || [],
        icon: { url: challenge.icon || '' },
        grayedIcon: { url: challenge.grayedIcon || '' },
        productionStatus: challenge.productionStatus,
        tags: challenge.tags || undefined,
        duration: challenge.duration || 1,
        description: challenge.description || '',
      });

      setT(challenge);

      setBreadcrumbPaths([
        {
          label: 'Challenges',
          path: '/challenges',
        },
        {
          label: challenge?.title || 'New Challenge',
          path: `/challenges/${id}`,
        },
      ]);
    }
  }, [challenge]);

  useEffect(() => {
    if (challenge && tags) {
      setFieldValue(
        'tags',
        tags.filter((el) => challenge.tags?.includes(el.value)),
      );
    }
  }, [setFieldValue, tags, challenge]);

  const disabledForm = false;

  const changeTo = (id: string, to: 'DEV' | 'PROD') => {
    setValidateElement({
      id,
      type: 'challenge',
      to,
    });
  };

  const deleteNode = async (node: NodeType) => {
    if (challenge) {
      const flow = challenge?.flow;
      const index = flow?.findIndex((el) => el === node.id);
      if (index > -1) {
        flow?.splice(index, 1);
        await updateChallenge(challenge.id, { flow });
        await deleteNodeDB(node.id);
        setChallenge({
          ...challenge,
          flow,
        });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <>
          <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
            <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
              <div>
                <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                  Challenge Details <span style={{ fontSize: '80%' }}>(ID: {challenge?.id || 'not yet'})</span>
                </label>
                <p>
                  <strong>Created:</strong>&nbsp;{uiFormatTimestamp(challenge?.createdAt)}
                  &nbsp;&nbsp;
                  <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(challenge?.updatedAt)}
                </p>
                {challenge ? (
                  <>
                    <div className={'flex flex-row items-center mt-3'}>
                      <p className='font-bold mr-3'>Status:</p>
                      {challenge?.productionStatus || 'WIP'}
                      {challenge?.productionStatus === 'DEV' ? (
                        <button
                          type={'button'}
                          onClick={() => changeTo(challenge?.id, 'PROD')}
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          Change to Prod
                        </button>
                      ) : null}
                      {!challenge?.productionStatus ? (
                        <button
                          type={'button'}
                          onClick={() => changeTo(challenge?.id, 'DEV')}
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          Change to Dev
                        </button>
                      ) : null}
                    </div>
                    <div className={'flex flex-row items-center mt-3'}>
                      <p className='font-bold mr-3'>Locked:</p>
                      {challenge?.locked ? (
                        <CheckIcon className='h-5 w-5 text-green-500' />
                      ) : (
                        <XIcon className='h-5 w-5 text-red-400' />
                      )}
                    </div>
                  </>
                ) : null}
                <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Name
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <div className='max-w-lg flex rounded-md shadow-sm'>
                        <input
                          maxLength={40}
                          disabled={disabledForm}
                          type='text'
                          name='title'
                          id='title'
                          autoComplete='title'
                          className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                            errors.title ? 'border-red-300' : 'border-gray-300'
                          }`}
                          defaultValue={values.title || ''}
                          onChange={handleChange}
                          placeholder={'Challenge Name'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`flex flex-col ${errors.texts ? 'bg-red-50' : ''}`}>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                      <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                        Text Blocks
                      </label>
                    </div>
                    <div>
                      {values.texts?.map((text, index) => (
                        <div
                          key={text.id}
                          className='sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'
                        >
                          <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                            Text Block {index + 1}
                          </label>
                          <div className='max-w-lg flex rounded-md shadow-sm flex flex-col gap-y-1'>
                            <textarea
                              // disabled={disabledForm}
                              // type='text'
                              name={`texts[${index}].text`}
                              id={`texts[${index}].text`}
                              autoComplete={`texts`}
                              className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                              value={values?.texts?.[index].text || ''}
                              onChange={handleChange}
                              placeholder={'Enter Text'}
                            />
                          </div>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              const temp = values.texts ? [...values.texts] : [];
                              const index = temp.findIndex((i) => i.id === text.id);
                              if (index > -1) {
                                temp.splice(index, 1);
                                setValues({
                                  ...values,
                                  texts: temp,
                                });
                              }
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      ))}
                      <button
                        className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        onClick={(e) => {
                          e.preventDefault();
                          const temp = values.texts ? [...values.texts] : [];
                          temp.push({
                            id: new Date().getTime().toString(),
                            text: '',
                          });
                          setValues({
                            ...values,
                            texts: temp,
                          });
                        }}
                      >
                        + Add Text Block
                      </button>
                    </div>
                  </div>

                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Description
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <textarea
                        id='description'
                        name='description'
                        rows={3}
                        className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                        value={values.description}
                        onChange={handleChange}
                        placeholder={'Provide a description'}
                      />
                    </div>
                  </div>

                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
                    <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                      Icons
                    </label>
                    <div className='flex  justify-evenly'>
                      <div className='flex items-center justify-center flex-col '>
                        <p className='text-sm text-gray-600 mb-2'>Normal</p>
                        <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100'>
                          {values?.icon?.url ? (
                            <img className='h-full w-full' src={values.icon.url} alt='icon' />
                          ) : (
                            <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                              <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                            </svg>
                          )}
                        </span>
                        <div className='flex text-sm text-gray-600 mt-3'>
                          <label
                            htmlFor='icon'
                            className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                          >
                            <span>Upload a file</span>
                            <input
                              id='icon'
                              name='icon'
                              type='file'
                              accept='image/*'
                              className='sr-only'
                              onChange={(e) => {
                                const file = e.target?.files?.item(0);
                                if (file) {
                                  const url = URL.createObjectURL(file);
                                  setValues({
                                    ...values,
                                    icon: {
                                      url: url,
                                      file: file,
                                    },
                                  });
                                }
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className='flex items-center justify-center flex-col '>
                        <p className='text-sm text-gray-600 mb-2'>Grayed</p>
                        <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100 hover:cursor-pointer'>
                          {values?.grayedIcon?.url ? (
                            <img className='h-full w-full' src={values.grayedIcon.url} alt='icon' />
                          ) : (
                            <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                              <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                            </svg>
                          )}
                        </span>
                        <div className='flex text-sm text-gray-600 mt-3'>
                          <label
                            htmlFor='grayIcon'
                            className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                          >
                            <span>Upload a file</span>
                            <input
                              id='grayIcon'
                              name='grayIcon'
                              type='file'
                              accept='image/*'
                              className='sr-only'
                              onChange={(e) => {
                                const file = e.target?.files?.item(0);
                                if (file) {
                                  const url = URL.createObjectURL(file);
                                  setValues({
                                    ...values,
                                    grayedIcon: {
                                      url: url,
                                      file: file,
                                    },
                                  });
                                }
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Estimated duration [min]
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <div className='max-w-lg flex rounded-md shadow-sm'>
                        <input
                          disabled={disabledForm}
                          type='number'
                          name='duration'
                          id='duration'
                          autoComplete='duration'
                          className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                            errors.duration ? 'border-red-300' : 'border-gray-300'
                          }`}
                          defaultValue={values.duration}
                          onChange={handleChange}
                          placeholder={'Challenge Name'}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                    <div>
                      <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        Tags:
                      </label>
                    </div>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <Select
                        options={tags || []}
                        isMulti={true}
                        value={selectedTags}
                        onChange={(vals) => {
                          setSelectedTags(vals as ReactSelectOptions[]);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                    </div>
                  </div>

                  {/*<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>*/}
                  {/*  <div>*/}
                  {/*    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>*/}
                  {/*      STATUS:*/}
                  {/*    </label>*/}
                  {/*    <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>*/}
                  {/*      What environtment is this lesson in? It Can be DEV or PROD*/}
                  {/*    </p>*/}
                  {/*  </div>*/}
                  {/*  <div className='mt-1 sm:mt-0 sm:col-span-2'>*/}
                  {/*    <select*/}
                  {/*      name='productionStatus'*/}
                  {/*      value={values.productionStatus || ''}*/}
                  {/*      onChange={handleChange}*/}
                  {/*      style={{ display: 'block' }}*/}
                  {/*    >*/}
                  {/*      <option value='DEV' label='DEV'>*/}
                  {/*        DEV*/}
                  {/*      </option>*/}
                  {/*      <option value='PROD' label='PROD'>*/}
                  {/*        PROD*/}
                  {/*      </option>*/}
                  {/*    </select>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
            <div className='pt-5'>
              <div className='flex justify-end'>
                <button
                  type='button'
                  className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => navigate('/challenges')}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  {challenge ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </form>
          {id !== 'new' ? (
            <ChallengeNodeList
              nodeIds={challenge?.flow}
              locked={challenge?.productionStatus === 'PROD'}
              deleteNode={deleteNode}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default ChallengeForm;
