import React, { PropsWithChildren, useState } from 'react';
import { Pages } from '../enums/pages';
import { User } from 'firebase/auth';
import { BreadcrumbType } from '../types/types';
import { useAuth } from 'reactfire';

export type ValidateElementType = {
  id: string;
  type:
    | 'unit'
    | 'track'
    | 'lesson'
    | 'challenge'
    | 'task'
    | 'challengeSwitch'
    | 'taskSwitch'
    | 'audioChunk'
    | 'practice'
    | 'learningGame';
  to: 'DEV' | 'PROD';
};

export type AppContextType = {
  currentPage?: Pages;
  setCurrentPage?: (v: Pages) => void;
  user?: User | null;
  setUser: (v: User | null) => void;
  error?: string;
  setError: (e: string) => void;
  searchTerm?: string;
  setSearchTerm: (e: string) => void;
  breadcrumbPaths: BreadcrumbType[];
  setBreadcrumbPaths: (e: BreadcrumbType[]) => void;
  role?: 'user' | 'science' | 'audio' | 'coach' | 'admin';
  setRole: (r: 'user' | 'science' | 'audio' | 'coach' | 'admin') => void;
  validateElement?: ValidateElementType | null;
  setValidateElement: (el: ValidateElementType | null) => void;
};

const initialValues: AppContextType = {
  setUser: (user: User | null) => console.log('no context set user', user),
  setError: (e: string) => console.log('no context set error', e),
  setSearchTerm: (e: string) => console.log('no searchTerm set error', e),
  setBreadcrumbPaths: (b) => console.log('no context set breadcrumbs', b),
  setRole: (r) => console.log('no context set role', r),
  breadcrumbPaths: [],
  setValidateElement: (r) => console.log('no context set validate element', r),
};

export const AppContext = React.createContext<AppContextType>(initialValues);

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const currentUser = auth.currentUser;
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.None);
  const [user, setUser] = useState<User | null>(currentUser);
  const [error, setError] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbType[]>([]);
  const [role, setRole] = useState<'user' | 'science' | 'audio' | 'coach' | 'admin'>();

  const [validateElement, setValidateElement] = useState<ValidateElementType | null>(null);

  const value: AppContextType = {
    currentPage,
    setCurrentPage,
    user,
    setUser,
    error,
    setError,
    breadcrumbPaths,
    setBreadcrumbPaths,
    searchTerm,
    setSearchTerm,
    role,
    setRole,
    validateElement,
    setValidateElement,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
export const useAppContext = () => React.useContext(AppContext);
