import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllCoaches } from '../../collections/coach';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { getAccountsByIdArray } from '../../collections/account';
import * as TabIcons from '../../assets/tabIcons/tabIcons';

const CoachList = () => {
  type TableData = {
    name?: string;
    organization?: string;
    id?: string;
    email?: string;
    imageUrl?: string;
  };
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData.filter((item) => {
      const matchName = item.name && item.name.match(regex);
      const matchOrg = item.organization && item.organization.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId || matchOrg;
    });
  }, [searchTerm, tableData]);
  useEffect(() => {
    const prepareTableData = async () => {
      const newTableData: TableData[] = [];
      const coaches = await getAllCoaches();
      const accounts = await getAccountsByIdArray(coaches.map((c) => c.id));
      for (const coach of coaches) {
        const account = accounts.find((account) => account.id === coach.id);
        const data: TableData = {
          name: coach.name,
          organization: coach.organizationName,
          id: coach.id,
          email: account?.email,
          imageUrl: coach.imageUrl,
        };
        newTableData.push(data);
      }
      setTableData(newTableData);
    };

    prepareTableData().then(() => setIsLoading(false));
  }, []);
  const navigate = useNavigate();
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      ></th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Name
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Email
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Organization
                      </th>
                      <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((coach) => (
                      <tr key={coach.id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {coach.imageUrl ? (
                                <img
                                  src={coach.imageUrl}
                                  className='h-8 w-8 rounded-full overflow-clip'
                                  alt={'userPhoto'}
                                />
                              ) : (
                                <TabIcons.UserIcon className='h-8 w-8 rounded-full overflow-clip' />
                              )}
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{coach.id}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{coach.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{coach.email}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{coach.organization}</div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <div onClick={() => navigate('/Coaches/' + coach.id)} style={{ cursor: 'pointer' }}>
                            Edit
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachList;
