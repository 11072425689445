// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { AppUpdateInfoId } from '../modeltypes/id';
import {
  AppUpdateInfoType,
  AppUpdateInfoTypeBase,
  AppUpdateInfoTypeBuilder,
  AppUpdateInfoTypeNew,
} from '../modeltypes/appUpdateInfo';
import { APP_UPDATE_INFO_TABLE_NAME } from './tableName';

// noinspection JSUnusedGlobalSymbols
export const getNewAppUpdateInfoId = getNewIdFor<AppUpdateInfoId>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewAppUpdateInfo = addNewFor<AppUpdateInfoId, AppUpdateInfoTypeNew>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewAppUpdateInfo = createNewFor<AppUpdateInfoId, AppUpdateInfoTypeBuilder>(
  APP_UPDATE_INFO_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const setNewAppUpdateInfo = setNewFor<AppUpdateInfoId, AppUpdateInfoTypeNew>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAppUpdateInfoRefById = getRefByIdFor<AppUpdateInfoId>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAppUpdateInfoById = getByIdFor<AppUpdateInfoId, AppUpdateInfoType>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAppUpdateInfoItemsByIdArray = getByIdArrayFor<AppUpdateInfoId, AppUpdateInfoType>(
  APP_UPDATE_INFO_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getAllAppUpdateInfoItems = getAllFor<AppUpdateInfoType>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAppUpdateInfoItemsByQuery = getByQueryFor<AppUpdateInfoType>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllAppUpdateInfoItemsQuery = getAllQueryFor(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateAppUpdateInfo = updateDocumentFor<AppUpdateInfoId, AppUpdateInfoTypeBase>(
  APP_UPDATE_INFO_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteAppUpdateInfo = deleteDocumentFor<AppUpdateInfoId>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchAppUpdateInfoIdSet = watchIdSetFor<AppUpdateInfoId>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAppUpdateInfoType = getValidateTypeFor<AppUpdateInfoType>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAppUpdateInfoTypeBase = getValidateTypeBaseFor<AppUpdateInfoTypeBase>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAppUpdateInfoTypeBuilder =
  getValidateTypeBuilderFor<AppUpdateInfoTypeBuilder>(APP_UPDATE_INFO_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAppUpdateInfoTypeNew = getValidateTypeNewFor<AppUpdateInfoType>(APP_UPDATE_INFO_TABLE_NAME);
