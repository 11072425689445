import { TrackType } from '../../../modeltypes/tracks';
import { UnitType } from '../../../modeltypes/unit';

const REQUIRED_KEYS_TRACK: Array<keyof TrackType> = ['title', 'description', 'goal'];

export const validateTrack = (track: TrackType | null, units: UnitType[], to: 'DEV' | 'PROD') => {
  const errors = [];

  if (!track) {
    errors.push(`CRITICAL TRACK - missing track`);
    return errors;
  }

  for (const key of REQUIRED_KEYS_TRACK) {
    if (!track[key]) {
      if (!track?.['goal'] && track.isIntroSeries) {
        break;
      }
      errors.push(`TRACK - Missing ${key}`);
    }
  }

  if (track.units.length === 0) {
    errors.push(`TRACK - No units attached!`);
  } else {
    for (const unit of units) {
      if (to === 'PROD' && unit.productionStatus !== 'PROD') {
        errors.push(`UNIT - ${unit.id} unit is not set to PROD`);
      } else if (to === 'DEV' && !unit.productionStatus) {
        errors.push(`UNIT - ${unit.id} unit needs to have at least DEV status`);
      }
    }
  }
  return errors;
};
