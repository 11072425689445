// *******************************************************
// ChallangeNodeList
// -------------------------------------------------------
// This is a ChallangeNodeList
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../enums/pages';
import { useCallback, useEffect, useState } from 'react';
import { NodeType } from '../../modeltypes/node';
import { getNodesByIdArray } from '../../collections/nodes';
import ChallengeNodeFlow from './ChallengeNodeFlow';
import cloneDeep from 'clone-deep';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// interface ChallangeNodeListProps {
//
// };

const ChallengeNodeList = ({
  nodeIds,
  locked,
  deleteNode,
}: {
  nodeIds?: string[];
  locked: boolean;
  deleteNode: (node: NodeType) => Promise<void>;
}) => {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState<NodeType[] | null>(null);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (nodeIds) {
      getNodesByIdArray(nodeIds).then((fNodes) => {
        setNodes(fNodes);
        setShow(false);
      });
    }
  }, [nodeIds]);

  useEffect(() => {
    if (!show) {
      setShow(true);
    }
  }, [show]);

  const updateNodes = async (nIds: string[]) => {
    const nodesToUpdate = await getNodesByIdArray(nIds);
    if (nodes) {
      const nodesClone = cloneDeep(nodes);
      for (const node of nodesToUpdate) {
        const index = nodesClone.findIndex((i) => i.id === node.id);
        if (index !== -1) {
          nodesClone.splice(index, 1, node);
        } else {
          nodesClone.push(node);
        }
      }
      setNodes(nodesClone);
    } else {
      setNodes(nodesToUpdate);
    }
    // setShow(false);
  };

  const isDetached = useCallback(
    (node: NodeType) => {
      const hasParent = nodes?.some((el) =>
        [el.nextNode, ...(el.answers?.map((answer) => answer.nextNode || '') || [])].includes(node.id),
      );
      const hasChild = !!node.nextNode || node.answers?.some((el) => el.nextNode) || false;

      return !hasParent && !hasChild;
    },
    [nodes],
  );

  return (
    <>
      <div className='sm:grid sm:items-start sm:pt-5'>
        <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2'>
          Challenge Nodes
        </label>
        <div className='mt-8 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full'>
                  <thead className='bg-white'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Code
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Title
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Type
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Audio Url
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Question
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Number of answers
                      </th>
                      <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        {locked ? null : (
                          <div
                            className='text-center text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => {
                              navigate(`${window.location.pathname}/${Pages.Node}/new`);
                            }}
                          >
                            Add Node
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  {nodes?.map((node, index) => (
                    <tr key={node.id} className={`${index === 0 ? 'border-gray-300' : 'border-gray-200'} border-t`}>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                        {node.id}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {node.code || '<NO CODE SET>'}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{node.title}</td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{node.nodeType}</td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 overflow-hidden truncate ... max-w-[100px]'>
                        {node.audioUrl ? (
                          <a target='_blank' rel='noopener noreferrer' href={node.audioUrl}>
                            {node.audioUrl}
                          </a>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className='px-3 py-4 text-sm text-gray-500 max-w-[200px]'>{node.question || '-'}</td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {node.answers?.length || '-'}
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center flex flex-row  justify-evenly text-sm font-medium sm:pr-6 '>
                        <div
                          className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer'
                          onClick={() => {
                            navigate(`${window.location.pathname}/${Pages.Node}/${node.id}`);
                          }}
                        >
                          Edit
                        </div>
                        {isDetached(node) ? (
                          <div
                            className='text-indigo-600 hover:text-indigo-900 hover:cursor-pointer'
                            onClick={async () => {
                              await deleteNode(node);
                              setShow(false);
                            }}
                          >
                            Delete
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {nodes && (
        <div
          className='sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 h-[800px]'
          onContextMenu={(e) => e.preventDefault()}
        >
          <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
            Flow
          </label>
          <button onClick={() => setShow(false)}>Refresh</button>
          {show && (
            <ChallengeNodeFlow
              rawNodes={nodes}
              refresh={() => setShow(false)}
              updateNodes={updateNodes}
              locked={locked}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ChallengeNodeList;
