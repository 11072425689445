/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedGlobalSymbols

import { deleteField, Timestamp } from '../models/dalaccess';
import { BreadcrumbType } from '../types/types';
import stableStringify from 'json-stable-stringify';
import { OK, ParseReturnType, z, ZodError, ZodIssueCode, ZodType } from 'zod';
import { SchemaForObject } from '../modelschemas/shared';

// The deep-equal doesn't support module import syntax, so I'm just requiring it and then re-exporting it.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const _deepEqual = require('deep-equal');

interface DeepEqualOptions {
  strict: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqual(actual: any, expected: any, opts?: DeepEqualOptions): boolean {
  return _deepEqual(actual, expected, opts);
}

export const strip = (instr: string): string => instr.replace(/^\s+|\s+$/gm, '');
export const rstrip = (instr: string): string => instr.replace(/\s+$/gm, '');

// Takes one argument of any type, returns it.
export function identityFunction<T>(x: T): T {
  return x;
}

export const canParseToInt = (instr: string): boolean => {
  try {
    parseInt(instr);
  } catch {
    return false;
  }
  return true;
};

export const startsWithAnyOf = (instr: string, ...rest: string[]): boolean => {
  for (const potentialPrefix of rest) {
    if (instr.startsWith(potentialPrefix)) {
      return true;
    }
  }
  return false;
};

export function* flatten<T>(iterableOfIterables: Iterable<Iterable<T>>): Iterable<T> {
  for (const iterable of iterableOfIterables) {
    for (const item of iterable) {
      yield item;
    }
  }
}

export function filterNullUndefOut<T>(inval: T): boolean {
  return inval !== null && inval !== undefined;
}

export function dieIfNullOrUndef<T>(inval: T | null | undefined): NonNullable<T> {
  if (inval === null || inval === undefined) {
    throw new Error('dieIfNullOrUndef is throwing, as requested, because the value is null or undefined.');
  }
  return inval as NonNullable<T>;
}

export function dieIfNullUndefOrEmpty<T>(inval: T | null | undefined): NonNullable<T> {
  if (inval === null || inval === undefined) {
    throw new Error('dieIfNullUndefOrEmpty is throwing, as requested, because the value is null or undefined.');
  }
  if (Array.isArray(inval) && inval.length === 0) {
    throw new Error('dieIfNullUndefOrEmpty is throwing, as requested, because the array value is empty.');
  }
  if (typeof inval === 'string' && inval.length === 0) {
    throw new Error('dieIfNullUndefOrEmpty is throwing, as requested, because the string value is empty.');
  }
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const len = inval.length;
    if (len === 0) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error("dieIfNullUndefOrEmpty is throwing, as requested, because the value's length is 0.");
    }
  } catch {
    /* empty */
  }

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const len = inval.size;
    if (len === 0) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error("dieIfNullUndefOrEmpty is throwing, as requested, because the value's size is 0.");
    }
  } catch {
    /* empty */
  }

  return inval as NonNullable<T>;
}

export function singleElementOrDefault<T, D = null>(inArray: T[], orDefault: D | null = null): T | D | null {
  if (inArray.length === 1) {
    return inArray[0];
  }
  return orDefault;
}

export const setsAreEqual = <T>(xs: Set<T>, ys: Set<T>): boolean =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x));

// Return a new array made by removing duplicates from the array, using the "key" function, if
// provided, to munge values. This implementation keeps the first one of any duplicates found.
// See also: uniqByKeepLast (...but this is probably the one you want.)
export function uniqByKeepFirst<T>(a: Array<T>, key: (object: T) => unknown = (obj) => obj): Array<T> {
  const seen = new Set();
  return a.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

// Return a new array made by removing duplicates from the array, using the "key" function, if
// provided, to munge values. This implementation keeps the last one of any duplicates found.
// See also: uniqByKeepFirst (You probably want that one, anyway.)
export function uniqByKeepLast<T>(a: Array<T>, key: (object: T) => unknown = (obj) => obj): Array<T> {
  return [...new Map(a.map((x) => [key(x), x])).values()];
}

// This modifies an array, instead of creating a new one like uniqByKeepFirst and uniqByKeepLast.
// But as is evident, it does so by calling uniqByKeepFirst and then replacing the contents of the original
// array, so it does NOT avoid the creation of a new array. It could be modified to do so, but...
export function uniqInPlace<T>(a: Array<T>): void {
  const b: Array<T> = uniqByKeepFirst(a);
  a.length = 0;
  a.push(...b);
}

// Any keys specifically set to "undefined" (which is to say: the key is present in the object but its value
// is, literally, "undefined") -- which is different from the key merely being absent from the object --
// will be deleted from the object. This is important because there is a distinction between "this field is
// not in the doc" and "this field IS in the doc, but its value is set to undefined". See also: deleteUndefinedFields
// For clarity, deleteUndefinedFields is only pertinent w/r/t to database updates, whereas removeUndefinedFields
// might be generally useful for JavaScript objects, as well as prepping database updates.
export function removeUndefinedFields<T extends object>(byref: T): T {
  Object.keys(byref).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (byref[key] === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete byref[key];
    }
  });
  return byref;
}

// Any keys specifically set to "undefined" (which is to say: the key is present in the object but its value
// is, literally, "undefined") -- which is different from the key merely being absent from the object --
// will be replaced with the `deleteField` sentinel object, so that when it's written to Firestore, the field,
// if present in the Firestore document, will be deleted. This is important because there is a distinction
// between "this field is not in the doc" and "this field IS in the doc, but its value is set to undefined".
// See also: removeUndefinedFields
export function deleteUndefinedFields<T extends object>(byref: T): T {
  Object.keys(byref).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (byref[key] === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      byref[key] = deleteField();
    }
  });
  return byref;
}

// The following little gem allows us to wrap any function with a try/catch that logs any exceptions
// to console.error before re-throwing the error. Whoo, Typescript! (Note this is specifically an
// arrow function and not a function-function so that it doesn't clog up stack traces in the debugger.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const excWrap = <T extends Array<any>, U>(fn: (...args: T) => U) => {
  try {
    return (...args: T): U => fn(...args);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export function fileNameAndExt(
  inFile: string | null | undefined,
): [string | null | undefined, string | null | undefined] {
  if (!inFile) {
    return [inFile, inFile];
  }
  if (inFile.length === 0) {
    return ['', ''];
  }

  const lidxdot = inFile.lastIndexOf('.');

  if (lidxdot === -1 || lidxdot === 0) {
    return [inFile, ''];
  }

  const ext = inFile.substring(lidxdot);
  const fn = inFile.substring(0, lidxdot);
  return [fn, ext];
}

export function emptyCallback(): void {
  // NB: There is no way to make an arrow function that returns void apparently
  // https://stackoverflow.com/questions/48360422/why-cant-i-make-a-void-arrow-function
  return;
}

// Well, this is neat! This allows TypeScript to recognize that if we .filter an array with
// this predicate that the resultant array will be a string[] and nothing else. Cool!
export function filterPredIsString(item: unknown): item is string {
  return typeof item === 'string';
}

// When we make queries for multiple entities, we use the IN operator, which does not repect the order
// of the IDs in the input array. This takes the ordered array of IDs and the unordered array of entities,
// and returns a new array of entities in the order called for in the ordered array of IDs. It handles
// duplicates in the ID array. You can optionally ask it to raise if there's a discontinuity, but by
// default, it just soldiers on.
export const sortedEntityArrayFromIdArray = <IdT, DocT extends { id: IdT }>(
  ids: IdT[],
  entities: DocT[],
  throwIfMissing = false,
): DocT[] => {
  const rv: DocT[] = [];
  if (entities?.length) {
    const entByIdsMap = new Map(entities.map((ent) => [ent.id, ent]));
    for (const id of ids) {
      const ent = entByIdsMap.get(id);
      if (ent) {
        rv.push(ent);
      } else if (throwIfMissing) {
        throw new Error(`sortedEntityArrayFromIdArray: No entity with ID '${id}' found.`);
      }
    }
  }
  return rv;
};

export function entityArrayMatchesIdArrayExact<IdT, DocT extends { id: IdT }>(ids: IdT[], entities: DocT[]) {
  if (entities.length === 0 && ids.length === 0) {
    return true;
  } else if (entities.length === 0 || ids.length === 0 || entities.length !== ids.length) {
    return false;
  }
  for (let i = 0; i < ids.length; i += 1) {
    if (ids[i] !== entities[i].id) {
      return false;
    }
  }
  return true;
}

export function entitiesToIds<IdT, DocT extends { id: IdT }>(entities: DocT[]): IdT[] {
  return entities.map((d) => d.id);
}

export const emptyStringToNull = (instr?: string): string | null => (instr?.length ? instr : null);

export const emptyStringToUndef = (instr?: string): string | undefined => (instr?.length ? instr : undefined);

export const nullUndefToEmptyString = (instr?: string | null | undefined): string => (!instr ? '' : instr);

export const undefToNull = <T>(inObj: T | undefined): T | null => (inObj === undefined ? null : inObj);

export const undefOrEmptyStringToNull = <T>(inObj: string | undefined | null): string | null =>
  emptyStringToNull(nullUndefToEmptyString(inObj));

export const nullToUndef = <T>(inObj: T | null): T | undefined => (inObj === null ? undefined : inObj);

export const replaceUndef = <T>(inObj: T | undefined, defVal: T): T => (inObj === undefined ? defVal : inObj);

export const replaceNull = <T>(inObj: T | null, defVal: T): T => (inObj === null ? defVal : inObj);

export const replaceUndefNull = <T>(inObj: T | null | undefined, defVal: T): T =>
  inObj === null || inObj === undefined ? defVal : inObj;

export const appendBreadcrumbIfNecessary = (
  newCrumb: BreadcrumbType,
  breadcrumbPaths: BreadcrumbType[],
  setBreadcrumbPaths: (bc: BreadcrumbType[]) => void,
) => {
  if (JSON.stringify(breadcrumbPaths[breadcrumbPaths.length - 1]) !== JSON.stringify(newCrumb)) {
    setBreadcrumbPaths([...breadcrumbPaths, newCrumb]);
  }
};

export const hmsDurationToSeconds = (instr: string | null): number => {
  if (instr === null) {
    return 0;
  }
  let seconds = 0;
  const parts = instr.split(':').reverse();
  if (parts.length > 0) {
    seconds += parseInt(parts[0]);
  }
  if (parts.length > 1) {
    seconds += parseInt(parts[1]) * 60;
  }
  if (parts.length > 2) {
    seconds += parseInt(parts[2]) * 3600;
  }
  if (parts.length > 3) {
    throw new Error("hmsDurationToSeconds had more than 3 parts after splitting on ':'");
  }
  return seconds;
};

export const secondsDurationToHms = (secs: number): string => {
  return new Date(secs * 1000).toISOString().substring(11, 11 + '00:00:00'.length);
};

export function secondsDurationToHmsTolerant(secs: number | null | undefined, defaultVal = '00:00:00') {
  if (secs === null || secs === undefined || isNaN(secs)) {
    return defaultVal;
  }
  return secondsDurationToHms(secs);
}

export const jsonEquality = <T>(a: T, b: T): boolean => {
  if (a === b) {
    return true;
  }
  const jsa = stableStringify(a);
  const jsb = stableStringify(b);
  return jsa === jsb;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DefaultMap<K, V, A extends Array<any> = Array<any>> extends Map<K, V> {
  constructDefaultValue: () => V;

  constructor(getDefaultValue: () => V, ...mapCtorArgs: A) {
    super(mapCtorArgs);

    if (typeof getDefaultValue !== 'function') {
      throw new Error('getDefaultValue must be a function');
    }

    this.constructDefaultValue = getDefaultValue;
  }

  get = (key: K): V => {
    if (!this.has(key)) {
      this.set(key, this.constructDefaultValue());
    }
    const rv = super.get(key);
    return dieIfNullOrUndef(rv);
  };
}

export type HasToString = {
  toString: () => string;
};

// export type HasId<IdT> = {
//   id?: IdT;
// };
//
// export type HasIdRequired<IdT> = {
//   id: IdT;
// };

export const idToString = <IdT>(id: IdT): string => {
  const rv = (id as unknown as HasToString).toString();
  return rv;
};

export const idsToStrings = <IdT>(ids: IdT[]): string[] => {
  const rv = ids.map(idToString);
  return rv;
};

export const idNullToString = <IdT>(id?: IdT | null, ifNullUndef = ''): string => {
  return !id ? ifNullUndef : idToString(id);
};

// This function exists to keep us from doing this hack all over the place.
export function idFromString<IdT>(strId: string): IdT {
  if (!strId || strId.length === 0) {
    throw new Error('Trying to make a database ID from a null or empty string. This is likely not what you want.');
  }
  return strId as unknown as IdT;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectHasOwnProperty(obj: any, property: string): boolean {
  // I understand why we have to use the prototype call, but it's always felt
  // egregiously clunky to me, hence this function.
  try {
    return Object.prototype.hasOwnProperty.call(obj, property);
  } catch {
    return false;
  }
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export function idempotentRecursiveZodUnwrap<T extends z.ZodTypeAny>(inObj: T): z.ZodTypeAny {
  let walker = inObj;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  while (typeof walker.unwrap === 'function') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    walker = walker.unwrap();
  }
  return walker;
}

export function notNullish<T>(item: T | null | undefined): item is T {
  return item !== null && item !== undefined;
}

// export function mergeableIdSchema(idSchema: z.ZodType) {
//   return z.object({id: idSchema});
// }
//
// I feel like this should be possible, but it's not working, and I need to move on.
// export function schemaMakeIdRequired<S extends z.SomeZodObject>(inSchema: S) {
//   const idSchema = inSchema.shape["id"];
//   const nonOptionalIdSchema = idempotentRecursiveZodUnwrap(idSchema);
//   const withoutId = inSchema.omit({"id": true});
//   const requiringId = withoutId.merge(z.object({"id": nonOptionalIdSchema}));
//   return requiringId;
// }

export function validateObject<IdT, T extends { id: IdT }>(
  inObj: T,
  inSchema: SchemaForObject<T>,
  unrecognizedKeys?: Set<string>,
  otherIssues?: DefaultMap<T['id'], z.ZodIssue[]>,
): T {
  try {
    inSchema.strict().parse(inObj);
    return inObj;
  } catch (e) {
    const ze = e as unknown as ZodError;
    ze.issues.forEach((issue) => {
      if (issue.code === ZodIssueCode.unrecognized_keys && unrecognizedKeys) {
        issue.keys.forEach((k) => {
          const tok = typeof (inObj as Record<string, unknown>)[k];
          unrecognizedKeys.add(`${k} (${tok})`);
        });
      } else {
        if (otherIssues) {
          otherIssues.get(inObj.id).push(issue);
        }
      }
    });
    try {
      const outObj = inSchema.strip().parse(inObj);
      return outObj as unknown as T;
    } catch (e) {
      return inObj;
    }
  }
}

export function dumpDebugCollections<IdT, T extends { id: IdT }>(
  unrecognizedKeys?: Set<string>,
  otherIssues?: DefaultMap<T['id'], z.ZodIssue[]>,
  entity?: string,
) {
  // These are typically going to be more severe, like strings where numbers should be...
  if (otherIssues && otherIssues.size) {
    for (const id of otherIssues.keys()) {
      // console.warn(`Errors in document ID: ${id}: `);
      // Not sure why I need this cast, but...
      otherIssues.get(id as unknown as IdT).forEach((ze) => {
        // console.warn(ze);
      });
    }
  }

  // These are usually extra data that's been written to the db.
  if (unrecognizedKeys && unrecognizedKeys.size) {
    // console.log(
    //   `Keys in DB not in Type ${entity ? " (for entity '" + entity + "')" : ''}: ${[...unrecognizedKeys]
    //     .sort()
    //     .join(', ')}`,
    // );
  }
}

export function debugValidateObject<IdT, T extends { id: IdT }>(
  inObj: T,
  inSchema: SchemaForObject<T>,
  entity?: string,
): T {
  const unrecognizedKeys = new Set<string>();
  const otherIssues = new DefaultMap<T['id'], z.ZodIssue[]>(() => new Array<z.ZodIssue>());
  const vo = validateObject(inObj, inSchema, unrecognizedKeys, otherIssues);
  dumpDebugCollections(unrecognizedKeys, otherIssues, entity);
  return vo;
}

export class ZodTimestamp extends ZodType<Timestamp> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _parse(input: any): ParseReturnType<Timestamp> {
    const { ctx } = this._processInputParams(input);
    return OK(ctx.data);
  }
}

export const timestampType = () => new ZodTimestamp({});

export function getEnumFromString<T>(type: T, str: string): T[keyof T] {
  const casted = str as keyof T;
  return type[casted];
}

export function downloadProgramToFile(progName: string, progData: string) {
  // create file in browser
  const fileName = progName;
  const json = progData;
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);

  // Create an "a" HTML element with href to file
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.json';
  document.body.appendChild(link);
  link.click();

  // Clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
