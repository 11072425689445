// noinspection DuplicatedCode

import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  setNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import { AnalyticsId } from '../modeltypes/id';
import { ANALYTICS_TABLE_NAME } from './tableName';
import { AnalyticsType, AnalyticsTypeBase, AnalyticsTypeBuilder, AnalyticsTypeNew } from '../modeltypes/analytics';

// noinspection JSUnusedGlobalSymbols
export const getNewAnalyticsId = getNewIdFor<AnalyticsId>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewAnalytics = addNewFor<AnalyticsId, AnalyticsTypeNew>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewAnalytics = createNewFor<AnalyticsId, AnalyticsTypeBuilder>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const setNewAnalytics = setNewFor<AnalyticsId, AnalyticsTypeNew>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAnalyticsRefById = getRefByIdFor<AnalyticsId>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAnalyticsById = getByIdFor<AnalyticsId, AnalyticsType>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAnalyticsByIdArray = getByIdArrayFor<AnalyticsId, AnalyticsType>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllAnalytics = getAllFor<AnalyticsType>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAnalyticsByQuery = getByQueryFor<AnalyticsType>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllAnalyticsQuery = getAllQueryFor(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateAnalytics = updateDocumentFor<AnalyticsId, AnalyticsTypeBase>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteAnalytics = deleteDocumentFor<AnalyticsId>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchAnalyticsIdSet = watchIdSetFor<AnalyticsId>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAnalyticsType = getValidateTypeFor<AnalyticsType>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAnalyticsTypeBase = getValidateTypeBaseFor<AnalyticsTypeBase>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAnalyticsTypeBuilder = getValidateTypeBuilderFor<AnalyticsTypeBuilder>(ANALYTICS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateAnalyticsTypeNew = getValidateTypeNewFor<AnalyticsType>(ANALYTICS_TABLE_NAME);
