import { RESPIRATION_DATA_TABLE_NAME } from './tableName';
import { RespirationDataId, UserId } from '../modeltypes/id';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';
import {
  RespirationDataType,
  RespirationDataTypeBase,
  RespirationDataTypeBuilder,
  RespirationDataTypeNew,
} from '../modeltypes/respirationData';
import { idToString } from 'src/utility/GeneralUtilities';
import { where } from 'firebase/firestore';

// noinspection JSUnusedGlobalSymbols
export const getNewRespirationDataId = getNewIdFor<RespirationDataId>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewRespirationData = addNewFor<RespirationDataId, RespirationDataTypeNew>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewRespirationData = createNewFor<RespirationDataId, RespirationDataTypeBuilder>(
  RESPIRATION_DATA_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const getRespirationDataRefById = getRefByIdFor<RespirationDataId>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getRespirationDataById = getByIdFor<RespirationDataId, RespirationDataType>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getRespirationDataByIdArray = getByIdArrayFor<RespirationDataId, RespirationDataType>(
  RESPIRATION_DATA_TABLE_NAME,
);

export const getAllRespirationData = getAllFor<RespirationDataType>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getRespirationDataByQuery = getByQueryFor<RespirationDataType>(RESPIRATION_DATA_TABLE_NAME);

export const getRespirationDataByUserId = async (userId: UserId) => {
  const respirationData = await getRespirationDataByQuery(where('userId', '==', idToString(userId)));
  // console.log('returning respirationData: ', respirationData );
  return respirationData;
};

// noinspection JSUnusedGlobalSymbols
export const getAllRespirationDataQuery = getAllQueryFor(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateRespirationData = updateDocumentFor<RespirationDataId, RespirationDataTypeBase>(
  RESPIRATION_DATA_TABLE_NAME,
);

// noinspection JSUnusedGlobalSymbols
export const deleteRespirationData = deleteDocumentFor<RespirationDataId>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchRespirationDataIdSet = watchIdSetFor<RespirationDataId>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRespirationDataType = getValidateTypeFor<RespirationDataType>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRespirationDataTypeBase =
  getValidateTypeBaseFor<RespirationDataTypeBase>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRespirationDataTypeBuilder =
  getValidateTypeBuilderFor<RespirationDataTypeBuilder>(RESPIRATION_DATA_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateRespirationDataTypeNew = getValidateTypeNewFor<RespirationDataType>(RESPIRATION_DATA_TABLE_NAME);
