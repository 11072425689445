/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod';
import { coachIdSchema, userIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { CoachType, CoachTypeBase, CoachTypeBuilder, CoachTypeNew } from '../modeltypes/coach';

export const coachTypeSchema = z.object({
  id: coachIdSchema,
  calendlyUrl: z.string().optional(),
  name: z.string(),
  organizationName: z.string().optional(),
  description: z.string().optional(),
  imageUrl: z.string().optional(),
  comments: z.array(z.string()).optional(),
  userId: userIdSchema.optional(),
  rating: z.array(z.string()).optional(),
});

export const coachTypeBaseSchema = coachTypeSchema.partial();

export const coachTypeBuilderSchema = coachTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: coachIdSchema.default(() => IdFactory.Coach()) }));

export const coachTypeNewSchema = coachTypeSchema.omit({ id: true }).merge(z.object({ id: coachIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: CoachType = coachTypeSchema.parse(undefined);
  const x: CoachTypeBase = coachTypeBaseSchema.parse(undefined);
  const y: CoachTypeBuilder = coachTypeBuilderSchema.parse(undefined);
  const z: CoachTypeNew = coachTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
