// *******************************************************
// Lesson list
// -------------------------------------------------------
// This is a component for listing all lessons in the app
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAllLessons } from '../../collections/lesson';
import { Pages } from '../../enums/pages';
import { useNavigate } from 'react-router-dom';
import { deleteLessonRecursive } from '../../collections/relationship';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import Skeleton from 'react-loading-skeleton';
import { LessonId } from '../../modeltypes/id';
import { LessonType } from '../../modeltypes/lesson';
import SearchBar from '../SearchBar';
import { getAllPrograms } from '../../collections/program';
import { ProgramType } from '../../modeltypes/program';
import ReactTooltip from 'react-tooltip';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
type LessonTypeWithProgramsArray = LessonType & {
  referencingPrograms?: ProgramType[];
};

export const LessonList = () => {
  const navigate = useNavigate();
  const [lessons, setLessons] = useState<LessonTypeWithProgramsArray[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<TagType[] | null>(null);

  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    console.debug('LessonList.useEffect called.');
    const fetchData = async () => {
      const fetchedLessons = (await getAllLessons()) as LessonTypeWithProgramsArray[];
      fetchedLessons.sort((a, b) => {
        const aUpdated = a.updatedAt ? a.updatedAt.toMillis() : 0;
        const bUpdated = b.updatedAt ? b.updatedAt.toMillis() : 0;
        return aUpdated < bUpdated ? 1 : aUpdated > bUpdated ? -1 : 0;
      });

      // Build a "back-map" so we can give each lesson a list of the programs that reference it.
      fetchedLessons.forEach((l) => {
        l.referencingPrograms = [];
      });
      const lessonsById = new Map(fetchedLessons.map((l) => [l.id, l]));

      // Since we don't expect the programs table to get very big, and for the lessons table to
      // grow much bigger relative to programs, it's more efficient to just get them all, instead of
      // doing N 'array-contains' queries.
      const allPrograms = await getAllPrograms();
      allPrograms.forEach((p) => {
        p.lessons &&
          p.lessons.forEach((plid) => {
            const lesson = lessonsById.get(plid);
            lesson && dieIfNullOrUndef(lesson.referencingPrograms).push(p);
          });
      });

      setLessons(fetchedLessons);
    };
    fetchData()
      .then(() => setIsLoading(false))
      .catch(console.error);
  }, [setLessons]);

  async function deleteLessonId(lessonId: LessonId) {
    await deleteLessonRecursive(lessonId);
    const fetchData = async () => {
      const fetchedLessons = await getAllLessons();
      setLessons(fetchedLessons);
    };
    fetchData().catch(console.error);
  }

  const onClickDelete = (lessonId: string) => {
    confirmAlert({
      title: `Confirm delete lesson (ID: ${lessonId})?`,
      message: 'Are you sure you want to delete this lesson? This operation cannot be undone.',
      buttons: [
        {
          label: 'Delete',
          onClick: async () => {
            await deleteLessonId(lessonId);
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  };

  function programsElement(lesson: LessonTypeWithProgramsArray) {
    const progs = lesson.referencingPrograms || [];
    if (progs.length === 0) {
      return <span>None</span>;
    } else if (progs.length === 1) {
      return <span>{progs[0].title}</span>;
    } else if (progs.length > 1) {
      const titles = progs.map((p) => '&#x2022; ' + p.title).join('\n<br/>');
      return (
        <>
          <p data-html={true} data-tip={titles}>
            Multiple...
          </p>
          <ReactTooltip />
        </>
      );
    }
  }

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Icon
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Title
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Description
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Tags
                      </th>

                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Status
                      </th>
                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Is Locked?
                      </th>
                      <th scope='col' className='px-3 py-3.5  text-sm font-semibold text-gray-900 text-center'>
                        Programs
                      </th>
                      <th
                        scope='col'
                        className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-semibold sm:pr-6'
                      >
                        Edit
                      </th>
                      <th
                        scope='col'
                        className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-semibold sm:pr-6'
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {lessons?.map((lesson) => (
                      <tr key={lesson.id} className='hover:bg-stone-200'>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='h-10 w-10 flex-shrink-0'>
                            <img className='h-10 w-10 rounded-full' src={lesson.icon || undefined} alt='' />
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{lesson.title}</div>
                        </td>
                        <td className=' px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500 flex flex-wrap w-[350px]'>{lesson.description}</div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {lesson?.tags?.length ? (
                                <div>
                                  {lesson.tags?.map((tag) => (
                                    <div key={tag || ''} className='text-gray-500 p-1 m-1 border rounded'>
                                      {tags?.find((t) => t.id === tag)?.name}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className='text-gray-500'></div>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{lesson.productionStatus || 'WIP'}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              {lesson.locked ? <CheckIcon className='h-4 w-4' /> : <XIcon className='h-4 w-4' />}
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                          {programsElement(dieIfNullOrUndef(lesson))}
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                          <div
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => navigate(`/${Pages.Lessons}/${lesson.id}`)}
                          >
                            Edit
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 '>
                          <button
                            disabled={lesson.productionStatus === 'PROD' || lesson.locked}
                            className={`${
                              lesson.productionStatus === 'PROD' || lesson.locked ? 'opacity-25' : 'opacity-100'
                            } text-indigo-600 hover:text-indigo-900`}
                            onClick={() => onClickDelete(dieIfNullOrUndef(lesson.id))}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
