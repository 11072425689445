export const ACCESS_CODE_TABLE_NAME = 'AccessCodes';
export const ACCESS_REQUEST_TABLE_NAME = 'AccessRequests';
export const ACCESS_TABLE_NAME = 'Access';
export const ACCOUNT_TABLE_NAME = 'Account';
export const ADMIN_PAGE_TABLE_NAME = 'AdminPage';
export const AFTER_SESSION_SURVEY_TABLE_NAME = 'AfterSessionSurvey';
export const AUDIO_NODE_TABLE_NAME = 'AudioNodes';
export const AUDIO_RECORDING_WHITELIST_TABLE_NAME = 'AudioRecordingWhitelist';
export const CALENDAR_TABLE_NAME = 'Calendar';
export const CHAPTER_TABLE_NAME = 'Chapters';
export const COACH_TABLE_NAME = 'Coaches';
export const PATH_TYPE_TABLE_NAME = 'PathTypes';
export const TAGS_TABLE_NAME = 'Tags';
export const TRACK_TABLE_NAME = 'Tracks';
export const TASKS_TABLE_NAME = 'Tasks';
export const APP_UPDATE_INFO_TABLE_NAME = 'AppUpdateInfo';
export const EMAIL_TABLE_NAME = 'Emails';
export const CHALLENGE_SWITCH_TABLE_NAME = 'ChallengeSwitches';
export const REWARDS_TABLE_NAME = 'Rewards';
export const QUIZZES_TABLE_NAME = 'QuizItems';
export const CHALLENGES_TABLE_NAME = 'Challenges';
export const TASK_SWITCH_TABLE_NAME = 'TaskSwitches';
export const COHORT_MEETING_TABLE_NAME = 'CohortMeetings';
export const COHORT_TABLE_NAME = 'Cohort';
export const COMPANY_TABLE_NAME = 'Company';
export const DAILY_DIARY_TABLE_NAME = 'DailyDiaries';
export const INCENTIVE_TABLE_NAME = 'Incentives';
export const INCENTIVE_TEMPLATE_TABLE_NAME = 'IncentivesTemplate';
export const LESSON_HOMEWORK_TABLE_NAME = 'LessonHomework';
export const LESSON_PROGRESS_TEMPLATE_TABLE_NAME = 'LessonProgressTemplate';
export const LESSON_RATING_TABLE_NAME = 'LessonRatings';
export const LESSON_TABLE_NAME = 'Lessons';
export const MAIL_TABLE_NAME = 'mail';
export const NOTIFICATION_TEMPLATE_TABLE_NAME = 'NotificationTemplates';
export const PROGRAM_TABLE_NAME = 'Program';
export const REMOTE_CONFIG_TABLE_NAME = 'RemoteConfig';
export const SCIENCE_DATA_TABLE_NAME = 'ScienceData';
export const RESPIRATION_DATA_TABLE_NAME = 'RespirationData';
export const SESSIONS_TABLE_NAME = 'Sessions';
export const STATISTICS_DATA_TABLE_NAME = 'StatisticsData';
export const SURVEY_TABLE_NAME = 'Survey';
export const USER_TABLE_NAME = 'User';
export const NODE_TABLE_NAME = 'Nodes';
export const GOALS_TABLE_NAME = 'Goals';
export const UNIT_TABLE_NAME = 'Units';
export const NOTIFICATIONS_TABLE_NAME = 'Notifications';
export const USER_JOURNEY_TABLE_NAME = 'UserJourney';
export const USER_REWARDS_TABLE_NAME = 'UserRewards';
export const NODE_PROGRESS_TABLE_NAME = 'UserNodesProgress';
export const FUNCTION_RUNS_TABLE_NAME = 'FunctionRuns';
export const LESSON_AC_TABLE_NAME = 'LessonAudioChunks';
export const LESSON_AP_TABLE_NAME = 'LessonAudioPractice';
export const DETAILED_SESSIONS_TABLE_NAME = 'DetailedSessions';
export const LEARNING_GAME_TABLE_NAME = 'LearningGames';
export const COMMUNICATION_TABLE_NAME = 'Communication';
export const ANALYTICS_TABLE_NAME = 'Analytics';

export const COMMUNICATIONS_TABLE_NAME = 'Communication';
export const SUBSCRIPTIONS_TABLE_NAME = 'Subscriptions';
export const STREAKS_TABLE_NAME = 'Streaks';
export const USER_LEADER_BOARD_TABLE_NAME = 'UserLeaderBoard';
