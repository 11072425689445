// *******************************************************
// Lesson Node Flow
// -------------------------------------------------------
// This is a Component for lesson paths presentation
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  addEdge,
  Connection,
  Controls,
  Edge,
  Node,
  updateEdge,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import { allPossibleDestinations } from '../../utility/ScriptUtilities';
import { AudioNodeType } from '../../modeltypes/audioNode';
import { NodeId } from '../../modeltypes/id';
import { DEFAULT_NODE_HEIGHT, DEFAULT_NODE_WIDTH, laidOutNodes } from '../../utility/FlowUtilities';
import AudioLessonStoryCustomNode, { StoryNodeProps } from './nodes/AudioLessonStoryCustomNode';
import SwitchCustomNode, { SwitchNodeProps } from './nodes/SwitchCustomNode';
import DecisionCustomNode, { DecisionNodeProps, TAPS } from './nodes/DecisionCustomNode';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { ModalType } from '../paths/sharedValues';
import LessonFlowModal, { LessonModalContentType } from './LessonFlowModal';
import { updateAudioNode } from '../../collections/audioNode';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

interface LessonNodeFlowProps {
  rawNodes: AudioNodeType[];
  refresh: () => void;
  updateNodes: (nodeIds: string[]) => void;
  // refresh: () => void;
  // updateNodes: (nodeIds: string[]) => void;
  locked: boolean;
}

export type LessonCustomNodeProps = StoryNodeProps | DecisionNodeProps | SwitchNodeProps;

const nodeTypes = {
  STORY: AudioLessonStoryCustomNode,
  SWITCH: SwitchCustomNode,
  DECISION: DecisionCustomNode,
  default: AudioLessonStoryCustomNode,
};

// const editConnection = ({
//   sourceId,
//   targetId,
//   isDeleting = false,
// }: {
//   sourceId: string;
//   targetId: string;
//   isDeleting?: boolean;
// }) => {
//
//   const data = isDeleting ? { nextNode: null } : { nextNode: targetId };
//   return updateAudioNode(sourceId, data);
// };

const editConnection = ({
  sourceId,
  type,
  targetId,
  isDeleting = false,
}: {
  sourceId: string;
  type: string;
  targetId: string;
  isDeleting?: boolean;
}) => {
  const data = isDeleting ? { [type]: null } : { [type]: targetId };

  console.log(sourceId, data);
  return updateAudioNode(sourceId, data);
};

const LessonNodeFlow = ({ updateNodes, rawNodes, refresh, locked }: LessonNodeFlowProps) => {
  // Build some 'utility maps'
  const [flowModalShown, setFlowModalShown] = useState(false);
  const [modalContent, setModalContent] = useState<LessonModalContentType | null>(null);

  const nodeRef = useRef<Node<LessonCustomNodeProps> | null>(null);

  const nodeMap = new Map<NodeId, AudioNodeType>();
  const nodeIds: string[] = [];
  const seenNodeIds = new Set<NodeId>();

  rawNodes.forEach((an) => {
    nodeMap.set(dieIfNullOrUndef(an.id), an);
    if (an.code) {
      if (!seenNodeIds.has(an.code)) {
        nodeIds.push(dieIfNullOrUndef(an.id));
        seenNodeIds.add(dieIfNullOrUndef(an.id));
      } else {
        console.warn(
          `Subsection code: '${an.code}' is found on more than one node in the lesson. Only the first will be graphed.`,
        );
      }
    } else {
      console.warn(`Node ID: ${an.id} has no subsection code, and will be orphaned.`);
    }
  });

  const showModal = (node: AudioNodeType | null) => (modalType: ModalType) => {
    if (node) {
      setFlowModalShown(true);
      setModalContent({
        node: node,
        type: modalType,
      });
    }
  };

  // Collect Nodes
  const nodesInitial: Node[] = rawNodes.map((audioNode, index) => ({
    id: dieIfNullOrUndef(audioNode.id),
    data: {
      node: audioNode,
    },
    type: audioNode.nodeType || 'default',
    position: {
      x: DEFAULT_NODE_WIDTH,
      y: DEFAULT_NODE_HEIGHT * 2 * index,
    },
    dragHandle: '.custom-drag-handle',
    connectable: !locked,
  }));

  // Collect Edges
  const edgesInitial: Edge[] = [];
  nodeIds.forEach((nodeId) => {
    const audioNode = dieIfNullOrUndef(nodeMap.get(nodeId));
    const allDsts = allPossibleDestinations(audioNode);
    for (const dst of allDsts) {
      edgesInitial.push({
        id: `${nodeId}_${dst.target}`,
        source: nodeId,
        target: dst.target,
        sourceHandle: dst.sourceHandle,
        type: 'smoothstep',
        // So... deleteEdge was an initial attempt to allow graph editing.
        // Maybe in the fullness of time, but certainly not now.
        // type: 'deleteEdge',
      });
    }
  });

  // Do some real layout...
  const positionedNodesInitial = laidOutNodes(nodesInitial, edgesInitial, 250, 450);

  const [nodes, setNodes, onNodesChange] = useNodesState(positionedNodesInitial);
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgesInitial);

  const edgeUpdateSuccessful = useRef(true);

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  useEffect(() => {
    if (!flowModalShown) {
      setModalContent(null);
    }
  }, [flowModalShown]);

  useEffect(() => {
    setNodes((prevState) =>
      prevState.map((node) => {
        const nodeToUpdate = rawNodes.find((n) => n.id === node.id);
        return {
          ...node,
          data: {
            node: nodeToUpdate || node.data.node,
            showModal: showModal(nodeToUpdate || node.data.node),
          },
        };
      }),
    );
  }, [rawNodes]);

  const onEdgeUpdateEnd = useCallback((_: any, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      const source = edge.source ? nodeMap.get(edge.source) : null;
      const target = edge.target ? nodeMap.get(edge.target) : null;

      console.log(edge);
      const isSpecial = !!edge.sourceHandle;
      const tap = TAPS.find((el) => el.id === edge.sourceHandle);

      const alertData: any = {};
      if (isSpecial && tap && source && target) {
        alertData.message = `Do you want to delete connection - "${tap.text}" of ${source.code} with ${target.code}?`;
        alertData.function = async () => {
          await editConnection({
            type: tap.id,
            targetId: target.id,
            sourceId: source.id,
            isDeleting: true,
          });
          updateNodes([source.id, target.id]);
        };
      } else if (!isSpecial && source && target) {
        alertData.message = `Are you sure you want to delete connection ${source.code} with ${target.code}?`;
        alertData.function = async () => {
          await editConnection({
            type: 'nextSubsection',
            targetId: target.id,
            sourceId: source.id,
            isDeleting: true,
          });
          updateNodes([source.id, target.id]);
        };
      }
      if (alertData) {
        confirmAlert({
          title: `Confirm deletion`,
          message: alertData.message,
          buttons: [
            {
              label: 'Delete',
              onClick: async () => {
                await toast
                  .promise(
                    () => alertData.function(),

                    {
                      pending: 'Deleting connection',
                      success: 'Connection Deleted',
                      error: "Can't delete this connection now..",
                    },
                  )
                  .then(() => {
                    setEdges((eds) => eds.filter((e) => e.id !== edge.id));
                  });
                edgeUpdateSuccessful.current = true;
              },
            },
            {
              label: 'Cancel',
            },
          ],
        });
      }
    }
  }, []);

  const onConnect = useCallback((params: Edge | Connection) => {
    const source = params.source ? nodeMap.get(params.source) : null;
    const target = params.target ? nodeMap.get(params.target) : null;

    const isSpecial = !!params.sourceHandle;
    const tap = TAPS.find((el) => el.id === params.sourceHandle);

    const alertData: any = {};
    if (isSpecial && tap && source && target) {
      alertData.message = `Do you want to connect "${tap.text}" of ${source.code} with ${target.code}?`;
      alertData.function = async () => {
        await editConnection({
          type: tap.id,
          targetId: target.id,
          sourceId: source.id,
        });
        updateNodes([source.id, target.id]);
      };
    } else if (!isSpecial && source && target) {
      alertData.message = `Are you sure you want to connect ${source.code} with ${target.code}?`;
      alertData.function = async () => {
        await editConnection({
          type: 'nextSubsection',
          targetId: target.id,
          sourceId: source.id,
        });
        updateNodes([source.id, target.id]);
      };
    }
    if (alertData) {
      confirmAlert({
        title: `Confirm connection`,
        message: alertData.message,
        buttons: [
          {
            label: 'Connect',
            onClick: async () => {
              await toast
                .promise(() => alertData.function(), {
                  pending: 'Connecting nodes',
                  success: 'Nodes connected!',
                  error: "Can't connect it now..",
                })
                .then(() => {
                  setEdges((els) =>
                    addEdge(
                      {
                        ...params,
                        id: `${params.source}_${params.target}`,
                        type: 'smoothstep',
                      },
                      els,
                    ),
                  );
                });
            },
          },
          {
            label: 'Cancel',
          },
        ],
      });
    }
  }, []);

  // add node after

  const addNodeAfter = (node: Node<LessonCustomNodeProps>, newNode: AudioNodeType) => {
    const newNodeAfter: Node<LessonCustomNodeProps> = {
      id: newNode.id,
      // we are removing the half of the node width (75) to center the new node
      data: {
        node: newNode,
        showModal: showModal(newNode),
      },
      type: newNode.nodeType || 'default',
      position: {
        x: node.position.x,
        y: node.position.y + (node.height || 300) + 75,
      },
    };

    setNodes((nds) => nds.concat(newNodeAfter));
    setEdges((eds) =>
      eds.concat({
        id: `${node.id}_${newNode.id}`,
        source: node.id,
        target: newNode.id,
      }),
    );
    // updateNode(node.id, { nextNode: newNode.id });
    updateNodes([newNode.id, node.id]);
    // refresh();
  };

  const flowProps = locked
    ? {}
    : {
        onEdgesChange: onEdgesChange,
        onEdgeUpdate: onEdgeUpdate,
        onEdgeUpdateStart: onEdgeUpdateStart,
        onEdgeUpdateEnd: onEdgeUpdateEnd,
        onConnect: onConnect,
      };

  return (
    <>
      <ReactFlow
        nodeTypes={nodeTypes}
        fitView
        nodes={nodes}
        style={{
          backgroundColor: '#EEE',
          borderRadius: 20,
        }}
        edges={edges}
        onNodesChange={onNodesChange}
        snapToGrid
        onNodeClick={(e, node: Node<LessonCustomNodeProps>) => (nodeRef.current = node)}
        {...flowProps}
      >
        <Controls />
      </ReactFlow>
      {flowModalShown && nodeRef.current && (
        <LessonFlowModal
          content={modalContent}
          hide={(shouldRefresh?: boolean) => {
            setFlowModalShown(false);
            if (shouldRefresh) {
              refresh();
            }
          }}
          updateNodes={updateNodes}
          addNodeAfter={addNodeAfter}
          eventCallerNode={nodeRef.current}
        />
      )}
    </>
  );
};

export default LessonNodeFlow;
