export default {
  primary: '#299CF9', // "#000000ff",
  secondary: '#AAC8E0', // "#000000bf",
  tertiary: '#4bb9ff', // "#00000080",
  quaternary: '#D1EAFF', // "#00000040",
  quinary: '#D9EDFD66',
  grayText: '#C4C4C4',
  black: '#000000',
  disabledBlack: '#00000020',
  white: '#ffffff',
  whiteOpacity12: 'rgba(255,255,255,0.12)',
  whiteOpacity60: 'rgba(255,255,255,0.60)',
  white30: 'rgba(255,255,255,0.30)',
  medium: '#a2a2a2',
  light: '#F8F9FE',
  dark: '#0c0c0c',
  extralight: '#f7fafe',
  danger: '#DC1919',
  lightblue: '#7DA3EA',
  darkblue: '#0071CC',
  blue: '#0087F3',
  greyblue: '#217CC6',
  buttonBlue: '#0E93FF',
  buttonBlue13: 'rgba(14,147,255,0.13)',
  lightpurple: '#F3F5FF',
  yellow: '#F5C371',
  mood: '#888DFF',
  sleep: '#4E5BA9',
  sleepOpacity: 'rgba(78,91,169,0.33)',
  sleeplight: '#7E8DE7',
  focus: '#7ADFED',
  disabledBorder: '#E2E2E233',
  blueDark: '#0675D0',
  blueDarkDisabled: '#0675D020',
  darkGray: '#565656',
  blackPallet: '#121212',
  lightBorder: '#BEC8FF',
  lightBorderOpacity: 'rgba(190,200,255,0.38)',
  lightBlueBorder: '#D9EDFD',
  switchTrackColor: '#abd9ff',
  error: '#DC1919',
  accLightBlueL: '#BEE2FF',
  veryLightBlue: '#90D4E9',
  frost: '#ebf5ff',
  frostFocus: '#cce9ff',
  mediumGray: '#8a8a8a',
  mediumGrayOpacity: 'rgba(138,138,138,0.42)',
  blackOpacity: 'rgba(0,0,0,0.55)',
  blackOpacity70: 'rgba(0,0,0,0.70)',
  blackOpacity13: 'rgba(0,0,0,0.13)',
  blackOpacity20: 'rgba(0,0,0,0.20)',

  grayOpacity: 'rgba(0,0,0,0.25)',
  lightYellow: '#FFD694',
  mediumYellow: '#D08100',
  lightGreen: '#B8DF99',
  darkRed: '#A04040',
  mediumRed: '#C36B6B',
  mediumGreen: '#6BA040',
  lightGray: '#EBEBEB',
  gray: '#8F8F8F',
  gray50: '#F7F7F7',
  gray100: '#E1E1E1',
  gray200: '#CFCFCE',
  gray300: '#B1B1B1',
  gray350: '#BABABA',
  gray400: '#9E959E',
  gray500: '#7E7E7E',
  gray600: '#626262',
  gray700: '#515151',
  gray800: '#3B3B3B',
  gray900: '#222222',
  aliceBlue: '#F0F8FE',
  lightGrey: '#D9D9D9',
  accLightBlue30: '#BEE2FF4D',
  lightsteelblue: '#A2C1DA',
  mineShaft: '#343434',
  darkslategray: '#383838',
  darkSlateGray: '#2E2E2E',
  whitesmoke: '#F8F8F8',
  whitesmoke2: '#F3F3F3',
  shark: '#1E1E1E',
  salmon: '#FF886D',
  mischka: '#D0D1DD',
  cadetblue: '#74B1A2',
  darkestSlateGray: '#1E1E1E',
  zircon: '#F1F6FD',
  chetwodeBlue: '#7488EC',
  slateGray: '#7C7D94',
  woodSmoke: '#181818',
  havelockBlue: '#5D93E0',
  mediumaquamarine: '#41BEA3',
  hint: '#FFECDA',
  dogerBlue: '#3282E6',
  cornflowerBlue: '#4F95ED',
};
