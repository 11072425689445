import { z } from 'zod';
import { Timestamp } from '../models/dalaccess';
import { canParseToInt, dieIfNullOrUndef, HasToString } from '../utility/GeneralUtilities';

// noinspection JSUnusedGlobalSymbols
export class ZodTimestamp extends z.ZodType<Timestamp> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _parse(input: any): z.ParseReturnType<Timestamp> {
    const { ctx } = this._processInputParams(input);
    const rv = z.OK(ctx.data);
    return rv;
  }
}

export const timestampType = () => new ZodTimestamp({});

export const productionStatusValueSchema = z.union([z.literal('DEV'), z.literal('PROD')]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SchemaForObject<T> = z.ZodObject<z.ZodRawShape, 'strip', z.ZodTypeAny, T, any>;

export function coerceInteger(ageVal: unknown): number | undefined {
  if (typeof ageVal === 'number') {
    return ageVal;
  }

  try {
    const sAge = ageVal as HasToString;
    const rv = sAge && canParseToInt(sAge.toString()) ? parseInt(sAge.toString()) : undefined;
    return rv;
  } catch {
    return undefined;
  }
}

export function coerceIntegerOrDie(ageVal: unknown): number {
  return dieIfNullOrUndef(coerceInteger(ageVal));
}
