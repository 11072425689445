// *******************************************************
// UserRewards
// -------------------------------------------------------
// This is a UserRewards
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getUserById } from '../../collections/user';
import { useQuery } from '@tanstack/react-query';
import { UserId } from 'src/modeltypes/id';

// import { prepareCSV, ResearchCSVType } from '../../utility/csvUtility';
// import { format } from 'date-fns';
import { getAllTracks } from 'src/collections/tracks';
import { TrackType } from 'src/modeltypes/tracks';
import { UnitType } from 'src/modeltypes/unit';
import { getAllUnits } from 'src/collections/units';
import { getUserRewardsById } from 'src/collections/userRewards';
import { RewardType } from 'src/modeltypes/rewards';
import { getAllRewards } from 'src/collections/rewards';
import { format } from 'date-fns';
import { Achievement, Progress } from 'src/modeltypes/userRewards';
import { getAllLessonAudioChunks } from 'src/collections/lessonAudioChunk';
import { LessonAudioChunkType } from 'src/modeltypes/lessonAudioChunk';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
// Auto-generated by Firefoo v1.5.11 on 2024-08-08 21:34:51 UTC

const UserRewards = () => {
  const { id } = useParams();

  const [tracks, setTracks] = useState<TrackType[] | null>(null);
  const [units, setUnits] = useState<UnitType[] | null>(null);
  const [rewards, setRewards] = useState<RewardType[] | null>(null);
  const [audioChunks, setAudioChunks] = useState<LessonAudioChunkType[] | null>(null);

  const { data: user, refetch: refetchUser } = useQuery({
    queryFn: () => getUserById(id),
    queryKey: ['user'],
    initialData: null,
  });

  const { data: rewardData, refetch: refectchRewardpData } = useQuery({
    queryFn: () => getUserRewardsById(id as UserId),
    queryKey: ['rewardData'],
    initialData: null,
  });

  console.log('rewardData: ', rewardData);
  console.log('user: ', user);
  console.log('tracks: ', tracks);
  console.log('units: ', units);
  console.log('rewards: ', rewards);

  const awards = rewardData?.awards || {};

  const awardValues = Object.values(awards);
  const awardKeys = Object.keys(awards);

  useEffect(() => {
    getAllTracks().then((fTracks) => setTracks(fTracks));
    getAllUnits().then((fUnits) => setUnits(fUnits));
    getAllRewards().then((fUnits) => setRewards(fUnits));
    getAllLessonAudioChunks().then((fTracks) => setAudioChunks(fTracks));
  }, []);

  if (!id) {
    return null;
  }

  // return (
  //   <div>
  //     <div>
  //       <p>User:</p>
  //       <p className='font-bold text-xl'>{user?.email}</p>
  //       <p className='font-bold text-xl'>{user?.id}</p>
  //     </div>
  //     <p>Refresh</p>
  //     <button
  //       className='ml-2 font-bold text-red-600 text-lg p-20'
  //       onClick={() => {
  //         refetchUser();
  //         refectchRewardpData();
  //       }}
  //     >
  //       Refresh This Beach
  //     </button>
  //   </div>
  // );

  return (
    <div>
      <div>
        <p>User:</p>
        <p className='font-bold text-xl'>{user?.email}</p>
        <p className='font-bold text-xl'>{user?.id}</p>

        <p className='font-bold text-xl'>
          {user?.firstName} {user?.lastName}
        </p>
      </div>
      <p>Rewards Last Updated at</p>
      {rewardData?.createdAt && (
        <div className='ml-4'>
          <div className='text-gray-500'>
            {format(rewardData?.createdAt?.toDate() || Date.now(), 'dd MMMM  yy, HH:mm')}
          </div>
        </div>
      )}

      <div className='my-5'>
        <button
          className='ml-2 font-bold text-gray-600 text-lg hover:text-blue-500 hover:cursor-pointer py-3 p-20 border-1 border-gray-300 px-10, py-5 border rounded-xl bg-gray-100 shadow ring-1 ring-black ring-opacity-5'
          onClick={() => {
            null;
          }}
        >
          Calculate Rewards
        </button>
      </div>

      <div className='my-5'>
        <button
          className='ml-2 font-bold text-gray-600 text-lg p-20 hover:text-blue-500 hover:cursor-pointer py-3 border-1 border-gray-300 px-10, py-5 border rounded-xl bg-gray-100 shadow ring-1 ring-black ring-opacity-5'
          onClick={() => {
            refetchUser();
            refectchRewardpData();
          }}
        >
          Refresh data
        </button>
      </div>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Reward</p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Created At</p>
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Progress</p>
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Achievements</p>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {awardValues.length &&
                    awardValues.map((awardKey, index) => {
                      const thisAward = awardKey;
                      const progress: Progress[] = awardKey?.progress;
                      const achievements: Achievement[] = awardKey?.achievements || [];

                      console.log('thisAward', thisAward);
                      console.log('progress', progress);
                      console.log('achievements', achievements);
                      return (
                        <tr key={index} className='hover:bg-stone-200'>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{awardKeys[index]}</div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'></div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {progress?.length ? (
                                  <div>
                                    {progress?.map((prog, index) => {
                                      const track = tracks?.find((track) => track.id === prog?.trackId);
                                      const unit = units?.find((unit) => unit.id === prog?.unitId);
                                      const node = audioChunks?.find((audioLesson) => audioLesson.id === prog?.nodeId);

                                      console.log('track', track);
                                      console.log('unit', unit);
                                      console.log('node', node);

                                      return (
                                        <div
                                          key={`progress${index}`}
                                          className='text-gray-500 p-1 m-1 border rounded'
                                          style={{ flexDirection: 'column' }}
                                        >
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                            <div className='flex items-center'>track - {track?.title}</div>
                                          </td>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                            <div className='flex items-center'>unit - {unit?.title}</div>
                                          </td>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                            <div className='flex items-center'>node - {node?.title}</div>
                                          </td>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className='text-gray-500'> - </div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                {achievements?.length ? (
                                  <div>
                                    {achievements?.map((achievement, index) => (
                                      <div key={`achievement${index}`} className='text-gray-500 p-1 m-1 border rounded'>
                                        level - {achievement?.level}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className='text-gray-500'> - </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <button
        className='mt-5 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        onClick={() => {
          return null;
        }}
      ></button>
    </div>
  );
};

export default UserRewards;
