/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { getGoalById, updateGoal, addNewGoal } from '../../collections/goals';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { GoalType } from '../../modeltypes/goals';
import { toast } from 'react-toastify';
import { getAllTags } from 'src/collections/tags';
import { TagOptions, TagType } from 'src/modeltypes/tags';
import { UploadedFile } from '../../utility/uploadHandler';
import { uploadFile } from '../../firebase/storage';
import { TagId } from 'src/modeltypes/id';
import { ReactSelectOptions } from 'src/types/types';

const GoalForm = () => {
  const [goal, setThisGoal] = useState<GoalType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState<TagOptions[]>([]);
  const [tags, setTags] = useState<TagOptions[]>([]);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();

  const getTags = async () => {
    const dbTags: TagType[] = await getAllTags();
    const selectorT: TagOptions[] = dbTags.map((dbTag: TagType) => {
      return {
        label: `${dbTag.name}`,
        value: dbTag?.id,
      };
    });
    setTags(selectorT);
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Goals',
        path: '/goals',
      },
      {
        label: id === 'new' ? 'New Goal' : 'Edit Goal',
        path: `/goals/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    //resetForm
  } = useFormik<{
    name: string;
    description: string;
    locked: boolean;
    tags?: TagId[];
    icon?: UploadedFile;
  }>({
    initialValues: {
      name: '',
      description: '',
      locked: true,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      description: yup.string(),
      value: yup.number(),
    }),
    validate: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors: any = {};
      if (!values.name) {
        errors.name = 'The goal must be present';
      }
      return errors;
    },
    onSubmit: async (newGoal) => {
      toast
        .promise(
          async () => {
            let goalId = goal?.id;

            if (!goalId) {
              goalId = await addNewGoal();
            }
            let iconUrl = null;
            if (goal?.icon !== values.icon?.url && values.icon?.url !== '' && values.icon?.file) {
              iconUrl = await uploadFile(values.icon.file, `${goalId}-${values.name}`, ['assets', 'goalIcons']);
            }
            const update = {
              ...newGoal,
              icon: iconUrl || null,
            };
            await updateGoal(goalId, update);
          },
          {
            pending: `Updating ${newGoal.name} Goal...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newGoal.name} Goal!`,
          },
        )
        .then(async () => {
          navigate('/goals');
        });
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currGoal = await getGoalById(id || '');
        if (currGoal === null) {
          navigate('/Goals');
          return;
        }

        await setValues({
          name: currGoal.name || '',
          description: currGoal.description || '',
          locked: currGoal.locked || false,
          tags: currGoal.tags || [],
          icon: { url: currGoal.icon },
        });
        setThisGoal(currGoal);

        console.log('currGoal?.tags?.length: ', currGoal?.tags?.length);
        if (currGoal?.tags?.length) {
          const populateTags = tags.filter((t) => currGoal?.tags?.includes(t.value));
          console.log('populateTags: ', populateTags);
          console.log('tags: ', tags);
          setSelectedTags(populateTags);
        }
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const disabledForm = false;

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id {id}
                </label>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  id saved {goal?.id}
                </label>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Name
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='name'
                        id='name'
                        autoComplete='name'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.name ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.name}
                        onChange={handleChange}
                        placeholder={'Goal Name'}
                      />
                    </div>
                  </div>
                  {errors && errors.name && <div className='text-red-600'>{errors?.name?.toString()}</div>}
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'Enter Goal Description.  The user will see this.'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
            <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
              Icons
            </label>
            <div className='flex  justify-evenly'>
              <div className='flex items-center justify-center flex-col '>
                <p className='text-sm text-gray-600 mb-2'>Normal</p>
                <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100'>
                  {values?.icon?.url ? (
                    <img className='h-full w-full' src={values.icon.url} alt='icon' />
                  ) : (
                    <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                      <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                    </svg>
                  )}
                </span>
                <div className='flex text-sm text-gray-600 mt-3'>
                  <label
                    htmlFor='icon'
                    className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                  >
                    <span>Upload a file</span>
                    <input
                      id='icon'
                      name='icon'
                      type='file'
                      accept='image/*'
                      className='sr-only'
                      onChange={(e) => {
                        const file = e.target?.files?.item(0);
                        if (file) {
                          const url = URL.createObjectURL(file);
                          setValues({
                            ...values,
                            icon: {
                              url: url,
                              file: file,
                            },
                          });
                        }
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='tracks' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Add Tags
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <Select
                options={tags?.length ? tags : []}
                isMulti={true}
                value={selectedTags}
                onChange={(vals) => {
                  setSelectedTags(vals as ReactSelectOptions[]);
                }}
                className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
              />
              <div className='flex flex-row items-center max-w-lg justify-between mt-4'>
                <h4 className=''>If you do not see the tag you are looking for, create it</h4>
                <button
                  onClick={() => navigate('/tags/new')}
                  className={`justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  Add Tags
                </button>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/goals')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {goal ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default GoalForm;

// import React, {  } from 'react';
// import Skeleton from 'react-loading-skeleton';
// const GoalForm = () => {

//   return (
//         <>
//           <Skeleton height={50} />
//           <Skeleton count={15} className='mt-3' />{' '}
//         </>
//   );
// };

// export default GoalForm;
