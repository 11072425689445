// *******************************************************
// ConfirmModal
// -------------------------------------------------------
// This is a ConfirmModal
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

export interface ConfirmModalProps {
  onConfirm: () => void;
  body: string;
  title: string;
  onCancel: () => void;
}

const ConfirmModal = ({ body, onConfirm, onCancel, title }: ConfirmModalProps) => {
  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[10%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative p-4 w-full max-w-2xl h-full md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>{title}</h3>
          </div>
          <div className='p-6 space-y-6'>
            <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>{body}</h3>
          </div>
          <div className='flex justify-end text-right p-6 space-x-2 rounded-b-20 rounded-t border-t border-gray-200 dark:border-gray-600'>
            <button
              onClick={onCancel}
              type='button'
              className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              type='button'
              className='text-white inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
