import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { getRewardById, setNewReward, updateReward } from '../../collections/rewards';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { RewardBadgeType, RewardType, RewardTypeBase } from '../../modeltypes/rewards';
import { toast } from 'react-toastify';
import { ReactSelectOptions } from '../../types/types';
import { TagType } from 'src/modeltypes/tags';
import { getAllTags } from 'src/collections/tags';
import { uiFormatTimestamp } from '../../collections/shared';
import { UploadedFile } from '../../utility/uploadHandler';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyToCliboardIcon } from '../../assets/copyToClipboard.svg';
import { FileUploader } from 'react-drag-drop-files';
import { uploadFile } from '../../firebase/storage';
import { TagId } from '../../modeltypes/id';

const RewardForm = () => {
  const [reward, setReward] = useState<RewardType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState<ReactSelectOptions[]>([]);
  const [tags, setTags] = useState<ReactSelectOptions[]>([]);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Rewards',
        path: '/rewards',
      },
      {
        label: id === 'new' ? 'New Reward' : 'Edit Reward',
        path: `/rewards/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const getTags = async () => {
    const dbTags = await getAllTags();
    const selectorT = dbTags.map((dbTag: TagType) => ({
      label: `${dbTag.name}`,
      value: dbTag?.id,
    }));
    setTags(selectorT);
  };

  useEffect(() => {
    getTags();
  }, []);

  const {
    values,
    handleSubmit,
    handleChange,
    setValues,
    //resetForm
  } = useFormik<{
    title: string;
    description: string;
    locked: boolean;
    tags?: TagId[];
    badges: (RewardBadgeType & { iconFile?: File })[];
    icon?: UploadedFile;
  }>({
    initialValues: {
      title: '',
      description: '',
      locked: true,
      badges: [],
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required'),
      description: yup.string(),
    }),
    validateOnChange: false,
    onSubmit: async (newReward) => {
      let rewardId = reward?.id;
      const isNew = !reward?.id;
      console.log('rewardId: ', rewardId);
      if (!rewardId) {
        rewardId = newReward.title.toUpperCase().replace(' ', '_');
        await setNewReward(rewardId, { id: rewardId });
        console.log('rewardId: ', rewardId);
      }
      console.log('rewardId after: ', rewardId);
      console.log(
        'sele',
        selectedTags.map((el) => el.value),
      );
      console.log('isNew: ', isNew);
      console.log('newReward?.tags: ', newReward?.tags);

      const newBadges: RewardBadgeType[] = [];

      for (const badgeIndex in values.badges) {
        const badge = values.badges[badgeIndex];
        const oldBadge = reward?.badges?.find((b) => b.id === badge.id);
        if ((!oldBadge || badge.iconUrl !== oldBadge.iconUrl) && badge.iconFile) {
          const iconUrl = await uploadFile(
            badge.iconFile,
            `${rewardId}-badge${badgeIndex + 1}.${badge.iconFile.name.split('.').pop()}`,
            ['assets', 'badgeIcons'],
          );
          delete badge.iconFile;

          newBadges.push({
            ...badge,
            iconUrl,
          });
        } else {
          delete badge.iconFile;
          newBadges.push(badge);
        }
      }

      let iconUrl: string | null = values.icon?.url || null;
      if (reward?.iconUrl !== values.icon?.url && values.icon?.url !== '' && values.icon?.file) {
        iconUrl = await uploadFile(values.icon.file, 'icon', ['assets', rewardId]);
      }

      const update: RewardTypeBase = {
        id: rewardId,
        title: values.title,
        description: values.description,
        tags: selectedTags.map((el) => el.value),
        locked: values.locked,
        iconUrl: iconUrl,
        badges: newBadges,
      };

      toast
        .promise(updateReward(rewardId, update), {
          pending: `Updating ${newReward.title} Reward...`,
          error: "Can't do it now, try again.",
          success: `Updated ${newReward.title} Reward!`,
        })
        .then(async () => {
          navigate('/rewards');
        });
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currReward = await getRewardById(id || '');
        if (currReward === null) {
          navigate('/Rewards');
          return;
        }

        await setValues({
          title: currReward.title || '',
          description: currReward.description || '',
          locked: currReward.locked || false,
          tags: currReward.tags || undefined,
          badges: currReward.badges,
          icon: { url: currReward.iconUrl },
        });

        setReward(currReward);

        const dbTags = await getAllTags();
        const selectorT = dbTags.map((dbTag: TagType) => ({
          label: `${dbTag.name}`,
          value: dbTag?.id,
        }));
        setTags(selectorT);
        if (currReward?.tags?.length) {
          setSelectedTags(selectorT.filter((t) => currReward?.tags?.includes(t?.value)));
        }
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  useEffect(() => {
    setValues({
      ...values,
      tags: selectedTags.map((t) => t.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const disabledForm = false;

  const handleUploadDrop = useCallback(
    (badgeId: string) =>
      (file: File): void => {
        const url = URL.createObjectURL(file);
        const badges = values.badges.map((badge) =>
          badge.id === badgeId
            ? {
                ...badge,
                iconUrl: url,
                iconFile: file,
              }
            : badge,
        );
        setValues({
          ...values,
          badges,
        });
      },
    [values, setValues],
  );

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5'>
                Reward Details <span style={{ fontSize: '80%' }}>(ID: {reward?.id || 'not yet'})</span>
              </label>
              <p>
                <strong>Created:</strong>&nbsp;{uiFormatTimestamp(reward?.createdAt)}
                &nbsp;&nbsp;
                <strong>Last Updated:</strong>&nbsp;{uiFormatTimestamp(reward?.updatedAt)}
              </p>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='rewards' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Reward Title
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type='text'
                      name={'title'}
                      id={`title`}
                      autoComplete={`title`}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      value={values.title || ''}
                      onChange={handleChange}
                      placeholder={'Enter Reward Title'}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <div>
                    <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Description
                    </label>
                    <p className={'text-[11px] text-gray-500 mt-1 mb-1'}>
                      {'If you want to show current level max in description use #max instead.'}
                    </p>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'For example: Reach #max minutes of practice '}
                    />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-2'>
                  <div>
                    <label htmlFor='title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Locked
                    </label>
                  </div>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input type='checkbox' name='locked' onChange={handleChange} checked={values.locked} />
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
                  <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                    Icons
                  </label>
                  <div className='flex  justify-evenly'>
                    <div className='flex items-center justify-center flex-col '>
                      <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100'>
                        {values?.icon?.url ? (
                          <img className='h-full w-full' src={values.icon.url} alt='icon' />
                        ) : (
                          <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                            <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                          </svg>
                        )}
                      </span>
                      <div className='flex text-sm text-gray-600 mt-3'>
                        <label
                          htmlFor='icon'
                          className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                        >
                          <span>Upload a file</span>
                          <input
                            id='icon'
                            name='icon'
                            type='file'
                            accept='image/*'
                            className='sr-only'
                            onChange={(e) => {
                              const file = e.target?.files?.item(0);
                              if (file) {
                                const url = URL.createObjectURL(file);
                                setValues({
                                  ...values,
                                  icon: {
                                    url: url,
                                    file: file,
                                  },
                                });
                              }
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='rewards' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Add Tags
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={tags || []}
                      isMulti={true}
                      value={selectedTags}
                      onChange={(vals) => {
                        setSelectedTags(vals as ReactSelectOptions[]);
                      }}
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                    />
                    <div className='flex flex-row items-center max-w-lg justify-between mt-4'>
                      <h4 className=''>If you do not see the tag you are looking for, create it</h4>
                      <button
                        onClick={() => navigate('/tags/new')}
                        className={`justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        Add Tags
                      </button>
                    </div>
                  </div>
                </div>
                <div className={`flex flex-col sm:border-t sm:border-gray-200 `}>
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start '>
                    <label htmlFor='name' className='block text-lg font-medium text-gray-700 sm:mt-px sm:py-3'>
                      Badges
                    </label>
                  </div>
                  <div>
                    {values.badges.map((badge, index) => (
                      <div
                        key={badge.id}
                        className={`flex flex-row items-center justify-evenly sm:py-5 ${
                          index > 0 ? 'sm:border-t sm:border-gray-200 ' : ''
                        }`}
                      >
                        <p className='block text-lg font-medium text-gray-700 mr-[20px]'>Badge {index + 1}</p>
                        <div className='min-w-[700px] flex rounded-md flex flex-col'>
                          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:border-gray-200 sm:py-5'>
                            <label
                              htmlFor='cover-photo'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Max
                            </label>
                            <input
                              // disabled={disabledForm}
                              type='number'
                              name={`badges[${index}].maxValue`}
                              id={`badges[${index}].maxValue`}
                              autoComplete={`badges`}
                              className={`sm:col-span-2 disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 border-gray-300`}
                              value={values?.badges?.[index].maxValue || ''}
                              onChange={handleChange}
                              placeholder={'Enter Max Value'}
                            />
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                            <label
                              htmlFor='cover-photo'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Existing Icon
                            </label>
                            <div className='mt-1 sm:mt-0 sm:col-span-2' style={{ display: 'inline-block' }}>
                              <div className='space-y-1 text-left' style={{ display: 'inline-block' }}>
                                {!badge?.iconUrl ? (
                                  <span className='text-sm'>None</span>
                                ) : (
                                  <>
                                    <a
                                      target='_blank'
                                      rel='noreferrer noopener'
                                      href={badge.iconUrl}
                                      className='sm:text-sm underline text-blue-400 pl-5 pr-5'
                                      onClick={() => false}
                                      style={{ display: 'inline-block' }}
                                      title={'Link to audio file.'}
                                    >
                                      Icon File
                                    </a>
                                    <CopyToClipboard text={badge.iconUrl}>
                                      <button type={'button'}>
                                        <CopyToCliboardIcon />
                                      </button>
                                    </CopyToClipboard>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5 mt-5'>
                            <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                              Upload Icon
                            </label>
                            <div className={`mt-1 sm:mt-0 sm:col-span-2`}>
                              <FileUploader
                                handleChange={handleUploadDrop(badge.id)}
                                name='icon'
                                types={['png', 'svg']}
                                multiple={false}
                                hoverTitle={''}
                                maxSize={10}
                                minSize={0}
                                label={'Select or drag an icon here.'}
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          onClick={(e) => {
                            e.preventDefault();
                            const temp = values.badges ? [...values.badges] : [];
                            const index = temp.findIndex((i) => i.id === badge.id);
                            if (index > -1) {
                              temp.splice(index, 1);
                              setValues({
                                ...values,
                                badges: temp,
                              });
                            }
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                    <button
                      className='mt-5 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={(e) => {
                        e.preventDefault();
                        const temp = values.badges ? [...values.badges] : [];
                        temp.push({
                          id: new Date().getTime().toString(),
                          maxValue: temp[temp.length - 1]?.maxValue + 10 || 10,
                        });
                        setValues({
                          ...values,
                          badges: temp,
                        });
                      }}
                    >
                      + Add Option
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>*/}
          {/*  <label htmlFor='minValue' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>*/}
          {/*    Minimum Value Requirement*/}
          {/*  </label>*/}
          {/*  <div className='mt-1 sm:mt-0 sm:col-span-2'>*/}
          {/*    <div className='max-w-lg flex rounded-md shadow-sm'>*/}
          {/*      <input*/}
          {/*        disabled={disabledForm}*/}
          {/*        type='number'*/}
          {/*        title='minValue'*/}
          {/*        id='minValue'*/}
          {/*        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${*/}
          {/*          errors.title ? 'border-red-300' : 'border-gray-300'*/}
          {/*        }`}*/}
          {/*        defaultValue={values.minValue}*/}
          {/*        onChange={handleChange}*/}
          {/*        placeholder={'Eneter the minimum value of the reward (<=)'}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  {errors && errors.minValue && <div className='text-red-600'>{errors?.minValue?.toString()}</div>}*/}
          {/*</div>*/}

          {/*<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>*/}
          {/*  <label htmlFor='maxValue' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>*/}
          {/*    Maximum Value Requirement*/}
          {/*  </label>*/}
          {/*  <div className='mt-1 sm:mt-0 sm:col-span-2'>*/}
          {/*    <div className='max-w-lg flex rounded-md shadow-sm'>*/}
          {/*      <input*/}
          {/*        disabled={disabledForm}*/}
          {/*        type='number'*/}
          {/*        name='maxValue'*/}
          {/*        id='maxValue'*/}
          {/*        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${*/}
          {/*          errors.title ? 'border-red-300' : 'border-gray-300'*/}
          {/*        }`}*/}
          {/*        defaultValue={values.maxValue}*/}
          {/*        onChange={handleChange}*/}
          {/*        placeholder={'Eneter the maximum value of the reward (>)'}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  {errors && errors.maxValue && <div className='text-red-600'>{errors?.maxValue?.toString()}</div>}*/}
          {/*</div>*/}
          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/rewards')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {reward ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default RewardForm;
