/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewCoach, getCoachById, updateCoach } from '../../collections/coach';
import { getCohortsForCoach } from '../../collections/cohort';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppContext } from '../../contexts/appContext';
import Select from 'react-select';
import { getCasualUsers } from '../../collections/user';
import { AccountRole, AccountType } from '../../modeltypes/account';
import { UserType } from '../../modeltypes/user';
import Skeleton from 'react-loading-skeleton';
import { CoachType, CoachTypeBase } from '../../modeltypes/coach';
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import { toast } from 'react-toastify';
import { updateAccount } from '../../collections/account';
import { UploadedFile } from '../../utility/uploadHandler';
import { uploadFile } from '../../firebase/storage';

// type UserSelect = {
//
// }

const CoachForm = () => {
  type CohortTableData = {
    name: string;
    id: string;
    company: string;
  };
  const [coach, setCoach] = useState<CoachType | null>(null);
  const [cohortTableData, setCohortTableData] = useState<CohortTableData[]>([]);
  const [cohortsToRemove, setCohortsToRemove] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [allUsers, setAllUsers] = useState<
    {
      label: string;
      value: Pick<AccountType, 'uid'> & Pick<UserType, 'firstName' | 'lastName'>;
    }[]
  >();

  const [selectedUser, setSelectedUser] = useState<
    (Pick<AccountType, 'uid'> & Pick<UserType, 'firstName' | 'lastName'>) | null
  >(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Coaches',
        path: '/coaches',
      },
      {
        label: id === 'new' ? 'New Coach' : 'Edit Coach',
        path: `/coaches/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const { values, errors, handleSubmit, handleChange, setValues, resetForm } = useFormik<{
    name: string;
    calendly: string;
    image?: UploadedFile;
    organization: string;
    description: string;
  }>({
    initialValues: {
      name: '',
      calendly: '',
      organization: '',
      description: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      calendly: yup.string().url(),
      organization: yup.string().required(),
      description: yup.string(),
    }),
    onSubmit: async (values) => {
      const newCoach: CoachTypeBase = {};
      newCoach.name = values.name;
      newCoach.calendlyUrl = values.calendly;
      newCoach.organizationName = values.organization;
      newCoach.description = values.description;
      if (coach) {
        newCoach.userId = coach.id;
        if (coach?.imageUrl !== values.image?.url && values.image?.url !== '' && values.image?.file) {
          newCoach.imageUrl = await uploadFile(values.image.file, `${coach.id}-avatar`, [
            'assets',
            'coaches',
            coach?.id,
          ]);
        }
        toast
          .promise(updateCoach(dieIfNullOrUndef(coach?.id), newCoach), {
            pending: 'Updating...',
            error: "Can't do it now, try again.",
            success: 'Coach Updated!',
          })
          .then(() => navigate('/coaches'));
      } else if (selectedUser) {
        newCoach.userId = selectedUser.uid;
        newCoach.id = selectedUser.uid;
        toast
          .promise(
            async () => {
              await addNewCoach(newCoach as CoachType, selectedUser.uid);
              await updateAccount(selectedUser.uid, { role: AccountRole.Coach });
            },
            {
              pending: `Adding ${newCoach.name} Coach...`,
              error: "Can't do it now, try again.",
              success: `Added ${newCoach.name} Coach!`,
            },
          )
          .then(() => {
            navigate('/coaches');
          });
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (id !== 'new') {
        const currCoach = await getCoachById(id || '');
        if (currCoach === null) {
          navigate('/Coaches');
          return;
        }

        const cohorts = await getCohortsForCoach(currCoach.id || '');
        setCohortTableData(
          cohorts.map((c) => {
            return {
              name: c.name,
              company: c.company,
              id: c.id,
            } as CohortTableData;
          }),
        );

        await setValues({
          name: currCoach.name || '',
          organization: currCoach.organizationName || '',
          calendly: currCoach.calendlyUrl || '',
          description: currCoach.description || '',
          image: { url: currCoach.imageUrl },
        });

        setCoach(currCoach);
      } else if (id === 'new') {
        getCasualUsers().then((casualUsers) => {
          setAllUsers(
            casualUsers.map((v) => ({
              label: `${v.firstName} ${v.lastName}`,
              value: v,
            })),
          );
        });
      }
    };

    getData().then(() => setIsLoading(false));
  }, [id, navigate, setValues]);

  const handleRemoveCohort = (cohortId: string) => {
    setCohortTableData(cohortTableData.filter((c) => c.id !== cohortId));
    setCohortsToRemove([...cohortsToRemove, cohortId]);
  };

  const disabledForm = !selectedUser && id === 'new';

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                {id === 'new' ? (
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Choose User *
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <Select
                        isClearable
                        options={allUsers}
                        onChange={(val) => {
                          if (val) {
                            setSelectedUser(val.value);
                            setValues({
                              ...values,
                              name: val.value.firstName + ' ' + val.value.lastName,
                            });
                          } else {
                            setSelectedUser(null);
                            resetForm();
                          }
                        }}
                        className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                        placeholder={'Type firstname or lastname'}
                      />
                    </div>
                  </div>
                ) : null}
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Name
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='name'
                        id='name'
                        autoComplete='name'
                        className={`disabled flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.name ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.name}
                        onChange={handleChange}
                        placeholder={'Viktor Frankl'}
                      />
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Avatar
                  </label>
                  <div className='flex  justify-evenly'>
                    <div className='flex items-center justify-center flex-col '>
                      <p className='text-sm text-gray-600 mb-2'>Normal</p>
                      <span className='h-16 w-16 rounded-full overflow-hidden bg-gray-100 hover:cursor-pointer'>
                        {values?.image?.url ? (
                          <img className='h-full w-full' src={values.image.url} alt='icon' />
                        ) : (
                          <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                            <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                          </svg>
                        )}
                      </span>
                      <div className='flex text-sm text-gray-600 mt-3'>
                        <label
                          htmlFor='icon'
                          className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                        >
                          <span>Upload a file</span>
                          <input
                            id='icon'
                            name='icon'
                            type='file'
                            accept='image/*'
                            className='sr-only'
                            onChange={(e) => {
                              const file = e.target?.files?.item(0);
                              if (file) {
                                const url = URL.createObjectURL(file);
                                setValues({
                                  ...values,
                                  image: {
                                    url: url,
                                    file: file,
                                  },
                                });
                              }
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='calendly' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Calendly URL
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='calendly'
                        id='calendly'
                        autoComplete='calendly'
                        className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.calendly ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.calendly}
                        onChange={handleChange}
                        placeholder={'https://calendly.com/your-awesome-username/30min'}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='organization' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Organization
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        disabled={disabledForm}
                        type='text'
                        name='organization'
                        id='organization'
                        autoComplete='organization'
                        className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                          errors.organization ? 'border-red-300' : 'border-gray-300'
                        }`}
                        defaultValue={values.organization}
                        onChange={handleChange}
                        placeholder={'The high five club'}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      disabled={disabledForm}
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'Anything you want users to know about the Coach'}
                    />
                  </div>
                </div>
              </div>
            </div>

            {id === 'new' ? null : (
              <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
                <div className='space-y-6 sm:space-y-5'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                          >
                            Cohort Name
                          </th>
                          <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                            Company
                          </th>
                          <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                            <span className='sr-only'>Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white'>
                        {cohortTableData.map((cohort) => (
                          <tr key={cohort.id}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                              <div className='flex items-center'>
                                <div className='ml-4'>
                                  <div className='text-gray-500'>{cohort.name}</div>
                                </div>
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='font-medium text-gray-900'>{cohort.company}</div>
                            </td>
                            <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                              <div
                                onClick={() => handleRemoveCohort(cohort.id)}
                                className='text-red-600 cursor-pointer'
                              >
                                Remove
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/coaches')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {coach ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default CoachForm;
