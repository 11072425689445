import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SwitchOption {
  @jsonMember(String, { isRequired: true })
  type: string | null = null;
  @jsonMember(String, { isRequired: true })
  nextNode: string | null = null;
}

@jsonObject
export class ScriptSubsection {
  @jsonMember(String, { isRequired: true })
  title: string | null = null;
  @jsonMember(String, { isRequired: true })
  code: string | null = null;
  @jsonMember(String)
  summaryText: string | null = null;
  @jsonMember(Number)
  duration: number | null = null;
  @jsonMember(String)
  spokenText: string | null = null;
  @jsonMember(String)
  singleTap: string | null = null;
  @jsonMember(String)
  doubleTap: string | null = null;
  @jsonMember(String)
  tripleTap: string | null = null;
  @jsonMember(String)
  nextSubsection: string | null = null;
  @jsonMember(String)
  path: string | null = null;
  @jsonMember(String)
  pathCode: string | null = null;
  @jsonMember(Number)
  silenceDuration: number | null = null;
  @jsonMember(Boolean)
  silenceFixedLen: boolean | null = null;
  @jsonArrayMember(String, { isRequired: true })
  comments: Array<string> = new Array<string>();
  @jsonMember(String)
  importedFromGDocID: string | null = null;
  @jsonMember(String)
  homeworkStatus: string | null = null;
  @jsonMember(String)
  homeworkAnswer: string | null = null;
  @jsonMember(String)
  type: string | null = null;
  @jsonMember(String)
  subType: string | null = null;
  @jsonArrayMember(SwitchOption)
  switchOptions: Array<SwitchOption> = new Array<SwitchOption>();
}

@jsonObject
export class ScriptSection {
  @jsonMember(String, { isRequired: true })
  sectionTitle: string | null = null;
  @jsonMember(String, { isRequired: true })
  sectionCode: string | null = null;
  @jsonArrayMember(ScriptSubsection)
  subsections: Array<ScriptSubsection> = new Array<ScriptSubsection>();
  @jsonArrayMember(String, { isRequired: true })
  comments: Array<string> = new Array<string>();
  @jsonMember(String)
  importedFromGDocID: string | null = null;
}

@jsonObject
export class ScriptLesson {
  @jsonMember(Number, { isRequired: true })
  version = 1;
  @jsonMember(String)
  author: string | null = null;
  @jsonMember(String)
  voiceArtist: string | null = null;
  @jsonMember(String)
  lastUpdate: string | null = null;
  @jsonMember(String, { isRequired: true })
  lessonTitle: string | null = null;
  @jsonMember(String, { isRequired: true })
  lessonSubtitle: string | null = null;
  @jsonMember(String, { isRequired: true })
  lessonDescription: string | null = null;
  @jsonMember(Number)
  lessonDuration: number | null = null;
  @jsonMember(Number)
  lessonMinDuration: number | null = null;
  @jsonMember(Number)
  lessonMaxDuration: number | null = null;
  @jsonMember(String)
  lessonHomework: string | null = null;
  @jsonMember(String)
  lessonHomeworkDoneQuestion: string | null = null;
  @jsonMember(String)
  lessonHomeworkNotDoneQuestion: string | null = null;
  @jsonArrayMember(ScriptSection)
  sections: Array<ScriptSection> = new Array<ScriptSection>();
  @jsonArrayMember(String, { isRequired: true })
  paths: Array<string> = new Array<string>();
  @jsonArrayMember(String, { isRequired: true })
  comments: Array<string> = new Array<string>();
  @jsonMember(String)
  importedFromGDocID: string | null = null;
}
