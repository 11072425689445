// *******************************************************
// AccessCodes List
// -------------------------------------------------------
// This is a Component for listing accessCode
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAccountsByIdArray } from '../collections/account';
import { getAllCohorts } from '../collections/cohort';
import { getAllCompanies } from '../collections/company';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/appContext';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Skeleton from 'react-loading-skeleton';
import { getAllAccessCodes, getAccessCodesByQuery } from '../collections/accessCode';
import SearchBar from './SearchBar';
import { orderBy, documentId } from '../models/dalaccess';
import { Timestamp, limit, startAfter } from 'firebase/firestore';
import { AccessCodeType } from '../modeltypes/accessCode';
import { CohortType } from '../modeltypes/cohort';
import { CompanyType } from '../modeltypes/company';
import { uiFormatTimestamp } from 'src/collections/shared';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const AccessCodesList = () => {
  type AccessCodeTableData = {
    id: string;
    code: string;
    description?: string;
    from?: string;
    companyId?: string;
    cohortId?: string;
    to?: string;
    used?: boolean;
    invited?: boolean;
    signedUp?: boolean;
    expireType?: string;
    expireValue?: any;
    userLimit?: number;
    discountAssossiation?: number;
    benifits?: any[];
    userList?: string[];
    createdAt?: Timestamp;
    accessCodeType?: string;
  };

  const [tableData, setTableData] = useState<AccessCodeTableData[]>([]);
  const [sortFilter, setSortFilter] = useState<{ key: keyof AccessCodeTableData; order: 'asc' | 'desc' }>();
  const [isLoading, setIsLoading] = useState(true);
  const [endReached, setEndReached] = useState(false);

  const [lastItem, setLastItem] = useState<AccessCodeType | null>(null);

  const [cohorts, setCohorts] = useState<CohortType[] | null>(null);
  const [companies, setCompanies] = useState<CompanyType[] | null>(null);

  const { searchTerm } = useAppContext();
  const navigate = useNavigate();

  const filteredItems = React.useMemo(() => {
    if (!tableData?.length) return [];
    return tableData
      .filter((item) => {
        const matchCode = item.code && item.code.match(`${searchTerm}`);
        const matchCompany = item.companyId && item.companyId.match(`${searchTerm}`);
        const matchCohort = item.cohortId && item.cohortId.match(`${searchTerm}`);
        const matchExperiationType = item.expireType && item.expireType.match(`${searchTerm}`);
        const matchId = item.id && item.id.match(`${searchTerm}`);
        return matchCode || matchId || matchCompany || matchCohort || matchExperiationType;
      })
      .sort((a, b) => {
        if (!sortFilter || !a[sortFilter.key]) {
          return 0;
        }
        if (sortFilter.key === 'createdAt') {
          const aCreatedAt = a.createdAt || Timestamp.now();
          const bCreatedAt = b.createdAt || Timestamp.now();
          if (sortFilter.order === 'desc') {
            return aCreatedAt.seconds - bCreatedAt.seconds;
          }
          if (sortFilter.order === 'asc') {
            return bCreatedAt.seconds - aCreatedAt.seconds;
          }
        } else {
          if (sortFilter.order === 'desc') {
            return a[sortFilter.key].toString().localeCompare(b[sortFilter.key].toString());
          }
          if (sortFilter.order === 'asc') {
            return b[sortFilter.key].toString().localeCompare(a[sortFilter.key].toString());
          }
        }
        return 0;
      });
  }, [searchTerm, tableData, sortFilter]);

  const mapAccessCodes = (accessCodes: AccessCodeType[], _cohorts: CohortType[], _companies: CompanyType[]) => {
    const mappedAccessCodes: AccessCodeTableData[] = [];

    console.log('mapAccessCodes accessCodes: ', accessCodes);
    for (const accessCode of accessCodes) {
      console.log('for loop accessCode: ', accessCode);

      const currAccessCode = accessCodes.find((a) => a.id === accessCode.id);
      const currCohort = _cohorts.find((c) => c.id === currAccessCode?.cohortId);
      const currCompany = _companies.find((c) => c.id === currAccessCode?.companyId);
      console.log('for loop currAccessCode: ', currAccessCode);
      console.log('for loop currCohort: ', currCohort);
      console.log('for loop currCompany: ', currCompany);

      const datum: AccessCodeTableData = {
        id: accessCode.id,
        code: accessCode.code,
        cohortId: currCohort?.name || '-',
        companyId: currCompany?.companyName || '-',
        expireType: accessCode?.expireType || 'NONE',
        createdAt: accessCode?.createdAt ? accessCode?.createdAt : Timestamp.now(),
      };

      mappedAccessCodes.push(datum);
    }
    return mappedAccessCodes;
  };

  const getAll = async () => {
    if (cohorts && companies) {
      setIsLoading(true);
      const accessCodes = await getAllAccessCodes();
      console.log('getAll accessCodes: ', accessCodes);
      setTableData(mapAccessCodes(accessCodes, cohorts, companies) || []);
      setEndReached(true);
      setIsLoading(false);
    }
  };

  const getAccessCodes = async () => {
    if (cohorts && companies) {
      const accessCode = await getAccessCodesByQuery(
        orderBy(documentId()),
        ...(lastItem ? [startAfter(lastItem.id)] : []),
        limit(50),
      );

      console.log('getAccessCodes accessCode: ', accessCode);

      setLastItem(accessCode.at(accessCode.length - 1) || null);
      const accessCodes = await getAccountsByIdArray(accessCode.map((el) => el.id));
      console.log('accessCodes lastItem: ', accessCodes);

      const thisThing = mapAccessCodes(accessCode, cohorts, companies) || [];

      console.log('mapAccessCodes thisThing: ', thisThing);

      const newData: AccessCodeTableData[] = [...tableData, ...thisThing];

      if (accessCode.length !== 50) {
        setEndReached(true);
      }

      setTableData(newData);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAccessCodes();
  }, [cohorts, companies]);

  useEffect(() => {
    if (!endReached && (searchTerm || sortFilter)) {
      getAll();
    }
  }, [sortFilter, searchTerm, endReached]);

  useEffect(() => {
    const cohortsPromise = getAllCohorts();
    const companiesPromise = getAllCompanies();
    Promise.all([cohortsPromise, companiesPromise]).then(([_cohorts, _companies]) => {
      setCohorts(_cohorts);
      setCompanies(_companies);
    });
  }, []);

  const sortRequest = (fieldName: keyof AccessCodeTableData) => {
    let order: 'asc' | 'desc' = 'asc';
    if (sortFilter && sortFilter.key === fieldName && sortFilter.order === 'asc') {
      order = 'desc';
    }
    setSortFilter({
      key: fieldName,
      order: order,
    });
  };

  // return (
  //   <div className='px-4 sm:px-6 lg:px-8'>
  //     <table className='min-w-full divide-y divide-gray-300'>
  //       <thead className='bg-gray-50'>
  //         <tr>
  //           <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 '>
  //             Id
  //           </th>
  //         </tr>
  //       </thead>
  //     </table>
  //   </div>
  // );

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 '>
                      Id
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('code')}
                    >
                      <p className='group inline-flex'>
                        Code
                        <span
                          className={`${sortFilter?.key !== 'code' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('accessCodeType')}
                    >
                      <p className='group inline-flex'>
                        AC Type
                        <span
                          className={`${sortFilter?.key !== 'accessCodeType' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>

                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('companyId')}
                    >
                      <p className='group inline-flex'>
                        Company
                        <span
                          className={`${sortFilter?.key !== 'companyId' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('cohortId')}
                    >
                      <p className='group inline-flex'>
                        Cohort
                        <span
                          className={`${sortFilter?.key !== 'cohortId' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('expireType')}
                    >
                      <p className='group inline-flex'>
                        Expiration Type
                        <span
                          className={`${sortFilter?.key !== 'expireType' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      onClick={() => sortRequest('createdAt')}
                    >
                      <p className='group inline-flex'>
                        Created At
                        <span
                          className={`${sortFilter?.key !== 'createdAt' && 'invisible'} ${
                            sortFilter?.order === 'asc' && 'rotate-180'
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      </p>
                    </th>
                    <th scope='col' colSpan={2} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {isLoading ? (
                    <>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton height={50} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={10}>
                          <Skeleton count={15} className='mt-3' />{' '}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {filteredItems.map((accessCode) => (
                        <tr key={accessCode.id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4 truncate max-w-[50px]'>
                                <div className='text-gray-500'>{accessCode.id}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{accessCode.code}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{accessCode.accessCodeType}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='font-medium text-gray-900 text-center'>{accessCode.companyId}</div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='text-gray-500 text-center'>{accessCode.cohortId}</div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {accessCode?.expireType}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {accessCode?.expireValue}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {uiFormatTimestamp(accessCode?.createdAt)}
                          </td>

                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                            <button
                              onClick={() => navigate('/AccessCodes/' + accessCode.id)}
                              className='text-indigo-600 hover:text-indigo-900 '
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={10} className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-center'>
                          {!endReached ? (
                            <button
                              onClick={() => {
                                getAccessCodes();
                              }}
                            >
                              Load More
                            </button>
                          ) : (
                            <p>End Reached</p>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessCodesList;
