import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CohortList from '../../components/cohort/CohortList';
import { useAppContext } from '../../contexts/appContext';

const CohortsPage = () => {
  const navigate = useNavigate();
  const { setBreadcrumbPaths, role } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Cohorts',
        path: '/cohorts',
      },
    ]);
  }, [setBreadcrumbPaths]);
  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl m-4 font-semibold text-gray-900'>Cohorts</h1>
        </div>
        {role === 'admin' && (
          <>
            <div className='mt-4 mr-10 sm:mt-0 sm:ml-16 sm:flex-none'>
              <button
                onClick={() => navigate('/Cohorts/new')}
                type='button'
                className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                Add Cohort
              </button>
            </div>

            <div className='mt-4 mr-10 sm:mt-0 sm:ml-16 sm:flex-none'>
              <button
                onClick={() => navigate('/Cohorts/reportOnAll')}
                type='button'
                className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              >
                Cohort Statistics
              </button>
            </div>
          </>
        )}
      </div>
      <CohortList />
    </>
  );
};
export default CohortsPage;
