export enum Pages {
  None = 'none',
  Companies = 'companies',
  Cohorts = 'cohorts',
  QuizItems = 'quizItems',
  LearningGames = 'learningGames',
  Tasks = 'tasks',
  AppUpdateInfo = 'appUpdateInfo',
  Email = 'email',
  Coaches = 'coaches',
  Users = 'users',
  AccessCode = 'accesscodes',
  Journey = 'journey',
  Streaks = 'streaks',
  Science = 'science',
  UserRewards = 'rewards',
  Incentives = 'incentives',
  CohortSessions = 'cohortSessions',
  Programs = 'programs',
  Lessons = 'lessons',
  Challenges = 'challenges',
  ChallengeSwitches = 'challengeswitches',
  TaskSwitches = 'taskswitches',
  AudioNode = 'audio-node',
  Chapter = 'chapter',
  ScienceLovers = 'science',
  PathTypes = 'pathtypes',
  Tags = 'tags',
  Goals = 'goals',
  Tracks = 'tracks',
  Rewards = 'rewards',
  Node = 'node',
  Unit = 'units',
  Notifications = 'notifications',
  FunctionRuns = 'function-runs',
  LessonAudioChunks = 'lessonAudioChunks',
  LessonAudioPractice = 'lessonAudioPractice',
  DataScience = 'datascience',
}
