// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
// *******************************************
// Component Imports
// -------------------------------------------
import IncentivesList from '../../components/IncentivesList';
import { useAppContext } from '../../contexts/appContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pages } from 'src/enums/pages';
// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const IncentivesPage = () => {
  const { setBreadcrumbPaths } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbPaths([{ label: 'Incentives', path: '/incentives' }]);
  }, [setBreadcrumbPaths]);

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Cohorts with Incentives</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            onClick={() => {
              navigate(`/${Pages.Incentives}/new`);
            }}
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            Add Incentive
          </button>
        </div>
      </div>
      <IncentivesList />
    </>
  );
};

export default IncentivesPage;
