// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';

// *******************************************
// Component Imports
// -------------------------------------------
import UsersList from '../../components/UsersList';
import { useAppContext } from '../../contexts/appContext';
import { useEffect } from 'react';

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const UsersPage = () => {
  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([{ label: 'Users', path: '/users' }]);
  }, [setBreadcrumbPaths]);
  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>Users</h1>
        </div>
      </div>
      <UsersList />
    </>
  );
};

export default UsersPage;
